import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useAtom } from 'jotai';

import { ChangeValue, Icon, OrderOrValuationId, useIsAdmin, useIsMobile, usePermissions, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import { versionAtom } from 'src/features/Warehouse';
import { toDateNumber } from 'src/utils/dateTime';
import { getFormattedAmount, getSupplyTypeTag } from 'src/utils/helpers';

import style from './SingleSupply.module.scss';

const SingleSupply = ({ supply, refreshSupplies, refreshProductsList }) => {
  const [version, setVersion] = useAtom(versionAtom);
  const [canViewOrders] = usePermissions([userPermissions.orders.read]);

  const { id, current_quantity, expire_date, order_uid, price, quantity, order_public_id, supply_date, type } = supply;
  const isMobile = useIsMobile(768);
  const isAdmin = useIsAdmin();

  const validator = useValidator();

  const changePriceHandler = async (newPrice) => {
    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      supply_id: id,
      price: newPrice
    };

    try {
      const { data } = await WarehouseApi.updateSupplyPrice(params);
      setVersion(version + 1);
      if (refreshSupplies) refreshSupplies(1, true);
      if (refreshProductsList) refreshProductsList();
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError([err.message]);
    }
  };

  return (
    <div className={classNames(style.container, { [style.hidePrices]: !canViewOrders })}>
      <div className={style.type}>
        {isMobile && <p>Typ dostawy:</p>}
        {getSupplyTypeTag({ type, className: style.tag })}
      </div>
      <div className={style.date}>
        {isMobile && <p>Data:</p>}
        {toDateNumber(supply_date)}
      </div>
      <div className={style.quantity}>
        {isMobile && <p>Dodana ilość:</p>}
        {quantity} szt.
      </div>
      <div className={style.inWarehouse}>
        {isMobile && <p>W magazynie:</p>}
        {current_quantity} szt.
      </div>
      {canViewOrders && (
        <div className={style.price}>
          {isMobile && <p>Cena za szt.:</p>}
          {isAdmin ? (
            <ChangeValue
              apiCallback={changePriceHandler}
              initialValue={price}
              type={'number'}
              label={'Zmień cenę.'}
              placement={'bottom'}
              unit={'zł'}
              hideArrows
              icon={
                <Icon
                  name={'edit'}
                  fill={'#888'}
                />
              }
            />
          ) : (
            getFormattedAmount(price)
          )}
        </div>
      )}
      <div className={style.expire}>
        {isMobile && <p>Data ważności:</p>}
        {expire_date ? toDateNumber(expire_date) : '-'}
      </div>
      <div className={style.order}>
        {isMobile && <p>Zamówienia:</p>}
        {order_public_id ? (
          <Link to={`/orders/${order_uid}`}>
            <OrderOrValuationId id={order_public_id} />
          </Link>
        ) : (
          '-'
        )}
      </div>
    </div>
  );
};

export default SingleSupply;
