import ApiClient from '../client';
import { NOTIFICATIONS_EVENT } from './events';
import { NotificationsRoutes, SocketsRoutes } from './routes';

export class AuthSockets {
  static authorizeWebsocketConnection(socket_id, channel_name) {
    return ApiClient.post(SocketsRoutes.AUTHORIZE_WEBSOCKET_CONNECTION, {
      socket_id,
      channel_name
    });
  }
}

export class NotificationsSockets {
  static openUserChanel(callback, companyID) {
    return window.pusher.subscribe(NotificationsRoutes.USER_NOTIFICATIONS(companyID)).bind(NOTIFICATIONS_EVENT, callback);
  }
  static closeUserChanel(companyID) {
    return window.pusher.unsubscribe(NotificationsRoutes.USER_NOTIFICATIONS(companyID));
  }
  static openSupplierChanel(callback, userID) {
    return window.pusher.subscribe(NotificationsRoutes.SUPPLIER_NOTIFICATIONS(userID)).bind(NOTIFICATIONS_EVENT, callback);
  }
}
