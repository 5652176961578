import React, { useEffect, useState } from 'react';

import { Button, PaginationNav, useRequestAbortController, useSearchParam } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WholesalersApi } from 'src/api';
import query from 'src/utils/query';

import WholesalerSuppliersTable from '../WholesalerSuppliersTable';

import styles from './WholesalersSuppliers.module.scss';

const WholesalersSuppliers = ({ wholesalerId, paramsData }) => {
  const params = query(paramsData);

  const sortBy = useSearchParam(params, 'sort_by');
  const sortOrder = useSearchParam(params, 'sort_order');
  const page = useSearchParam(params, 'page');

  const [pageData, setPageData] = useState([]);
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [isLoading, setIsLoading] = useState(true);

  const [abortController, setNewController] = useRequestAbortController();

  const getData = async () => {
    if (abortController) abortController.abort();
    const signal = setNewController();

    const params = {
      wholesaler_id: wholesalerId,
      page: page || 1,
      perPage: perPage,
      ...(sortBy && { sort_by: sortBy }),
      ...(sortOrder && { sort_order: sortOrder })
    };

    try {
      setIsLoading(true);
      const { data } = await WholesalersApi.getWholesalerDistributors(params, signal);
      setPageQty(data.last_page);
      setPageData(data.data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [sortBy, sortOrder, page, perPage]);

  return (
    <div>
      <WholesalerSuppliersTable
        list={pageData}
        isLoading={isLoading}
        params={params}
        refetchSuppliers={getData}
      />
      <PaginationNav
        params={params}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
      />
    </div>
  );
};

export default WholesalersSuppliers;
