import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';
import { refreshList } from 'src/features/Lists/actions';

import style from './EditListName.module.scss';

const EditListName = ({ closeModal, uid, name }) => {
  const [listName, setListName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setListName(name);
  }, [name]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await ListsApi.editListName({ uid, title: listName });
      dispatch(refreshList());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={handleSubmit}
    >
      <Input
        placeholder={'Nowa nazwa listy'}
        wrapperClass={style.input}
        value={listName}
        onChange={(e) => setListName(e.target.value)}
      />
      <Button
        label={'Zmień nazwę'}
        title={'Zmień nazwę listy'}
        type={'submit'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default EditListName;
