import React, { useEffect, useMemo, useState } from 'react';
import { useAtom } from 'jotai';

import { LabelWithValue, useIsAdmin } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { StatisticsApi } from 'src/api';
import { ordersAtom, suppliersCountAtom } from 'src/features/Analytics';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './OrdersSummary.module.scss';

export const getOrdersSumForUser = (data = []) => data?.reduce((acc, value) => acc + value, 0);

const getOrdersSumAndCountForAdmin = (data = []) => {
  const sumArray = [];

  data.forEach((company) => {
    sumArray.push(...company.data);
  });

  return sumArray.reduce((acc, value) => acc + value, 0);
};

const OrdersSummary = ({ filters, selectedCompanyId }) => {
  const isAdmin = useIsAdmin();
  const [ordersData, setOrdersData] = useAtom(ordersAtom);
  const [suppliersData, setSuppliersData] = useAtom(suppliersCountAtom);

  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    if (isAdmin && !selectedCompanyId) return;

    const queryData = {
      ...(isAdmin
        ? {
            company_ids:
              typeof selectedCompanyId === 'string' ? [Number(selectedCompanyId)] : selectedCompanyId.map((id) => Number(id))
          }
        : { company_ids: filters.selectedCompanies.map((company) => company.value) }),
      ...(filters.startDate && filters.endDate && { date_from: filters.startDate, date_to: filters.endDate })
    };

    try {
      setIsLoading(true);
      const { data: order } = isAdmin
        ? await StatisticsApi.getExpensesMonthlyByCompanies(queryData)
        : await StatisticsApi.getExpensesMonthly(queryData);
      const { data: suppliers } = await StatisticsApi.getProductsRealisationBySupplierMonthly(queryData);
      setOrdersData(order);
      setSuppliersData(suppliers);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [filters, selectedCompanyId]);

  const ordersTotalValue = useMemo(() => {
    const sum = isAdmin ? getOrdersSumAndCountForAdmin(ordersData?.series_total_sum) : getOrdersSumForUser(ordersData?.total_sum);
    const count = isAdmin
      ? getOrdersSumAndCountForAdmin(ordersData?.series_orders_count)
      : ordersData?.orders_count?.reduce((acc, value) => acc + value, 0);

    const productsCount = ordersData?.series_unique_products_count
      ?.map((item) => item.data.reduce((acc, value) => acc + value, 0))
      .reduce((acc, value) => acc + value, 0);

    return { sum, count, productsCount };
  }, [ordersData]);

  const suppliersCount = useMemo(() => {
    const count = suppliersData?.series_realisation_count
      ?.map((sup) => sup.data.reduce((acc, [_, value]) => acc + value, 0))
      .filter((value) => value > 0).length;

    return { count };
  }, [suppliersData]);

  return (
    <div className={style.wrapper}>
      <LabelWithValue
        label={'Wartość zamówień'}
        value={ordersTotalValue.sum ? getFormattedAmount(ordersTotalValue.sum) : '-'}
        isLoading={isLoading}
      />
      <LabelWithValue
        isLoading={isLoading}
        label={'Liczba zamówień'}
        value={ordersTotalValue.count || '-'}
      />
      <LabelWithValue
        label={'Dostawcy, biorący udział'}
        value={suppliersCount.count || '-'}
        isLoading={isLoading}
      />
    </div>
  );
};

export default OrdersSummary;
