import React, { useMemo, useState } from 'react';

import { Button, QtySelector } from 'components';

import ChangeMenu from '../index';
import VolumesForm from './components/VolumesForm';

import style from '../ChangeMenu.module.scss';

const ChangeQuantity = (props) => {
  const {
    quantity,
    setQuantity,
    unit = '',
    productId,
    wasQtyChange,
    isActive,
    setIsActive,
    min,
    max,
    tagClassName,
    displayValueFormatter,
    isOfferCatalogVolumeChange,
    companyId,
    disabled
  } = props;
  const [qtyValue, setQtyValue] = useState(+quantity);
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async (volume = null) => {
    const value = volume && typeof volume === 'number' ? volume : qtyValue;
    setIsLoading(true);
    if (setQuantity) await setQuantity(Number(value));
    setIsActive(false);
    setIsLoading(false);
  };

  const isNotDisabled = useMemo(() => {
    if (min?.toString() && max?.toString()) {
      return +qtyValue >= +min && +qtyValue <= +max;
    } else if (min?.toString()) {
      return +qtyValue >= +min;
    } else if (max?.toString()) {
      return +qtyValue <= +max;
    } else {
      return true;
    }
  }, [qtyValue, min, max]);

  return (
    <ChangeMenu
      value={`${quantity} ${unit || ''}`}
      isActive={isActive}
      setIsActive={setIsActive}
      wasDataChange={wasQtyChange}
      tagClassName={tagClassName}
      displayValueFormatter={displayValueFormatter}
      placement={isOfferCatalogVolumeChange && 'bottom'}
      disabled={disabled}
    >
      {isOfferCatalogVolumeChange ? (
        <VolumesForm
          handleSave={handleSave}
          productId={productId}
          companyId={companyId}
          isSaveLoading={isLoading}
          setVolumeState={setQtyValue}
        >
          <QtySelector
            label={'Zmień ilość'}
            id={productId + '-new-qty'}
            name={productId + '-new-qty'}
            state={qtyValue}
            setState={setQtyValue}
            suffix={unit}
            width={130}
          />
          <Button
            label={'Zapisz'}
            onClick={handleSave}
            className={style.save}
            disabled={!isNotDisabled}
            isLoading={isLoading}
          />
        </VolumesForm>
      ) : (
        <>
          <QtySelector
            label={'Zmień ilość'}
            id={productId + '-new-qty'}
            name={productId + '-new-qty'}
            state={qtyValue}
            setState={setQtyValue}
            suffix={unit}
            width={130}
          />
          <Button
            label={'Zapisz'}
            onClick={handleSave}
            className={style.save}
            disabled={!isNotDisabled}
            isLoading={isLoading}
          />
        </>
      )}
    </ChangeMenu>
  );
};

export default ChangeQuantity;
