import React from 'react';

import { Checkbox, InfoWidget } from 'components';

import style from '../UserRowForm/UserRowForm.module.scss';

const PermCheckbox = ({
  name,
  permissions,
  getIsInherit,
  onPermissionChange,
  areDisabled,
  isLoading,
  grantExtraPermissions = [],
  revokeExtraPermissions = [],
  isSelected = false
}) => {
  const isInherited = getIsInherit(name);

  const onChangeHandler = (e) => {
    onPermissionChange(e, e.target.checked ? grantExtraPermissions : revokeExtraPermissions);
  };

  return (
    <div className={style.checkboxWrapper}>
      <Checkbox
        name={name}
        value={permissions[name] || isSelected}
        onChange={onChangeHandler}
        className={style.checkbox}
        disabled={areDisabled || getIsInherit(name)}
        isLoading={isLoading}
      />
      {isInherited && (
        <InfoWidget
          className={style.info}
          place={'bottom'}
        >
          <p>Permisja dziedziczona z oddziału nadrzędnego.</p>
        </InfoWidget>
      )}
    </div>
  );
};

export default PermCheckbox;
