import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Input, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AccountsApi } from 'src/api';

import style from '../../../../onboarding.module.scss';

const ResetPasswordForm = () => {
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const validator = useValidator();
  const history = useHistory();
  const params = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const queryData = {
      password,
      password_confirmation: repeatPassword,
      token: params.token
    };

    try {
      setIsLoading(true);
      const { data } = await AccountsApi.resetPassword(queryData);
      notifyCommon([data.message]);
      history.push('/login');
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.card}
      onSubmit={handleSubmit}
    >
      <h2 className={style.formLabel}>Resetowanie hasła</h2>
      <Input
        id='password'
        name='password'
        placeholder='Nowe hasło'
        type='password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        validator={validator}
        rule={'required|min_password'}
      />
      <Input
        id='repeat-password'
        name='repeat-password'
        placeholder='Powtórz hasło'
        type='password'
        value={repeatPassword}
        onChange={(e) => setRepeatPassword(e.target.value)}
        validator={validator}
        rule={`required|min_password|in:${password}`}
      />
      <div className={classNames(style.buttons)}>
        <Button
          type='submit'
          label={'Ustaw hasło'}
          isLoading={isLoading}
          className={style.button}
        />
      </div>
    </form>
  );
};

export default ResetPasswordForm;
