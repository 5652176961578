import { formatPhoneNumberIntl } from 'react-phone-number-input';

import { ADD_TO_WAREHOUSE, UNDO_STATUS } from '../features/OrdersView/components/SingleCart/components/ProductsSelect';
import { getRoleColorCode } from '../utils/helpers';
import { availabilities, orderEvents, productStatuses, userRoles } from './enums';

export const CONTACT_PHONE_NUMBER = '+48732191011';
export const CONTACT_PHONE_NUMBER_FORMATTED = formatPhoneNumberIntl(CONTACT_PHONE_NUMBER);

export const KATE_PHONE_NUMBER = '+48732131585';
export const KATE_PHONE_NUMBER_FORMATTED = formatPhoneNumberIntl(KATE_PHONE_NUMBER);

export const CONTACT_EMAIL = 'info@avalio.io';

export const APP_AVALIO_PUBLIC_URL = 'https://app.avalio.io';

export const WP_MESSAGES_API_URL = '/posts?categories=17&per_page=1&status=publish';
export const WP_CHANGELOGS_API_URL = '/posts?categories=14&per_page=1&status=publish';

export const legalForms = [
  {
    id: 1,
    name: 'JDG',
    title: 'Jednoosobowa działalność gospodarcza'
  },
  {
    id: 2,
    name: 'Sp. c.',
    title: 'Spółka cywilna'
  },
  {
    id: 3,
    name: 'Sp. z o.o.',
    title: 'Spółka z ograniczoną odpowiedzialnością'
  },
  {
    id: 4,
    name: 'S.A.',
    title: 'Spółka akcyjna'
  },
  {
    id: 7,
    name: 'S.K.A',
    title: 'Spółka komandytowo-akcyjna'
  },
  {
    id: 5,
    name: 'Sp. j.',
    title: 'Spółka jawna'
  },
  {
    id: 6,
    name: 'Sp. k.',
    title: 'Spółka komandytowa'
  },
  {
    id: 7,
    name: 'Sp. p.',
    title: 'Spółka partnerska'
  }
];

const getUserRoleLabel = (role) => {
  switch (role) {
    case 'user':
      return 'Użytkownik';
    case 'admin':
      return 'Administrator';
    case 'supplier':
      return 'Dostawca';
    case 'distributor':
      return 'Dystrybutor';
    default:
      return role;
  }
};

const getRoleOption = (role) => ({
  value: role,
  label: getUserRoleLabel(role),
  color: getRoleColorCode(role)
});

export const userRolesOptions = [
  getRoleOption(userRoles.admin),
  getRoleOption(userRoles.user),
  getRoleOption(userRoles.supplier),
  getRoleOption(userRoles.distributor)
];

const avaialbilityDictionary = {
  AVAILABLE: 'Dostępny',
  AVAILABLE_IN: 'Dostępny w',
  AVAILABLE_AFTER: 'Dostępny od',
  UNAVAILABLE: 'Brak'
};

const avaialbilityColors = {
  AVAILABLE: '#3e877e',
  AVAILABLE_IN: '#f5d060',
  AVAILABLE_AFTER: '#f5d060',
  UNAVAILABLE: '#ea6c64'
};

export const availabilityOptions = [
  {
    label: avaialbilityDictionary[availabilities.available],
    color: avaialbilityColors[availabilities.available],
    value: availabilities.available
  },
  {
    label: avaialbilityDictionary[availabilities.availableSince],
    color: avaialbilityColors[availabilities.availableSince],
    value: availabilities.availableSince
  },
  {
    label: avaialbilityDictionary[availabilities.notAvailable],
    color: avaialbilityColors[availabilities.notAvailable],
    value: availabilities.notAvailable
  }
];

export const getOrderProductModalContent = (status, orderHistory = []) => {
  const supplierAccepted = orderHistory.find(({ event }) => event === orderEvents.STATUS_CHANGED_TO_PROCESSING);
  const supplierSent = orderHistory.find(({ event }) => event === orderEvents.STATUS_CHANGED_TO_SENT);
  const productComplaint = orderHistory.filter(({ event }) => event === orderEvents.PRODUCT_COMPLAINT_BY_USER);

  const complainedProductsIds = productComplaint.reduce((accumulator, product) => {
    const valueObj = typeof product.value === 'string' && JSON.parse(product.value);
    let complainedProducts = [];

    valueObj?.product_ids.forEach((item) => {
      if (!accumulator.includes(item)) complainedProducts.push(item);
    });

    return [...accumulator, ...complainedProducts];
  }, []);

  switch (status) {
    case productStatuses.completed:
      return {
        title: 'Odbieranie produktów',
        description: 'Zadeklaruj liczę dostarczonych produktów. Dostawę pozostałych możesz odebrać później.',
        button: 'Odbierz',
        allowedStatuses: [productStatuses.pending, productStatuses.processing, productStatuses.sent, productStatuses.complaint],
        emptyState: 'Wszystkie produkty zostały odebrane.',
        notify: 'Wybrane produkty zostały odebrane.'
      };
    case productStatuses.complaint:
      return {
        title: 'Reklamacja produktów',
        description: 'Zaznacz produkty, które chcesz poddać reklamacji:',
        button: 'Poddaj reklamacji',
        allowedStatuses: [productStatuses.pending, productStatuses.processing, productStatuses.completed],
        emptyState: 'Reklamacji można poddać tylko odebrane produkty.',
        notify: 'Wybrane produkty zostały poddane reklamacji. Dostawca otrzyma Twoją wiadomość.'
      };
    case productStatuses.refunded:
      return {
        title: 'Zwrot produktów',
        description: 'Zaznaczy produkty, które chcesz zwrócić:',
        button: 'Zwróć',
        allowedStatuses: [productStatuses.completed, productStatuses.complaint],
        emptyState: 'Zwrócić można tylko odebrane lub reklamowane produkty.',
        notify: 'Wybrane produkty zostały zwrócone. Dostawca otrzyma Twoją wiadomość.'
      };
    case productStatuses.cancelled:
      return {
        title: 'Anuluj produkty',
        description: 'Zaznacz produkty, które chcesz anulować:',
        button: 'Anuluj wybrane produkty',
        allowedStatuses: [productStatuses.processing, productStatuses.sent],
        emptyState: 'Anulować można tylko te produkty, które nie zostały odebrane, zwrócone lub reklamowane.',
        notify: 'Wybrane produkty zostały anulowane.'
      };
    case UNDO_STATUS:
      return {
        complainedProductsIds,
        wasSupplierAcceptedOrder: !!supplierAccepted,
        wasSupplierSentOrder: !!supplierSent,
        title: 'Cofnij odbiór produktów',
        description: 'Zaznacz produkty, które chcesz, aby wróciły do poprzedniego statusu:',
        button: 'Przywróć status',
        allowedStatuses: [productStatuses.completed],
        emptyState: 'Możesz przywrócić status tylko odebranym produktom.',
        notify: 'Status wybranych produktów został przywrócony.'
      };
    case ADD_TO_WAREHOUSE:
      return {
        title: 'Dodaj produkty do magazynu',
        description: 'Zaznacz te produkty, które chcesz dodać do magazynu:',
        button: 'Dodaj do magazynu',
        allowedStatuses: [productStatuses.completed],
        extraFilter: (prod) => !prod?.added_to_warehouse,
        emptyState: 'Dodać do magazynu można tylko odebrane produkty.',
        notify: 'Wybrane produkty zostały dodane do magazynu'
      };
    default:
      return {
        title: '',
        description: '',
        button: '',
        allowedStatuses: [],
        emptyState: ''
      };
  }
};

export const companyPlaceholder = {
  name: 'Dane klienta ukryte',
  nip: 'Dane ukryte',
  phone: '+48 123 123 123',
  address: {
    apartment_number: null,
    building_number: '0',
    zip_code: 'Ukryto',
    street: 'Ukryto',
    city: 'Dane ukryte'
  }
};

export const months = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień'
];

export const EXCEL_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
