import queryString from 'query-string';

class Query {
  constructor(props) {
    const { location, history } = props;
    this.location = location || '';
    this.history = history;
    this.keys = queryString.parse(this.location.search) || {};
  }

  _set(key, value) {
    this.keys[key] = value;
  }

  _updateHistorySearch() {
    this._clearEmpty();
    this.history.push({
      search: queryString.stringify(this.keys)
    });
  }

  _clearEmpty() {
    Object.entries(this.keys).forEach(([key, value]) => {
      !value && delete this.keys[key];
    });
  }

  get(key) {
    return this.keys[key];
  }

  set(key, value) {
    this._set(key, value);
    this._updateHistorySearch();
  }

  setFew(values) {
    values.forEach((value) => {
      this._set(value.key, value.value);
    });
    this._updateHistorySearch();
  }

  has(key) {
    return key in this.keys;
  }

  bool(key) {
    return ['True', 'true', '1'].includes(this.get(key));
  }

  clear() {
    this.keys = {};
    this._updateHistorySearch();
  }

  remove(key) {
    if (this.keys[key]) {
      delete this.keys[key];
      this._updateHistorySearch();
    }
  }
}

export default (props) => new Query(props);
