import React from 'react';
import { Tabs } from 'antd';

import query from 'src/utils/query';

import WholesalerProducts from '../WholesalerProducts';
import WholesalerSuppliers from '../WholesalerSuppliers';

import styles from './WholesalerTabs.module.scss';

const WholesalerTabs = ({ wholesalerId, params }) => {
  const activeParams = query(params);

  const items = [
    {
      key: '1',
      label: 'Produkty',
      children: (
        <WholesalerProducts
          wholesalerId={wholesalerId}
          paramsData={params}
        />
      )
    },
    {
      key: '2',
      label: 'Przedstawiciele',
      children: (
        <WholesalerSuppliers
          wholesalerId={wholesalerId}
          paramsData={params}
        />
      )
    }
  ];

  return (
    <div className={styles.container}>
      <Tabs
        defaultActiveKey='1'
        items={items}
        onChange={() => activeParams.clear()}
        destroyInactiveTabPane
      />
    </div>
  );
};

export default WholesalerTabs;
