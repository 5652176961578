export const getSumOfProducts = (columns = [[]]) => {
  let sum = 0;
  const [column = { items: [] }] = columns;
  column.items.forEach(({ products }) => {
    sum += products?.length;
  });
  return sum;
};

export const getNumberOfSelectedProductInColumn = (column) => {
  let count = 0;

  column.items.forEach((cat) => {
    cat.products.forEach((product) => {
      if (product?.valuation?.selected) {
        count++;
      }
    });
  });

  return count;
};

export const getColumnPricesSum = (column) => {
  let sum = 0;

  column.items.forEach((cat) => {
    cat.products.forEach((product) => {
      if (product?.valuation?.selected) {
        sum += (product.valuation?.price * (100 - product.valuation?.discount)) / 100;
      }
    });
  });

  return sum;
};
