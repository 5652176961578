import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';

import { changeProducersVersion } from '../../actions';

import style from '../MoveProductsProducer/MoveProductsProducer.module.scss';

const NewProducer = ({ closeModal }) => {
  const validator = useValidator();
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    try {
      setIsLoading(true);
      await AdminApi.addProducer(name);
      dispatch(changeProducersVersion());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={(e) => handleSubmit(e)}
    >
      <Input
        id='producer-name'
        name='producer-name'
        label='Nazwa producenta'
        placeholder='np. 3M'
        value={name}
        onChange={(e) => setName(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <Button
        label={'Dodaj'}
        type={'submit'}
        isLoading={isLoading}
        className={style.button}
      />
    </form>
  );
};

export default NewProducer;
