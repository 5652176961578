import React, { useState } from 'react';

import { Button, Modal, useCompany } from 'components';

import MemberForm from './components/MemberForm';
import MembersList from './components/MembersList';
import PermissionsForm from './components/PermissionsForm';

import style from './MembersManagement.module.scss';

const MembersManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false);
  const [listVersion, setListVersion] = useState(0);
  const company = useCompany();

  const addMemberButton = (
    <>
      <Button
        label={'Edytuj uprawnienia'}
        onClick={() => setIsPermissionsModalOpen(true)}
        gray
      />
      <Button
        label={'Dodaj pracownika'}
        onClick={() => setIsModalOpen(true)}
      />
    </>
  );

  return (
    <>
      <div className={style.container}>
        <MembersList
          addMemberButton={addMemberButton}
          version={listVersion}
        />
      </div>
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Dodaj pracownika do ${company.name}`}
      >
        <MemberForm
          closeModal={() => setIsModalOpen(false)}
          changeVersion={() => setListVersion((prev) => prev + 1)}
        />
      </Modal>
      <Modal
        visible={isPermissionsModalOpen}
        onClose={() => setIsPermissionsModalOpen(false)}
        title={'Edytuj uprawnienia'}
      >
        <PermissionsForm closeModal={() => setIsModalOpen(false)} />
      </Modal>
    </>
  );
};

export default MembersManagement;
