import React from 'react';
import classNames from 'classnames';

import ProductHead from '../ProductHead';

import style from './ProductHistory.module.scss';

export const getColumnQty = (arr = []) => arr.length * 230 + 600 + 'px';

const ProductHistory = ({ product = {} }) => {
  const { current_quantity, logs = [], name, price } = product;

  return (
    <div
      className={style.container}
      style={{ width: getColumnQty(logs) }}
    >
      <ProductHead
        current_quantity={current_quantity}
        name={name}
        price={price}
      />
      <div className={style.productBody}>
        {logs.map((log, index) => (
          <div
            key={`${product.id}-${index}`}
            className={style.column}
          >
            <div className={classNames(style.wrapper, style.increase)}>
              <p>{log.increase}</p>
            </div>
            <div className={classNames(style.wrapper, style.decrease)}>
              <p>{log.decrease}</p>
            </div>
            <div className={style.wrapper}>
              <p>{log.quantity}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductHistory;
