import React from 'react';
import classnames from 'classnames';
import cn from 'classnames';
import moment from 'moment/moment';

import icon_person from 'assets/icons/person.svg';
import { CustomTooltip, useAuthUser, useIsMobile } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import { userRoles } from 'src/constants/enums';
import { imageErrorHandler } from 'src/utils/helpers';

import AddToCollection from '../AddToCollection';

import style from './SupplierProduct.module.scss';

moment.locale('pl');

const SupplierProduct = ({ product }) => {
  const { thumbnail, image, unit_name, name, producer, category, isUserProduct, user_name, quantity, id } = product || {};

  const isMobile = useIsMobile(1250);
  const user = useAuthUser();
  const isAdmin = user?.role === userRoles.admin;

  return (
    <div className={style.container}>
      <div className={classnames(style.wrapper, style.nameWrapper)}>
        <div className={style.columnWrapper}>
          <div className={style.thumbnailWrapper}>
            {!!isUserProduct && !isMobile && (
              <CustomTooltip
                wrapperStyle={style.userIconWrapper}
                title={isAdmin ? user_name : 'Twój produkt'}
              >
                <img
                  src={icon_person}
                  className={style.userIcon}
                  alt={'ikona użytkownika'}
                  onError={imageErrorHandler}
                />
              </CustomTooltip>
            )}
            <ProductThumbnailImage
              thumbnail={thumbnail}
              image={image}
              title={name}
              id={id}
            />
          </div>
        </div>
        <div className={style.columnWrapper}>
          <p className={style.text}>{name}</p>
          <div className={style.rowWrapper}>
            <p>Jednostka: {unit_name}</p>
            <p>Producent: {producer}</p>
          </div>
        </div>
      </div>
      <div className={cn(style.wrapper, style.quantity)}>
        {isMobile && <p className={style.text}>Wielkość zamówień:</p>}
        <p className={style.text}>{quantity}</p>
      </div>
      <div className={cn(style.wrapper, style.category)}>
        {isMobile && <p className={style.text}>Kategoria:</p>}
        <p className={style.text}>{category}</p>
      </div>
      <div className={classnames(style.wrapper, style.callToAction)}>
        <AddToCollection product={product} />
      </div>
    </div>
  );
};

export default SupplierProduct;
