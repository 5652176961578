import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input, useValidator } from 'components';
import ChangeMenu from 'components/layout/changeMenu';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';

import { changeSuppliersVersion } from '../../actions';

const SupplierEmailForm = (props) => {
  const { currentEmail, isActive, setIsActive, id: supplier_id } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(currentEmail);

  const validator = useValidator();
  const dispatch = useDispatch();

  const handleSave = async () => {
    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    const queryData = { supplier_id, email };

    try {
      setIsLoading(true);
      await AdminApi.changeSupplierEmail(queryData);
      dispatch(changeSuppliersVersion());
      setIsActive(false);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ChangeMenu
      value={currentEmail}
      isActive={isActive}
      setIsActive={setIsActive}
      width={'350px'}
    >
      <Input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        validator={validator}
        label={'E-mail'}
        rule={'required|email'}
      />
      <Button
        label={'Zapisz'}
        onClick={handleSave}
        isLoading={isLoading}
      />
    </ChangeMenu>
  );
};

export default SupplierEmailForm;
