import React from 'react';
import cn from 'classnames';

import styles from './Loader.module.scss';

const Loader = ({ prefix, style, className, light, large }) => {
  const spinner = <span className={cn(styles.spinner, { [className]: !prefix, [styles.light]: light, [styles.large]: large })} />;

  if (prefix) {
    return (
      <div
        className={cn(styles.prefixWrapper, className)}
        style={style}
      >
        {prefix}
        {spinner}
      </div>
    );
  }

  return spinner;
};

export default Loader;
