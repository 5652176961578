import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import { SaveButton, useIsAdmin } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi, CompanyApi } from 'src/api';

import style from '../thumbnails.module.scss';

const InviteThumbnail = ({ invite, refreshCallback, companyId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const isAdmin = useIsAdmin();

  const ApiClass = useMemo(() => (isAdmin ? AdminApi : CompanyApi), [isAdmin]);

  const handleCancelInvite = async () => {
    const data = {
      email: invite?.email,
      ...(isAdmin && { company_id: companyId })
    };
    try {
      setIsLoading(true);
      await ApiClass.cancelInvitation(data);
      if (refreshCallback) await refreshCallback();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <p>{invite?.email}</p>
      </div>
      <div className={classNames(style.wrapper, style.reverse, style.wide)}>
        <SaveButton
          type={'delete'}
          onClick={handleCancelInvite}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default InviteThumbnail;
