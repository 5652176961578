import React, { useLayoutEffect, useState } from 'react';

import { Select, useAuthUser } from 'components';

import { userRoles } from 'src/constants/enums';
import { compareArrays, getParamsArray } from 'src/utils/helpers';

import style from '../Filtering.module.scss';

const fn = (x) => ({ label: x, value: x });

const FilterByOptions = ({ params, optionKey, options = [], valueToOption = fn, placeholder, forAdmin, isMulti, ...rest }) => {
  const [selectedOption, setSelectedOption] = useState(isMulti ? [] : null);
  const paramValue = params.get(optionKey);
  const user = useAuthUser();

  const setSingleValue = (option) => {
    const areValueTheSame = option?.value === paramValue;

    if (!areValueTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: optionKey, value: option?.value },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set(optionKey, option?.value);
      }
    }
  };

  const setMultiValue = (options) => {
    const currentValue = getParamsArray(paramValue);
    const valuesArray = options?.map((option) => option.value);
    const areArrayTheSame = compareArrays(valuesArray, currentValue);

    if (!areArrayTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: optionKey, value: valuesArray },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set(optionKey, valuesArray);
      }
    }
  };

  const onChangeHandler = (e) => {
    setSelectedOption(e);
    if (isMulti) setMultiValue(e);
    else setSingleValue(e);
  };

  useLayoutEffect(() => {
    if (paramValue) {
      if (Array.isArray(paramValue)) setSelectedOption(paramValue?.map((item) => valueToOption(item)));
      else setSelectedOption(valueToOption(paramValue));
    }
  }, []);

  if (forAdmin && user?.role !== userRoles.admin) {
    return null;
  }

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={onChangeHandler}
      placeholder={placeholder}
      wrapperStyle={style.roleSelect}
      isSearchable={false}
      isMulti={isMulti}
      isClearable
      {...rest}
    />
  );
};

export default FilterByOptions;
