import React, { useEffect, useMemo, useState } from 'react';

import { Card, Chart, Select, useIsAdmin } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { StatisticsApi } from 'src/api';
import { getFormattedAmount } from 'src/utils/helpers';

import ExportData from '../ExportData';
import SuppliersPartOfBasket from '../SuppliersPartOfBasket';
import TimeOfResponseBySupplier from '../TimeOfResponseBySupplier';

import style from '../Charts.module.scss';

const options = [
  { label: 'Wartość zamówień', value: 'series_total' },
  { label: 'Liczba zamówień', value: 'series_orders_count' },
  { label: 'Liczba SKU', value: 'series_unique_products_count' }
];

const ProductsRealisationBySupplier = ({ selectedCompanyId, filters }) => {
  const isAdmin = useIsAdmin();
  const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState(options[0]);

  const getData = async () => {
    const queryData = {
      ...(isAdmin
        ? {
            company_ids:
              typeof selectedCompanyId === 'string' ? [Number(selectedCompanyId)] : selectedCompanyId.map((id) => Number(id))
          }
        : { company_ids: filters.selectedCompanies.map((company) => company.value) }),
      ...(filters.startDate && filters.endDate && { date_from: filters.startDate, date_to: filters.endDate })
    };
    try {
      setIsLoading(true);
      const { data } = await StatisticsApi.getProductsRealisationBySupplier(queryData);
      setChartData(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [filters, selectedCompanyId]);

  const chartHeight = Math.max((chartData?.supplier_names?.length || 0) * 50, 400);
  const colors = ['#3e877e', '#ea6c64'];

  const chartConfig = useMemo(() => {
    const getPercentages = (data) => {
      const completedPercentages = [];
      const cancelledPercentages = [];

      if (data && Array.isArray(data)) {
        data[0]?.data.forEach((completed, index) => {
          const cancelled = data[1].data[index];
          const total = completed + cancelled;

          completedPercentages.push(total === 0 ? 0 : (completed / total) * 100);
          cancelledPercentages.push(total === 0 ? 0 : (cancelled / total) * 100);
        });

        return { realized: completedPercentages, cancelled: cancelledPercentages };
      } else {
        return { realized: [], cancelled: [] };
      }
    };

    const data = chartData ? chartData[selectedDataType.value] : [];
    const percentagesData = getPercentages(data);
    let formatter;

    switch (selectedDataType.value) {
      case 'series_total': {
        formatter = (value) => getFormattedAmount(value);
        break;
      }
      default: {
        formatter = (value) => value;
        break;
      }
    }

    const defaultFormatter = (val, opts) => {
      const index = opts?.dataPointIndex;
      let percentages;
      if (opts.seriesIndex === 0) {
        percentages = percentagesData.realized[index];
      } else {
        percentages = percentagesData.cancelled[index];
      }
      return `${formatter(val)} (${percentages.toFixed(2)}%)`;
    };

    return { data, formatter, percentagesFormatter: defaultFormatter };
  }, [chartData, selectedDataType, selectedCompanyId]);

  return (
    <Card className={style.container}>
      <header className={style.header}>
        <h2 className={style.title}>Dostawcy</h2>
        <div className={style.options}>
          <ExportData
            apiCallback={StatisticsApi.exportProductsRealisationBySupplier}
            company_id={selectedCompanyId}
            filters={filters}
          />
        </div>
      </header>
      <div className={style.chartsWrapper}>
        <Chart
          tooltipFormatter={chartConfig.percentagesFormatter}
          title={'Zamówienia zrealizowane i anulowane'}
          dataLabelsFormatter={chartConfig.formatter}
          xAxisFormatter={chartConfig.formatter}
          categories={chartData?.supplier_names}
          configRefreshVariable={chartConfig}
          customSeries={chartConfig.data}
          haveData={chartData?.have_data}
          isLoading={isLoading}
          chartTypes={['bar']}
          height={chartHeight}
          colors={colors}
          tooltipShared
          horizontal
          fullWidth
          stacked
        >
          <Select
            onChange={setSelectedDataType}
            wrapperStyle={style.select}
            value={selectedDataType}
            className={style.select}
            isSearchable={false}
            options={options}
            width={200}
          />
        </Chart>
        <SuppliersPartOfBasket
          selectedCompanyId={selectedCompanyId}
          filters={filters}
        />
        <TimeOfResponseBySupplier
          selectedCompanyId={selectedCompanyId}
          filters={filters}
        />
      </div>
    </Card>
  );
};

export default ProductsRealisationBySupplier;
