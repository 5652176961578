import React, { useState } from 'react';

import { Button, Textarea, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OrdersApi, SupplierApi } from 'src/api';
import { getUniquesFromArrays } from 'src/api/utils';
import { handleNumeralWords } from 'src/utils/helpers';

import style from '../../../../OrderCart.module.scss';

const ConfirmOrderSupplierForm = ({
  selectedProducts,
  products,
  uid,
  orderUid,
  isLoading,
  setIsLoading,
  closeConfirmOrderModal,
  refreshDataCallback,
  supplierId
}) => {
  const [cancelProductsReasonComment, setCanelProductsReasonComment] = useState('');
  const validator = useValidator();

  const getProductCancelationComment = (comment, products = []) => `
    Anulowano realizację ${products.length} ${handleNumeralWords(['produktu', 'produktów', 'produktów'], products.length)}:
    
    ${products.map((prod, index) => `${index + 1}. ${prod.name}`).join('\n')}

    Powód anulowania:
    ${comment}
  `;

  const confirmOrder = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      await SupplierApi.saveOrderLink({
        selected: selectedProducts.map((product) => product.id),
        uid
      });
      if (selectedProducts?.length < products?.length) {
        const canceledProducts = getUniquesFromArrays(products, selectedProducts);
        await OrdersApi.sendSupplierSystemMessage({
          uid: orderUid,
          message: getProductCancelationComment(cancelProductsReasonComment, canceledProducts),
          supplier_id: supplierId,
          as_supplier: 1
        });
      }
      notifyCommon(['Zamówienie zostało przyjęte do realizacji.']);
      await refreshDataCallback();
      if (closeConfirmOrderModal) closeConfirmOrderModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={confirmOrder}>
      <h3 className={style.confirmTitle}>
        {selectedProducts?.length}/{products?.length} {handleNumeralWords(['produkt', 'produkty', 'produktów'], products?.length)}
      </h3>
      {selectedProducts?.length < products?.length && (
        <div className={style.cancelReasonComment}>
          <Textarea
            id={'cancel-reason'}
            name={'cancel-reason'}
            label={'Powód anulowania produktów'}
            value={cancelProductsReasonComment}
            setValue={(comment) => setCanelProductsReasonComment(comment)}
            validator={validator}
            rule={'required'}
          />
        </div>
      )}
      <div className={style.formButtonsContainer}>
        <Button
          label={'Cofnij'}
          onClick={closeConfirmOrderModal}
          gray
        />
        <Button
          label={'Zatwierdź'}
          onClick={confirmOrder}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default ConfirmOrderSupplierForm;
