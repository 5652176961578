import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import { Checkbox, CustomTooltip, useIsMobile } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import { ReactComponent as TicIcon } from 'src/assets/icons/green-tic.svg';
import { toDateNumber, toTime } from 'src/utils/dateTime';

import style from './ProductView.module.scss';

const ProductView = ({ item, selectedProducts, setSelectedProducts, wasAddedToListData }) => {
  const { product_id, producer, name, thumbnail, image, qty, unit_name, id } = item || {};
  const isMobile = useIsMobile();
  const container = useRef(null);

  const isSelected = selectedProducts.find((prodId) => prodId === product_id);

  const clearTabindexEffectHandler = () => {
    if (container.current?.hasAttribute('tabindex')) {
      container.current?.removeAttribute('tabIndex');
    }
  };

  const selectProduct = () => {
    if (isSelected) {
      setSelectedProducts((prev) => prev.filter((id) => id !== product_id));
    } else {
      setSelectedProducts((prev) => [...prev, product_id]);
    }
  };

  const tooltipContent = (
    <div className={style.tooltipWrapper}>
      <h4>Produkt został dodany do listy</h4>
      {!!wasAddedToListData?.created_at && (
        <p>
          Data: {toDateNumber(wasAddedToListData.created_at)}, {toTime(wasAddedToListData.created_at)}
        </p>
      )}
      <Link
        to={`/lists/${wasAddedToListData?.list_uid}`}
        className={style.link}
      >
        Lista: {wasAddedToListData?.list_title}
      </Link>
    </div>
  );

  return (
    <div
      className={style.container}
      id={`list-product-${product_id}`}
      onClick={clearTabindexEffectHandler}
      ref={container}
    >
      <div className={style.checkbox}>
        <Checkbox
          onChange={selectProduct}
          value={isSelected}
          className={style.checkbox}
          reverse={isMobile}
        />
      </div>
      <div className={style.wrapper}>
        <ProductThumbnailImage
          thumbnail={thumbnail}
          image={image}
          title={name}
          id={id}
        />
        <p className={style.text}>{name}</p>
        {!!wasAddedToListData && (
          <CustomTooltip title={tooltipContent}>
            <TicIcon />
          </CustomTooltip>
        )}
      </div>
      <div className={style.wrapper}>
        <p className={style.text}>{`${qty} ${unit_name ? unit_name : ''}`}</p>
      </div>

      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Producent: </p>}
        <p className={style.text}>{producer}</p>
      </div>
    </div>
  );
};

export default ProductView;
