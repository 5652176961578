import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Card, LoadingIndicator, Textarea, useAutoSave, useRequestAbortController } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';

import { refreshListView } from '../../actions';

import style from './ListComment.module.scss';
import shared from 'styles/Shared.module.scss';

const ListComment = ({ listComment, uid, parentIsLoading }) => {
  const dispatch = useDispatch();

  const [comment, setComment] = useState(listComment);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveError, setIsSaveError] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();

  const saveComment = async () => {
    const queryParams = {
      list_comment: comment || '',
      uid
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsSaveError(false);
      setIsLoading(true);
      await ListsApi.saveListComment(queryParams, signal);
      dispatch(refreshListView());
    } catch (err) {
      notifyApiError(err);
      setIsSaveError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useAutoSave({
    first: listComment,
    second: comment,
    saveHandler: saveComment,
    dependency: [comment],
    isLoading: parentIsLoading,
    timeoutValue: 1800
  });

  if (parentIsLoading) {
    return (
      <Card className={classNames(shared.defaultWrapper, style.card)}>
        <Skeleton height={20} />
      </Card>
    );
  }

  return (
    <div className={style.box}>
      <Textarea
        style={{ minHeight: '50px' }}
        value={comment}
        setValue={setComment}
        placeholder={'Np. Interesują mnie tańsze zamienniki'}
        label={'Wiadomość do dostawców (będzie widoczna dla każdego, kto otrzyma listę do wyceny)'}
      />
      <LoadingIndicator
        isLoading={isLoading}
        hasError={isSaveError}
      />
    </div>
  );
};

export default ListComment;
