import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import cn from 'classnames';

import File from 'components/layout/FileUploader/components/File';
import InputWrapper, { getWrapperProps } from 'components/layout/InputWrapper';

import style from './FileDropzone.module.scss';

const FileDropzone = (props) => {
  const { onChange, value, ...rest } = props;
  const wrapperProps = getWrapperProps(props);

  const [showError, setShowError] = useState(false);
  const handleClearFile = () => onChange(null);

  return (
    <InputWrapper
      {...wrapperProps}
      setShowError={setShowError}
      showError={showError}
    >
      <Dropzone onDrop={(file) => onChange(file[0])}>
        {({ getRootProps, getInputProps }) => (
          <section className={cn(style.container, { [style.withFile]: value })}>
            {value ? (
              <File
                value={value}
                handleClearFile={handleClearFile}
              />
            ) : (
              <div {...getRootProps({ className: cn(style.dropzone, { [style.error]: showError }) })}>
                <input
                  {...getInputProps()}
                  {...rest}
                />
                <p className={style.label}>Przeciągnij i upuść lub kliknij, aby wybrać plik</p>
              </div>
            )}
          </section>
        )}
      </Dropzone>
    </InputWrapper>
  );
};

export default FileDropzone;
