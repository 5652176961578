import React from 'react';
import ReactSelect from 'react-select';

import { Loader } from 'components';
import InputWrapper, { getWrapperProps } from 'components/layout/InputWrapper';

import { selectStyles } from './styles';

const Select = (props) => {
  const {
    wrapperStyle,
    noOptionsMessage = 'Brak wyników',
    hideArrow,
    hiddenSelect,
    isMulti,
    isSearchable = true,
    ...rest
  } = props;
  const wrapperProps = getWrapperProps(props);

  return (
    <InputWrapper {...wrapperProps}>
      <div className={wrapperStyle}>
        <ReactSelect
          menuPlacement={'auto'}
          styles={selectStyles({ hasError: !!wrapperProps.errorMessage, hideArrow, hiddenSelect, isMulti, isSearchable })}
          noOptionsMessage={() => (props?.isLoading ? <Loader /> : noOptionsMessage)}
          loadingMessage={() => <Loader />}
          isMulti={isMulti}
          isSearchable={isSearchable}
          {...rest}
        />
      </div>
    </InputWrapper>
  );
};

export default Select;
