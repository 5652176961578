import * as types from '../actions/types';

export const initialState = {
  version: 0,
  ocrVersion: 0,
  summaryVersion: 0,
  isDefinedView: true,
  selectedCompany: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_CART_AND_CATALOG_VERSION:
      return {
        ...state,
        version: (state.version += 1)
      };
    case types.CHANGE_SUMMARY_VERSION:
      return {
        ...state,
        summaryVersion: (state.summaryVersion += 1)
      };
    case types.CHANGE_OCR_VERSION:
      return {
        ...state,
        ocrVersion: (state.ocrVersion += 1)
      };
    case types.CHANGE_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload
      };
    case types.CHANGE_VIEW_TYPE:
      return {
        ...state,
        isDefinedView: action.payload
      };
    default:
      return state;
  }
};
