export const mockValues = [1423, 2334, 3423, 2422, 2952, 5660, 3552, 3422, 6203, 2343, 3342, 2244, 5355, 4234];

export const mockMonths = [
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień',
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień'
];
