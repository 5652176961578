import React, { useState } from 'react';

import { Button, FileDropzone, Input, useValidator } from 'components';
import File from 'components/layout/FileUploader/components/File';
import InputWrapper from 'components/layout/InputWrapper';
import { notifyApiError } from 'components/layout/Toasts';

import { ExpensesApi } from 'src/api';

import style from './MasterCategoryForm.module.scss';

const defaultValue = {
  description: '',
  name: '',
  logo: null,
  new_logo_file: null
};

const ExpenseCategoryForm = ({ masterCategoryId, defaultCategory = defaultValue, closeModal, refreshCallback }) => {
  const validator = useValidator();

  const [category, setCategory] = useState(defaultCategory);
  const [isLoading, setIsLoading] = useState(false);
  const [fileReplaceEnabled, setFileReplaceEnabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    const formData = new FormData();
    formData.append('name', category.name);
    formData.append('description', category.description);
    if (defaultCategory.id) formData.append('id', defaultCategory.id);
    if (!defaultCategory.id) formData.append('tag_id', masterCategoryId);
    if (fileReplaceEnabled || !defaultCategory.id) formData.append('logo', category.new_logo_file);

    const ApiCallback = defaultCategory.id ? ExpensesApi.editCategory : ExpensesApi.addCategory;

    try {
      setIsLoading(true);
      await ApiCallback(formData);
      refreshCallback();
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCategoryChange = (name, value) =>
    setCategory((prev) => ({
      ...prev,
      [name]: value
    }));

  const dropzone = (
    <FileDropzone
      label={'Logo kategorii'}
      onChange={(file) => onCategoryChange('new_logo_file', file)}
      value={category.new_logo_file}
      id={'file'}
      rule={'required'}
      validator={validator}
      name={'file'}
    />
  );

  return (
    <div className={style.container}>
      {fileReplaceEnabled ? (
        dropzone
      ) : (
        <InputWrapper
          label={'Logo kategorii'}
          rule={'required'}
        >
          <div className={style.fileCard}>
            <File
              value={{ name: category.logo }}
              handleClearFile={() => setFileReplaceEnabled(true)}
            >
              <a onClick={() => setFileReplaceEnabled(true)}>Zmień plik z Logo </a>
            </File>
          </div>
        </InputWrapper>
      )}
      <Input
        id='name'
        name='name'
        label='Nazwa kategorii'
        value={category.name}
        onChange={(e) => onCategoryChange('name', e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <Input
        id='name'
        name='name'
        label='Krótki opis'
        value={category.description}
        onChange={(e) => onCategoryChange('description', e.target.value)}
      />
      <Button
        label={defaultCategory.id ? 'Zapisz' : 'Dodaj'}
        isLoading={isLoading}
        onClick={handleSubmit}
      />
    </div>
  );
};

export default ExpenseCategoryForm;
