import React, { useState } from 'react';

import { Button, Textarea } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';

import style from './ProductComment.module.scss';

const ProductComment = ({ product, onClose }) => {
  const [comment, setComment] = useState(product?.product_comment);
  const [isLoading, setIsLoading] = useState(false);

  const saveComment = async () => {
    const data = {
      new_warehouse_products_id: product.id,
      comment
    };

    try {
      setIsLoading(true);
      await WarehouseApi.addOrUpdateProductComment(data);
      if (onClose) onClose();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <Textarea
        id={'product-comment'}
        label={'Komentarz do produktu'}
        value={comment}
        setValue={setComment}
      />
      <Button
        label={'Zapisz'}
        onClick={saveComment}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ProductComment;
