import React, { useState } from 'react';

import { Button, Input, QtySelector } from 'components';

import { toDateNumber } from 'src/utils/dateTime';

import ChangeMenu from '../index';

import style from '../ChangeMenu.module.scss';

const ChangeValue = (props) => {
  const {
    type,
    initialValue,
    apiCallback,
    label,
    unit = '',
    productId,
    wasQtyChange,
    displayValueFormatter,
    valueWrapperClasses,
    hideArrows,
    placement,
    validator,
    disabled,
    icon
  } = props;
  const [value, setValue] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    if (apiCallback) await apiCallback(value);
    setIsActive(false);
    setIsLoading(false);
  };

  const getVisibleValue = () => {
    switch (type) {
      case 'date':
        return toDateNumber(initialValue);
      case 'text':
        return initialValue;
      default:
        return `${initialValue} ${unit || ''}`;
    }
  };

  return (
    <ChangeMenu
      placement={placement}
      value={getVisibleValue()}
      isActive={isActive}
      setIsActive={setIsActive}
      wasDataChange={wasQtyChange}
      displayValueFormatter={displayValueFormatter}
      valueWrapperClasses={valueWrapperClasses}
      disabled={disabled}
      icon={icon}
    >
      {type === 'date' ? (
        <Input
          type='date'
          label={label}
          id={productId + '-new-value'}
          name={productId + '-new-value'}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          validator={validator}
          rule={'required'}
        />
      ) : type === 'text' ? (
        <Input
          label={label}
          id={productId + '-new-value'}
          name={productId + '-new-value'}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          validator={validator}
          rule={'required'}
          wrapperStyle={style.input}
        />
      ) : (
        <QtySelector
          label={label}
          id={productId + '-new-value'}
          name={productId + '-new-value'}
          state={value}
          setState={setValue}
          hideArrows={hideArrows}
          suffix={unit}
          width={150}
        />
      )}
      <Button
        label={'Zapisz'}
        onClick={handleSave}
        className={style.save}
        isLoading={isLoading}
      />
    </ChangeMenu>
  );
};

export default ChangeValue;
