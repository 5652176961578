import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Modal, SwitchButton } from 'components';
import ProductDetailsForm from 'components/layout/ProductDetailsForm';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { UserApi } from 'src/api';
import { refreshList } from 'src/features/ProductsBase/actions';

import style from '../../CreateProduct.module.scss';

const ProductFormUser = ({ product_id = null, visible, onClose, title, type }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [closeOnSave, setCloseOnSave] = useState(true);
  const [productDetails, setProductDetails] = useState();

  const [clearProductData, setClearProductData] = useState(true);

  const getData = async () => {
    setIsLoading(true);
    try {
      const { data } = await UserApi.getProductCreationData({
        product_id: product_id
      });
      setProductDetails(data.product);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const saveProduct = async (product) => {
    const { unit, collectionQty, producer, productName, category, croppedPhotoData } = product;

    const queryParams = {
      product_id: productDetails?.id,
      unit_id: unit?.value,
      collection_amount: unit?.is_collection ? +collectionQty : 0,
      producer_id: producer.value,
      category_id: category.value,
      name: productName,
      image: croppedPhotoData
    };

    try {
      setIsLoading(true);
      const { data } = await UserApi.addProduct(queryParams);
      dispatch(refreshList());

      if (product.id || productDetails?.id) {
        notifyCommon(['Zmiany zostały zapisane.']);
        getData(data.product_id);
      } else {
        notifyCommon(['Produkt został dodany pomyślnie!']);
        setProductDetails(null);
      }

      if (closeOnSave) {
        onClose();
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    visible && getData();
  }, [product_id, visible]);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title={title}
    >
      <div className={style.container}>
        <ProductDetailsForm
          product={productDetails}
          isParentLoading={isLoading}
          clearProductData={clearProductData}
          onSave={saveProduct}
        />
        <div className={style.modalControls}>
          <div className={style.switchWrapper}>
            <SwitchButton
              value={closeOnSave}
              setValue={setCloseOnSave}
              label={'Zamknij po zapisaniu'}
              reverse
            />
          </div>
          {type === 'create-product' && (
            <div className={style.switchWrapper}>
              <SwitchButton
                value={clearProductData}
                setValue={setClearProductData}
                label={'Czyść dane po zapisaniu'}
                reverse
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ProductFormUser;
