import React, { useState } from 'react';
import { useAtomValue } from 'jotai';

import { Button, Card, Input, useIsAdmin, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { TagsApi } from 'src/api';
import { selectedCompanyAtom } from 'src/features/TagsManagement';

import styles from './AddTagForm.module.scss';

const defaultTag = {
  id: null,
  name: '',
  color: '#f6f6f0'
};

const AddTagForm = ({ tagData, refreshCallback }) => {
  const isAdmin = useIsAdmin();
  const selectedCompany = useAtomValue(selectedCompanyAtom);

  const [tag, setTag] = useState(tagData || defaultTag);
  const [isLoading, setIsLoading] = useState(false);

  const validator = useValidator();

  const onChange = (e) => {
    setTag((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const addTagHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      name: tag.name,
      color: tag.color,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      setIsLoading(true);
      const { data } = await TagsApi.addTag(params);
      if (refreshCallback) refreshCallback();

      setTag(defaultTag);
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const editTagHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      id: tag.id,
      name: tag.name,
      color: tag.color
    };

    try {
      setIsLoading(true);
      const { data } = await TagsApi.editTag(params);
      if (refreshCallback) refreshCallback();
      setTag(defaultTag);
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={styles.container}
      onSubmit={tagData ? editTagHandler : addTagHandler}
    >
      {tagData && (
        <Card info>
          <p>Zmiany dokonane w tym formularzu zaktualizują jedynie wygląd tagu. Nie wpłyną na przypisane do niego produkty.</p>
        </Card>
      )}
      <Input
        id={'name'}
        name={'name'}
        label={'Nazwa'}
        value={tag.name}
        onChange={onChange}
        validator={validator}
        rule={'required'}
      />
      <Input
        id='color'
        name='color'
        type='color'
        label='Kolor'
        placeholder='#ffffff'
        value={tag.color}
        onChange={onChange}
        validator={validator}
        rule={'required'}
      />
      <Button
        label={tagData ? 'Zapisz' : 'Dodaj'}
        isLoading={isLoading}
        className={styles.button}
      />
    </form>
  );
};

export default AddTagForm;
