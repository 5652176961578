import * as types from '../actions/types';

export const initialState = {
  version: 0,
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_COLLECTION_VERSION:
      return {
        ...state,
        version: state.version + 1
      };
    case types.START_DATA_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case types.FINISH_DATA_LOADING:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};
