import React, { useMemo, useState } from 'react';

import { OptionSwitch } from 'components';

import FlatFileExport from './components/FlatFileExport';
import FlatFileImport from './components/FlatFileImport';
import VolumeExport from './components/VolumeExport';
import VolumeImport from './components/VolumeImport';

import style from './FlatFileHandling.module.scss';

const FlatFileHandling = ({ selectedCompany, type = 'flatFile', closeModal }) => {
  const [isExport, setIsExport] = useState(true);

  const Export = useMemo(() => {
    switch (type) {
      case 'flatFile':
        return FlatFileExport;
      case 'volumes':
        return VolumeExport;
      default:
        return null;
    }
  }, [type, selectedCompany]);

  const Import = useMemo(() => {
    switch (type) {
      case 'flatFile':
        return FlatFileImport;
      case 'volumes':
        return VolumeImport;
      default:
        return null;
    }
  }, [type, selectedCompany]);

  return (
    <div className={style.container}>
      <OptionSwitch
        defaultOption={isExport}
        onChange={setIsExport}
        falseLabel={'Import'}
        trueLabel={'Export'}
      />
      {isExport ? (
        <Export selectedCompany={selectedCompany} />
      ) : (
        <Import
          selectedCompany={selectedCompany}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default FlatFileHandling;
