import React, { useEffect, useState } from 'react';

import { PageHeader, PaginationNav, SeoHelmet, useRequestAbortController } from 'components';
import { headerTextPlaceholder } from 'components/layout/PageHeader/placeholders';
import { notifyApiError } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import pages from 'src/dictionaries/pages.json';
import { getCorrectFormOfResultsLabel } from 'src/utils/helpers';
import query from 'src/utils/query';

import ClientsList from './components/ClientsList';

import style from './SupplierClients.module.scss';

const SupplierClients = (props) => {
  const params = query(props);
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [itemsQty, setItemsQty] = useState(0);
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState(params.get('sort_by'));
  const [currentPage, setCurrentPage] = useState(params.get('page'));
  const [querySearch, setQuerySearch] = useState(params.get('search'));
  const [sortOrder, setSortOrder] = useState(params.get('sort_order'));
  const [abortController, setNewController] = useRequestAbortController();

  useEffect(() => {
    getData();
  }, [currentPage, querySearch, perPage, sortBy, sortOrder]);

  const getData = async () => {
    if (abortController) abortController.abort();
    const signal = setNewController();

    const queryProps = {
      page: currentPage || 1,
      perPage: perPage,
      search: querySearch,
      ...(sortBy && { sort_by: sortBy }),
      ...(sortOrder && { sort_order: sortOrder })
    };

    try {
      setIsLoading(true);
      const { data } = await SupplierApi.getClients(queryProps, signal);
      setPageData(data.data);
      setItemsQty(data.total);
      setPageQty(data?.last_page);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(params.get('page'));
    setQuerySearch(params.get('search'));
    setSortBy(params.get('sort_by'));
    setSortOrder(params.get('sort_order'));
  }, [params]);

  return (
    <>
      <SeoHelmet title={pages.supplierClients.title} />
      <PageHeader
        name={pages.supplierClients.title}
        text={isLoading ? headerTextPlaceholder() : getCorrectFormOfResultsLabel(itemsQty)}
        wrapOnMobile
      />
      <div className={style.container}>
        <ClientsList
          list={pageData}
          params={params}
          isLoading={isLoading}
        />
        <PaginationNav
          onClick={(number) => setCurrentPage(number)}
          params={params}
          pagesQty={pageQty}
          setPerPage={setPerPage}
          defaultQty={perPage}
        />
      </div>
    </>
  );
};

export default SupplierClients;
