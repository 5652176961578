import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const UseUnreadNotifications = (types = []) => {
  const notificationsStore = useSelector((state) => state.notifications);

  return useMemo(() => {
    const { notifications, oldNotifications } = notificationsStore;
    const ntfs = [...notifications, ...oldNotifications];
    return ntfs.filter((ntf) => !ntf.read_at).filter((ntf) => types.includes(ntf.type));
  }, [notificationsStore.notifications, notificationsStore.oldNotifications]);
};

export default UseUnreadNotifications;
