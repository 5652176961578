import React from 'react';

import { SaveButton, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WholesalersApi } from 'src/api';

import styles from './WholesalerSupplierDetailsThumbnail.module.scss';

const WholesalerSupplierDetailsThumbnail = (props) => {
  const { id, email, company_name, shipping_price, free_shipping_price, wholesaler_name, index, refetchSuppliers } = props;
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const handleDissociateWholesaler = async () => {
    try {
      const { data } = await WholesalersApi.dissociateWholesalerFromSupplier(id);
      notifyCommon([data.message]);
      refetchSuppliers();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const openDissociateWholesalerConfirm = () => {
    handleOpenModalConfirm({
      message: `Czy na pewno chcesz usunąć dostawcę ${email} z hurtowni ${wholesaler_name}?`,
      content: `Właścicielem tego dostawcy jest firma ${company_name}. Zatwierdzenie tej akcji spowoduje usunięcie przypisania hurtownii tylko w przypadku tego konkretnego dostawcy przypisanego do w/w właściciela.`,
      handleConfirm: handleDissociateWholesaler
    });
  };

  return (
    <>
      <div className={styles.container}>
        <div>{index}.</div>
        <div>
          <span
            className={styles.companyName}
            title={company_name}
          >
            {company_name || '-'}
          </span>
        </div>
        <div>{shipping_price} zł</div>
        <div>{free_shipping_price} zł</div>
        <div>
          <SaveButton
            type={'delete'}
            onClick={openDissociateWholesalerConfirm}
          />
        </div>
      </div>
      {renderedModalConfirm}
    </>
  );
};

export default WholesalerSupplierDetailsThumbnail;
