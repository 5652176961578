import * as Sentry from '@sentry/react';
import axios from 'axios';
import qs from 'qs';

import store from 'src/store/configureStore';

import { apiErrorHandler, isNetworkError, throttledNotifyNetworkError } from './utils';

const WP_BASE_URL = 'https://support.avalio.io/wp-json/wp/v2';
export const wpAxiosInstance = axios.create({
  baseURL: WP_BASE_URL
});

const client = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en'
  }
});

client.interceptors.request.use((config) => {
  const { selectedCompanyId, adminId, access } = store.getState().auth;

  config.headers.Authorization = `Bearer ${access}`;
  if (adminId) config.headers.isAdmin = adminId;
  config.headers.CurrentCompany = selectedCompanyId;

  return config;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.message !== 'canceled') {
      const errorMessage = apiErrorHandler(error);
      Sentry.captureMessage(errorMessage[0]);
    }
    if (error?.message !== 'canceled' && isNetworkError(error)) {
      throttledNotifyNetworkError();
    }

    return Promise.reject(error);
  }
);

class ApiClient {
  static get(url, params, customConfigs) {
    const configs = {
      params,
      paramsSerializer: qs.stringify,
      ...customConfigs
    };
    return client.get(url, configs);
  }
  static post(url, data, customConfigs) {
    return client.post(url, data, customConfigs);
  }
  static put(url, data, customConfigs) {
    return client.put(url, data, customConfigs);
  }
  static patch(url, data, customConfigs) {
    return client.patch(url, data, customConfigs);
  }
  static delete(url, params, customConfigs) {
    const configs = {
      params,
      paramsSerializer: qs.stringify,
      ...customConfigs
    };
    return client.delete(url, configs);
  }
}

export default ApiClient;
