import React from 'react';
import { Grid, Placeholder, Segment } from 'semantic-ui-react';

import style from './ThumbnailPlaceholder.module.scss';
import shared from 'styles/Shared.module.scss';

const DataPlaceholder = () => (
  <Segment
    raised
    className={style.container}
  >
    <Grid
      columns={2}
      stackable
    >
      <Grid.Column className={style.columnWrapper}>
        <Placeholder className={style.leftWrapper}>
          <Placeholder.Paragraph>
            <Placeholder.Line length={'long'} />
          </Placeholder.Paragraph>
          <Placeholder.Paragraph>
            <Placeholder.Line length='short' />
            <Placeholder.Line length='medium' />
          </Placeholder.Paragraph>
        </Placeholder>
      </Grid.Column>
      <Grid.Column className={style.columnWrapper}>
        <Placeholder className={style.rightWrapper}>
          <Placeholder.Paragraph>
            <Placeholder.Line length='very long' />
          </Placeholder.Paragraph>
        </Placeholder>
      </Grid.Column>
    </Grid>
  </Segment>
);

const ThumbnailPlaceholder = ({ quantity = 7 }) => {
  const getElements = () => {
    let elements = [];
    for (let i = 0; i < quantity; i++) {
      elements.push(<DataPlaceholder key={'placeholder-data-' + i} />);
    }
    return elements;
  };

  return (
    <div
      className={shared.listContent}
      style={{ gap: '16px' }}
    >
      {getElements()}
    </div>
  );
};

export default ThumbnailPlaceholder;
