import React from 'react';

import { Tag } from 'components';

import { getFormattedAmount } from 'src/utils/helpers';

import style from './RefPrice.module.scss';

const RefPrice = ({ price, quantity = 1 }) => {
  return (
    <div className={style.container}>
      {quantity > 0 && price ? <span>{getFormattedAmount(price * quantity)}</span> : <span className={style.empty}>Brak</span>}
      <Tag
        value={'Cena z listy'}
        color={'yellow'}
      />
    </div>
  );
};

export default RefPrice;
