import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Accordion, Loader, ProcessStepper, Tag } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OrdersApi } from 'src/api';
import { getOrderTrackingCurrentStep } from 'src/utils/helpers';

import style from './OrderTracing.module.scss';
import shared from 'styles/Shared.module.scss';

const OrderTracing = ({ order, children }) => {
  const { trackingnumber, trackingurl } = order;

  const [isLoading, setIsLoading] = useState(false);
  const [wasAccordionOpened, setWasAccordionOpened] = useState(false);
  const [trackingData, setTrackingData] = useState(null);

  const title = <h3 className={classNames(shared.tableCategoryTitle, shared.large, shared.noPadding)}>Śledzenie przesyłki</h3>;

  const getTrackingData = async () => {
    if (!trackingnumber) return;

    setIsLoading(true);
    try {
      const { data } = await OrdersApi.getShipmentDetails({ shipment_number: trackingnumber });
      setTrackingData({
        carrier: data?.carrier_name,
        trackingUrl: data?.carrier_url,
        data: data?.data
      });
    } catch (err) {
      notifyApiError(err);
      setTrackingData(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (wasAccordionOpened) getTrackingData();
  }, [trackingnumber, wasAccordionOpened]);

  const stepperData = useMemo(() => {
    if (!trackingData?.data) return null;
    const stepper = getOrderTrackingCurrentStep(trackingData.data);
    return stepper;
  }, [trackingData]);

  if (!trackingnumber) return null;

  return (
    <>
      <Accordion
        id={`order-tracking-${order.id}`}
        section
        title={
          <div className={style.accordionHeader}>
            {title} {isLoading && <Loader className={style.loader} />}
          </div>
        }
        defaultWrapped
        setWasOpened={setWasAccordionOpened}
        extraDeps={[order, trackingData]}
      >
        <div className={classNames(style.tracking, { [style.withStepper]: !!trackingData })}>
          {trackingData && (
            <div className={style.stepper}>
              <ProcessStepper
                {...stepperData}
                smaller
                direction={'vertical'}
              />
            </div>
          )}

          <div className={style.box}>
            <div className={style.column}>
              <p className={style.label}>Przewoźnik:</p>
              {trackingData?.carrier ? <p className={style.value}>{trackingData.carrier}</p> : <Tag value={'Nieznany'} />}
            </div>
            <div className={style.column}>
              <p className={style.label}>Numer listu przewozowego:</p>
              {trackingnumber ? <p className={style.value}>{trackingnumber}</p> : <Tag value={'Nie załączono'} />}
            </div>
            <div className={style.column}>
              <p className={style.label}>Link do śledzenia przesyłki:</p>
              {trackingData?.trackingUrl ? (
                <a
                  className={classNames(style.value, style.link)}
                  href={trackingData.trackingUrl}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  {trackingData.trackingUrl}
                </a>
              ) : (
                <Tag value={'Nie załączono'} />
              )}
            </div>
          </div>
        </div>

        {children}
      </Accordion>
    </>
  );
};

export default OrderTracing;
