import React from 'react';

import { RelativeTime, SaveButton, useModalConfirm } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ServicesApi } from 'src/api';

import style from './AttachmentThumbnail.module.scss';

const AttachmentThumbnail = ({ serviceId, file_name, created_at, id, refreshCallback }) => {
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const deleteAttachment = async () => {
    const params = {
      service_id: serviceId,
      service_file_id: id
    };

    try {
      await ServicesApi.deleteServiceFile(params);
      if (refreshCallback) refreshCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteAttachmentHandler = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć załącznik?',
      handleConfirm: deleteAttachment
    });
  };

  const handleDownloadAttachment = (url) => {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', file_name);

    document.body.appendChild(link);
    link.click();
  };

  const downloadAttachment = async () => {
    const params = {
      service_id: serviceId,
      service_file_id: id,
      file_name
    };

    try {
      const { data, headers } = await ServicesApi.downloadServiceFile(params);
      const url = window.URL.createObjectURL(new Blob([data], { type: headers['content-type'] }));
      handleDownloadAttachment(url);
    } catch (err) {
      notifyApiError(err);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.first}>
        <p>{file_name}</p>
      </div>
      <div className={style.wrapper}>
        <RelativeTime date={created_at} />
      </div>
      <div className={style.wrapper}>
        <SaveButton
          type={'download'}
          onClick={downloadAttachment}
        />
        <SaveButton
          type={'delete'}
          onClick={deleteAttachmentHandler}
        />
      </div>
      {renderedModalConfirm}
    </div>
  );
};

export default AttachmentThumbnail;
