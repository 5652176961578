import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { AllOrdersSumBar, DeclinedReason, LoaderGlobal, PageHeader, SeoHelmet } from 'components';
import OrderSuppliersTable from 'components/layout/OrderSuppliersTable';
import { notifyApiError, notifyDanger } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { getApprovalStatusTag } from 'src/utils/helpers';

import ApproveOrder from '../../components/ApproveOrder';
import CompanyDetails from '../../components/CompanyDetails';
import ContactWithClient from '../../components/ContactWithClient';
import ApprovalCart from '../../components/OrderCart/containers/ApprovalCart';
import OrderData from '../../components/OrderData';

import style from '../../orderConfirmations.module.scss';

const OrderApproval = () => {
  const { token } = useParams();
  const history = useHistory();
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);

  const getData = async () => {
    try {
      const { data } = await SupplierApi.orderApprovalData(token);
      setPageData(data);
    } catch (err) {
      notifyApiError(err);
      notifyDanger(['Wystąpił problem z zamówieniem. Skontaktuj się z nami, w celu rozwiązania problemów.']);
      history.push('/dashboard');
    }
  };

  const getDataWithLoading = async () => {
    setIsLoading(true);
    await getData();
    setIsLoading(false);
  };

  useEffect(() => {
    getDataWithLoading();
  }, [history.location.pathname]);

  if (isLoading) {
    return <LoaderGlobal />;
  }

  return (
    <>
      <SeoHelmet title={`Akceptacja zamówienia #${pageData?.public_id}`} />
      <PageHeader
        name={`Akceptacja zamówienia #${pageData?.public_id}`}
        text={getApprovalStatusTag({ status: pageData?.status })}
        textCenter
      />
      <ContactWithClient
        title={'Skontaktuj się z kliniką'}
        isOpen={isContactOpen}
        setIsOpen={setIsContactOpen}
        details={pageData?.order?.shipping_details}
      />
      <div className={style.container}>
        <OrderData pageData={pageData} />
        <ApproveOrder
          token={token}
          status={pageData.status}
          refreshDataCallback={getData}
        />
        <DeclinedReason declineReason={pageData.decline_reason} />
        <CompanyDetails
          company={pageData?.invoice_details}
          sender={pageData?.sender}
        />
        {/* TODO replace with data*/}
        <OrderSuppliersTable
          order={pageData}
          defaultOpen
        />
        {pageData?.suppliers?.map((cart) => (
          <ApprovalCart
            key={cart.id}
            forApprova
            cart={cart}
            refreshDataCallback={getData}
            token={token}
          />
        ))}
        <AllOrdersSumBar
          suppliersNumber={pageData?.suppliers?.length}
          isHidden={false}
          totalSum={pageData?.total_sum}
          shipPrice={pageData?.shipping_sum}
          status={pageData?.status}
        />
      </div>
    </>
  );
};

export default OrderApproval;
