import * as types from '../actions/types';

const initialState = {
  products: [],
  version: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SELECT_PRODUCT: {
      return {
        ...state,
        products: [action.payload, ...state.products]
      };
    }
    case types.DELETE_PRODUCT: {
      const filteredProducts = state.products.filter((prod) => prod !== action.payload);
      return {
        ...state,
        products: filteredProducts
      };
    }
    case types.SELECT_PRODUCTS_ARRAY: {
      return {
        ...state,
        products: action.payload
      };
    }
    case types.DELETE_ALL: {
      return {
        ...state,
        products: []
      };
    }
    case types.REFRESH_LIST_VIEW: {
      return {
        ...state,
        version: state.version + 1
      };
    }
    default:
      return state;
  }
};
