export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';

export const SET_OLD_NOTIFICATIONS = 'SET_OLD_NOTIFICATIONS';

export const SET_NOTIFICATION_AS_READ = 'SET_NOTIFICATION_AS_READ';
export const SET_ALL_NOTIFICATIONS_AS_READ = 'SET_ALL_NOTIFICATIONS_AS_READ';

export const CHANGE_VERSION = 'CHANGE_VERSION';

export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';

export const SET_DEFAULT_STATE = 'SET_DEFAULT_STATE';
