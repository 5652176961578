import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import { Tag, useCompany } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { PermissionsApi } from 'src/api';
import { userPermissions, userPermissionsList } from 'src/constants/enums';
import { getAreAllPermissionsSelected, getFormattedPermissionObject, getPermissionsObject } from 'src/utils/helpers';

import PermCheckbox from '../PermCheckbox';

import style from './UserRowForm.module.scss';

const { lists, valuations, company, orders, analytics, warehouse, offer_catalog, invoices, products, suppliers, services } =
  userPermissions;

const UserRowForm = ({ user, isOwner }) => {
  const companyData = useCompany();
  const arr = useMemo(() => (isOwner ? userPermissionsList : user?.permissions.map((perm) => perm.name)), [isOwner, user]);
  const getIsInherit = useCallback((name) => user.permissions?.find((perm) => perm.name === name)?.inherited, [user]);
  const [isLoading, setIsLoading] = useState(false);
  const [areDisabled, setAreDisabled] = useState(isOwner);
  const [permissions, setPermissions] = useState(getPermissionsObject(arr || []));
  const areAllSelected = useMemo(() => getAreAllPermissionsSelected(permissions), [permissions]);

  useEffect(() => {
    setAreDisabled(permissions[company.admin]);
  }, [permissions[company.admin]]);

  const changePermissionHandler = async (permission, grant) => {
    const data = {
      permission,
      user_id: user.id,
      company_id: companyData.id
    };

    const apiHandler = grant ? PermissionsApi.grantPermission : PermissionsApi.revokePermission;

    try {
      setIsLoading(true);
      await apiHandler(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onPermissionChange = async (e, extraPermissions = []) => {
    const permissionsArr = [e.target.name, ...extraPermissions];
    setPermissions((prev) => ({
      ...prev,
      ...getFormattedPermissionObject(permissionsArr, e.target.checked)
    }));
    changePermissionHandler(permissionsArr, e.target.checked);
  };

  const containerStyles = cn(style.container, {
    [style.owner]: isOwner,
    [style.extended]: companyData?.suppliers_management_enabled
  });

  return (
    <div className={containerStyles}>
      <div className={style.nameWrapper}>
        <p className={style.name}>{user?.name}</p>
        {!isOwner && <p className={style.email}>{user?.email}</p>}
        {isOwner && <Tag value={'Właścicel konta'} />}
      </div>
      <div className={cn(style.wrapper, style.single)}>
        <PermCheckbox
          isSelected={areAllSelected}
          name={'all-permissions'}
          revokeExtraPermissions={userPermissionsList.slice(0, -1)}
          grantExtraPermissions={userPermissionsList.slice(0, -1)}
          onPermissionChange={onPermissionChange}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
      </div>
      <div className={style.wrapper}>
        <PermCheckbox
          name={lists.read}
          revokeExtraPermissions={[lists.delete, lists.create]}
          onPermissionChange={onPermissionChange}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        <PermCheckbox
          name={lists.create}
          grantExtraPermissions={[lists.read]}
          revokeExtraPermissions={[lists.delete]}
          onPermissionChange={onPermissionChange}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        <PermCheckbox
          name={lists.delete}
          grantExtraPermissions={[lists.read, lists.create]}
          onPermissionChange={onPermissionChange}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
      </div>

      <div className={cn(style.wrapper, { [style.extended]: companyData?.suppliers_management_enabled })}>
        <PermCheckbox
          name={valuations.read}
          revokeExtraPermissions={[valuations.cancel, valuations.create]}
          onPermissionChange={onPermissionChange}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        {!!companyData?.suppliers_management_enabled && (
          <PermCheckbox
            name={valuations.create}
            grantExtraPermissions={[valuations.read]}
            onPermissionChange={onPermissionChange}
            permissions={permissions}
            areDisabled={areDisabled}
            isLoading={isLoading}
            getIsInherit={getIsInherit}
          />
        )}
        <PermCheckbox
          name={valuations.create}
          grantExtraPermissions={[valuations.read]}
          onPermissionChange={onPermissionChange}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        <PermCheckbox
          name={valuations.cancel}
          grantExtraPermissions={[valuations.read, valuations.create]}
          onPermissionChange={onPermissionChange}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
      </div>

      <div className={style.wrapper}>
        <PermCheckbox
          name={orders.read}
          revokeExtraPermissions={[orders.cancel, orders.create]}
          onPermissionChange={onPermissionChange}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        <PermCheckbox
          name={orders.create}
          grantExtraPermissions={[orders.read]}
          revokeExtraPermissions={[orders.cancel]}
          onPermissionChange={onPermissionChange}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        <PermCheckbox
          name={orders.cancel}
          grantExtraPermissions={[orders.read, orders.create]}
          onPermissionChange={onPermissionChange}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
      </div>

      <div className={cn(style.wrapper, style.double)}>
        <PermCheckbox
          name={offer_catalog.read}
          revokeExtraPermissions={[offer_catalog.menage]}
          onPermissionChange={onPermissionChange}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        <PermCheckbox
          name={offer_catalog.menage}
          grantExtraPermissions={[offer_catalog.read]}
          onPermissionChange={onPermissionChange}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
      </div>

      <div className={cn(style.wrapper, style.single)}>
        <PermCheckbox
          name={analytics.read}
          onPermissionChange={onPermissionChange}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
      </div>

      <div className={style.wrapper}>
        <PermCheckbox
          name={warehouse.read}
          onPermissionChange={onPermissionChange}
          revokeExtraPermissions={[warehouse.write, warehouse.delete]}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        <PermCheckbox
          name={warehouse.write}
          onPermissionChange={onPermissionChange}
          grantExtraPermissions={[warehouse.read]}
          revokeExtraPermissions={[warehouse.delete]}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        <PermCheckbox
          name={warehouse.delete}
          onPermissionChange={onPermissionChange}
          grantExtraPermissions={[warehouse.write, warehouse.read]}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
      </div>

      <div className={style.wrapper}>
        <PermCheckbox
          name={invoices.read}
          onPermissionChange={onPermissionChange}
          revokeExtraPermissions={[invoices.write, invoices.delete]}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        <PermCheckbox
          name={invoices.write}
          onPermissionChange={onPermissionChange}
          grantExtraPermissions={[invoices.read]}
          revokeExtraPermissions={[invoices.delete]}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        <PermCheckbox
          name={invoices.delete}
          onPermissionChange={onPermissionChange}
          grantExtraPermissions={[invoices.write, invoices.read]}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
      </div>

      <div className={style.wrapper}>
        <PermCheckbox
          name={products.read}
          onPermissionChange={onPermissionChange}
          revokeExtraPermissions={[products.write, products.delete]}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        <PermCheckbox
          name={products.write}
          onPermissionChange={onPermissionChange}
          grantExtraPermissions={[products.read]}
          revokeExtraPermissions={[products.delete]}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        <PermCheckbox
          name={products.delete}
          onPermissionChange={onPermissionChange}
          grantExtraPermissions={[products.write, products.read]}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
      </div>

      <div className={style.wrapper}>
        <PermCheckbox
          name={suppliers.read}
          onPermissionChange={onPermissionChange}
          revokeExtraPermissions={[suppliers.write, suppliers.delete]}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        <PermCheckbox
          name={suppliers.write}
          onPermissionChange={onPermissionChange}
          grantExtraPermissions={[suppliers.read]}
          revokeExtraPermissions={[suppliers.delete]}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        <PermCheckbox
          name={suppliers.delete}
          onPermissionChange={onPermissionChange}
          grantExtraPermissions={[suppliers.write, suppliers.read]}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
      </div>

      <div className={style.wrapper}>
        <PermCheckbox
          name={services.read}
          onPermissionChange={onPermissionChange}
          revokeExtraPermissions={[services.write, services.delete]}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        <PermCheckbox
          name={services.write}
          onPermissionChange={onPermissionChange}
          grantExtraPermissions={[services.read]}
          revokeExtraPermissions={[services.delete]}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
        <PermCheckbox
          name={services.delete}
          onPermissionChange={onPermissionChange}
          grantExtraPermissions={[services.write, services.read]}
          permissions={permissions}
          areDisabled={areDisabled}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
      </div>

      <div className={cn(style.wrapper, style.single)}>
        <PermCheckbox
          name={company.admin}
          onPermissionChange={onPermissionChange}
          grantExtraPermissions={userPermissionsList}
          permissions={permissions}
          areDisabled={isOwner}
          isLoading={isLoading}
          getIsInherit={getIsInherit}
        />
      </div>
    </div>
  );
};

export default UserRowForm;
