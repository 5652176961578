import React from 'react';
import classnames from 'classnames';

import style from './ProgressLine.module.scss';

const ProgressLine = ({
  maxValue = 100,
  currentValue = 10,
  prefix = '',
  suffix = 'produktów',
  type,
  fullWidth,
  alignToLeft,
  className
}) => {
  return (
    <div
      className={classnames(style.container, className, {
        [style.fullWidth]: fullWidth,
        [style.alignToLeft]: alignToLeft
      })}
    >
      <p className={style.text}>{`${prefix} ${currentValue} / ${maxValue} ${suffix}`}</p>
      <div className={style.wrapper}>
        <span
          className={classnames(style.valueBar, {
            [style.green]: type === 'green'
          })}
          style={{ width: `${((currentValue * 100) / maxValue).toFixed(0)}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressLine;
