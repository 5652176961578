import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import classnames from 'classnames';

import { AsyncSelect, Button, QtySelector, useRequestAbortController, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';
import { refreshOrder } from 'src/features/OrdersView/actions';
import { scrollToErrors } from 'src/utils/helpers';

import style from '../../AddProductToList.module.scss';
import shared from 'styles/Shared.module.scss';

const AddToListForm = ({ products, onClose, onSuccess, selectedProducts, productId, orderID, showProductsInput }) => {
  const dispatch = useDispatch();
  const validator = useValidator();
  const containerRef = useRef();
  const [isLoading, setIsLoading] = useState();
  const [selectedList, setSelectedList] = useState();
  const [productQuantity, setProductQuantity] = useState(1);
  const [abortController, setNewController] = useRequestAbortController();

  const [productsQuantity, setProductsQuantity] = useState({});

  useEffect(() => {
    setSelectedList(null);
    validator.hideMessages();
  }, [onClose]);

  useEffect(() => {
    disableBodyScroll(containerRef?.current);

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const selectedProductsData = useMemo(
    () =>
      selectedProducts?.map((id) => {
        const product = products?.find(({ product_id, id: productId }) => product_id === id || productId === id);
        return {
          ...product,
          id,
          quantity: product?.qty || product?.quantity
        };
      }),
    [selectedProducts]
  );

  const storeProductToList = async () => {
    const getProductQuantity = ({ id, quantity }) => {
      return { id, quantity: showProductsInput ? productsQuantity[id] : quantity };
    };

    const data = {
      order_id: orderID,
      uid: selectedList?.uid,
      products: selectedProductsData?.map(getProductQuantity),
      product_id: productId,
      quantity: productQuantity
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      await ListsApi.storeToList(data, signal);
      notifyCommon(['Dodano produkt do listy.']);
      dispatch(refreshOrder());
      if (onSuccess) onSuccess();
      if (onClose) onClose();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      scrollToErrors();
      return null;
    }

    storeProductToList();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={shared.defaultWrapper}
    >
      <div className={classnames(style.wrapper, { [style.cancelledWrapper]: products, [style.wide]: showProductsInput })}>
        <AsyncSelect
          label={'Lista'}
          placeholder={'Wybierz listę...'}
          value={selectedList}
          onChange={setSelectedList}
          apiCallback={ListsApi.getLists}
          valueKey={'name'}
          labelFormat={(list) => `${list.name} (${list.company_name ?? list.username})`}
          validator={validator}
          rule={'required'}
          id={'list-select'}
          name={'list-select'}
        />
        {!products && (
          <QtySelector
            label={'Ilość'}
            id={'product-quantity'}
            name={'product-quantity'}
            state={productQuantity}
            setState={setProductQuantity}
            validator={validator}
            rule={'required|only_positive'}
          />
        )}
      </div>
      {showProductsInput && (
        <div
          className={style.box}
          ref={containerRef}
        >
          {selectedProductsData.map((prod, index) => (
            <div
              className={style.product}
              key={prod.id}
            >
              <p>
                {index + 1}. {prod.name}
              </p>
              <QtySelector
                id={prod.id + '-qty'}
                name={prod.id + '-qty'}
                state={productsQuantity[prod.id]}
                setState={(value) => setProductsQuantity((prev) => ({ ...prev, [prod.id]: value }))}
                width={170}
                suffix={prod.unit_name}
                validator={validator}
                rule={'required|only_positive'}
              />
            </div>
          ))}
        </div>
      )}
      <Button
        label='Dodaj do listy'
        type={'submit'}
        isLoading={isLoading}
        className={style.button}
      />
    </form>
  );
};

export default AddToListForm;
