import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'antd';
import classNames from 'classnames';
import cn from 'classnames';

import { CustomTooltip, Menu } from 'components';

import { toDate, toTime } from 'src/utils/dateTime';
import { handleNumeralWords } from 'src/utils/helpers';

import style from './Thumbnail.module.scss';

const Thumbnail = ({
  item,
  menu = [],
  children,
  showTime,
  link,
  small,
  namePrefix = '',
  status,
  extraInfo,
  title,
  hideOwner,
  datePrefix,
  areDataHidden,
  notificationCount
}) => {
  const { name, created_at, company_name, username, order_invoices_count, suppliers } = item || {};

  const thumbnailMenu = !!menu?.length && (
    <div className={style.menuWrapper}>
      <Menu
        className={style.menu}
        actions={menu}
      />
    </div>
  );

  const thumbnailName = (
    <Link
      to={{ pathname: link, state: { from: 'thumbnail' } }}
      className={style.linkWrapper}
    >
      {areDataHidden && <p className={style.name}>{namePrefix}</p>}
      <p className={classNames(style.name, { [style.blur]: areDataHidden })}>
        {namePrefix} {areDataHidden ? 'Dane klienta ukryte' : title ?? name}
      </p>
      <Badge count={notificationCount} />
    </Link>
  );

  const thumbnailCreatedAt = created_at && (
    <>
      {!!datePrefix && datePrefix}
      {toDate(created_at)}
      {!!showTime && `, ${toTime(created_at)}`}
    </>
  );

  const tooltipContent = (
    <>
      <p>Utworzono przez:</p>
      <p>{username}</p>
    </>
  );

  const thumbnailOwner = (
    <p className={style.owner}>
      <CustomTooltip title={tooltipContent}>
        {company_name || <span className={style.noOwner}>Brak właścicela</span>}
      </CustomTooltip>
    </p>
  );

  return (
    <article className={classNames(style.container, { [style.small]: small })}>
      {thumbnailMenu}
      <div className={style.data}>
        {thumbnailName}
        <span className={style.text}>
          {thumbnailCreatedAt}
          {!hideOwner && thumbnailOwner}
        </span>
        {extraInfo && <span className={style.text}>{extraInfo}</span>}
        {order_invoices_count >= 0 && suppliers?.length > 0 && (
          <span className={style.text}>
            {order_invoices_count === 0
              ? 'Brak dodanych faktur '
              : `Faktura dodana do ${order_invoices_count} z ${suppliers.length} ${handleNumeralWords(
                  ['koszyka', 'koszyków', 'koszyków'],
                  suppliers.length
                )}.`}
          </span>
        )}
        {!!status && <div className={style.status}>{status}</div>}
      </div>
      {children && (
        <div className={cn(style.wrapper, { [style.withMenu]: !!menu })}>
          <div className={style.tags}>{children}</div>
        </div>
      )}
    </article>
  );
};

export default Thumbnail;
