import { createApiAction } from 'src/api/actions';
import ApiClient from 'src/api/client';
import { AccountsRoutes, SupplierRoutes } from 'src/api/routes';
import { setLocalStorageItem, setSessionStorageItem } from 'src/utils/storage';

import * as types from './types';

export const logout = () => (dispatch) => {
  dispatch({
    type: types.LOGOUT
  });
};

export const loadUserToken = (token, admin_id) => (dispatch) => {
  setSessionStorageItem('isAdminView', true);
  setSessionStorageItem('adminId', admin_id);
  dispatch({
    type: types.LOAD_USER_TOKEN,
    payload: { token, admin_id }
  });
  dispatch(getUserData());
};

export const changeCurrentCompany = (id) => (dispatch) => {
  setLocalStorageItem('selectedCompanyId', id);
  dispatch({
    type: types.CHANGE_CURRENT_COMPANY,
    payload: id
  });
  dispatch(getUserData());
};

class AccountsApi {
  static postLogin(data) {
    return ApiClient.post(AccountsRoutes.LOGIN, data);
  }
  static postRegister(data) {
    return ApiClient.post(AccountsRoutes.REGISTER, data);
  }
  static registerNewMember(data) {
    return ApiClient.post(AccountsRoutes.REGISTER_MEMBER, data);
  }
  static getUserData() {
    return ApiClient.get(AccountsRoutes.GET_USER_DATA);
  }
}

class SupplierApi {
  static registerSupplierAccount(data) {
    return ApiClient.post(SupplierRoutes.REGISTER_SUPPLIER_ACCOUNT, data);
  }
}

// /\
// TODO
// https://stackoverflow.com/questions/66967499/can-i-use-react-lazy-inside-a-function
// try this to fix "using before initialisation" bug
// \/

export const login = createApiAction(AccountsApi.postLogin, types.LOGIN_REQUEST, types.LOGIN_SUCCESS, types.LOGIN_FAILURE);

export const register = createApiAction(
  AccountsApi.postRegister,
  types.REGISTER_REQUEST,
  types.REGISTER_SUCCESS,
  types.REGISTER_FAILURE
);

export const registerNewMember = createApiAction(
  AccountsApi.registerNewMember,
  types.REGISTER_NEW_MEMBER_REQUEST,
  types.REGISTER_NEW_MEMBER_SUCCESS,
  types.REGISTER_NEW_MEMBER_FAILURE
);

export const getUserData = createApiAction(
  AccountsApi.getUserData,
  types.GET_USER_DATA_REQUEST,
  types.GET_USER_DATA_SUCCESS,
  types.GET_USER_DATA_FAILURE
);

export const refreshUserData = createApiAction(
  AccountsApi.getUserData,
  types.REFRESH_USER_DATA_REQUEST,
  types.REFRESH_USER_DATA_SUCCESS,
  types.REFRESH_USER_DATA_FAILURE
);

export const registerSupplierAccount = createApiAction(
  SupplierApi.registerSupplierAccount,
  types.SUPPLIER_REGISTER_REQUEST,
  types.SUPPLIER_REGISTER_SUCCESS,
  types.SUPPLIER_REGISTER_FAILURE
);
