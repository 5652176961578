import React, { useState } from 'react';

import { AsyncSelect, LoadingIndicator } from 'components';
import useAutoSave from 'components/hooks/useAutoSave';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OCRInvoicesApi, ProductsApi } from 'src/api';
import searchPlaceholder from 'src/assets/img/search-image-placeholder.png';

import style from './ConnectProducts.module.scss';

const getSelectedProduct = (id, details) => (id ? { label: details?.name, value: id, ...details } : null);

const ConnectProducts = ({ product, refreshCallback, isDisabled }) => {
  const { product_id, product_details } = product || {};

  const [selectedProduct, setSelectedProduct] = useState(getSelectedProduct(product_id, product_details));
  const [isSaveError, setIsSaveError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const linkSelectedProduct = async () => {
    const queryParams = {
      invoice_product_id: product.id,
      product_id: selectedProduct?.value
    };

    try {
      setIsLoading(true);
      await OCRInvoicesApi.linkInvoiceProduct(queryParams);
      if (isSaveError) setIsSaveError(false);
      notifyCommon([`Produkt "${selectedProduct.label}" został przypisany.`]);
      if (refreshCallback) refreshCallback();
    } catch (err) {
      notifyApiError(err);
      setIsSaveError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useAutoSave({
    saveHandler: linkSelectedProduct,
    first: selectedProduct?.value || null,
    isDisabled: !selectedProduct,
    second: product_id,
    timeoutValue: 1000,
    dependency: [selectedProduct]
  });

  return (
    <div className={style.container}>
      <ProductThumbnailImage
        thumbnail={selectedProduct?.thumbnail || searchPlaceholder}
        image={selectedProduct?.image}
        title={selectedProduct?.label}
        id={selectedProduct?.value}
        size={'tinySpecial'}
      />
      <AsyncSelect
        id={'select-product'}
        value={selectedProduct}
        onChange={setSelectedProduct}
        apiCallback={ProductsApi.getProducts}
        customRadius={'0 6px 6px 0'}
        valueKey={'id'}
        labelKey={'name'}
        placeholder={'Wybierz produkt...'}
        productSearch
        isDisabled={isDisabled}
        isTiny
        rule={'required'}
      />
      <LoadingIndicator
        isLoading={isLoading}
        hasError={isSaveError}
        aboveInput
      />
    </div>
  );
};

export default ConnectProducts;
