import React, { useState } from 'react';

import { Button, Textarea, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OrdersApi, SupplierApi } from 'src/api';

import style from '../../../../OrderCart.module.scss';

const CancelOrderSupplierForm = ({
  uid,
  isCancelLoading,
  setIsCancelLoading,
  closeCancelOrderModal,
  refreshDataCallback,
  supplierId,
  orderUid
}) => {
  const [cancelOrderReason, serCancelOrderReason] = useState('');
  const validator = useValidator();

  const getOrderCancelationComment = (comment) => `
  Zamówienie zostało anulowane.

  Powód anulowania:
  ${comment}
`;

  const cancelOrder = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsCancelLoading(true);
      await SupplierApi.cancelOrder({ uid });
      await OrdersApi.sendSupplierSystemMessage({
        uid: orderUid,
        message: getOrderCancelationComment(cancelOrderReason),
        supplier_id: supplierId,
        as_supplier: 1
      });
      await refreshDataCallback();
      if (closeCancelOrderModal) closeCancelOrderModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsCancelLoading(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <form onSubmit={cancelOrder}>
      <div
        className={style.cancelReasonComment}
        style={{ marginTop: 0 }}
      >
        <Textarea
          id={'cancel-reason'}
          name={'cancel-reason'}
          label={'Powód anulowania zamówienia'}
          value={cancelOrderReason}
          setValue={(comment) => serCancelOrderReason(comment)}
          validator={validator}
          rule={'required'}
        />
      </div>
      <div className={style.formButtonsContainer}>
        <Button
          label={'Nie'}
          onClick={closeCancelOrderModal}
          gray
        />
        <Button
          label={'Tak'}
          onClick={cancelOrder}
          isLoading={isCancelLoading}
        />
      </div>
    </form>
  );
};

export default CancelOrderSupplierForm;
