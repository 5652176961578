import React, { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import Skeleton from 'react-loading-skeleton';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import cn from 'classnames';

import { DateRangePicker, EmptyState } from 'components';
import Tile from 'components/layout/TimelineHistory/components/Tile';

import { ReactComponent as CloseIcon } from 'src/assets/icons/closeIcon.svg';
import { getActionsHistoryContent } from 'src/utils/helpers';

import style from './HistorySidebar.module.scss';

const HistorySidebar = ({
  isOpen,
  setIsOpen,
  title = 'Historia zamówienia',
  emptyStateType = 'orderHistory',
  history,
  getProductName,
  bottomMessage,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  isLoading
}) => {
  const getContent = useCallback((item) => getActionsHistoryContent(item), [history]);
  const containerClasses = cn(style.container, { [style.slideIn]: isOpen });
  const overlayClasses = cn(style.overlay, { [style.visible]: isOpen });
  const containerRef = useRef(null);

  useEffect(() => {
    if (isOpen) disableBodyScroll(containerRef.current);
    else enableBodyScroll(containerRef.current);
  }, [isOpen]);

  return createPortal(
    <>
      <div
        className={overlayClasses}
        onClick={() => setIsOpen(false)}
      />
      <div
        className={containerClasses}
        ref={containerRef}
      >
        <header className={style.header}>
          <div className={style.wrapper}>
            <h3>{title}</h3>
            <button onClick={() => setIsOpen(false)}>
              <CloseIcon />
            </button>
          </div>
          {endDate && startDate && (
            <div className={style.inputWrapper}>
              <DateRangePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                label={'Zakres dat'}
                numberOfMonths={1}
              />
            </div>
          )}
        </header>
        {isLoading ? (
          <Skeleton
            height={100}
            style={{ marginBottom: '30px' }}
            count={5}
          />
        ) : history.length === 0 ? (
          <EmptyState type={emptyStateType} />
        ) : (
          history.map((item, index) => (
            <Tile
              key={item.user_id + '-' + index}
              getProductName={getProductName}
              historyLength={history.length}
              itemContent={getContent(item)}
              index={index}
              item={item}
            />
          ))
        )}
        <p className={style.bottomMessage}>{bottomMessage}</p>
      </div>
    </>,
    document.body
  );
};

export default HistorySidebar;
