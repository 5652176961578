import React from 'react';

import { Modal, Tag } from 'components';

import { getFormattedPhoneNumber } from 'src/utils/helpers';

import style from './SupplierContactData.module.scss';

const SupplierContactData = ({ isOpen, setIsOpen, supplier, children }) => {
  const phone = supplier.phone_number || supplier.phone;

  return (
    <Modal
      visible={isOpen}
      onClose={() => setIsOpen(false)}
      title={'Skontaktuj się z dostawcą'}
    >
      <div className={style.container}>
        <h2 className={style.name}>{supplier.name || supplier.supplier_name}</h2>
        <p className={style.data}>
          Telefon: {phone ? <a href={`tel:${phone}`}>{getFormattedPhoneNumber(phone)}</a> : <Tag value={'Brak'} />}
        </p>
        <p className={style.data}>
          E-mail: {supplier.email ? <a href={`mailto:${supplier.email}`}>{supplier.email}</a> : <Tag value={'Brak'} />}
        </p>
        {children}
      </div>
    </Modal>
  );
};

export default SupplierContactData;
