import { ReactComponent as LogoAvalio } from 'assets/img/logo-avalio.svg';
import { SeoHelmet } from 'components';

import pages from 'src/dictionaries/pages.json';

import ContactInfo from '../../components/ContactInfo';
import RegisterForm from './components/RegisterForm';

import style from '../../onboarding.module.scss';

const Register = () => {
  return (
    <>
      <SeoHelmet
        title={pages.register.title}
        description={pages.register.description}
      />
      <div className={style.container}>
        <div className={style.logoContainer}>
          <LogoAvalio />
        </div>
        <RegisterForm />
        <ContactInfo />
      </div>
    </>
  );
};

export default Register;
