import React from 'react';

import HorizontalProduct from '../../../HorizontalProduct';

import shared from 'styles/Shared.module.scss';

const ProductsList = ({ pageData, productClasses, changeValuationSelections, status, showRefPrice, selectedCategories = [] }) => {
  return (
    <>
      {pageData?.categories.map((category) => {
        if (
          selectedCategories.length === 0 ||
          (selectedCategories.length > 0 && selectedCategories.find((el) => el.value === category.id))
        ) {
          return (
            <div
              className={shared.tableCategory}
              key={category.id}
            >
              <h3 className={shared.tableCategoryTitle}>
                {category.name} <span className={shared.counter}>({category.products?.length})</span>
              </h3>
              {category.products.map((product) => (
                <HorizontalProduct
                  product={product}
                  key={product.id}
                  className={productClasses}
                  changeValuationSelections={changeValuationSelections}
                  status={status}
                  showRefPrice={showRefPrice}
                />
              ))}
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

export default ProductsList;
