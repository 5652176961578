import React from 'react';

import { Modal } from 'components';

import ImportExportForm from 'src/features/Warehouse/new/ImportExportForm';

const ImportExportModal = ({ isOpen, setIsOpen }) => {
  return (
    <Modal
      visible={isOpen}
      onClose={() => setIsOpen(false)}
      title={'Inwentaryzacja'}
    >
      <ImportExportForm closeModal={() => setIsOpen(false)} />
    </Modal>
  );
};

export default ImportExportModal;
