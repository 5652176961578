import React, { useState } from 'react';

import { Button, FileDropzone, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WholesalersApi } from 'src/api';

import styles from '../../WholesalerImportExportForm.module.scss';

const ImportForm = ({ wholesalerId, closeModal }) => {
  const [flatFile, setFlatFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const validator = useValidator();

  const handleImport = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const formData = new FormData();
    formData.append('products', flatFile);
    formData.append('wholesaler_id', wholesalerId);

    try {
      setIsLoading(true);
      const { data } = await WholesalersApi.importWholesalerProducts(formData);
      notifyCommon([`${data.message}`]);
      setFlatFile(null);
      if (closeModal) closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={styles.container}
      onSubmit={handleImport}
    >
      <header className={styles.header}>
        <h3>Import pliku z produktami</h3>
        <p>Wybierz plik z wprowadzonymi kodami produktów, aby dodać je do hurtownii.</p>
      </header>
      <FileDropzone
        label={'Szablon z wprowadzonymi kodami produktów'}
        onChange={setFlatFile}
        value={flatFile}
        validator={validator}
        rule={'required'}
      />
      <Button
        label={'Zaimportuj produkty'}
        type={'submit'}
        className={styles.button}
        isLoading={isLoading}
      />
    </form>
  );
};

export default ImportForm;
