import React, { useEffect, useState } from 'react';

import { ReactComponent as ToTopIcon } from 'src/assets/icons/arrow-down-solid.svg';

import style from './ScrollToTopButton.module.scss';

const ScrollToTopButton = ({ container }) => {
  const toTopHandler = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const scrollHandler = () => {
      container?.getBoundingClientRect().top;
      setShowButton(-1 * container?.getBoundingClientRect().top > window.innerHeight / 2);
    };
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  if (!showButton) {
    return null;
  }

  return (
    <button
      className={style.button}
      onClick={toTopHandler}
    >
      <ToTopIcon className={style.icon} />
    </button>
  );
};

export default ScrollToTopButton;
