import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageHeader, Select, useIsMobile } from 'components';

import { valuationStatuses } from 'src/constants/enums';
import { getValuationStatusTag } from 'src/utils/helpers';
export const priceTypes = [
  { label: 'brutto', value: 'brutto' },
  { label: 'netto', value: 'netto' }
];

import { changePriceType } from '../../actions';

import style from '../../SupplierForm.module.scss';

const ValuationHeader = ({ sendFormButton, valuationName, autoSaveButton }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.supplierForm.status);
  const price_type = useSelector((state) => state.supplierForm.form.price_type);
  const isDisabled = useSelector((state) => state.supplierForm.isDisabled);

  const switchPrice = (type) => {
    dispatch(changePriceType(type));
  };

  return (
    <div className={style.headerWrapper}>
      <PageHeader
        name={valuationName}
        textCenter={!isMobile}
        className={style.pageHeader}
        text={<span id={'valuation-status'}>{getValuationStatusTag(status)}</span>}
      >
        <div className={style.headerContent}>
          {status === valuationStatuses.pending && (
            <div
              className={style.priceType}
              id={'price-type-selector'}
            >
              <Select
                wrapperStyle={style.select}
                options={priceTypes}
                value={price_type}
                label={'Wyświetlaj ceny:'}
                onChange={switchPrice}
                isDisabled={isDisabled}
              />
            </div>
          )}
          {autoSaveButton}
          {sendFormButton}
        </div>
      </PageHeader>
    </div>
  );
};

export default ValuationHeader;
