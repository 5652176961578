import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import icon_person from 'assets/icons/person.svg';
import {
  CustomTooltip,
  EanIndicator,
  Menu,
  Modal,
  useAuthUser,
  useCompany,
  useIsMobile,
  useModalConfirm,
  usePermissions
} from 'components';
import AddProductToList from 'components/layout/AddProductToList';
import BarcodeScanner from 'components/layout/BarcodeScanner';
import PricesCounter from 'components/layout/PricesCounter';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';
import { AdminApi } from 'src/api';
import { userPermissions, userRoles } from 'src/constants/enums';
import { refreshList } from 'src/features/ProductsBase/actions';
import { imageErrorHandler } from 'src/utils/helpers';

import ProductFormAdmin from '../ProductForms/components/ProductFormAdmin';
import ProductFormUser from '../ProductForms/components/ProductFormUser';
import ReplaceProduct from '../ReplaceProduct';

import style from './DefaultProduct.module.scss';

const DefaultProduct = ({ product }) => {
  const {
    id,
    thumbnail,
    image,
    unit_name,
    collection_amount,
    company_name,
    name,
    producer,
    category,
    is_company_product,
    user_name,
    prices_count,
    ean
  } = product || {};

  const user = useAuthUser();
  const isMobile = useIsMobile();
  const company = useCompany();
  const dispatch = useDispatch();
  const isAdmin = user?.role === userRoles.admin;
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const [isVisible, setIsVisible] = useState(false);
  const [isModalProductFromOpen, setIsModalProductFromOpen] = useState(false);
  const [isModalAddToListOpen, setIsModalAddToListOpen] = useState(false);
  const [isModalBarcodeOpen, setIsModalBarcodeOpen] = useState(false);
  const [canReadLists] = usePermissions([userPermissions.lists.read]);

  const deleteProduct = async () => {
    try {
      await ProductsApi.deleteProduct(id);
      notifyCommon([`Produkt ${name} został usunięty.`]);
      dispatch(refreshList());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleDeleteProduct = () => {
    handleOpenModalConfirm({
      message: `Czy na pewno chcesz usunąć produkt ${name}?`,
      handleConfirm: deleteProduct
    });
  };

  const addProductToProductBase = async () => {
    try {
      await AdminApi.addUserProductToDb(id);
      notifyCommon([`Produkt "${name}" został dodany do bazy produktów.`]);
      dispatch(refreshList());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleAddProductToProductBase = () => {
    handleOpenModalConfirm({
      message: 'Czy na pewno chcesz dodać ten produkt do bazy?',
      handleConfirm: addProductToProductBase
    });
  };

  const actions = [
    {
      title: 'Edytuj',
      icon: 'edit',
      action: () => setIsModalProductFromOpen(true),
      roles: ['admin']
    },
    {
      title: 'Dodaj do listy',
      icon: 'add',
      action: () => setIsModalAddToListOpen(true),
      disabled: !isAdmin && !canReadLists,
      roles: ['admin', 'user']
    },
    {
      title: 'Skanuj kod kreskowy',
      icon: 'multiselect',
      action: () => setIsModalBarcodeOpen(true),
      disabled: !isAdmin && !company?.enable_ean_edit,
      color: 'blue',
      roles: ['admin', 'user']
    },
    {
      title: 'Podmień produkt',
      icon: 'edit',
      action: () => setIsVisible(true),
      color: 'blue',
      roles: ['admin']
    },
    {
      title: 'Dodaj do Bazy Produktów',
      icon: 'add',
      action: handleAddProductToProductBase,
      disabled: !isAdmin && !is_company_product,
      color: 'blue',
      roles: ['admin']
    },
    {
      title: 'Usuń',
      icon: 'trash',
      action: handleDeleteProduct,
      disabled: !isAdmin && !is_company_product,
      color: 'red',
      roles: ['admin']
    }
  ];

  return (
    <div className={style.container}>
      <Menu
        actions={actions}
        className={style.menu}
      />
      <div className={classnames(style.wrapper, style.nameWrapper)}>
        <div className={style.thumbnailWrapper}>
          {!!is_company_product && !isMobile && (
            <CustomTooltip
              wrapperStyle={style.userIconWrapper}
              title={isAdmin ? `${company_name} (${user_name})` : 'Twój produkt'}
              placement={'right'}
            >
              <img
                src={icon_person}
                className={style.userIcon}
                alt={'ikona użytkownika'}
                onError={imageErrorHandler}
              />
            </CustomTooltip>
          )}
          <ProductThumbnailImage
            thumbnail={thumbnail}
            image={image}
            title={name}
            id={id}
          />
        </div>
        <p className={style.text}>{name}</p>
        <PricesCounter count={prices_count} />
        <EanIndicator ean={ean} />
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Jednostka:</p>}
        <p className={style.text}>{unit_name}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Ilość w op.:</p>}
        <p className={style.text}>{collection_amount}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Producent:</p>}
        <p className={style.text}>{producer}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Kategoria:</p>}
        <p className={style.text}>{category}</p>
      </div>
      {!!is_company_product && isMobile && (
        <div className={style.wrapper}>
          {isMobile && <p className={style.text}>Właściciel:</p>}
          <p className={style.text}>{isAdmin ? `${company_name} (${user_name})` : 'Twój produkt'}</p>
        </div>
      )}
      <AddProductToList
        title={`Dodaj "${name}" do listy`}
        visible={isModalAddToListOpen}
        onClose={() => setIsModalAddToListOpen(false)}
        productId={id}
      />
      {isAdmin ? (
        <ProductFormAdmin
          title='Edytuj produkt'
          product_id={id}
          visible={isModalProductFromOpen}
          type='edit-product'
          onClose={() => setIsModalProductFromOpen(false)}
        />
      ) : (
        <ProductFormUser
          title='Edytuj produkt'
          product_id={id}
          visible={isModalProductFromOpen}
          type='edit-product'
          onClose={() => setIsModalProductFromOpen(false)}
        />
      )}
      {renderedModalConfirm}
      <ReplaceProduct
        name={name}
        productId={id}
        setIsVisible={setIsVisible}
        isVisible={isVisible}
      />
      <Modal
        visible={isModalBarcodeOpen}
        title={'Dodaj numer EAN do produktu'}
        onClose={() => setIsModalBarcodeOpen(false)}
      >
        <BarcodeScanner product={product} />
      </Modal>
    </div>
  );
};

export default DefaultProduct;
