import React, { useState } from 'react';
import cn from 'classnames';

import { AsyncSelect, Button, useAuthUser, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi, CompanyApi } from 'src/api';
import { userRoles } from 'src/constants/enums';

import style from '../../Personnel.module.scss';

const AddUserToCompany = ({ companyId, onSuccess }) => {
  const [selectedUser, setSelectedUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const validator = useValidator();
  const user = useAuthUser();

  if (user.role !== userRoles.admin) {
    return null;
  }

  const handleAddToCompany = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const queryData = {
      company_id: companyId,
      user_id: selectedUser?.id
    };
    try {
      setIsLoading(true);
      await CompanyApi.addUserToCompany(queryData);
      notifyCommon(['Użytkownik został dodany.']);
      if (onSuccess) onSuccess();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={cn(style.container, style.smaller)}
      onSubmit={handleAddToCompany}
    >
      <AsyncSelect
        value={selectedUser}
        onChange={setSelectedUser}
        apiCallback={AdminApi.getUsers}
        valueKey={'id'}
        labelKey={'email'}
        label={'Użytkownik'}
        labelFormat={(user) => `${user.email} (${user.name})`}
        isClearable
        placeholder={'Użytkownik'}
        validator={validator}
        rule={'required'}
      />
      <Button
        label={'Dodaj do oddziału'}
        type={'submit'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default AddUserToCompany;
