import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useAtom } from 'jotai/index';

import { Button, useIsAdmin } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { EXCEL_FILE_TYPE } from 'src/constants/misc';
import { selectedCompanyAtom, versionAtom } from 'src/features/Warehouse';
import { downloadFile, getDecodeBase64File } from 'src/utils/helpers';

import style from '../../ImportExportForm.module.scss';

const Export = ({ closeModal }) => {
  const isAdmin = useIsAdmin();

  const [version, setVersion] = useAtom(versionAtom);
  const [selectedCompany] = useAtom(selectedCompanyAtom);

  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  const downloadHandler = async (type) => {
    const date = dayjs().format('DD.MM.YYYY, HH:mm');

    const queryParams = {
      file_format: type,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      setIsLoadingCsv(type === 'CSV');
      setIsLoadingExcel(type === 'XLSX');
      const { data } = await WarehouseApi.exportWarehouseProducts(queryParams);
      const blob = type === 'CSV' ? new Blob([data], { type: 'text/csv' }) : getDecodeBase64File(data, EXCEL_FILE_TYPE);
      downloadFile(blob, 'magazyn_' + date + '.' + type.toLowerCase());
      setVersion(version + 1);
      if (closeModal) closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoadingCsv(false);
      setIsLoadingExcel(false);
    }
  };

  return (
    <div className={style.container}>
      <header className={style.header}>
        <h3>Export magazynu</h3>
        <p>
          Pobierz plik z całą zawartością magazynu, dzięki czemu możesz edytować niektóre dane produktów. Zaimportuj gotowy plik
          aby zaktualizwać stan magazynowy.
        </p>
      </header>
      <div className={style.buttonsWrapper}>
        <Button
          label={'Pobierz .csv'}
          isLoading={isLoadingCsv}
          onClick={() => downloadHandler('CSV')}
        />
        <Button
          label={'Pobierz .xlsx'}
          isLoading={isLoadingExcel}
          onClick={() => downloadHandler('XLSX')}
        />
      </div>
    </div>
  );
};

export default Export;
