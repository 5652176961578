import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';

import { refreshCategories } from '../../actions';

import style from './EditNode.module.scss';

const EditNode = ({ id, name, closeModal }) => {
  const [categoryName, setCategoryName] = useState(name);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const validator = useValidator();

  const nameChange = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    try {
      setIsLoading(true);
      await AdminApi.saveCategory({ id: id, name: categoryName });
      dispatch(refreshCategories());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={nameChange}
    >
      <Input
        label={'Nazwa kategorii'}
        placeholder={'Nazwa kategorii'}
        wrapperClass={style.input}
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <Button
        label={'Zapisz'}
        title={'Zapisz'}
        type={'submit'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default EditNode;
