import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import {
  Icon,
  OutsideClickHandler,
  PaginationScroll,
  ProductsFiltering,
  SearchInput,
  useCompany,
  useIsAdmin,
  useIsMobile,
  useRequestAbortController
} from 'components';
import { searchTypeOptions } from 'components/layout/ProductsFiltering';
import SearchThumbnail from 'components/layout/SearchThumbnail';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi, ProductsApi } from 'src/api';

import style from './ProductSearch.module.scss';

const QUERY_LIMIT = 25;

const ProductSearch = ({
  list = [],
  hiddenElements = [],
  setHiddenElements,
  onProductClick,
  hideQty,
  fullWidth,
  placeholder,
  scrollIntoViewOnActive,
  onlyProductsBase,
  companyId
}) => {
  const isAdmin = useIsAdmin();
  const company = useCompany();
  const isMobile = useIsMobile();
  const containerRef = useRef(null);
  const filteringRef = useRef(null);
  const productDetailsRef = useRef(null);

  const [page, setPage] = useState(0);
  const [querySearch, setQuerySearch] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [products, setProducts] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [wasDataCleared, setWasDataCleared] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();
  const [selectedProducer, setSelectedProducer] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isFilteringVisible, setIsFilteringVisible] = useState(false);
  const [onlyProductsWithOffers, setOnlyProductsWithOffers] = useState(searchTypeOptions[0]);

  const headerClasses = classNames(style.header, { [style.active]: isActive });
  const containerClasses = classNames(style.container, {
    [style.active]: isActive,
    [style.fullWidth]: fullWidth
  });
  const contentClasses = classNames(style.content, {
    [style.active]: isActive
  });

  const getData = async (page, clearData) => {
    if (isAdmin && !companyId) return;

    const params = {
      page: page,
      perPage: QUERY_LIMIT,
      search: querySearch || undefined,
      category_id: selectedCategory?.value,
      producer_id: selectedProducer?.value,
      ...(selectedCategory && { category_ids: [selectedCategory.value] }),
      ...(onlyProductsWithOffers.value && { company_id: company?.id }),
      ...(isAdmin && companyId && { company_id: companyId })
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    const ApiCallback = onlyProductsWithOffers.value ? ListsApi.getData : ProductsApi.getProducts;

    try {
      setIsLoading(true);
      const { data } = await ApiCallback(params, signal);
      const arr = (onlyProductsWithOffers.value ? data?.data?.products : data?.data) || [];

      if (clearData || arr.length === 0) {
        setWasDataCleared(true);
        setProducts(arr);
        setHasMore(arr.length < data.total);
      } else {
        setWasDataCleared(false);
        setProducts((prev) => [...prev, ...arr]);
        setHasMore(arr.length + products.length < data.total);
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreResults = () => {
    if (!isLoading) {
      getData(page + 1);
      setPage(page + 1);
    }
  };

  const focusHandler = () => {
    setIsActive(true);
    if (scrollIntoViewOnActive && containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'start',
        inline: 'start'
      });
    }
  };

  const clearHandler = () => {
    if (productDetailsRef.current !== null) return;
    setIsActive(false);
  };

  useEffect(() => {
    if (setHiddenElements && list) {
      const arr = [];
      list.forEach((cat) => {
        cat.items.forEach((item) => {
          arr.push(item.id);
        });
      });

      setHiddenElements(arr);
    }
  }, [list, setHiddenElements]);

  useEffect(() => {
    const searchDelay = setTimeout(() => {
      getData(1, true);
      setPage(1);
    }, 400);

    return () => clearTimeout(searchDelay);
  }, [querySearch]);

  useEffect(() => {
    getData(1, true);
    setPage(1);
  }, [selectedProducer, selectedCategory, onlyProductsWithOffers, companyId]);

  useEffect(() => {
    setIsFilteringVisible(isMobile);
  }, [isMobile]);

  useEffect(() => {
    document.body.style.overflow = isActive ? 'hidden' : 'auto';
  }, [isActive]);

  useEffect(() => {
    return () => (document.body.style.overflow = 'auto');
  }, []);

  return (
    <div
      className={containerClasses}
      ref={containerRef}
    >
      <OutsideClickHandler
        onOutsideClick={clearHandler}
        ignoredElements={[filteringRef.current]}
      >
        <div>
          <div className={headerClasses}>
            <SearchInput
              state={querySearch}
              setState={setQuerySearch}
              className={style.input}
              inputClasses={style.input}
              clearButtonVisible={isActive}
              onFocus={focusHandler}
              onClear={clearHandler}
              placeholder={placeholder}
              alwaysExpand
            />
            {isMobile && isActive && (
              <button
                className={classNames(style.mobileButton, {
                  [style.active]: !!selectedProducer || !!selectedCategory
                })}
                onClick={() => setIsFilteringVisible((prev) => !prev)}
              >
                <Icon
                  name={'filter'}
                  size={24}
                  fill={!!selectedProducer || !!selectedCategory ? '#5349dc' : '#0A1B2D'}
                />
              </button>
            )}
          </div>
          <div className={contentClasses}>
            <ProductsFiltering
              ref={filteringRef}
              producerState={[selectedProducer, setSelectedProducer]}
              categoryState={[selectedCategory, setSelectedCategory]}
              searchTypeState={[onlyProductsWithOffers, setOnlyProductsWithOffers]}
              onlyProductsBase={isAdmin || onlyProductsBase}
              isFilteringVisible={isFilteringVisible}
            />
            <PaginationScroll
              data={products}
              hasMore={hasMore}
              page={page}
              loadMoreResults={loadMoreResults}
              isFilteringVisible={isFilteringVisible}
              wasDataCleared={wasDataCleared}
            >
              {products.map((item, index) => (
                <SearchThumbnail
                  onClick={(qty) => onProductClick(item, qty)}
                  productId={item?.product_id || item.id}
                  closeSearch={clearHandler}
                  key={String(item.id) + index}
                  title={item.name}
                  pricesCount={item?.prices_count || item?.prices?.length}
                  unitName={item.unit_name}
                  subtitle={item.producer}
                  thumbnail={item?.thumbnail || placeholder}
                  image={item?.image || placeholder}
                  hideElement={hiddenElements.includes(item?.product_id || item.id)}
                  isLoading={isLoading && page === 1}
                  hideQty={hideQty}
                  ref={productDetailsRef}
                />
              ))}
            </PaginationScroll>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default ProductSearch;
