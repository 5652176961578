import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { useIsAdmin, useSearchParam } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';
import { getFormattedAmount, getParamsArray } from 'src/utils/helpers';
import query from 'src/utils/query';

import AnalyticsSummaryTable from '../AnalyticsSummaryTable';

import managementStyle from '../../CatalogManagement.module.scss';
import style from './SavingsTable.module.scss';

const SavingsTable = (props) => {
  const params = query(props);
  const isAdmin = useIsAdmin();

  const [tableData, setTableData] = useState({});
  const [summaryData, setSummaryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const topParam = useSearchParam(params, 'top');
  const sortBy = useSearchParam(params, 'sort_by');
  const querySearch = useSearchParam(params, 'search');
  const sortOrder = useSearchParam(params, 'sort_order');
  const selectedSuppliers = useSearchParam(params, 'supplier');
  const selectedCategories = useSearchParam(params, 'category');

  const version = useSelector((state) => state.cartAndCatalog.version);
  const selectedCompany = useSelector((state) => state.cartAndCatalog.selectedCompany);

  const getData = async () => {
    const queryParams = {
      ...(sortBy && { sort_by: sortBy }),
      ...(topParam && { limit: +topParam }),
      ...(sortOrder && { sort_order: sortOrder }),
      ...(querySearch && { search: querySearch }),
      ...(isAdmin && { company_id: selectedCompany }),
      ...(selectedSuppliers && { supplier_ids: getParamsArray(selectedSuppliers) }),
      ...(selectedCategories && { category_ids: getParamsArray(selectedCategories) })
    };

    try {
      setIsLoading(true);
      const { data } = await OfferCatalogApi.getCatalogSavingsForVolumes(queryParams);
      const { data: summary } = await OfferCatalogApi.getAnalyticsSummaryForOfferCatalog(selectedCompany);
      setTableData(data);
      setSummaryData(summary);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [sortBy, sortOrder, querySearch, isAdmin, selectedSuppliers, selectedCategories, version]);

  const getValue = (value) => {
    if (tableData && tableData?.monthly_product_counter === 0) {
      return 'Brak danych *';
    } else {
      return getFormattedAmount(value) + ' *';
    }
  };

  const { monthly_expenses_sum, quarter_expenses_sum, half_year_expenses_sum, year_expenses_sum } = tableData;
  const { monthly_savings_sum, quarter_savings_sum, half_year_savings_sum, year_savings_sum } = tableData;

  return (
    <div className={style.container}>
      <div className={cn(managementStyle.header, style.header)}>
        <h2>Analityka</h2>
        <p className={managementStyle.text}>Poniżej prezentowane jest zestawienie prognoz wydatków i oszczędności.</p>
      </div>
      {isLoading ? (
        <div className={style.skeleton}>
          <Skeleton
            width={'100%'}
            height={40}
            count={3}
          />
        </div>
      ) : (
        <>
          <AnalyticsSummaryTable {...summaryData} />
          <div className={style.tableWrapper}>
            <div className={cn(managementStyle.tableWrapper, style.table)}>
              <div className={style.row}>
                <p></p>
                <p className={style.title}>Miesiąc</p>
                <p className={style.title}>Kwartał</p>
                <p className={style.title}>Pół roku</p>
                <p className={style.title}>Rok</p>
              </div>
              <div className={style.row}>
                <p className={style.title}>Wydatki</p>
                <p>{getValue(monthly_expenses_sum)}</p>
                <p>{getValue(quarter_expenses_sum)}</p>
                <p>{getValue(half_year_expenses_sum)}</p>
                <p>{getValue(year_expenses_sum)}</p>
              </div>
              <div className={style.row}>
                <p className={style.title}>Oszczędności</p>
                <p>{getValue(monthly_savings_sum)}</p>
                <p>{getValue(quarter_savings_sum)}</p>
                <p>{getValue(half_year_savings_sum)}</p>
                <p>{getValue(year_savings_sum)}</p>
              </div>
            </div>
          </div>
          <p className={managementStyle.disclaimer}>
            * Prognoza wydatków przewidywana jest na podstawie wybranej oferty preferowanej oraz wolumenu. Oszczędności natomiast
            wyliczane są na podstawie trzech wartości: ceny referencyjnej, wolumenu oraz wybranej oferty preferowanej. Brak
            jakiekolwiek z tych wartości wyklucza produkt z podsumowania.
          </p>
        </>
      )}
    </div>
  );
};

export default SavingsTable;
