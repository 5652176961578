import React, { useState } from 'react';

import { Button, FileDropzone } from 'components/index';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OrdersApi, SupplierApi } from 'src/api';

import style from './UploadAttachment.module.scss';

const UploadAttachment = ({ userAttachment, uid, supplierId, refreshDataCallback, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState();

  const uploadAttachmentHandler = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (file) formData.append('invoice', file);
    if (uid) formData.append('uid', uid);
    if (supplierId) formData.append('supplier_id', supplierId);

    const apiCallback = userAttachment ? OrdersApi.uploadUserAttachment : SupplierApi.uploadSupplierAttachment;

    try {
      setIsLoading(true);
      await apiCallback(formData);
      notifyCommon([`Załącznik ${file?.name} został dodany.`]);
      refreshDataCallback();
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <p>Dodany przez Ciebie plik będzie widoczny w zamówieniu. </p>
      <FileDropzone
        label={'Załącznik'}
        id={'supplier_id'}
        name={'supplier-id'}
        onChange={setFile}
        value={file}
        rule={'required'}
      />
      <Button
        label='Zapisz'
        onClick={uploadAttachmentHandler}
        isLoading={isLoading}
        disabled={!file}
      />
    </div>
  );
};

export default UploadAttachment;
