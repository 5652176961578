import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Modal, PageHeader, PaginationNav, Searchbar, useIsMobile } from 'components';
import { headerTextPlaceholder } from 'components/layout/PageHeader/placeholders';
import SeoHelmet from 'components/layout/SeoHelmet';
import { notifyApiError } from 'components/layout/Toasts';

import { UserApi } from 'src/api';
import addIcon from 'src/assets/icons/add.svg';
import pages from 'src/dictionaries/pages.json';
import DistributorForm from 'src/features/Distributors/components/DistributorForm';
import DistributorsTable from 'src/features/Distributors/components/DistributorsTable';
import query from 'src/utils/query';

import { getCorrectFormOfResultsLabel } from '../../utils/helpers';

import style from './Distributors.module.scss';

const Distributors = (props) => {
  const params = query(props);
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(params.get('page'));
  const [querySearch, setQuerySearch] = useState(params.get('search'));
  const [creationData, setCreationData] = useState();
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [itemsQty, setItemsQty] = useState();
  const suppliersVersion = useSelector((state) => state.suppliers.version);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!isLoading) {
      getData();
    }
  }, [suppliersVersion, currentPage, querySearch, perPage]);

  const getData = async () => {
    setIsLoading(true);
    const queryProps = {
      page: currentPage || 1,
      perPage: perPage,
      search: querySearch,
      pagination: 1
    };

    try {
      const { data } = await UserApi.getDistributors(queryProps);
      setPageData(data.data.distributors);
      setCreationData(data.data.creation_data);
      setItemsQty(data.total);
      setPageQty(data?.last_page);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(params.get('page'));
    setQuerySearch(params.get('search'));
  }, [params]);

  return (
    <>
      <SeoHelmet title={pages.distributors.title} />
      <PageHeader
        name={pages.distributors.title}
        text={isLoading ? headerTextPlaceholder() : getCorrectFormOfResultsLabel(itemsQty)}
      >
        {!isMobile && <Searchbar params={params} />}
        <Button
          label='Dodaj dystrybutora'
          onClick={() => setIsModalOpen(true)}
          icon={addIcon}
        />
      </PageHeader>
      {isMobile && (
        <div className={style.mobileSearch}>
          <Searchbar
            params={params}
            fullWidth
          />
        </div>
      )}
      <div className={style.container}>
        <DistributorsTable
          list={pageData}
          isLoading={isLoading}
        />
        <PaginationNav
          onClick={(number) => setCurrentPage(number)}
          params={params}
          pagesQty={pageQty}
          setPerPage={setPerPage}
          defaultQty={perPage}
        />
        <Modal
          visible={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={'Dodaj dystrybutora'}
        >
          <DistributorForm
            closeModal={() => setIsModalOpen(false)}
            creationData={creationData}
          />
        </Modal>
      </div>
    </>
  );
};

export default Distributors;
