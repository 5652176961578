import React from 'react';
import classNames from 'classnames';

import { ColumnSortButton, EmptyState } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import WholesalerSupplierThumbnail from '../WholesalerSupplierThumbnail';

import style from './WholesalerSuppliersTable.module.scss';
import shared from 'styles/Shared.module.scss';

const WholesalerSuppliersTable = ({ list = [], isLoading, params, refetchSuppliers }) => {
  if (isLoading) {
    return (
      <section
        className={shared.tableWrapper}
        style={{ paddingTop: 0 }}
      >
        <div className={classNames(shared.tableHeader, style.header)}>
          <p>E-mail</p>
          <p>Nazwa</p>
          <p>Liczba właścicieli</p>
          <p>Telefon</p>
        </div>
        <div className={shared.tableCategory}>
          <ProductPlaceholder />
        </div>
      </section>
    );
  }

  return (
    <section
      className={shared.tableWrapper}
      style={{ paddingTop: 0 }}
    >
      {list.length > 0 && (
        <div className={classNames(shared.tableHeader, style.header)}>
          <p>E-mail</p>
          <ColumnSortButton
            parameterName={'name'}
            params={params}
            content={'Nazwa'}
          />
          <p>Liczba właścicieli</p>
          <p>Telefon</p>
        </div>
      )}
      <div className={shared.tableCategory}>
        {list.length ? (
          list.map((supplier, i) => (
            <WholesalerSupplierThumbnail
              key={`supplier-${supplier.email}-${i}`}
              refetchSuppliers={refetchSuppliers}
              {...supplier}
            />
          ))
        ) : (
          <EmptyState type={params.keys.search ? 'search' : 'wholesalersSuppliers'} />
        )}
      </div>
    </section>
  );
};

export default WholesalerSuppliersTable;
