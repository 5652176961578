import React, { useCallback } from 'react';
import classnames from 'classnames';

import { Accordion, useIsMobile } from 'components';
import InfoWidget from 'components/layout/InfoWidget';

import {
  getFormattedAmount,
  getOrderRealizationSum,
  getOrderStatusTag,
  handleNumeralWords,
  suppliersCancelledProductsSum
} from 'src/utils/helpers';

import style from './OrderSuppliersTable.module.scss';

const OrderSuppliersTable = ({ order, isStatus, defaultOpen }) => {
  const { amount: productsSum, products_sum, suppliers = [], shipping_sum, total_sum, products_price_sum } = order || {};
  const isMobile = useIsMobile();
  const amount = products_price_sum || productsSum;

  const title = (
    <h2 className={style.tableTitle}>
      Podsumowanie{' '}
      <p className={style.suppliersQty}>
        ({suppliers.length} {handleNumeralWords(['dostawca', 'dostawców', 'dostawców'], suppliers.length)})
      </p>
    </h2>
  );

  const getShippingPrice = useCallback(
    (sup) => (Object.keys(sup).includes('shipping_price') ? sup.shipping_price : +sup?.total_sum - +sup?.products_sum),
    [suppliers]
  );

  return (
    <Accordion
      title={title}
      defaultWrapped={!defaultOpen}
    >
      <div className={classnames(style.wrapper, style.header, { [style.status]: isStatus })}>
        {isStatus && <p className={classnames(style.amount, style.sum)}>Status</p>}
        <p className={classnames(style.text, style.sum)}>Dostawca</p>
        <p className={classnames(style.amount, style.sum)}>Wartość</p>
        <p className={classnames(style.amount, style.sum)}>Wysyłka</p>
        <p className={classnames(style.amount, style.sum)}>Suma</p>
        {isStatus && <p className={classnames(style.amount, style.sum)}>Realizacja</p>}
      </div>
      {suppliers.map((sup) => (
        <div
          key={sup.id}
          className={classnames(style.wrapper, { [style.status]: isStatus })}
        >
          {isStatus && (
            <span className={style.amount}>
              {isMobile && <p>Status: </p>}
              {getOrderStatusTag({ status: sup.status, className: style.tag })}
            </span>
          )}
          <h2 className={style.text}>{sup.name || sup?.supplier_name}</h2>
          <p className={style.amount}>
            {isMobile && <p>Wartość produktów: </p>}
            {getFormattedAmount(sup.amount || sup?.products_sum)}
          </p>
          <span className={style.amount}>
            {isMobile && <p>Koszt wysyłki: </p>}
            {getFormattedAmount(getShippingPrice(sup) + (sup?.additional_shipping_price ? +sup?.additional_shipping_price : 0))}
            {sup?.supplier_comment && (
              <InfoWidget
                className={style.info}
                place={'bottom'}
              >
                <span>
                  <strong>Szczegóły:</strong>&nbsp;
                  {getFormattedAmount(sup.shipping_price || sup?.total_sum - sup?.products_sum)} +{' '}
                  {getFormattedAmount(sup?.additional_shipping_price)}
                </span>
                <br />
                <span>
                  <strong>Komentarz:</strong> {sup?.supplier_comment}
                </span>
              </InfoWidget>
            )}
          </span>
          <p className={style.amount}>
            {isMobile && <p>Suma: </p>}
            {getFormattedAmount(sup.amount + sup.shipping_price + (sup?.additional_shipping_price || 0) || sup?.total_sum)}
          </p>
          {isStatus && (
            <p className={style.amount}>
              {isMobile && <p>Realizacja: </p>}
              {sup.products.length - suppliersCancelledProductsSum(sup)} / {sup.products.length}
            </p>
          )}
        </div>
      ))}
      <div className={classnames(style.wrapper, { [style.status]: isStatus })}>
        <p className={classnames(style.text, style.sum, { [style.sumStatus]: isStatus })}></p>

        <p className={classnames(style.amount, style.sum)}>
          {isMobile && <p>Wartość: </p>}
          {getFormattedAmount(amount || products_sum)}
        </p>
        <p className={classnames(style.amount, style.sum)}>
          {isMobile && <p>Koszt: </p>}
          {getFormattedAmount(shipping_sum)}
        </p>
        <p className={classnames(style.amount, style.sum)}>
          {isMobile && <p>Suma: </p>}
          {getFormattedAmount(total_sum)}
        </p>
        {isStatus && (
          <p className={classnames(style.amount, style.sum)}>
            {`${getOrderRealizationSum(suppliers).counter}  (${getOrderRealizationSum(suppliers).percentages}%)`}
          </p>
        )}
      </div>
    </Accordion>
  );
};

export default OrderSuppliersTable;
