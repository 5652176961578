import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { LoadingIndicator, SaveButton, Select, useAutoSave, useDepartments, useIsMobile, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';

import { refreshListView } from '../../actions';
import SetDepartmentQuantity from '../SetDepartmentQuantity';

import style from './ListViewItemDepartment.module.scss';

const departmentToOption = (department) => {
  const { id, name } = department;
  return { label: name, value: id };
};

const ListViewItemDepartment = ({ uid, product, department }) => {
  const { id, products_lists_departments_id, name, quantity } = department;

  const dispatch = useDispatch();
  const isMobile = useIsMobile(767);
  const myDepartments = useDepartments();
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaveError, setIsSaveError] = useState(false);
  const [departmentValue, setDepartmentValue] = useState(departmentToOption(department) || null);

  const options = useMemo(() => {
    const unused = myDepartments.departments.filter((item) => !product.departments.map((i) => i.id).includes(item.id));
    const asOptions = unused.map((item) => departmentToOption(item));
    return asOptions;
  }, [myDepartments, product.departments]);

  const deleteProductDepartmentHandler = async () => {
    try {
      await ListsApi.deleteProductDepartment(uid, products_lists_departments_id);
      notifyCommon(['Jednostka organizacyjna została usunięta dla tego produktu.']);
      dispatch(refreshListView());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const changeDepartmentHandler = async () => {
    try {
      setIsSaveError(false);
      setIsLoading(true);

      const data = {
        list_uid: uid,
        product_id: product.id,
        department_id: departmentValue.value,
        quantity,
        products_lists_departments_id
      };

      await ListsApi.setProductDepartment(data);
      dispatch(refreshListView());
    } catch (err) {
      notifyApiError(err);
      setIsSaveError(true);
      setDepartmentValue(departmentToOption(department));
    } finally {
      setIsLoading(false);
    }
  };

  useAutoSave({
    first: +department.id,
    second: +departmentValue.value,
    dependency: [departmentValue],
    saveHandler: changeDepartmentHandler,
    timeoutValue: 800
  });

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <Select
          options={options}
          value={departmentValue}
          onChange={setDepartmentValue}
          placeholder={'Jednostka organizacyjna'}
          isSearchable={false}
        />
        <LoadingIndicator
          isLoading={isLoading}
          hasError={isSaveError}
          aboveInput
        />
      </div>
      <div className={style.wrapper}></div>
      <div className={style.wrapper}></div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Ilość: </p>}
        <SetDepartmentQuantity
          id={products_lists_departments_id}
          uid={uid}
          productId={product.id}
          departmentId={department.id}
          qty={quantity}
          unitName={product.unit_name}
        />
      </div>
      <SaveButton
        type='delete'
        onClick={() =>
          handleOpenModalConfirm({
            message: `Czy na pewno chcesz usunąć ${name} z podziału produktu ${product.name}?`,
            handleConfirm: deleteProductDepartmentHandler
          })
        }
      />
      {renderedModalConfirm}
    </div>
  );
};

export default ListViewItemDepartment;
