import { Accordion } from 'components';

import styles from '../../SystemMessages.module.scss';

const MessageThumbnail = (props) => {
  const { title, content } = props;
  return (
    <Accordion
      title={title.rendered}
      defaultWrapped
      borderLeftDecorator
    >
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: content.rendered }}
      />
    </Accordion>
  );
};

export default MessageThumbnail;
