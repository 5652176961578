import React, { useState } from 'react';

import { OptionSwitch } from 'components';

import Export from './components/Export';
import Import from './components/Import';

import style from './ExportImportForm.module.scss';

const ExportImportForm = () => {
  const [isExport, setIsExport] = useState(true);

  return (
    <div className={style.container}>
      <OptionSwitch
        defaultOption={isExport}
        onChange={setIsExport}
        falseLabel={'Import'}
        trueLabel={'Export'}
      />
      {isExport ? <Export /> : <Import />}
    </div>
  );
};

export default ExportImportForm;
