import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { CustomTooltip, OptionCheckbox, RelativeTime, ReplacementDetails, Tag, useIsAdmin, usePermissions } from 'components';
import InfoWidget from 'components/layout/InfoWidget';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi, OfferCatalogApi } from 'src/api';
import { ReactComponent as ArrowIcon } from 'src/assets/icons/up-arrow.svg';
import { userPermissions } from 'src/constants/enums';
import { toDateNumber, toTime } from 'src/utils/dateTime';
import { getFormattedAmount, getPriceTypeContent } from 'src/utils/helpers';

import { refreshSummary } from '../../actions';
import OfferSettings from '../OfferSettings';

import style from './OfferWrapper.module.scss';

const WEEK_INTERVAL = 60_480_000;

const OfferWrapper = ({ item, product, changeValuationSelections, selectedCompany, isCatalogView, isReplacementOffer }) => {
  const { listUID } = useParams();
  const { price, discount, previous_price_created_at, previous_price, created_at } = item || {};
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();

  const [isSelected, setIsSelected] = useState(false);
  const [isLoadingSelection, setIsLoadingSelection] = useState(false);
  const [canMenageCatalog] = usePermissions([userPermissions.offer_catalog.menage]);

  const Api = isCatalogView ? OfferCatalogApi : ListsApi;

  const handleChange = async () => {
    const queryParams = {
      id: item.id,
      ...(!isCatalogView && { list_uid: listUID }),
      ...(isAdmin && { company_id: selectedCompany })
    };
    const changeData = { productId: product?.id, supplierId: isSelected ? false : item.supplier_id };

    try {
      setIsLoadingSelection(true);
      await Api.selectPrice(queryParams);
      if (changeValuationSelections) changeValuationSelections(changeData);
      dispatch(refreshSummary());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoadingSelection(false);
    }
  };

  const { label: priceAddTypeLabel } = useMemo(() => getPriceTypeContent(item?.add_type), [item]);

  const priceTooltipContent = canMenageCatalog ? (
    <div className={style.tooltip}>
      <p>{priceAddTypeLabel}</p>
      <br />
      <p>Poprzednia cena: {getFormattedAmount(previous_price)}</p>
      {!!created_at && (
        <span>
          Ostatnia zmiana: <RelativeTime date={created_at} />
          <br />({toDateNumber(created_at)}, {toTime(created_at)})
        </span>
      )}
      {!!previous_price_created_at && (
        <p>
          Poprzednią cenę dodano:
          <br />
          {toDateNumber(previous_price_created_at)}
        </p>
      )}
    </div>
  ) : null;

  const priceChangeIndicator =
    +price !== +previous_price && dayjs().diff(dayjs(created_at)) < WEEK_INTERVAL ? (
      +price > +previous_price ? (
        <ArrowIcon className={style.up} />
      ) : (
        <ArrowIcon className={style.down} />
      )
    ) : null;

  const header = useMemo(
    () => (
      <div className={style.priceWrapper}>
        {item.price ? (
          <div className={classNames(style.price, { [style.isReplacementPrice]: isReplacementOffer })}>
            {isReplacementOffer ? (
              <span>
                {getFormattedAmount(item.price)}{' '}
                {item.availability === 'AVAILABLE_AFTER' && item.delivery_date && (
                  <InfoWidget place={'top'}>
                    <span>Produkt dostępny od: {toDateNumber(item.delivery_date)}</span>
                  </InfoWidget>
                )}
              </span>
            ) : (
              <CustomTooltip title={priceTooltipContent}>
                {getFormattedAmount(item.price)} {!!+previous_price && priceChangeIndicator}
              </CustomTooltip>
            )}
          </div>
        ) : price === null ? (
          <span className={classNames(style.price)} />
        ) : (
          <p className={style.price}>
            <CustomTooltip title={priceTooltipContent}>
              {getFormattedAmount(item.price)} {!!+previous_price && priceChangeIndicator}
            </CustomTooltip>
          </p>
        )}
      </div>
    ),
    [item, price, discount]
  );

  useEffect(() => {
    setIsSelected(!!item.isSelected);
  }, [item]);

  return (
    <div className={style.offerBox}>
      <OptionCheckbox
        name={product?.name}
        value={name + item.id}
        onChange={handleChange}
        handleDelete={handleChange}
        header={header}
        isChecked={isCatalogView ? undefined : isSelected}
        className={classNames(style.optionCheckbox, {
          [style.noHover]: isReplacementOffer,
          [style.product]: item?.type === 'valuation'
        })}
        borderOnSelect
        tickOnSelect
        isLoading={isLoadingSelection}
        hideRadio={price === null || isCatalogView}
      >
        <div className={style.contentWrapper}>
          <div className={style.settings}>
            <Tag
              value={item?.supplier_name || '-'}
              className={style.tag}
              style={{ borderLeft: `5px solid ${item?.color}` || '' }}
            />
            <OfferSettings
              priceId={item.id}
              item={item}
              selectedCompany={selectedCompany}
            />
          </div>
        </div>
      </OptionCheckbox>
    </div>
  );
};

export default OfferWrapper;
