import { SeoHelmet } from 'components';

import pages from 'src/dictionaries/pages.json';
import SupplierForm from 'src/features/SupplierForm';

import style from './SupplierValuation.module.scss';

const SupplierValuation = () => {
  return (
    <div className={style.container}>
      <SeoHelmet title={pages.supplierValuations.title} />
      <SupplierForm />
    </div>
  );
};

export default SupplierValuation;
