import React from 'react';
import Camera from 'react-html5-camera-photo';

import { getDecodeBase64File } from 'src/utils/helpers';

import 'react-html5-camera-photo/build/css/index.css';
import style from './MakeImage.module.scss';

const MakeImage = ({ onPhotoTake }) => {
  const handleTakePhoto = (dataUri) => {
    const blob = getDecodeBase64File(dataUri.split(',')[1], 'image/png');
    const file = new File([blob], `invoice-picture-${new Date().toLocaleString()}.png`, { type: 'image/png' });
    onPhotoTake(dataUri, file);
  };

  return (
    <div className={style.container}>
      <Camera
        onTakePhoto={handleTakePhoto}
        idealFacingMode={'environment'}
        isImageMirror={false}
        isMaxResolution
      />
    </div>
  );
};

export default MakeImage;
