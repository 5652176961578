import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import { Menu, useIsAdmin, useIsMobile } from 'components';
import AddProductToList from 'components/layout/AddProductToList';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import { getFormattedAmount } from 'src/utils/helpers';

import style from './Product.module.scss';

const Product = ({ item, index }) => {
  const { id, producer_name, product_name, thumbnail, image, top_category_name, products_sum, products_volume } = item || {};
  const isAdmin = useIsAdmin();

  const [isModalAddToListOpen, setIsModalAddToListOpen] = useState(false);

  const isMobile = useIsMobile();
  const container = useRef(null);

  const clearTabindexEffectHandler = () => {
    if (container.current?.hasAttribute('tabindex')) {
      container.current?.removeAttribute('tabIndex');
    }
  };

  const actions = [
    {
      title: 'Dodaj do listy',
      icon: 'add',
      action: () => setIsModalAddToListOpen(true)
    }
  ];

  return (
    <div
      className={classNames(style.container, { [style.admin]: isAdmin })}
      id={`list-product-${id}`}
      onClick={clearTabindexEffectHandler}
      ref={container}
    >
      <div className={style.checkbox}>
        <p>{index + 1}.</p>
      </div>
      <div className={style.wrapper}>
        <ProductThumbnailImage
          thumbnail={thumbnail}
          image={image}
          title={product_name}
          id={id}
        />
        <p className={style.text}>{product_name}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Producent: </p>}
        <p className={style.text}>{producer_name}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Kategoria: </p>}
        <p className={style.text}>{top_category_name}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Łączna wartość: </p>}
        <p className={style.text}>{getFormattedAmount(products_sum)}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Zamówiona ilość: </p>}
        <p className={style.text}>{+products_volume}</p>
      </div>
      <div className={classNames(style.wrapper, style.menuWrapper)}>
        <Menu
          actions={actions}
          className={style.menu}
        />
      </div>
      <AddProductToList
        title={`Dodaj "${product_name}" do listy`}
        visible={isModalAddToListOpen}
        onClose={() => setIsModalAddToListOpen(false)}
        productId={id}
      />
    </div>
  );
};

export default Product;
