import React, { useState } from 'react';

import { Modal, Tag } from 'components';

import { orderStatuses } from 'src/constants/enums';
import { toDateNumber } from 'src/utils/dateTime';

import style from './OrderApproval.module.scss';

const OrderApproval = ({ orderApprovalData, orderStatus }) => {
  const { decline_reason, updated_at, threshold_email } = orderApprovalData || {};
  const [isVisible, setIsVisible] = useState(false);

  if (!orderApprovalData || orderStatus !== orderStatuses.draft) {
    return null;
  }

  return (
    <>
      <Tag
        value={'Odrzucono'}
        color={'red'}
        title={`Powód: ${decline_reason}`}
        onClick={() => setIsVisible(true)}
      />
      <Modal
        visible={isVisible}
        onClose={() => setIsVisible(false)}
        title={'Zamówienie zostało odrzucone'}
      >
        <div className={style.container}>
          <p>
            <strong>Akceptujący:</strong> {threshold_email}
          </p>
          <p>
            <strong>Data odrzucenia:</strong> {toDateNumber(updated_at)}
          </p>
          <p style={{ maxWidth: '450px' }}>
            <strong>Powód:</strong> {decline_reason}
          </p>
        </div>
      </Modal>
    </>
  );
};

export default OrderApproval;
