import * as types from '../actions/types';

export const initialState = {
  orderDetails: [],
  version: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_CART_ORDER_DETAILS: {
      const details = state.orderDetails.filter((order) => order?.supplier_id !== action.payload?.supplier_id);
      return {
        ...state,
        orderDetails: [...details, action.payload]
      };
    }
    case types.CLEAR_CART_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: []
      };
    case types.CHANGE_CART_ORDER_VERSION:
      return {
        ...state,
        version: (state.version += 1)
      };
    default:
      return state;
  }
};
