import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { AsyncSelect, Button, FileDropzone, useIsAdmin, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OfferCatalogApi, UserApi } from 'src/api';

import { refreshCartOrCatalog } from '../../../../actions';
import { getFormData } from '../VolumeImport';

import style from '../../FlatFileHandling.module.scss';

const FlatFileImport = ({ selectedCompany, closeModal }) => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const validator = useValidator();
  const [flatFile, setFlatFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const queryData = {
      prices: flatFile,
      supplier_id: selectedSupplier.value,
      ...(isAdmin && { company_id: selectedCompany })
    };

    const formData = getFormData(queryData);

    try {
      setIsLoading(true);
      await OfferCatalogApi.importCatalogPrices(formData);
      notifyCommon(['Ceny zostały zaimportowane'], { title: 'Sukces!' });
      validator.hideMessages();
      setFlatFile(null);
      setSelectedSupplier(null);
      dispatch(refreshCartOrCatalog());
      if (closeModal) closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={submitHandler}
    >
      <header className={style.header}>
        <h3>Import pliku z cenami</h3>
        <p>
          Wybierz plik oraz dostawcę, aby dodać do katalogu wprowadzone w pliku ceny. Zostaną one automatycznie przypisane do
          wybranego poniżej odstawcy.
        </p>
      </header>
      <FileDropzone
        label={'Szablon z wprowadzonymi cenami'}
        onChange={setFlatFile}
        value={flatFile}
        validator={validator}
        rule={'required'}
      />
      <AsyncSelect
        value={selectedSupplier}
        onChange={setSelectedSupplier}
        apiCallback={UserApi.getDistributors}
        valueKey={'id'}
        labelKey={'name'}
        label={'Dostawca'}
        placeholder={'Dostawca'}
        queryParams={{ pagination: 1, ...(isAdmin && { company_id: selectedCompany }) }}
        validator={validator}
        rule={'required'}
        isClearable
      />
      <Button
        label={'Zaimportuj ceny'}
        type={'submit'}
        className={style.button}
        isLoading={isLoading}
      />
    </form>
  );
};

export default FlatFileImport;
