import React, { useEffect, useMemo, useState } from 'react';

import { Card, Chart } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { StatisticsApi } from 'src/api';
import { toDateNumber } from 'src/utils/dateTime';
import { getFormattedAmount } from 'src/utils/helpers';

import ExportData from '../ExportData';

import style from '../Charts.module.scss';

const ExpensesByCategory = ({ selectedCompanyId, filters }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState({});

  const getData = async () => {
    const queryData = {
      ...(!!filters.selectedCompanies && { company_ids: filters.selectedCompanies.map((company) => company.value) }),
      ...(selectedCompanyId && { company_id: selectedCompanyId }),
      ...(filters.startDate && filters.endDate && { date_from: filters.startDate, date_to: filters.endDate })
    };
    try {
      setIsLoading(true);
      const { data } = await StatisticsApi.getExpensesByCategory(queryData);
      setChartData(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getSeries = (type) => {
    if (type === 'pie') {
      return chartData?.series || [];
    } else {
      const data = [];
      chartData?.labels?.forEach((label, index) => {
        data.push({
          x: label,
          y: chartData?.series[index]
        });
      });
      return [{ data, name: 'Wydatki' }];
    }
  };

  const chartFormatter = useMemo(() => {
    const getPercentages = (data = []) => {
      let monthlySum = 0;

      data.forEach((value) => {
        monthlySum += value;
      });

      return data.map((value) => (monthlySum === 0 ? 0 : (value / monthlySum) * 100));
    };

    const categoriesPercentages = getPercentages(chartData?.series);
    return (val, opts) => `${getFormattedAmount(val)} (${categoriesPercentages[opts?.dataPointIndex]?.toFixed(2)}%)`;
  }, [chartData, selectedCompanyId]);

  useEffect(() => {
    getData();
  }, [filters, selectedCompanyId]);

  if (!chartData) {
    return null;
  }

  return (
    <Card className={style.container}>
      <header className={style.header}>
        <h2 className={style.title}>Wydatki</h2>
        <div className={style.options}>
          <ExportData
            company_id={selectedCompanyId}
            filters={filters}
            apiCallback={StatisticsApi.exportExpensesByCategory}
          />
        </div>
      </header>
      <div>
        <Chart
          renderCustomSeries={getSeries}
          customSeries={chartData?.series}
          labels={chartData?.labels}
          categories={chartData?.labels}
          title={'Podział na kategorie'}
          categoriesLabel={'Zamówione produkty'}
          chartTypes={['treemap', 'bar', 'pie']}
          dataLabelsFormatter={(val) => (typeof val === 'number' ? val.toFixed(1) + '%' : val)}
          yAxisFormatter={(val) => getFormattedAmount(val)}
          tooltipFormatter={chartFormatter}
          haveData={chartData?.have_data}
          isLoading={isLoading}
          distributed
          height={500}
          fullWidth
        />
      </div>
      <p>
        * - Dane na dzień {toDateNumber(filters.endDate)}r. Dane agregowane są na podstawie zamówień o statusach: Oczekuje na
        potwierdzenie, W realizacji, Wysłano, Zrealizowano.
      </p>
    </Card>
  );
};

export default ExpensesByCategory;
