import { useSelector } from 'react-redux';

import { availabilities, productTypes } from 'src/constants/enums';

import { priceTypes } from './components/ValuationHeader';

const compareObjects = (keys, item1, item2, isNumber = false) => {
  let areEqual = true;

  keys.forEach((key) => {
    if (isNumber && +item1[key] !== +item2[key]) areEqual = false;
    if (!isNumber && item1[key] !== item2[key]) areEqual = false;
  });

  return areEqual;
};

const areArrItemsEqual = (arr1, arr2, extraKey = '') => {
  let areEqual = true;

  if (arr1.length !== arr2.length) return false;

  arr1.forEach((item, index) => {
    if (item?.id !== arr2[index]?.id) {
      areEqual = false;
    }
    if (extraKey && item[extraKey] !== arr2[index][extraKey]) areEqual = false;
  });

  return areEqual;
};

export const getAreItemsEqual = (item1, item2, type) => {
  const numberKeys = ['price'];
  const keys = ['name', 'producer', 'replacement_item', 'quantity', 'comment', 'delivery_date', 'tax'];
  if (type !== productTypes.offer) keys.push('unit_name');

  let areEqual = compareObjects(keys, item1, item2);
  if (!compareObjects(numberKeys, item1, item2, true)) areEqual = false;
  if (
    !(item2['availability'] === null && item1['availability'] === availabilities.available) &&
    item1['availability'] !== item2['availability']
  )
    areEqual = false;

  return areEqual;
};

export const areFormDataEqual = (item1, item2) => {
  const numberKeys = ['shipping_price', 'free_shipping_price'];
  const keys = ['comment', 'price_type'];

  let areEqual = compareObjects(numberKeys, item1, item2, true);
  if (!compareObjects(keys, item1, item2)) areEqual = false;
  if (!areArrItemsEqual(item1.payments, item2.payments, 'selected')) areEqual = false;
  if (!areArrItemsEqual(item1.shipments, item2.shipments, 'selected')) areEqual = false;
  if (!areArrItemsEqual(item1.gifts, item2.gifts)) areEqual = false;

  return areEqual;
};

export const getDefaultOffer = (product) => {
  if (product.offer) {
    return {
      ...product.offer,
      unit_name: product.unit_name
    };
  } else {
    return null;
  }
};

export const getDefaultReplacement = (product) => {
  if (product.replacement) {
    return {
      ...product.replacement,
      ...(product.replacement_item?.value && { replacement_item: product.replacement_item })
    };
  } else {
    return null;
  }
};

export const getNewReplacement = (product) => ({
  ...product,
  name: '',
  producer: '',
  replacement_item: null,
  availability: availabilities.available,
  unit_name: product.unit_name,
  delivery_date: null
});

export const getSelectedId = (elements = []) => {
  let arr = [];
  elements.forEach((shipment) => {
    if (shipment.selected) {
      arr.push(shipment.id);
    }
  });
  return arr;
};

export const getProductsFromCategories = (categories) => {
  const arr = [];
  for (let category of categories) {
    arr.push(...category.items);
  }
  return arr;
};

export const getFilteredGifts = (gifts, action) =>
  gifts.map((gift) => {
    if (gift?.id === action.payload) {
      return {
        ...gift,
        deleted: true
      };
    } else if (gift?.giftId === action.payload) {
      return null;
    } else {
      return gift;
    }
  });

export const getDefaultPriceType = (price_type) => priceTypes.find((type) => type.value === price_type) || priceTypes[0];

export const useSupplierFormValidationForceUpdate = () => {
  return useSelector((state) => state.supplierForm.numberOfValidations);
};

export const getCorrectDataSource = (type, product, offer, replacement) => {
  const sources = {
    [productTypes.product]: product,
    [productTypes.offer]: offer,
    [productTypes.replacement]: replacement
  };

  return sources[type] || null;
};

export const resetDefaultProductValue = (type, item, setState) => {
  switch (type) {
    case productTypes.product:
      setState(item);
      break;
    case productTypes.offer:
      setState((prev) => ({
        ...prev,
        offer: {
          ...prev.offer,
          ...item
        }
      }));
      break;
    case productTypes.replacement:
      setState((prev) => ({
        ...prev,
        replacement: {
          ...prev.replacement,
          ...item
        }
      }));
      break;
    default:
      break;
  }
};

export const getProductTypeAsKey = (type) => (type !== productTypes.product ? { type: type.toLowerCase() } : {});
