import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { AsyncSelect, Button, Modal, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';

import { refreshList } from '../../actions';

import style from './ReplaceProduct.module.scss';

const ReplaceProduct = ({ name, productId, isVisible, setIsVisible }) => {
  const dispatch = useDispatch();
  const validator = useValidator();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const closeModal = () => {
    setIsVisible(false);
    setSelectedProduct(null);
    validator.purgeFields();
  };

  const replaceProduct = async () => {
    if (!validator.allValid()) return validator.showMessages();
    const queryData = { old_product_id: productId, new_product_id: selectedProduct.value };

    try {
      setIsLoading(true);
      await ProductsApi.replaceProduct(queryData);
      notifyCommon([`Produkt "${name}" został podmieniony.`]);
      dispatch(refreshList());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      visible={isVisible}
      onClose={closeModal}
      title={'Podmiana produktu'}
    >
      <div className={style.container}>
        <p>
          Wybierz produkt, który ma zastąpić <strong>{name}</strong> w zamówieniach i ofertach.
        </p>
        <AsyncSelect
          value={selectedProduct}
          onChange={setSelectedProduct}
          apiCallback={ProductsApi.getProducts}
          valueKey={'id'}
          labelKey={'name'}
          label={'Produkt'}
          placeholder={'Produkt'}
          validator={validator}
          rule={'required'}
          productSearch
          isClearable
        />
        <Button
          label={'Zapisz'}
          onClick={replaceProduct}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  );
};

export default ReplaceProduct;
