import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import { Button, CompanyForm, CopyToClipboard, Icon, Modal, usePermissions } from 'components';

import { userPermissions } from 'src/constants/enums';
import { getFormattedPhoneNumber, getIndicatorColor } from 'src/utils/helpers';

import style from '../details.module.scss';

const CompanyDetails = ({
  details,
  asAddNewButton,
  readOnly,
  renderChildren,
  depth = 0,
  index,
  isLast = false,
  isLoading,
  refreshCb,
  companyManagement,
  sendInvoiceToEmailEnabled,
  copyOnCLick
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const indicatorColor = useMemo(() => getIndicatorColor(depth), [depth]);
  const [canEdit] = usePermissions([userPermissions.company.admin]);

  if (!details && !asAddNewButton) {
    return null;
  }

  const { legal_form, name, phone, nip, address, companies, company_name, send_invoice_to_email, email_for_invoices } =
    details || {};
  const { street, building_number, apartment_number, zip_code, city } = address || {};

  const handleClick = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const companyFormModal = (
    <Modal
      visible={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      title={details ? 'Edytuj dane firmy' : 'Dodaj nowy oddział'}
    >
      <CompanyForm
        refreshCb={refreshCb}
        invoiceData={details}
        closeModal={() => setIsModalOpen(false)}
      />
    </Modal>
  );

  if (asAddNewButton && canEdit) {
    return (
      <>
        <Button
          label={'Dodaj oddział'}
          onClick={() => setIsModalOpen(true)}
          isLoading={isLoading}
        />
        {companyFormModal}
      </>
    );
  }

  return (
    <div
      style={{ borderColor: indicatorColor }}
      className={style.mainWrapper}
    >
      {!isLast && !!depth && (
        <span
          className={style.containerIndicator}
          style={{
            left: `${depth * 50 - 34}px`,
            borderColor: indicatorColor
          }}
        />
      )}
      <div
        style={{ marginLeft: `${depth * 50}px` }}
        className={classNames(style.details, {
          [style.companyManagement]: companyManagement
        })}
      >
        {!!depth && (
          <span
            style={{ borderColor: indicatorColor }}
            className={classNames(style.indicator, {
              [style.first]: index === 0
            })}
          />
        )}
        <div className={style.textWrapper}>
          <p className={style.label}>Forma prawna</p>
          <p className={style.text}>{legal_form}</p>
        </div>
        <div className={style.textWrapper}>
          <p className={style.label}>Nazwa firmy</p>
          <p className={style.text}>{name || company_name}</p>
        </div>
        <div className={style.textWrapper}>
          <p className={style.label}>Adres</p>
          <span>
            <p className={style.text}>
              ul. {street} {building_number}
              {apartment_number ? `/${apartment_number}` : null}
            </p>
            <p className={style.text}>
              {zip_code} {city}
            </p>
          </span>
        </div>
        <div className={style.textWrapper}>
          <p className={style.label}>NIP</p>
          {copyOnCLick ? (
            <CopyToClipboard
              text={nip}
              renderElement={(text) => <p className={style.text}>{text}</p>}
            />
          ) : (
            <p className={style.text}>{nip}</p>
          )}
        </div>
        <div className={style.textWrapper}>
          <p className={style.label}>Telefon</p>
          <p className={style.text}>{getFormattedPhoneNumber(phone)}</p>
        </div>
        {(send_invoice_to_email > 0 || sendInvoiceToEmailEnabled) && email_for_invoices && (
          <div className={style.textWrapper}>
            <p className={style.label}>E-mail do wysyłki faktur</p>
            <p className={style.text}>{details.email_for_invoices}</p>
          </div>
        )}
        {companyFormModal}
        {!readOnly && canEdit && (
          <button
            className={style.edit}
            onClick={handleClick}
          >
            <Icon
              name={'edit'}
              fill={'#6F767E'}
            />
          </button>
        )}
      </div>

      {renderChildren &&
        companies &&
        companies.map((company, index) => (
          <CompanyDetails
            companyManagement={companyManagement}
            details={company}
            key={company.id}
            readOnly={readOnly}
            renderChildren={renderChildren}
            depth={depth + 1}
            index={index}
            isLast={companies.length - 1 === index}
            refreshCb={refreshCb}
            sendInvoiceToEmailEnabled={send_invoice_to_email}
          />
        ))}
    </div>
  );
};

export default CompanyDetails;
