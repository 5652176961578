import { useEffect, useState } from 'react';

import { Loader, useRequestAbortController } from 'components';

import { wpAxiosInstance } from 'src/api/client';
import { WP_MESSAGES_API_URL } from 'src/constants/misc';

import MessageThumbnail from './components/MessageThumbnail';

import styles from './SystemMessages.module.scss';

const SystemMessages = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();

  useEffect(() => {
    const getData = async () => {
      if (abortController) abortController.abort();
      const signal = setNewController();

      try {
        setLoading(true);
        const { data } = await wpAxiosInstance.get(WP_MESSAGES_API_URL, { signal });
        setMessages(data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  if (loading) return <Loader />;
  if (messages.length === 0) return null;

  return (
    <div className={styles.container}>
      {messages.length > 0 &&
        messages.map((message) => (
          <MessageThumbnail
            key={message.id}
            {...message}
          />
        ))}
    </div>
  );
};

export default SystemMessages;
