import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

const SupplierPage = () => {
  const params = useParams();

  return <Redirect to={`/confirm-order/${params.uid}`} />;
};

export default SupplierPage;
