import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import { AsyncSelect, Button, Loader, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { TagsApi } from 'src/api';

import styles from './AddTagForm.module.scss';

const AddTagForm = ({ productId, refreshCallback, assignedTags = [], refreshPageDataCallback }) => {
  const [selectedTag, setSelectedTag] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDefineLoading, setIsDefineLoading] = useState(false);
  const [tagsVersion, setTagsVersion] = useState(0);

  const validator = useValidator();

  const handleDefineTag = async (tagName) => {
    const params = {
      name: tagName,
      color: '#515c6a'
    };

    try {
      setIsDefineLoading(true);
      const { data } = await TagsApi.addTag(params);
      notifyCommon([data.message]);
      setTagsVersion((prev) => prev + 1);
      setSelectedTag({ value: data.tag_id, label: tagName, color: '#515c6a' });
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsDefineLoading(false);
    }
  };

  const handleAddProductTag = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      product_id: productId,
      tag_id: selectedTag.value
    };

    try {
      setIsLoading(true);
      const { data } = await TagsApi.addProductTag(params);
      if (refreshCallback) await refreshCallback(true);
      if (refreshPageDataCallback) await refreshPageDataCallback();

      notifyCommon([data.message]);
      setSelectedTag(null);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const assignedTagsIds = useMemo(() => assignedTags.map((tag) => tag.id), [assignedTags]);
  const isOptionDisabled = (option) => assignedTagsIds.includes(option.id);

  return (
    <form
      className={styles.container}
      onSubmit={handleAddProductTag}
    >
      <AsyncSelect
        key={tagsVersion}
        value={selectedTag}
        onChange={setSelectedTag}
        apiCallback={TagsApi.getTags}
        valueKey={'id'}
        labelKey={'name'}
        placeholder={'Wybierz lub dodaj nowe tagi'}
        noOptionCallback={(value) => (
          <a
            onClick={() => handleDefineTag(value)}
            className={classNames({ [styles.disabled]: !value })}
          >
            {isDefineLoading ? <Loader prefix={'Dodawanie...'} /> : !value ? 'Wpisz nazwę nowego tagu' : 'Zdefiniuj nowy tag'}
          </a>
        )}
        isOptionDisabled={isOptionDisabled}
        isClearable
        validator={validator}
        rule={'required'}
      />
      <Button
        label='Dodaj'
        iconName={'add'}
        isLoading={isLoading}
        className={styles.button}
      />
    </form>
  );
};

export default AddTagForm;
