import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { notifyApiError } from 'components/layout/Toasts';

import { ValuationsApi } from 'src/api';

import { refreshValuations } from '../../actions';
import SupplierSwitch from './components/SupplierSwitch';

import style from './HideValuationsModal.module.scss';

const HideValuationsModal = ({ uid, distributors }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onlyOneVisible = useMemo(() => distributors.filter((dist) => dist.is_visible).length <= 1, [distributors]);

  const hideColumns = async (isVisible, id) => {
    const queryParams = {
      uid,
      distributors: [id]
    };

    const callback = isVisible ? ValuationsApi.hideColumns : ValuationsApi.showColumns;

    try {
      setIsLoading(true);
      await callback(queryParams);
      dispatch(refreshValuations());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <p>
        Możesz wykluczyć z wyceny wybrane kolumny. W każdym momencie masz możliwość ich przywrócenia, dane nie zostaną utracone.
      </p>
      {distributors.map((dist) => (
        <SupplierSwitch
          key={dist.id}
          supplier={dist}
          onChange={(isVisible) => hideColumns(!isVisible, dist.id)}
          isLoading={isLoading}
          onlyOneVisible={onlyOneVisible}
        />
      ))}
    </div>
  );
};

export default HideValuationsModal;
