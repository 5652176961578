import Pusher from 'pusher-js';

import { notifyApiError } from 'components/layout/Toasts';

import { AuthSockets } from './connections';

const authorizer = (channel) => ({
  authorize: async (socketId, callback) => {
    try {
      const response = await AuthSockets.authorizeWebsocketConnection(socketId, channel.name);
      callback(response.status !== 200, response.data);
    } catch (err) {
      notifyApiError(err);
      callback(!!err);
    }
  }
});

const defaultConfig = {
  cluster: 'eu',
  authorizer: authorizer
};

export const SocketClient = (config) => {
  if (import.meta.env.VITE_PUSHER_KEY) {
    console.warn('import.meta.env.VITE_PUSHER_KEY', import.meta.env.VITE_PUSHER_KEY);
    return new Pusher(import.meta.env.VITE_PUSHER_KEY, {
      ...defaultConfig,
      ...config
    });
  } else {
    console.warn('Pusher key error! You must pass your app key when you instantiate Pusher.');
    return false;
  }
};
