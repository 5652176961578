import * as types from './types';

export const editNewProduct = (id) => ({
  type: types.EDIT_NEW_PRODUCT,
  payload: id
});

export const addSupplierData = (supplier) => ({
  type: types.ADD_SUPPLIER_DATA,
  payload: supplier
});

export const refreshList = () => ({
  type: types.CHANGE_LIST_VERSION
});
