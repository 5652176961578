import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import cn from 'classnames';

import { InfoWidget, Menu, Modal, RelativeTime, Tag, useAuthUser, useIsMobile } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import { userRoles } from 'src/constants/enums';
import EditUserData from 'src/features/Users/components/EditUserData';
import UserCompanies from 'src/features/Users/components/UserCompanies';
import { toDateNumber, toTime } from 'src/utils/dateTime';
import { getFormattedPhoneNumber, getRoleColor, getUserRoleLabel } from 'src/utils/helpers';

import { changeUsersVersion } from '../../actions';
import ChangePassword from '../ChnagePassword';
import SupplierClientConnections from '../SupplierClientConnections';

import style from './UserThumbnail.module.scss';

const UserThumbnail = ({ user }) => {
  const { name, email, id, role, phone, last_login_at, created_at, isActive, deactivated_at, user_companies } = user;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContentType, setModalContentType] = useState('');
  const isMobile = useIsMobile(1240);
  const dispatch = useDispatch();
  const adminData = useAuthUser();

  const handleSwitchAccount = async () => {
    try {
      const { data } = await AdminApi.getUserToken(user.id);
      const url = `${window.location.origin}/r/${window.btoa(data)}/${adminData.id}`;
      window.open(url, '_blank');
    } catch (err) {
      notifyApiError(err);
    }
  };

  const userActivityHandler = async () => {
    const ApiCallback = isActive ? AdminApi.deactivateUser : AdminApi.activateUser;

    try {
      await ApiCallback(id);
      notifyCommon([`Konto ${email} zostało ${isActive ? 'wyłączone' : 'aktywowane'}.`]);
      dispatch(changeUsersVersion());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const actions = [
    {
      title: 'Edytuj dane',
      icon: 'edit',
      action: () => {
        setIsModalVisible(true);
        setModalContentType('EDIT_DATA');
      }
    },
    {
      title: 'Zmień hasło',
      icon: 'edit',
      action: () => {
        setIsModalVisible(true);
        setModalContentType('CHANGE_PASSWORD');
      }
    },
    {
      title: 'Przełącz na konto klienta',
      icon: 'share',
      color: 'blue',
      action: handleSwitchAccount,
      disabled: user.role === userRoles.admin
    },
    {
      title: isActive ? 'Dezaktywuj konto' : 'Przywróć konto',
      icon: isActive ? 'close' : 'smile',
      color: isActive ? 'red' : 'blue',
      action: userActivityHandler,
      disabled: user.role === userRoles.admin
    }
  ];

  const supplierActions = [
    ...actions,
    {
      title: `Zarządzaj klientami (${user?.connected_suppliers?.length})`,
      icon: 'profile',
      color: 'blue',
      action: () => {
        setIsModalVisible(true);
        setModalContentType('EDIT_CLIENT_CONNECTIONS');
      },
      disabled: user.role !== userRoles.supplier
    }
  ];

  const modalContent = useMemo(() => {
    switch (modalContentType) {
      case 'EDIT_DATA':
        return (
          <EditUserData
            user={user}
            closeModal={() => setIsModalVisible(false)}
          />
        );
      case 'CHANGE_PASSWORD':
        return (
          <ChangePassword
            user={user}
            closeModal={() => setIsModalVisible(false)}
          />
        );
      case 'EDIT_CLIENT_CONNECTIONS':
        return (
          <SupplierClientConnections
            user={user}
            closeModal={() => setIsModalVisible(false)}
          />
        );
    }
  }, [modalContentType]);

  const modalTitle = useMemo(() => {
    switch (modalContentType) {
      case 'EDIT_DATA':
        return 'Edytuj dane';
      case 'CHANGE_PASSWORD':
        return 'Zmień hasło';
      case 'EDIT_CLIENT_CONNECTIONS':
        return 'Połączenia klientów';
    }
  }, [modalContentType]);

  return (
    <div className={cn(style.container, { [style.notActive]: !isActive })}>
      <div className={classnames(style.wrapper, style.roleTag)}>
        <Tag
          color={getRoleColor(role)}
          value={getUserRoleLabel(role)}
          className={style.tag}
        />
      </div>
      <div className={classnames(style.wrapper, style.userDetails)}>
        <p className={classnames(style.text, style.email)}>{email}</p>
        {deactivated_at && (
          <InfoWidget>
            <strong>Konto {isActive ? 'aktywne' : 'nieaktywne'}</strong>
            <p>Data dezaktywacji konta: {`${toDateNumber(deactivated_at)}, ${toTime(deactivated_at)}`}</p>
          </InfoWidget>
        )}
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={classnames(style.label, style.nameLabel)}>Nazwa:</p>}
        <p className={classnames(style.text, style.name)}>{name}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={classnames(style.label, style.nameLabel)}>Oddziały:</p>}
        <p className={classnames(style.text, style.name)}>
          <UserCompanies
            userCompanies={user_companies}
            userName={name}
          />
        </p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.label}>Telefon:</p>}
        <p className={style.text}>{phone ? getFormattedPhoneNumber(phone) : '-'}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.label}>Data logowania:</p>}
        {last_login_at ? (
          <span className={cn(style.text, style.loginDate)}>
            <RelativeTime date={last_login_at} />
          </span>
        ) : (
          <span className={style.text}>-</span>
        )}
      </div>
      <div className={cn(style.wrapper, style.created)}>
        {isMobile && <p className={style.label}>Data rejestracji:</p>}
        {!!created_at && <p className={style.text}>{toDateNumber(created_at)}</p>}
      </div>
      <div className={classnames(style.wrapper, style.menu)}>
        <Menu
          className={style.menu}
          actions={user.role === userRoles.supplier ? supplierActions : actions}
        />
      </div>
      <Modal
        visible={isModalVisible}
        title={modalTitle}
        onClose={() => setIsModalVisible(false)}
      >
        {modalContent}
      </Modal>
    </div>
  );
};

export default UserThumbnail;
