import React, { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { useStateTimeout } from 'components';

import { priceTypes } from 'src/constants/enums';

import style from '../ProductForms.module.scss';

const CommentForm = forwardRef((props, ref) => {
  const { comment, priceType, setComment } = props;
  const [localComment, setLocalComment] = useState(false);

  const disabled = useSelector((state) => state.supplierForm.isDisabled);

  useStateTimeout({
    localState: localComment,
    setLocalState: setLocalComment,
    state: comment,
    setState: setComment
  });

  const containerClasses = classnames(style.commentContainer, {
    [style.net]: priceType.value === priceTypes.netto
  });

  const textareaClasses = classnames(style.textArea, {
    [style.disabled]: disabled
  });

  return (
    <div className={containerClasses}>
      <textarea
        ref={ref}
        disabled={disabled}
        className={textareaClasses}
        value={localComment}
        onChange={(e) => setLocalComment(e.target.value)}
        placeholder={'Napisz komentarz do produktu...'}
      />
    </div>
  );
});
export default CommentForm;
