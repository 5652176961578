import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { useIsMobile } from 'components';

import { priceTypes, productTypes } from 'src/constants/enums';

import { useSupplierFormValidationForceUpdate } from '../../../../helpers';
import Availability from '../../components/Availability';
import DeleteButton from '../../components/DeleteButton';
import ItemValue from '../../components/ItemValue';
import OfferDetails from '../../components/OfferDetails';
import Price from '../../components/Price';
import Quantity from '../../components/Quantity';
import TaxSelect from '../../components/TaxSelect';

import style from '../ProductForms.module.scss';

const OfferForm = React.memo((props) => {
  const isMobile = useIsMobile();
  useSupplierFormValidationForceUpdate();
  const { product, setProduct, removeHandler } = props;
  const { price, availability, id, quantity, unit_name, tax, delivery_date } = product || {};

  const priceType = useSelector((state) => state.supplierForm.form.price_type);
  const validator = useSelector((state) => state.supplierForm.validator);
  const disabled = useSelector((state) => state.supplierForm.isDisabled);

  if (!product) {
    return null;
  }

  const containerClasses = classNames(style.offerContainer, {
    [style.net]: priceType.value === priceTypes.netto
  });

  const onChangeHandler = (value, name) => {
    setProduct((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className={containerClasses}>
      <OfferDetails />
      <Price
        price={price}
        setPrice={(value) => onChangeHandler(value, 'price')}
        disabled={disabled}
        validator={validator}
        isMobile={isMobile}
        id={id}
      />
      {priceType.value === priceTypes.netto && (
        <TaxSelect
          disabled={disabled}
          tax={tax}
          setTax={(value) => onChangeHandler(value, 'tax')}
        />
      )}
      <Quantity
        disabled={disabled}
        validator={validator}
        isMobile={isMobile}
        unitName={unit_name}
        quantity={quantity}
        setQuantity={(value) => onChangeHandler(value, 'quantity')}
      />
      <ItemValue
        priceType={priceType}
        isMobile={isMobile}
        price={price}
        quantity={quantity}
        tax={tax}
      />
      <Availability
        validator={validator}
        isMobile={isMobile}
        disabled={disabled}
        type={productTypes.offer}
        availability={availability}
        deliveryDate={delivery_date}
        setAvailability={(value) => onChangeHandler(value, 'availability')}
        setDeliveryDate={(value) => onChangeHandler(value, 'delivery_date')}
      />
      <DeleteButton
        disabled={disabled}
        removeHandler={removeHandler}
      />
    </div>
  );
});

export default OfferForm;
