import React from 'react';
import classNames from 'classnames';

import { SaveButton } from 'components';

import style from '../ProductComponents.module.scss';

const DeleteButton = ({ disabled, removeHandler }) => {
  return (
    <div className={classNames(style.wrapper, style.menuWrapper)}>
      <SaveButton
        className={style.saveButton}
        disabled={disabled}
        type={'delete'}
        onClick={removeHandler}
      />
    </div>
  );
};

export default DeleteButton;
