import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { AsyncSelect, useAuthUser } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import { userRoles } from 'src/constants/enums';
import { formatUserOption } from 'src/utils/helpers';

import style from '../Filtering.module.scss';

const FilterByUser = ({ params, fullWidth, valueKey = 'id', labelKey = 'email' }) => {
  const [selectedUser, setSelectedUser] = useState();
  const user = useAuthUser();
  const [isLoading, setIsLoading] = useState(false);
  const paramValue = params.get('user');

  useEffect(() => {
    if (selectedUser?.value !== paramValue && params.get('page')) {
      params.setFew([
        { key: 'user', value: selectedUser?.value },
        { key: 'page', value: '1' }
      ]);
    } else if (selectedUser?.value !== paramValue) {
      params.set('user', selectedUser?.value);
    }
  }, [selectedUser]);

  useEffect(() => {
    const getSelectedElement = async () => {
      try {
        setIsLoading(true);
        const { data } = await AdminApi.getUser(paramValue);
        setSelectedUser(formatUserOption(data));
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (paramValue) getSelectedElement();
  }, []);

  if (user?.role !== userRoles.admin) {
    return null;
  }

  return (
    <AsyncSelect
      value={selectedUser}
      onChange={setSelectedUser}
      apiCallback={AdminApi.getUsers}
      valueKey={valueKey}
      labelKey={labelKey}
      placeholder={'Użytkownik'}
      queryParams={{ role: userRoles.user }}
      wrapperStyle={classNames(style.select, {
        [style.selected]: !!selectedUser,
        [style.fullWidth]: fullWidth
      })}
      isClearable
    />
  );
};

export default FilterByUser;
