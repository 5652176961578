import React, { useMemo, useState } from 'react';

import { Modal } from 'components';

import style from './UserCompanies.module.scss';

const UserCompanies = ({ userName, userCompanies = [] }) => {
  const [isOpen, setIsOpen] = useState(false);

  if (userCompanies.length === 0) {
    return '-';
  }

  const firstCompany = useMemo(() => userCompanies.find((comp) => comp.type === 'HQ') || userCompanies[0], [userCompanies]);

  return (
    <>
      <a onClick={() => setIsOpen(true)}>
        <div className={style.box}>
          <span className={style.company}>{firstCompany.name}</span>
          {userCompanies.length > 1 && <span className={style.counter}>(+{userCompanies.length - 1})</span>}
        </div>
      </a>
      <Modal
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        title={`Oddziały użytkownika ${userName}`}
      >
        <ol className={style.container}>
          {userCompanies.map((comp, index) => (
            <li key={comp.id}>
              {index + 1}. {comp.name}
            </li>
          ))}
        </ol>
      </Modal>
    </>
  );
};

export default UserCompanies;
