import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';

import { notifyApiError } from 'components/layout/Toasts';

import { OCRInvoicesApi } from 'src/api';
import { getPdfUrlFromImage } from 'src/utils/helpers';

import style from './FilePreview.module.scss';

const FilePreview = ({ invoice, isVisible, apiCallback = OCRInvoicesApi.downloadFile }) => {
  const { invoice_details, file_name } = invoice || {};
  const [contentSrc, setContentSrc] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getPreview = async () => {
    if (!(invoice_details?.name || file_name)) return null;

    try {
      setIsLoading(true);
      const { data, headers } = await apiCallback(invoice_details?.name || file_name);

      const url = window.URL.createObjectURL(new Blob([data], { type: headers['content-type'] }));
      const documentType = headers['content-type'];

      const src = documentType === 'application/pdf' ? url : await getPdfUrlFromImage(url);
      setContentSrc(src);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isVisible && !contentSrc) getPreview();
  }, [invoice, isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={style.container}>
      {isLoading ? (
        <div className={style.loaderWrapper}>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      ) : contentSrc ? (
        <embed
          type={'application/pdf'}
          src={contentSrc + '#zoom=100'}
          height={'100%'}
          width={'100%'}
        />
      ) : (
        <p>something went wrong ;(</p>
      )}
    </div>
  );
};

export default FilePreview;
