import React from 'react';
import classNames from 'classnames';

import { Accordion } from 'components';

import NotExistingProductTableThumbnail from './components/NotExistingProductTableThumbnail';

import styles from './NotExistingProductsTable.module.scss';
import shared from 'styles/Shared.module.scss';

const NotExistingProductsTable = ({ products }) => {
  return (
    <div className={styles.container}>
      <Accordion
        title={<h3 className={styles.headline}>Lista niezaimportowanych produktów:</h3>}
        extraDeps={[products]}
        defaultWrapped
        section
        yellow
      >
        <div className={styles.tableWrapper}>
          <div className={classNames(shared.tableHeader, styles.header)}>
            <p>Nazwa</p>
            <p>ID</p>
          </div>
          {products.map((product, i) => (
            <NotExistingProductTableThumbnail
              key={`${i}-id-${product[0]}`}
              name={product[1]}
              id={product[0]}
            />
          ))}
        </div>
      </Accordion>
    </div>
  );
};

export default NotExistingProductsTable;
