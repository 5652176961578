import React, { useState } from 'react';

import { Button, Input, useValidator } from 'components';
import ChangeMenu from 'components/layout/changeMenu';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WholesalersApi } from 'src/api';

const WholesalerProductChangeNumberForm = ({
  currentNumber,
  isActive,
  setIsActive,
  productId,
  wholesalerId,
  wholesalerProductId,
  refetchProducts
}) => {
  const [number, setNumber] = useState(currentNumber || '');
  const [isLoading, setIsLoading] = useState(false);
  const validator = useValidator();

  const handleSave = async () => {
    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    const params = {
      wholesaler_product_id: wholesalerProductId,
      wholesaler_id: wholesalerId,
      product_id: productId,
      wh_product_number: number
    };

    try {
      setIsLoading(true);
      const { data } = await WholesalersApi.addOrUpdateProduct(params);
      notifyCommon([`${data.message}`]);
      refetchProducts();
      setIsActive(false);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ChangeMenu
      value={currentNumber}
      isActive={isActive}
      setIsActive={setIsActive}
      width={'350px'}
    >
      <Input
        value={number}
        onChange={(e) => setNumber(e.target.value)}
        validator={validator}
        label={'ID hurtownii'}
        rule={'required'}
      />
      <Button
        label={'Zapisz'}
        onClick={handleSave}
        isLoading={isLoading}
      />
    </ChangeMenu>
  );
};

export default WholesalerProductChangeNumberForm;
