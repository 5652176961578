import React from 'react';

import { bytesToSize } from 'src/utils/helpers';

import style from '../../FileUploader.module.scss';

const File = ({ handleClearFile, value, children }) => {
  return (
    <div className={style.fileInfo}>
      <button
        className={style.clearButton}
        onClick={handleClearFile}
        type={'button'}
      />
      {!!value.name && <p className={style.fileName}>{value.name}</p>}
      {!!value.size && <p className={style.fileSize}>{bytesToSize(value.size)}</p>}
      {children}
    </div>
  );
};

export default File;
