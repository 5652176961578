import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, SwitchButton } from 'components';

import { changeViewType } from '../../../../actions';
import AddNewPrice from '../../../AddNewPrice';
import FlatFileHandling from '../../../FlatFileHandling';
import PendingValuations from '../../../PendingValuations';
import ChangedPricesProductsList from './components/ChangedPricesProductsList';
import Tile from './components/Tile';

import managementStyle from '../../CatalogManagement.module.scss';
import style from './Management.module.scss';

const Management = () => {
  const dispatch = useDispatch();

  const [isVolumeModalOpen, setIsVolumeModalOpen] = useState(false);
  const [isFlatFileModalOpen, setIsFlatFileModalOpen] = useState(false);
  const [isExportChangedPricesOpen, setIsExportChangedPricesOpen] = useState(false);

  const isDefinedView = useSelector((state) => state.cartAndCatalog.isDefinedView);
  const selectedCompany = useSelector((state) => state.cartAndCatalog.selectedCompany);

  const changeView = (value) => dispatch(changeViewType(value));
  const closeVolumesModal = () => setIsVolumeModalOpen(false);
  const closeFlatFileModal = () => setIsFlatFileModalOpen(false);
  const closeExportChangedPrices = () => setIsExportChangedPricesOpen(false);

  const tiles = [
    {
      id: 1,
      icon: null,
      title: 'Dodaj nowy produkt',
      description: 'Umożliwia rozbudowanie katalogu o oferty dla produktów nie znajdujących się jeszcze w naszym katalogu',
      action: (
        <AddNewPrice
          asLink
          selectedCompany={selectedCompany}
        />
      )
    },
    {
      id: 2,
      icon: null,
      title: 'Import & Eksport ofert',
      description:
        'Pozwala pobrać katalog z ofertami danego dostawcy lub listę produktów z miejscem na oferty w formacie .csv lub .xlsx.',
      action: <a onClick={() => setIsFlatFileModalOpen(true)}>Kliknij, aby otworzyć formularz</a>
    },
    {
      id: 3,
      icon: null,
      title: 'Import & eksport wolumenów',
      description:
        'Pozwala pobrać listę produktów w formacie .csv lub .xlsx w celu uzupełnienia danych o rocznym wolumenie produktu.',
      action: <a onClick={() => setIsVolumeModalOpen(true)}>Kliknij, aby otworzyć formularz</a>
    },
    {
      id: 4,
      icon: null,
      title: 'Aktywne wyceny',
      content: <PendingValuations selectedCompany={selectedCompany} />
    },
    {
      id: 5,
      icon: null,
      title: 'Pokaż widok pełny',
      description: 'Pozwala wyświetlić wszystkie dostępne oferty dla danych produktów',
      action: (
        <SwitchButton
          reverse
          value={!isDefinedView}
          setValue={(value) => changeView(!value)}
          label={'Pokaż widok pełny'}
        />
      )
    },
    {
      id: 6,
      icon: null,
      title: 'Ceny',
      description: 'Pozwala zobaczyć lub pobrać listę produktów których cena została zmieniona.',
      action: (
        <>
          <a onClick={() => setIsExportChangedPricesOpen(true)}>Kliknij, aby zobaczyć produkty</a>
        </>
      )
    }
  ];

  return (
    <div className={style.container}>
      <div className={managementStyle.header}>
        <h2>Zarządzanie</h2>
        <p className={managementStyle.text}>Dostosuj widok ofert, pobieraj i importuj nowe cenniki lub pojedyncze oferty.</p>
      </div>
      <div className={style.wrapper}>
        {tiles.map((tile) => (
          <Tile
            key={tile.id}
            tile={tile}
          />
        ))}
      </div>
      <Modal
        visible={isFlatFileModalOpen}
        onClose={closeFlatFileModal}
        title={'Import & Export ofert'}
      >
        <FlatFileHandling
          type={'flatFile'}
          closeModal={closeFlatFileModal}
          selectedCompany={selectedCompany}
        />
      </Modal>
      <Modal
        visible={isVolumeModalOpen}
        onClose={closeVolumesModal}
        title={'Import & Export wolumenów'}
      >
        <FlatFileHandling
          type={'volumes'}
          closeModal={closeVolumesModal}
          selectedCompany={selectedCompany}
        />
      </Modal>
      <Modal
        visible={isExportChangedPricesOpen}
        onClose={closeExportChangedPrices}
        title={'Produkty, których cena została zmieniona'}
      >
        <ChangedPricesProductsList />
      </Modal>
    </div>
  );
};

export default Management;
