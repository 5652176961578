import React from 'react';

import { Card, LabelWithValue } from 'components';

import styles from './WholesalerDetails.module.scss';

const WholesalerDetails = ({ domain, nip, productsCount }) => {
  return (
    <Card className={styles.container}>
      <LabelWithValue
        label={'Numer NIP'}
        value={nip}
      />
      <LabelWithValue
        label={'Domena'}
        value={domain}
      />
      {/*<LabelWithValue*/}
      {/*  label={'Sprzedaż (ostatnie 30 dni)'}*/}
      {/*  value={getFormattedAmount(75_456.43)}*/}
      {/*/>*/}
      {/*<LabelWithValue*/}
      {/*  label={'Liczba produktów'}*/}
      {/*  value={productsCount}*/}
      {/*/>*/}
      {/*<LabelWithValue*/}
      {/*  label={'Liczba przedstawicieli'}*/}
      {/*  value={14}*/}
      {/*/>*/}
    </Card>
  );
};

export default WholesalerDetails;
