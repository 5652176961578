import React from 'react';
import classnames from 'classnames';

import style from './SwitchButton.module.scss';

const SwitchButton = (props) => {
  const { value = true, setValue, onClick, reverse, disabled, isLoading, ...rest } = props;

  const handleChange = (e) => {
    e.preventDefault();
    setValue(!value);
    if (onClick) onClick(!value);
  };

  const wrapperClasses = classnames(props.wrapperStyle, {
    [style.withLabel]: !!props?.label,
    [style.reverse]: reverse
  });

  return (
    <div className={wrapperClasses}>
      {!!props?.label && <label className={style.label}>{props.label}</label>}
      <button
        onClick={handleChange}
        disabled={disabled}
        className={classnames(style.container, {
          [style.off]: !value,
          [style.disabled]: disabled
        })}
        {...rest}
      >
        <span className={classnames(style.switch, { [style.off]: !value })} />
      </button>
    </div>
  );
};

export default SwitchButton;
