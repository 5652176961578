import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { SwitchButton, Tag } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';

import { refreshCollection } from '../../actions';

import style from './IsActiveSwitch.module.scss';

const PriceInput = ({ product }) => {
  const dispatch = useDispatch();
  const { uid } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const isDataLoading = useSelector((state) => state.collectionView.isLoading);

  const [isActive, setIsActive] = useState(product.isActive);

  const switchLabel = (
    <Tag
      value={isActive ? 'Dostępny' : 'Niedostępny'}
      className={style.tag}
    />
  );

  useEffect(() => {
    setIsActive(product.isActive);
  }, [product]);

  if (!product) {
    return null;
  }

  const handleSavePrice = async (active) => {
    const data = {
      uid,
      product_id: product.id,
      price: +product.price,
      isActive: +active
    };

    try {
      setIsLoading(true);
      await SupplierApi.changePrice(data);
      dispatch(refreshCollection());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SwitchButton
      label={switchLabel}
      value={isActive}
      setValue={setIsActive}
      onClick={handleSavePrice}
      disabled={isDataLoading || isLoading}
    />
  );
};

export default PriceInput;
