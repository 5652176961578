import React from 'react';

import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import style from '../Single.module.scss';

const SingleProduct = ({ product, children }) => {
  const { category_name, name, producer, product_id, id, thumbnail, image } = product || {};

  return (
    <div className={style.container}>
      <div className={style.wrapper}>{children}</div>
      <div className={style.wrapper}>
        <ProductThumbnailImage
          thumbnail={thumbnail}
          image={image}
          title={name}
          id={product_id || id}
          size={'tiny'}
        />
        <p>{name}</p>
      </div>
      <div className={style.wrapper}>
        <p>{category_name}</p>
      </div>
      <div className={style.wrapper}>
        <p>{producer}</p>
      </div>
    </div>
  );
};

export default SingleProduct;
