import Zoom from 'react-medium-image-zoom';

import { ItemTagsList, LabelWithValue, useIsAdmin, usePermissions } from 'components';

import { ReactComponent as ZoomImage } from 'src/assets/icons/zoom.svg';
import { userPermissions } from 'src/constants/enums';
import { getFormattedAmount, imageErrorHandler } from 'src/utils/helpers';

import style from '../../ProductDetails.module.scss';

const ProductDetailsSection = ({ product = {}, image, children, buttons = null, refreshCallback, refreshPageDataCallback }) => {
  const isAdmin = useIsAdmin();
  const [canViewOrders] = usePermissions([userPermissions.orders.read]);
  const { id, product_name, producer_name, last_price, category_name, unit_name, last_supplier, tags } = product;

  return (
    <>
      <div className={style.imageWrapper}>
        <Zoom>
          <img
            loading={'lazy'}
            src={image}
            alt={product_name}
            className={style.image}
            onError={imageErrorHandler}
          />
          <span className={style.zoomIcon}>
            <ZoomImage />
          </span>
          <span className={style.imageDisclaimer}>zdjęcie poglądowe</span>
        </Zoom>
      </div>
      <div className={style.details}>
        <div className={style.titleWrapper}>
          <div className={style.producer}>
            <p className={style}>Producent: </p>
            <p>{producer_name}</p>
          </div>
          <h2>{product_name}</h2>
          {!isAdmin && (
            <ItemTagsList
              refreshCallback={refreshCallback}
              refreshPageDataCallback={refreshPageDataCallback}
              productId={id}
              tags={tags}
            />
          )}
        </div>
        <div className={style.row}>
          <LabelWithValue
            label={'Kategoria'}
            value={category_name || '-'}
          />
          <LabelWithValue
            label={'Jednostka'}
            value={unit_name || '-'}
          />
        </div>
        {canViewOrders && (
          <div className={style.row}>
            <LabelWithValue
              label={'Ostatnia cena'}
              value={last_price ? getFormattedAmount(last_price) : '-'}
            />
            <LabelWithValue
              label={'Ostatni dostawca'}
              value={last_supplier || '-'}
            />
          </div>
        )}
        {children}
        {buttons}
      </div>
    </>
  );
};

export default ProductDetailsSection;
