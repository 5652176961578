import React, { useEffect, useMemo, useState } from 'react';

import { AsyncSelect, Select, useIsAdmin } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OrdersApi, UserApi } from 'src/api';

const SelectOrder = ({ validator, onSuborderSelect, onSupplierSelect, invoiceFromOrder, selectedCompanyId, id, name }) => {
  const isAdmin = useIsAdmin();
  const [suppliers, setSuppliers] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedSuborder, setSelectedSuborder] = useState(null);
  const [isSuppliersLoading, setIsSuppliersLoading] = useState(false);

  const getOrderSuppliers = async (uid) => {
    try {
      setIsSuppliersLoading(true);
      const { data } = await OrdersApi.getProducts({ uid });
      setSuppliers(data.suppliers);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsSuppliersLoading(false);
    }
  };

  const suppliersOptions = useMemo(
    () =>
      suppliers.map((sup) => ({
        label: `${sup.name}`,
        value: sup.suborder_uid,
        ...sup
      })),
    [suppliers]
  );

  useEffect(() => {
    if (selectedOrder && selectedOrder.value) {
      getOrderSuppliers(selectedOrder.value);
    }
  }, [selectedOrder]);

  useEffect(() => {
    setSelectedSupplier(null);
  }, [invoiceFromOrder, selectedOrder]);

  const handleSupplierSelect = (e) => {
    onSupplierSelect(e?.value || null);
    setSelectedSupplier(e);
  };

  const handleSuborderSelect = (e) => {
    onSuborderSelect(e?.value || null);
    setSelectedSuborder(e);
  };

  return (
    <>
      {invoiceFromOrder && (
        <AsyncSelect
          value={selectedOrder}
          onChange={setSelectedOrder}
          apiCallback={OrdersApi.getOrders}
          valueKey={'uid'}
          labelKey={'name'}
          label={'Numer zamówienia'}
          placeholder={'#F5F32X'}
          isClearable
          validator={validator}
          rule={'required'}
          id={id + 'number'}
          name={name + 'number'}
        />
      )}
      {invoiceFromOrder && selectedOrder && (
        <Select
          options={suppliersOptions}
          value={selectedSuborder}
          onChange={handleSuborderSelect}
          placeholder={'Dostawca'}
          isLoading={isSuppliersLoading}
          label={'Dostawca'}
          isClearable
          validator={validator}
          rule={'required'}
          id={id + 'supplier'}
          name={name + 'supplier'}
        />
      )}
      {!invoiceFromOrder && (
        <AsyncSelect
          value={selectedSupplier}
          onChange={handleSupplierSelect}
          apiCallback={UserApi.getDistributors}
          valueKey={'id'}
          labelKey={'name'}
          label={'Dostawca'}
          queryParams={{ pagination: 1, ...(isAdmin && { company_id: selectedCompanyId }) }}
          placeholder={'Dostawca'}
          isDisabled={isAdmin && !selectedCompanyId}
          isClearable
          validator={validator}
          rule={'required'}
          id={id + 'supplier-2'}
          name={name + 'supplier-2'}
        />
      )}
    </>
  );
};

export default SelectOrder;
