import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PageHeader, PaginationNav, Searchbar, SwitchButton, useSearchParam } from 'components';
import { headerTextPlaceholder } from 'components/layout/PageHeader/placeholders';
import SeoHelmet from 'components/layout/SeoHelmet';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import pages from 'src/dictionaries/pages.json';
import { getCorrectFormOfResultsLabel } from 'src/utils/helpers';
import query from 'src/utils/query';

import List from './components/List';

import style from './CompaniesList.module.scss';

const CompaniesList = (props) => {
  const params = query(props);

  const [itemsQty, setItemsQty] = useState();
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAsTree, setShowAsTree] = useState(false);
  const [showDeactivated, setShowDeactivated] = useState(false);
  const version = useSelector((state) => state.companiesList.version);

  const currentPage = useSearchParam(params, 'page');
  const querySearch = useSearchParam(params, 'search');

  const getData = async () => {
    const queryProps = {
      page: currentPage || 1,
      perPage: perPage,
      search: querySearch,
      show_deactivated: +showDeactivated
    };

    const apiCallback = showAsTree ? AdminApi.getCompaniesAsTree : AdminApi.getCompanies;

    try {
      const { data } = await apiCallback(queryProps);
      setPageData(data.data);
      setItemsQty(data.total);
      setPageQty(data?.last_page);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleChangeViewType = (value) => {
    params.set('page', 1);
    setShowAsTree(value);
  };

  const handleChangeVisibility = (value) => {
    params.set('page', 1);
    setShowDeactivated(value);
  };

  useEffect(() => {
    const getDataWithLoading = async () => {
      if (!isLoading) {
        setIsLoading(true);
        await getData();
        setIsLoading(false);
      }
    };

    getDataWithLoading();
  }, [currentPage, querySearch, perPage, showAsTree, showDeactivated]);

  useEffect(() => {
    getData();
  }, [version]);

  return (
    <>
      <SeoHelmet title={pages.companies.title} />
      <PageHeader
        name={pages.companies.title}
        wrapOnMobile
        text={isLoading ? headerTextPlaceholder() : getCorrectFormOfResultsLabel(itemsQty)}
      >
        <div className={style.headerWrapper}>
          <SwitchButton
            label={'Pokaż nieaktywne'}
            value={showDeactivated}
            setValue={handleChangeVisibility}
          />
          <SwitchButton
            label={'Widok w formie drzewa'}
            value={showAsTree}
            setValue={handleChangeViewType}
          />
          <Searchbar params={params} />
        </div>
      </PageHeader>
      <List
        companies={pageData}
        isLoading={isLoading}
      />
      <PaginationNav
        params={params}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
      />
    </>
  );
};

export default CompaniesList;
