import React, { useState } from 'react';
import { useAtomValue } from 'jotai';

import { Button, FileDropzone, Input, SwitchButton, Textarea, useIsAdmin, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ServicesApi } from 'src/api';
import { selectedCompanyAtom } from 'src/features/Services';

import styles from './AddServiceForm.module.scss';

const defaultService = {
  name: '',
  model: '',
  producer: '',
  serial_number: '',
  logo: null,
  produced_at: null,
  purchased_at: null,
  first_used_at: null,
  next_service_at: null,
  is_disabled: false,
  description: null,
  file: null
};

const AddServiceForm = ({ serviceData, onClose, refreshCallback }) => {
  const isAdmin = useIsAdmin();
  const selectedCompany = useAtomValue(selectedCompanyAtom);

  const [service, setService] = useState(serviceData || defaultService);
  const [isEnabled, setIsEnabled] = useState(serviceData?.is_disabled === 1 ? false : true);
  const [replaceLogo, setReplaceLogo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validator = useValidator();

  const onChange = (e) => {
    setService((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const onChangeHandler = (name, data) => {
    setService((prev) => ({
      ...prev,
      [name]: data
    }));
  };

  const addOrEditServiceHandler = async (e, type) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      ...service,
      ...(type === 'edit' && { service_id: serviceData.id }),
      ...(type === 'edit' && { is_disabled: isEnabled ? 0 : 1 }),
      ...(service.logo && typeof service.logo !== 'string' && { logo: service.logo }),
      ...(isAdmin && { company_id: selectedCompany })
    };

    const apiCallback = type === 'edit' ? ServicesApi.editService : ServicesApi.addService;

    try {
      setIsLoading(true);
      await apiCallback(params);
      if (refreshCallback) refreshCallback();
      if (onClose) onClose();
      notifyCommon([`Serwis ${service.name} ${type === 'add' ? 'dodany' : 'zaktualizowany'} pomyślnie.`]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={styles.container}
      onSubmit={(e) => addOrEditServiceHandler(e, serviceData ? 'edit' : 'add')}
    >
      {typeof service.logo === 'string' && !replaceLogo ? (
        <div className={styles.existingLogo}>
          <img src={service.logo} />
          <a
            onClick={() => setReplaceLogo(true)}
            className={styles.a}
          >
            Zmień zdjęcie
          </a>
        </div>
      ) : (
        <FileDropzone
          label={'Zdjęcie (jpg, jpeg, png)'}
          onChange={(logo) => onChangeHandler('logo', logo || null)}
          value={typeof service.logo === 'string' ? null : service.logo}
          id={'logo'}
          name={'logo'}
        />
      )}
      {serviceData && (
        <div className={styles.switch}>
          <SwitchButton
            value={isEnabled}
            setValue={setIsEnabled}
            label={isEnabled ? 'Włączony' : 'Wyłączony'}
          />
        </div>
      )}
      <div className={styles.box}>
        <Input
          id='name'
          name='name'
          label='Nazwa sprzętu'
          value={service.name}
          onChange={onChange}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='model'
          name='model'
          label='Model'
          value={service.model}
          onChange={onChange}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='producer'
          name='producer'
          label='Producent'
          value={service.producer}
          onChange={onChange}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='serial_number'
          name='serial_number'
          label='Numer seryjny'
          value={service.serial_number}
          onChange={onChange}
          validator={validator}
          rule={'required'}
        />
        <Input
          type='date'
          id='produced_at'
          name='produced_at'
          label='Data produkcji'
          value={service.produced_at || ''}
          onChange={onChange}
        />
        <Input
          type='date'
          id='purchased_at'
          name='purchased_at'
          label='Data zakupu'
          value={service.purchased_at || ''}
          onChange={onChange}
        />
        <Input
          type='date'
          id='first_used_at'
          name='first_used_at'
          label='Data pierwszego użycia'
          value={service.first_used_at || ''}
          onChange={onChange}
        />
        <Input
          type='date'
          id='next_service_at'
          name='next_service_at'
          label='Data następnego serwisu'
          value={service.next_service_at || ''}
          onChange={onChange}
        />
      </div>
      <Textarea
        id={'description'}
        name={'description'}
        label={'Opis'}
        value={service.description || ''}
        setValue={(description) => onChangeHandler('description', description !== '' ? description : null)}
      />
      {!serviceData && (
        <FileDropzone
          id={'file'}
          name={'file'}
          value={service.file}
          label={'Załącznik'}
          onChange={(attachment) => onChangeHandler('file', attachment || null)}
        />
      )}
      <Button
        label={serviceData ? 'Zapisz' : 'Dodaj'}
        isLoading={isLoading}
        className={styles.button}
      />
    </form>
  );
};

export default AddServiceForm;
