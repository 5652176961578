import React from 'react';

import { getMonthName } from 'src/api/utils';

import styles from '../VolumesForm/VolumesForm.module.scss';

const MonthItem = ({ date, amount }) => {
  return (
    <div className={styles.monthItem}>
      <div>{getMonthName(date)}</div>
      <span>{amount}</span>
    </div>
  );
};

export default MonthItem;
