import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ReactNotifications } from 'react-notifications-component';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { ScrollToTop, useIsUserAuth } from 'components';
import AdminViewWidget from 'components/layout/AdminViewWidget';
import ErrorFallback from 'components/layout/ErrorFallback';

//pages
import SocketsConnect from 'src/app/SocketsConnect';
import ConfirmOrderPage from 'src/features/ConfirmOrderPage';
import NotFoundRoute from 'src/features/NotFoundRoute';
import NotificationsStore from 'src/features/NotificationsStore';
import ForgotPassword from 'src/features/onboarding/containers/ForgotPassword';
import Login from 'src/features/onboarding/containers/Login';
import Logout from 'src/features/onboarding/containers/Logout';
import Register from 'src/features/onboarding/containers/Register';
import ResetPassword from 'src/features/onboarding/containers/ResetPassword';
import OrderApprovalPage from 'src/features/OrderApprovalPage';
import SupplierFormPage from 'src/features/SupplierFormPage';
import SupplierPage from 'src/features/SupplierPage';
import UserArea from 'src/features/userArea';

import { useAuthUser } from '../../components';
import TawkToWidget from '../../components/layout/TawkToWidget';
import { PublicOnlyRoute } from './routes';

const AppRouter = () => {
  const isAuth = useIsUserAuth();
  const user = useAuthUser();

  return (
    <>
      <Router>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ScrollToTop />
          <SocketsConnect />
          <NotificationsStore />
          <ReactNotifications />
          <AdminViewWidget />
          <Switch>
            <PublicOnlyRoute
              exact
              path='/'
              component={Login}
            />
            <Route
              exact
              path='/supplier-form/:uid'
              component={SupplierFormPage}
            />
            <Route
              exact
              path='/supplier-page/:uid'
              component={SupplierPage}
            />
            <Route
              exact
              path='/confirm-order/:uid'
              component={ConfirmOrderPage}
            />
            <PublicOnlyRoute
              exact
              path='/login'
              component={() => <Redirect to={'/'} />}
            />
            <PublicOnlyRoute
              exact
              path='/register'
              component={Register}
            />
            <PublicOnlyRoute
              exact
              path='/forgot-password'
              component={ForgotPassword}
            />
            <PublicOnlyRoute
              exact
              path='/reset-password/:token'
              component={ResetPassword}
            />
            <PublicOnlyRoute
              exact
              path='/order-approval/:token'
              component={OrderApprovalPage}
            />
            <Route
              path='/404'
              component={NotFoundRoute}
            />
            <Route
              path='/logout'
              component={Logout}
            />
            <UserArea isAuthenticated={isAuth} />
          </Switch>
        </ErrorBoundary>
      </Router>
      <TawkToWidget
        username={user?.name}
        email={user?.email}
      />
    </>
  );
};

export default AppRouter;
