import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { PhoneInput, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { Button, Checkbox, Input } from 'src/components';
import { register } from 'src/features/onboarding/actions';

import style from '../../../../onboarding.module.scss';

const RegisterForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const validator = useValidator();

  const [name, setName] = useState('');
  const [officeName, setOfficeName] = useState('');
  const [email, setEmail] = useState('');
  const [agreementsAccepted, setAgreementsAccepted] = useState(false);
  const [password, setPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');
  const [nip, setNip] = useState('');
  const [phone, setPhone] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const userData = {
      email,
      name,
      password,
      nip,
      clinics_name: officeName,
      phone
    };

    try {
      setLoading(true);
      await dispatch(register(userData));
      history.push('/dashboard');
    } catch (err) {
      notifyApiError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      className={style.card}
      onSubmit={(e) => handleSubmit(e)}
    >
      <h2 className={style.formLabel}>Rejestracja</h2>
      <Input
        id='name'
        name='name'
        placeholder='Imię i nazwisko'
        value={name}
        onChange={(e) => setName(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <Input
        id='office-name'
        name='office-name'
        placeholder='Nazwa gabinetu'
        value={officeName}
        onChange={(e) => setOfficeName(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <Input
        id='email'
        name='email'
        placeholder='Adres e-mail'
        type='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        validator={validator}
        rule={'required|email'}
      />
      <Input
        id='nip'
        name='nip'
        placeholder='NIP'
        value={nip}
        onChange={(e) => setNip(e.target.value)}
        validator={validator}
        rule={'required|nip'}
      />
      <PhoneInput
        id='phone'
        name='phone'
        placeholder='Numer telefonu'
        value={phone}
        onChange={setPhone}
        validator={validator}
        rule={'required|phone'}
      />
      <Input
        id='password'
        name='password'
        placeholder='Hasło'
        type='password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        validator={validator}
        rule={'required|min_password'}
      />
      <Input
        id='repeatPassword'
        name='repeatPassword'
        placeholder='Powtórz hasło'
        type='password'
        value={secondPassword}
        onChange={(e) => setSecondPassword(e.target.value)}
        validator={validator}
        rule={`required|min_password|in:${password}`}
      />
      <label
        htmlFor='rememberMe'
        className={style.checkboxWrapper}
      >
        <Checkbox
          id={'agreements'}
          name='rememberMe'
          value={agreementsAccepted}
          onChange={(e) => setAgreementsAccepted(e.target.checked)}
          content={'Zgoda na wysyłkę materiałów marketingowych'}
          validator={validator}
          rule={'accepted'}
        />
      </label>
      <div className={style.buttons}>
        <Button
          type='submit'
          label={'Utwórz konto'}
          isLoading={isLoading}
        />
        <Link to={'/login'}>Masz już konto? Zaloguj się</Link>
      </div>
    </form>
  );
};

export default RegisterForm;
