import React, { useState } from 'react';
import moment from 'moment';

import { Button, Input, useValidator } from 'components';

import style from './ExtendDateForm.module.scss';

const ExtendDateForm = ({ handleValuationExtend, closeModal }) => {
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const validator = useValidator();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    setIsLoading(true);
    await handleValuationExtend(getCorrectDate());
    setIsLoading(false);
    closeModal();
  };

  const getCorrectDate = () => moment(date).set('hour', 23).set('minute', 59).set('second', 0).toISOString();

  return (
    <form
      className={style.container}
      onSubmit={handleSubmit}
    >
      <Input
        type='date'
        id='expired_date'
        name='expired_date'
        value={date}
        onChange={(e) => setDate(e.target.value)}
        validator={validator}
        rule={'required'}
        label={'Data wygaśnięcia'}
        wrapperStyle={style.input}
      />
      <Button
        label={'Ustaw nową datę wygaśnięcia'}
        type={'submit'}
        isLoading={isLoading}
        disabled={!date}
      />
    </form>
  );
};

export default ExtendDateForm;
