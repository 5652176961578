import React from 'react';
import classNames from 'classnames';

import { EmptyState, Loader } from 'components';

import CompanyThumbnail from '../CompanyThumbnail';

import style from './List.module.scss';
import shared from 'styles/Shared.module.scss';

const List = ({ companies = [], isLoading }) => {
  if (isLoading) {
    return (
      <div className={style.emptyState}>
        <div className={style.loaderWrapper}>
          <Loader center />
        </div>
      </div>
    );
  }

  if (!companies.length > 0) {
    return (
      <section className={classNames(shared.tableWrapper)}>
        <div className={shared.tableCategory}>
          <EmptyState type={'companies'} />
        </div>
      </section>
    );
  }

  return (
    <section className={classNames(shared.tableWrapper, style.container)}>
      {isLoading ? (
        <div className={style.loaderWrapper}>
          <Loader center />
        </div>
      ) : (
        <div className={classNames(shared.tableCategory, style.wrapper)}>
          {companies.map((company, index) => (
            <CompanyThumbnail
              company={company}
              index={index}
              key={company.id}
            />
          ))}
        </div>
      )}
    </section>
  );
};

export default List;
