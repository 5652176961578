import React from 'react';

import ProductInfo from '../ProductInfo';
import ReplacementsTable from './components/ReplacementsTable';

import styles from '../../ValuationReplacements.module.scss';

const ValuationReplacementsThumbnail = ({ replacement }) => {
  return (
    <div className={styles.table_item}>
      <ProductInfo product={replacement} />
      <ReplacementsTable
        replacements={replacement.replacement_items}
        pricingProdId={replacement.pricing_prod_id}
      />
    </div>
  );
};

export default ValuationReplacementsThumbnail;
