import React, { useEffect, useState } from 'react';

import { Select, useAuthUser } from 'components';

import { userRoles } from 'src/constants/enums';
import { userRolesOptions } from 'src/constants/misc';
import { getRoleColor, getUserRoleLabel } from 'src/utils/helpers';

import style from '../Filtering.module.scss';

const FilterByRole = ({ params }) => {
  const [selectedRole, setSelectedRole] = useState();
  const user = useAuthUser();

  const paramValue = params.get('role');

  useEffect(() => {
    if (selectedRole?.value !== paramValue && params.get('page')) {
      params.setFew([
        { key: 'role', value: selectedRole?.value },
        { key: 'page', value: '1' }
      ]);
    } else if (selectedRole?.value !== paramValue) {
      params.set('role', selectedRole?.value);
    }
  }, [selectedRole]);

  useEffect(() => {
    if (paramValue) {
      setSelectedRole({
        value: paramValue,
        label: getUserRoleLabel(paramValue),
        color: getRoleColor(paramValue)
      });
    }
  }, []);

  if (user?.role !== userRoles.admin) {
    return null;
  }

  return (
    <Select
      options={userRolesOptions}
      value={selectedRole}
      onChange={setSelectedRole}
      placeholder={'Rola'}
      isClearable
      wrapperStyle={style.roleSelect}
      isSearchable={false}
    />
  );
};

export default FilterByRole;
