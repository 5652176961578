import React from 'react';
import classNames from 'classnames';

import style from './ColumnSortButton.module.scss';

const sortTypesEnum = {
  asc: 'ASC',
  desc: 'DESC'
};

const ColumnSortButton = ({ children, parameterName, params, content }) => {
  const page = params.get('page');
  const sortValue = params.get('sort_by');
  const sortOption = params.get('sort_order');

  const isActive = sortValue === parameterName;
  const resetPage = page ? 1 : undefined;

  const handleClick = () => {
    if (parameterName) {
      if (sortValue !== parameterName) {
        params.setFew([
          { key: 'sort_by', value: parameterName },
          { key: 'sort_order', value: sortTypesEnum.desc },
          { key: 'page', value: resetPage }
        ]);
      } else if (isActive && sortOption === sortTypesEnum.desc) {
        params.setFew([
          { key: 'sort_by', value: parameterName },
          { key: 'sort_order', value: sortTypesEnum.asc },
          { key: 'page', value: resetPage }
        ]);
      } else {
        params.remove('sort_by');
        params.remove('sort_order');
        params.set('page', resetPage);
      }
    }
  };

  return (
    <button
      className={classNames(style.button, {
        [style.active]: isActive,
        [style.notActive]: !isActive,
        [style.asc]: isActive && sortOption === sortTypesEnum.asc,
        [style.desc]: isActive && sortOption === sortTypesEnum.desc
      })}
      onClick={handleClick}
    >
      {!!content && <p>{content}</p>}
      {children}
    </button>
  );
};

export default ColumnSortButton;
