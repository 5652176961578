import React, { useMemo } from 'react';

import { Tag, Thumbnail, useIsMobile } from 'components';

import { toDate, toTime } from 'src/utils/dateTime';
import { handleNumeralWords } from 'src/utils/helpers';

import style from './CollectionThumbnail.module.scss';

const CollectionThumbnail = ({ item: collection }) => {
  const isMobile = useIsMobile();

  const productsCountTag = useMemo(
    () => (
      <div className={style.row}>
        {!collection.id && (
          <Tag
            value={'Oferta główna'}
            color={'blue'}
          />
        )}
        <Tag
          value={`Zawiera ${collection.products_count || 0} ${handleNumeralWords(
            ['produkt', 'produkty', 'produktów'],
            collection.products_count || 0
          )}`}
        />
      </div>
    ),
    [collection]
  );

  const extraInfo = useMemo(
    () =>
      !!collection?.updated_at &&
      `Data ostatniej aktualizacji: ${toDate(collection.updated_at)}, ${toTime(collection.updated_at)}`,
    [collection]
  );

  const isGlobalCollection = useMemo(() => collection.collection_type === 'global', [collection]);

  return (
    <Thumbnail
      item={collection}
      link={`/supplier/collections/${collection.uid}`}
      namePrefix={isGlobalCollection ? 'Twoja oferta dla wszystkich klientów' : 'Oferta dla: '}
      datePrefix={'Utworzono: '}
      extraInfo={collection.nip && `NIP: ${collection.nip}`}
      areDataHidden={collection?.client_data_hidden && collection?.collection_type === 'individual'}
      status={productsCountTag}
      small={isMobile}
      hideOwner
    >
      <div className={style.wrapper}>{extraInfo && <p>{extraInfo}</p>}</div>
    </Thumbnail>
  );
};
export default CollectionThumbnail;
