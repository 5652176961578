import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Select, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';

import { refreshCategories } from '../../actions';

import style from './ChangeParent.module.scss';

const ChangeParent = ({ id, closeModal, categoryTree }) => {
  const [parentCategory, setParentCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const validator = useValidator();

  const options = useMemo(
    () => [
      { value: 0, label: ' - Brak kategorii nadrzędnej' },
      ...categoryTree.map((cat) => ({ value: cat.id, label: cat.name }))
    ],
    [categoryTree]
  );

  const parentChange = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    try {
      setIsLoading(true);
      await AdminApi.saveCategory({ id: id, parent: parentCategory.value });
      dispatch(refreshCategories());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={parentChange}
    >
      <Select
        options={options}
        id={'parent-select'}
        wrapperStyle={style.select}
        value={parentCategory}
        onChange={setParentCategory}
        label={'Nadkategoria'}
        placeholder={'Nadkategoria'}
        validator={validator}
        rule={'required'}
        isClearable
      />
      <Button
        label={'Zapisz'}
        title={'Zapisz'}
        type={'submit'}
        isLoading={isLoading}
        className={style.button}
      />
    </form>
  );
};

export default ChangeParent;
