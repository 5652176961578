import { useState } from 'react';

import { useModalConfirm } from 'components';

import { AccountsApi } from 'src/api';
import { OrdersApi } from 'src/api';
import { SupplierApi } from 'src/api';

import SaveButton from '../SaveButton';
import { notifyApiError } from '../Toasts';

import style from './AttachmentThumbnail.module.scss';

const AttachmentThumbnail = ({ userAttachment, attachmentName, uid, supplierId, refreshDataCallback }) => {
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isLoading, setIsLoading] = useState(false);
  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);

  const deleteAttachment = async () => {
    const apiCallback = userAttachment ? OrdersApi.deleteUserAttachment : SupplierApi.deleteSupplierAttachment;
    try {
      setIsLoading(true);
      await apiCallback({ uid, file_name: attachmentName, supplier_id: supplierId });
      refreshDataCallback();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAttachmentHandler = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć załącznik?',
      handleConfirm: deleteAttachment
    });
  };

  const downloadAttachment = async () => {
    const params = { name: attachmentName };

    try {
      setIsAttachmentLoading(true);
      const { data } = await AccountsApi.getAttachment(params);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', attachmentName);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsAttachmentLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <p className={style.sumText}>{attachmentName}</p>
      <div className={style.btns}>
        <SaveButton
          onClick={downloadAttachment}
          type='download'
          isLoading={isAttachmentLoading}
        />
        <SaveButton
          onClick={deleteAttachmentHandler}
          type='delete'
          isLoading={isLoading}
        />
      </div>
      {renderedModalConfirm}
    </div>
  );
};

export default AttachmentThumbnail;
