import React, { useState } from 'react';
import dayjs from 'dayjs';

import { Button } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { WholesalersApi } from 'src/api';
import { EXCEL_FILE_TYPE } from 'src/constants/misc';
import { downloadFile, getDecodeBase64File } from 'src/utils/helpers';

import styles from '../../WholesalerImportExportForm.module.scss';

const ExportForm = ({ wholesalerId }) => {
  const [isDownloadingTemplateCSV, setIsDownloadingTemplateCSV] = useState(false);
  const [isDownloadingTemplateXLSX, setIsDownloadingTemplateXLSX] = useState(false);
  const [isDownloadingProductsCSV, setIsDownloadingProductsCSV] = useState(false);
  const [isDownloadingProductsXLSX, setIsDownloadingProductsXLSX] = useState(false);

  const setProperLoading = (type, value, action) => {
    if (type === 'CSV' && action === 'template') {
      setIsDownloadingTemplateCSV(value);
    } else if (type === 'XLSX' && action === 'template') {
      setIsDownloadingTemplateXLSX(value);
    } else if (type === 'CSV' && action === 'products') {
      setIsDownloadingProductsCSV(value);
    } else if (type === 'XLSX' && action === 'products') {
      setIsDownloadingProductsXLSX(value);
    }
  };

  const downloadTemplateHandler = async (type) => {
    const date = dayjs().format('DD.MM.YYYY');

    try {
      setProperLoading(type, true, 'template');
      const { data } = await WholesalersApi.exportWholesalersProductsTemplateFile({
        file_format: type
      });

      if (type === 'CSV') {
        const blob = new Blob([data], { type: 'text/csv' });
        downloadFile(blob, 'hurtownia_produkty_flatfile_export_' + date + '.csv');
      } else {
        const blob = getDecodeBase64File(data, EXCEL_FILE_TYPE);
        downloadFile(blob, 'hurtownia_produkty_flatfile_export_' + date + '.xlsx');
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setProperLoading(type, false, 'template');
    }
  };

  const downloadProductsHandler = async (type) => {
    const date = dayjs().format('DD.MM.YYYY');

    try {
      setProperLoading(type, true, 'products');
      const { data } = await WholesalersApi.exportWholesalerProducts({
        wholesaler_id: wholesalerId,
        file_format: type
      });

      if (type === 'CSV') {
        const blob = new Blob([data], { type: 'text/csv' });
        downloadFile(blob, 'hurtownia_produkty_export_' + date + '.csv');
      } else {
        const blob = getDecodeBase64File(data, EXCEL_FILE_TYPE);
        downloadFile(blob, 'hurtownia_produkty_export_' + date + '.xlsx');
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setProperLoading(type, false, 'products');
    }
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h3>Export szablonu</h3>
        <p>
          Pobierz szablon, uzupełnij kody produktów a następnie przejdź do zakładki <strong>Import</strong> i prześlij plik.
        </p>
      </header>
      <div className={styles.buttonsWrapper}>
        <Button
          label={'Pobierz szablon (.csv)'}
          isLoading={isDownloadingTemplateCSV}
          onClick={() => downloadTemplateHandler('CSV')}
        />
        <Button
          label={'Pobierz szablon (.xlsx)'}
          isLoading={isDownloadingTemplateXLSX}
          onClick={() => downloadTemplateHandler('XLSX')}
        />
      </div>
      <header className={styles.header}>
        <h3>Export produktów</h3>
        <p>Pobierz plik z produktami i uzupełnionymi kodami produktów dla danej hurtownii.</p>
      </header>
      <div className={styles.buttonsWrapper}>
        <Button
          label={'Pobierz produkty (.csv)'}
          isLoading={isDownloadingProductsCSV}
          onClick={() => downloadProductsHandler('CSV')}
        />
        <Button
          label={'Pobierz produkty (.xlsx)'}
          isLoading={isDownloadingProductsXLSX}
          onClick={() => downloadProductsHandler('XLSX')}
        />
      </div>
    </div>
  );
};

export default ExportForm;
