import React, { useRef } from 'react';
import { Popover } from 'antd';
import cn from 'classnames';

import { Icon } from 'components';
import useOutsideClick from 'components/hooks/useOutsideClick';
import Button from 'components/layout/Actions/components/Button';

import styles from './Actions.module.scss';

const Actions = (props) => {
  const { classActionsHead, classActionsOption, items, disabled = false } = props;

  const elementTarget = useRef(null);
  const [isActive, setIsActive] = useOutsideClick(elementTarget);

  const handleClickOption = async (fn) => {
    await fn();
  };

  const content = (
    <div
      className={styles.actionsWrapper}
      ref={elementTarget}
    >
      {items.map((item, index) => (
        <Button
          classActionsOption={classActionsOption}
          toggle={() => setIsActive(false)}
          handleClickOption={handleClickOption}
          key={item.title + index.toString()}
          index={index}
          item={item}
        />
      ))}
    </div>
  );

  return (
    <Popover
      trigger={'click'}
      placement={'bottomRight'}
      content={content}
      open={!disabled && isActive}
    >
      <button
        id={'menu-button'}
        onClick={() => setIsActive(true)}
        className={cn(styles.head, classActionsHead, {
          [styles.disabled]: disabled
        })}
      >
        <Icon
          name='more-horizontal'
          size='20'
        />
      </button>
    </Popover>
  );
};

export default Actions;
