import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import { useAtom } from 'jotai/index';

import { EmptyState, PaginationScroll, Table, useIsAdmin, usePermissions, useRequestAbortController } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import { selectedCompanyAtom } from 'src/features/Warehouse';
import SingleSupply from 'src/features/Warehouse/new/ProductDetails/components/Supplies/components/SingleSupply';
import productStyle from 'src/features/Warehouse/new/ProductDetails/components/Supplies/components/SingleSupply/SingleSupply.module.scss';
import query from 'src/utils/query';

import style from './Supplies.module.scss';

const Supplies = (props) => {
  const { warehouseProdId, refreshCallback } = props;
  const [canViewOrders] = usePermissions([userPermissions.orders.read]);

  const params = query(props);
  const isAdmin = useIsAdmin();
  const wasDataLoaded = useRef(false);

  const [page, setPage] = useState(1);
  const [supplies, setSupplies] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [abortController, setNewController] = useRequestAbortController();

  const [selectedCompany] = useAtom(selectedCompanyAtom);

  const columns = canViewOrders
    ? [
        { name: 'Typ dostawy' },
        { name: 'Data' },
        { name: 'Dodana ilość' },
        { name: 'W magazynie' },
        { name: 'Cena za szt.' },
        { name: 'Data ważności' },
        { name: 'Zamówienie' }
      ]
    : [
        { name: 'Typ dostawy' },
        { name: 'Data' },
        { name: 'Dodana ilość' },
        { name: 'W magazynie' },
        { name: 'Data ważności' },
        { name: 'Zamówienie' }
      ];

  const getData = async (page = 1, clearData) => {
    const queryParams = {
      page: 1,
      perPage: 10,
      new_warehouse_products_id: warehouseProdId,
      ...(isAdmin && { company_id: selectedCompany })
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await WarehouseApi.getProductSupplies(queryParams, signal);

      if (clearData || data.data.length === 0) {
        setSupplies(data.data);
        setHasMore(data.data.length < data.total);
      } else {
        setSupplies((prev) => [...prev, ...data.data]);
        setHasMore(data.data.length + supplies.length < data.total);
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreResults = () => {
    if (!isLoading) {
      getData(page + 1);
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (!wasDataLoaded.current) {
      getData(1);
      wasDataLoaded.current = true;
    }
  }, []);

  return (
    <div className={style.container}>
      <Table
        columns={columns}
        params={params}
        headerClasses={classNames(productStyle.gridRow, { [productStyle.hidePrices]: !canViewOrders })}
        className={style.table}
      >
        <PaginationScroll
          data={supplies}
          hasMore={hasMore}
          page={page}
          height={300}
          loadMoreResults={loadMoreResults}
          emptyState={
            <EmptyState
              type={'supplies'}
              tiny
            />
          }
          loader={
            <Skeleton
              height={38}
              count={6}
              style={{ borderRadius: 8 }}
            />
          }
        >
          {supplies.map((supply) => (
            <SingleSupply
              key={supply.id}
              supply={supply}
              refreshSupplies={getData}
              refreshProductsList={refreshCallback}
            />
          ))}
        </PaginationScroll>
      </Table>
    </div>
  );
};

export default Supplies;
