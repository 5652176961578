export const ADD_GIFT = 'ADD_GIFT';
export const DELETE_GIFT = 'DELETE_GIFT';

export const SET_SHIPPING_PRICE = 'SET_SHIPPING_PRICE';
export const SET_FREE_SHIPPING_PRICE = 'SET_FREE_SHIPPING_PRICE';

export const TOGGLE_PAYMENT_METHOD = 'TOGGLE_PAYMENT_METHOD';

export const TOGGLE_SHIPMENT_METHOD = 'TOGGLE_SHIPMENT_METHOD';

export const ADD_COMMENT = 'ADD_COMMENT';

export const CLEAR_SUPPLIER_FORM_STORE = 'CLEAR_SUPPLIER_FORM_STORE';

export const CHANGE_PRICE_TYPE = 'CHANGE_PRICE_TYPE';

export const GET_FORM_DATA_REQUEST = 'GET_FORM_DATA_REQUEST';
export const GET_FORM_DATA_SUCCESS = 'GET_FORM_DATA_SUCCESS';
export const GET_FORM_DATA_FAILURE = 'GET_FORM_DATA_FAILURE';

export const SET_NEW_SHIPMENT_METHODS = 'SET_NEW_SHIPMENT_METHODS';

export const DELETE_SHIPMENT_METHODS = 'DELETE_SHIPMENT_METHODS';

export const ADD_PRODUCTS_PRICING = 'ADD_PRODUCTS_PRICING';
export const UPDATE_PRODUCT_PRICING = 'UPDATE_PRODUCT_PRICING';

export const SET_NEW_VALIDATOR = 'SET_NEW_VALIDATOR';
export const INCREMENT_NUMBER_OF_VALIDATIONS = 'INCREMENT_NUMBER_OF_VALIDATIONS';

export const UPDATE_IS_DISABLED = 'UPDATE_IS_DISABLED';

export const SET_INITIAL_FORM_DATA = 'SET_INITIAL_FORM_DATA';

export const SET_IS_DATA_SAVED = 'SET_IS_DATA_SAVED';
