import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { Menu, Modal, SupplierColor, SwitchButton, Tag, useIsAdmin, useIsMobile, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { UserApi, WholesalersApi } from 'src/api';
import { changeSuppliersVersion } from 'src/features/Suppliers/actions';
import { getFormattedPhoneNumber } from 'src/utils/helpers';

import AssignWholesalerForm from '../AssignWholesalerForm';
import SupplierEmailForm from '../SupplierEmailForm';
import SupplierForm from '../SupplierForm';

import style from './SuppliersItems.module.scss';

const SupplierItem = ({ supplier, manageCheckbox }) => {
  const { id, name, email, phone, username, company_name, color, client_data_hidden, wholesaler_name } = supplier || {};
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isAdmin = useIsAdmin();

  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assignToWholesalerOpen, setAssignToWholesalerOpen] = useState(false);
  const [hideClientData, setHideClientData] = useState(!!client_data_hidden);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const deleteSupplier = async () => {
    try {
      await UserApi.deleteSupplier({ id });
      dispatch(changeSuppliersVersion());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const changeClientDataVisibilityHandler = async (data) => {
    try {
      setIsLoading(true);
      await UserApi.editSupplier({
        id,
        client_data_hidden: +data
      });
      dispatch(changeSuppliersVersion());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDissociateWholesaler = async () => {
    try {
      setIsLoading(true);
      const { data } = await WholesalersApi.dissociateWholesalerFromSupplier(id);
      notifyCommon([data.message]);
      dispatch(changeSuppliersVersion());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = () => {
    handleOpenModalConfirm({
      message: `Czy na pewno chcesz usunąć dostawcę? (${supplier.name})`,
      handleConfirm: deleteSupplier
    });
  };

  const openDissociateWholesalerConfirm = () => {
    handleOpenModalConfirm({
      message: `Czy na pewno chcesz usunąć dostawcę (${email}) z hurtowni ${wholesaler_name}?`,
      handleConfirm: handleDissociateWholesaler
    });
  };

  const menu = [
    {
      title: 'Edytuj',
      icon: 'edit',
      action: () => setIsModalOpen(true)
    },
    {
      title: 'Edytuj adres e-mail',
      icon: 'edit',
      color: 'blue',
      action: () => setTimeout(() => setIsActive(true), 50),
      roles: ['admin']
    },
    ...(wholesaler_name
      ? [
          {
            title: 'Usuń z hurtowni',
            icon: 'trash',
            color: 'red',
            action: openDissociateWholesalerConfirm,
            roles: ['admin']
          }
        ]
      : [
          {
            title: 'Przypisz do hurtowni',
            icon: 'add',
            action: () => setAssignToWholesalerOpen(true),
            roles: ['admin']
          }
        ]),
    {
      title: 'Usuń',
      icon: 'trash',
      action: handleDelete,
      color: 'red'
    }
  ];

  const containerClasses = classnames(style.container, {
    [style.admin]: isAdmin
  });

  useEffect(() => {
    setHideClientData(!!client_data_hidden);
  }, [supplier]);

  return (
    <div className={containerClasses}>
      <div className={style.wrapper}>
        <div className={classnames(style.text, style.name)}>
          <div>{manageCheckbox}</div>
          {name}
        </div>
        {color && <SupplierColor supplier={supplier} />}
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>E-mail:</p>}
        {isAdmin ? (
          <SupplierEmailForm
            currentEmail={email}
            isActive={isActive}
            setIsActive={setIsActive}
            id={id}
          />
        ) : (
          <p className={style.text}>{email}</p>
        )}
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Telefon:</p>}
        <span className={style.text}>{getFormattedPhoneNumber(phone)}</span>
      </div>
      {isAdmin && (
        <div className={style.wrapper}>
          {isMobile && <p className={style.text}>Hurtownia:</p>}
          <span className={style.text}>{wholesaler_name || '-'}</span>
        </div>
      )}
      {isAdmin && (
        <div className={style.wrapper}>
          {isMobile && <p className={style.text}>Dodany przez:</p>}
          <span className={style.text}>
            <Tag
              value={company_name || username || '-'}
              className={style.tag}
            />
          </span>
        </div>
      )}
      {isAdmin && (
        <div className={style.wrapper}>
          {isMobile && <p className={style.text}>Ukryj dane:</p>}
          <SwitchButton
            value={hideClientData}
            setValue={setHideClientData}
            onClick={changeClientDataVisibilityHandler}
            isLoading={isLoading}
          />
        </div>
      )}
      <div className={classnames(style.wrapper, style.reverse)}>
        <Menu
          actions={menu}
          className={style.menu}
        />
      </div>
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={'Edytuj dane dostawcy'}
      >
        <SupplierForm
          supplier={supplier}
          closeModal={() => setIsModalOpen(false)}
        />
      </Modal>
      <Modal
        visible={assignToWholesalerOpen}
        onClose={() => setAssignToWholesalerOpen(false)}
        title={`Przypisz dostawcę (${email}) do hurtowni`}
      >
        <AssignWholesalerForm
          supplierId={supplier.id}
          closeModal={() => setAssignToWholesalerOpen(false)}
          refreshCallback={() => dispatch(changeSuppliersVersion())}
        />
      </Modal>
      {renderedModalConfirm}
    </div>
  );
};

export default SupplierItem;
