import React from 'react';

import { InfoWidget } from 'components';

import { CONTACT_EMAIL, KATE_PHONE_NUMBER, KATE_PHONE_NUMBER_FORMATTED } from 'src/constants/misc';

import style from './BlurDataInfo.module.scss';

const BlurDataInfo = () => {
  return (
    <InfoWidget place={'right'}>
      <div className={style.info}>
        <p>Skontaktuj się z nami, aby odblokować dostęp do danych klienta.</p>
        <a
          className={style.link}
          href={`tel:${KATE_PHONE_NUMBER}`}
        >
          {KATE_PHONE_NUMBER_FORMATTED}
        </a>
        <a
          className={style.link}
          href={`mailto:${CONTACT_EMAIL}`}
        >
          {CONTACT_EMAIL}
        </a>
      </div>
    </InfoWidget>
  );
};

export default BlurDataInfo;
