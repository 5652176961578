import React from 'react';

import { CustomTooltip } from 'components';

import { ReactComponent as PriceIcon } from 'src/assets/icons/price-icon.svg';

import style from './PricesCounter.module.scss';

const PricesCounter = ({ count }) => {
  if (count < 1 || typeof count === 'undefined') {
    return null;
  }

  const text = <p>Ten produkt został już wyceniony wcześniej przez {count === 1 ? 'jednego' : count} z Twoich dostawców.</p>;

  return (
    <CustomTooltip title={text}>
      <PriceIcon className={style.icon} />
    </CustomTooltip>
  );
};

export default PricesCounter;
