import React from 'react';
import classNames from 'classnames';

import style from '../../ProductsTable.module.scss';
import shared from 'styles/Shared.module.scss';

const CategoryProductsWrapper = ({ name, children }) => {
  return (
    <div className={classNames(shared.tableCategory, style.table)}>
      <h3
        className={shared.tableCategoryTitle}
        id={'category-name'}
      >
        {name}
      </h3>

      {children}
    </div>
  );
};

export default CategoryProductsWrapper;
