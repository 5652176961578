import React, { useState } from 'react';

import { Button, Input, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';

import style from './NewDepartmentForm.module.scss';

const NewDepartmentForm = ({ id, currentName, onClose, refreshData }) => {
  const validator = useValidator();
  const [name, setName] = useState(currentName);
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const data = {
      name,
      ...(id && { department_id: id })
    };

    try {
      setIsLoading(true);
      await WarehouseApi.addOrUpdateDepartment(data);
      if (refreshData) await refreshData();
      notifyCommon([`Jednostka "${name}" została ${id ? 'zapisana' : 'dodana'}.`]);
      if (onClose) onClose();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={submitHandler}
    >
      <Input
        id='name'
        name='name'
        label='Nazwa'
        value={name}
        onChange={(e) => setName(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <Button
        type={'submit'}
        label={id ? 'Zapisz' : 'Dodaj'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default NewDepartmentForm;
