import React from 'react';
import classNames from 'classnames';

import { EmptyState } from 'components';
import SupplierClientThumbnail from 'components/layout/SupplierClientThumbnail';

import { userRoles } from '../../../../constants/enums';

import style from './SupplierClientConnections.module.scss';
import shared from 'styles/Shared.module.scss';

const SupplierClientConnections = ({ user, closeModal }) => {
  if (user.role !== userRoles.supplier) {
    return null;
  }

  return (
    <div>
      <section className={shared.tableWrapper}>
        {user.connected_suppliers.length ? (
          <>
            <div className={classNames(shared.tableHeader, style.tableHeader)}>
              <p>E-mail klienta</p>
              <p>Nazwa kliniki</p>
              <p>Nazwa dostawcy</p>
            </div>
            {user.connected_suppliers.map((clinic) => (
              <SupplierClientThumbnail
                supplierId={user.id}
                key={clinic.id}
                clinic={clinic}
                closeModal={closeModal}
              />
            ))}
          </>
        ) : (
          <EmptyState type={'supplierClients'} />
        )}
      </section>
    </div>
  );
};

export default SupplierClientConnections;
