import React, { useState } from 'react';

import { OptionSwitch } from 'components';

import ExistedMemberForm from './components/ExistedMemberForm';
import NewMemberForm from './components/NewMemberForm';

import style from './MemberForm.module.scss';

const MemberForm = ({ closeModal, changeVersion }) => {
  const [isEmployeeExist, setIsEmployeeExist] = useState(true);

  return (
    <div className={style.container}>
      <OptionSwitch
        falseLabel={'Dodaj nowego pracownika'}
        trueLabel={'Dodaj istniejącego'}
        defaultOption={isEmployeeExist}
        onChange={setIsEmployeeExist}
      />
      {isEmployeeExist ? (
        <ExistedMemberForm
          closeModal={closeModal}
          changeVersion={changeVersion}
        />
      ) : (
        <NewMemberForm
          closeModal={closeModal}
          changeVersion={changeVersion}
        />
      )}
    </div>
  );
};

export default MemberForm;
