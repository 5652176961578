import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { userRoles } from '../../constants/enums';

const UseIsAdmin = (props) => {
  const user = useSelector((state) => state.auth.user);
  return useMemo(() => user?.role === userRoles.admin, [user]);
};

export default UseIsAdmin;
