import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';

import {
  Button,
  Modal,
  PageHeader,
  PaginationNav,
  Searchbar,
  SeoHelmet,
  useRequestAbortController,
  useSearchParam
} from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { VariationsApi } from 'src/api';
import pages from 'src/dictionaries/pages.json';
import { getCorrectFormOfGroupsLabel } from 'src/utils/helpers';
import query from 'src/utils/query';

import DefineGroupForm from './components/DefineGroupForm';
import MergeGroupsForm from './components/MergeGroupsForm';
import VariationsGroups from './components/VariationsGroups';

import styles from './ProductVariationsManagement.module.scss';

const ProductVariationsManagement = (props) => {
  const params = query(props);
  const version = useSelector((state) => state.variations.version);

  const page = useSearchParam(params, 'page');
  const search = useSearchParam(params, 'search');

  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageData, setPageData] = useState([]);
  const [total, setTotal] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isSilentLoading, setIsSilentLoading] = useState(false);

  const [isDefineGroupOpen, setIsDefineGroupOpen] = useState(false);
  const [isMergeGroupsOpen, setIsMergeGroupsOpen] = useState(false);

  const [abortController, setNewController] = useRequestAbortController();

  const getData = async (silent = false) => {
    if (abortController) abortController.abort();
    const signal = setNewController();

    const params = {
      page: page || 1,
      perPage: perPage,
      ...(search && { search })
    };

    try {
      !silent ? setIsLoading(true) : setIsSilentLoading(true);
      const { data } = await VariationsApi.getGroups(params, signal);
      setPageQty(data.last_page);
      setPageData(data.data);
      setTotal(data.total);
    } catch (err) {
      notifyApiError(err);
    } finally {
      !silent ? setIsLoading(false) : setIsSilentLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [version, search, page]);

  return (
    <>
      <SeoHelmet title={pages.variations.title} />
      <PageHeader
        name={pages.variations.title}
        text={
          <div>
            {isLoading ? (
              <Skeleton
                width={50}
                height={20}
              />
            ) : (
              getCorrectFormOfGroupsLabel(total)
            )}
          </div>
        }
      >
        <Button
          label={'Połącz grupy'}
          onClick={() => setIsMergeGroupsOpen(true)}
          gray
        />

        <Button
          label={'Zdefiniuj grupę'}
          iconName={'add'}
          onClick={() => setIsDefineGroupOpen(true)}
        />
      </PageHeader>
      <div className={styles.filters}>
        <Searchbar
          params={params}
          placeholder={'Wyszukaj grupę, wpisując jej nazwę lub nazwę produktu, który zawiera...'}
          searchSize={'small'}
          fullWidth
        />
      </div>
      <VariationsGroups
        groups={pageData}
        emptyStateType={search ? 'search' : 'emptyVariationsGroups'}
        isLoading={isLoading}
        isSilentLoading={isSilentLoading}
        refreshCallback={getData}
      />
      <PaginationNav
        params={params}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
        hideSelect
      />
      <Modal
        visible={isDefineGroupOpen}
        onClose={() => setIsDefineGroupOpen(false)}
        title={'Zdefiniuj grupę'}
      >
        <DefineGroupForm closeModal={() => setIsDefineGroupOpen(false)} />
      </Modal>
      <Modal
        visible={isMergeGroupsOpen}
        onClose={() => setIsMergeGroupsOpen(false)}
        title={'Połącz grupy'}
      >
        <MergeGroupsForm closeModal={() => setIsMergeGroupsOpen(false)} />
      </Modal>
    </>
  );
};

export default ProductVariationsManagement;
