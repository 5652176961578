import React, { useEffect, useState } from 'react';

import {
  Button,
  EmptyState,
  Loader,
  Modal,
  PaginationNav,
  ProfileSection as Section,
  Searchbar,
  Tag,
  useSearchParam
} from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import DepartmentThumbnail from 'src/features/Profile/features/Warehouse/components/DepartmentThumbnail';
import NewDepartmentForm from 'src/features/Profile/features/Warehouse/components/NewDepartmentForm';
import { handleNumeralWords } from 'src/utils/helpers';
import query from 'src/utils/query';

import style from './Warehouse.module.scss';

const Warehouse = (props) => {
  const params = query(props);

  const [selectedElement, setSelectedElement] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);

  const page = useSearchParam(params, 'page') || 1;
  const search = useSearchParam(params, 'search');

  const getData = async () => {
    const params = { page, perPage, search };

    try {
      const { data } = await WarehouseApi.getDepartments(params);
      setLastPage(data.last_page);
      setDepartments(data.data);
      setTotal(data.total);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    const getDataHandler = async () => {
      setIsLoading(true);
      await getData();
      setIsLoading(false);
    };

    getDataHandler();
  }, [search, page, perPage]);

  useEffect(() => {
    return () => {
      params.remove('page');
    };
  }, []);

  const addButton = (
    <>
      <Searchbar params={params} />
      <Button
        label={'Dodaj jednostkę'}
        onClick={() => setIsModalOpen(true)}
      />
    </>
  );

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedElement(null);
  };

  const editElementHandler = (element) => {
    setIsModalOpen(true);
    setSelectedElement(element);
  };

  const refreshAfterDeleteHandler = () => {
    params.remove('page');
    return getData();
  };

  return (
    <Section
      isLoading={isLoading}
      title={'Jednostki organizacyjne'}
      text={<Tag value={`${total} ${handleNumeralWords(['pozycja', 'pozycje', 'pozycji'], total)}`} />}
      button={addButton}
    >
      <Modal
        title={selectedElement?.id ? `Edytuj jednostkę "${selectedElement?.name}"` : 'Dodaj jednostkę'}
        visible={isModalOpen}
        onClose={closeModal}
      >
        <NewDepartmentForm
          onClose={closeModal}
          refreshData={getData}
          id={selectedElement?.id}
          currentName={selectedElement?.name}
        />
      </Modal>
      <div className={style.container}>
        {isLoading ? (
          <Loader />
        ) : departments.length > 0 ? (
          departments.map((dep) => (
            <DepartmentThumbnail
              refreshData={refreshAfterDeleteHandler}
              onEdit={() => editElementHandler(dep)}
              department={dep}
              key={dep.id}
            />
          ))
        ) : (
          <EmptyState
            type={'departments'}
            tiny
          />
        )}
        <PaginationNav
          params={params}
          pagesQty={lastPage}
          setPerPage={setPerPage}
          defaultQty={perPage}
          hidePadding
          hideSelect
        />
      </div>
    </Section>
  );
};

export default Warehouse;
