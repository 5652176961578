import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AsyncSelect, Button, FileDropzone, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OCRInvoicesApi, WholesalersApi } from 'src/api';

import { changeOcrVersion } from '../../../../../../actions';

import style from './UploadInvoiceForm.module.scss';

const UploadInvoiceForm = ({ closeModal }) => {
  const selectedCompany = useSelector((state) => state.cartAndCatalog.selectedCompany);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const validator = useValidator();
  const dispatch = useDispatch();

  const uploadOCRInvoice = async () => {
    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const formData = new FormData();
    const invoiceData = {
      company_id: selectedCompany,
      wholesaler_id: selectedSupplier.value,
      invoice
    };

    for (const [key, value] of Object.entries(invoiceData)) {
      if (value) formData.append(key, value);
    }

    try {
      setIsLoading(true);
      await OCRInvoicesApi.uploadInvoice(formData);
      if (closeModal) closeModal();
      dispatch(changeOcrVersion());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <FileDropzone
        label={'Plik z fakturą'}
        onChange={setInvoice}
        value={invoice}
        validator={validator}
        rule={'required'}
        id={'file'}
        name={'file'}
      />
      <AsyncSelect
        value={selectedSupplier}
        onChange={setSelectedSupplier}
        apiCallback={WholesalersApi.getWholesalers}
        valueKey={'id'}
        emailKey={'domain'}
        labelKey={'name'}
        label={'Hurtownia'}
        placeholder={'Dostawca'}
        queryParams={{ pagination: 1, company_id: selectedCompany }}
        isClearable
        validator={validator}
        rule={'required'}
      />
      <Button
        label={'Przeanalizuj fakturę'}
        onClick={uploadOCRInvoice}
        isLoading={isLoading}
      />
    </div>
  );
};

export default UploadInvoiceForm;
