import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useCompany, usePermissions } from 'components';
import PhotoUploader from 'components/layout/PhotoUploader';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { CompanyApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';

import { refreshUserData } from '../../../../../onboarding/actions';

const LogoUploader = () => {
  const company = useCompany();
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [canEdit] = usePermissions([userPermissions.company.admin]);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setIsImageLoading(true);
    try {
      await CompanyApi.saveLogo({ image: data });
      notifyCommon(['Zaktualizowano zdjęcie profilowe!']);
      await dispatch(refreshUserData());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsImageLoading(false);
    }
  };

  return (
    <PhotoUploader
      onSubmit={onSubmit}
      isLoading={isImageLoading}
      sourceImage={company?.logo_medium}
      disabled={!canEdit}
      showSmaller
      showRound
    />
  );
};

export default LogoUploader;
