import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, LoaderGlobal, Modal, PageHeader, SeoHelmet } from 'components';
import { headerTextPlaceholder } from 'components/layout/PageHeader/placeholders';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import pages from 'src/dictionaries/pages.json';
import { getCorrectFormOfResultsLabel } from 'src/utils/helpers';

import AddNode from './components/AddNode';
import SingleNode from './components/SingleNode';

import style from './CategoriesManager.module.scss';

const CategoriesManager = () => {
  const [treeData, setTreeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [visible, setVisible] = useState(false);
  const [itemsQty, setItemsQty] = useState(false);
  const version = useSelector((state) => state.categories.version);

  const getDataHandler = async () => {
    try {
      const { data } = await AdminApi.getCategories();
      setTreeData(
        data.map((element) => ({
          count: element.products_count,
          ...element
        }))
      );
      setItemsQty(data?.length);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      await getDataHandler();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const addCategory = async ({ text, parent = 0 }) => {
    try {
      setVisible(false);
      setIsSaving(true);
      await AdminApi.addCategory({ name: text, parent: parent });
      getData();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (version > 0) {
      getDataHandler();
    }
  }, [version]);

  return (
    <>
      <SeoHelmet title={pages.categories.title} />
      <PageHeader
        name={pages.categories.title}
        wrapOnMobile
        text={isLoading ? headerTextPlaceholder() : getCorrectFormOfResultsLabel(itemsQty)}
      >
        <div className={style.buttonWrapper}>
          <Button
            onClick={() => setVisible(true)}
            label='Nowa kategoria'
            isLoading={isSaving}
          />
        </div>
      </PageHeader>
      <div>
        <div className={style.treeContainer}>
          {isLoading ? (
            <LoaderGlobal />
          ) : (
            treeData?.map((cat) => {
              if (!cat.parent)
                return (
                  <SingleNode
                    key={cat.id}
                    className={style.label}
                    category={cat}
                    depth={0}
                    treeData={treeData}
                  />
                );
            })
          )}
        </div>
        <Modal
          visible={visible}
          onClose={() => setVisible(false)}
          title={'Dodaj kategorię'}
        >
          <AddNode
            categoryTree={treeData}
            closeModal={() => setVisible(false)}
            onClick={addCategory}
          />
        </Modal>
      </div>
    </>
  );
};

export default CategoriesManager;
