import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Button, Checkbox, Input, useLocalStorage, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { getUserData, login } from 'src/features/onboarding/actions';
import { removeSessionStorageItem, setSessionStorageItem } from 'src/utils/storage';

import style from '../../../../onboarding.module.scss';

const LoginForm = ({ onLoginSucceeded }) => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState({ email: '', password: '' });
  const [isLoginError, setIsLoginError] = useState(false);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [rememberMe, setRememberMe] = useLocalStorage('rememberMe', false);

  const validator = useValidator();
  const dispatch = useDispatch();

  const handleRedirectAfterLogin = () => {
    if (location.state?.from) {
      history.push(location.state.from);
    } else if (onLoginSucceeded) {
      onLoginSucceeded();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoginError(false);

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    try {
      await dispatch(login(data));
      await dispatch(getUserData());
      setSessionStorageItem('isAdminView', false);
      removeSessionStorageItem('adminId');
      handleRedirectAfterLogin();
    } catch (err) {
      notifyApiError(err);
      setIsLoginError(true);
    }
  };

  useEffect(() => {
    setRememberMe(false);
  }, []);

  const onChange = (e) => {
    setIsLoginError(false);
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <form
      className={style.card}
      onSubmit={handleSubmit}
    >
      <h2 className={style.formLabel}>Logowanie</h2>
      <Input
        id='email'
        name='email'
        placeholder='Adres e-mail'
        value={data.email}
        onChange={onChange}
        disabled={isLoading}
        validator={validator}
        rule={'required|email'}
        extraError={isLoginError ? 'Adres e-mail lub hasło są niepoprawne' : ''}
        auto='true'
      />
      <Input
        id='password'
        name='password'
        placeholder='Hasło'
        type='password'
        value={data.password}
        onChange={onChange}
        validator={validator}
        disabled={isLoading}
        rule={'required'}
        extraError={isLoginError ? 'Adres e-mail lub hasło są niepoprawne' : ''}
        auto='true'
      />
      <label
        htmlFor='rememberMe'
        className={style.checkboxWrapper}
      >
        <Checkbox
          name='rememberMe'
          value={rememberMe}
          onChange={(e) => setRememberMe(e.target.checked)}
          content={'Zapamiętaj mnie'}
        />
      </label>
      <div className={style.buttons}>
        <Button
          type='submit'
          id='login-button'
          label={'Logowanie'}
          isLoading={isLoading}
          className={style.button}
        />
        <Link to={'/forgot-password'}>Nie pamiętam hasła</Link>
      </div>
    </form>
  );
};

export default LoginForm;
