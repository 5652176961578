import React, { useState } from 'react';
import classnames from 'classnames';

import { CompanyDetails, CompanyForm, Modal, ProfileSection as Section, useCompany } from 'components';

import style from './Company.module.scss';

const Company = ({ inProfile, company }) => {
  const userCompany = useCompany();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const content = (
    <>
      <div className={classnames(style.wrapper, { [style.single]: !inProfile })}>
        <CompanyDetails details={company || userCompany} />
      </div>
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={'Dodaj dane do faktury'}
      >
        <CompanyForm closeModal={() => setIsModalOpen(false)} />
      </Modal>
    </>
  );

  if (!inProfile) {
    return content;
  }

  return <Section title={'Dane oddziału'}>{content}</Section>;
};

export default Company;
