import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import style from './OptionSwitch.module.scss';

const OptionSwitch = ({ trueLabel, falseLabel, onChange, defaultOption = true }) => {
  const [isLeft, setIsLeft] = useState(defaultOption);

  const indicatorClasses = cn(style.indicator, { [style.right]: !isLeft });
  const clickHandler = (e, value) => {
    e.preventDefault();
    setIsLeft(value);
  };

  useEffect(() => {
    if (onChange) onChange(isLeft);
  }, [isLeft]);

  return (
    <div className={style.container}>
      <button
        className={style.option}
        onClick={(e) => clickHandler(e, true)}
      >
        {trueLabel}
      </button>
      <button
        className={style.option}
        onClick={(e) => clickHandler(e, false)}
      >
        {falseLabel}
      </button>
      <span className={indicatorClasses} />
    </div>
  );
};

export default OptionSwitch;
