import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { useIsUserAuth } from 'components';

import { PrivateRoute } from 'src/app/AppRouter/routes';
import {
  OfferCatalogTypes,
  orderTypes,
  productBaseTypes,
  userPermissions,
  userRoles,
  valuationsTypes
} from 'src/constants/enums';
import AdminToUserRedirect from 'src/features/AdminToUserRedirect';
import Analytics from 'src/features/Analytics';
import CartOrder from 'src/features/CartOrder';
import CatalogAndCartController from 'src/features/CatalogAndCartController';
import CategoriesManager from 'src/features/CategoriesManager';
import Collections from 'src/features/Collections';
import CollectionView from 'src/features/CollectionView';
import CompaniesList from 'src/features/CompaniesList';
import Dashboard from 'src/features/Dashboard';
import Distributors from 'src/features/Distributors';
import Expenses from 'src/features/Expenses';
import ExpensesManagement from 'src/features/ExpensesManagement';
import FloorPlan from 'src/features/FloorPlan';
import Invoices from 'src/features/Invoices';
import Lists from 'src/features/Lists';
import ListView from 'src/features/ListView';
import MarketPrices from 'src/features/MarketPrices';
import { getUserData } from 'src/features/onboarding/actions';
import ConfirmOrder from 'src/features/orderConfirmations/containers/ConfirmOrder';
import Orders from 'src/features/Orders';
import OrdersApprovals from 'src/features/OrdersApprovals';
import OrdersView from 'src/features/OrdersView';
import OrderThanksPage from 'src/features/OrderThanksPage';
import Producers from 'src/features/Producers';
import ProductReplacementsManagement from 'src/features/ProductReplacementsManagement';
import ProductsBase from 'src/features/ProductsBase';
import ProductVariationsManagement from 'src/features/ProductVariationsManagement';
import Profile from 'src/features/Profile';
import Services from 'src/features/Services';
import ServicesView from 'src/features/ServicesView';
import SupplierClients from 'src/features/SupplierClients';
import Suppliers from 'src/features/Suppliers';
import SuppliersManagement from 'src/features/SuppliersManagement';
import SupplierValuation from 'src/features/SupplierValuation';
import Users from 'src/features/Users';
import Valuations from 'src/features/Valuations';
import ValuationsOrder from 'src/features/ValuationsOrder';
import ValuationView from 'src/features/ValuationView';
import Warehouse from 'src/features/Warehouse';
import WarehouseHistory from 'src/features/WarehouseHistory';
import Wholesalers from 'src/features/Wholesalers';
import WholesalersView from 'src/features/WholesalersView';
import { offerCatalogEnabled, suppliersManagementEnabled } from 'src/utils/companyAccessFunctions';

import OrderApproval from '../orderConfirmations/containers/OrderApproval';
import TagsManagement from '../TagsManagement';

const { user, admin, supplier, distributor } = userRoles;
const { lists, valuations, orders, analytics, warehouse, offer_catalog, invoices, products, suppliers, services } =
  userPermissions;

const UserAreaRouter = () => {
  const dispatch = useDispatch();
  const isAuth = useIsUserAuth();

  useEffect(() => {
    if (isAuth) dispatch(getUserData());
  }, []);

  return (
    <Switch>
      <PrivateRoute
        exact
        path='/r/:token/:admin_id'
        component={AdminToUserRedirect}
        roles={[admin]}
        nestedRoute
      />
      <PrivateRoute
        exact
        path='/dashboard'
        component={Dashboard}
        roles={[user, admin, supplier, distributor]}
      />
      <PrivateRoute
        exact
        path='/warehouse'
        component={Warehouse}
        roles={[user, admin]}
        requiredPermissions={[warehouse.read]}
        wide
      />
      <PrivateRoute
        exact
        path='/warehouse/history'
        component={WarehouseHistory}
        requiredPermissions={[warehouse.read]}
        roles={[user, admin]}
        wide
      />
      <PrivateRoute
        exact
        path='/lists'
        component={Lists}
        roles={[user, admin]}
        requiredPermissions={[lists.read]}
      />
      <PrivateRoute
        exact
        path='/lists/:uid'
        component={ListView}
        roles={[user, admin]}
        requiredPermissions={[lists.read]}
        showGoBackButton
        showToTopButton
      />
      <PrivateRoute
        exact
        path='/valuations'
        render={(props) => (
          <Valuations
            type={valuationsTypes.user}
            {...props}
          />
        )}
        roles={[user, admin]}
        requiredPermissions={[valuations.read]}
      />
      <PrivateRoute
        exact
        path='/supplier/valuations'
        render={(props) => (
          <Valuations
            type={valuationsTypes.supplier}
            {...props}
          />
        )}
        roles={[supplier, admin]}
      />
      <PrivateRoute
        exact
        path='/supplier/valuations/:uid'
        component={SupplierValuation}
        roles={[supplier, admin]}
        showGoBackButton
        showToTopButton
        wide
      />
      <PrivateRoute
        exact
        path='/valuations/:uid'
        component={ValuationView}
        requiredPermissions={[valuations.read]}
        roles={[user, admin]}
        showGoBackButton
        showToTopButton
        wide
      />
      <PrivateRoute
        exact
        path='/valuations/:uid/order'
        component={ValuationsOrder}
        roles={[user, admin]}
        requiredPermissions={[valuations.read, orders.create]}
        showGoBackButton
        showToTopButton
      />
      <PrivateRoute
        exact
        path='/valuations/:uid/order/thanks'
        component={OrderThanksPage}
        roles={[user, admin]}
      />
      <PrivateRoute
        path='/offer-catalog'
        component={CatalogAndCartController}
        companyAccessFn={offerCatalogEnabled}
        requiredPermissions={[offer_catalog.read]}
        roles={[user, admin]}
        showToTopButton
        wide
      />
      <PrivateRoute
        wide
        exact
        showToTopButton
        roles={[user, admin]}
        path='/lists/:listUID/cart'
        companyAccessFn={offerCatalogEnabled}
        requiredPermissions={[orders.create]}
        showGoBackButton
        render={(props) => (
          <CatalogAndCartController
            type={OfferCatalogTypes.cart}
            {...props}
          />
        )}
      />
      <PrivateRoute
        exact
        path='/lists/:listUID/cart/proceed-order'
        roles={[user, admin]}
        requiredPermissions={[lists.read, orders.create]}
        companyAccessFn={offerCatalogEnabled}
        showGoBackButton
        showToTopButton
        component={CartOrder}
      />
      <PrivateRoute
        exact
        path='/lists/:listUID/cart/proceed-order/thanks'
        component={OrderThanksPage}
        requiredPermissions={[lists.read, orders.create]}
        roles={[user, admin]}
      />
      <PrivateRoute
        exact
        path='/orders'
        render={(props) => (
          <Orders
            type={orderTypes.user}
            {...props}
          />
        )}
        roles={[user, admin]}
        requiredPermissions={[orders.read]}
      />
      <PrivateRoute
        exact
        path='/orders-approvals'
        component={OrdersApprovals}
        roles={[admin]}
      />
      <PrivateRoute
        exact
        path='/orders-approvals/:token'
        component={OrderApproval}
        roles={[admin]}
        showGoBackButton
      />
      <PrivateRoute
        exact
        path='/supplier/orders'
        render={(props) => (
          <Orders
            type={orderTypes.supplier}
            {...props}
          />
        )}
        roles={[supplier, admin]}
      />
      <PrivateRoute
        exact
        path='/distributor/orders'
        component={Orders}
        roles={[distributor]}
      />
      <PrivateRoute
        exact
        path='/supplier/orders/:uid'
        component={ConfirmOrder}
        roles={[supplier, admin]}
        showGoBackButton
      />
      <PrivateRoute
        exact
        path='/supplier/products'
        render={(props) => (
          <ProductsBase
            type={productBaseTypes.supplier}
            {...props}
          />
        )}
        roles={[supplier]}
      />
      <PrivateRoute
        exact
        path='/market-prices/:list_uid?'
        component={MarketPrices}
        roles={[admin]}
      />
      <PrivateRoute
        exact
        path='/supplier/collections'
        component={Collections}
        roles={[supplier]}
      />
      <PrivateRoute
        exact
        path='/supplier/collections/:uid'
        component={CollectionView}
        showGoBackButton
        goBackButtonUrl={'/supplier/collections'}
        roles={[supplier]}
      />
      <PrivateRoute
        exact
        path='/supplier/clients'
        component={SupplierClients}
        roles={[supplier]}
      />
      <PrivateRoute
        exact
        path='/distributor/orders/:uid'
        component={ConfirmOrder}
        roles={[distributor]}
        showGoBackButton
      />
      <PrivateRoute
        exact
        path='/orders/:uid'
        component={OrdersView}
        roles={[user, admin]}
        requiredPermissions={[orders.read]}
        showToTopButton
        showGoBackButton
      />
      <PrivateRoute
        exact
        path='/suppliers'
        component={Suppliers}
        roles={[user, admin]}
        requiredPermissions={[suppliers.read]}
      />
      <PrivateRoute
        exact
        path='/analytics'
        component={Analytics}
        roles={[user, admin]}
        requiredPermissions={[analytics.read]}
      />
      <PrivateRoute
        path='/profile'
        component={Profile}
        roles={[user, admin, supplier, distributor]}
        wide
      />
      <PrivateRoute
        exact
        path='/tags'
        component={TagsManagement}
        roles={[user, admin]}
      />
      <PrivateRoute
        exact
        path='/products-base'
        component={ProductsBase}
        roles={[user, admin]}
        requiredPermissions={[products.read]}
      />
      <PrivateRoute
        exact
        path='/products-replacements'
        component={ProductReplacementsManagement}
        roles={[admin]}
      />
      <PrivateRoute
        exact
        path='/products-variations'
        component={ProductVariationsManagement}
        roles={[admin]}
      />
      <PrivateRoute
        exact
        path='/companies'
        component={CompaniesList}
        roles={[admin]}
      />
      <PrivateRoute
        exact
        path='/distributors'
        component={Distributors}
        roles={[admin]}
      />
      <PrivateRoute
        exact
        path='/categories'
        component={CategoriesManager}
        roles={[admin]}
      />
      <PrivateRoute
        exact
        path='/users'
        component={Users}
        roles={[admin]}
      />
      <PrivateRoute
        exact
        path='/producers'
        component={Producers}
        roles={[admin]}
      />
      <PrivateRoute
        exact
        path='/invoices'
        component={Invoices}
        roles={[admin, user]}
        requiredPermissions={[invoices.read]}
      />
      <PrivateRoute
        exact
        path='/expenses'
        component={Expenses}
        roles={[admin, user]}
      />
      <PrivateRoute
        exact
        path='/expenses-management'
        component={ExpensesManagement}
        roles={[admin]}
      />
      <PrivateRoute
        exact
        path='/services'
        component={Services}
        roles={[user, admin]}
        requiredPermissions={[services.read]}
      />
      <PrivateRoute
        exact
        path='/services/:id'
        component={ServicesView}
        roles={[admin, user]}
        showGoBackButton
        goBackButtonUrl={'/services'}
        requiredPermissions={[services.read]}
      />
      <PrivateRoute
        exact
        path='/wholesalers'
        component={Wholesalers}
        roles={[admin]}
      />
      <PrivateRoute
        exact
        path='/floor-plan'
        component={FloorPlan}
        roles={[admin]}
      />
      <PrivateRoute
        exact
        path='/wholesalers/:uid'
        component={WholesalersView}
        roles={[admin]}
        showToTopButton
        showGoBackButton
      />
      <PrivateRoute
        exact
        path='/suppliers-management'
        component={SuppliersManagement}
        roles={[user, admin]}
        companyAccessFn={suppliersManagementEnabled}
      />
      <Route render={() => <Redirect to='/404' />} />
    </Switch>
  );
};

const UserArea = ({ isAuthenticated }) => {
  const location = useLocation();

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: {
            from: location
          }
        }}
      />
    );
  }

  return <UserAreaRouter />;
};

export default UserArea;
