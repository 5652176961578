import React from 'react';

import { Card } from 'components';

import style from '../OrderCart/OrderCart.module.scss';

const UserComment = ({ comment }) => {
  if (!comment) {
    return null;
  }

  return (
    <Card className={style.commentCard}>
      <h2>Komentarz klienta do zamówienia</h2>
      <p>{comment}</p>
    </Card>
  );
};

export default UserComment;
