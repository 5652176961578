import React from 'react';

import Checkbox from 'components/layout/Checkbox';

import style from './SelectElements.module.scss';

const SelectElements = ({ title, elements = [], selected = [], onChange }) => {
  return (
    <div className={style.container}>
      <h3 className={style.title}>{title}</h3>
      {elements.map(({ id, name }) => (
        <Checkbox
          key={id}
          content={name}
          value={selected.includes(id)}
          onChange={(e) => onChange(id, e.target.checked)}
        />
      ))}
    </div>
  );
};

export default SelectElements;
