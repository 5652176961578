import React, { useState } from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';

import { Icon, useStateTimeout } from 'components';

import ReplacementsProductSelect from './components/ReplacementsProductSelect';

import style from '../ProductComponents.module.scss';

const ReplacementDetails = (props) => {
  const {
    name,
    producer,
    productReplacement,
    setName,
    setProducer,
    setProductReplacement,
    validator,
    disabled,
    currentProduct,
    removeHandler,
    setLoading,
    loading,
    setAutoSaveLoading
  } = props;
  const { product_id } = currentProduct;

  const [localSelectedProduct, setLocalSelectedProduct] = useState(productReplacement || null);

  useStateTimeout({
    localState: localSelectedProduct,
    setLocalState: setLocalSelectedProduct,
    state: productReplacement,
    setState: setProductReplacement
  });

  return (
    <div className={classNames(style.wrapper, style.productDetails)}>
      <div className={classNames(style.wrapper, style.replacementDetails)}>
        <div className={classNames(style.wrapper, style.replacementInputs)}>
          <div className={style.header}>
            <h5>Zamiennik</h5>
            <Tooltip
              placement='top'
              title={'Zrezygnuj'}
            >
              <button
                className={style.closeReplacementsFormButton}
                onClick={removeHandler}
              >
                <Icon
                  name='close'
                  size='20'
                />
              </button>
            </Tooltip>
          </div>
          <ReplacementsProductSelect
            currentProductId={product_id}
            validator={validator}
            value={localSelectedProduct}
            onChange={setLocalSelectedProduct}
            setLoading={setLoading}
            loading={loading}
            setAutoSaveLoading={setAutoSaveLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ReplacementDetails;
