import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { useIsMobile } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import { priceTypes, productTypes } from 'src/constants/enums';

import { useSupplierFormValidationForceUpdate } from '../../../../helpers';
import Availability from '../../components/Availability';
import DeleteButton from '../../components/DeleteButton';
import ItemValue from '../../components/ItemValue';
import Price from '../../components/Price';
import Quantity from '../../components/Quantity';
import ReplacementDetails from '../../components/ReplacementDetails';
import TaxSelect from '../../components/TaxSelect';

import style from '../ProductForms.module.scss';

const ReplacementForm = React.memo((props) => {
  const isMobile = useIsMobile();
  useSupplierFormValidationForceUpdate();
  const { product, setProduct, removeHandler, currentProduct, isLoading, setAutoSaveLoading } = props;
  const { price, availability, name, producer, replacement_item, id, quantity, tax, delivery_date } = product || {};

  const [localLoading, setLocalLoading] = useState(false);

  const priceType = useSelector((state) => state.supplierForm.form.price_type);
  const validator = useSelector((state) => state.supplierForm.validator);
  const disabled = useSelector((state) => state.supplierForm.isDisabled);

  if (!product) {
    return null;
  }

  const containerClasses = classNames(style.replacementContainer, {
    [style.net]: priceType.value === priceTypes.netto,
    [style.isLoading]: localLoading
  });

  const onChangeHandler = (value, name) => {
    setProduct((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const removeReplacementProductHandler = () => {
    setProduct((prev) => ({
      ...prev,
      replacement_item: null
    }));
  };

  useEffect(() => {
    if (isLoading) {
      setLocalLoading(true);
    } else {
      setLocalLoading(false);
    }
  }, [isLoading]);

  return (
    <div className={style.replacementFormWrapper}>
      <ReplacementDetails
        name={name}
        producer={producer}
        productReplacement={replacement_item}
        setName={(value) => onChangeHandler(value, 'name')}
        setProducer={(value) => onChangeHandler(value, 'producer')}
        setProductReplacement={(value) => onChangeHandler(value, 'replacement_item')}
        disabled={disabled}
        isMobile={isMobile}
        validator={validator}
        currentProduct={currentProduct}
        removeHandler={removeHandler}
        setLoading={setLocalLoading}
        loading={localLoading}
        setAutoSaveLoading={setAutoSaveLoading}
      />
      {replacement_item && (
        <div className={containerClasses}>
          <div className={style.replacementNameWrapper}>
            {localLoading ? (
              <>
                <div>
                  <Skeleton
                    width={36}
                    height={36}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <Skeleton
                    width={'100%'}
                    height={16}
                  />
                </div>
              </>
            ) : (
              <>
                <ProductThumbnailImage
                  thumbnail={replacement_item?.thumbnail}
                  title={replacement_item?.name}
                  clickable={false}
                  size={'tiny'}
                />
                <p>{replacement_item?.name}</p>
              </>
            )}
          </div>
          <Price
            price={price}
            setPrice={(value) => onChangeHandler(value, 'price')}
            disabled={disabled}
            validator={validator}
            isMobile={isMobile}
            id={id}
          />
          {priceType.value === priceTypes.netto && (
            <TaxSelect
              disabled={disabled}
              tax={tax}
              setTax={(value) => onChangeHandler(value, 'tax')}
            />
          )}
          <Quantity
            disabled={disabled}
            validator={validator}
            isMobile={isMobile}
            unitName={replacement_item?.unit_name}
            quantity={quantity}
            setQuantity={(value) => onChangeHandler(value, 'quantity')}
          />
          <ItemValue
            priceType={priceType}
            isMobile={isMobile}
            price={price}
            quantity={quantity}
            tax={tax}
          />
          <Availability
            validator={validator}
            isMobile={isMobile}
            disabled={disabled}
            type={productTypes.replacement}
            availability={availability}
            deliveryDate={delivery_date}
            setAvailability={(value) => onChangeHandler(value, 'availability')}
            setDeliveryDate={(value) => onChangeHandler(value, 'delivery_date')}
          />
          <DeleteButton
            disabled={disabled}
            removeHandler={removeReplacementProductHandler}
          />
        </div>
      )}
    </div>
  );
});

export default ReplacementForm;
