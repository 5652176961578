import React from 'react';
import { Placeholder } from 'semantic-ui-react';

export const titlePlaceholder = (width = '250px') => (
  <Placeholder style={{ width, mixBlendMode: 'darken', marginTop: '6px' }}>
    <Placeholder.Paragraph>
      <Placeholder.Line
        length={'full'}
        style={{ marginBottom: '24px' }}
      />
    </Placeholder.Paragraph>
  </Placeholder>
);

export const headerTextPlaceholder = (width = '110px') => (
  <Placeholder style={{ width, mixBlendMode: 'darken', marginTop: '6px' }}>
    <Placeholder.Paragraph>
      <Placeholder.Line
        length={'full'}
        style={{ marginBottom: '13px' }}
      />
    </Placeholder.Paragraph>
  </Placeholder>
);
