import React from 'react';
import classNames from 'classnames';

import { EmptyState } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import ChangedPricesProductThumbnail from '../ChangedPricesProductThumbnail';

import style from './ChangedPricesProductsListTable.module.scss';
import shared from 'styles/Shared.module.scss';

const ChangedPricesProductsListTable = ({ list = [], isLoading }) => {
  if (isLoading) {
    return (
      <section
        className={shared.tableWrapper}
        style={{ paddingTop: 0 }}
      >
        <div className={classNames(shared.tableHeader, style.header)}>
          <p>Nazwa</p>
          <p>Poprzednia cena</p>
          <p>Aktualna cena</p>
          <p>Różnica</p>
          <p>Data zmiany</p>
        </div>
        <div className={shared.tableCategory}>
          <ProductPlaceholder quantity={4} />
        </div>
      </section>
    );
  }

  return (
    <section
      className={shared.tableWrapper}
      style={{ paddingTop: 0 }}
    >
      <div className={style.tableContainer}>
        <div className={style.tableWrapper}>
          {list.length > 0 && (
            <div className={classNames(shared.tableHeader, style.header)}>
              <p>Nazwa</p>
              <p>Poprzednia cena</p>
              <p>Aktualna cena</p>
              <p>Różnica</p>
              <p>Data zmiany</p>
            </div>
          )}
          <div className={shared.tableCategory}>
            {list.length > 0 ? (
              list.map((product) => (
                <ChangedPricesProductThumbnail
                  key={product.id}
                  {...product}
                />
              ))
            ) : (
              <EmptyState type={'changedPricesProducts'} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangedPricesProductsListTable;
