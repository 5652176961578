import React, { useState } from 'react';

import { SearchInput, useStateTimeout } from 'components';

const StateSearchbar = ({ state, setState }) => {
  const [localSearch, setLocalSearch] = useState('');

  useStateTimeout({
    timeoutValue: 400,
    localState: localSearch,
    setLocalState: setLocalSearch,
    setState,
    state
  });

  return (
    <SearchInput
      state={localSearch}
      setState={setLocalSearch}
      alwaysExpand
      fullWidth
    />
  );
};

export default StateSearchbar;
