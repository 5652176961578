import React from 'react';

import { Accordion } from 'components';

import CompanyDetailsCard from 'src/features/SupplierForm/components/CompanyDetails';
import { getFormattedPhoneNumber } from 'src/utils/helpers';

import style from './CompanyDetails.module.scss';

const CompanyDetails = ({ company, sender }) => {
  const companyTitle = <h2>Dane oddziału składającego zamówienie</h2>;
  const employeeTitle = <h2>Zamówienie wysłane do akceptacji przez:</h2>;
  const { name, email, phone } = sender || {};

  return (
    <div className={style.wrapper}>
      <Accordion
        title={companyTitle}
        defaultWrapped
      >
        <CompanyDetailsCard company={company} />
      </Accordion>
      <Accordion
        title={employeeTitle}
        defaultWrapped
      >
        <div className={style.box}>
          <h3>{name}</h3>
          <p>{email}</p>
          <p>{getFormattedPhoneNumber(phone)}</p>
        </div>
      </Accordion>
    </div>
  );
};

export default CompanyDetails;
