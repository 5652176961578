import React from 'react';

import ReplacementsTableThumbnail from '../ReplacementsTableThumbnail';

import styles from '../../../../ValuationReplacements.module.scss';

const ReplacementsTable = ({ replacements, pricingProdId }) => {
  return (
    <div className={styles.table_item_replacements}>
      {replacements.map((replacement) => (
        <ReplacementsTableThumbnail
          key={replacement.replacement_product_id}
          replacement={replacement}
          pricingProdId={pricingProdId}
        />
      ))}
    </div>
  );
};

export default ReplacementsTable;
