import React from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

import { userRoles } from 'src/constants/enums';

import style from './AdminViewWidget.module.scss';

const AdminViewWidget = () => {
  const { user, isAdminView } = useSelector((state) => state.auth);

  const isAdminViewNoLoaded = isAdminView && user?.role === userRoles.admin;

  if (!isAdminView || isAdminViewNoLoaded || !user) {
    return null;
  }

  return createPortal(
    <div className={style.container}>
      <p>
        <strong>Uwaga!</strong> Przeglądasz właśnie konto użytkownika {user.email} ({user.name})
      </p>
    </div>,
    document.body
  );
};

export default AdminViewWidget;
