import { FilterByDateRange, Searchbar } from 'components';

const Filtering = ({ params }) => {
  return (
    <>
      <div>
        <Searchbar params={params} />
      </div>
      <FilterByDateRange
        params={params}
        isExpenses
      />
    </>
  );
};

export default Filtering;
