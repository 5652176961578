import React, { useEffect } from 'react';
import { Badge } from 'antd';
import cn from 'classnames';

import style from './VisibilityCallback.module.scss';

const VisibilityCallback = (props) => {
  const { elementRef, notification, callback = () => {}, timeout = 3500, threshold = 0.25, displayOnLeft } = props;

  useEffect(() => {
    let timeoutId;

    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) timeoutId = setTimeout(callback, timeout);
        else clearTimeout(timeoutId);
      });
    };

    if (notification && elementRef?.current) {
      const observer = new IntersectionObserver(handleIntersect, { threshold });
      observer.observe(elementRef.current);

      return () => {
        observer.disconnect();
        clearTimeout(timeoutId);
      };
    }
  }, [elementRef, notification, callback]);

  if (!notification) {
    return null;
  }

  return (
    <Badge
      count={notification ? 1 : 0}
      size={'small'}
      className={cn(style.badge, { [style.left]: displayOnLeft })}
    />
  );
};

export default VisibilityCallback;
