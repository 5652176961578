import React from 'react';
import classNames from 'classnames';

import style from './OrderOrValuationId.module.scss';

const OrderOrValuationId = ({ id, withPrefix, className, noPadding }) => {
  if (!id) {
    return null;
  }

  return (
    <p className={classNames(style.idWrapper, { [style.noPadding]: noPadding }, className)}>
      {withPrefix} <span>#{id}</span>
    </p>
  );
};

export default OrderOrValuationId;
