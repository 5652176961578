import React, { useState } from 'react';

import { AsyncSelect, Button, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ProductsApi, VariationsApi } from 'src/api';

import styles from './ProductVariationsAddProductsToGroupForm.module.scss';

const ProductVariationsAddProductsToGroupForm = ({ groupId, refreshCallback, version, setVersion }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const validator = useValidator();

  const addGroupProductsHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      group_id: groupId,
      product_ids: selectedProducts.map((product) => product.id)
    };

    try {
      setIsLoading(true);
      const { data } = await VariationsApi.addGroupProducts(params);
      if (refreshCallback) refreshCallback(true);
      setSelectedProducts([]);
      setVersion((prev) => prev + 1);
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={styles.container}
      onSubmit={addGroupProductsHandler}
    >
      <AsyncSelect
        key={version}
        id={'selected-products'}
        value={selectedProducts}
        onChange={setSelectedProducts}
        apiCallback={ProductsApi.getProducts}
        label={'Wybierz produkty'}
        valueKey={'id'}
        labelKey={'name'}
        placeholder={'Wyszukaj..'}
        validator={validator}
        rule={'required'}
        productSearch
        highlightUsedInVariationsGroup
        isClearable
        isMulti
      />
      <Button
        label={'Dodaj'}
        isLoading={isLoading}
        className={styles.button}
      />
    </form>
  );
};

export default ProductVariationsAddProductsToGroupForm;
