import React, { useState } from 'react';

import { Loader, SaveButton } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { ReactComponent as TicIcon } from 'src/assets/icons/tick.svg';

import style from './AddToCollection.module.scss';

const AddToCollection = ({ product }) => {
  const { in_collection, id } = product;

  const [isAdded, setIsAdded] = useState(in_collection);
  const [isLoading, setIsLoading] = useState(false);

  const changeCollectionProductHandler = async () => {
    const data = { product_id: id, price: 0 };
    const ApiCallback = isAdded ? SupplierApi.removeProductFromCollection : SupplierApi.addProductToCollection;

    try {
      setIsLoading(true);
      await ApiCallback(data);
      setIsAdded((prev) => !prev);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isAdded) {
    return (
      <>
        <TicIcon />
        <p>Dodano</p>
        <SaveButton
          onClick={changeCollectionProductHandler}
          type={'delete'}
        />
      </>
    );
  }

  return (
    <>
      {isLoading && <Loader />}
      <a
        className={style.link}
        onClick={changeCollectionProductHandler}
      >
        Dodaj do oferty
      </a>
    </>
  );
};

export default AddToCollection;
