import React, { useState } from 'react';

import { Modal } from 'components';

import WholesalerSupplierDetails from '../WholesalerSupplierDetails';

import styles from './WholesalerSupplierThumbnail.module.scss';

const WholesalerSupplierThumbnail = ({ name, email, phone_number, suppliers_count, refetchSuppliers }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <div
        className={styles.container}
        onClick={() => setIsVisible(true)}
      >
        <div>{email}</div>
        <div>{name}</div>
        <div>{suppliers_count}</div>
        <div>{phone_number}</div>
      </div>
      <Modal
        visible={isVisible}
        onClose={() => setIsVisible(false)}
        title={`Właściciele przedstawiciela ${email}`}
      >
        <WholesalerSupplierDetails
          search={email}
          refetchSuppliers={refetchSuppliers}
        />
      </Modal>
    </>
  );
};

export default WholesalerSupplierThumbnail;
