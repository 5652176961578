import * as types from './types';

export const refreshCartOrCatalog = () => ({
  type: types.CHANGE_CART_AND_CATALOG_VERSION
});

export const changeOcrVersion = () => ({
  type: types.CHANGE_OCR_VERSION
});

export const refreshSummary = () => ({
  type: types.CHANGE_SUMMARY_VERSION
});

export const changeSelectedCompany = (value) => ({
  type: types.CHANGE_SELECTED_COMPANY,
  payload: value
});

export const changeViewType = (isDefinedView) => ({
  type: types.CHANGE_VIEW_TYPE,
  payload: isDefinedView
});
