import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  Modal,
  PageHeader,
  PaginationNav,
  Searchbar,
  SeoHelmet,
  SwitchButton,
  useRequestAbortController
} from 'components';
import { headerTextPlaceholder } from 'components/layout/PageHeader/placeholders';
import { notifyApiError } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';
import pages from 'src/dictionaries/pages.json';
import { getCorrectFormOfResultsLabel } from 'src/utils/helpers';
import query from 'src/utils/query';

import NewProducerForm from './components/NewProducerForm';
import ProducersTable from './components/ProducersTable';

import style from './Producers.module.scss';

const Producers = (props) => {
  const params = query(props);
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showOnlyUsersProduct, setShowOnlyUsersProduct] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [querySearch, setQuerySearch] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [perPage, setPerPage] = useState(50);
  const [pageQty, setPageQty] = useState(1);
  const [itemsQty, setItemsQty] = useState();

  const producersVersion = useSelector((state) => state.producers.version);

  const getData = async () => {
    const queryParams = {
      added_by_user: showOnlyUsersProduct,
      page: currentPage || 1,
      search: querySearch,
      perPage
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await ProductsApi.getProducers(queryParams, signal);
      setPageData(data.data);
      setItemsQty(data.total);
      setPageQty(data?.last_page);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [producersVersion, querySearch, currentPage, perPage, showOnlyUsersProduct]);

  useEffect(() => {
    setQuerySearch(params.get('search'));
    setCurrentPage(params.get('page'));
  }, [params]);

  return (
    <>
      <SeoHelmet title={pages.producers.title} />
      <PageHeader
        name={pages.producers.title}
        text={isLoading ? headerTextPlaceholder() : getCorrectFormOfResultsLabel(itemsQty)}
      >
        <SwitchButton
          label={'Tylko użytkowników'}
          setValue={setShowOnlyUsersProduct}
          value={showOnlyUsersProduct}
        />
        <Searchbar
          params={params}
          wrapperStyle={style.search}
        />
        <Button
          label={'Dodaj producenta'}
          onClick={() => setIsModalOpen(true)}
        />
      </PageHeader>
      <div className={style.container}>
        <ProducersTable
          list={pageData}
          isLoading={isLoading}
        />
      </div>
      <PaginationNav
        onClick={(number) => setCurrentPage(number)}
        params={params}
        pagesQty={pageQty}
        defaultQty={perPage}
        setPerPage={setPerPage}
      />
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={'Dodaj producenta'}
      >
        <NewProducerForm closeModal={() => setIsModalOpen(false)} />
      </Modal>
    </>
  );
};

export default Producers;
