import React from 'react';

import { useIsMobile } from 'components';

import { toDateNumber } from 'src/utils/dateTime';
import { getReleaseTypeTag } from 'src/utils/helpers';

import style from './SingleRelease.module.scss';

const SingleRelease = ({ release }) => {
  const { department_name, user_name, quantity, release_date, release_type } = release;
  const isMobile = useIsMobile(768);

  return (
    <div className={style.container}>
      <div className={style.type}>
        {isMobile && <p>Typ wydania:</p>}
        {getReleaseTypeTag({ type: release_type, className: style.tag })}
      </div>
      <div className={style.date}>
        {isMobile && <p>Data:</p>}
        {toDateNumber(release_date)}
      </div>
      <div className={style.quantity}>
        {isMobile && <p>Wydana ilość:</p>}
        {quantity} szt.
      </div>
      <div className={style.department}>
        {isMobile && <p>Wydano dla:</p>}
        {department_name ?? '-'}
      </div>
      <div className={style.user}>
        {isMobile && <p>Wydano przez:</p>}
        {user_name ?? '-'}
      </div>
    </div>
  );
};

export default SingleRelease;
