import { useState } from 'react';

import { ItemTag, Modal, SaveButton, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { TagsApi } from 'src/api';

import AddTagForm from '../AddTagForm';

import styles from './TagThumbnail.module.scss';

const TagThumbnail = ({ id, name, color, used_count, refreshCallback }) => {
  const [editTagVisible, setEditTagVisible] = useState(false);

  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const tagData = { id, name, color };

  const handleDeleteTag = async () => {
    try {
      const { data } = await TagsApi.deleteTag(id);
      if (refreshCallback) refreshCallback();
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div>
          <ItemTag
            name={name}
            color={color || ''}
          />
        </div>
        <div>{used_count}</div>
        <div className={styles.actions}>
          <SaveButton
            type={'edit'}
            onClick={() => setEditTagVisible(true)}
          />
          <SaveButton
            type={'delete'}
            onClick={() =>
              handleOpenModalConfirm({
                message: `Czy na pewno chcesz usunąć tag ${name}?`,
                content: 'Usunięcie tagu jest równoznaczne z usunięciem jego przypisania w każdym powiązanym produkcie.',
                handleConfirm: handleDeleteTag
              })
            }
          />
        </div>
      </div>
      <Modal
        visible={editTagVisible}
        onClose={() => setEditTagVisible(false)}
        title={'Edytuj tag'}
      >
        <AddTagForm
          tagData={tagData}
          refreshCallback={refreshCallback}
        />
      </Modal>
      {renderedModalConfirm}
    </>
  );
};

export default TagThumbnail;
