import React from 'react';

import FormProgressBar from '../FormProgressBar';
import ValuationSum from '../ValuationSum';

import style from '../../SupplierForm.module.scss';

const ProgressAndSum = () => {
  return (
    <div className={style.rowWrapper}>
      <FormProgressBar />
      <ValuationSum />
    </div>
  );
};

export default ProgressAndSum;
