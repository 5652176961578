import * as types from './types';

export const addCartOrderDetails = (data) => ({
  type: types.ADD_CART_ORDER_DETAILS,
  payload: data
});

export const clearCartOrderDetails = () => ({
  type: types.CLEAR_CART_ORDER_DETAILS
});

export const refreshCartOrder = () => ({
  type: types.CHANGE_CART_ORDER_VERSION
});
