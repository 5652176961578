import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { Menu, Modal, useIsMobile, useModalConfirm } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import DistributorForm from 'src/features/Distributors/components/DistributorForm';
import { changeSuppliersVersion } from 'src/features/Suppliers/actions';
import { getFormattedPhoneNumber } from 'src/utils/helpers';

import style from './DistributorItem.module.scss';

const DistributorItem = ({ supplier }) => {
  const { id, name, email, phone } = supplier || {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const deleteDistributor = async () => {
    try {
      await AdminApi.deleteDistributor(id);
      dispatch(changeSuppliersVersion());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleDeleteDistributor = () => {
    handleOpenModalConfirm({
      message: `Czy na pewno chcesz usunąć dostawcę? (${name})`,
      handleConfirm: deleteDistributor
    });
  };

  const menu = [
    {
      title: 'Edytuj',
      icon: 'edit',
      action: () => setIsModalOpen(true)
    },
    {
      title: 'Usuń',
      icon: 'trash',
      action: handleDeleteDistributor
    }
  ];

  return (
    <div className={style.container}>
      <Menu actions={menu} />
      <div className={style.wrapper}>
        <p className={classnames(style.text, style.name)}>{name}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>E-mail:</p>}
        <p className={style.text}>{email}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Telefon:</p>}
        <p className={style.text}>{getFormattedPhoneNumber(phone)}</p>
      </div>
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={'Edytuj dystrybutora'}
      >
        <DistributorForm
          supplier={supplier}
          closeModal={() => setIsModalOpen(false)}
        />
      </Modal>
      {renderedModalConfirm}
    </div>
  );
};

export default DistributorItem;
