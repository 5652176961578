import React from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { ColumnSortButton, EmptyState } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import Product from '../Product';

import style from './ProductsList.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductsList = ({ list = [], isLoading, showSuppliers, params }) => {
  const { list_uid } = useParams();

  if (!isLoading && !list.length) {
    return (
      <section className={shared.tableWrapper}>
        <div className={shared.tableCategory}>
          <EmptyState type={'search'} />
        </div>
      </section>
    );
  }

  return (
    <section className={shared.tableWrapper}>
      <div className={classNames(shared.tableHeader, style.header, { [style.formList]: list_uid })}>
        <ColumnSortButton
          parameterName={'name'}
          params={params}
          content={'Nazwa'}
        />
        {list_uid && <p>Cena z listy</p>}
        <p>Zakres cen</p>
        <p>Średnia</p>
        <p>Mediana</p>
        <p>Liczba cen</p>
      </div>
      <div className={shared.tableCategory}>
        {isLoading ? (
          <ProductPlaceholder />
        ) : (
          list.map((product) => (
            <Product
              key={product.id}
              product={product}
              showSuppliers={showSuppliers}
            />
          ))
        )}
      </div>
    </section>
  );
};

export default ProductsList;
