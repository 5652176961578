import React, { useMemo } from 'react';
import { Popover } from 'antd';
import classnames from 'classnames';
import cn from 'classnames';

import { ProductThumbnailImage, RecentlyOrdered, Tag } from 'components';

import { getFormattedAmount } from 'src/utils/helpers';

import style from './ProductDetails.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductDetails = ({
  className,
  product,
  showRefPrice,
  autoSize,
  tiny,
  orderedDate,
  withoutSavings,
  showTags = false,
  customQuantity
}) => {
  const { name, producer, unit_name, quantity, thumbnail, image, ref_price, product_id, tags = [] } = product || {};

  const refPriceSaving = useMemo(() => {
    const x = product?.prices?.find((x) => x.isSelected);
    return +ref_price * x?.quantity - +x?.price;
  }, [ref_price, product]);

  const popoverContent = useMemo(() => {
    const filtered = tags.filter((_, i) => i !== 0);

    return (
      <div className={shared.popoverWrapper}>
        {filtered.map((tag) => (
          <Tag
            key={`ProductDetailsTag-${tag.id}`}
            value={tag.name}
            color={tag.color}
          />
        ))}
      </div>
    );
  }, [tags]);

  return (
    <div className={classnames(style.container, { [style.autoSize]: autoSize }, className)}>
      <div className={style.wrapper}>
        <ProductThumbnailImage
          thumbnail={thumbnail}
          image={image}
          title={name}
          id={product_id}
          size={tiny ? 'tiny' : undefined}
        />
        <p
          className={style.text}
          title={name}
        >
          {name}
        </p>
      </div>
      <div className={style.row}>
        {!showTags && (
          <>
            <span
              className={cn(style.details, style.quantity)}
              title={`Ilość: ${quantity} ${unit_name || ''}`}
            >
              <p>Ilość:</p>
              <span className={style.detailsValue}>
                {customQuantity || quantity}&nbsp;{unit_name}
              </span>
            </span>
            <span className={style.divider} />
          </>
        )}

        <span
          className={style.details}
          title={`Producent: ${producer}`}
        >
          <p>{showTags ? 'Prod' : 'Producent'}:</p>
          <span className={style.detailsValue}>{producer || 'Brak'}</span>
        </span>

        {showTags && tags.length > 0 && (
          <div className={style.tags}>
            <Tag
              value={tags[0].name}
              color={tags[0].color}
              className={style.tag}
            />

            {tags.length > 1 && (
              <Popover
                key={`ProductDetailsTagPopover-${product_id}`}
                content={tags.length > 1 ? popoverContent : undefined}
                placement={'bottomRight'}
              >
                <div>
                  <Tag
                    value={`+ ${tags.length - 1}...`}
                    color={'gray'}
                  />
                </div>
              </Popover>
            )}
          </div>
        )}
        <RecentlyOrdered data={orderedDate} />
        {!withoutSavings && showRefPrice && !!ref_price && +refPriceSaving > 0 && (
          <div className={style.savings}>
            <Tag
              value={`Oszczędność względem listy: ${getFormattedAmount(refPriceSaving)}`}
              color={'yellow'}
              className={style.tag}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetails;
