import React, { useMemo, useState } from 'react';

import { ReactComponent as PlusIcon } from 'assets/icons/plus-blue.svg';
import { AddressForm, Modal, ProfileSection as Section, useCompany, usePermissions } from 'components';
import AddressDetails from 'components/layout/details/AddressDetails';

import { userPermissions } from 'src/constants/enums';

import style from './Addresses.module.scss';

const Addresses = ({ company }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentCompany = useCompany();

  const [canEdit] = usePermissions([userPermissions.company.admin]);
  const companySource = useMemo(() => company || currentCompany, [company, currentCompany]);
  const defaultAddress = useMemo(() => companySource.shipping_details.find((address) => address.is_default), [companySource]);

  const addNewAddressButton = canEdit && (
    <button
      className={style.addNew}
      onClick={() => setIsModalOpen(true)}
    >
      <PlusIcon />
      Dodaj nowy adres
    </button>
  );

  return (
    <>
      <Section title={'Domyślny adres dostawy'}>
        <div className={style.wrapper}>
          {defaultAddress ? (
            <AddressDetails
              details={defaultAddress}
              inProfile
              companyId={company?.id}
            />
          ) : (
            addNewAddressButton
          )}
        </div>
      </Section>
      <Section title={'Adresy dostaw'}>
        <div className={style.wrapper}>
          {companySource.shipping_details.map((address) => (
            <AddressDetails
              inProfile
              key={address.id}
              details={address}
              companyId={company?.id}
            />
          ))}
          {addNewAddressButton}
        </div>
      </Section>
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={'Dodaj nowy adres'}
      >
        <AddressForm
          closeModal={() => setIsModalOpen(false)}
          companyId={company?.id}
        />
      </Modal>
    </>
  );
};

export default Addresses;
