import React, { useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { useIsMobile, useValuationResponsive } from 'components';

import CartSummary from '../CartSummary';
import Column from '../Column';
import LeftColumn from '../LeftColumn';
import ValuationDropdown from '../ValuationDropdown';

import style from '../../ValuationView.module.scss';
import shared from 'styles/Shared.module.scss';

const VerticalValuationWrapper = ({ columns, pageData, changeValuationSelections }) => {
  const [supplier, setSupplier] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  const [carouselItems, setCarouselItems] = useState([]);
  const [isColumnAdded, setIsColumnAdded] = useState(false);
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const isMobile = useIsMobile(768);

  const responsive = useValuationResponsive();

  const handleChangeSupplier = (e) => {
    if (e.target.value !== supplier) {
      setSupplier(e.target.value);
    }
  };

  useEffect(() => {
    setIsColumnAdded(false);
    setCarouselItems(
      columns?.map((column, index) => (
        <Column
          key={column.supplier.id}
          column={column}
          onSupplierChange={handleChangeSupplier}
          selected={supplier}
          lastElement={index === columns.length - 1}
          changeValuationSelections={changeValuationSelections}
        />
      ))
    );
  }, [columns, isMobile]);

  useEffect(() => {
    if (isMobile && !isColumnAdded) {
      setIsColumnAdded(true);
      setCarouselItems((prev) => [leftColumn, ...prev]);
    }
  }, [carouselItems]);

  const leftColumn = (
    <LeftColumn
      pageData={pageData}
      columns={columns}
      valuationEdit
    />
  );

  return (
    <div className={style.contentWrapper}>
      <ValuationDropdown>
        <CartSummary columns={columns} />
      </ValuationDropdown>
      {!isMobile && leftColumn}
      <div
        className={classNames(style.rightColumn, {
          [style.rightColumnOpenSidebar]: isSidebarOpen,
          [style.onlyThree]: columns.length === 3,
          [style.onlyTwo]: columns.length === 2,
          [style.onlyOne]: columns.length === 1
        })}
      >
        {carouselItems.length === 1 ? (
          carouselItems
        ) : (
          <AliceCarousel
            activeIndex={activeIndex}
            mouseTracking
            items={carouselItems}
            responsive={responsive}
            disableSlideInfo
            disableDotsControls
            swipeExtraPadding={30}
            onSlideChanged={(e) => setActiveIndex(e.item)}
            renderNextButton={({ isDisabled }) => (
              <div
                className={classNames(shared.control, shared.next, {
                  [shared.disabled]: isDisabled
                })}
              />
            )}
            renderPrevButton={({ isDisabled }) => (
              <div
                className={classNames(shared.control, shared.prev, {
                  [shared.disabled]: isDisabled
                })}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export default VerticalValuationWrapper;
