import React from 'react';

import { Tag } from 'components';
import InfoWidget from 'components/layout/InfoWidget';

import { getFormattedAmount } from 'src/utils/helpers';

import styles from './BestOfferThumbnail.module.scss';

const BestOfferThumbnail = ({ offer }) => {
  const { company_name, price, supplier_name } = offer;

  return (
    <div className={styles.container}>
      <div className={styles.price}>
        <span className={styles.price_wrapper}>{getFormattedAmount(price)}</span>
        <InfoWidget>
          <p>{company_name}</p>
        </InfoWidget>
      </div>
      <div className={styles.tag_wrapper}>
        <Tag value={supplier_name} />
      </div>
    </div>
  );
};

export default BestOfferThumbnail;
