import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Button, Loader, Modal, PageHeader, useModalConfirm, useRequestAbortController } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WholesalersApi } from 'src/api';
import query from 'src/utils/query';

import { refreshWholesalers } from '../Wholesalers/actions';
import AddWholesalerForm from '../Wholesalers/components/AddWholesalerForm';
import WholesalerDetails from './components/WholesalerDetails';
import WholesalerTabs from './components/WholesalerTabs';

import styles from './WholesalersView.module.scss';

const WholesalersView = (props) => {
  const params = query(props);
  const history = useHistory();
  const { uid } = useParams();
  const dispatch = useDispatch();
  const version = useSelector((state) => state.wholesalers.version);

  const [pageData, setPageData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const deleteWholesaler = async () => {
    try {
      await WholesalersApi.deleteWholesaler(pageData?.id);
      history.push('/wholesalers');
      notifyCommon(['Hurtownia usunięta.']);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteWholesalerConfirm = () => {
    handleOpenModalConfirm({
      message: `Na pewno chcesz usunąć hurtownię ${pageData?.name}?`,
      handleConfirm: deleteWholesaler
    });
  };

  useEffect(() => {
    const getData = async () => {
      if (abortController) abortController.abort();
      const signal = setNewController();

      try {
        setIsLoading(true);
        const { data } = await WholesalersApi.getWholesalerDetails(uid, signal);
        setPageData(data);
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [version]);

  const saveHandler = () => {
    setIsVisibleEdit(false);
    dispatch(refreshWholesalers());
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className={styles.container}>
        {/* <div className={styles.logoContainer}>
          <img
            src={pageData.logo}
            alt={pageData.name}
            className={styles.logo}
          />
        </div> */}
        <PageHeader
          name={pageData.name}
          isLoading={loading}
          metaData={{
            created_at: pageData.created_at,
            author: 'Hurtownia'
          }}
        >
          <Button
            label={'Edytuj'}
            onClick={() => setIsVisibleEdit(true)}
          />
          <Button
            label={'Usuń'}
            onClick={() => deleteWholesalerConfirm()}
            gray
          />
        </PageHeader>
        <WholesalerDetails {...pageData} />
        <WholesalerTabs
          wholesalerId={pageData.id}
          params={params}
        />
      </div>
      <Modal
        visible={isVisibleEdit}
        onClose={() => setIsVisibleEdit(false)}
        title={'Edytuj hurtownię'}
      >
        <AddWholesalerForm
          wholesalerData={pageData}
          onClose={saveHandler}
        />
      </Modal>
      {renderedModalConfirm}
    </>
  );
};

export default WholesalersView;
