import { notifyApiError } from 'src/components/layout/Toasts';

import { apiErrorHandler } from './utils';

const actionRequest = (type, props) => {
  return { type, props };
};

const actionSuccess = (type, data, props) => {
  return { type, data, props };
};

const actionFailure = (type, data, props) => {
  return { type, data, props };
};

export const createApiAction =
  (apiAction, requestType, successType, failureType, silentFor = []) =>
  (props) =>
  async (dispatch) => {
    dispatch(actionRequest(requestType, props));
    const apiPromise = apiAction(props);

    try {
      const response = await apiPromise;
      await dispatch(actionSuccess(successType, response.data, props));
    } catch (error) {
      const errorDetail = apiErrorHandler(error);
      const preventNotify = error?.response?.status && silentFor.includes(error.response.status);
      if (!preventNotify) {
        notifyApiError(error);
      }
      dispatch(actionFailure(failureType, errorDetail, props));
    }

    return apiPromise;
  };
