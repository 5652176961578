import React from 'react';
import { useAtom } from 'jotai/index';

import { FilterByCategory, FilterByTag, Searchbar, useIsAdmin } from 'components';
import FilterByOptions from 'components/layout/filtering/FilterByOptions';

import { TagsApi, WarehouseApi } from 'src/api';
import { selectedCompanyAtom } from 'src/features/Warehouse';

import style from './Filtering.module.scss';

const sortOptions = [
  { label: 'Przekroczono stan minimalny', value: 'out_of_stock' },
  { label: 'Po terminie ważności', value: 'expired' }
];

const statusToOption = (status) => sortOptions.find((el) => el.value === status);

const Filtering = ({ params }) => {
  const isAdmin = useIsAdmin();
  const [selectedCompany] = useAtom(selectedCompanyAtom);

  return (
    <div className={style.container}>
      <Searchbar
        params={params}
        fullWidth
        placeholder={'Wpisz nazwę...'}
      />
      <Searchbar
        params={params}
        fullWidth
        paramName={'ean'}
        placeholder={'Wpisz kod EAN...'}
      />
      <FilterByCategory
        params={params}
        apiCallback={WarehouseApi.getProductsCategories}
        queryParams={{ ...(isAdmin && { company_id: selectedCompany }) }}
        fullWidth
      />
      <FilterByTag
        params={params}
        apiCallback={TagsApi.getTags}
        valueKey={'id'}
        labelKey={'name'}
        queryParams={{ ...(isAdmin && { company_id: selectedCompany }) }}
        isMulti
        fullWidth
      />
      <FilterByOptions
        valueToOption={statusToOption}
        placeholder={'Filtruj wyniki...'}
        optionKey={'filter'}
        options={sortOptions}
        params={params}
      />
    </div>
  );
};

export default Filtering;
