import React from 'react';

import ValuationReplacementsThumbnail from '../ValuationReplacementsThumbnail';

import styles from '../../ValuationReplacements.module.scss';

const ValuationReplacementsTable = ({ replacements }) => {
  return (
    <div className={styles.table}>
      <div className={styles.table_wrapper}>
        {replacements.map((replacement) => (
          <ValuationReplacementsThumbnail
            key={replacement.id}
            replacement={replacement}
          />
        ))}
      </div>
    </div>
  );
};

export default ValuationReplacementsTable;
