import React from 'react';
import classNames from 'classnames';

import { Menu } from 'components';

import style from '../ProductComponents.module.scss';

const ItemMenu = ({ actions = [], disabled }) => {
  return (
    <div
      className={classNames(style.wrapper, style.menuWrapper)}
      id={'product-menu'}
    >
      <Menu
        actions={actions}
        className={style.menu}
        disabled={disabled}
      />
    </div>
  );
};

export default ItemMenu;
