import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Card, Menu, Modal, SaveButton, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ReplacementsApi } from 'src/api';
import { handleNumeralWords } from 'src/utils/helpers';

import { refreshReplacements } from '../../actions';
import AddProductsToGroupForm from '../AddProductsToGroupForm';
import DefineGroupForm from '../DefineGroupForm';
import MergeGroupsForm from '../MergeGroupsForm';
import ReplacementsGroupProducts from '../ReplacementsGroupProducts';

import styles from './ReplacementsGroup.module.scss';
import shared from 'styles/Shared.module.scss';

const ReplacementsGroup = (props) => {
  const dispatch = useDispatch();

  const { id, name, description, products, refreshCallback } = props;

  const [isEditGroupOpen, setIsEditGroupOpen] = useState(false);
  const [isAddProductOpen, setIsAddProductOpen] = useState(false);
  const [isMergeGroupsOpen, setIsMergeGroupsOpen] = useState(false);

  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const handleDeleteGroup = async () => {
    try {
      const { data } = await ReplacementsApi.deleteGroup(id);
      dispatch(refreshReplacements());
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const openDeleteGroupModalConfirm = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć wybraną grupę?',
      handleConfirm: handleDeleteGroup,
      content: (
        <p>
          Usunięta zostanie grupa o nazwie <strong>{name}</strong>.
        </p>
      )
    });
  };

  const actions = [
    {
      title: 'Dodaj produkty',
      icon: 'add',
      color: 'blue',
      action: () => setIsAddProductOpen(true)
    },
    {
      title: 'Edytuj grupę',
      icon: 'edit',
      action: () => setIsEditGroupOpen(true)
    },
    {
      title: 'Połącz grupę',
      icon: 'share',
      action: () => setIsMergeGroupsOpen(true)
    },
    {
      title: 'Usuń grupę',
      icon: 'trash',
      color: 'red',
      action: openDeleteGroupModalConfirm
    }
  ];

  const groupData = {
    id,
    name,
    description
  };

  return (
    <>
      <Card className={styles.wrapper}>
        <div className={styles.groupHeader}>
          <div>
            <h2 className={styles.groupHeadline}>
              Grupa: <strong className={shared.colorGreen}>{name}</strong>
              <small>
                {`(${products?.length || '0'} ${handleNumeralWords(['produkt', 'produkty', 'produktów'], products?.length)})`}
              </small>
            </h2>
            {description && <p className={styles.groupDescription}>{description}</p>}
          </div>
          <div className={styles.groupHeaderActions}>
            <SaveButton
              type={'add'}
              onClick={() => setIsAddProductOpen(true)}
            />
            <Menu
              className={styles.menu}
              actions={actions}
            />
          </div>
        </div>
        <ReplacementsGroupProducts
          products={products}
          groupId={id}
          refreshCallback={refreshCallback}
        />
      </Card>
      <Modal
        visible={isEditGroupOpen}
        onClose={() => setIsEditGroupOpen(false)}
        title={'Edytuj grupę'}
      >
        <DefineGroupForm
          groupData={groupData}
          closeModal={() => setIsEditGroupOpen(false)}
        />
      </Modal>
      <Modal
        visible={isAddProductOpen}
        onClose={() => setIsAddProductOpen(false)}
        title={'Dodaj produkty'}
      >
        <AddProductsToGroupForm
          groupId={id}
          groupName={name}
          closeModal={() => setIsAddProductOpen(false)}
          refreshCallback={refreshCallback}
        />
      </Modal>
      <Modal
        visible={isMergeGroupsOpen}
        onClose={() => setIsMergeGroupsOpen(false)}
        title={'Połącz grupy'}
      >
        <MergeGroupsForm
          closeModal={() => setIsMergeGroupsOpen(false)}
          groupId={id}
          groupName={name}
        />
      </Modal>
      {renderedModalConfirm}
    </>
  );
};

export default ReplacementsGroup;
