import React, { useState } from 'react';
import { useAtomValue } from 'jotai';

import { Button, Input, PhoneInput, useIsAdmin, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ServicesApi } from 'src/api';
import { selectedCompanyAtom } from 'src/features/Services';

import style from './CreateServiceForm.module.scss';

const defaultService = {
  name: '',
  nip: '',
  email: '',
  keeper: '',
  phone: ''
};

const CreateServiceForm = ({ servicerData, onClose, refreshCallback }) => {
  const isAdmin = useIsAdmin();
  const selectedCompany = useAtomValue(selectedCompanyAtom);

  const validator = useValidator();

  const [isLoading, setIsLoading] = useState(false);
  const [servicer, setServicer] = useState(servicerData || defaultService);

  const onChange = (e) => {
    setServicer((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const onPhoneChange = (value) => {
    setServicer((prev) => ({
      ...prev,
      phone: value
    }));
  };

  const addOrEditServiceHandler = async (e, type) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      ...(isAdmin && { company_id: selectedCompany }),
      ...(type === 'edit' && { servicer_id: servicerData.id }),
      ...servicer
    };

    const apiCallback = type === 'edit' ? ServicesApi.editServicer : ServicesApi.addServicer;

    try {
      setIsLoading(true);
      await apiCallback(params);
      if (onClose) onClose();
      if (refreshCallback) refreshCallback();
      notifyCommon([`Serwisant został pomyślnie ${type === 'add' ? 'zapisany' : 'dodany'}.`]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={(e) => addOrEditServiceHandler(e, servicerData ? 'edit' : 'add')}
    >
      <Input
        id='name'
        name='name'
        label='Nazwa serwisanta'
        value={servicer.name}
        onChange={onChange}
        validator={validator}
        rule={'required'}
      />
      <Input
        id='keeper'
        name='keeper'
        label='Przedstawiciel'
        value={servicer.keeper}
        onChange={onChange}
        validator={validator}
        rule={'required'}
      />
      <Input
        id='nip'
        name='nip'
        label='NIP'
        value={servicer.nip}
        onChange={onChange}
        validator={validator}
        rule={'required'}
      />
      <Input
        id='email'
        name='email'
        label='E-mail'
        value={servicer.email}
        onChange={onChange}
        validator={validator}
        rule={'required|email'}
      />
      <PhoneInput
        id='phone'
        name='phone'
        label='Telefon kontaktowy'
        value={servicer.phone}
        onChange={onPhoneChange}
        validator={validator}
        rule={'required|phone'}
      />
      <Button
        label={servicerData ? 'Zapisz' : 'Dodaj'}
        isLoading={isLoading}
        className={style.button}
      />
    </form>
  );
};

export default CreateServiceForm;
