import React from 'react';
import classNames from 'classnames';

import { ColumnSortButton, EmptyState } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import WholesalerProductThumbnail from '../WholesalerProductThumbnail';

import style from './WholesalerProductsTable.module.scss';
import shared from 'styles/Shared.module.scss';

const WholesalerProductsTable = ({ list = [], isLoading, params, refetchProducts }) => {
  if (isLoading) {
    return (
      <section
        className={shared.tableWrapper}
        style={{ paddingTop: 0 }}
      >
        <div className={classNames(shared.tableHeader, style.header)}>
          <p>Nazwa</p>
          <p>ID w hurtownii</p>
          <p>Kategoria</p>
          <p>Producent</p>
          <p>Dodano</p>
          <p></p>
        </div>
        <div className={shared.tableCategory}>
          <ProductPlaceholder />
        </div>
      </section>
    );
  }

  return (
    <section
      className={shared.tableWrapper}
      style={{ paddingTop: 0 }}
    >
      {list.length > 0 && (
        <div className={classNames(shared.tableHeader, style.header)}>
          <ColumnSortButton
            parameterName={'name'}
            params={params}
            content={'Nazwa'}
          />
          <p>ID w hurtownii</p>
          <p>Kategoria</p>
          <p>Producent</p>
          <ColumnSortButton
            parameterName={'created_at'}
            params={params}
            content={'Dodano'}
          />
          <p>Edytowano</p>
          <p></p>
        </div>
      )}
      <div className={shared.tableCategory}>
        {list.length > 0 ? (
          list.map((product, i) => (
            <WholesalerProductThumbnail
              key={product.product_id}
              refetchProducts={refetchProducts}
              {...product}
            />
          ))
        ) : (
          <EmptyState type={params.keys.search ? 'search' : params.keys.category ? 'search' : 'wholesalersProducts'} />
        )}
      </div>
    </section>
  );
};

export default WholesalerProductsTable;
