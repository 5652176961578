import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import _id from 'lodash';

import { Loader } from 'components';

import style from './OptionCheckbox.module.scss';

const progressBarHeight = 58;

const OptionCheckbox = (props) => {
  const {
    name,
    label,
    header,
    value,
    onChange,
    handleDelete,
    isChecked,
    children,
    showBackground,
    className,
    borderOnSelect,
    tickOnSelect,
    disabled,
    isLoading,
    large = false,
    hideRadio = false,
    small
  } = props;

  const element = useRef();
  const input = useRef();
  const [height, setHeight] = useState();

  useEffect(() => {
    const table = document.querySelector('#valuation-table');
    if (element?.current && table) {
      setHeight(table.clientHeight + element.current.clientHeight + progressBarHeight + 'px');
    } else {
      setHeight('50vh');
    }
  }, [showBackground]);

  const inputClick = () => {
    if (!disabled) {
      if (isChecked && handleDelete) {
        handleDelete();
      }
    }
  };

  return (
    <div
      ref={element}
      className={classNames(
        style.container,
        {
          [style.hideBorder]: showBackground,
          [style.borderSelected]: borderOnSelect && isChecked,
          [style.large]: large,
          [style.small]: small
        },
        className
      )}
    >
      {(showBackground || disabled) && (
        <div
          style={{ height }}
          className={classNames(style.background, {
            [style.disabled]: disabled
          })}
        />
      )}
      <div className={style.header}>
        {!header && (
          <label
            className={style.optionName}
            htmlFor={name}
          >
            {label}
          </label>
        )}
        {header && header}
        {isLoading ? (
          <div className={style.loaderWrapper}>
            <Loader />
          </div>
        ) : (
          <div
            className={classNames(style.inputWrapper, {
              [style.tick]: tickOnSelect && !isLoading
            })}
            onClick={inputClick}
          >
            {!hideRadio && (
              <input
                data-hj-allow
                ref={input}
                type='radio'
                id={String(_id)}
                value={value}
                checked={isChecked}
                onChange={onChange}
                className={classNames(style.box, {
                  [style.tick]: tickOnSelect
                })}
              />
            )}
          </div>
        )}
      </div>
      <div className={style.wrapper}>{children}</div>
    </div>
  );
};

export default OptionCheckbox;
