import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input, PhoneInput, useAuthUser, useIsAdmin, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { UserApi } from 'src/api';
import { refreshUserData } from 'src/features/onboarding/actions';

import style from './UserDataForm.module.scss';

const UserDataForm = () => {
  const user = useAuthUser();
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const validator = useValidator();

  const [name, setName] = useState(user.name);
  const [phone, setPhone] = useState(user.phone);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const data = { user_name: name, phone };

    try {
      setIsLoading(true);
      await UserApi.updateUserData(data);
      await dispatch(refreshUserData());
      notifyCommon(['Dane zostały zapisane.']);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={handleSubmit}
    >
      <Input
        id='email'
        name='email'
        label='E-mail'
        value={user.email}
        disabled
      />
      <Input
        id='name'
        name='name'
        label='Imię i nazwisko'
        value={name}
        onChange={(e) => setName(e.target.value)}
        disabled={isLoading || isAdmin}
        validator={validator}
        rule={'required'}
      />
      <PhoneInput
        id='phone'
        name='phone'
        label='Telefon'
        value={phone}
        onChange={setPhone}
        disabled={isLoading || isAdmin}
        validator={validator}
        rule={'required|phone'}
      />
      <Button
        label={'Zapisz dane'}
        type={'submit'}
        className={style.button}
        disabled={isAdmin}
      />
    </form>
  );
};

export default UserDataForm;
