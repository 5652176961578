import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { Checkbox, Modal, Tag, useModalConfirm } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';
import { notifyApiError } from 'components/layout/Toasts';

import { CompanyApi } from 'src/api';
import { getRandomSupplierColor, getSupplierBackgroundColor } from 'src/utils/helpers';

import { refreshProducts } from '../../actions';
import AddSupplierToProduct from '../AddSupplierToProduct';
import SuggestedSuppliers from '../SuggestedSuppliers';

import style from '../../SuppliersManagement.module.scss';

const modalType = {
  suggested: 'SUGGESTED',
  add: 'ADD'
};

const Product = ({ product, selectedProducts = [], setSelectedProducts, isCheckboxVisible }) => {
  const { name, id, thumbnail, image, category_name } = product || {};
  const dispatch = useDispatch();
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingId, setLoadingId] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [modalContentType, setModalContentType] = useState(null);

  const deleteSupplier = async (id) => {
    const data = {
      product_ids: [product.id],
      supplier_id: id
    };

    try {
      setIsLoading(true);
      setLoadingId(id);
      await CompanyApi.removeSupplierFromProduct(data);
      dispatch(refreshProducts());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
      setLoadingId(null);
    }
  };

  const deleteHandler = (id) => {
    handleOpenModalConfirm({
      message: 'Czy chcesz usunąć dostawcę z produktu?',
      handleConfirm: () => deleteSupplier(id),
      cancelButtonName: 'Nie',
      confirmButtonName: 'Tak'
    });
  };

  const selectProduct = (prod) => {
    if (selectedProducts.find((product) => product.id === prod.id)) {
      setSelectedProducts((prev) => prev.filter((product) => product.id !== prod.id));
    } else {
      setSelectedProducts((prev) => [...prev, prod]);
    }
  };

  const closeOpenModalHandler = (type) => {
    setIsOpen(!!type);
    setModalContentType(type || null);
  };

  const modalContent = useMemo(() => {
    switch (modalContentType) {
      case modalType.add:
        return {
          title: 'Dodaj dostawcę do produktu',
          content: (
            <AddSupplierToProduct
              id={product.id}
              name={product.name}
              selectedSuppliers={product.suppliers}
              closeModal={() => setIsOpen(false)}
            />
          )
        };
      case modalType.suggested:
        return {
          title: 'Lista dostawców, którzy mają produkt w ofercie',
          content: (
            <SuggestedSuppliers
              suppliers={product?.suggested_suppliers}
              productId={product.id}
              closeModal={closeOpenModalHandler}
            />
          )
        };
      default:
        return {
          title: '',
          content: null
        };
    }
  }, [modalContentType]);

  return (
    <div className={style.product}>
      <div className={style.productDetails}>
        {isCheckboxVisible && (
          <Checkbox
            value={!!selectedProducts.find((product) => product.id === id)}
            onChange={() => selectProduct(product)}
            wrapperStyle={style.checkbox}
          />
        )}
        <ProductThumbnailImage
          title={name}
          size={'tiny'}
          thumbnail={thumbnail}
          image={image}
          id={id}
        />
        <p className={style.name}>
          {name} | {category_name}
        </p>
      </div>
      <div className={cn(style.wrapper, style.list)}>
        {product.suppliers.map((sup) => (
          <div
            className={style.wrapper}
            key={sup.id}
          >
            <Tag
              value={sup.name}
              className={cn(style.tag, { [style.loading]: isLoading && loadingId === sup.id })}
              style={getSupplierBackgroundColor(sup, getRandomSupplierColor(sup.id))}
              onClick={() => deleteHandler(sup.id)}
            />
          </div>
        ))}
        <a
          onClick={() => closeOpenModalHandler(modalType.add)}
          className={style.tag}
        >
          Dodaj +
        </a>
      </div>
      {product.suggested_suppliers.length > 0 && (
        <button
          onClick={() => closeOpenModalHandler(modalType.suggested)}
          className={style.button}
        >
          {`Sugerowani dostawcy (${product.suggested_suppliers.length})`}
        </button>
      )}
      <Modal
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        title={modalContent.title}
      >
        {modalContent.content}
      </Modal>
      {renderedModalConfirm}
    </div>
  );
};

export default Product;
