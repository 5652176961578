import React, { useState } from 'react';
import cn from 'classnames';

import { Modal, ProductThumbnailImage, SaveButton, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ExpensesApi } from 'src/api';
import ExpenseCategoryForm from 'src/features/ExpensesManagement/components/ExpenseCategoryForm';

import style from './ExpenseCategory.module.scss';

const ExpenseCategory = ({ category, refreshCallback }) => {
  const { logo, category_name, description } = category;
  const [isCreateVisible, setIsCreateVisible] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const closeCreateModal = () => setIsCreateVisible(false);

  const deleteExpenseCategoryHandler = () =>
    handleOpenModalConfirm({
      message: 'Czy na pewno chcesz usunąć kategorię? Aktualnie dodane wydatki zostaną bez zmian.',
      handleConfirm: deleteExpenseCategory,
      cancelButtonName: 'Nie',
      confirmButtonName: 'Tak'
    });

  const deleteExpenseCategory = async () => {
    try {
      await ExpensesApi.deleteCategory(category.id);
      notifyCommon(['Kategoria została usunięta.']);
      refreshCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <ProductThumbnailImage thumbnail={logo} />
        <div>
          <h4 className={style.title}>{category_name}</h4>
          <p>{description || '-'}</p>
        </div>
      </div>
      <div className={cn(style.wrapper, style.last)}>
        <SaveButton
          type={'edit'}
          onClick={() => setIsCreateVisible(true)}
        />
        <SaveButton
          type={'delete'}
          onClick={deleteExpenseCategoryHandler}
        />
      </div>
      {renderedModalConfirm}
      <Modal
        visible={isCreateVisible}
        onClose={closeCreateModal}
        title={`Edytuj kategorię: ${category.category_name}`}
      >
        <ExpenseCategoryForm
          closeModal={closeCreateModal}
          refreshCallback={refreshCallback}
          masterCategoryId={category.id}
          defaultCategory={{ ...category, name: category.category_name }}
        />
      </Modal>
    </div>
  );
};

export default ExpenseCategory;
