import React from 'react';

import { FilterByCompany, FilterByDateRange, useAuthUser } from 'components';
import FilterByProducts from 'components/layout/filtering/FilterByProducts';
import FilterBySupplier from 'components/layout/filtering/FilterBySupplier';

import style from './OrdersFiltering.module.scss';

const OrdersFiltering = ({ params }) => {
  const user = useAuthUser();

  return (
    <div className={style.container}>
      <FilterByProducts
        params={params}
        isMulti
        fullWidth
      />
      <FilterByCompany
        params={params}
        isMulti
        fullWidth
      />
      <FilterByDateRange
        params={params}
        fullWidth
      />
      {user.role !== 'supplier' && (
        <FilterBySupplier
          params={params}
          asUser
          isMulti
          fullWidth
        />
      )}
    </div>
  );
};

export default OrdersFiltering;
