import React from 'react';
import { Link } from 'react-router-dom';

import { useIsMobile } from 'components';

import { toDateNumber } from 'src/utils/dateTime';
import { getFormattedAmount } from 'src/utils/helpers';

import style from '../../TableWithPagination.module.scss';

const Thumbnail = ({ item, asOrder }) => {
  const { public_id, price, producer_name, qty, unit, created_at, uuid } = item || {};
  const isTabletWidth = useIsMobile(768);

  return (
    <div className={style.order}>
      {asOrder ? (
        <Link to={`/orders/${uuid}`}>
          <p>#{public_id}</p>
        </Link>
      ) : (
        <p>#{public_id}</p>
      )}
      <p>{producer_name}</p>
      <p>{getFormattedAmount(price)}</p>
      {!isTabletWidth && (
        <>
          <p>
            {qty} {unit}
          </p>
          <p>{getFormattedAmount(price * qty)}</p>
          <p>{toDateNumber(created_at)}</p>
        </>
      )}
    </div>
  );
};

export default Thumbnail;
