import React, { useState } from 'react';

import { Button, Input, QtySelector, Textarea, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OCRInvoicesApi } from 'src/api';

import styles from './AddProductFormOCR.module.scss';

const AddProductFormOCR = ({ invoiceId, refreshCallback, onClose }) => {
  const [product, setProduct] = useState({
    name: '',
    price: 0,
    wh_product_number: ''
  });
  const [qtyValue, setQtyValue] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const validator = useValidator();

  const onChangeHandler = (name, data) => {
    setProduct((prev) => ({
      ...prev,
      [name]: data
    }));
  };

  const onChange = (e) => {
    setProduct((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      invoice_id: invoiceId,
      name: product.name,
      price: +product.price,
      quantity: +qtyValue,
      ...(product.wh_product_number && { wh_product_number: product.wh_product_number })
    };

    try {
      setIsLoading(true);
      await OCRInvoicesApi.addInvoiceProduct(params);
      notifyCommon(['Produkt został dodany.']);
      if (onClose) onClose();
      if (refreshCallback) refreshCallback();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={styles.container}
      onSubmit={handleAddProduct}
    >
      <Textarea
        id={'name'}
        name={'name'}
        label={'Nazwa produktu'}
        value={product.name || ''}
        setValue={(name) => onChangeHandler('name', name)}
        validator={validator}
        rule={'required'}
      />
      <div className={styles.row}>
        <QtySelector
          id='price'
          name='price'
          label='Cena'
          state={product.price}
          setState={(price) => onChangeHandler('price', price)}
          width={'100%'}
          suffix={'zł'}
          priceInput
          hideArrows
          validator={validator}
          rule={'required|only_positive'}
        />
        <QtySelector
          label={'Ilość'}
          id={product.id + '-new-qty'}
          name={product.id + '-new-qty'}
          state={qtyValue}
          setState={setQtyValue}
          width={'100%'}
          validator={validator}
          rule={'required|only_positive'}
        />
      </div>
      <Input
        id='wh_product_number'
        name='wh_product_number'
        label='ID hurtownii'
        value={product.wh_product_number}
        onChange={onChange}
      />
      <Button
        label={'Dodaj'}
        isLoading={isLoading}
        className={styles.button}
      />
    </form>
  );
};

export default AddProductFormOCR;
