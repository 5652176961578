import React from 'react';

import { Card, Icon } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import styles from './ProductsComparasion.module.scss';

const ProductsComparasion = ({ currentProduct, selectedReplacement }) => {
  const { thumbnail, image, name, product_id } = currentProduct;

  return (
    <div className={styles.container}>
      <Card
        className={styles.card}
        info
      >
        <ProductThumbnailImage
          thumbnail={thumbnail}
          image={image}
          title={name}
          id={product_id}
        />
        <p>{name}</p>
      </Card>
      <div>
        <Icon
          name='arrow_down'
          size='18'
          fill='#3e877e'
          className={styles.arrow}
        />
      </div>
      <Card
        className={styles.card}
        info
      >
        {selectedReplacement ? (
          <>
            <ProductThumbnailImage
              thumbnail={selectedReplacement.thumbnail}
              image={selectedReplacement.image}
              title={selectedReplacement.name}
              id={selectedReplacement.product_id}
            />
            <p>{selectedReplacement.name}</p>
          </>
        ) : (
          <p className={styles.empty}>Wybierz zamiennik</p>
        )}
      </Card>
    </div>
  );
};

export default ProductsComparasion;
