import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import ProductPlaceholder from 'components/layout/ProductPlaceholder';
import { notifyApiError } from 'components/layout/Toasts';

import { UserApi } from 'src/api';

import WholesalerSupplierDetailsThumbnail from './components/WholesalerSupplierDetailsThumbnail';

import styles from './WholesalerSupplierDetails.module.scss';
import shared from 'styles/Shared.module.scss';

const WholesalerSupplierDetails = ({ search, refetchSuppliers }) => {
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await UserApi.getDistributors({
        pagination: 0,
        search
      });
      setPageData(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles.container}>
      {loading ? (
        <ProductPlaceholder quantity={2} />
      ) : (
        <>
          <div className={classNames(shared.tableHeader, styles.header)}>
            <p>Lp.</p>
            <p>Właściciel</p>
            <p>Wysyłka</p>
            <p>Darmowa wysyłka</p>
            <p></p>
          </div>
          {pageData.map(
            (supplier, i) =>
              supplier.wholesaler_id && (
                <WholesalerSupplierDetailsThumbnail
                  key={supplier.id}
                  index={i + 1}
                  refetchSuppliers={refetchSuppliers}
                  {...supplier}
                />
              )
          )}
        </>
      )}
    </div>
  );
};

export default WholesalerSupplierDetails;
