import { useState } from 'react';

import ModalConfirm from 'components/layout/ModalConfirm';

export default function useModalConfirm() {
  const [passedData, setPassedData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModal = (props) => {
    setPassedData({
      message: props.message,
      content: props.content,
      cancelButtonName: props.cancelButtonName,
      confirmButtonName: props.confirmButtonName,
      handleConfirm: props.handleConfirm,
      handleCancel: props.handleCancel
    });
    setIsModalVisible(true);
  };

  const renderedModalConfirm = (
    <ModalConfirm
      message={passedData.message}
      content={passedData.content}
      visible={isModalVisible}
      setVisible={setIsModalVisible}
      onConfirm={passedData.handleConfirm}
      onCancel={passedData.handleCancel}
      cancelButtonName={passedData.cancelButtonName}
      confirmButtonName={passedData.confirmButtonName}
    />
  );

  return [renderedModalConfirm, handleOpenModal];
}
