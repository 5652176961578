import React from 'react';

import { DateRangePicker, Select } from 'components';

import style from './FilteringAndSorting.module.scss';

export const contentRangeTypes = [
  { value: 'day', label: 'dni' },
  { value: 'week', label: 'tygodnie' },
  { value: 'month', label: 'miesiące' }
];

const FilteringAndSorting = (props) => {
  const { startDate, setStartDate, endDate, setEndDate, selectedType, setSelectedType } = props;

  return (
    <div className={style.container}>
      <DateRangePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        wrapperStyle={style.wrapper}
        setEndDate={setEndDate}
        label={'Zakres dat'}
      />
      <Select
        options={contentRangeTypes}
        value={selectedType}
        onChange={setSelectedType}
        wrapperStyle={style.wrapper}
        width={150}
        label={'Z podziałem na'}
        placeholder={'tydzień'}
        isSearchable={false}
      />
    </div>
  );
};

export default FilteringAndSorting;
