import React, { useState } from 'react';

import { Button } from 'components';
import HistorySidebar from 'components/layout/TimelineHistory/components/HistorySidebar';

const TimelineHistory = (props) => {
  const {
    buttonLabel = 'Historia zamówienia',
    title = 'Historia zamówienia',
    history = [],
    getProductName,
    bottomMessage,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    isLoading,
    emptyStateType
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        label={buttonLabel}
        onClick={() => setIsOpen(true)}
        gray
      />
      <HistorySidebar
        getProductName={getProductName}
        history={history}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={title}
        bottomMessage={bottomMessage}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        isLoading={isLoading}
        emptyStateType={emptyStateType}
      />
    </>
  );
};

export default TimelineHistory;
