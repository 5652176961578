import React from 'react';
import classNames from 'classnames';

import { RelativeTime } from 'components';

import { getFormattedAmount, getProductStatusTag } from 'src/utils/helpers';

import styles from '../../ProductsSaleSummary.module.scss';

const ProductsSaleSummaryThumbnail = ({ item }) => {
  const { company_name, product_name, created_at, price, quantity, value, status, supplier_email, wholesaler_name } = item;

  return (
    <div className={classNames(styles.item, styles.grid)}>
      <p>
        <span
          className={styles.textOverflow}
          title={company_name}
        >
          {company_name}
        </span>
      </p>
      <p>
        <span
          className={styles.textOverflow}
          title={product_name}
        >
          {product_name}
        </span>
      </p>
      <p>
        <RelativeTime date={created_at} />
      </p>
      <p>{getFormattedAmount(price)}</p>
      <p>{quantity}</p>
      <p>{getFormattedAmount(value)}</p>
      <p className={styles.statusWrapper}>{getProductStatusTag(status)}</p>
      <p>
        <span
          className={styles.textOverflow}
          title={supplier_email}
        >
          {supplier_email}
        </span>
      </p>
      <p>
        <span
          className={styles.textOverflow}
          title={wholesaler_name}
        >
          {wholesaler_name || '-'}
        </span>
      </p>
    </div>
  );
};

export default ProductsSaleSummaryThumbnail;
