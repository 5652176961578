import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Menu } from 'components';
import Checkbox from 'components/layout/Checkbox';
import { notifyApiError } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { deleteShipmentMethod, toggleShipmentMethod } from 'src/features/SupplierForm/actions';

import style from './ShippingSelection.module.scss';

const ShippingSelection = ({ shippingMethod, disabled, showError, uid }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(shippingMethod.selected);
  const [isLoading, setIsLoading] = useState(false);

  const changeSelected = (e) => {
    setSelected(e.target.checked);
    dispatch(toggleShipmentMethod(shippingMethod.id));
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await SupplierApi.deleteShippingMethod(shippingMethod.id, uid);
      dispatch(deleteShipmentMethod(shippingMethod.id));
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (shippingMethod.selected || shippingMethod.isDefault) {
      setSelected(true);
    }
  }, [shippingMethod]);

  const actions = [
    {
      title: 'Usuń formę wysyłki',
      icon: 'trash',
      action: handleDelete,
      disabled: disabled
    }
  ];

  return (
    <div className={style.container}>
      <Checkbox
        key={shippingMethod?.id}
        value={selected}
        content={shippingMethod?.name}
        onChange={changeSelected}
        disabled={shippingMethod.isDefault || disabled}
        showError={showError}
        wrapperStyle={classNames({
          [style.opacity]: isLoading
        })}
      />
      {!disabled && (
        <Menu
          actions={actions}
          className={style.menu}
        />
      )}
    </div>
  );
};

export default ShippingSelection;
