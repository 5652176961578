import React, { useState } from 'react';
import dayjs from 'dayjs';

import { Button, Modal } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { EXCEL_FILE_TYPE } from 'src/constants/misc';
import { downloadFile, getDecodeBase64File } from 'src/utils/helpers';

import style from './ExportData.module.scss';

const fileFormats = ['CSV', 'XLSX'];
const buttonsLabels = { CSV: 'Pobierz w formacie .csv', XLSX: 'Pobierz w formacie .xlsx' };

const ExportData = ({
  apiCallback,
  company_id,
  filters,
  formats = fileFormats,
  labels = buttonsLabels,
  children,
  queryParams,
  asButton
}) => {
  const [isOpen, setIsOpen] = useState();
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const [isXLSXLoading, setIsXLSXLoading] = useState(false);

  const date = dayjs().format('DD.MM.YYYY, HH:mm');

  const downloadCSV = async (data) => {
    const blob = new Blob([data], { type: 'text/csv' });
    downloadFile(blob, date + '.csv');
  };
  const downloadXLSX = async (data) => {
    const blob = getDecodeBase64File(data, EXCEL_FILE_TYPE);
    downloadFile(blob, date + '.xlsx');
  };

  const downloadHandler = async (file_format) => {
    const queryData = {
      company_id,
      file_format,
      company_ids: filters.selectedCompanies.map((company) => company.value),
      date_from: filters.startDate,
      date_to: filters.endDate,
      ...(!!queryParams && queryParams)
    };

    try {
      file_format === 'CSV' ? setIsCSVLoading(true) : setIsXLSXLoading(true);
      const { data } = await apiCallback(queryData);
      file_format === 'CSV' ? downloadCSV(data) : downloadXLSX(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      file_format === 'CSV' ? setIsCSVLoading(false) : setIsXLSXLoading(false);
    }
  };

  if (asButton) {
    return formats.map((f) => (
      <Button
        key={f}
        label={labels[f]}
        isLoading={f === 'CSV' ? isCSVLoading : isXLSXLoading}
        onClick={() => downloadHandler(f)}
      />
    ));
  }

  return (
    <>
      <Button
        label={'Eksportuj'}
        onClick={() => setIsOpen(true)}
      />
      <Modal
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        title={'Eksportuj dane'}
      >
        <div className={style.container}>
          {formats.map((f) => (
            <Button
              key={f}
              label={labels[f]}
              isLoading={f === 'CSV' ? isCSVLoading : isXLSXLoading}
              onClick={() => downloadHandler(f)}
            />
          ))}
          {children}
        </div>
      </Modal>
    </>
  );
};

export default ExportData;
