import React from 'react';
import classNames from 'classnames';

import visibility from 'src/assets/icons/visibility.svg';
import visibilityOff from 'src/assets/icons/visibility-off.svg';
import { imageErrorHandler } from 'src/utils/helpers';

import style from './InputPasswordButton.module.scss';

const InputPasswordButton = (props) => {
  const { isVisible, setIsVisible, className, hasInputLabel = false } = props;

  return (
    <button
      type='button'
      onClick={() => setIsVisible((prev) => !prev)}
      className={classNames(
        style.visibilityButton,
        {
          [style.withLabel]: hasInputLabel
        },
        className
      )}
    >
      {isVisible ? (
        <img
          src={visibility}
          alt='hide password'
          onError={imageErrorHandler}
        />
      ) : (
        <img
          src={visibilityOff}
          alt='show password'
          onError={imageErrorHandler}
        />
      )}
    </button>
  );
};

export default InputPasswordButton;
