import { useState } from 'react';
import classNames from 'classnames';

import { Icon } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { TagsApi } from 'src/api';

import styles from './ItemTag.module.scss';

const ItemTag = ({ name, color, tagId, productId, refreshCallback, refreshPageDataCallback }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteProductTag = async () => {
    try {
      setIsLoading(true);
      const { data } = await TagsApi.deleteProductTag({
        product_id: productId,
        tag_id: tagId
      });
      if (refreshCallback) await refreshCallback(true);
      if (refreshPageDataCallback) await refreshPageDataCallback();

      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <span className={classNames(styles.container, { [styles.isLoading]: isLoading })}>
      <span className={styles.icon}>
        <Icon
          name='tag'
          fill={color || '#515c6a'}
          size={14}
        />
      </span>
      {name}
      {!!productId && (
        <button
          className={classNames(styles.icon, styles.delete)}
          onClick={handleDeleteProductTag}
        >
          <Icon
            name='close'
            fill={'#515c6a'}
            size={14}
          />
        </button>
      )}
    </span>
  );
};

export default ItemTag;
