import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ReactComponent as BoxIcon } from 'assets/icons/box-mini.svg';
import { ReactComponent as ReplacementIcon } from 'assets/icons/replacement-blue.svg';
import { OptionCheckbox, ProductStatus, ReplacementDetails, Tag, ValuationSettings } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ValuationsApi } from 'src/api';
import { valuationStatuses } from 'src/constants/enums';
import { getFormattedAmount } from 'src/utils/helpers';

import { refreshValuations, updateSingleSaving } from '../../../../actions';

import style from '../../Column.module.scss';

export const EmptyWrapper = ({ children, supplierName, status }) => {
  return (
    <div className={style.offerBox}>
      <div className={classNames(style.supplierWrapper, style.emptyWrapper)}>
        <div className={style.empty}>{children}</div>
        {supplierName && status === valuationStatuses.sent && (
          <Tag
            value={supplierName}
            className={style.emptyWrapperTag}
          />
        )}
      </div>
    </div>
  );
};

const OfferWrapper = ({ item: item_data, product, changeValuationSelections, horizontal }) => {
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState(false);
  const [item, setItem] = useState(item_data);
  const { product_id, supplier_id, price, discount, availability, type, delivery_date } = item;
  const items = useSelector((state) => state.valuationView.products);
  const stateValuation = items.find(
    (item) => item.product_id === product_id && (item.supplier_id === supplier_id || item.type === type)
  );
  const [isLoadingSelection, setIsLoadingSelection] = useState(false);

  const handleChange = useCallback(async () => {
    const queryParams = {
      pricing_prod_id: item.pricing_prod_id,
      dist_id: supplier_id
    };

    try {
      setIsLoadingSelection(true);
      let { data } = await ValuationsApi.changeSelectedProductDistributor(queryParams);
      changeValuationSelections(data);
      dispatch(updateSingleSaving(data));
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoadingSelection(false);
    }
  }, [isSelected, item.pricing_prod_id, supplier_id]);

  const deleteSelection = useCallback(async () => {
    const queryParams = {
      pricing_prod_id: item.pricing_prod_id,
      dist_id: supplier_id
    };

    const changeSelectionPayload = {
      products_pricings_id: item.pricing_prod_id,
      distributor_id: null
    };

    const removeFromPricing = {
      created_at: null,
      distributor_id: item.supplier_id,
      id: item.id,
      products_pricings_id: item.pricing_prod_id,
      saved_money: 0,
      updated_at: null
    };

    try {
      setIsLoadingSelection(true);
      await ValuationsApi.deleteSelection(queryParams);
      changeValuationSelections(changeSelectionPayload);
      dispatch(updateSingleSaving(removeFromPricing));
    } catch (err) {
      notifyApiError(err);
      setIsLoadingSelection(false);
      dispatch(refreshValuations());
    } finally {
      setIsLoadingSelection(false);
    }
  }, [item.pricing_prod_id, supplier_id]);

  const header = useMemo(
    () => (
      <div className={style.priceWrapper}>
        {stateValuation?.price ? (
          <p className={style.price}>{getFormattedAmount((stateValuation.price * (100 - stateValuation.discount)) / 100)}</p>
        ) : price === null ? (
          <span className={classNames(style.price)} />
        ) : (
          <p className={style.price}>{getFormattedAmount((price * (100 - discount)) / 100)}</p>
        )}
      </div>
    ),
    [stateValuation, price, discount]
  );

  const selectSubstitute = useCallback(async () => {
    const changeSelectedProductParams = {
      pricing_prod_id: item.pricing_prod_id,
      offer_id: item.replacements[0]?.id
    };

    try {
      await ValuationsApi.changeSupplierOffer(changeSelectedProductParams);
      dispatch(refreshValuations());
    } catch (err) {
      notifyApiError(err);
    }
  }, [item.pricing_prod_id, item.replacements]);

  useEffect(() => {
    setItem(item_data);
    setIsSelected(item_data.selected);
  }, [item_data]);

  return (
    <div className={classNames(style.offerBox, { [style.horizontal]: horizontal })}>
      <OptionCheckbox
        name={product.name}
        value={name + item.id}
        onChange={handleChange}
        handleDelete={deleteSelection}
        isChecked={isSelected}
        header={header}
        className={classNames({
          [style.replacement]: item?.type === 'replacement',
          [style.offer]: item?.type === 'offer'
        })}
        borderOnSelect
        tickOnSelect
        isLoading={isLoadingSelection}
        hideRadio={price === null}
      >
        <div className={style.contentWrapper}>
          <div className={style.available}>
            <ProductStatus
              availability={availability}
              avData={delivery_date}
            />
            {/* {item.id === null && item?.replacements?.length && (
              <a
                className={classNames(style.link, style.withIcon)}
                onClick={selectSubstitute}
              >
                <ReplacementIcon />
                Dostępny zamiennik
              </a>
            )} */}
            {/* {item?.type === 'replacement' && <ReplacementDetails replacement={item} />} */}
            {item?.type === 'offer' && (
              <p className={classNames(style.productInfo, style.withIcon)}>
                <BoxIcon />
                Oferowana ilość: {item.quantity}
              </p>
            )}
          </div>
          <div className={style.settings}>
            <Tag
              value={stateValuation?.supplier_name || item.supplier}
              style={{ borderLeft: `5px solid ${item?.color}` || '' }}
            />
            <ValuationSettings
              item={item}
              product={product}
            />
          </div>
        </div>
      </OptionCheckbox>
    </div>
  );
};

export default OfferWrapper;
