import React, { useCallback, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import cn from 'classnames';

import { Card, EmptyState, SwitchButton, Table, useLocalStorage, useManageMany, usePermissions } from 'components';
import AddProductToList from 'components/layout/AddProductToList';

import { userPermissions } from 'src/constants/enums';
import ProductThumbnail from 'src/features/Warehouse/new/ProductThumbnail';
import { handleNumeralWords } from 'src/utils/helpers';

import productStyle from '../ProductThumbnail/ProductThumbnail.module.scss';
import style from './ProductsList.module.scss';

const ProductsList = ({ products = [], params, isLoading, refreshData }) => {
  const containerRef = useRef(null);
  const rightTableRef = useRef(null);
  const [canViewOrders] = usePermissions([userPermissions.orders.read]);

  const [isSticky, setIsSticky] = useState(false);
  const [isModalAddToListOpen, setIsModalAddToListOpen] = useState(false);
  const [isScrollTranslated, setIsScrollTranslated] = useLocalStorage('warehouse_scroll_translate', false);

  const baseColumns = canViewOrders
    ? [
        { name: 'Tagi' },
        { name: 'Stan min.', parameterName: 'out_of_stock_quantity' },
        { name: 'Ilość', parameterName: 'quantity' },
        { name: 'Cena', parameterName: 'price' },
        { name: 'Wartość' },
        { name: 'Data ważności', parameterName: 'expire_date' },
        { name: 'Komentarz' }
      ]
    : [
        { name: 'Tagi' },
        { name: 'Stan min.', parameterName: 'out_of_stock_quantity' },
        { name: 'Ilość', parameterName: 'quantity' },
        { name: 'Data ważności', parameterName: 'expire_date' },
        { name: 'Komentarz' }
      ];

  const checkIfSticky = () => {
    const containerScrollLeft = containerRef.current.scrollLeft;
    setIsSticky(containerScrollLeft > 3);
  };

  const transformScroll = (e) => {
    if (containerRef.current && isScrollTranslated) {
      e.preventDefault();
      containerRef.current.scrollLeft += e.deltaY;
    }
  };

  useEffect(() => {
    containerRef.current?.addEventListener('scroll', checkIfSticky);
    rightTableRef.current?.addEventListener('wheel', transformScroll);

    return () => {
      containerRef.current?.removeEventListener('scroll', checkIfSticky);
      rightTableRef.current?.removeEventListener('wheel', transformScroll);
    };
  }, [isScrollTranslated]);

  const { BulkManagementModule, selectedElements, renderSelectAllCheckbox, renderSingleCheckbox, resetHandler } = useManageMany({
    allElements: products.map((prod) => ({ ...prod, id: prod.product_id })),
    options: [{ label: 'Dodaj wybrane do listy', value: '0', onClick: () => setIsModalAddToListOpen(true) }]
  });

  const selectAllElement = renderSelectAllCheckbox({ content: '', reverse: false });

  const getColumns = useCallback((selectAllElement) => {
    if (selectAllElement) {
      return [{ name: selectAllElement }, { name: 'Nazwa', parameterName: 'name' }];
    } else {
      return [{ name: 'Nazwa', parameterName: 'name' }];
    }
  }, []);

  if (!isLoading && products.length === 0) {
    return (
      <Card className={style.wrapper}>
        <EmptyState type={'warehouseWithFilters'} />
      </Card>
    );
  }

  return (
    <div className={style.container}>
      <div className={style.box}>
        {BulkManagementModule}
        <SwitchButton
          reverse
          value={isScrollTranslated}
          setValue={setIsScrollTranslated}
          label={'Horyzontalne przewijanie listy'}
        />
      </div>
      <div
        className={style.tableWrapper}
        ref={containerRef}
      >
        <Table
          params={params}
          columns={getColumns(selectAllElement)}
          className={cn(style.tableLeft, { [style.shadow]: isSticky })}
          headerClasses={cn(productStyle.header, {
            [productStyle.withChildren]: !!selectAllElement
          })}
        >
          {isLoading ? (
            <Skeleton
              height={55}
              count={10}
              style={{ borderRadius: '10px 0 0 10px' }}
            />
          ) : (
            products.map((product) => (
              <ProductThumbnail
                refreshData={refreshData}
                product={product}
                key={product.id}
                onlyName
              >
                {renderSingleCheckbox(product.product_id)}
              </ProductThumbnail>
            ))
          )}
        </Table>
        <Table
          params={params}
          columns={baseColumns}
          className={cn(style.tableRight)}
          headerClasses={cn(productStyle.rowGrid, { [productStyle.hidePrices]: !canViewOrders })}
          ref={rightTableRef}
        >
          {isLoading ? (
            <Skeleton
              height={55}
              count={10}
              style={{ borderRadius: '0 10px 10px 0' }}
            />
          ) : (
            products.map((product) => (
              <ProductThumbnail
                refreshData={refreshData}
                product={product}
                key={product.id}
              >
                {renderSingleCheckbox(product.product_id)}
              </ProductThumbnail>
            ))
          )}
        </Table>
      </div>
      {BulkManagementModule}
      <AddProductToList
        title={`Dodaj ${selectedElements.length} ${handleNumeralWords(
          ['produkt', 'produkty', 'produktów'],
          selectedElements.length
        )} do wybranej listy`}
        selectedProducts={selectedElements}
        visible={isModalAddToListOpen}
        products={products}
        onClose={() => setIsModalAddToListOpen(false)}
        onSuccess={resetHandler}
        showProductsInput
      />
    </div>
  );
};

export default ProductsList;
