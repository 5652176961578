import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';

import { useIsAdmin, useRequestAbortController } from 'components';
import TimelineHistory from 'components/layout/TimelineHistory';
import { notifyApiError } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { selectedCompanyAtom, versionAtom } from 'src/features/Warehouse';

const WarehouseHistory = () => {
  const isAdmin = useIsAdmin();

  const [version] = useAtom(versionAtom);
  const [selectedCompany] = useAtom(selectedCompanyAtom);

  const [historyData, setHistoryData] = useState();
  const [historyTotal, setHistoryTotal] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();

  const getHistory = async () => {
    const params = {
      page: 1,
      perPage: 100,
      ...(isAdmin && { company_id: selectedCompany })
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    if (isAdmin && !selectedCompany) return null;

    try {
      setIsLoading(true);
      const { data } = await WarehouseApi.getWarehouseHistory(params, signal);
      setHistoryData(data.data);
      setHistoryTotal(data.total);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getHistory();
  }, [version, selectedCompany]);

  return (
    <TimelineHistory
      history={historyData}
      title={'Historia magazynu'}
      buttonLabel={'Historia'}
      bottomMessage={historyData?.length ? `+ ${historyTotal - historyData?.length} innych.` : ''}
      isLoading={isLoading}
    />
  );
};

export default WarehouseHistory;
