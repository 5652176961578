import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import cn from 'classnames';

import style from './CopyToClipboard.module.scss';

const CopyToClipboard = ({ text, fallbackText = '-', renderElement }) => {
  const [isBadgeVisible, setIsBadgeVisible] = useState(false);

  const copyToClipboardHandler = async () => {
    if (text) {
      await navigator.clipboard.writeText(text);
      setIsBadgeVisible(true);
    }
  };

  useEffect(() => {
    let timeout;
    if (isBadgeVisible) {
      timeout = setTimeout(() => setIsBadgeVisible(false), 5000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isBadgeVisible]);

  if (!text) {
    return <div>{fallbackText}</div>;
  }

  return (
    <Popover
      content={isBadgeVisible ? 'Skopiowano!' : 'Kliknij, aby skopiować'}
      placement={'bottomLeft'}
    >
      <div
        className={cn(style.container, { [style.active]: !!text })}
        onClick={copyToClipboardHandler}
      >
        {renderElement ? renderElement(text) : text}
      </div>
    </Popover>
  );
};

export default CopyToClipboard;
