import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Checkbox, Input, PhoneInput, QtySelector } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import { changeSuppliersVersion } from 'src/features/Suppliers/actions';

import style from './DistributorForm.module.scss';

const DistributorForm = ({ closeModal, supplier = null, creationData = null }) => {
  const { id, name, email, phone, shipment_price, free_shipment_price, shipments, payments } = supplier || '';

  const [localName, setName] = useState(name);
  const [localDeliveryPrice, setDeliveryPrice] = useState(shipment_price);
  const [freeDeliveryAmount, setFreeDeliveryAmount] = useState(free_shipment_price);
  const [localPhone, setPhone] = useState(phone);
  const [localEmail, setEmail] = useState(email);
  const [isLoading, setIsLoading] = useState();
  const [localPayments, setPayments] = useState(payments);
  const [localShipments, setShipments] = useState(shipments);
  const dispatch = useDispatch();

  useEffect(() => {
    if (creationData && !supplier) {
      setPayments(creationData.payments);
      setShipments(creationData.shipments);
    }
  }, [creationData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await AdminApi.saveSupplier({
        id: id,
        name: localName,
        phone: localPhone,
        email: localEmail,
        shipment: localDeliveryPrice,
        free_shipment: freeDeliveryAmount,
        payMethods: localPayments.filter((el) => el.selected).map((el) => el.id),
        shipMethods: localShipments.filter((el) => el.selected).map((el) => el.id)
      });
      dispatch(changeSuppliersVersion());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const changeSelectedPayment = (payMethod) => {
    setPayments((state) =>
      state.map((el) => {
        if (el === payMethod) return { ...el, selected: !el.selected };
        return el;
      })
    );
  };

  const changeSelectedShipment = (shipMethod) => {
    setShipments((state) =>
      state.map((el) => {
        if (el === shipMethod) return { ...el, selected: !el.selected };
        return el;
      })
    );
  };

  return (
    <form
      className={style.container}
      onSubmit={(e) => handleSubmit(e)}
    >
      <Input
        id='name'
        name='name'
        placeholder='Sklep XYZ'
        label='Nazwa'
        type='text'
        value={localName}
        onChange={(e) => setName(e.target.value)}
      />
      <div className={style.pricesWrapper}>
        <QtySelector
          id='deliveryPrice'
          name='deliveryPrice'
          label='Cena wysyłki'
          placeholder='15zł'
          width={130}
          state={localDeliveryPrice}
          setState={setDeliveryPrice}
          suffix={'zł'}
          hideArrows
          priceInput
        />
        <QtySelector
          id='freeDeliveryAmount'
          name='freeDeliveryAmount'
          label='Próg darmowej wysyłki'
          placeholder='300zł'
          width={130}
          state={freeDeliveryAmount}
          setState={setFreeDeliveryAmount}
          suffix={'zł'}
          hideArrows
          priceInput
        />
      </div>
      <PhoneInput
        id='phone'
        name='phone'
        label='Telefon'
        value={localPhone}
        onChange={setPhone}
      />
      <Input
        id='email'
        name='email'
        label='Adres e-mail'
        placeholder={'jan@dostawca.pl'}
        variant='outlined'
        type='email'
        value={localEmail}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        required
      />
      <section className={style.pricesWrapper}>
        <div className={style.wrapper}>
          <h2 className={style.title}>Sposób płatności</h2>
          {localPayments &&
            localPayments.map((paymentMethod) => (
              <Checkbox
                key={paymentMethod?.id}
                value={paymentMethod?.selected || paymentMethod?.isDefault}
                content={paymentMethod?.name}
                onChange={() => changeSelectedPayment(paymentMethod)}
                disabled={paymentMethod?.isDefault}
              />
            ))}
        </div>
        <div className={style.wrapper}>
          <h2 className={style.title}>Sposób wysyłki</h2>
          {localShipments &&
            localShipments.map((shipMethod) => (
              <Checkbox
                key={shipMethod?.id}
                value={shipMethod?.selected || shipMethod?.isDefault}
                content={shipMethod?.name}
                onChange={() => changeSelectedShipment(shipMethod)}
                disabled={shipMethod?.isDefault}
              />
            ))}
        </div>
      </section>
      <div className={style.button}>
        <Button
          label={supplier ? 'Zapisz dystrybutora' : 'Dodaj dystrybutora'}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default DistributorForm;
