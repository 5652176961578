import React from 'react';

import { Card, EmptyState } from 'components';

import ServiceThumbnail from '../ServiceThumbnail';
import ThumbnailPlaceholder from '../ThumbnailPlaceholder';

import style from './ServicesTable.module.scss';

const ServicesTable = ({ list = [], isLoading, params }) => {
  if (list.length === 0 && !isLoading) {
    return (
      <Card className={style.wrapper}>
        <EmptyState type={params.keys.search ? 'search' : 'services'} />
      </Card>
    );
  }

  return (
    <section className={style.container}>
      {isLoading ? (
        <>
          <ThumbnailPlaceholder />
          <ThumbnailPlaceholder />
          <ThumbnailPlaceholder />
          <ThumbnailPlaceholder />
          <ThumbnailPlaceholder />
          <ThumbnailPlaceholder />
        </>
      ) : (
        list.map((service) => (
          <ServiceThumbnail
            key={service.id}
            service={service}
          />
        ))
      )}
    </section>
  );
};

export default ServicesTable;
