import { useEffect, useRef, useState } from 'react';
import { Popover } from 'antd';

import { Button, Icon, Loader, Modal, PageHeader, useOutsideClick, useRequestAbortController } from 'components';

import { wpAxiosInstance } from 'src/api/client';
import { compareAppVersions } from 'src/api/utils';
import { WP_CHANGELOGS_API_URL } from 'src/constants/misc';

import styles from './Changelog.module.scss';

const Changelog = ({ localAppVersion, env }) => {
  const containerRef = useRef();
  const [changelogs, setChangelogs] = useState([]);
  const [incomingAppVersion, setIncomingAppVersion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useOutsideClick(containerRef);
  const [changeVersionVisible, setChangeVersionVisible] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();

  useEffect(() => {
    const getData = async () => {
      if (abortController) abortController.abort();
      const signal = setNewController();

      try {
        setLoading(true);
        const { data } = await wpAxiosInstance.get(WP_CHANGELOGS_API_URL, { signal });
        setChangelogs(data);
        setIncomingAppVersion(data[0].acf.avalio_app_version);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    if (!localAppVersion || !incomingAppVersion) return;

    const isRefreshRequired = ['staging', 'development'].includes(env)
      ? false
      : compareAppVersions(localAppVersion, incomingAppVersion);
    setChangeVersionVisible(isRefreshRequired);
  }, [incomingAppVersion]);

  useEffect(() => {
    if (visible && containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [visible, containerRef]);

  const content = (
    <div
      ref={containerRef}
      className={styles.contentContainer}
    >
      <PageHeader
        name={changelogs[0]?.title.rendered}
        metaData={{
          created_at: changelogs[0]?.date,
          author: 'Avalio'
        }}
      />
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{
          __html: `
            ${changelogs[0]?.content.rendered}
            <a
              href='https://support.avalio.io/informacje-o-aktualizacjach-w-aplikacji/'
              target='_blank'
              rel='noreferrer'
              style='display: block; text-align: center;'
            >
              Zobacz wszystkie
            </a>
        `
        }}
      ></div>
    </div>
  );

  if (loading)
    return (
      <div style={{ marginRight: 30 }}>
        <Loader />
      </div>
    );
  if (changelogs.length === 0) return null;
  return (
    <>
      <Popover
        content={content}
        open={visible}
        trigger={'click'}
        placement={'bottomRight'}
      >
        <div className={styles.container}>
          <button onClick={() => setVisible(!visible)}>
            <Icon
              name='changelog'
              size='24'
            />
          </button>
        </div>
      </Popover>
      <Modal
        visible={changeVersionVisible}
        title={'Nowa wersja aplikacji'}
        disableClose
        outerClasses={styles.refreshModal}
      >
        <div className={styles.modalContent}>
          <p>
            Uaktualniliśmy aplikację do najnowszej wersji! Aby sprawdzić nowości sprawdź Changelog (ikona &quot;i&quot; obok
            powiadomień &quot;dzwoneczek&quot;).
          </p>
          <Button
            label={'Przejdź do aplikacji'}
            onClick={() => window.location.reload(true)}
            className={styles.refreshButton}
          />
        </div>
      </Modal>
    </>
  );
};

export default Changelog;
