import React, { useState } from 'react';

import { AsyncSelect, Button, FileDropzone, Input, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WholesalersApi } from 'src/api';

import styles from './AssignWholesalerForm.module.scss';

const AssignWholesalerForm = ({ supplierId, closeModal, refreshCallback }) => {
  const [selectedWholesaler, setSelectedWholesaler] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const validator = useValidator();

  const handleAssignWholesaler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      const { data } = await WholesalersApi.setWholesalerToSupplier({
        supplier_id: supplierId,
        wholesaler_id: selectedWholesaler.id
      });
      setSelectedWholesaler(null);
      notifyCommon([`${data.message}`]);
      if (closeModal) closeModal();
      if (refreshCallback) refreshCallback();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={styles.container}
      onSubmit={handleAssignWholesaler}
    >
      <AsyncSelect
        label={'Wybierz hurtownię'}
        apiCallback={WholesalersApi.getWholesalers}
        onChange={setSelectedWholesaler}
        value={selectedWholesaler}
        placeholder={'Hurtownia'}
        labelKey={'name'}
        valueKey={'id'}
        validator={validator}
        name={'wholesaler-select'}
        id={'wholesaler-select'}
        rule={'required'}
        isClearable
      />
      <Button
        label={'Zatwierdź'}
        type={'submit'}
        isLoading={isLoading}
        className={styles.button}
      />
    </form>
  );
};

export default AssignWholesalerForm;
