import React, { useState } from 'react';

import { Button, Textarea, useValidator } from 'components';

import styles from './RenameProductFormOCR.module.scss';

const RenameProductFormOCR = ({ currentName, handleSave, onClose }) => {
  const [name, setName] = useState(currentName || '');
  const [isLoading, setIsLoading] = useState(false);

  const validator = useValidator();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      await handleSave(name, 'name');
      if (onClose) onClose();
    } finally {
      setIsLoading(true);
    }
  };

  return (
    <form
      className={styles.container}
      onSubmit={handleSubmit}
    >
      <Textarea
        id={'name'}
        name={'name'}
        label={'Nazwa produktu'}
        value={name || ''}
        setValue={(name) => setName(name)}
        validator={validator}
        rule={'required'}
      />
      <Button
        label={'Dodaj'}
        isLoading={isLoading}
        className={styles.button}
      />
    </form>
  );
};

export default RenameProductFormOCR;
