import React, { useState } from 'react';

import { AsyncSelect, Button, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { VariationsApi } from 'src/api';

import styles from './MoveGroupProductForm.module.scss';

const MoveGroupProductForm = ({ productName, productId, groupId, closeModal, refreshCallback }) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const validator = useValidator();

  const moveGroupProductHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      product_id: productId,
      group_id: selectedGroup.id
    };

    try {
      setIsLoading(true);
      const { data } = await VariationsApi.moveGroupProduct(params);
      if (refreshCallback) refreshCallback(true);
      closeModal();
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={styles.container}
      onSubmit={moveGroupProductHandler}
    >
      <p>
        Wybierz grupę, do której chcesz przenieść produkt <strong>{productName}</strong>.
      </p>
      <AsyncSelect
        id={'selected-group'}
        value={selectedGroup}
        onChange={setSelectedGroup}
        apiCallback={VariationsApi.getGroups}
        excludeOptionById={groupId}
        label={'Wybierz grupę'}
        valueKey={'id'}
        labelKey={'name'}
        placeholder={'Wyszukaj..'}
        validator={validator}
        rule={'required'}
        isClearable
      />
      <Button
        label={'Przenieś'}
        isLoading={isLoading}
        className={styles.button}
      />
    </form>
  );
};

export default MoveGroupProductForm;
