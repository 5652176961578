import React, { useEffect, useMemo, useState } from 'react';
import { useAtom } from 'jotai';

import {
  Card,
  EmptyState,
  FilterByCategory,
  FilterByProducer,
  FilterBySupplier,
  LabelWithValue,
  Loader,
  PaginationNav,
  useSearchParam
} from 'components';
import Table from 'components/layout/Table';
import { notifyApiError } from 'components/layout/Toasts';
import Product from 'components/layout/TopProducts/components/Product';

import { StatisticsApi } from 'src/api';
import { ordersAtom } from 'src/features/Analytics';
import ExportData from 'src/features/Analytics/components/ExportData';
import { getOrdersSumForUser } from 'src/features/Analytics/components/OrdersSummary';
import { getFormattedAmount } from 'src/utils/helpers';

import productStyle from './components/Product/Product.module.scss';
import style from './TopProducts.module.scss';

const options = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 50, label: '50' }
];

const TopProducts = ({ selectedCompanyId, filters, params }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(20);
  const [data, setData] = useState();
  const [orders] = useAtom(ordersAtom);

  const sortBy = useSearchParam(params, 'sort_by');
  const producerId = useSearchParam(params, 'producer');
  const categoryId = useSearchParam(params, 'category');
  const supplierId = useSearchParam(params, 'supplier');
  const sortOrder = useSearchParam(params, 'sort_order');

  const someFiltersApplied = !!(producerId || categoryId || supplierId || sortOrder || sortBy);

  const getData = async () => {
    const params = {
      ...(!!filters.selectedCompanies && { company_ids: filters.selectedCompanies.map((company) => company.value) }),
      ...(filters.startDate && filters.endDate && { date_from: filters.startDate, date_to: filters.endDate }),
      ...(!!sortBy && !!sortOrder && { sort_order: sortOrder, sort_by: sortBy }),
      ...(selectedCompanyId && { company_id: selectedCompanyId }),
      ...(!!categoryId && { category_id: categoryId }),
      ...(!!producerId && { producer_id: producerId }),
      ...(!!supplierId && { supplier_id: supplierId }),
      perPage: limit
    };

    try {
      setIsLoading(true);
      const { data } = await StatisticsApi.getTopProducts(params);
      setData(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [producerId, categoryId, supplierId, sortOrder, sortBy, limit, filters]);

  const columns = [
    { name: 'Lp.' },
    { name: 'Produkt' },
    { name: 'Producent' },
    { name: 'Kategoria' },
    { name: 'Łączna wartość', parameterName: 'VALUE' },
    { name: 'Zamówiona ilość', parameterName: 'QUANTITY' }
  ];

  const exportButton = useMemo(() => {
    const params = {
      ...(!!sortBy && !!sortOrder && { sort_order: sortOrder, sort_by: sortBy }),
      ...(!!categoryId && { category_id: producerId }),
      ...(!!producerId && { producer_id: categoryId }),
      ...(!!supplierId && { supplier_id: supplierId }),
      perPage: limit
    };

    return (
      <ExportData
        apiCallback={StatisticsApi.exportTopProducts}
        company_id={selectedCompanyId}
        filters={filters}
        queryParams={params}
      />
    );
  }, [producerId, categoryId, supplierId, sortOrder, sortBy, limit, filters]);

  const summary = useMemo(() => {
    const sum = data?.reduce((acc, product) => acc + product.products_sum, 0);
    const percentage = (sum * 100) / getOrdersSumForUser(orders?.total_sum);
    return { sum, percentage };
  }, [data]);

  return (
    <Card className={style.container}>
      <header className={style.header}>
        <h2 className={style.title}>Najczęściej zamawiane produkty w ujęciu czasowym</h2>
        <div className={style.options}>{exportButton}</div>
      </header>
      <div className={style.row}>
        <PaginationNav
          label={'Liczba produktów'}
          setPerPage={setLimit}
          defaultQty={limit}
          options={options}
          params={params}
          pagesQty={1}
          onlyLimit
        />
        <LabelWithValue
          labelClasses={style.label}
          label={'Łączna wartość produktów w tabeli'}
          value={getFormattedAmount(summary.sum)}
          isLoading={isLoading}
        />
        <LabelWithValue
          labelClasses={style.label}
          label={'Udział produktów w wartości zamówień'}
          value={summary.percentage ? summary.percentage.toFixed(2) + '%' : '- %'}
          isLoading={isLoading}
        />
      </div>
      <div className={style.row}>
        <FilterByCategory
          params={params}
          label={'Kategoria'}
          fullWidth
          showAsTree
          onlyTop
        />
        <FilterBySupplier
          params={params}
          label={'Dostawca'}
          fullWidth
        />
        <FilterByProducer
          params={params}
          label={'Producent'}
          fullWidth
        />
      </div>
      <Table
        params={params}
        columns={columns}
        headerClasses={productStyle.rowGrid}
        className={style.table}
      >
        {isLoading ? (
          <div className={style.loaderWrapper}>
            <Loader />
          </div>
        ) : data?.length ? (
          data?.map((product, index) => (
            <Product
              item={product}
              key={product.id}
              index={index}
            />
          ))
        ) : (
          <EmptyState type={someFiltersApplied ? 'topProductsWithFilters' : 'topProducts'} />
        )}
      </Table>
    </Card>
  );
};

export default TopProducts;
