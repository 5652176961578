import React from 'react';

import { Menu, RelativeTime } from 'components';

import { getFormattedAmount } from 'src/utils/helpers';

import styles from '../../FloorPlan.module.scss';

const WarehouseOverview = () => {
  const noteActions = [
    {
      title: 'Edytuj',
      icon: 'edit',
      action: () => console.log('edit')
    },
    {
      title: 'Usuń',
      icon: 'trash',
      color: 'red',
      action: () => console.log('remove')
    }
  ];

  return (
    <div className={styles.modalContainer}>
      <div className={styles.dataWrapper}>
        <h4 className={styles.dataWrapperHeadline}>Podstawowe informacje</h4>
        <ul className={styles.items}>
          <li>
            <span>Ilość produktów w magazynie:</span>
            <span className={styles.green}>375</span>
          </li>
          <li>
            <span>Produkty poniżej stanu minimalnego:</span>
            <span className={styles.red}>5</span>
          </li>
          <li>
            <span>Produkty z kończącą się datą ważności:</span>
            <span className={styles.red}>8</span>
          </li>
          <li>
            <span>Zamówienia w trakcie realizacji:</span>
            <span>2</span>
          </li>
          <li>
            <span>Data ostatniej dostawy:</span>
            <span>
              <RelativeTime date={'2024-02-22 15:14:47'} />
            </span>
          </li>
          <li>
            <span>Data ostatniego rozchodu:</span>
            <span>
              <RelativeTime date={'2024-02-26 10:00:00'} />
            </span>
          </li>
          <li>
            <span>Wartość magazynu:</span>
            <span className={styles.green}>{getFormattedAmount(230326.2)}</span>
          </li>
        </ul>
      </div>
      <div className={styles.dataWrapper}>
        <h4 className={styles.dataWrapperHeadline}>Notatka</h4>
        <div className={styles.note}>
          <p>
            Ostatnio brakuje rękawiczek od Marrodentu. Trzeba poszukać alternatywy, lub zmienić model. Lekarze sugerują te od
            KolDental. Zwrócić uwagę na nadwyżkę, która zostaje każdego miesiąca i ograniczyć zamówienia.
          </p>
          <Menu
            actions={noteActions}
            className={styles.noteMenu}
          />
        </div>
      </div>
    </div>
  );
};

export default WarehouseOverview;
