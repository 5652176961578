import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Button, Input, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';

import { setNewShipmentMethod } from '../../actions';

import style from '../OrderDetails/OrderDetails.module.scss';

const AddShipment = ({ uid }) => {
  const validator = useValidator();
  const dispatch = useDispatch();

  const [newShipMethod, setNewShipMethod] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const isDisabled = useSelector((state) => state.supplierForm.isDisabled);

  const addShippingMethod = async () => {
    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const queryParams = {
      uid: uid,
      name: newShipMethod
    };

    try {
      setIsLoading(true);
      let { data } = await SupplierApi.addShippingMethod(queryParams);
      dispatch(setNewShipmentMethod(data));
      notifyCommon(['Zmiany zostały zapisane.']);
      setNewShipMethod('');
      setIsVisible(false);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
      validator.purgeFields();
      validator.hideMessages();
    }
  };

  if (isDisabled) {
    return null;
  }

  if (!isVisible) {
    return (
      <a
        onClick={() => setIsVisible(true)}
        className={style.shippingLink}
      >
        Chcesz dodać inny sposób wysyłki?
      </a>
    );
  }

  return (
    <div className={style.addShippingForm}>
      <h3 className={classNames(style.title, style.withoutMargin)}>Dodaj własny sposób wysyłki</h3>
      <p>Będzie on widoczny tylko dla Ciebie i Twoich klientów.</p>
      <Input
        label={'Nazwa'}
        placeholder={'np. Kurier XYZ'}
        value={newShipMethod}
        onChange={(e) => setNewShipMethod(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <Button
        className={style.shippingButton}
        onClick={addShippingMethod}
        isLoading={isLoading}
        disabled={isLoading}
        label='Dodaj wysyłkę'
      />
    </div>
  );
};

export default AddShipment;
