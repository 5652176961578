import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import cn from 'classnames';

import {
  Button,
  ChangeValue,
  EmptyState,
  Icon,
  LabelWithValue,
  Modal,
  PageHeader,
  RelativeTime,
  Table,
  useRequestAbortController
} from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OCRInvoicesApi } from 'src/api';
import { OCRInvoiceStatuses } from 'src/constants/enums';
import { refreshCartOrCatalog } from 'src/features/CatalogAndCartController/actions';
import FilePreview from 'src/features/CatalogAndCartController/components/CatalogManagement/components/InvoiceDetails/components/FilePreview';
import { getFormattedAmount, getOCRInvoiceStatusTag } from 'src/utils/helpers';

import AddProductFormOCR from './components/AddProductFormOCR';
import LoadingPlaceholder from './components/LoadingPlaceholder';
import ProductThumbnail from './components/ProductThumbnail';

import thumbnailStyles from './components/ProductThumbnail/ProductThumbnail.module.scss';
import style from './OffersDetails.module.scss';

export const columns = [
  { name: 'Lp.' },
  { name: 'Nazwa' },
  { name: 'Połączony produkt' },
  { name: 'Cena za szt.' },
  { name: 'Ilość' },
  { name: 'Wartość' }
];

const OffersDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const areDataFetching = useRef(false);
  const { search } = useLocation();
  const { selectedInvoice } = useParams();
  const [invoice, setInvoice] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [isAddProductVisible, setIsAddProductVisible] = useState(false);
  const selectedCompany = useSelector((state) => state.cartAndCatalog.selectedCompany);
  const [abortController, setNewController] = useRequestAbortController();

  const getData = async () => {
    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      const { data } = await OCRInvoicesApi.getInvoiceDetails(selectedInvoice, signal);
      setInvoice(data);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    const getDataHandler = async () => {
      setIsLoading(true);
      await getData();
      setIsLoading(false);
    };

    if (!areDataFetching.current) {
      areDataFetching.current = true;
      getDataHandler();
    }
  }, []);

  const { created_at, invoice_number, status, sale_date, id, wholesaler_name } = invoice?.invoice_details || {};

  const invoiceSum = useMemo(
    () => invoice?.invoice_products.reduce((acc, prod) => acc + prod.price * prod.quantity, 0),
    [invoice]
  );

  const invoiceProducts = invoice?.invoice_products && invoice.invoice_products.length ? invoice?.invoice_products : [];
  const hasProductsWarning = invoice?.invoice_details?.linked_products < invoice?.invoice_details?.products;

  const handleSaveValue = async (value, key) => {
    try {
      await OCRInvoicesApi.editInvoice({ invoice_id: invoice.invoice_details.id, [key]: value });
      await getData();
      notifyCommon(['Dane faktury zostały zapisane']);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const linkProductAuto = async () => {
    try {
      await OCRInvoicesApi.assignProducts(id);
      await getData();
      notifyCommon(['Produkty zostały przypisane']);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const addInvoiceToCatalog = async () => {
    try {
      await OCRInvoicesApi.fillOfferCatalog({ invoice_id: id, company_id: selectedCompany });
      await getData();
      dispatch(refreshCartOrCatalog());
      notifyCommon([`Produkty z faktury "${name}" zostały dodane`]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const saleDateElement = (
    <ChangeValue
      disabled={status === OCRInvoiceStatuses.added}
      apiCallback={(value) => handleSaveValue(value, 'sale_date')}
      initialValue={sale_date}
      displayValueFormatter={(value) => value || 'DD.MM.RRRR'}
      type={'date'}
      label={'Zmień datę sprzedaży'}
      placement={'bottom'}
      valueWrapperClasses={style.dateWrapper}
      icon={
        <Icon
          name={'edit'}
          fill={'#888'}
        />
      }
    />
  );

  const invoiceNumberElement = (
    <ChangeValue
      disabled={status === OCRInvoiceStatuses.added}
      apiCallback={(value) => handleSaveValue(value, 'invoice_number')}
      initialValue={invoice_number}
      type={'text'}
      label={'Zmień numer faktury'}
      placement={'bottomLeft'}
      valueWrapperClasses={style.titleWrapper}
      icon={
        <Icon
          name={'edit'}
          fill={'#888'}
        />
      }
    />
  );

  const actions = [
    {
      title: 'Przypisz produkty ponowie',
      icon: 'repeat',
      action: linkProductAuto
    },
    {
      title: 'Dodaj do katalogu',
      color: 'blue',
      icon: 'add',
      action: addInvoiceToCatalog
    }
  ];

  const productsWithErrorsCount = useMemo(
    () => invoice?.invoice_products.filter((prod) => +prod.quantity === 0 || +prod.price === 0)?.length || 0,
    [invoice]
  );

  return (
    <>
      <Modal
        visible
        onClose={() => history.push('/offer-catalog/offers' + search)}
        title={'Analiza pliku' + (invoice ? `: ${invoice?.invoice_details?.name}` : '')}
      >
        <div className={style.main}>
          <div className={style.container}>
            {isLoading ? (
              <LoadingPlaceholder />
            ) : (
              <>
                <PageHeader
                  text={getOCRInvoiceStatusTag({ status })}
                  name={invoiceNumberElement}
                  className={style.title}
                  actions={actions}
                  textCenter
                >
                  <Button
                    label={'Dodaj produkt'}
                    onClick={() => setIsAddProductVisible(true)}
                  />
                  <Button
                    label={isPreviewVisible ? 'Zamknij podgląd' : 'Podgląd'}
                    onClick={() => setIsPreviewVisible((prev) => !prev)}
                    gray={isPreviewVisible}
                  />
                </PageHeader>
                <header className={style.header}>
                  <LabelWithValue
                    label={'Sprzedawca'}
                    className={style.wholesaler}
                    value={wholesaler_name}
                  />
                  <LabelWithValue
                    label={'Wartość brutto'}
                    className={style.label}
                    value={getFormattedAmount(invoiceSum)}
                    dangerValue={+invoiceSum}
                    dangerWhenFalsy
                  />
                  <LabelWithValue
                    label={'Ilość produktów'}
                    className={style.label}
                    value={
                      <div className={style.wrapper}>
                        {`${invoice?.invoice_details?.linked_products} / ${invoice?.invoice_details?.products}`}
                        <Icon
                          name={hasProductsWarning ? 'warning' : 'checkInCircle'}
                          fill={hasProductsWarning ? '#f1c043' : '#1d7268'}
                        />
                      </div>
                    }
                  />
                  <LabelWithValue
                    label={'Data sprzedaży'}
                    className={style.label}
                    value={saleDateElement}
                    dangerValue={!!sale_date}
                    dangerWhenFalsy
                  />
                  {productsWithErrorsCount > 0 && (
                    <LabelWithValue
                      className={style.label}
                      value={productsWithErrorsCount}
                      label={'Produkty z błędami'}
                      dangerWhenFalsy
                    />
                  )}
                  <LabelWithValue
                    className={style.label}
                    value={created_at && <RelativeTime date={created_at} />}
                    label={'Dodano'}
                  />
                </header>
                <Table
                  columns={invoiceProducts.length ? columns : []}
                  headerClasses={cn(thumbnailStyles.gridRow, style.tableHeader)}
                  className={style.table}
                >
                  {invoiceProducts.length ? (
                    <>
                      {invoice?.invoice_products?.map((prod, index) => (
                        <ProductThumbnail
                          key={prod.id}
                          index={index}
                          product={prod}
                          status={status}
                          refreshCallback={getData}
                        />
                      ))}
                    </>
                  ) : (
                    <EmptyState
                      type={'OCRProducts'}
                      tiny
                    />
                  )}
                </Table>
              </>
            )}
          </div>
          <FilePreview
            invoice={invoice}
            isVisible={isPreviewVisible}
          />
        </div>
      </Modal>
      <Modal
        visible={isAddProductVisible}
        onClose={() => setIsAddProductVisible(false)}
        title={'Dodaj produkt'}
      >
        <AddProductFormOCR
          invoiceId={invoice?.invoice_details?.id}
          refreshCallback={getData}
          onClose={() => setIsAddProductVisible(false)}
        />
      </Modal>
    </>
  );
};

export default OffersDetails;
