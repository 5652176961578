import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';

import PriceThumbnail from '../../../SupplierThumbnail';

const SupplierThumbnail = ({ item, setIsDragged }) => {
  const [collectedProps, drag] = useDrag(
    () => ({
      item: item,
      type: 'supplier',
      collect: (monitor) => {
        return {
          isDragging: !!monitor.isDragging()
        };
      }
    }),
    [item]
  );

  useEffect(() => {
    if (setIsDragged) setIsDragged(collectedProps.isDragging);
  }, [collectedProps.isDragging]);

  return (
    <PriceThumbnail
      ref={drag}
      item={item}
      isDragging={collectedProps.isDragging}
      isEditable
    />
  );
};

export default SupplierThumbnail;
