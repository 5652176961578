import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Card, PageHeader, useIsMobile } from 'components';

import { getValuationStatusTag } from 'src/utils/helpers';

import style from '../../SupplierForm.module.scss';

const FooterHeader = ({ valuationName, sendFormButton, autoSaveButton }) => {
  const isMobile = useIsMobile();
  const status = useSelector((state) => state.supplierForm.status);

  return (
    <Card className={style.footerHeader}>
      <PageHeader
        name={valuationName}
        textCenter={!isMobile}
        className={cn(style.pageHeader, style.footer)}
        text={<span id={'valuation-status'}>{getValuationStatusTag(status)}</span>}
      >
        {autoSaveButton}
        {sendFormButton}
      </PageHeader>
    </Card>
  );
};

export default FooterHeader;
