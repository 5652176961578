import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Checkbox from 'components/layout/Checkbox';

import { togglePaymentMethod } from 'src/features/SupplierForm/actions';

import style from './PaymentSelection.module.scss';

const PaymentSelection = ({ paymentMethod, disabled, showError }) => {
  const [selected, setSelected] = useState(paymentMethod.selected);
  const dispatch = useDispatch();

  useEffect(() => {
    if (paymentMethod.selected || paymentMethod.isDefault) {
      setSelected(true);
    }
  }, [paymentMethod]);

  const changeSelected = (e) => {
    setSelected(e.target.checked);
    dispatch(togglePaymentMethod(paymentMethod.id));
  };

  return (
    <div className={style.container}>
      <Checkbox
        key={paymentMethod?.id}
        value={selected}
        content={paymentMethod?.name}
        onChange={changeSelected}
        disabled={paymentMethod?.isDefault || disabled}
        showError={showError}
      />
    </div>
  );
};

export default PaymentSelection;
