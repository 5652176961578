import React, { useMemo, useState } from 'react';

import { LoadingIndicator, SaveButton, Select, useAutoSave, useDepartments, useIsMobile, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';

import useValidator from '../../hooks/useValidator';
import QtySelector from '../QtySelector';

import style from './ProductDepartmentThumbnail.module.scss';

const departmentToOption = (department) => {
  const { id, name } = department;
  return { label: name, value: id };
};

const ProductDepartmentThumbnail = ({ uid, product, department, onDepartmentChange }) => {
  const { id, products_lists_departments_id, name, quantity } = department;
  const { unit_name } = product;

  const validator = useValidator();
  const isMobile = useIsMobile(767);
  const myDepartments = useDepartments();
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaveError, setIsSaveError] = useState(false);
  const [isQtyLoading, setIsQtyLoading] = useState(false);
  const [isSaveQtyError, setIsSaveQtyError] = useState(false);
  const [departmentValue, setDepartmentValue] = useState(departmentToOption(department) || null);
  const [qty, setQty] = useState(quantity);

  const options = useMemo(() => {
    const unused = myDepartments.departments.filter((item) => !product.departments.map((i) => i.id).includes(item.id));
    const asOptions = unused.map((item) => departmentToOption(item));
    return asOptions;
  }, [myDepartments, product.departments]);

  const deleteProductDepartmentHandler = async () => {
    try {
      await ListsApi.deleteProductDepartment(uid, products_lists_departments_id);
      notifyCommon(['Jednostka organizacyjna została usunięta dla tego produktu.']);
      if (onDepartmentChange) onDepartmentChange();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const changeDepartmentHandler = async () => {
    try {
      setIsSaveError(false);
      setIsLoading(true);

      const data = {
        list_uid: uid,
        product_id: product.product_id,
        department_id: departmentValue.value,
        quantity,
        products_lists_departments_id
      };

      await ListsApi.setProductDepartment(data);
      if (onDepartmentChange) onDepartmentChange();
    } catch (err) {
      notifyApiError(err);
      setIsSaveError(true);
      setDepartmentValue(departmentToOption(department));
    } finally {
      setIsLoading(false);
    }
  };

  const saveQuantityHandler = async () => {
    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const data = {
      list_uid: uid,
      product_id: product.product_id,
      department_id: id,
      products_lists_departments_id,
      quantity: qty
    };

    try {
      setIsSaveQtyError(false);
      setIsQtyLoading(true);
      await ListsApi.setProductDepartment(data);
      if (onDepartmentChange) onDepartmentChange();
    } catch (err) {
      notifyApiError(err);
      setIsSaveQtyError(true);
      setQty(quantity);
    } finally {
      setIsQtyLoading(false);
    }
  };

  useAutoSave({
    first: +department.id,
    second: +departmentValue.value,
    dependency: [departmentValue],
    saveHandler: changeDepartmentHandler,
    timeoutValue: 800
  });

  useAutoSave({
    first: +quantity,
    second: +qty,
    dependency: [qty],
    saveHandler: saveQuantityHandler,
    timeoutValue: 800
  });

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <Select
          options={options}
          value={departmentValue}
          onChange={setDepartmentValue}
          placeholder={'Jednostka organizacyjna'}
          isSearchable={false}
        />
        <LoadingIndicator
          isLoading={isLoading}
          hasError={isSaveError}
          aboveInput
        />
      </div>
      <div className={style.wrapper}></div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Ilość: </p>}
        <div className={style.quantityInput}>
          <QtySelector
            state={qty}
            setState={setQty}
            suffix={unit_name}
            width={110}
            id={`offerDepartmentQty-${parent.id}`}
            rule={'required|only_positive'}
            validator={validator}
          />
          <LoadingIndicator
            isLoading={isQtyLoading}
            hasError={isSaveQtyError}
            aboveInput
          />
        </div>
      </div>
      <SaveButton
        type='delete'
        onClick={() =>
          handleOpenModalConfirm({
            message: `Czy na pewno chcesz usunąć ${name} z podziału produktu ${product.name}?`,
            handleConfirm: deleteProductDepartmentHandler
          })
        }
      />
      {renderedModalConfirm}
    </div>
  );
};

export default ProductDepartmentThumbnail;
