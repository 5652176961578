import React, { useEffect, useMemo, useState } from 'react';
import { useAtom } from 'jotai';

import { Card, Chart, Select, SwitchButton, useIsAdmin } from 'components';
import { getCustomTooltipWithSeriesSum } from 'components/layout/Chart';
import { notifyApiError } from 'components/layout/Toasts';

import { StatisticsApi } from 'src/api';
import { ordersAtom } from 'src/features/Analytics';
import { toDateNumber } from 'src/utils/dateTime';
import { getFormattedAmount, translateMonthsWithInfo } from 'src/utils/helpers';

import ExportData from '../ExportData';

import style from '../Charts.module.scss';

const topChartOptions = [
  { label: 'Wartość zamówień', value: 'total_sum' },
  { label: 'Wartość produktów', value: 'products_price_sum' },
  { label: 'Liczba zamówień', value: 'orders_count' },
  { label: 'Liczba koszyków', value: 'buckets_count' },
  { label: 'Liczba zamówionych produktów', value: 'quantity_sum' },
  { label: 'Liczba zamówionych SKU', value: 'unique_products_count' }
];

const bottomChartOptions = [
  { label: 'Średnia wartość zamówienia', value: 'avg_ord_total_per_orders' },
  { label: 'Średnia wartość koszyka', value: 'avg_ord_total_per_baskets' },
  { label: 'Średnia liczba produktów w zamówieniu', value: 'prod_count_per_orders' },
  { label: 'Średnia liczba produktów w koszyku', value: 'prod_count_per_baskets' },
  { label: 'Średnia liczba SKU w zamówieniu', value: 'uniq_prod_count_per_orders' },
  { label: 'Średnia liczba SKU w koszyku', value: 'uniq_prod_count_per_baskets' },
  { label: 'Średnia liczba koszyków w zamówieniu', value: 'avg_baskets_per_order' },
  { label: 'Udział kosztów wysyłki w zamówieniach', value: 'perc_shipings_per_ord_total' }
];

const ExpensesMonthly = ({ selectedCompanyId, filters }) => {
  const isAdmin = useIsAdmin();
  const [chartData, setChartData] = useAtom(ordersAtom);
  const [isStacked, setIsStacked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const prefix = isAdmin ? 'series_' : '';

  const [topChartSelectedDataType, setTopChartSelectedDataType] = useState(topChartOptions[0]);
  const [bottomChartSelectedDataType, setBottomChartSelectedDataType] = useState(bottomChartOptions[0]);

  const getData = async () => {
    if (isAdmin && !selectedCompanyId) return;

    const queryData = {
      ...(isAdmin
        ? {
            company_ids:
              typeof selectedCompanyId === 'string' ? [Number(selectedCompanyId)] : selectedCompanyId.map((id) => Number(id))
          }
        : { company_ids: filters.selectedCompanies.map((company) => company.value) }),
      ...(filters.startDate && filters.endDate && { date_from: filters.startDate, date_to: filters.endDate })
    };

    const ApiCallback = isAdmin ? StatisticsApi.getExpensesMonthlyByCompanies : StatisticsApi.getExpensesMonthly;

    try {
      setIsLoading(true);
      const { data } = await ApiCallback(queryData);
      setChartData(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [filters, selectedCompanyId, isStacked]);

  const topChartConfig = useMemo(() => {
    const data = chartData ? chartData[prefix + topChartSelectedDataType.value] : [];
    let dataLabel = topChartSelectedDataType.label;
    let formatter;

    switch (topChartSelectedDataType.value) {
      case 'total_sum':
        formatter = (value) => getFormattedAmount(value);
        break;
      case 'products_price_sum':
        formatter = (value) => getFormattedAmount(value);
        break;
      default:
        formatter = (value) => Math.floor(value);
        break;
    }

    const getSeriesSumRenderer = ({ series, dataPointIndex, w }) =>
      getCustomTooltipWithSeriesSum(series, dataPointIndex, w, formatter);

    return { data, dataLabel, formatter, getSeriesSumRenderer };
  }, [chartData, topChartSelectedDataType, selectedCompanyId, isStacked]);

  const bottomChartConfig = useMemo(() => {
    const data = chartData ? chartData[prefix + bottomChartSelectedDataType.value] : [];
    let dataLabel = bottomChartSelectedDataType.label;
    let formatter;

    switch (bottomChartSelectedDataType.value) {
      case 'avg_ord_total_per_orders':
      case 'avg_ord_total_per_baskets': {
        formatter = (value) => getFormattedAmount(value);
        break;
      }
      case 'perc_shipings_per_ord_total': {
        formatter = (value) => +value.toFixed(2) + '%';
        break;
      }
      default: {
        formatter = (value) => Math.floor(value);
        break;
      }
    }

    const getSeriesSumRenderer = ({ series, dataPointIndex, w }) =>
      getCustomTooltipWithSeriesSum(series, dataPointIndex, w, formatter);

    return { data, dataLabel, formatter, getSeriesSumRenderer };
  }, [chartData, bottomChartSelectedDataType, selectedCompanyId, isStacked]);

  return (
    <Card className={style.container}>
      <header className={style.header}>
        <h2 className={style.title}>Zamówienia</h2>
        <div className={style.options}>
          {isAdmin && (
            <SwitchButton
              setValue={setIsStacked}
              value={isStacked}
              label={'Sumuj dane'}
            />
          )}
          <ExportData
            apiCallback={StatisticsApi.exportExpensesMonthly}
            company_id={selectedCompanyId}
            filters={filters}
          />
        </div>
      </header>
      <div className={style.chartsWrapper}>
        <Chart
          fullWidth
          height={300}
          title={'Dane'}
          isLoading={isLoading}
          chartTypes={['area', 'bar']}
          categories={chartData?.months}
          haveData={chartData?.have_data}
          stacked={isAdmin ? isStacked : false}
          configRefreshVariable={topChartConfig}
          yAxisFormatter={topChartConfig.formatter}
          tooltipShared={isAdmin ? isStacked : false}
          dataLabelsFormatter={topChartConfig.formatter}
          categoriesLabel={topChartSelectedDataType.label}
          data={isAdmin ? undefined : topChartConfig.data}
          customSeries={isAdmin ? topChartConfig.data : undefined}
          categoriesFormatter={(value) => translateMonthsWithInfo(value, filters)}
          customTooltipRender={isAdmin ? topChartConfig.getSeriesSumRenderer : undefined}
        >
          <Select
            width={200}
            isSearchable={false}
            className={style.select}
            options={topChartOptions}
            wrapperStyle={style.select}
            value={topChartSelectedDataType}
            onChange={setTopChartSelectedDataType}
          />
        </Chart>
        <Chart
          fullWidth
          height={300}
          title={'Wskaźniki'}
          isLoading={isLoading}
          chartTypes={['area', 'bar']}
          categories={chartData?.months}
          haveData={chartData?.have_data}
          stacked={isAdmin ? isStacked : false}
          configRefreshVariable={bottomChartConfig}
          tooltipShared={isAdmin ? isStacked : false}
          yAxisFormatter={bottomChartConfig.formatter}
          dataLabelsFormatter={bottomChartConfig.formatter}
          categoriesLabel={bottomChartSelectedDataType.label}
          data={isAdmin ? undefined : bottomChartConfig.data}
          customSeries={isAdmin ? bottomChartConfig.data : undefined}
          categoriesFormatter={(value) => translateMonthsWithInfo(value, filters)}
          customTooltipRender={isAdmin ? bottomChartConfig.getSeriesSumRenderer : undefined}
        >
          <Select
            width={200}
            isSearchable={false}
            className={style.select}
            wrapperStyle={style.select}
            options={bottomChartOptions}
            value={bottomChartSelectedDataType}
            onChange={setBottomChartSelectedDataType}
          />
        </Chart>
      </div>
      <p>
        * - Dane na dzień {toDateNumber(filters.endDate)}r. Dane agregowane są na podstawie zamówień o statusach: Oczekuje na
        potwierdzenie, W realizacji, Wysłano, Zrealizowano.
      </p>
    </Card>
  );
};

export default ExpensesMonthly;
