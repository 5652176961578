import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, useStateTimeout } from 'components';

import { addComment } from 'src/features/SupplierForm/actions';

import style from './OrderComments.module.scss';
import shared from 'styles/Shared.module.scss';

const OrderComments = () => {
  const dispatch = useDispatch();

  const comment = useSelector((state) => state.supplierForm.form.comment);
  const disabled = useSelector((state) => state.supplierForm.isDisabled);
  const [localComment, setLocalComment] = useState(comment);

  useStateTimeout({
    localState: localComment,
    setLocalState: setLocalComment,
    state: comment,
    setState: (com) => dispatch(addComment(com))
  });

  if (disabled && localComment === '') {
    return null;
  }

  return (
    <Card
      className={shared.defaultWrapper}
      id={'order-comment'}
    >
      <h2 className={style.title}>Komentarz dla klienta</h2>
      <textarea
        disabled={disabled}
        className={style.textArea}
        placeholder={'Napisz wiadomość...'}
        value={localComment}
        onChange={(e) => setLocalComment(e.target.value)}
      />
    </Card>
  );
};

export default OrderComments;
