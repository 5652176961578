import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, QtySelector, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';
import { refreshCartOrCatalog } from 'src/features/CatalogAndCartController/actions';

import styles from './ChangeOfferPriceForm.module.scss';

const ChangeOfferPriceForm = ({ offerId, currentPrice, onClose }) => {
  const dispatch = useDispatch();

  const [price, setPrice] = useState(currentPrice);
  const [isLoading, setIsLoading] = useState(false);

  const validator = useValidator();

  const changePriceHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      price_offer_id: offerId,
      price: Number(price)
    };

    try {
      setIsLoading(true);
      const { data } = await OfferCatalogApi.editPriceOffer(params);
      notifyCommon([data.message]);
      if (onClose) onClose();
      dispatch(refreshCartOrCatalog());
    } catch (err) {
      notifyApiError([err.message]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={changePriceHandler}
      className={styles.container}
    >
      <QtySelector
        id='price'
        name='price'
        label='Cena'
        state={price}
        setState={(price) => setPrice(price)}
        width={'100%'}
        suffix={'zł'}
        priceInput
        hideArrows
        validator={validator}
        rule={'required|only_positive'}
      />
      <Button
        label={'Zapisz'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default ChangeOfferPriceForm;
