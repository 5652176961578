import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { Tag } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import { getFormattedAmount } from 'src/utils/helpers';

import style from './ValuationProduct.module.scss';

const ValuationProduct = ({ product, className }) => {
  const { name, producer, unit_name, qty, thumbnail, image, id } = product || {};

  const savings = useSelector((state) => state.valuationView.savings);
  const { products } = savings;

  const productSaving = useMemo(
    () => products.find((prod) => prod.product_id === product.pricing_prod_id)?.saved_money,
    [savings]
  );

  return (
    <div className={classnames(style.container, className)}>
      <div className={style.wrapper}>
        <ProductThumbnailImage
          thumbnail={thumbnail}
          image={image}
          title={name}
          id={id}
        />
        <p
          className={style.text}
          title={name}
        >
          {name}
        </p>
      </div>
      <div className={style.row}>
        <span
          className={style.details}
          title={`Ilość: ${qty} ${unit_name || ''}`}
        >
          <p>Ilość:</p>
          {qty}&nbsp;{unit_name}
        </span>
        {!!producer && (
          <>
            <span>|</span>
            <span
              className={style.producer}
              title={`Producent: ${producer}`}
            >
              <span>Producent:</span>
              {producer}
            </span>
          </>
        )}
        {!!+productSaving && (
          <div className={style.savings}>
            <Tag
              value={`Oszczędność: ${getFormattedAmount(productSaving)}`}
              color={'yellow'}
              className={style.tag}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ValuationProduct;
