import React from 'react';
import { Link } from 'react-router-dom';

import { Button, usePermissions } from 'components';

import style from './AccessTab.module.scss';

const AccessTab = ({ title, link, icon, requiredPermissions = [], buttonText }) => {
  const [canView] = usePermissions(requiredPermissions);

  if (!canView) {
    return null;
  }

  return (
    <Link to={link}>
      <div className={style.container}>
        <span className={style.icon}>
          {icon}
          <p>{title}</p>
        </span>
        <Button
          label={buttonText || 'Przejdź'}
          className={style.button}
        />
      </div>
    </Link>
  );
};

export default AccessTab;
