import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { toDateNumber } from 'src/utils/dateTime';

import { getColumnQty } from '../ProductHistory';

import style from '../ProductHistory/ProductHistory.module.scss';

const ColumnHeaders = ({ product = {}, selectedType }) => {
  const { logs = [] } = product;

  const getRangeLabel = (index, from, to) => {
    switch (selectedType?.value) {
      case 'week':
        return (
          <>
            <p>Tydzień {index + 1}</p>
            <p>
              {toDateNumber(from)} - {toDateNumber(to)}
            </p>
          </>
        );
      case 'day':
        return (
          <>
            <p>Dzień {index + 1}</p>
            <p>{toDateNumber(from)}</p>
          </>
        );
      case 'month':
        return (
          <>
            <p>{moment(from).format('MMMM')}</p>
            <p>
              {toDateNumber(from)} - {toDateNumber(to)}
            </p>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div
        className={style.container}
        style={{ width: getColumnQty(logs) }}
      >
        <div className={classNames(style.column, style.productHead, style.headEmpty)}>
          <div className={classNames(style.wrapper, style.name)} />
          <div className={style.wrapper} />
          <div className={style.wrapper} />
        </div>
        <div className={style.productBody}>
          {logs.map((log, index) => (
            <div
              key={`${product.id}-${index}`}
              className={style.column}
            >
              <div className={classNames(style.wrapper, style.columnHead)}>{getRangeLabel(index, log.from, log.to)}</div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={style.container}
        style={{ width: getColumnQty(logs) }}
      >
        <div className={classNames(style.column, style.productHead)}>
          <div className={classNames(style.wrapper, style.name)}>
            <p>Nazwa</p>
          </div>
          <div className={style.wrapper}>
            <p>Aktualna Ilość</p>
          </div>
          <div className={style.wrapper}>
            <p>Wartość</p>
          </div>
        </div>
        {logs.map((log, index) => (
          <div
            key={`${product.id}-${index}`}
            className={style.column}
          >
            <div className={classNames(style.wrapper, style.increase)}>
              <p>Dostawa</p>
            </div>
            <div className={classNames(style.wrapper, style.decrease)}>
              <p>Zużycie</p>
            </div>
            <div className={style.wrapper}>
              <p>Stan</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ColumnHeaders;
