import React from 'react';
import classNames from 'classnames';

import { availabilities } from 'src/constants/enums';
import { getProductAvailability } from 'src/utils/helpers';

import style from './ProductStatus.module.scss';

const ProductStatus = ({ availability, avData, quantity, isReplacement = false }) => {
  const getProductState = () => {
    if (quantity === 0) {
      return 'Niedostępny';
    } else if (quantity > 5) {
      return 'Dostępny';
    } else {
      return 'Braki';
    }
  };

  if (quantity || typeof quantity === 'number') {
    return (
      <div className={style.container}>
        <span
          className={classNames(style.dot, {
            [style.yellow]: quantity > 0 && quantity <= 5,
            [style.green]: quantity > 5,
            [style.red]: quantity === 0
          })}
        />
        <p>{getProductState()}</p>
      </div>
    );
  }

  return (
    <div className={style.container}>
      <span
        className={classNames(style.dot, {
          [style.yellow]: availability === availabilities.availableSince,
          [style.green]: availability === availabilities.available,
          [style.red]: availability === availabilities.notAvailable
        })}
      />
      <p>{getProductAvailability(availability, avData, isReplacement)}</p>
    </div>
  );
};

export default ProductStatus;
