import React, { useState } from 'react';
import { useAtom } from 'jotai/index';

import { AsyncSelect, Button, QtySelector, useIsAdmin, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ProductsApi, WarehouseApi } from 'src/api';
import { selectedCompanyAtom } from 'src/features/Warehouse';

import style from './WarehouseAddProduct.module.scss';

const WarehouseAddProduct = ({ closeModal, refreshData, whProductId }) => {
  const isAdmin = useIsAdmin();
  const validator = useValidator();

  const [selectedCompany] = useAtom(selectedCompanyAtom);

  const [isLoading, setIsLoading] = useState(false);
  const [outOfStockQuantity, setOutOfStockQuantity] = useState();
  const [selectedProduct, setSelectedProduct] = useState(null);

  const addToWarehouse = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      out_of_stock_quantity: +outOfStockQuantity,
      ...(isAdmin && { company_id: selectedCompany }),
      ...(!!whProductId && { wh_product_id: whProductId }),
      ...(!!selectedProduct.value && { product_id: selectedProduct.value })
    };

    try {
      setIsLoading(true);
      await WarehouseApi.addOrUpdateProduct(params);
      if (refreshData) refreshData();
      if (closeModal) closeModal();
      notifyCommon([`Produkt "${selectedProduct.name}" został dodany do magazynu.`]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={(e) => addToWarehouse(e)}
      className={style.container}
    >
      <div className={style.row}>
        <AsyncSelect
          apiCallback={ProductsApi.getProducts}
          onChange={setSelectedProduct}
          value={selectedProduct}
          placeholder={'Produkt'}
          id={'select-product'}
          validator={validator}
          rule={'required'}
          labelKey={'name'}
          label={'Produkt'}
          valueKey={'id'}
          productSearch
          isClearable
        />
        <QtySelector
          label={'Stan minimalny'}
          setState={setOutOfStockQuantity}
          wrapperStyle={style.qtySelector}
          state={outOfStockQuantity}
          hideValueWhenZero
          id={'quantity'}
        />
      </div>
      <Button
        className={style.button}
        isLoading={isLoading}
        label={'Zapisz'}
      />
    </form>
  );
};

export default WarehouseAddProduct;
