import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, FileDropzone, Input, Select, SwitchButton, Textarea, useValidator } from 'components';
import File from 'components/layout/FileUploader/components/File';
import InputWrapper from 'components/layout/InputWrapper';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ExpensesApi } from 'src/api';
import { expensesBillingPeriods, expensesPaymentMethods } from 'src/constants/enums';

import styles from './AddCategoryForm.module.scss';

const defaultCategory = {
  data: null,
  billing_period: '',
  payment_method: '',
  comment: '',
  files: [],
  for_negotiation: 0
};

const AddCategoryForm = ({ categoryData, closeModal }) => {
  const selectedCompanyId = useSelector((state) => state.auth.selectedCompanyId);

  const [category, setCategory] = useState(categoryData || defaultCategory);
  const [categoriesList, setCategoriesList] = useState([]);
  const [replaceFiles, setReplaceFiles] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validator = useValidator();

  const expensesOptionsFromEnums = (enums) => {
    const array = [];
    Object.entries(enums).forEach((entry) => {
      const [key, value] = entry;
      array.push({ value: key, label: value });
    });
    return array;
  };

  const onChangeHandler = (name, data) => {
    setCategory((prev) => ({
      ...prev,
      [name]: data
    }));
  };

  const editExpenseHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      id: category.id,
      expense_category_id: category.data.value,
      billing_period: category.billing_period.value,
      payment_method: category.payment_method.value,
      for_negotiation: category.for_negotiation,
      company_id: selectedCompanyId,
      comment: category.comment
    };

    try {
      setIsLoading(true);
      if (replaceFiles) {
        await ExpensesApi.deleteExpenseFile({ id: category.existing_files[0].id });
      }
      if (category.files.length > 0) {
        await ExpensesApi.addExpenseFiles({
          expense_id: category.id,
          files: category.files
        });
      }
      const { data } = await ExpensesApi.editUserExpense(params);
      if (closeModal) closeModal();
      notifyCommon([`${data.message}`]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const addExpenseHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      expense_category_id: category.data.value,
      billing_period: category.billing_period.value,
      payment_method: category.payment_method.value,
      for_negotiation: category.for_negotiation,
      company_id: selectedCompanyId,
      ...(category.files.length > 0 && { files: category.files }),
      ...(category.comment && { comment: category.comment })
    };

    try {
      setIsLoading(true);
      const { data } = await ExpensesApi.addUserExpense(params);
      if (closeModal) closeModal();
      setCategory(defaultCategory);
      notifyCommon([`${data.message}`]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getData = async () => {
    try {
      const { data } = await ExpensesApi.getCategories();
      const categories = data.map((item) => {
        return { value: item.id, label: item.category_name };
      });
      setCategoriesList(categories);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <form
      className={styles.container}
      onSubmit={categoryData ? editExpenseHandler : addExpenseHandler}
    >
      <Select
        options={categoriesList}
        value={category.data}
        onChange={(category) => onChangeHandler('data', category)}
        label={'Kategoria'}
        placeholder={'Wybierz'}
        isSearchable={false}
        validator={validator}
        rule={'required'}
        id={'data'}
        name={'data'}
      />
      <Select
        options={expensesOptionsFromEnums(expensesBillingPeriods)}
        value={category.billing_period}
        onChange={(billing_period) => onChangeHandler('billing_period', billing_period)}
        label={'Okres rozliczeniowy'}
        placeholder={'Wybierz'}
        isSearchable={false}
        validator={validator}
        rule={'required'}
        id={'billing_period'}
        name={'billing_period'}
      />
      <Select
        options={expensesOptionsFromEnums(expensesPaymentMethods)}
        value={category.payment_method}
        onChange={(payment_method) => onChangeHandler('payment_method', payment_method)}
        label={'Metoda płatności'}
        placeholder={'Wybierz'}
        isSearchable={false}
        validator={validator}
        rule={'required'}
        id={'payment_method'}
        name={'payment_method'}
      />
      {!replaceFiles && categoryData?.existing_files.length > 0 ? (
        <InputWrapper label={'Plik (umowa, kontrakt)'}>
          <div className={styles.fileCard}>
            <File
              value={{ name: categoryData?.existing_files[0].file_name }}
              handleClearFile={() => setReplaceFiles(true)}
            >
              <a
                onClick={() => setReplaceFiles(true)}
                className={styles.a}
              >
                Zmień plik
              </a>
            </File>
          </div>
        </InputWrapper>
      ) : (
        <FileDropzone
          label={'Plik (umowa, kontrakt)'}
          onChange={(file) => onChangeHandler('files', file ? [file] : [])}
          value={category.files[0] || null}
          id={'file'}
          name={'file'}
        />
      )}
      <Textarea
        id={'comment'}
        name={'comment'}
        label={'Komentarz'}
        value={category.comment || ''}
        setValue={(comment) => onChangeHandler('comment', comment)}
      />
      <div className={styles.switchContainer}>
        <SwitchButton
          label={'Do negocjacji'}
          value={category.for_negotiation !== 0}
          setValue={(for_negotiation) => onChangeHandler('for_negotiation', for_negotiation === true ? 1 : 0)}
        />
      </div>
      <Button
        label={categoryData ? 'Zapisz' : 'Dodaj'}
        isLoading={isLoading}
        className={styles.button}
      />
    </form>
  );
};

export default AddCategoryForm;
