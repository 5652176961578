import React from 'react';
import { useDispatch } from 'react-redux';

import { Tag } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { CompanyApi } from 'src/api';
import { getRandomSupplierColor, getSupplierBackgroundColor } from 'src/utils/helpers';

import { refreshProducts } from '../../actions';

import style from './SuggestedSuppliers.module.scss';

const SuggestedSuppliers = ({ suppliers = [], productId, closeModal }) => {
  const dispatch = useDispatch();

  const addSupplierToProductsHandler = async (id) => {
    const data = {
      product_ids: [productId],
      supplier_id: id
    };

    try {
      await CompanyApi.addSupplierToProduct(data);
      dispatch(refreshProducts());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    }
  };

  return (
    <div className={style.container}>
      <p>Kliknij, aby dodać dostawcę do produktu</p>
      <div className={style.wrapper}>
        {suppliers.map((sup) => (
          <Tag
            key={sup.id}
            value={sup.name}
            className={style.tag}
            style={getSupplierBackgroundColor(sup, getRandomSupplierColor(sup.id))}
            onClick={() => addSupplierToProductsHandler(sup.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default SuggestedSuppliers;
