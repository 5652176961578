import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, useAuthUser, useIsMobile } from 'components';
import { closeMobileSidebar } from 'components/layout/Sidebar/actions';

import { userRoles } from 'src/constants/enums';

import { changeCurrentCompany } from '../../../features/onboarding/actions';

import style from './CompanySelect.module.scss';

const CompanySelect = () => {
  const store = useSelector((state) => state.auth);
  const { formattedCompanies, selectedCompanyId } = store;
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const user = useAuthUser();

  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    if (selectedCompany && selectedCompany.value !== selectedCompanyId) {
      setSelectedCompany(formattedCompanies.find((company) => company.value === selectedCompanyId));
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    if (selectedCompanyId) {
      setSelectedCompany(formattedCompanies?.find((company) => company.value === selectedCompanyId));
    }
  }, [selectedCompanyId, formattedCompanies]);

  const handleOnChange = (data) => {
    if (data?.value) {
      dispatch(changeCurrentCompany(data.value));
      setSelectedCompany(data);
      if (isMobile) {
        dispatch(closeMobileSidebar());
      }
    }
  };

  if (!formattedCompanies || user.role !== userRoles.user) {
    return null;
  }

  return (
    <div className={style.container}>
      <Select
        options={formattedCompanies}
        value={selectedCompany}
        onChange={handleOnChange}
        isClearable={false}
        isDisabled={formattedCompanies.length < 2}
        placeholder={'Wybierz oddział...'}
        hideArrow
        hiddenSelect
      />
    </div>
  );
};

export default CompanySelect;
