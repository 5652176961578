import React from 'react';
import { Steps } from 'antd';
import cn from 'classnames';

import style from './ProcessStepper.module.scss';

const ProcessStepper = ({ steps = [], current = 0, smaller, className, inline, responsive = true, ...rest }) => {
  if (steps.length === 0) {
    return null;
  }

  const containerClasses = cn(
    style.container,
    {
      inlineSteps: inline,
      smallerSteps: smaller,
      [style.inlineSteps]: inline || smaller
    },
    className
  );

  return (
    <div className={containerClasses}>
      <Steps
        current={current}
        items={steps}
        responsive={responsive}
        {...rest}
      />
    </div>
  );
};

export default ProcessStepper;
