import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { notifyApiError } from 'components/layout/Toasts';

import { UserApi } from 'src/api';
import { setNotificationAsRead } from 'src/features/NotificationsStore/actions';

const useReadNotification = (notification) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isAdminView = useSelector((state) => state.auth.isAdminView);

  const readNotificationHandler = async () => {
    if (notification && !isAdminView) {
      try {
        setIsLoading(true);
        await UserApi.setNotificationAsRead(notification.id);
        dispatch(setNotificationAsRead(notification.id));
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return [readNotificationHandler, isLoading];
};

export default useReadNotification;
