import React, { forwardRef } from 'react';
import classNames from 'classnames';

import { Loader } from 'components';

import style from './SelectedSupplierWrapper.module.scss';

const SelectedSupplierWrapper = forwardRef((props, ref) => {
  const { isDragged, isOver, children, isLoading, smaller, withOptions, onClickEmpty, disabled } = props;

  const wrapperClasses = classNames(style.wrapper, {
    [style.smaller]: smaller,
    [style.empty]: !children && !isLoading,
    [style.emptyWithOptions]: !children && withOptions,
    [style.withBorder]: isDragged,
    [style.withBlueBorder]: isOver,
    [style.disabled]: disabled
  });

  return (
    <div
      className={style.container}
      onClick={withOptions && !children ? onClickEmpty : undefined}
      ref={ref}
    >
      <div className={wrapperClasses}>{isLoading ? <Loader /> : children}</div>
    </div>
  );
});

export default SelectedSupplierWrapper;
