import React from 'react';

import OrderHeader from 'components/layout/OrderHeader';
import TimelineHistory from 'components/layout/TimelineHistory';

import { getApprovalStatusTag, getFormattedAmount } from 'src/utils/helpers';

import style from '../../OrderCart.module.scss';

const SupplierDetails = ({ supplier, products, totalSum }) => {
  const getProductName = (id) => products.find((prod) => prod.id === id)?.name || '';

  return (
    <div className={style.supplierDetails}>
      <OrderHeader
        supplier={supplier}
        extraData={['supplier@email.com', getFormattedAmount(totalSum)]}
        withoutBorder
      >
        {getApprovalStatusTag({ status: supplier.status })}
      </OrderHeader>
      <TimelineHistory
        history={supplier?.actions}
        getProductName={getProductName}
        title={`Historia zamówienia (${supplier.name})`}
        buttonLabel={'Historia zamówienia'}
      />
    </div>
  );
};

export default SupplierDetails;
