import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { InfoWidget, QtySelector, SaveButton } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';

import { refreshCollection } from '../../actions';

import style from './PriceInput.module.scss';

const PriceInput = ({ product }) => {
  const dispatch = useDispatch();
  const { uid } = useParams();

  const [newPrice, setNewPrice] = useState(product?.price || 0);
  const [isButtonHidden, setIsButtonHidden] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const isDataLoading = useSelector((state) => state.collectionView.isLoading);

  useEffect(() => {
    setIsButtonHidden(Number(product.price ?? 0) === +newPrice);
  }, [product, newPrice]);

  if (!product) {
    return null;
  }

  const handleSavePrice = async () => {
    const data = {
      uid,
      product_id: product.id,
      isActive: product.isActive,
      price: +newPrice
    };

    try {
      setIsLoading(true);
      await SupplierApi.changePrice(data);
      dispatch(refreshCollection());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <QtySelector
        id={`${product.id}-price`}
        state={newPrice}
        setState={setNewPrice}
        suffix={'zł'}
        disabled={isLoading || isDataLoading || !!product?.is_fixed}
        wrapperStyle={style.price}
        priceInput
        hideArrows
        hideValueWhenZero
      />
      {!!product?.is_fixed && (
        <InfoWidget>
          <p>Cena została zablokowana. Aby ją zmienić skontaktuj się z nami.</p>
        </InfoWidget>
      )}
      {!isButtonHidden && (
        <SaveButton
          onClick={handleSavePrice}
          isLoading={isLoading || isDataLoading}
        />
      )}
    </div>
  );
};

export default PriceInput;
