import React from 'react';

import { ProfileSection as Section, UserData } from 'components';

import ChangePassword from './components/ChangePassword';
import Notifications from './components/Notifications';
import UserDataForm from './components/UserDataForm';

import style from './ProfileForm.module.scss';

const ProfileForm = () => {
  return (
    <div className={style.container}>
      <UserData inProfile />
      <Section title={'Twoje dane'}>
        <UserDataForm />
      </Section>
      <Section title={'Powiadomienia'}>
        <Notifications />
      </Section>
      <Section title={'Dostęp'}>
        <ChangePassword />
      </Section>
    </div>
  );
};

export default ProfileForm;
