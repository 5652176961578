import React, { useState } from 'react';

import { Button, Select } from 'components';

import { statuses } from 'src/features/Invoices/components/InvoiceForm';
import { getInvoiceStatus } from 'src/utils/helpers';

import ChangeMenu from '../index';

import style from '../ChangeMenu.module.scss';

const getDefaultStatus = (current) => statuses.find((status) => status.value === current);

const ChangeInvoiceStatus = (props) => {
  const { currentStatus, onSave, wasQtyChange, isActive, setIsActive } = props;
  const [invoiceStatus, setInvoiceStatus] = useState(getDefaultStatus(currentStatus));
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    if (onSave) await onSave(invoiceStatus.value);
    setIsActive(false);
    setIsLoading(false);
  };

  return (
    <ChangeMenu
      value={getInvoiceStatus({ status: currentStatus, className: style.tag })}
      isActive={isActive}
      setIsActive={setIsActive}
      wasDataChange={wasQtyChange}
    >
      <Select
        options={statuses}
        value={invoiceStatus}
        onChange={setInvoiceStatus}
        placeholder={'Status'}
        label={'Status'}
        isSearchable={false}
        id={'status'}
        name={'status'}
      />
      <Button
        label={'Zapisz'}
        onClick={handleSave}
        className={style.save}
        isLoading={isLoading}
      />
    </ChangeMenu>
  );
};

export default ChangeInvoiceStatus;
