import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';

import icon_person from 'assets/icons/person.svg';
import { CustomTooltip, Menu, Modal, Tag, useAuthUser, useIsMobile } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import { userRoles } from 'src/constants/enums';
import { getFormattedAmount, getMedian } from 'src/utils/helpers';
import { imageErrorHandler } from 'src/utils/helpers';

import PriceChart from '../PriceChart';
import PriceList from '../PriceList';

import style from './Product.module.scss';

const Product = ({ product, showSuppliers }) => {
  const { list_uid } = useParams();
  const { thumbnail, image, company_name, name, is_company_product, user_name, id } = product || {};

  const isMobile = useIsMobile();
  const user = useAuthUser();
  const isAdmin = user?.role === userRoles.admin;

  const [isOpen, setIsOpen] = useState(false);
  const [isChartOpen, setIsChartOpen] = useState(false);

  const productStats = useMemo(() => {
    const prices = product.prices.map((price) => price.price);
    const gfa = getFormattedAmount;

    return {
      lowest: gfa(prices.length ? Math.min(...prices) : 0),
      lowestRaw: prices.length ? Math.min(...prices) : 0,
      highest: gfa(prices.length ? Math.max(...prices) : 0),
      highestRaw: prices.length ? Math.max(...prices) : 0,
      average: gfa(prices.reduce((a, b) => a + b, 0) / prices.length),
      averageRaw: prices.reduce((a, b) => a + b, 0) / prices.length,
      median: gfa(getMedian(prices)),
      medianRaw: getMedian(prices),
      count: prices.length
    };
  }, [product]);

  const options = [
    {
      title: 'Lista wszystkich ceny',
      action: () => setIsOpen(true),
      icon: 'search'
    },
    {
      title: 'Wykres cenowy',
      action: () => setIsChartOpen(true),
      icon: 'pie-chart'
    }
  ];

  const getDifference = useCallback(
    (toCompare) => {
      if (!product.ref_price) return null;

      const savings = +product.ref_price - +toCompare;
      const numberRaw = -1 * (+product.ref_price - +toCompare);
      const number = getFormattedAmount(numberRaw);
      const percentages = -1 * ((savings / (savings + +toCompare)) * 100).toFixed(2);

      return (
        <Tag
          value={`${numberRaw > 0 ? '+' : ''}${number} (${percentages > 0 ? '+' : ''}${percentages}%)`}
          color={numberRaw > 0 ? 'yellow' : numberRaw < 0 ? 'green' : ''}
          className={style.tag}
        />
      );
    },
    [product.ref_price]
  );

  const uniqSuppliers = useMemo(() => {
    const arr = [];
    product.prices.forEach(({ email }) => !arr.includes(email) && arr.push(email));
    return arr;
  }, [product]);

  return (
    <div className={classnames(style.container, { [style.formList]: !!list_uid })}>
      <Menu
        actions={options}
        className={style.menu}
      />
      <div className={classnames(style.wrapper, style.nameWrapper)}>
        <div className={style.thumbnailWrapper}>
          {!!is_company_product && !isMobile && (
            <CustomTooltip
              wrapperStyle={style.userIconWrapper}
              title={isAdmin ? `${company_name} (${user_name})` : 'Twój produkt'}
              placement={'right'}
            >
              <img
                src={icon_person}
                className={style.userIcon}
                alt={'ikona użytkownika'}
                onError={imageErrorHandler}
              />
            </CustomTooltip>
          )}
          <ProductThumbnailImage
            thumbnail={thumbnail}
            image={image}
            title={name}
            id={id}
          />
        </div>
        <p className={style.text}>{name}</p>
      </div>
      {!!list_uid && (
        <div className={style.wrapper}>
          {isMobile && <p className={style.text}>Cena z listy:</p>}
          <p className={classnames(style.text, style.blue)}>{getFormattedAmount(product.ref_price)}</p>
        </div>
      )}
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Zakres cen:</p>}
        <p className={style.text}>
          {productStats.lowest} - {productStats.highest}
        </p>
        {getDifference(productStats.lowestRaw)}
      </div>
      <div className={classnames(style.wrapper, style.column)}>
        {isMobile && <p className={style.text}>Średnia:</p>}
        <p className={style.text}>{productStats.average}</p>
        {getDifference(productStats.averageRaw)}
      </div>
      <div className={classnames(style.wrapper, style.column)}>
        {isMobile && <p className={style.text}>Mediania:</p>}
        <p className={style.text}>{productStats.median}</p>
        {getDifference(productStats.medianRaw)}
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Liczba cen:</p>}
        <p className={style.text}>{productStats.count}</p>
      </div>
      {!!is_company_product && isMobile && (
        <div className={style.wrapper}>
          {isMobile && <p className={style.text}>Właściciel:</p>}
          <p className={style.text}>{isAdmin ? `${company_name} (${user_name})` : 'Twój produkt'}</p>
        </div>
      )}
      {uniqSuppliers.length > 0 && showSuppliers && (
        <div className={style.suppliers}>
          {uniqSuppliers.map((sup) => (
            <Tag
              value={sup}
              key={sup + product.id}
            />
          ))}
        </div>
      )}
      <Modal
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        title={product.name}
      >
        <PriceList prices={product.prices} />
      </Modal>
      <Modal
        visible={isChartOpen}
        onClose={() => setIsChartOpen(false)}
        title={product.name}
      >
        <PriceChart prices={product.prices} />
      </Modal>
    </div>
  );
};

export default Product;
