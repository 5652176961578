import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import {
  Button,
  ColumnList,
  EmptyState,
  Modal,
  PaginationNav,
  SeoHelmet,
  StickyPageHeader,
  SwitchButton,
  useAuthUser,
  useIsAdmin,
  usePermissions,
  useRequestAbortController,
  useSearchParam
} from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { DistributorApi, OrdersApi } from 'src/api';
import { SupplierApi } from 'src/api';
import { orderTypes, userPermissions, userRoles } from 'src/constants/enums';
import pages from 'src/dictionaries/pages.json';
import { getParamsArray } from 'src/utils/helpers';
import query from 'src/utils/query';

import Searchbar from '../../components/layout/Searchbar';
import ExportOrders from './components/ExportOrders';
import OrdersFiltering from './components/OrdersFiltering';
import OrderThumbnail from './components/OrderThumbnail';
import ProviderOrderThumbnail from './components/ProviderOrderThumbnail';
import RefreshOrders from './components/RefreshOrders';

import style from './Orders.module.scss';

const Orders = (props) => {
  const params = query(props);
  const isAdmin = useIsAdmin();

  const page = useSearchParam(params, 'page');
  const search = useSearchParam(params, 'search');
  const supplier = useSearchParam(params, 'supplier');
  const product_id = useSearchParam(params, 'product_id');
  const company = useSearchParam(params, 'company');
  const date_from = useSearchParam(params, 'startDate');
  const date_to = useSearchParam(params, 'endDate');

  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState();
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [hideCancelled, setHideCancelled] = useState(true);
  const ordersVersion = useSelector((state) => state.orders.version);
  const user = useAuthUser();
  const location = useLocation();
  const [abortController, setNewController] = useRequestAbortController();
  const [isExportVisible, setIsExportVisible] = useState(false);
  const [isRefreshVisible, setIsRefreshVisible] = useState(false);
  const [isCompanyAdmin] = usePermissions([userPermissions.company.admin]);

  const getCorrectRequest = useCallback(
    async (params, signal) => {
      if (props.type === orderTypes.supplier) {
        return await SupplierApi.getOrders(params, signal);
      } else if (props.type === orderTypes.distributor) {
        return await DistributorApi.getOrder(params, signal);
      } else {
        return await OrdersApi.getOrders(params, signal);
      }
    },
    [props.type]
  );

  const getData = async () => {
    let error;
    const params = {
      page: page || 1,
      perPage: perPage,
      search: search,
      hide: props.type === orderTypes.supplier ? false : hideCancelled,
      date_from: date_from ? moment(date_from).format('Y-MM-DD') : undefined,
      date_to: date_to ? moment(date_to).format('Y-MM-DD') : undefined,
      company_id: company,
      ...(supplier && { supplier_ids: getParamsArray(supplier) }),
      ...(product_id && { product_ids: getParamsArray(product_id) })
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await getCorrectRequest(params, signal);
      setPageData(data);
      setPageQty(data?.last_page);
    } catch (err) {
      notifyApiError(err);
      error = err;
    } finally {
      setIsLoading(error?.message === 'canceled');
    }
  };

  useEffect(() => {
    getData();
  }, [page, perPage, search, hideCancelled, ordersVersion, company, supplier, date_from, date_to, product_id, location.pathname]);

  const thumbnailComponent = useMemo(() => {
    switch (props.type) {
      case orderTypes.user:
        return OrderThumbnail;
      case orderTypes.supplier:
      case orderTypes.distributor:
        return ProviderOrderThumbnail;
      default:
        return null;
    }
  }, [props.type]);

  const pageTitle = useMemo(
    () => (user.role === userRoles.admin && props.type !== orderTypes.user ? pages.supplierOrders.title : pages.orders.title),
    [user, props.type]
  );

  return (
    <div className={style.container}>
      <SeoHelmet title={pageTitle} />
      <StickyPageHeader
        name={pageTitle}
        filtering={<OrdersFiltering params={params} />}
        belowContent={
          <>
            {props.type === orderTypes.user && (
              <SwitchButton
                value={hideCancelled}
                setValue={setHideCancelled}
                label={'Ukryj anulowane'}
              />
            )}

            {isCompanyAdmin && (
              <Button
                onClick={() => setIsExportVisible(true)}
                label={'Eksport'}
              />
            )}

            {isAdmin && (
              <Button
                onClick={() => setIsRefreshVisible(true)}
                label={'Odśwież statusy'}
                gray
              />
            )}
          </>
        }
      >
        <Searchbar params={params} />
      </StickyPageHeader>

      <ColumnList
        type={props.type}
        isLoading={isLoading}
        list={pageData?.data}
        component={thumbnailComponent}
        emptyState={<EmptyState type={params.get('search') ? 'search' : 'orders'} />}
      />
      <PaginationNav
        params={params}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
      />
      <Modal
        visible={isExportVisible}
        onClose={() => setIsExportVisible(false)}
        title={'Eksport zamówień'}
      >
        <ExportOrders onClose={() => setIsExportVisible(false)} />
      </Modal>
      <Modal
        visible={isRefreshVisible}
        onClose={() => setIsRefreshVisible(false)}
        title={'Odświeżenie statusów zamówień'}
      >
        <RefreshOrders handleCloseModal={() => setIsRefreshVisible(false)} />
      </Modal>
    </div>
  );
};

export default Orders;
