import React from 'react';
import classNames from 'classnames';

import { EmptyState } from 'components';

import LoadingGroupsSkeleton from '../LoadingGroupsSkeleton';
import ReplacementsGroup from '../ReplacementsGroup';

import styles from './ReplacementsGroups.module.scss';

const ReplacementsGroups = ({ groups = [], isLoading, isSilentLoading, emptyStateType, refreshCallback }) => {
  if (isLoading) return <LoadingGroupsSkeleton />;
  if (groups.length < 1) return <EmptyState type={emptyStateType} />;

  return (
    <div className={classNames(styles.container, { [styles.isSilentLoading]: isSilentLoading })}>
      {groups.map((group) => (
        <ReplacementsGroup
          key={group.id}
          refreshCallback={refreshCallback}
          {...group}
        />
      ))}
    </div>
  );
};

export default ReplacementsGroups;
