import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, SwitchButton } from 'components';
import ProductDetailsForm from 'components/layout/ProductDetailsForm';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';
import { editNewProduct, refreshList } from 'src/features/ProductsBase/actions';

import style from '../../CreateProduct.module.scss';

const ProductFormAdmin = ({ product_id = null, onClose, type, visible, title, productName, refreshCallback }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [closeOnSave, setCloseOnSave] = useState(true);
  const [productDetails, setProductDetails] = useState(null);

  const [clearProductData, setClearProductData] = useState(true);

  const editedProduct = useSelector((state) => state.productsBase.editedProduct);

  const getData = async (id = product_id) => {
    setIsLoading(true);
    try {
      const { data } = await ProductsApi.getProductCreationData({
        product_id: id
      });
      setProductDetails(data.product);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const saveProduct = async (product) => {
    const { unit, collectionQty, producer, productName, category, croppedPhotoData } = product;

    const queryParams = {
      product_id: productDetails?.id,
      collection_amount: unit?.is_collection ? +collectionQty : 0,
      unit_id: unit?.value,
      producer_id: producer.value,
      category_id: category.value,
      name: productName,
      image: croppedPhotoData,
      suppliers: editedProduct.suppliers
    };

    try {
      setIsLoading(true);
      const { data } = await ProductsApi.addProduct(queryParams);
      dispatch(refreshList());
      if (refreshCallback) refreshCallback();

      if (product.id || productDetails?.id) {
        notifyCommon(['Zmiany zostały zapisane.']);
        getData(data.product_id);
      } else {
        notifyCommon(['Produkt został dodany pomyślnie!']);
        setProductDetails(null);
      }

      if (closeOnSave) {
        onClose();
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    visible && getData();
  }, [product_id, visible]);

  useEffect(() => {
    dispatch(editNewProduct());
  }, []);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title={title}
    >
      <div className={style.container}>
        <ProductDetailsForm
          product={productDetails}
          productName={productName}
          isParentLoading={isLoading}
          clearProductData={clearProductData}
          onSave={saveProduct}
        />
        <div className={style.modalControls}>
          {type !== 'edit-product' && (
            <div className={style.switchWrapper}>
              <SwitchButton
                value={closeOnSave}
                setValue={setCloseOnSave}
                label={'Zamknij po zapisaniu'}
                reverse
              />
            </div>
          )}
          {type === 'create-product' && (
            <div className={style.switchWrapper}>
              <SwitchButton
                value={clearProductData}
                setValue={setClearProductData}
                label={'Czyść dane po zapisaniu'}
                reverse
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ProductFormAdmin;
