import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { Icon, Modal, PhotoCropper } from 'components';

import img from 'src/assets/img/no-image.png';
import placeholder from 'src/assets/img/no-image.png';
import { imageErrorHandler } from 'src/utils/helpers';

import style from './PhotoUploader.module.scss';

const PhotoUploader = ({
  onSubmit,
  sourceImage,
  isLoading,
  productThumbnail = false,
  showSmaller = false,
  showRound = false,
  disabled
}) => {
  const [files, setFiles] = useState(null);
  const [isCropperVisible, setIsCropperVisible] = useState(false);
  const [croppedArea, setCroppedArea] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const canvasRef = useRef();

  const inputRef = useRef();
  const containerRef = useRef();

  const onCropComplete = (data) => {
    setCroppedArea(data);
  };

  const setFile = (e) => {
    setFiles(e.target.files[0]);
    let files = e.target.files || e.dataTransfer.files;
    createImage(files[0]);
  };

  function createImage(file) {
    if (file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setImageBase64(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  const getFile = async () => {
    return await fetch(imageURL);
  };

  if (files) {
    getFile();
  }

  const handleSubmit = (data) => {
    resizeImage(imageURL, 760, 760);
    if (isLoading === undefined) {
      setIsCropperVisible(false);
    }

    onSubmit({
      ...data,
      'image-data': imageBase64
    });
  };

  useEffect(() => {
    if (files) {
      setIsCropperVisible(true);
      setImageURL(URL.createObjectURL(files));
    }
  }, [files]);

  const resizeImage = (imagePath, newWidth, newHeight) => {
    if (canvasRef && canvasRef.current) {
      const originalImage = new Image();
      originalImage.src = imagePath;

      const ctx = canvasRef.current?.getContext('2d');

      const renderCroppedImage = () => {
        const originalWidth = originalImage.naturalWidth;
        const originalHeight = originalImage.naturalHeight;
        const aspectRatio = originalWidth / originalHeight;
        const imageSize = showSmaller ? 100 : 150;

        if (!newHeight) {
          newHeight = Math.floor(newWidth / aspectRatio);
        }

        canvasRef.current.width = newWidth;
        canvasRef.current.height = newHeight;

        ctx.drawImage(
          originalImage,
          croppedArea.x,
          croppedArea.y,
          croppedArea.width,
          croppedArea.height,
          0,
          0,
          imageSize,
          imageSize
        );
      };

      //TODO unsubscribe listener
      originalImage.addEventListener('load', renderCroppedImage);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setIsCropperVisible(false);
    }
  }, [isLoading]);

  const handleCancel = () => {
    setIsCropperVisible(false);
    setImageBase64(null);
    setCroppedArea(null);
    setImageURL(null);
    setFiles(null);
  };

  return (
    <div
      className={style.container}
      ref={containerRef}
    >
      <div
        className={classNames(style.wrapper, {
          [style.small]: showSmaller
        })}
      >
        <div
          className={classNames(style.imageWrapper, {
            [style.product]: productThumbnail,
            [style.small]: showSmaller
          })}
        >
          {files ? (
            <canvas ref={canvasRef} />
          ) : (
            <img
              src={imageURL || sourceImage || placeholder}
              className={classNames(style.image, {
                [style.product]: productThumbnail
              })}
              alt='Miniatura produktu'
              onError={imageErrorHandler}
            />
          )}
        </div>
        {!disabled && (
          <button
            className={style.button}
            onClick={() => inputRef.current?.click()}
          >
            <Icon
              name={'upload'}
              size={20}
              fill={'#fff'}
            />
          </button>
        )}
      </div>
      <input
        data-hj-allow
        accept='image/*'
        ref={inputRef}
        className={style.inputFile}
        type={'file'}
        onChange={(e) => setFile(e)}
        disabled={disabled}
      />
      <Modal
        visible={isCropperVisible}
        onClose={handleCancel}
        hideHeader
      >
        <PhotoCropper
          isLoading={isLoading}
          onCropComplete={onCropComplete}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          image={imageURL}
          showRound={showRound}
        />
      </Modal>
    </div>
  );
};

export default PhotoUploader;
