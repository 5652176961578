import React, { useState } from 'react';

import { AsyncSelect, Button, Card, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ProductsApi, VariationsApi } from 'src/api';

import styles from './AddProductsToGroupForm.module.scss';
import shared from 'styles/Shared.module.scss';

const AddProductsToGroupForm = ({ groupId, groupName, closeModal, refreshCallback }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const validator = useValidator();

  const addGroupProductsHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      group_id: groupId,
      product_ids: selectedProducts.map((product) => product.id)
    };

    try {
      setIsLoading(true);
      const { data } = await VariationsApi.addGroupProducts(params);
      if (refreshCallback) refreshCallback(true);
      closeModal();
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={styles.container}
      onSubmit={addGroupProductsHandler}
    >
      <p>
        Dodaj produkty do grupy <strong className={shared.colorGreen}>{groupName}</strong>, wybierając je z listy.
      </p>
      <Card info>
        <strong>Uwaga!</strong> Produkty oznaczone na <strong className={shared.colorRed}>czerwono</strong> w wyszukiwarce
        produktów są już przypisane do istniejącej grupy. Wybranie ich ponownie spowoduje przeniesienie ich do nowo tworzonej
        grupy.
      </Card>
      <AsyncSelect
        id={'selected-products'}
        value={selectedProducts}
        onChange={setSelectedProducts}
        apiCallback={ProductsApi.getProducts}
        label={'Wybierz produkty'}
        valueKey={'id'}
        labelKey={'name'}
        placeholder={'Wyszukaj..'}
        validator={validator}
        rule={'required'}
        productSearch
        highlightUsedInVariationsGroup
        isClearable
        isMulti
      />
      <Button
        label={'Dodaj'}
        isLoading={isLoading}
        className={styles.button}
      />
    </form>
  );
};

export default AddProductsToGroupForm;
