import React from 'react';

import { EmptyState } from 'components';

import ColumnHeaders from '../ColumnHeaders';
import ProductHistory from '../ProductHistory';

import style from './Table.module.scss';

const Table = ({ products = [], selectedType, areDataSelected }) => {
  return (
    <div className={style.container}>
      {areDataSelected ? (
        products.length ? (
          <>
            <div className={style.wrapper}>
              <ColumnHeaders
                product={products[0]}
                selectedType={selectedType}
              />
            </div>
            {products.map((product) => (
              <ProductHistory
                product={product}
                key={product.id}
              />
            ))}
          </>
        ) : (
          <EmptyState type={'warehouseHistory'} />
        )
      ) : (
        <EmptyState type={'warehouseHistoryWelcome'} />
      )}
    </div>
  );
};

export default Table;
