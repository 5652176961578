import { useEffect } from 'react';
import encHex from 'crypto-js/enc-hex';
import HmacSHA256 from 'crypto-js/hmac-sha256';

const TawkToWidget = ({ username, email }) => {
  useEffect(() => {
    const apiKey = import.meta.env.VITE_TAWKTO_API_KEY;
    const hash = HmacSHA256(email, apiKey).toString(encHex);

    const tawkScript = document.createElement('script');
    tawkScript.src = 'https://embed.tawk.to/657ad9dc70c9f2407f7fb6c7/1hhju61kf';
    tawkScript.async = true;
    document.body.appendChild(tawkScript);

    const onTawkLoad = () => {
      if (window.Tawk_API) {
        window.Tawk_API.setAttributes(
          {
            name: username,
            email: email,
            hash: hash
          },
          (error) => {
            if (error) {
              console.error('Error setting Tawk.to visitor attributes:', error);
            }
          }
        );
      }
    };

    window.addEventListener('tawkLoad', onTawkLoad);

    tawkScript.onload = () => {
      window.dispatchEvent(new Event('tawkLoad'));
    };

    return () => {
      window.removeEventListener('tawkLoad', onTawkLoad);
      document.body.removeChild(tawkScript);
    };
  }, [username, email]);

  return null;
};

export default TawkToWidget;
