import React, { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

import { useAuthUser, useIsAdmin } from 'components';

import { userRoles } from 'src/constants/enums';

import DropdownElement from './components/DropdownElement';

import styles from './Dropdown.module.scss';

const Dropdown = ({ className, item, visibleSidebar, isClose, horizontalOnMobile, themeLight, onClose }) => {
  const [items, setItems] = useState([]);
  const user = useAuthUser();
  const isAdmin = useIsAdmin();

  const { pathname } = useLocation();

  useLayoutEffect(() => {
    const itemsArr = [];

    item.dropdown.forEach((link, index) => {
      const linkForDifferentRole = !link.roles?.includes(user?.role);
      const canCompanyEntries = link?.companyAccessFn ? isAdmin || link?.companyAccessFn(user?.company) : true;

      if (linkForDifferentRole || !canCompanyEntries) return null;

      if (
        user &&
        user.role !== userRoles.admin &&
        link?.requiredPermission &&
        user?.company?.permissions &&
        !user.company.permissions.includes(link.requiredPermission)
      ) {
        return null;
      }

      itemsArr.push(
        <DropdownElement
          onClose={onClose}
          key={index}
          link={link}
          styles={styles}
          index={index}
          isClose={isClose}
          horizontalOnMobile={horizontalOnMobile}
          themeLight={themeLight}
        />
      );
    });

    setItems(itemsArr);
  }, [item, user, visibleSidebar, isClose]);

  if (items.length === 0) {
    return null;
  }

  return (
    <div
      className={cn(styles.dropdown, className, styles.active, {
        [styles.active]: pathname.includes(item.slug),
        [styles.wide]: visibleSidebar
      })}
    >
      <div
        className={cn(styles.body, {
          [styles.horizontalOnMobile]: horizontalOnMobile
        })}
      >
        {items}
      </div>
    </div>
  );
};

export default Dropdown;
