import { useSelector } from 'react-redux';

const useCompany = () => {
  const user = useSelector((state) => state.auth.user);
  if (user && user?.company) {
    return user.company;
  } else {
    return null;
  }
};

export default useCompany;
