import React, { useMemo } from 'react';

import OrderOrValuationId from 'components/layout/OrderOrValuationId';

import { orderApprovalStatuses, orderStatuses } from 'src/constants/enums';
import { toDate, toTime } from 'src/utils/dateTime';
import { getApprovalUpdatedAt } from 'src/utils/helpers';

import style from '../../orderConfirmations.module.scss';

const OrderData = ({ pageData, createdAtTitle = 'Wysłano do akceptacji' }) => {
  const createdDate = useMemo(
    () => (pageData?.created_at ? `${toDate(pageData?.created_at)} ${toTime(pageData?.created_at)}` : ''),
    [pageData]
  );
  const sentDate = useMemo(
    () => (pageData?.sent_date ? `${toDate(pageData?.sent_date)} ${toTime(pageData?.sent_date)}` : ''),
    [pageData]
  );

  return (
    <div className={style.dateWrapper}>
      <OrderOrValuationId
        id={pageData?.public_id}
        withPrefix={'Zamówienie numer:'}
      />
      {!!createdDate && (
        <p className={style.createdDate}>
          {createdAtTitle}: {createdDate}
        </p>
      )}
      {(pageData.status === orderStatuses.sent || pageData.status === orderStatuses.completed) && !!sentDate && (
        <p className={style.createdDate}>wysłano: {sentDate}</p>
      )}
      {[orderApprovalStatuses.declined, orderApprovalStatuses.approved].includes(pageData.status) && (
        <p className={style.createdDate}>{getApprovalUpdatedAt(pageData)}</p>
      )}
    </div>
  );
};

export default OrderData;
