import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

import pages from 'src/dictionaries/pages.json';

export default function SeoHelmet({ title, titleForce, description, children }) {
  return (
    <Helmet>
      <title>{!title ? pages.main.title : titleForce ? `${title}` : `${title} - ${pages.main.title}`}</title>

      {description && (
        <meta
          name='description'
          content={description}
        />
      )}

      {children}
    </Helmet>
  );
}

SeoHelmet.propTypes = {
  title: PropTypes.string,
  titleForce: PropTypes.bool,
  description: PropTypes.string,
  children: PropTypes.node
};
