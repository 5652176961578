import React from 'react';

import { Loader } from 'components';

import style from './ProfileSection.module.scss';

const ProfileSection = ({ title, children, button, isLoading, skeleton: Skeleton, text = null }) => {
  return (
    <section className={style.section}>
      <header className={style.sectionHeader}>
        <div className={style.sectionName}>
          <h3>{title}</h3>
          {isLoading ? <Loader /> : text}
        </div>
        {button && <div className={style.buttons}>{button}</div>}
      </header>
      {isLoading ? (
        Skeleton ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <Loader />
        )
      ) : (
        children
      )}
    </section>
  );
};

export default ProfileSection;
