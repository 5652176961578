import React, { useState } from 'react';
import dayjs from 'dayjs';

import { Button } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';
import { EXCEL_FILE_TYPE } from 'src/constants/misc';
import { downloadFile, getDecodeBase64File } from 'src/utils/helpers';

import style from '../../ExportImportForm.module.scss';
const Export = () => {
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);

  const downloadHandler = async (type) => {
    const date = dayjs().format('DD.MM.YYYY, HH:mm');

    try {
      setIsLoadingCsv(type === 'CSV');
      setIsLoadingExcel(type === 'XLSX');
      const { data } = await ProductsApi.exportAllProducts({ file_format: type });
      const blob = type === 'CSV' ? new Blob([data], { type: 'text/csv' }) : getDecodeBase64File(data, EXCEL_FILE_TYPE);
      downloadFile(blob, 'baza_produktów_' + date + '.' + type.toLowerCase());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoadingCsv(false);
      setIsLoadingExcel(false);
    }
  };

  return (
    <div className={style.container}>
      <header className={style.header}>
        <h3>Export produktów</h3>
        <p>
          Pobierz szablon z całą bazą produktów, dzięki czemu możesz edytować niektóre dane produktów. Zaimportuj gotowy plik aby
          zaktualizwać bazę.
        </p>
      </header>
      <div className={style.buttonsWrapper}>
        <Button
          label={'Pobierz .csv'}
          isLoading={isLoadingCsv}
          onClick={() => downloadHandler('CSV')}
        />
        <Button
          label={'Pobierz .xlsx'}
          isLoading={isLoadingExcel}
          onClick={() => downloadHandler('XLSX')}
        />
      </div>
    </div>
  );
};

export default Export;
