import React, { Component, createElement } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { LoaderGlobal, useAuthUser, useIsAdmin, useIsUserAuth, usePermissions } from 'components';
import Page from 'components/layout/Page';

const BaseRoute = (props) => {
  const { component = Component, hasPermissions, fallbackUrl = '/404', ...rest } = props;

  if (!hasPermissions) {
    return <Redirect to={fallbackUrl} />;
  }

  const render = (props) => {
    if (hasPermissions) {
      return createElement(component, props);
    }
    return <Redirect to={fallbackUrl} />;
  };

  return (
    <Route
      {...rest}
      render={props.render || render}
    />
  );
};

export const PublicOnlyRoute = (props) => {
  const isAuthenticated = useIsUserAuth();

  return (
    <BaseRoute
      {...props}
      hasPermissions={!isAuthenticated}
      fallbackUrl={isAuthenticated ? '/dashboard' : '/'}
    />
  );
};

export const PrivateRoute = ({
  roles = [],
  children,
  wide,
  showGoBackButton,
  goBackButtonUrl,
  showToTopButton,
  requiredPermissions = [],
  nestedRoute = false,
  companyAccessFn = () => true,
  env = false,
  ...rest
}) => {
  const isAuthenticated = useIsUserAuth();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [hasPermission] = usePermissions(requiredPermissions);
  const isAdmin = useIsAdmin();
  const user = useAuthUser();

  const roleBased = roles?.includes(user?.role);
  const isPendingState = isAuthenticated && !user;

  const canCompanyEntries = isAdmin || companyAccessFn(user?.company);

  if (isLoading || isPendingState) {
    return <LoaderGlobal />;
  }

  if (!canCompanyEntries || !isAuthenticated) {
    return <BaseRoute hasPermissions={false} />;
  }

  if (nestedRoute) {
    return (
      <BaseRoute
        hasPermissions={isAuthenticated && roleBased && hasPermission}
        fallbackUrl='/dashboard'
        component={children}
        {...rest}
      />
    );
  }

  return (
    <Page
      wide={wide}
      showGoBackButton={showGoBackButton}
      goBackButtonUrl={goBackButtonUrl}
      showToTopButton={showToTopButton}
    >
      <BaseRoute
        hasPermissions={isAuthenticated && roleBased && hasPermission}
        fallbackUrl='/404'
        {...rest}
      />
    </Page>
  );
};
