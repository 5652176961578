import React, { useEffect, useState } from 'react';

import { Select } from 'components';

const SortByOptions = ({ params, options = [], ...rest }) => {
  const [selectedSorting, setSelectedSorting] = useState(null);

  const topValue = params.get('top');
  const sortByValue = params.get('sort_by');
  const sortOrderValue = params.get('sort_order');
  const typeValue = params.get('type');

  useEffect(() => {
    if (selectedSorting?.top) {
      params.remove('type');

      if (
        (selectedSorting?.sort_by !== sortByValue ||
          selectedSorting?.sort_by !== sortOrderValue ||
          +selectedSorting?.top !== +topValue) &&
        params.get('page')
      ) {
        params.setFew([
          { key: 'sort_by', value: selectedSorting?.sort_by },
          { key: 'sort_order', value: selectedSorting?.sort_order },
          { key: 'top', value: selectedSorting?.top },
          { key: 'page', value: '1' }
        ]);
      } else if (
        selectedSorting?.sort_by !== sortByValue ||
        selectedSorting?.sort_by !== sortOrderValue ||
        +selectedSorting?.top !== +topValue
      ) {
        params.setFew([
          { key: 'sort_by', value: selectedSorting?.sort_by },
          { key: 'sort_order', value: selectedSorting?.sort_order },
          { key: 'top', value: selectedSorting?.top }
        ]);
      }
    } else {
      params.remove('top');
      params.remove('sort_by');
      params.remove('sort_order');
      params.remove('type');

      if (+selectedSorting?.type !== typeValue && params.get('page')) {
        params.setFew([
          { key: 'type', value: selectedSorting?.type },
          { key: 'page', value: '1' }
        ]);
      } else if (+selectedSorting?.type !== typeValue) {
        params.setFew([{ key: 'type', value: selectedSorting?.type }]);
      }
    }
  }, [selectedSorting]);

  useEffect(() => {
    if (sortByValue && sortOrderValue) {
      setSelectedSorting(
        options.find((opt) => opt?.sort_by === sortByValue && opt?.sort_order === sortOrderValue && opt.top === topValue)
      );
    }
  }, []);

  return (
    <Select
      options={options}
      value={selectedSorting}
      onChange={setSelectedSorting}
      isSearchable={false}
      isClearable
      {...rest}
    />
  );
};

export default SortByOptions;
