import React, { useState } from 'react';

import { Button, FloatingCard, Modal, Textarea, useModalConfirm, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { orderApprovalStatuses } from 'src/constants/enums';

import style from './ApproveOrder.module.scss';

const ApproveOrder = ({ token, refreshDataCallback, status }) => {
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isLoading, setIsLoading] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const validator = useValidator();

  const orderApproval = async (approval) => {
    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const queryData = { token, approval, ...(!approval && { decline_reason: declineReason }) };

    try {
      setIsLoading(true);
      await SupplierApi.orderApproval(queryData);
      await refreshDataCallback();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => setIsOpen(false);
  const rejectHandler = () => setIsOpen(true);
  const approveHandler = () =>
    handleOpenModalConfirm({
      message: 'Czy na pewno chcesz zaakceptować zamówienie?',
      handleConfirm: () => orderApproval(true),
      cancelButtonName: 'Nie',
      confirmButtonName: 'Tak'
    });

  if (status !== orderApprovalStatuses.waiting_for_approval) {
    return null;
  }

  return (
    <FloatingCard
      title={'Zamówienie oczekuje na akceptację'}
      description={'Po zaakceptowaniu automatycznie zostanie przekazane do realizacji.'}
      trackLeftPosition
      yellow
    >
      <div className={style.buttons}>
        <Button
          onClick={rejectHandler}
          label={'Odrzuć'}
          isLoading={isLoading}
          color={'red'}
        />
        <Button
          onClick={approveHandler}
          label={'Zaakceptuj'}
          isLoading={isLoading}
          color={'green'}
        />
      </div>
      {renderedModalConfirm}
      <Modal
        visible={isOpen}
        onClose={onClose}
        title={'Czy na pewno chcesz odrzucić zamówienie?'}
      >
        <Textarea
          id={'decline-comment'}
          name={'decline-comment'}
          label={'Powód odrzucenia'}
          value={declineReason}
          setValue={setDeclineReason}
          validator={validator}
          rule={'required'}
        />
        <div className={style.buttonsWrapper}>
          <Button
            label={'Nie'}
            onClick={onClose}
            gray
          />
          <Button
            label={'Tak'}
            onClick={() => orderApproval(false)}
          />
        </div>
      </Modal>
    </FloatingCard>
  );
};

export default ApproveOrder;
