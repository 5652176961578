import React from 'react';

import { ProductDetails } from 'components';

const ProductInfo = ({ product }) => {
  return (
    <div>
      <ProductDetails
        product={product}
        autoSize
      />
    </div>
  );
};

export default ProductInfo;
