import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { AsyncSelect, Button, Input, Textarea, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { VariationsApi } from 'src/api';

import { refreshVariations } from '../../actions';

import styles from './MergeGroupsForm.module.scss';

const MergeGroupsForm = ({ groupId, groupName, closeModal }) => {
  const dispatch = useDispatch();

  const [group, setGroup] = useState({
    name: '',
    description: ''
  });
  const [selectedGroups, setSelectedGroups] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e) => {
    setGroup((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const onChangeHandler = (name, data) => {
    setGroup((prev) => ({
      ...prev,
      [name]: data
    }));
  };

  const mergeGroupsHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const group_ids = groupId ? selectedGroups.map((group) => group.id).concat(groupId) : selectedGroups.map((group) => group.id);

    const params = {
      name: group.name,
      ...(group.description && { description: group.description }),
      group_ids
    };

    try {
      setIsLoading(true);
      const { data } = await VariationsApi.mergeGroups(params);
      dispatch(refreshVariations());
      closeModal();
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const validator = useValidator();
  return (
    <form
      className={styles.container}
      onSubmit={mergeGroupsHandler}
    >
      {groupName ? (
        <p>
          Wybierz grupy, które chcesz połączyć z grupą <strong>{groupName}</strong>. W ich miejsce zostanie utworzona nowa grupa z
          nazwą którą podasz w tym formularzu oraz opcjonalnie opisem.
        </p>
      ) : (
        <p>
          Wybierz grupy, które chcesz połączyć. W ich miejsce zostanie utworzona nowa grupa z nazwą którą podasz w tym formularzu
          oraz opcjonalnie opisem.
        </p>
      )}

      <Input
        id='name'
        name='name'
        label='Nazwa grupy'
        value={group.name}
        onChange={onChange}
        validator={validator}
        rule={'required'}
      />
      <Textarea
        id={'description'}
        name={'description'}
        label={'Opis grupy'}
        value={group.description || ''}
        setValue={(description) => onChangeHandler('description', description)}
      />
      <AsyncSelect
        id={'selected-groups'}
        value={selectedGroups}
        onChange={setSelectedGroups}
        apiCallback={VariationsApi.getGroups}
        excludeOptionById={groupId || false}
        label={'Wybierz grupy'}
        valueKey={'id'}
        labelKey={'name'}
        placeholder={'Wyszukaj..'}
        validator={validator}
        rule={'required'}
        isClearable
        isMulti
      />
      <Button
        label={'Połącz'}
        isLoading={isLoading}
        className={styles.button}
      />
    </form>
  );
};

export default MergeGroupsForm;
