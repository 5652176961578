import React from 'react';

import { toDateNumber } from 'src/utils/dateTime';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './SinglePrice.module.scss';

const SinglePrice = ({ data }) => {
  const { created_at, email } = data;

  return (
    <div className={style.container}>
      <p>{email}</p>
      <p>{getFormattedAmount(data.price)}</p>
      <p>{created_at ? toDateNumber(created_at) : '-'}</p>
    </div>
  );
};

export default SinglePrice;
