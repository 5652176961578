import React from 'react';

import { Button, Menu, RelativeTime } from 'components';

import { getFormattedAmount } from 'src/utils/helpers';

import styles from '../../FloorPlan.module.scss';

const RoomOverview = ({ roomId }) => {
  const noteActions = [
    {
      title: 'Edytuj',
      icon: 'edit',
      action: () => console.log('edit')
    },
    {
      title: 'Usuń',
      icon: 'trash',
      color: 'red',
      action: () => console.log('remove')
    }
  ];

  return (
    <div className={styles.modalContainer}>
      {roomId === 1 ? (
        <>
          <div className={styles.dataWrapper}>
            <h4 className={styles.dataWrapperHeadline}>Podstawowe informacje</h4>
            <ul className={styles.items}>
              <li>
                <span>Przychód w tym miesiącu:</span>
                <span className={styles.green}>{getFormattedAmount(63960)}</span>
              </li>
              <li>
                <span>Data następnego serwisu:</span>
                <RelativeTime date={'2024-02-29 15:14:47'} />
              </li>
              <li>
                <span>Wartość zużytych materiałów:</span>
                <span className={styles.red}>{getFormattedAmount(4920.5)}</span>
              </li>
              <li>
                <span>Obłożenie:</span>
                <span>72%</span>
              </li>
              <li>
                <span>Zysk/Strata:</span>
                <span className={styles.green}>{getFormattedAmount(12792.5)}</span>
              </li>
            </ul>
          </div>
          <div className={styles.dataWrapper}>
            <h4 className={styles.dataWrapperHeadline}>Notatka</h4>
            <div className={styles.note}>
              <p>Zbliża się termin serwisu urządzeń w tym gabinecie. Zadzwonić i umówić serwis na 15.02.2024.</p>
              <Menu
                actions={noteActions}
                className={styles.noteMenu}
              />
            </div>
          </div>
        </>
      ) : roomId === 2 ? (
        <>
          <div className={styles.dataWrapper}>
            <h4 className={styles.dataWrapperHeadline}>Podstawowe informacje</h4>
            <ul className={styles.items}>
              <li>
                <span>Przychód w tym miesiącu:</span>
                <span className={styles.green}>{getFormattedAmount(70356.0)}</span>
              </li>
              <li>
                <span>Data następnego serwisu:</span>
                <RelativeTime date={'2024-03-04 15:14:47'} />
              </li>
              <li>
                <span>Wartość zużytych materiałów:</span>
                <span className={styles.red}>{getFormattedAmount(5720.5)}</span>
              </li>
              <li>
                <span>Obłożenie:</span>
                <span>62%</span>
              </li>
              <li>
                <span>Zysk/Strata:</span>
                <span className={styles.green}>{getFormattedAmount(7035.6)}</span>
              </li>
            </ul>
          </div>
          <div className={styles.dataWrapper}>
            <h4 className={styles.dataWrapperHeadline}>Notatka</h4>
            <div className={styles.emptyNote}>
              <p>Brak notatek.</p>
              <Button label={'Dodaj notatkę'} />
            </div>
          </div>
        </>
      ) : roomId === 3 ? (
        <>
          <div className={styles.dataWrapper}>
            <h4 className={styles.dataWrapperHeadline}>Podstawowe informacje</h4>
            <ul className={styles.items}>
              <li>
                <span>Przychód w tym miesiącu:</span>
                <span className={styles.green}>{getFormattedAmount(50156.5)}</span>
              </li>
              <li>
                <span>Data następnego serwisu:</span>
                <RelativeTime date={'2024-03-04 15:14:47'} />
              </li>
              <li>
                <span>Wartość zużytych materiałów:</span>
                <span className={styles.red}>{getFormattedAmount(3990.5)}</span>
              </li>
              <li>
                <span>Obłożenie:</span>
                <span>51%</span>
              </li>
              <li>
                <span>Zysk/Strata:</span>
                <span className={styles.red}>{getFormattedAmount(-3035.6)}</span>
              </li>
            </ul>
          </div>
          <div className={styles.dataWrapper}>
            <h4 className={styles.dataWrapperHeadline}>Notatka</h4>
            <div className={styles.emptyNote}>
              <p>Brak notatek.</p>
              <Button label={'Dodaj notatkę'} />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default RoomOverview;
