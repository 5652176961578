import { notifyNotification } from 'components/layout/Toasts';

import * as types from './types';

export const newNotification = (data) => {
  notifyNotification(data);
  return {
    type: types.NEW_NOTIFICATION,
    payload: {
      ...data,
      read_at: null
    }
  };
};

export const refreshNotifications = () => ({
  type: types.CHANGE_VERSION
});

export const resetNotifications = () => ({
  type: types.RESET_NOTIFICATIONS
});

export const setAllNotificationsAsRead = () => ({
  type: types.SET_ALL_NOTIFICATIONS_AS_READ
});

export const clearNotificationsStore = () => ({
  type: types.SET_DEFAULT_STATE
});

export const setNotificationAsRead = (id) => ({
  type: types.SET_NOTIFICATION_AS_READ,
  payload: id
});

export const setOldNotifications = (notifications) => ({
  type: types.SET_OLD_NOTIFICATIONS,
  payload: notifications
});

// const mockNtf = {
//   created_at: '2022-11-14T11:40:55.446352Z',
//   id:'f1418be1-d889-4d91-8418-238ef4dbe42a',
//   supplier_id:51,
//   supplier_name:'Jan Kowalski',
//   type:'App\\Notifications\\PricingCompleted',
//   valuation_name:'notify',
//   valuation_uid:'564cf3af-5624-4311-9f4a-c8231ecbcbbb',
// }
