import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input, PhoneInput, QtySelector, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AccountsApi, UserApi } from 'src/api';
import { changeSuppliersVersion } from 'src/features/Suppliers/actions';
import { getRandomSupplierColor } from 'src/utils/helpers';

import SelectElements from './components/SelectElements';

import style from './SupplierForm.module.scss';

const getDefaultSup = () => ({
  id: undefined,
  name: '',
  patron: '',
  phone: '',
  email: '',
  color: getRandomSupplierColor(),
  free_shipping_price: 0,
  shipping_price: 0,
  ship_methods: [],
  pay_methods: []
});

const SupplierForm = ({ closeModal, supplier }) => {
  const dispatch = useDispatch();
  const validator = useValidator();

  const [supplierData, setSupplierData] = useState(supplier || getDefaultSup());
  const [isLoading, setIsLoading] = useState(false);
  const [shipAndPayMethods, getShipAndPayMethode] = useState(null);

  const getShipAndPayMethods = async () => {
    try {
      setIsLoading(true);
      const { data } = await AccountsApi.getShipAndPayMethods();
      getShipAndPayMethode(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getShipAndPayMethods();
  }, []);

  const changeHandler = (e) => {
    setSupplierData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const changePhoneHandler = (phone) => {
    setSupplierData((prev) => ({
      ...prev,
      phone
    }));
  };

  const changePriceHandler = (value, name) => {
    setSupplierData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const apiCallback = supplier ? UserApi.editSupplier : UserApi.addSupplier;
    const queryData = {
      ...supplierData,
      free_shipping_price: String(supplierData?.free_shipping_price),
      shipping_price: String(supplierData?.shipping_price)
    };

    try {
      setIsLoading(true);
      await apiCallback(queryData);
      dispatch(changeSuppliersVersion());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (supplier && !supplier.color) {
      setSupplierData((prev) => ({ ...prev, color: getRandomSupplierColor(supplier.id) }));
    }
  }, [supplier]);

  const onElementChange = (id, value, type) => {
    setSupplierData((prev) => {
      const payArr = prev[type] || [];
      return {
        ...prev,
        [type]: value ? [...payArr, id] : payArr.filter((item) => item !== id)
      };
    });
  };

  return (
    <form
      className={style.container}
      onSubmit={(e) => handleSubmit(e)}
    >
      <div className={style.row}>
        <Input
          id='name'
          name='name'
          label='Nazwa'
          placeholder='Nazwa dostawcy'
          value={supplierData.name}
          onChange={changeHandler}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='email'
          name='email'
          label='Adres e-mail'
          placeholder='jan@avalio.pl'
          value={supplierData.email}
          onChange={changeHandler}
          validator={validator}
          rule={'required|email'}
          disabled={!!supplier}
        />
      </div>
      <div className={style.row}>
        <Input
          id='patron'
          name='patron'
          label='Przedstawiciel'
          placeholder='Imię i nazwisko'
          value={supplierData.patron}
          onChange={changeHandler}
          validator={validator}
          rule={'required'}
        />
        <PhoneInput
          id='phone'
          name='phone'
          label='Telefon'
          value={supplierData.phone}
          onChange={changePhoneHandler}
          validator={validator}
          rule={'required|phone'}
        />
      </div>
      <div className={style.row}>
        <QtySelector
          id={'shipping_price'}
          name={'shipping_price'}
          label={'Koszt wysyłki'}
          state={supplierData.shipping_price}
          setState={(e) => changePriceHandler(e, 'shipping_price')}
          wrapperStyle={style.input}
          suffix={'zł'}
          validator={validator}
          rule={'required'}
          priceInput
          hideArrows
        />
        <QtySelector
          id={'free_shipping_price'}
          name={'free_shipping_price'}
          label={'Minimum logistyczne'}
          state={supplierData.free_shipping_price}
          setState={(e) => changePriceHandler(e, 'free_shipping_price')}
          wrapperStyle={style.input}
          suffix={'zł'}
          validator={validator}
          rule={'required'}
          priceInput
          hideArrows
        />
        <Input
          id='color'
          name='color'
          type='color'
          label='Kolor dostawcy'
          placeholder='#ffffff'
          value={supplierData.color}
          onChange={changeHandler}
        />
      </div>
      <div className={style.row}>
        <SelectElements
          title={'Dostępne formy płatności'}
          elements={shipAndPayMethods?.pay_methods}
          selected={supplierData?.pay_methods}
          onChange={(id, value) => onElementChange(id, value, 'pay_methods')}
        />
        <SelectElements
          title={'Dostępne formy wysyłki'}
          elements={shipAndPayMethods?.ship_methods}
          selected={supplierData?.ship_methods}
          onChange={(id, value) => onElementChange(id, value, 'ship_methods')}
        />
      </div>
      <div className={style.button}>
        <Button
          label={'Zapisz'}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default SupplierForm;
