import React, { useState } from 'react';
import classNames from 'classnames';

import { Button, EmptyState, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ReplacementsApi } from 'src/api';
import { handleNumeralWords } from 'src/utils/helpers';

import ProductReplacementsAddProductsToGroupForm from '../ProductReplacementsAddProductsToGroupForm';
import ProductReplacementsTableThumbnail from './components/ProductReplacementsTableThumbnail';

import styles from './ProductReplacementsTable.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductReplacementsTable = ({ id, name, products, productId, refreshCallback }) => {
  const [version, setVersion] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const handleRemoveProduct = async () => {
    try {
      setIsLoading(true);
      const { data } = await ReplacementsApi.removeGroupProduct(productId);
      if (refreshCallback) refreshCallback(true);
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const openRemoveProductModalConfirm = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć aktualny produkt z grupy?',
      handleConfirm: handleRemoveProduct
    });
  };

  return (
    <div>
      <div className={styles.header}>
        <h3 className={styles.groupName}>
          Produkt należy do grupy: <strong className={shared.colorGreen}>{name}</strong>
          <small>
            {`(${products?.length || '0'} ${handleNumeralWords(['zamiennik', 'zamienniki', 'zamienników'], products?.length)})`}
          </small>
        </h3>
        <Button
          label={'Usuń z grupy'}
          isLoading={isLoading}
          onClick={openRemoveProductModalConfirm}
          gray
        />
      </div>
      <ProductReplacementsAddProductsToGroupForm
        groupId={id}
        refreshCallback={refreshCallback}
        version={version}
        setVersion={setVersion}
      />
      <div className={styles.tableWrapper}>
        {products?.length > 0 ? (
          <div className={shared.tableWrapper}>
            <div className={classNames(shared.tableHeader, styles.tableHeader)}>
              <p>Produkt</p>
              <p>Producent</p>
              <p></p>
            </div>
            {products.map((product) => (
              <ProductReplacementsTableThumbnail
                key={product.id}
                refreshCallback={refreshCallback}
                setVersion={setVersion}
                {...product}
              />
            ))}
          </div>
        ) : (
          <EmptyState
            type={'noReplacements'}
            tiny
          />
        )}
      </div>
      {renderedModalConfirm}
    </div>
  );
};

export default ProductReplacementsTable;
