import React, { useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';

import { EmptyState, InfoWidget, SupplierColor } from 'components';

import { getFormattedAmount } from 'src/utils/helpers';

import style from './CartSummary.module.scss';
import shared from 'styles/Shared.module.scss';

const CartSummary = ({ summary = [], isLoading }) => {
  const sectionClasses = classNames(shared.tableWrapper, style.table);
  const wrapperClasses = classNames(style.tableHeader, { [style.hidden]: summary.length === 0 });
  const sortedSummary = summary.sort((a, b) => +b.product_count - +a.product_count);

  const getShippingCost = useCallback(
    (supplier) => (supplier.sum === 0 || supplier.sum >= supplier.free_shipment_price ? 0 : supplier?.shipping_price),
    [summary]
  );
  const getAmountToFreeShipping = useCallback((supplier) => supplier.free_shipment_price - supplier.sum, [summary]);

  if (isLoading) {
    return (
      <div className={style.container}>
        <section className={sectionClasses}>
          <div className={style.tableHeader}>
            <p>Dostawca</p>
            <p className={style.textRight}>Produkty</p>
            <p className={style.textRight}>Wartość</p>
            <p className={style.textRight}>Wysyłka</p>
            <p className={style.textRight}>Suma</p>
          </div>
          <div className={style.wrapper}>
            <Skeleton
              height={27}
              count={Math.max(sortedSummary?.length, 2)}
            />
          </div>
        </section>
      </div>
    );
  }

  return (
    <div className={style.container}>
      <div className={style.tableWrapper}>
        <div className={style.tableContent}>
          <section className={sectionClasses}>
            <div className={wrapperClasses}>
              <p>Dostawca</p>
              <p className={style.textRight}>Produkty</p>
              <p className={style.textRight}>Wartość</p>
              <p className={style.textRight}>Wysyłka</p>
              <p className={style.textRight}>Suma</p>
            </div>
            {sortedSummary.length > 0 ? (
              <div className={style.wrapper}>
                {sortedSummary.map((supplier) => (
                  <div
                    className={classNames(style.row)}
                    key={`${supplier.name}-${supplier.id}`}
                  >
                    <div className={style.rowSupplierName}>
                      <SupplierColor supplier={supplier} />
                      <p>{supplier.name}</p>
                    </div>
                    <div className={style.data}>
                      <p>{supplier.product_count || 0}</p>
                    </div>
                    <div className={style.data}>
                      <p>{getFormattedAmount(supplier.sum)}</p>
                    </div>
                    <div className={style.data}>
                      <p className={style.data}>{getFormattedAmount(getShippingCost(supplier))}</p>
                      {getAmountToFreeShipping(supplier) > 0 && (
                        <InfoWidget
                          className={style.info}
                          place={'bottom'}
                        >
                          Brakuje {getFormattedAmount(getAmountToFreeShipping(supplier))} do darmowej wysyłki.
                        </InfoWidget>
                      )}
                    </div>
                    <div className={style.data}>
                      <p className={style.data}>{getFormattedAmount(getShippingCost(supplier) + supplier.sum)}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <EmptyState
                type={'noPricesSelected'}
                tiny
              />
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default CartSummary;
