import React from 'react';
import classNames from 'classnames';

import { Tag } from 'components';

import style from './ChartWrapper.module.scss';

const ChartWrapper = ({ children, isEmpty }) => {
  return (
    <div
      className={classNames(style.container, {
        [style.empty]: isEmpty
      })}
    >
      <div className={style.container}>{children}</div>
      {isEmpty && (
        <div className={style.empty}>
          <Tag value={'Brak danych'} />
        </div>
      )}
    </div>
  );
};

export default ChartWrapper;
