import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { AsyncSelect, Button, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { CompanyApi, UserApi } from 'src/api';
import { userRoles } from 'src/constants/enums';

import { refreshProducts } from '../../actions';

import style from './AddSupplierToProduct.module.scss';

const AddSupplierToProduct = ({ id, name, closeModal, selectedSuppliers }) => {
  const dispatch = useDispatch();
  const validator = useValidator();

  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const addSupplierToProductsHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const data = {
      product_ids: [id],
      supplier_id: selectedSupplier?.value
    };

    try {
      setIsLoading(true);
      await CompanyApi.addSupplierToProduct(data);
      notifyCommon([`Dostawca ${selectedSupplier?.label} został przypisany do ${name}.`]);
      dispatch(refreshProducts());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const supplersIDs = useMemo(() => selectedSuppliers.map((sup) => sup.id), [selectedSupplier]);

  const isOptionDisabled = (option) => supplersIDs.includes(option.id);

  return (
    <form
      className={style.container}
      onSubmit={addSupplierToProductsHandler}
    >
      <AsyncSelect
        value={selectedSupplier}
        onChange={setSelectedSupplier}
        apiCallback={UserApi.getDistributors}
        valueKey={'id'}
        labelKey={'name'}
        placeholder={'Dostawca'}
        queryParams={{ role: userRoles.supplier, pagination: 1 }}
        wrapperStyle={classNames(style.select)}
        isOptionDisabled={isOptionDisabled}
        disabledElements
        isClearable
      />
      <Button
        label={'Zapisz'}
        title={'Zapisz'}
        type={'submit'}
        className={style.button}
        isLoading={isLoading}
      />
    </form>
  );
};

export default AddSupplierToProduct;
