import React, { useState } from 'react';
import cn from 'classnames';

import { EmptyState, Loader, Modal } from 'components';

import Supplier from './components/Supplier';

import style from './ShowHideSupplierOffers.module.scss';

const ShowHideSupplierOffers = ({ suppliers = [], isModalOpen, setIsModalOpen, refreshSuppliers, isSuppliersLoading }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      visible={isModalOpen}
      title={'Pokaż / ukryj dostawców'}
      onClose={() => setIsModalOpen(false)}
    >
      <div className={style.container}>
        <p>Ukrycie ofert skutkuje wykluczeniem ich podczas realizacji zamówienia. Nie będą widoczne w Twoim koszyku.</p>
        <p>UWAGA! Jeśli oferty dostawcy są przypisane jako preferowane, to zostaną zastąpione ofertami alternatywnymi.</p>
        {isSuppliersLoading && suppliers.length === 0 ? (
          <Loader />
        ) : suppliers.length === 0 ? (
          <EmptyState type={'suppliersVisibility'} />
        ) : (
          <div className={style.wrapper}>
            {suppliers.map((sup) => (
              <Supplier
                supplier={sup}
                key={sup.id}
                refreshSuppliers={refreshSuppliers}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            ))}
          </div>
        )}
        <p className={cn(style.disclaimer, { [style.isLoading]: isLoading })}>* Trwa zmiana kolejności ofert, proszę czekać.</p>
      </div>
    </Modal>
  );
};

export default ShowHideSupplierOffers;
