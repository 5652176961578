import { Children, cloneElement, useCallback, useEffect, useRef } from 'react';

// This file is clone of this package.
// https://www.npmjs.com/package/react-clickout-ts?activeTab=readme

const OutsideClickHandler = (props) => {
  const { children, enabled = true, events = ['mousedown', 'touchstart'], ignoredElements = [], onOutsideClick } = props;
  const wrapperRef = useRef(null);

  const shouldFire = useCallback(
    (ev) => {
      return (
        enabled &&
        wrapperRef.current &&
        !wrapperRef.current.contains(ev.target) &&
        !ignoredElements.some((element) => element && (element.contains(ev.target) || !document.body.contains(ev.target)))
      );
    },
    [enabled, ignoredElements]
  );

  useEffect(() => {
    const handleClickOut = (ev) => {
      if (shouldFire(ev)) onOutsideClick(ev);
    };

    events.forEach((event) => document.addEventListener(event, handleClickOut));

    return () => {
      events.forEach((event) => document.removeEventListener(event, handleClickOut));
    };
  }, [events, onOutsideClick, shouldFire]);

  if (typeof children === 'function') {
    return children({ ref: wrapperRef });
  }

  return cloneElement(Children.only(children), {
    ref: wrapperRef
  });
};
export default OutsideClickHandler;
