import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Card } from 'components';

import styles from './LoadingGroupsSkeleton.module.scss';

const LoadingGroupsSkeleton = () => {
  return (
    <div className={styles.container}>
      {[...Array(3)].map((_, i) => (
        <Card
          key={i}
          className={styles.wrapper}
        >
          <div className={styles.groupHeader}>
            <div>
              <h2 className={styles.groupHeadline}>
                <Skeleton
                  width={200}
                  height={20}
                />
              </h2>
              <p className={styles.groupDescription}>
                <Skeleton
                  width={400}
                  height={10}
                />
              </p>
            </div>
          </div>
          <Skeleton
            width={'100%'}
            height={40}
            count={5}
          />
        </Card>
      ))}
    </div>
  );
};

export default LoadingGroupsSkeleton;
