import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useRequestAbortController } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ServicesApi } from 'src/api';
import imagePlaceholder from 'src/assets/img/product-thumbnail.png';
import ServiceEvents from 'src/features/ServicesView/components/ServiceEvents';
import { imageErrorHandler } from 'src/utils/helpers';
import query from 'src/utils/query';

import ServiceDetails from './components/ServiceDetails';

import style from './ServicesView.module.scss';

const ServicesView = (props) => {
  const { id } = useParams();
  const params = query(props);
  const version = useSelector((state) => state.services.version);

  const [pageData, setPageData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();

  useEffect(() => {
    const getData = async () => {
      if (abortController) abortController.abort();
      const signal = setNewController();

      try {
        setIsLoading(true);
        const { data } = await ServicesApi.getService(id, signal);
        setPageData(data);
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [version]);

  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <img
            src={pageData?.logo || imagePlaceholder}
            alt={'alt'}
            loading={'lazy'}
            className={style.img}
            onError={imageErrorHandler}
          />
          <ServiceDetails
            service={pageData}
            isLoading={loading}
          />
        </div>
        <ServiceEvents
          serviceId={pageData?.id}
          params={params}
        />
      </div>
    </>
  );
};

export default ServicesView;
