import React from 'react';

import { NotificationCard } from 'components';
import styles from 'components/layout/Header/Notification/Notification.module.scss';

import style from './List.module.scss';

const List = ({ notifications = [], closeMenu, title, hideList, handleRead }) => {
  if (hideList) {
    return null;
  }

  if (notifications.length < 1) {
    return (
      <div className={styles.container}>
        <h2 className={style.title}>{title}</h2>
        <p className={style.emptyState}>Brak powiadomień</p>
      </div>
    );
  }

  return (
    <div className={style.container}>
      {!!title && <h2 className={style.title}>{title}</h2>}
      {notifications?.map((el) => (
        <NotificationCard
          key={el.id}
          notification={el}
          closeMenu={closeMenu}
          onRead={handleRead}
        />
      ))}
    </div>
  );
};

export default List;
