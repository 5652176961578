import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  AllOrdersSumBar,
  Button,
  DeclinedReason,
  PageHeader,
  ProcessStepper,
  SeoHelmet,
  useUnreadNotifications
} from 'components';
import { notifyApiError, notifyDanger } from 'components/layout/Toasts';

import { OrdersApi } from 'src/api';
import { orderStatuses, productStatuses } from 'src/constants/enums';
import { ordersNtfTypes } from 'src/constants/notifications/types';
import OrderSummary from 'src/features/OrdersView/components/OrderSummary';
import SingleCart from 'src/features/OrdersView/components/SingleCart';
import { getOrderCurrentStep, getOrderRealizationSum } from 'src/utils/helpers';
import { getOrderStatusTag } from 'src/utils/helpers';

import Notifications from './components/Notifications';
import OrderAnalytics from './components/OrderAnalytics';
import OrderProductsDepartments from './components/OrderProductsDepartments';
import RealizationSummary from './components/RealizationSummary';
import SentToApproval from './components/SentToApproval';

import style from './OrdersView.module.scss';

const OrdersView = () => {
  const { uid } = useParams();
  const history = useHistory();
  const [pageData, setPageData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const version = useSelector((state) => state.orderView.version);
  const unreadNotifications = useUnreadNotifications(ordersNtfTypes);

  const getData = async () => {
    try {
      const { data } = await OrdersApi.getProducts({ uid });
      setPageData(data);
      if (data.suppliers.length === 0) {
        notifyDanger(['Coś poszło nie tak... Zamówienie nie posiada dostawców. Skontaktuj się z nami.']);
        history.push('/orders');
      }
    } catch (err) {
      notifyApiError(err);
      history.push('/orders');
    }
  };

  useEffect(() => {
    const getDataHandler = async () => {
      setIsLoading(true);
      await getData();
      setIsLoading(false);
    };

    getDataHandler();
  }, [uid]);

  useEffect(() => {
    if (version > 0) getData();
  }, [version]);

  const getCancelledProducts = () => {
    const arr = [];
    pageData?.suppliers.forEach((sup) => {
      sup.products.forEach((product) => {
        arr.push(product);
      });
    });

    return arr.filter((data) => data.status === productStatuses.cancelled);
  };

  const cancelledProducts = useMemo(() => getCancelledProducts(), [pageData]);
  const orderNotifications = useMemo(
    () => unreadNotifications?.filter((ntf) => ntf?.data?.uid === uid),
    [unreadNotifications, pageData]
  );

  return (
    <>
      <SeoHelmet title={pageData?.name || 'Zamówienie'} />
      <PageHeader
        name={pageData?.name}
        isLoading={isLoading}
        text={pageData?.status && getOrderStatusTag({ status: pageData.status, wholeOrderStatus: true, className: style.tag })}
        metaData={{
          created_at: pageData?.created_at,
          author: pageData?.shipping_details?.name
        }}
        hideChildrenWhenLoading
        textCenter
      >
        <Button
          onClick={window.print}
          label='Drukuj zamówienie'
          gray
        />
      </PageHeader>
      <div className={style.container}>
        <ProcessStepper
          {...getOrderCurrentStep(pageData)}
          smaller
        />
        {pageData?.status === orderStatuses.draft && (
          <DeclinedReason
            showExit
            orderApprovalData={pageData?.order_approval}
          />
        )}
        {!!pageData && (
          <SentToApproval
            uid={uid}
            status={pageData.status}
          />
        )}
        <Notifications notifications={orderNotifications} />
        {!!pageData && (
          <OrderSummary
            isStatus
            order={pageData}
            defaultOpen
          />
        )}
        {cancelledProducts.length > 0 && (
          <RealizationSummary
            suppliers={pageData?.suppliers}
            label={getOrderRealizationSum(pageData?.suppliers).counter}
            products={cancelledProducts}
            orderID={pageData?.id}
            productsAddedToList={pageData?.cancelled_products_added_to_list}
          />
        )}
        {!!pageData && <OrderAnalytics status={pageData.status} />}
        {pageData?.suppliers.map((sup) => (
          <SingleCart
            notifications={orderNotifications}
            createdAt={pageData?.created_at}
            companyId={pageData?.company_id}
            isLoading={isLoading}
            supplier={sup}
            key={sup.id}
            uid={uid}
          />
        ))}
        <AllOrdersSumBar
          suppliersNumber={pageData?.suppliers?.length}
          isHidden={!pageData?.total_sum}
          totalSum={pageData?.total_sum}
          shipPrice={pageData?.shipping_sum}
          status={pageData?.status}
        />
        {pageData?.has_departments && <OrderProductsDepartments uid={uid} />}
      </div>
    </>
  );
};

export default OrdersView;
