import React from 'react';
import { useLocation } from 'react-router';

import { useCompany } from 'components';

import LogoUploader from './components/LogoUploader';

import style from '../../features/Account/ProfileForm.module.scss';

const TopSection = () => {
  const company = useCompany();
  const location = useLocation();

  if (!company || location.pathname === '/profile') {
    return null;
  }

  return (
    <>
      <div className={style.row}>
        <div className={style.topSection}>
          <LogoUploader />
          <div className={style.userDetails}>
            <p>Przeglądasz dane oddziału:</p>
            <h2>{company?.name}</h2>
            <p>NIP: {company?.nip}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopSection;
