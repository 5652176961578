import React from 'react';
import classNames from 'classnames';

import { Tag } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import style from '../ProductComponents.module.scss';

const ProductDetails = React.memo(({ thumbnail, image, name, producer, product_id }) => (
  <div
    className={classNames(style.wrapper, style.productDetails)}
    id={'product-details'}
  >
    <div className={classNames(style.wrapper, style.productName)}>
      <ProductThumbnailImage
        thumbnail={thumbnail}
        title={name}
        clickable={false}
      />
      <p className={style.text}>{name}</p>
    </div>
    <span className={classNames(style.text, style.producer)}>
      {!!producer && (
        <>
          <p>Producent:</p> <Tag value={producer} />
        </>
      )}
    </span>
  </div>
));

export default ProductDetails;
