import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Select } from 'components';

import style from './CompanySelect.module.scss';

const options = [
  { label: 'Wszystkie oddziały', value: 'ALL' },
  { label: 'Wybrane oddziały', value: 'SELECTED' }
];

const CompanySelect = ({ selectedCompanies, setSelectedCompanies }) => {
  const formattedCompanies = useSelector((state) => state.auth.formattedCompanies);
  const [selectedType, setSelectedType] = useState(options[0]);

  if (!formattedCompanies || formattedCompanies?.length < 2) {
    return null;
  }

  const onTypeChange = (e) => {
    if (e.value === 'ALL') setSelectedCompanies(formattedCompanies);
    setSelectedType(e);
  };

  return (
    <>
      <Select
        options={options}
        isClearable={false}
        isSearchable={false}
        value={selectedType}
        onChange={onTypeChange}
        label={'Zakres badania'}
        placeholder={'Wybierz zakres...'}
        wrapperStyle={style.shortSelect}
      />
      {selectedType.value === 'SELECTED' && (
        <Select
          options={formattedCompanies}
          value={selectedCompanies}
          onChange={setSelectedCompanies}
          isDisabled={formattedCompanies.length < 2}
          placeholder={'Wybierz pododdziały...'}
          label={'Badane oddziały' + (selectedCompanies?.length > 0 ? `(${selectedCompanies?.length})` : '')}
          wrapperStyle={style.container}
          isMulti
        />
      )}
    </>
  );
};

export default CompanySelect;
