import React from 'react';

import { FilterByCategory, FilterByProducer, Searchbar } from 'components';

import style from '../../SuppliersManagement.module.scss';

const Filtering = ({ params }) => {
  return (
    <div className={style.filtering}>
      <Searchbar
        fullWidth
        params={params}
      />
      <FilterByProducer
        fullWidth
        params={params}
      />
      <FilterByCategory
        fullWidth
        params={params}
        showAsTree
      />
    </div>
  );
};

export default Filtering;
