import React, { useEffect, useState } from 'react';
import ImageMapper from 'react-img-mapper';

import { Modal, StickyPageHeader } from 'components';

import ReceptionOverview from './components/ReceptionOverview';
import RoomOverview from './components/RoomOverview';
import WarehouseOverview from './components/WarehouseOverview';

import styles from './FloorPlan.module.scss';

const htmlString = `
<map name="image-map">
    <area target="" alt="Magazyn" title="Magazyn" href="magazyn" coords="264,385,364,452" shape="rect">
    <area id="1" target="" alt="Gabinet 1" title="Gabinet 1" href="gabinet" coords="77,72,212,217"  shape="rect">
    <area id="2" target="" alt="Gabinet 2" title="Gabinet 2" href="gabinet" coords="220,72,355,217" shape="rect">
    <area id="3" target="" alt="Gabinet 3" title="Gabinet 3" href="gabinet" coords="37,572,188,572,200,640,139,702,36,703" shape="poly">
    <area target="" alt="Recepcja" title="Recepcja" href="recepcja" coords="512,457,649,457,650,705,508,704,506,635,479,609,450,609,450,543,511,545" shape="poly">
</map>
`;

function htmlToReactImgMapperJson(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const areaElements = doc.querySelectorAll('area');

  const areas = Array.from(areaElements).map((area) => ({
    shape: area.getAttribute('shape'),
    coords: area.getAttribute('coords').split(',').map(Number),
    href: area.getAttribute('href'),
    alt: area.getAttribute('alt'),
    title: area.getAttribute('title'),
    ...(area.id && { id: area.id })
  }));

  return {
    name: doc.querySelector('map').getAttribute('name'),
    areas: areas
  };
}

const calculateCenter = (shape, coords) => {
  switch (shape) {
    case 'rect': {
      return {
        x: (coords[0] + coords[2]) / 2,
        y: (coords[1] + coords[3]) / 2
      };
    }
    case 'circle': {
      return {
        x: coords[0],
        y: coords[1]
      };
    }
    case 'poly': {
      let totalX = 0;
      let totalY = 0;
      let minX = Infinity;
      let maxX = -Infinity;
      const numPoints = coords.length / 2;

      for (let i = 0; i < coords.length; i += 2) {
        const x = coords[i];
        const y = coords[i + 1];
        totalX += x;
        totalY += y;
        minX = Math.min(minX, x);
        maxX = Math.max(maxX, x);
      }

      let centerX = totalX / numPoints;
      let centerY = totalY / numPoints;

      const width = maxX - minX;
      const dynamicCorrectionFactor = width * 0.05;
      centerX -= dynamicCorrectionFactor;

      return { x: centerX, y: centerY };
    }
    default: {
      return { x: 0, y: 0 };
    }
  }
};

const FloorPlan = () => {
  const [modal, setModal] = useState({
    visible: false,
    title: '',
    type: null
  });
  const [circlePositions, setCirclePositions] = useState([]);
  const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0, title: '' });
  const [roomId, setRoomId] = useState(null);

  const imageSrc = 'https://i.pinimg.com/736x/e7/08/c8/e708c822c8d091a8208e5cfeaf70e4b1.jpg';
  const json = htmlToReactImgMapperJson(htmlString);

  const handleAreaClick = (area) => {
    switch (area.href) {
      case 'magazyn':
        setModal({
          visible: true,
          title: area.title,
          type: 'magazyn'
        });
        break;
      case 'gabinet':
        setModal({
          visible: true,
          title: area.title,
          type: 'gabinet'
        });
        setRoomId(Number(area.id));
        break;
      case 'recepcja':
        setModal({
          visible: true,
          title: area.title,
          type: 'recepcja'
        });
        break;
      default:
        break;
    }
  };

  const handleMouseEnter = (area, _, event) => {
    setTooltip((prevTooltip) => ({
      ...prevTooltip,
      visible: true,
      x: event.clientX + 15,
      y: event.clientY + 15,
      title: area.title
    }));
  };

  const handleMouseLeave = () => {
    setTooltip((prevTooltip) => ({
      ...prevTooltip,
      visible: false
    }));
  };

  const handleMouseMove = (_, __, event) => {
    setTooltip((prevTooltip) => ({
      ...prevTooltip,
      x: event.clientX + 15,
      y: event.clientY + 15
    }));
  };

  useEffect(() => {
    const positions = json.areas.map((area) => {
      const coords = area.coords.map(Number);
      return calculateCenter(area.shape, coords);
    });
    setCirclePositions(positions);
  }, []);

  return (
    <div className={styles.container}>
      <StickyPageHeader name={'Plan kliniki'} />
      <div className={styles.mapWrapper}>
        <div className={styles.map}>
          <ImageMapper
            src={imageSrc}
            map={json}
            fillColor={'rgba(62, 135, 126, 0.75)'}
            onClick={(area) => handleAreaClick(area)}
            onMouseEnter={(area, _, event) => handleMouseEnter(area, _, event)}
            onMouseMove={(area, _, event) => handleMouseMove(area, _, event)}
            onMouseLeave={handleMouseLeave}
          />
          {tooltip.visible && (
            <div
              className={styles.tooltip}
              style={{
                left: tooltip.x,
                top: tooltip.y
              }}
            >
              {tooltip.title}
            </div>
          )}
          {circlePositions.map((pos, index) => (
            <div
              key={index}
              className={styles.pulseCircle}
              style={{
                left: `${pos.x - 10}px`,
                top: `${pos.y - 10}px`
              }}
            ></div>
          ))}
        </div>
      </div>
      <Modal
        visible={modal.visible}
        onClose={() =>
          setModal({
            visible: false,
            title: '',
            type: null
          })
        }
        title={modal.title}
      >
        {modal.type === 'magazyn' && <WarehouseOverview />}
        {modal.type === 'gabinet' && <RoomOverview roomId={roomId} />}
        {modal.type === 'recepcja' && <ReceptionOverview />}
      </Modal>
    </div>
  );
};

export default FloorPlan;
