import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { Button, EmptyState, LoaderGlobal, PageHeader, SeoHelmet, useIsUserAuth } from 'components';
import { notifyApiError, notifyDanger } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { orderStatuses } from 'src/constants/enums';
import { getOrderStatusTag } from 'src/utils/helpers';

import AddOrderTracking from '../../components/AddOrderTracking';
import ContactWithClient from '../../components/ContactWithClient';
import NewQuantityProducts from '../../components/NewQuantityProducts';
import SupplierCart from '../../components/OrderCart/containers/SupplierCart';
import OrderDetails from '../../components/OrderDetails';
import SendOrder from '../../components/SendOrder';
import UserComment from '../../components/UserComment';

import style from '../../orderConfirmations.module.scss';

const ConfirmOrder = () => {
  const { uid } = useParams();
  const isAuth = useIsUserAuth();
  const history = useHistory();
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isContactOpen, setIsContactOpen] = useState(false);

  const getData = async () => {
    try {
      const { data } = await SupplierApi.getOrder({ uid });
      setPageData(data);
    } catch (err) {
      notifyApiError(err);
      notifyDanger(['Wystąpił problem z zamówieniem. Skontaktuj się z nami, w celu rozwiązania problemów.']);
      history.push('/dashboard');
    }
  };

  const getDataWithLoading = async () => {
    setIsLoading(true);
    await getData();
    setIsLoading(false);
  };

  const orderEmptyStateValue = useMemo(() => {
    switch (pageData?.status) {
      case orderStatuses.cancelled:
        if (pageData?.message === 'Użytkownik anulował zamówienie') {
          return 'supplierOrderCancelledByUser';
        }
        return 'supplierOrderCancelled';
      case orderStatuses.sent:
        return 'supplierOrderSent';
      case orderStatuses.completed:
        return 'supplierOrderCompleted';
      default:
        return false;
    }
  }, [pageData]);

  const orderName = useMemo(
    () =>
      pageData.order?.invoice_details?.company_name
        ? `Zamówienie dla: ${pageData.order?.invoice_details?.company_name}`
        : `Zamówienie numer: #${pageData.public_id}`,
    [pageData]
  );

  useEffect(() => {
    getDataWithLoading();
  }, [history.location.pathname]);

  if (isLoading) {
    return <LoaderGlobal />;
  }

  if (orderEmptyStateValue === 'supplierOrderCancelledByUser') {
    return (
      <EmptyState
        type={orderEmptyStateValue}
        tiny
      />
    );
  }

  return (
    <>
      <SeoHelmet title={orderName || 'Zamówienie'} />
      <PageHeader
        name={orderName}
        isLoading={isLoading}
        metaData={{
          created_at: pageData?.created_at,
          order_id: pageData?.public_id
        }}
        text={getOrderStatusTag({ status: pageData.status })}
        textCenter
      >
        <Button
          label={'Skontaktuj się z klientem'}
          onClick={() => setIsContactOpen(true)}
        />
      </PageHeader>
      <ContactWithClient
        isOpen={isContactOpen}
        setIsOpen={setIsContactOpen}
        details={pageData?.order?.shipping_details}
      />
      <div className={style.container}>
        {!!orderEmptyStateValue && (
          <div className={style.wrapper}>
            <EmptyState
              type={orderEmptyStateValue}
              tiny
            />
          </div>
        )}
        <OrderDetails
          refreshDataCallback={getData}
          data={pageData}
        />
        <AddOrderTracking
          uid={uid}
          data={pageData}
          refreshDataCallback={getData}
        />
        <NewQuantityProducts
          list={pageData.products}
          status={pageData.status}
          refreshDataCallback={getData}
        />
        <UserComment comment={pageData.user_comment} />
        <SupplierCart
          refreshDataCallback={getData}
          pageData={pageData}
          uid={uid}
        />
      </div>
      {!!pageData?.supplier_name && (
        <div className={style.supplierInfo}>
          Dostawca: {pageData.supplier_name}
          <br />
          {import.meta.env.VITE_APP_VERSION}
        </div>
      )}
    </>
  );
};

export default ConfirmOrder;
