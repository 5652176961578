import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { AsyncSelect, useAuthUser } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import { userRoles } from 'src/constants/enums';
import { compareArrays, formatCompanyValue } from 'src/utils/helpers';

import style from '../Filtering.module.scss';

const FilterByCompany = ({
  params,
  fullWidth,
  valueKey = 'id',
  labelKey = 'name',
  isMulti,
  className,
  label,
  onChange,
  value
}) => {
  const user = useAuthUser();
  const paramValue = params?.get('company') || value;
  const [selectedCompany, setSelectedCompany] = useState(isMulti ? [] : null);

  const setSingleValue = (company) => {
    const areValueTheSame = company?.value === paramValue;

    if (onChange) {
      onChange(company?.value);
      return;
    }

    if (!areValueTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'company', value: company?.value },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('company', company?.value);
      }
    }
  };

  const setMultiValue = (companies) => {
    const idsArray = companies?.map((comp) => comp.value);
    const areArrayTheSame = compareArrays(idsArray, paramValue);

    if (onChange) {
      onChange(idsArray);
      return;
    }

    if (!areArrayTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'company', value: idsArray },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('company', idsArray);
      }
    }
  };

  const onChangeHandler = (e) => {
    setSelectedCompany(e);
    if (isMulti) {
      setMultiValue(e);
    } else {
      setSingleValue(e);
    }
  };

  useEffect(() => {
    const fetchCompany = (id = paramValue) => AdminApi.getCompanyDetails(id);

    const getSelectedCompany = async () => {
      try {
        const { data } = await fetchCompany();
        setSelectedCompany(formatCompanyValue(data));
      } catch (err) {
        notifyApiError(err);
      }
    };

    const getDataForAllCompanies = async () => {
      try {
        const results = await Promise.all(paramValue.map((param) => fetchCompany(param)));
        setSelectedCompany(results.map((result) => formatCompanyValue(result.data)));
      } catch (err) {
        notifyApiError(err);
      }
    };

    if (paramValue) {
      if (Array.isArray(paramValue)) getDataForAllCompanies();
      else getSelectedCompany();
    }
  }, [value]);

  if (user?.role !== userRoles.admin) {
    return null;
  }

  return (
    <AsyncSelect
      value={selectedCompany}
      onChange={onChangeHandler}
      apiCallback={AdminApi.getCompanies}
      queryParams={{ show_deactivated: 0 }}
      valueKey={valueKey}
      labelKey={labelKey}
      label={label}
      placeholder={'Firma'}
      wrapperStyle={classNames(
        style.select,
        {
          [style.selected]: !!selectedCompany,
          [style.fullWidth]: fullWidth
        },
        className
      )}
      isClearable
      isMulti={isMulti}
    />
  );
};

export default FilterByCompany;
