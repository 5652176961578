import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { PublicHeader, useIsUserAuth } from 'components';

import SupplierForm from 'src/features/SupplierForm';

import SupplierRegister from '../onboarding/containers/SupplierRegister';

import style from './SupplierFormPage.module.scss';

const SupplierFormPage = () => {
  const isAuth = useIsUserAuth();
  const history = useHistory();
  const params = useParams();

  const token = useSelector((state) => state.supplierForm.token);

  useLayoutEffect(() => {
    if (isAuth) {
      history.push(`/supplier/valuations/${params?.uid}`);
    }
  }, [isAuth]);

  return (
    <>
      <PublicHeader supplierForm />
      <SupplierRegister
        supplierForm
        token={token}
      />
      <div className={style.container}>
        <SupplierForm inPublicPage />
      </div>
    </>
  );
};

export default SupplierFormPage;
