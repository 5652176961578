import React from 'react';

import { toDateNumber } from 'src/utils/dateTime';

import LineChartComponent from '../../../Analytics/components/LineChart';

import style from './PriceList.module.scss';

const PriceList = ({ prices = [] }) => {
  const chartData = prices.map((price) => ({
    price: price.price,
    date: price.created_at
  }));

  const catCallback = (data) => data?.map((item) => toDateNumber(item.date));

  return (
    <div className={style.container}>
      <LineChartComponent
        data={chartData}
        seriesKey={'price'}
        categoriesFn={catCallback}
      />
    </div>
  );
};

export default PriceList;
