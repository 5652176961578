import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAuthUser } from 'components';

import { SocketClient } from 'src/api/sockets/client';
import { userRoles } from 'src/constants/enums';

const SocketsConnect = () => {
  const store = useSelector((state) => state.auth);
  const user = useAuthUser();

  useEffect(() => {
    const isAdminViewLoaded = store.isAdminView && store.user?.role === userRoles.admin;

    if (!!user && user.role !== userRoles.admin && !window.pusher && !isAdminViewLoaded) {
      window.pusher = SocketClient();
      console.log('SocketClient created');
    }
  }, [user, store.access, store]);

  return null;
};

export default SocketsConnect;
