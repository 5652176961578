import React, { useMemo } from 'react';
import classNames from 'classnames';

import { Tag, useAuthUser } from 'components';

import { userRoles } from 'src/constants/enums';
import { getRoleColor } from 'src/utils/helpers';

import style from './UserData.module.scss';
const roleDictionary = {
  [userRoles.admin]: 'Administrator',
  [userRoles.user]: 'Użytkownik',
  [userRoles.supplier]: 'Dostawca',
  [userRoles.distributor]: 'Dystrybutor'
};

const UserData = ({ inProfile }) => {
  const user = useAuthUser();

  const userRoleTag = useMemo(
    () => (
      <Tag
        value={roleDictionary[user.role]}
        color={getRoleColor(user.role)}
        className={style.tag}
      />
    ),
    [user.role]
  );

  return (
    <div
      className={classNames(style.container, {
        [style.inProfile]: inProfile
      })}
    >
      {userRoleTag}
      <h2 className={style.username}>{user.name}</h2>
      <p>{user.email}</p>
    </div>
  );
};

export default UserData;
