import * as types from './types';

export const selectProduct = (id) => ({
  type: types.SELECT_PRODUCT,
  payload: id
});

export const deleteProduct = (id) => ({
  type: types.DELETE_PRODUCT,
  payload: id
});

export const selectProductArray = (ids) => ({
  type: types.SELECT_PRODUCTS_ARRAY,
  payload: ids
});

export const deleteAll = () => ({
  type: types.DELETE_ALL,
  payload: null
});

export const refreshListView = () => ({
  type: types.REFRESH_LIST_VIEW
});
