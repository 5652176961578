import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Select, useIsMobile, useSearchParam } from 'components';

import style from './PaginationNav.module.scss';

const perPage = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: '200', value: 200 }
];

const PaginationNav = ({
  pagesQty = 5,
  params,
  setPerPage,
  defaultQty = 25,
  setPage,
  hideSelect,
  page = 0,
  className,
  hidePadding,
  label = 'Wyników na stronie',
  onlyLimit,
  options = perPage
}) => {
  const [perPageQty, setPerPageQty] = useState({
    label: defaultQty,
    value: defaultQty
  });
  const [currentPage, setCurrentPage] = useState(params?.get('page') || page || 1);
  const [buttons, setButtons] = useState([]);
  const isMobile = useIsMobile();

  const topParam = useSearchParam(params, 'top');

  useEffect(() => {
    const buttons = [];

    for (let i = 1; i <= pagesQty; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => {
            params?.set('page', i);
            if (setPage) setPage(i);
          }}
          className={classNames(style.button, {
            [style.active]: Number(currentPage) === i
          })}
        >
          {i}
        </button>
      );
    }

    if (buttons.length > 6) {
      const page = Number(currentPage) || 1;

      if (page <= 3) {
        setButtons([
          buttons[0],
          buttons[1],
          buttons[2],
          buttons[3],
          <p key={'pagination-middle'}>...</p>,
          buttons[buttons.length - 1]
        ]);
      } else if (page > buttons.length - 3) {
        setButtons([
          buttons[0],
          <p key={'pagination-middle'}>...</p>,
          buttons[buttons.length - 4],
          buttons[buttons.length - 3],
          buttons[buttons.length - 2],
          buttons[buttons.length - 1]
        ]);
      } else {
        if (isMobile) {
          setButtons([
            buttons[0],
            <p key={'pagination-middle1'}>...</p>,
            buttons[page - 2],
            buttons[page - 1],
            buttons[page],
            <p key={'pagination-middle2'}>...</p>,
            buttons[buttons.length - 1]
          ]);
        } else {
          setButtons([
            buttons[0],
            <p key={'pagination-middle1'}>...</p>,
            buttons[page - 3],
            buttons[page - 2],
            buttons[page - 1],
            buttons[page],
            buttons[page + 1],
            <p key={'pagination-middle2'}>...</p>,
            buttons[buttons.length - 1]
          ]);
        }
      }
    } else {
      setButtons(buttons);
    }
  }, [params, currentPage, isMobile, page, pagesQty]);

  useEffect(() => {
    if (params && params?.get('page') !== currentPage) {
      setCurrentPage(params?.get('page') || 1);
    }
  }, [params]);

  useEffect(() => {
    if (page >= 1 && page !== currentPage) {
      setCurrentPage(page);
    }
  }, [page]);

  useEffect(() => {
    setPerPage(perPageQty.value);
  }, [perPageQty]);

  const select = (
    <div className={style.select}>
      <Select
        options={options}
        value={perPageQty}
        label={label}
        isSearchable={false}
        onChange={(e) => {
          setPerPageQty(e);
          if (!onlyLimit) params?.set('page', '1');
        }}
      />
    </div>
  );

  if (topParam) {
    return null;
  }

  if (onlyLimit) {
    return select;
  }

  return (
    <div className={classNames(style.container, { [style.hidePadding]: hidePadding }, className)}>
      <div className={style.wrapper}>{!hideSelect && select}</div>
      <div className={style.wrapper}>{buttons}</div>
    </div>
  );
};

export default PaginationNav;
