import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import Payments from './components/Payments';
import Shipments from './components/Shipments';
import ShippingPrice from './components/ShippingPrice';

import style from './OrderDetails.module.scss';

const OrderDetails = () => {
  const params = useParams();
  const disabled = useSelector((state) => state.supplierForm.isDisabled);

  return (
    <section className={style.container}>
      <Payments disabled={disabled} />
      <Shipments
        disabled={disabled}
        uid={params.uid}
      />
      <ShippingPrice disabled={disabled} />
    </section>
  );
};

export default OrderDetails;
