import React, { useMemo } from 'react';

import { useCompany, useIsAdmin } from 'components';

const UsePermissions = (requiredPermissions = []) => {
  const company = useCompany();
  const isAdmin = useIsAdmin();

  const isAllowed = useMemo(() => {
    if (requiredPermissions.length === 0 || isAdmin) {
      return true;
    }
    const checkIsAllowed = (permission) => company && company.permissions.includes(permission);
    let isAllowed = false;

    requiredPermissions.forEach((perm) => {
      const result = checkIsAllowed(perm);
      if (result) isAllowed = result;
    });

    return isAllowed;
  }, [company, requiredPermissions, isAdmin]);

  return [isAllowed, company?.permissions || []];
};

export default UsePermissions;
