import React, { useMemo, useState } from 'react';

import { Button, EmptyState, FileDropzone, Modal, useIsAdmin, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ServicesApi } from 'src/api';

import AttachmentThumbnail from '../AttachmentThumbnail';

import style from './EventAttachments.module.scss';

const EventAttachments = ({ eventId, files = [], refreshCallback, visible, setVisible, selectedCompany }) => {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [file, setFile] = useState(null);
  const validator = useValidator();
  const isAdmin = useIsAdmin();

  const label = useMemo(() => {
    if (files.length === 0) {
      return 'Brak';
    } else if (files.length === 1) {
      return files[0].file_name;
    } else {
      return `${files[0].file_name} + ${files.length - 1} więcej...`;
    }
  }, [files]);

  const uploadAttachment = async () => {
    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const queryParams = {
      service_event_id: eventId,
      file,
      ...(isAdmin && { company_id: Number(selectedCompany) })
    };

    try {
      setIsFileUploading(true);
      await ServicesApi.uploadEventFile(queryParams);
      setFile(null);
      validator.purgeFields();
      if (refreshCallback) refreshCallback();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsFileUploading(false);
    }
  };

  return (
    <>
      <a onClick={() => setVisible(true)}>{label}</a>
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        title={'Dodaj załącznik'}
      >
        <div className={style.container}>
          <div className={style.box}>
            <FileDropzone
              onChange={setFile}
              value={file}
              id={'logo'}
              name={'logo'}
              validator={validator}
              rule={'required'}
            />
            <Button
              label={'Dodaj załącznik'}
              onClick={uploadAttachment}
              isLoading={isFileUploading}
            />
          </div>
          <h3>Lista załączników</h3>
          {files.map((file) => (
            <AttachmentThumbnail
              key={file.id}
              eventId={eventId}
              refreshCallback={refreshCallback}
              {...file}
            />
          ))}
          {files.length === 0 && (
            <EmptyState
              tiny
              type={'eventsAttachments'}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default EventAttachments;
