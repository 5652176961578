import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { CommentModal, Menu, Modal, RecentlyOrdered, useDepartments, useIsAdmin, useIsMobile, usePermissions } from 'components';
import AddProductToList from 'components/layout/AddProductToList';
import PricesCounter from 'components/layout/PricesCounter';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';

import { refreshListView, selectProduct } from '../../actions';
import ListViewItemDepartment from '../ListViewItemDepartment';
import ProductCommentForm from '../ProductCommentForm';
import SetQuantity from '../SetQuantity';

import style from './ListViewItem.module.scss';

const ListViewItem = ({ item, uid, index, recentlyOrdered, children }) => {
  const { id, producer, name, qty, unit_name, thumbnail, image, ref_price, comment, prices_count, departments } = item || {};

  const [isModalAddToListOpen, setIsModalAddToListOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [canCreateLists] = usePermissions([userPermissions.lists.create]);
  const container = useRef(null);
  const isMobile = useIsMobile(767);
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  const myDepartments = useDepartments();
  const hasDepartments = departments.length > 0;

  const clearTabindexEffectHandler = () => {
    if (container.current?.hasAttribute('tabindex')) {
      container.current?.removeAttribute('tabIndex');
    }
  };

  const deleteProductHandler = async () => {
    try {
      await ListsApi.deleteProduct(uid, [id]);
      notifyCommon(['Produkt został usunięty.']);
      dispatch(refreshListView());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const findUnusedDepartments = () => {
    return myDepartments.departments.filter((item) => !departments.map((i) => i.id).includes(item.id)) || [];
  };

  const addProductDepartment = async () => {
    const unused = findUnusedDepartments();
    if (unused.length === 0) return;

    const data = {
      list_uid: uid,
      product_id: id,
      department_id: unused[0].id,
      quantity: hasDepartments ? 1 : qty
    };

    try {
      await ListsApi.setProductDepartment(data);
      dispatch(refreshListView());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const actions = useMemo(() => {
    const unusedDepartments = findUnusedDepartments();
    const canSetDepartment = canCreateLists && unusedDepartments.length > 0;

    const menu = [
      {
        title: `${comment ? 'Edytuj' : 'Dodaj'} komentarz`,
        action: () => setIsModalOpen(true),
        roles: ['admin', 'user'],
        icon: 'message'
      },
      {
        title: 'Dodaj do listy',
        icon: 'add',
        action: () => setIsModalAddToListOpen(true)
      },
      canSetDepartment
        ? {
            title: 'Przydziel jednostce',
            icon: 'move',
            action: addProductDepartment
          }
        : undefined,
      {
        title: 'Usuń z listy',
        action: deleteProductHandler,
        icon: 'trash',
        color: 'red'
      }
    ].filter((i) => i !== undefined);

    return menu;
  }, [myDepartments, departments]);

  useEffect(() => {
    dispatch(selectProduct(id));
  }, []);

  return (
    <div
      className={style.container}
      id={`list-product-${id}`}
      onClick={clearTabindexEffectHandler}
      ref={container}
    >
      <div className={style.item}>
        <div className={style.checkbox}>{children || <p>{index + 1}.</p>}</div>
        <div className={style.wrapper}>
          <ProductThumbnailImage
            thumbnail={thumbnail}
            image={image}
            title={name}
            id={id}
          />
          <p className={style.text}>{name}</p>
          <PricesCounter count={prices_count} />
          <RecentlyOrdered data={recentlyOrdered} />
        </div>
        <div className={style.wrapper}>
          {isMobile && <p className={style.text}>Producent: </p>}
          <p className={style.text}>{producer}</p>
        </div>
        <div className={style.wrapper}>
          {isMobile && <p className={style.text}>Komentarz: </p>}
          <CommentModal
            comment={comment}
            modalTitle={'Komentarz do produktu ' + name}
          />
        </div>
        <div className={style.wrapper}>
          {isMobile && <p className={style.text}>Ilość: </p>}
          <SetQuantity
            uid={uid}
            id={id}
            combinedQuantity={qty}
            unitName={unit_name}
            disabled={hasDepartments}
          />
        </div>
        <div className={classNames(style.wrapper, style.menuWrapper)}>
          <Menu
            actions={actions}
            className={style.menu}
          />
        </div>
      </div>

      {departments.length > 0 && (
        <div className={style.departments}>
          {departments.map((department) => (
            <ListViewItemDepartment
              uid={uid}
              product={item}
              department={department}
              key={`listViewItemDepartment-${department.products_lists_departments_id}`}
            />
          ))}
        </div>
      )}

      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Dodaj komentarz do produktu ${name}`}
      >
        <ProductCommentForm
          product={item}
          uid={uid}
          closeModal={() => setIsModalOpen(false)}
        />
      </Modal>
      <AddProductToList
        title={`Dodaj "${name}" do listy`}
        visible={isModalAddToListOpen}
        onClose={() => setIsModalAddToListOpen(false)}
        productId={id}
      />
    </div>
  );
};

export default ListViewItem;
