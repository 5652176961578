import React from 'react';

import HorizontalList from '../../components/HorizontalList';

import style from '../../ValuationView.module.scss';

const HorizontalValuation = ({ columns, pageData, changeValuationSelections, showRefPrice }) => {
  return (
    <div className={style.contentWrapper}>
      <HorizontalList
        pageData={pageData}
        columns={columns}
        valuationEdit
        changeValuationSelections={changeValuationSelections}
        showRefPrice={showRefPrice}
      />
    </div>
  );
};

export default HorizontalValuation;
