import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  EmptyState,
  Menu,
  NotificationCard,
  PaginationScroll,
  ProfileSection as Section,
  useRequestAbortController
} from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { UserApi } from 'src/api';

import { setAllNotificationsAsRead } from '../../../NotificationsStore/actions';

import style from './NotificationsCenter.module.scss';

const QUERY_LIMIT = 5;

const NotificationsCenter = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [abortController, setNewController] = useRequestAbortController();
  const store = useSelector((state) => state.notifications);
  const { notifications: newNotifications } = store;
  const dispatch = useDispatch();

  const newNotificationsIDs = useMemo(() => newNotifications.map((ntf) => ntf.id), [newNotifications.length]);

  const getData = async (page, clearData) => {
    const queryParams = {
      page: page || 1,
      perPage: QUERY_LIMIT
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await UserApi.getNotifications(queryParams, signal);

      const ntfs = data.data.map((ntf) => ({ ...ntf, ...ntf.data }));
      const filteredNtfs = ntfs.filter((ntf) => !newNotificationsIDs.includes(ntf.id));

      if (clearData || data.data.length === 0) {
        setNotifications(filteredNtfs);
        setHasMore(data.data.length < data.total);
      } else {
        setNotifications((prev) => [...prev, ...filteredNtfs]);
        setHasMore(data.data.length + notifications.length < data.total);
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreResults = () => {
    if (!isLoading) {
      getData(page + 1);
      setPage(page + 1);
    }
  };

  const handleReadAllNotifications = async () => {
    try {
      await UserApi.setAllNotificationsAsRead();
      dispatch(setAllNotificationsAsRead());
      await getData();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const actions = [
    {
      title: 'Oznacz jako przeczytane',
      icon: 'check',
      action: handleReadAllNotifications
    }
  ];

  useEffect(() => {
    getData();
  }, []);

  if (notifications.length === 0 && !isLoading) {
    return (
      <Section title={'Centrum powiadomień'}>
        <div className={style.container}>
          <EmptyState type={'notificationsCenter'} />
        </div>
      </Section>
    );
  }

  return (
    <Section
      title={'Centrum powiadomień'}
      button={
        <Menu
          actions={actions}
          className={style.menu}
        />
      }
    >
      <div className={style.container}>
        <div className={style.wrapper}>
          {newNotifications.length > 0 && <h2 className={style.title}>Nowe</h2>}
          {newNotifications?.map((el) => (
            <NotificationCard
              key={el.id}
              notification={el}
            />
          ))}
        </div>
        <h2 className={style.title}>Wcześniejsze</h2>
        <PaginationScroll
          data={notifications}
          hasMore={hasMore}
          page={page}
          loadMoreResults={loadMoreResults}
          emptyState={<EmptyState type={'notificationsCenter'} />}
        >
          <div className={style.wrapper}>
            {notifications?.map((el) => (
              <NotificationCard
                key={el.id}
                notification={el}
              />
            ))}
          </div>
        </PaginationScroll>
      </div>
    </Section>
  );
};

export default NotificationsCenter;
