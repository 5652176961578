import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Select, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';

import { refreshCategories } from '../../actions';

import style from './MoveProducts.module.scss';

const MoveProducts = ({ id, name, closeModal, categoryTree = [] }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const validator = useValidator();

  const moveCategoryProductsHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const data = {
      category_id_from: id,
      category_id_to: selectedCategory?.value
    };

    try {
      setIsLoading(true);
      await AdminApi.moveCategoryProducts(data);
      notifyCommon([`Produkty kategorii ${name} zostały przeniesione do ${selectedCategory?.label}.`]);
      dispatch(refreshCategories());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const options = useMemo(() => categoryTree.map((cat) => ({ value: cat.id, label: cat.name })), [categoryTree]);

  return (
    <form
      className={style.container}
      onSubmit={moveCategoryProductsHandler}
    >
      <Select
        options={options}
        id={'move-select'}
        wrapperStyle={style.select}
        value={selectedCategory}
        onChange={setSelectedCategory}
        label={'Przenieś produkty do: '}
        placeholder={'Kategoria'}
        validator={validator}
        rule={'required'}
        isClearable
      />
      <Button
        label={'Zapisz'}
        title={'Zapisz'}
        type={'submit'}
        className={style.button}
        isLoading={isLoading}
      />
    </form>
  );
};

export default MoveProducts;
