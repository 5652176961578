import React, { useEffect, useState } from 'react';

import { CompanyDetails, Loader, ProfileSection as Section } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { CompanyApi } from 'src/api';

import style from './CompaniesManagement.module.scss';

const CompaniesManagement = () => {
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [version, setVersion] = useState(0);

  const getAllCompanies = async () => {
    try {
      setIsLoading(true);
      const { data } = await CompanyApi.getAllCompanies();
      setCompanies(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCompanies();
  }, [version]);

  const addCompanyButton = (
    <CompanyDetails
      asAddNewButton
      refreshCb={() => setVersion((prev) => prev + 1)}
      isLoading={isLoading}
    />
  );

  return (
    <Section
      title={'Zarządzanie oddziałami '}
      button={addCompanyButton}
    >
      <div className={style.container}>
        {isLoading ? (
          <Loader center />
        ) : (
          <div className={style.companies}>
            {companies.map((company, index) => (
              <CompanyDetails
                companyManagement
                details={company}
                key={company.id}
                renderChildren
                index={index}
                refreshCb={() => setVersion((prev) => prev + 1)}
              />
            ))}
          </div>
        )}
      </div>
    </Section>
  );
};

export default CompaniesManagement;
