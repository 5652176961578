import React, { useState } from 'react';
import classnames from 'classnames';

import { Modal, ProductDetails, useIsAdmin, usePermissions } from 'components';

import { supplierTypes, userPermissions } from 'src/constants/enums';

import AddNewPrice from '../../../AddNewPrice';
import OfferWrapper from '../../../OfferWrapper';
import OtherSuppliers from '../../../OtherSuppliers';
import ProductReplacementsButton from '../../../ProductReplacementsButton';
import RefPrice from '../../../RefPrice';
import SelectedSupplierWrapper from '../../../SelectedSupplierWrapper';
import SuppliersList from '../../../SuppliersList';
import BestOffers from '../../components/BestOffers';
import ProductMenu from '../../components/ProductMenu';

import style from '../../Product.module.scss';

const ProductWithSelectedSuppliers = ({
  product,
  className,
  isCatalogView,
  recentlyOrdered,
  selectedCompany,
  showRefPrice = false,
  changeValuationSelections,
  isQuietLoading
}) => {
  const isAdmin = useIsAdmin();
  const { ref_price: refPrice } = product || {};
  const [isOpen, setIsOpen] = useState(false);

  const boxClasses = classnames(style.box, { [style.withRefPrice]: showRefPrice });
  const [canMenageCatalog] = usePermissions([userPermissions.offer_catalog.menage]);

  const primary = product.prices.find((sup) => sup.priority === supplierTypes.primary);
  const secondary = product.prices.find((sup) => sup.priority === supplierTypes.secondary);
  const third = product.prices.find((sup) => sup.priority === supplierTypes.third);

  const primaryWithOptions = (product.prices.length > 0 && !secondary) || (product.prices.length > 1 && secondary);
  const secondaryWithOptions = (product.prices.length > 0 && !primary) || (product.prices.length > 1 && primary);
  const thirdWithOptions =
    (product.prices.length > 0 && !primary && !secondary) || (product.prices.length > 2 && primary && secondary);

  return (
    <>
      <div className={boxClasses}>
        <ProductReplacementsButton
          product={product}
          isCatalogView={isCatalogView}
          selectedCompany={selectedCompany}
        />
        <ProductDetails
          className={className}
          product={product}
          showRefPrice={showRefPrice}
          orderedDate={recentlyOrdered}
          withoutSavings
          autoSize
          showTags={isCatalogView}
        />
        <RefPrice
          price={refPrice}
          quantity={product.quantity}
          showRefPrice={showRefPrice}
          selectedCompany={selectedCompany}
          productId={product.product_id}
          product={product}
        />
        <div className={style.supplierWrapperContainer}>
          <span className={style.mobileOfferLabel}>Oferta preferowana</span>
          <SelectedSupplierWrapper
            withOptions={canMenageCatalog ? primaryWithOptions : false}
            onClickEmpty={() => setIsOpen(true)}
          >
            {!!primary && (
              <div className={style.priceWrapper}>
                <OfferWrapper
                  item={primary}
                  product={product}
                  changeValuationSelections={changeValuationSelections}
                  selectedCompany={selectedCompany}
                  isCatalogView={isCatalogView}
                />
              </div>
            )}
          </SelectedSupplierWrapper>
        </div>
        <div className={style.supplierWrapperContainer}>
          <span className={style.mobileOfferLabel}>Oferta alternatywna</span>
          <SelectedSupplierWrapper
            withOptions={canMenageCatalog ? secondaryWithOptions : false}
            onClickEmpty={() => setIsOpen(true)}
          >
            {!!secondary && (
              <div className={style.priceWrapper}>
                <OfferWrapper
                  item={secondary}
                  product={product}
                  changeValuationSelections={changeValuationSelections}
                  selectedCompany={selectedCompany}
                  isCatalogView={isCatalogView}
                />
              </div>
            )}
          </SelectedSupplierWrapper>
        </div>
        <div className={style.supplierWrapperContainer}>
          <span className={style.mobileOfferLabel}>Oferta zapasowa</span>
          <SelectedSupplierWrapper
            withOptions={canMenageCatalog ? thirdWithOptions : false}
            onClickEmpty={() => setIsOpen(true)}
          >
            {!!third && (
              <div className={style.priceWrapper}>
                <OfferWrapper
                  item={third}
                  product={product}
                  changeValuationSelections={changeValuationSelections}
                  selectedCompany={selectedCompany}
                  isCatalogView={isCatalogView}
                />
              </div>
            )}
          </SelectedSupplierWrapper>
        </div>
        {canMenageCatalog && (
          <div className={style.buttonsWrapper}>
            <OtherSuppliers
              count={product.prices.length}
              onClick={() => setIsOpen(true)}
            />
            <AddNewPrice
              product={product}
              selectedCompany={selectedCompany}
            />
          </div>
        )}
        {isCatalogView && isAdmin && <BestOffers offers={product?.best_offers} />}
        <Modal
          visible={isOpen}
          onClose={() => setIsOpen(false)}
          title={'Wszystkie oferty'}
        >
          <SuppliersList
            product={product}
            changeValuationSelections={changeValuationSelections}
            selectedCompany={selectedCompany}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isQuietLoading={isQuietLoading}
          />
        </Modal>
        <ProductMenu
          selectedCompany={selectedCompany}
          product={product}
          isCatalogView={isCatalogView}
        />
      </div>
    </>
  );
};

export default ProductWithSelectedSuppliers;
