import React, { useLayoutEffect, useMemo, useState } from 'react';
import classnames from 'classnames';

import { Button, Modal, Select } from 'components';

import style from './ChangeUnit.module.scss';

const ChangeUnit = ({ onSave, currentUnit, units = [], disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [unit, setUnit] = useState(currentUnit);

  useLayoutEffect(() => {
    if (!unit) {
      setUnit(currentUnit);
    }
  }, [currentUnit]);

  const handleSaveUnit = () => {
    onSave(unit);
    setIsOpen(false);
  };

  if (!currentUnit) {
    return null;
  }

  return (
    <>
      <button
        disabled={disabled}
        onClick={() => setIsOpen(true)}
        className={classnames(style.button, {
          [style.disabled]: disabled
        })}
      >
        {currentUnit.label}
      </button>
      <Modal
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        title={'Wybierz jednostkę produktu'}
      >
        <div className={style.container}>
          <Button
            label={'Zapisz'}
            onClick={handleSaveUnit}
          />
          <Select
            options={units}
            value={unit}
            onChange={setUnit}
            placeholder={'Jednostka'}
            isSearchable={false}
          />
        </div>
      </Modal>
    </>
  );
};

export default ChangeUnit;
