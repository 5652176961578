import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  EmptyState,
  FilterByCompany,
  Modal,
  PaginationNav,
  Searchbar,
  SeoHelmet,
  StickyPageHeader,
  usePermissions,
  useRequestAbortController,
  useSearchParam
} from 'components';
import ColumnList from 'components/layout/ColumnList';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import pages from 'src/dictionaries/pages.json';
import ListThumbnail from 'src/features/Lists/components/ListThumbnail';
import query from 'src/utils/query';

import CreateList from './components/CreateList';
import MergeLists from './components/MergeLists';

import style from './Lists.module.scss';

const Lists = (props) => {
  const params = query(props);

  const [canCreateList] = usePermissions([userPermissions.lists.create]);
  const [pageData, setPageData] = useState();
  const [isAddingVisible, setIsAddingVisible] = useState(false);
  const [isMergingVisible, setIsMergingVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [search, setSearch] = useState();
  const [queryUser, setQueryUser] = useState();
  const [queryCompanyId, setQueryCompanyId] = useState();
  const [wasDataLoaded, setWasDataLoaded] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();

  const listVersion = useSelector((state) => state.lists.version);
  const openNewInvoiceModal = useSearchParam(params, 'new_element');

  const getData = async () => {
    let error;
    const params = {
      page: currentPage || 1,
      perPage,
      search,
      user: queryUser,
      company_id: queryCompanyId
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await ListsApi.getLists(params, signal);
      setPageData(data);
      setPageQty(data?.last_page);
      setIsLoading(false);
    } catch (err) {
      notifyApiError(err);
      error = err;
    } finally {
      setIsLoading(error?.message === 'canceled');
      if (!wasDataLoaded) setWasDataLoaded(true);
    }
  };

  useEffect(() => {
    getData();
  }, [listVersion, search, currentPage, perPage, queryUser, queryCompanyId]);

  useEffect(() => {
    setCurrentPage(params.get('page'));
    setSearch(params.get('search'));
    setQueryUser(params.get('user'));
    setQueryCompanyId(params.get('company'));
  }, [params]);

  useEffect(() => {
    if (openNewInvoiceModal) setIsAddingVisible(true);
  }, [openNewInvoiceModal]);

  useEffect(() => {
    if (openNewInvoiceModal) params.remove('new_element');
  }, [openNewInvoiceModal]);

  const headerContent = (
    <>
      <Searchbar params={params} />
      <FilterByCompany params={params} />
      {canCreateList && (
        <Button
          label={'Połącz'}
          onClick={() => setIsMergingVisible(true)}
          iconName={'add'}
          gray
        />
      )}
      {canCreateList && (
        <Button
          label={'Utwórz listę'}
          onClick={() => setIsAddingVisible(true)}
          iconName={'add'}
        />
      )}
    </>
  );

  return (
    <div className={style.container}>
      <SeoHelmet title={pages.lists.title} />
      <StickyPageHeader name={pages.lists.title}>{headerContent}</StickyPageHeader>
      <ColumnList
        isLoading={isLoading}
        list={pageData?.data}
        component={ListThumbnail}
        emptyState={<EmptyState type={params.get('search') ? 'search' : 'lists'} />}
      />
      <PaginationNav
        onClick={(number) => setCurrentPage(number)}
        params={params}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
      />
      <Modal
        visible={isAddingVisible}
        onClose={() => setIsAddingVisible(false)}
        title={'Utwórz listę'}
      >
        <CreateList closeModal={() => setIsAddingVisible(false)} />
      </Modal>
      <Modal
        visible={isMergingVisible}
        onClose={() => setIsMergingVisible(false)}
        title={'Połącz listy'}
      >
        <MergeLists closeModal={() => setIsMergingVisible(false)} />
      </Modal>
    </div>
  );
};

export default Lists;
