import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import { Icon, Loader } from 'components';

import { ReactComponent as Add } from 'src/assets/icons/add.svg';
import { ReactComponent as Download } from 'src/assets/icons/arrow-down-solid.svg';
import { ReactComponent as Bin } from 'src/assets/icons/bin.svg';
import { ReactComponent as Cancel } from 'src/assets/icons/cancel.svg';
import { ReactComponent as Tick } from 'src/assets/icons/tick.svg';

import style from './SaveButton.module.scss';

const SaveButton = ({
  className,
  onClick,
  isLoading,
  type = 'save',
  disabled = false,
  dataTip,
  wrapperStyle,
  large,
  htmlType = 'submit'
}) => {
  const iconElement = useMemo(() => {
    switch (type) {
      case 'save':
        return <Tick />;
      case 'add':
        return <Add />;
      case 'delete':
        return <Bin />;
      case 'cancel':
        return <Cancel />;
      case 'download':
        return <Download className={style.download} />;
      case 'search':
      case 'send':
      case 'edit':
        return (
          <Icon
            name={type}
            fill='#ffffff'
          />
        );
      default:
        return null;
    }
  }, [type]);

  const [isLocalLoading, setIsLocalLoading] = useState(false);

  const buttonStyles = classNames(
    style.button,
    {
      [style.downloadButton]: ['download', 'send', 'search'].includes(type),
      [style.deleteButton]: ['delete', 'cancel'].includes(type),
      [style.disabled]: disabled,
      [style.large]: large
    },
    className
  );

  const onClickHandler = async () => {
    setIsLocalLoading(true);
    await onClick();
    setIsLocalLoading(false);
  };

  return (
    <>
      <span
        className={classNames(style.tooltipWrapper, { [style.large]: large }, wrapperStyle)}
        data-tip={dataTip}
      >
        <button
          className={buttonStyles}
          onClick={onClickHandler}
          disabled={disabled}
          type={htmlType}
        >
          {isLoading || isLocalLoading ? <Loader light /> : iconElement}
        </button>
      </span>
    </>
  );
};

export default SaveButton;
