export const CHANGE_CART_AND_CATALOG_VERSION = 'CHANGE_CART_AND_CATALOG_VERSION';

export const CHANGE_OCR_VERSION = 'CHANGE_OCR_VERSION';

export const CHANGE_SUMMARY_VERSION = 'CHANGE_SUMMARY_VERSION';

export const CHANGE_SELECTED_COMPANY = 'CHANGE_SELECTED_COMPANY';

export const CHANGE_VIEW_TYPE = 'CHANGE_VIEW_TYPE';

export const IS_PENDING_VALUATION_VISIBLE = 'IS_PENDING_VALUATION_VISIBLE';
