import React from 'react';
import Skeleton from 'react-loading-skeleton';
import cn from 'classnames';

import { LabelWithValue, Table } from 'components';

import { columns } from '../../index';

import style from '../../InvoiceDetails.module.scss';
import thumbnailStyles from '../ProductThumbnail/ProductThumbnail.module.scss';

const LoadingPlaceholder = () => {
  return (
    <div style={{ width: '100%' }}>
      <Skeleton height={80} />
      <header className={style.header}>
        <LabelWithValue
          label={
            <Skeleton
              height={20}
              width={130}
            />
          }
          className={style.wholesaler}
          value={
            <Skeleton
              height={25}
              width={120}
            />
          }
        />
        <LabelWithValue
          label={
            <Skeleton
              height={20}
              width={130}
            />
          }
          className={style.wholesaler}
          value={
            <Skeleton
              height={25}
              width={120}
            />
          }
        />
        <LabelWithValue
          label={
            <Skeleton
              height={20}
              width={130}
            />
          }
          className={style.wholesaler}
          value={
            <Skeleton
              height={25}
              width={120}
            />
          }
        />
        <LabelWithValue
          label={
            <Skeleton
              height={20}
              width={130}
            />
          }
          className={style.wholesaler}
          value={
            <Skeleton
              height={25}
              width={120}
            />
          }
        />
        <LabelWithValue
          label={
            <Skeleton
              height={20}
              width={130}
            />
          }
          className={style.wholesaler}
          value={
            <Skeleton
              height={25}
              width={120}
            />
          }
        />
      </header>
      <Table
        columns={columns}
        headerClasses={cn(thumbnailStyles.gridRow, style.tableHeader)}
        className={style.table}
      >
        <Skeleton
          height={40}
          count={4}
        />
      </Table>
    </div>
  );
};

export default LoadingPlaceholder;
