import React from 'react';
import { Grid, Placeholder, Segment } from 'semantic-ui-react';

import style from './ProductPlaceholder.module.scss';
import shared from 'styles/Shared.module.scss';

const DataPlaceholder = () => (
  <Segment
    raised
    className={style.container}
    padded={false}
  >
    <Grid
      columns={3}
      padded={'vertically'}
      style={{ padding: '0', marginLeft: '0px' }}
    >
      <Grid.Column
        width={1}
        padded={6}
        style={{ padding: '0' }}
      >
        <Placeholder style={{ height: 48, width: 48, borderRadius: '4px' }}>
          <Placeholder.Image />
        </Placeholder>
      </Grid.Column>
      <Grid.Column
        width={7}
        style={{ padding: '0 6px' }}
      >
        <Placeholder>
          <Placeholder.Line
            length={'long'}
            className={style.line}
          />
        </Placeholder>
      </Grid.Column>
      <Grid.Column
        width={8}
        style={{ padding: '0 6px' }}
      >
        <Placeholder>
          <Placeholder.Line
            length={'full'}
            className={style.line}
          />
        </Placeholder>
      </Grid.Column>
    </Grid>
  </Segment>
);

const ProductPlaceholder = ({ quantity = 15 }) => {
  const getElements = () => {
    let elements = [];
    for (let i = 0; i < quantity; i++) {
      elements.push(
        <div
          className={style.background}
          key={'product-p-' + i}
        >
          <DataPlaceholder />
        </div>
      );
    }
    return elements;
  };

  return (
    <div
      className={shared.listContent}
      style={{ gap: '0' }}
    >
      {getElements()}
    </div>
  );
};

export default ProductPlaceholder;
