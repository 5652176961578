import React from 'react';

import { getSupplierBackgroundColor } from 'src/utils/helpers';

import style from './SupplierColor.module.scss';

const SupplierColor = ({ supplier }) => {
  return (
    <span
      className={style.supplierColor}
      style={getSupplierBackgroundColor(supplier)}
    />
  );
};

export default SupplierColor;
