import React from 'react';
import classNames from 'classnames';

import { EmptyState } from 'components';

import VariationsGroupProductThumbnail from '../VariationsGroupProductThumbnail';

import styles from './VariationsGroupProducts.module.scss';
import shared from 'styles/Shared.module.scss';

const VariationsGroupProducts = ({ products = [], groupId, refreshCallback }) => {
  if (products.length < 1)
    return (
      <div className={styles.table}>
        <EmptyState
          type={'replacementsGroupEmpty'}
          tiny
        />
      </div>
    );

  return (
    <div className={classNames(shared.tableWrapper, styles.table)}>
      <div className={styles.tableInner}>
        <div className={classNames(shared.tableHeader, styles.header, styles.grid)}>
          <p>Produkt</p>
          <p>Jednostka</p>
          <p>Ilość w op.</p>
          <p>Producent</p>
          <p>Kategoria</p>
          <p></p>
        </div>
        <div className={shared.tableCategory}>
          {products.map((product) => (
            <VariationsGroupProductThumbnail
              key={product.id}
              refreshCallback={refreshCallback}
              groupId={groupId}
              {...product}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default VariationsGroupProducts;
