import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { Card, Chart, Loader, usePermissions } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { StatisticsApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import Section from 'src/features/Dashboard/components/Section';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './ExpensesCategoriesChart.module.scss';

const DASHBOARD_ELEMENT_ID = 2;

const ExpensesCategoriesChart = ({ config }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [canViewAnalytics] = usePermissions([userPermissions.analytics.read]);
  const [chartData, setChartData] = useState({});
  const formattedCompanies = useSelector((state) => state.auth.formattedCompanies);
  const canBeDisplayed = useMemo(
    () => canViewAnalytics && config?.elements?.includes(DASHBOARD_ELEMENT_ID),
    [canViewAnalytics, config]
  );

  const getData = async () => {
    const queryData = {
      date_from: dayjs().subtract(5, 'month').tz().format(),
      date_to: dayjs().tz().format(),
      ...(!!formattedCompanies && { company_ids: formattedCompanies.map((company) => company.value) })
    };

    try {
      setIsLoading(true);
      const { data } = await StatisticsApi.getExpensesByCategory(queryData);
      setChartData(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (canBeDisplayed) getData();
  }, [canBeDisplayed]);

  const getSeries = (type) => {
    if (type === 'pie') {
      return chartData?.series || [];
    } else {
      const data = [];
      chartData?.labels?.forEach((label, index) => {
        data.push({
          x: label,
          y: chartData?.series[index]
        });
      });
      return [{ data, name: 'Wydatki' }];
    }
  };

  const chartFormatter = useMemo(() => {
    const getPercentages = (data = []) => {
      let monthlySum = 0;

      data.forEach((value) => {
        monthlySum += value;
      });

      return data.map((value) => (monthlySum === 0 ? 0 : (value / monthlySum) * 100));
    };

    const categoriesPercentages = getPercentages(chartData?.series);
    return (val, opts) => `${getFormattedAmount(val)} (${categoriesPercentages[opts?.dataPointIndex]?.toFixed(2)}%)`;
  }, [chartData]);

  if (!canBeDisplayed) {
    return null;
  }

  if (isLoading) {
    return (
      <Section
        title={'Statystyki'}
        link={'/analytics'}
        className={style.container}
      >
        <Card className={style.emptyCard}>
          <Loader />
        </Card>
      </Section>
    );
  }

  return (
    <Section
      title={'Statystyki'}
      link={'/analytics'}
      className={style.container}
    >
      <Card className={style.card}>
        <Chart
          renderCustomSeries={getSeries}
          customSeries={chartData?.series}
          labels={chartData?.labels}
          categories={chartData?.labels}
          title={'Podział na kategorie'}
          categoriesLabel={'Zamówione produkty'}
          chartTypes={['treemap', 'bar', 'pie']}
          dataLabelsFormatter={(val) => (typeof val === 'number' ? val.toFixed(1) + '%' : val)}
          yAxisFormatter={(val) => getFormattedAmount(val)}
          tooltipFormatter={chartFormatter}
          haveData={chartData?.have_data}
          isLoading={isLoading}
          distributed
          height={316}
          fullWidth
        />
      </Card>
    </Section>
  );
};

export default ExpensesCategoriesChart;
