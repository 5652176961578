import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Button, OrderOrValuationId, ProgressLine, Tag } from 'components';
import InfoWidget from 'components/layout/InfoWidget';
import ShipmentPriceInfo from 'components/layout/ShipmentPriceInfo';

import { openSummary } from 'src/features/ValuationView/actions';
import { getSumOfProducts } from 'src/features/ValuationView/helpers';
import { getFormattedAmount } from 'src/utils/helpers';

import ProductsList from './components/ProductsList';

import style from '../../ValuationView.module.scss';
import shared from 'styles/Shared.module.scss';

export const getNumberOfCompletedValuations = (columns) => {
  let counter = 0;
  columns.forEach((column) => {
    if (Number(column?.supplier?.amount) !== 0) counter++;
  });
  return counter;
};

const LeftColumn = ({ columns, pageData, valuationEdit }) => {
  const numberOfCompletedValuations = getNumberOfCompletedValuations(columns);
  const dispatch = useDispatch();

  const savings = useSelector((state) => state.valuationView.savings);
  const { sum } = savings;

  const getOrderSummary = useMemo(() => {
    let productCount = 0;
    let totalSumCount = 0;
    let shippingPrice = 0;
    let supplierSum = {};

    pageData.categories.forEach((cat) => {
      cat.products.forEach((prod) => {
        prod.prices.forEach((price) => {
          if (price.selected) {
            productCount += 1;
            totalSumCount += price.price;
            if (supplierSum[price.supplier_id]) {
              supplierSum[price.supplier_id] = supplierSum[price.supplier_id] + price.price;
            } else {
              supplierSum[price.supplier_id] = price.price;
            }
          }
        });
      });
    });

    pageData.suppliers.forEach((sup) => {
      if (supplierSum[sup.id] && sup.free_shipment_price > supplierSum[sup.id]) {
        shippingPrice += sup.shipment_price;
      }
    });

    return {
      productCount,
      totalSumCount,
      shippingPrice
    };
  });

  const savingsPercentages = useMemo(
    () => ((+sum / (+getOrderSummary.totalSumCount + +sum)) * 100).toFixed(2),
    [sum, getOrderSummary]
  );

  return (
    <div className={style.leftColumn}>
      <div className={classNames(style.optionsWrapper)}>
        <div className={style.optionsInfo}>
          <span className={style.listCommentWrapper}>
            <OrderOrValuationId
              id={pageData.public_id}
              withPrefix={'Podsumowanie wyceny'}
              className={style.text}
            />
            {!!pageData?.list_comment && (
              <InfoWidget>
                <p>Dodano komentarz do wyceny:</p>
                <p>{pageData.list_comment}</p>
              </InfoWidget>
            )}
          </span>
          {valuationEdit ? (
            <>
              <span className={style.priceWrapper}>
                <h2 className={style.orderPrice}>{getFormattedAmount(getOrderSummary.totalSumCount)}</h2>
                <ShipmentPriceInfo
                  shippingPrice={getOrderSummary.shippingPrice}
                  className={style.shippingPrice}
                />
                {!!+sum && (
                  <div className={style.savings}>
                    <Tag
                      className={style.tag}
                      value={`Suma oszczędności: ${getFormattedAmount(sum)} (${savingsPercentages}%)`}
                      color={'green'}
                    />
                  </div>
                )}
              </span>
              <ProgressLine
                className={style.progress}
                maxValue={getSumOfProducts(columns)}
                currentValue={getOrderSummary.productCount}
                prefix={'wybrano'}
                alignToLeft
                fullWidth
              />
              <Button
                onClick={() => dispatch(openSummary())}
                label={'Pokaż szczegóły'}
              />
            </>
          ) : (
            <div className={style.valuationProgress}>
              {numberOfCompletedValuations === columns.length ? (
                <p className={style.label}>
                  Otrzymałeś {numberOfCompletedValuations} {numberOfCompletedValuations <= 4 ? 'wyceny' : 'wycen'}
                </p>
              ) : (
                <ProgressLine
                  suffix={columns.length <= 4 ? 'wyceny' : 'wycen'}
                  currentValue={numberOfCompletedValuations}
                  className={style.progress}
                  maxValue={columns.length}
                  prefix={'Otrzymano '}
                  type={'green'}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <section
        className={classNames(shared.tableWrapper, style.tableWrapper)}
        id={'valuation-table'}
      >
        <div className={classNames(shared.tableHeader, style.header)}>
          <p>Produkt</p>
        </div>
        <ProductsList
          pageData={pageData}
          productClasses={style.product}
        />
        <div className={classNames(shared.tableCategory, style.bottomSection)}>
          <h3 className={classNames(shared.tableCategoryTitle, shared.large)}>Gratisy do zamówienia:</h3>
        </div>
        <div className={classNames(shared.tableCategory, style.bottomSection)}>
          <h3 className={classNames(shared.tableCategoryTitle, shared.large)}>Komentarz dostawcy:</h3>
        </div>
      </section>
    </div>
  );
};

export default LeftColumn;
