import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { useAtom } from 'jotai/index';

import {
  ChangeValue,
  CommentModal,
  Icon,
  ItemTagsList,
  Menu,
  Modal,
  useIsAdmin,
  useIsMobile,
  useModalConfirm,
  usePermissions
} from 'components';
import AddProductToList from 'components/layout/AddProductToList';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import { modalTypes } from 'src/features/Warehouse';
import { selectedCompanyAtom } from 'src/features/Warehouse';
import ProductComment from 'src/features/Warehouse/new/ProductComment';
import ProductDetails from 'src/features/Warehouse/new/ProductDetails';
import { toDateNumber } from 'src/utils/dateTime';
import { getExpireDateStatus } from 'src/utils/dateTime';
import { getFormattedAmount, imageErrorHandler } from 'src/utils/helpers';

import ProductModal from '../../components/ProductModal';

import style from './ProductThumbnail.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductThumbnail = ({ product, refreshData, children, onlyName }) => {
  const {
    out_of_stock_quantity,
    latest_expire_date,
    top_category_name,
    product_comment,
    producer_name,
    newest_price,
    product_id,
    thumbnail,
    quantity,
    tags,
    name,
    id
  } = product;

  const isAdmin = useIsAdmin();
  const isMobile = useIsMobile();
  const [canViewOrders] = usePermissions([userPermissions.orders.read]);

  const [selectedCompany] = useAtom(selectedCompanyAtom);

  const [modalType, setModalType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductDetailsOpen, setIsProductDetailsOpen] = useState(false);
  const [isProductCommentOpen, setIsProductCommentOpen] = useState(false);
  const [isModalAddToListOpen, setIsModalAddToListOpen] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const deleteProduct = async () => {
    try {
      await WarehouseApi.deleteProduct(id);
      if (refreshData) refreshData();
      notifyCommon([`Produkt "${name}" został usunięty z magazynu.`]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const saveOutOfStockQuantity = async (qty) => {
    const params = {
      ...(isAdmin && { company_id: selectedCompany }),
      out_of_stock_quantity: qty,
      wh_product_id: id,
      product_id
    };

    try {
      await WarehouseApi.addOrUpdateProduct(params);
      if (refreshData) await refreshData();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteProductHandler = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć produkt? Tej akcji nie można cofnąć.',
      handleConfirm: deleteProduct
    });
  };

  const actions = [
    {
      title: 'Dodaj do listy',
      icon: 'add',
      action: () => setIsModalAddToListOpen(true)
    },
    {
      title: 'Dostawa',
      icon: 'add',
      color: 'blue',
      action: () => handleOpenModal(modalTypes.increase)
    },
    {
      title: 'Wydanie',
      icon: 'minus',
      action: () => handleOpenModal(modalTypes.decrease)
    },
    {
      title: 'Dodaj komentarz',
      icon: 'message',
      action: () => setIsProductCommentOpen(true)
    },
    {
      title: 'Usuń produkt',
      icon: 'trash',
      color: 'red',
      action: deleteProductHandler
    }
  ];

  const outOfStockQuantityElement = (
    <ChangeValue
      apiCallback={saveOutOfStockQuantity}
      initialValue={out_of_stock_quantity}
      type={'number'}
      label={'Zmień stan min.'}
      placement={'bottom'}
      unit={'szt.'}
      icon={
        <Icon
          name={'edit'}
          fill={'#888'}
        />
      }
    />
  );

  const handleOpenModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const quantityWrapperStyles = useMemo(
    () =>
      cn(style.wrapper, {
        [style.error]: quantity < out_of_stock_quantity
      }),
    [quantity, out_of_stock_quantity]
  );

  const expireDateWrapperStyles = useMemo(() => {
    const type = getExpireDateStatus(latest_expire_date);
    return cn(style.wrapper, { [style[type]]: !!type }, style.expireDate);
  }, [latest_expire_date]);

  if (onlyName) {
    return (
      <div className={cn(style.container, { [style.withChildren]: !!children, [style.onlyName]: onlyName })}>
        {!!children && <div className={cn(style.wrapper, style.checkbox)}>{children}</div>}
        <a onClick={() => setIsProductDetailsOpen(true)}>
          <div className={style.wrapper}>
            {!isMobile && (
              <img
                src={thumbnail}
                alt={name}
                className={cn(shared.defaultThumbnail)}
                loading={'lazy'}
                onError={imageErrorHandler}
              />
            )}
            <p className={style.text}>{name}</p>
          </div>
        </a>
        <div className={style.productMeta}>
          <p>
            <span>Producent: </span>
            {producer_name}
          </p>
          <p>
            <span>Kategoria: </span>
            {top_category_name}
          </p>
        </div>
        <Modal
          title={'Detale produktu'}
          visible={isProductDetailsOpen}
          onClose={() => setIsProductDetailsOpen(false)}
        >
          <ProductDetails
            productData={product}
            refreshData={refreshData}
          />
        </Modal>
      </div>
    );
  }

  return (
    <div className={cn(style.container, { [style.withChildren]: !!children, [style.hidePrices]: !canViewOrders })}>
      <div className={style.wrapper}>
        <div className={style.text}>
          <ItemTagsList
            displayOnly
            tags={tags ? tags : []}
          />
        </div>
      </div>
      <div className={style.wrapper}>{outOfStockQuantityElement}</div>
      <div className={quantityWrapperStyles}>{quantity} szt.</div>
      {canViewOrders && (
        <>
          <div className={style.wrapper}>
            <p>{newest_price ? getFormattedAmount(newest_price) : '-'}</p>
          </div>
          <div className={style.wrapper}>
            <p>{getFormattedAmount(Math.max(newest_price, 0) * Math.max(quantity, 0))}</p>
          </div>
        </>
      )}
      <div className={expireDateWrapperStyles}>{latest_expire_date ? toDateNumber(latest_expire_date) : '-'}</div>

      <div className={style.wrapper}>
        <CommentModal
          comment={product_comment}
          title={name}
          modalTitle={'Komentarz do produktu'}
        />
      </div>
      <div className={style.wrapper}>
        <Menu
          actions={actions}
          className={style.menu}
        />
      </div>
      <AddProductToList
        title={`Dodaj "${name}" do listy`}
        visible={isModalAddToListOpen}
        onClose={() => setIsModalAddToListOpen(false)}
        productId={product_id}
      />
      {renderedModalConfirm}
      <Modal
        title={'Dodaj komentarz'}
        visible={isProductCommentOpen}
        onClose={() => setIsProductCommentOpen(false)}
      >
        <ProductComment
          product={product}
          onClose={() => {
            setIsProductCommentOpen(false);
            refreshData();
          }}
        />
      </Modal>
      <ProductModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        modalType={modalType}
        refreshData={refreshData}
        product={product}
      />
    </div>
  );
};

export default ProductThumbnail;
