import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { ReactComponent as Arrow } from 'src/assets/icons/arrow-left.svg';

import style from './GoBackButton.module.scss';

const GoBackButton = ({ wide = false, goBackUrl }) => {
  const history = useHistory();

  const onClickHandler = useCallback(() => {
    if (goBackUrl) {
      history.push(goBackUrl);
    } else {
      history.goBack();
    }
  }, [goBackUrl]);

  return (
    <button
      className={classnames(style.container, {
        [style.wide]: wide
      })}
      onClick={onClickHandler}
      title={'Wstecz'}
    >
      <Arrow className={style.icon} />
      Wstecz
    </button>
  );
};

export default GoBackButton;
