import React, { useMemo, useState } from 'react';

import { Button, Input, Select } from 'components';

import style from './AddNode.module.scss';

const AddNode = ({ categoryTree, onClick }) => {
  const [listName, setListName] = useState('');
  const [parentCategory, setParentCategory] = useState(0);

  const options = useMemo(() => {
    return [{ value: 0, label: 'Brak' }, ...categoryTree.map((cat) => ({ value: cat.id, label: cat.name }))];
  }, [categoryTree]);

  return (
    <div className={style.container}>
      <Input
        label={'Nazwa kategorii'}
        placeholder={'Amalgamaty'}
        wrapperClass={style.input}
        value={listName}
        onChange={(e) => setListName(e.target.value)}
      />
      <Select
        options={options}
        wrapperStyle={style.select}
        value={parentCategory}
        onChange={setParentCategory}
        label={'Do jakiej kategorii przypisać (opcjonalnie)'}
        placeholder={'Nadkategoria'}
        isClearable
      />
      <Button
        label={'Dodaj kategorię'}
        title={'Dodaj kategorię'}
        type={'button'}
        onClick={() => onClick({ text: listName, parent: parentCategory.value })}
      />
    </div>
  );
};

export default AddNode;
