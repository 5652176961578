import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, FileDropzone, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';

import { refreshList } from '../../../../../../actions';

import style from '../../../../ExportImportForm.module.scss';

const ImportNewProductsForm = () => {
  const dispatch = useDispatch();
  const validator = useValidator();

  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const submitNewProductsHandler = async (e) => {
    e.preventDefault();
    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const formData = new FormData();
    formData.append('products', file);

    try {
      setIsLoading(true);
      await ProductsApi.importNewProducts(formData);
      notifyCommon(['Produkty zostały dodane'], { title: 'Sukces!' });
      validator.hideMessages();
      setFile(null);
      dispatch(refreshList());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <form
      className={style.container}
      onSubmit={submitNewProductsHandler}
    >
      <header className={style.header}>
        <h3>Import pliku z nowymi produktami</h3>
        <p>Wybierz plik i zapisz, aby dodać nowe produkty.</p>
      </header>
      <FileDropzone
        label={'Szablon z nowymi produktami'}
        onChange={setFile}
        value={file}
        validator={validator}
        rule={'required'}
      />
      <Button
        label={'Zaimportuj produkty'}
        type={'submit'}
        className={style.button}
        isLoading={isLoading}
      />
    </form>
  );
};

export default ImportNewProductsForm;
