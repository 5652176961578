import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

import buttons from 'src/dictionaries/buttons.json';

import Button from '../Button';
import EmptyState from '../EmptyState';

import style from './ErrorFallback.module.scss';

export default function ErrorFallback({ error, resetErrorBoundary }) {
  const location = useLocation();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    resetErrorBoundary();
  }, [location.key]);

  return (
    <>
      <div className={style.container}>
        <div className={style.main}>
          <EmptyState type={'errorFallback'} />
          <Button
            to={'/'}
            label={buttons.public.goToHomePage}
          />
        </div>
      </div>
      {error.message && <div className={style.message}>Komunikat błędu: {error.message}</div>}
    </>
  );
}
