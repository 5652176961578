import React, { useMemo } from 'react';
import { Popover } from 'antd';
import classNames from 'classnames';

import { Tag, useIsMobile } from 'components';

import style from './ThumbnailList.module.scss';
import shared from 'styles/Shared.module.scss';

const fn = () => null;

const ThumbnailList = ({ items = [], uid, statusesList = [], renderElement = fn, label }) => {
  const isMobile = useIsMobile();
  const limit = useMemo(() => (isMobile ? 3 : 4), [isMobile]);
  const suppliersToDisplay = useMemo(() => {
    const getIndex = (supplier) => statusesList.indexOf(supplier.status);
    const sorted = items.sort((a, b) => +getIndex(a) - +getIndex(b));

    const popoverContent = <div className={shared.popoverWrapper}>{sorted.slice(limit - 1).map(renderElement)}</div>;

    if (sorted.length > limit) {
      return [
        ...sorted.slice(0, limit - 1).map(renderElement),
        <Popover
          key={'tag' + uid}
          content={popoverContent}
          placement={'bottomRight'}
        >
          <div>
            <Tag
              value={`+ ${+sorted.length - (limit - 1)} innych...`}
              color={'gray'}
            />
          </div>
        </Popover>
      ];
    } else {
      return sorted.map(renderElement);
    }
  }, [items, limit, statusesList, renderElement]);

  return (
    <div className={shared.thumbnailContentWrapper}>
      <p className={classNames(shared.thumbnailContentLabel, style.suppliersLabel)}>{label}</p>
      {suppliersToDisplay.length ? (
        suppliersToDisplay
      ) : (
        <Tag
          value={'Brak'}
          color={'darkGray'}
        />
      )}
    </div>
  );
};

export default ThumbnailList;
