import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { SwitchButton, useAuthUser, useIsAdmin } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { UserApi } from 'src/api';
import { refreshUserData } from 'src/features/onboarding/actions';

import style from './Notifications.module.scss';

const Notifications = () => {
  const user = useAuthUser();
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();

  const [areSMSAccepted, setAreSMSAccepted] = useState(!!user.sms);
  const [areEmailAccepted, setAreEmailAccepted] = useState(!!user.mail);
  const [isSwitchLoading, setIsSwitchLoading] = useState(false);

  const saveSwitch = async (type, value) => {
    const data = { [type]: +value };

    try {
      setIsSwitchLoading(true);
      await UserApi.updateUserData(data);
      await dispatch(refreshUserData());
      notifyCommon(['Dane zostały zapisane.']);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsSwitchLoading(false);
    }
  };

  return (
    <div className={style.box}>
      <SwitchButton
        label={'Chcę otrzymywać powiadomienia SMS'}
        setValue={(value) => {
          setAreSMSAccepted(value);
          saveSwitch('sms', value);
        }}
        value={areSMSAccepted}
        wrapperStyle={style.switch}
        disabled={isSwitchLoading || isAdmin}
        reverse
      />
      <SwitchButton
        label={'Chcę otrzymywać powiadomienia E-mail'}
        setValue={(value) => {
          setAreEmailAccepted(value);
          saveSwitch('mail', value);
        }}
        value={areEmailAccepted}
        wrapperStyle={style.switch}
        disabled={isSwitchLoading || isAdmin}
        reverse
      />
    </div>
  );
};

export default Notifications;
