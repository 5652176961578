import React, { useState } from 'react';

import { Button, Input, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ExpensesApi } from 'src/api';

import style from './MasterCategoryForm.module.scss';

const MasterCategoryForm = ({ id, currentName = '', closeModal, refreshCallback }) => {
  const [name, setName] = useState(currentName);
  const validator = useValidator();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const ApiCallback = id ? ExpensesApi.editTag : ExpensesApi.addTag;
    const params = {
      name,
      ...(id && { id })
    };

    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    try {
      setIsLoading(true);
      await ApiCallback(params);
      notifyCommon([`Tag został ${id ? 'zapisany' : 'dodany'}!`]);
      refreshCallback();
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={handleSubmit}
    >
      <Input
        id='name'
        name='name'
        label='Nazwa tagu'
        value={name}
        onChange={(e) => setName(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <Button
        label={id ? 'Zapisz' : 'Dodaj'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default MasterCategoryForm;
