import React, { useEffect, useState } from 'react';

import { Button, EmptyState, Modal, ProfileSection as Section } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { CompanyApi } from 'src/api';

import ThresholdForm from './components/ThresholdForm';
import ThresholdThumbnail, { ThresholdSkeleton } from './components/ThresholdThumbnail';

import style from './OrdersApproval.module.scss';

const OrdersApproval = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [editedThreshold, setEditedThreshold] = useState(null);
  const [thresholds, setThresholds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const button = (
    <Button
      label={'Dodaj próg akceptacji'}
      onClick={() => setIsOpen(true)}
    />
  );

  const editThresholdHandler = (item) => {
    setEditedThreshold(item);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setEditedThreshold(null);
  };

  const getThresholds = async () => {
    try {
      setIsLoading(true);
      const { data } = await CompanyApi.getThresholds();
      setThresholds(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getThresholds();
  }, []);

  return (
    <div className={style.container}>
      <Section
        title={'Akceptacja zamówień'}
        button={button}
        isLoading={isLoading}
        skeleton={ThresholdSkeleton}
      >
        <div className={style.wrapper}>
          {thresholds.length > 0 ? (
            thresholds.map((item, index) => (
              <ThresholdThumbnail
                item={item}
                key={item.id}
                isLast={index === thresholds.length - 1}
                handleEdit={() => editThresholdHandler(item)}
                refreshData={getThresholds}
              />
            ))
          ) : (
            <EmptyState
              type={'thresholds'}
              className={style.empty}
            />
          )}
        </div>
      </Section>
      <Modal
        visible={isOpen}
        onClose={closeModal}
        title={editedThreshold ? 'Edytuj' : 'Dodaj' + ' próg kwotowy akceptacji'}
      >
        <ThresholdForm
          closeModal={closeModal}
          thresholds={thresholds}
          threshold={editedThreshold}
          refreshData={getThresholds}
        />
      </Modal>
    </div>
  );
};

export default OrdersApproval;
