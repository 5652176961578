import cn from 'classnames';

import InfoWidget from 'components/layout/InfoWidget';

import { getFormattedAmount } from '../../../utils/helpers';

import style from './AdditionalShipping.module.scss';

export default function AdditionalShipping({ price, comment, tooltipPosition = 'bottom', tooltipFirst, noPlus }) {
  return !price ? null : (
    <>
      {!tooltipFirst && !noPlus && ' + '}
      {!tooltipFirst && getFormattedAmount(price)}
      <InfoWidget
        className={cn(style.shippingTooltip, {
          [style.left]: tooltipFirst,
          [style.right]: !tooltipFirst
        })}
        place={tooltipPosition}
      >
        {comment}
      </InfoWidget>
      {tooltipFirst && getFormattedAmount(price)}
      {tooltipFirst && !noPlus && ' + '}
    </>
  );
}
