import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { ReactComponent as PlusIcon } from 'assets/icons/plus-blue.svg';
import {
  Accordion,
  AddressDetails,
  Button,
  ChangeValue,
  CompanyDetails,
  Icon,
  QtySelector,
  Textarea,
  useValidator
} from 'components';
import { AdditionalShipping } from 'components';
import Modal from 'components/layout/Modal';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { orderStatuses } from 'src/constants/enums';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './OrderDetails.module.scss';

const OrderDetails = ({ data, refreshDataCallback, hideExtraCost, defaultClose }) => {
  const { invoice_details, shipping_details } = data.order || {};

  const params = useParams();
  const validator = useValidator();
  const [shippingValue, setShippingValue] = useState();
  const [shippingComment, setShippingComment] = useState();
  const [isModalShippingOpen, setIsModalShippingOpen] = useState();

  useEffect(() => {
    setShippingValue(shipping_details?.additional_shipping_price);
    setShippingComment(data?.supplier_comment);
  }, [data]);

  const clearShippingFormHandler = () => {
    setShippingValue(null);
    setShippingComment(null);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const queryParams = {
      uid: params.uid,
      additional_shipping_price: shippingValue,
      supplier_comment: shippingComment
    };

    try {
      await SupplierApi.updateOrder(queryParams);
      notifyCommon(['Dodano pomyślnie!']);
      refreshDataCallback();
      setIsModalShippingOpen(false);
      clearShippingFormHandler();
    } catch (error) {
      notifyApiError(error);
    }

    validator.hideMessages();
  };

  const updatePrice = async (price) => {
    try {
      await SupplierApi.updateShippingPrice({
        suborder_uid: data.suborder_uid,
        shipping_price: price
      });
      refreshDataCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const shippingPriceElement = (
    <ChangeValue
      disabled={[orderStatuses.completed, orderStatuses.cancelled].includes(data.status)}
      apiCallback={updatePrice}
      initialValue={shipping_details?.shipping_price}
      displayValueFormatter={getFormattedAmount}
      label={'Zmień cenę wysyłki'}
      placement={'bottom'}
      unit={'zł'}
      hideArrows
      icon={
        <Icon
          name={'edit'}
          fill={'#888'}
        />
      }
    />
  );

  const invoiceDataTitle = <h2 className={style.title}>Dane do faktury</h2>;
  const shippingDataTitle = <h2 className={style.title}>Dane do wysyłki</h2>;

  return (
    <div className={style.container}>
      <Accordion
        title={invoiceDataTitle}
        defaultWrapped={defaultClose}
      >
        <CompanyDetails
          details={invoice_details}
          copyOnCLick
          readOnly
        />
      </Accordion>
      <Accordion
        title={shippingDataTitle}
        defaultWrapped={defaultClose}
      >
        <div className={style.contentWrapper}>
          <AddressDetails
            details={shipping_details}
            readOnly
          />
          <div>
            <div className={style.row}>
              <p className={style.label}>Metoda płatności:</p>
              <p className={style.value}>{shipping_details?.pay_method}</p>
            </div>
            <div className={style.row}>
              <p className={style.label}>Metoda wysyłki:</p>
              <p className={style.value}>{shipping_details?.ship_method}</p>
            </div>
            <div className={style.row}>
              <p className={style.label}>Koszty wysyłki:</p>
              <p className={style.value}>
                {shippingPriceElement}
                <AdditionalShipping
                  price={shipping_details?.additional_shipping_price}
                  comment={data?.supplier_comment}
                />
              </p>
              {!hideExtraCost && (
                <a
                  onClick={() => setIsModalShippingOpen(true)}
                  className={style.extraCost}
                >
                  <PlusIcon />
                </a>
              )}
            </div>
          </div>
        </div>
      </Accordion>
      <Modal
        visible={isModalShippingOpen}
        onClose={() => setIsModalShippingOpen(false)}
        title={
          shipping_details?.additional_shipping_price > 0 ? 'Edytuj dodatkowy koszt wysyłki' : 'Dodaj dodatkowy koszt wysyłki'
        }
        closeOnOutsideClick
      >
        <form
          className={style.modalFormWrapper}
          onSubmit={submitHandler}
        >
          <QtySelector
            id={'shippingValue'}
            name={'shippingValue'}
            label={'Dodatkowy koszt wysyłki'}
            suffix={'zł'}
            state={shippingValue}
            setState={setShippingValue}
            validator={validator}
            rule={'required|only_positive'}
            hideArrows
            priceInput
          />
          <Textarea
            id={'shippingComment'}
            name={'shippingComment'}
            label={'Komentarz'}
            value={shippingComment}
            setValue={setShippingComment}
            validator={validator}
            rule={'required'}
          />
          <Button label={'Dodaj'} />
        </form>
      </Modal>
    </div>
  );
};

export default OrderDetails;
