import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname, search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const page = searchParams.get('page');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, page]);

  return <></>;
};

export default ScrollToTop;
