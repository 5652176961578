import React, { useEffect, useState } from 'react';

import { Button, Card, Loader, Modal, PageHeader, SeoHelmet } from 'components';
import { headerTextPlaceholder } from 'components/layout/PageHeader/placeholders';
import { notifyApiError } from 'components/layout/Toasts';

import { ExpensesApi } from 'src/api';
import pages from 'src/dictionaries/pages.json';
import { handleNumeralWords } from 'src/utils/helpers';

import MasterCategory from './components/MasterCategory';
import MasterCategoryForm from './components/MasterCategoryForm';

import style from './ExpensesManagement.module.scss';

const ExpensesManagement = () => {
  const [version, setVersion] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [masterCategories, setMasterCategories] = useState([]);
  const [nestedCategories, setNestedCategories] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const { data } = await ExpensesApi.getTags();
        setMasterCategories(data);
        const { data: nested } = await ExpensesApi.getCategories();
        setNestedCategories(nested);
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [version]);

  const refreshCallback = () => setVersion((prev) => prev + 1);

  const createNewLabel = 'Dodaj nowy tag';
  const closeModal = () => setIsVisible(false);
  const pageLabel = `${masterCategories.length} ${handleNumeralWords(['tag', 'tagi', 'tagów'], masterCategories?.length)}, ${
    nestedCategories?.length
  } ${handleNumeralWords(['kategoria', 'kategorie', 'kategorii'], nestedCategories?.length)}`;

  return (
    <>
      <SeoHelmet title={pages.expensesManagement.title} />
      <PageHeader
        name={pages.expensesManagement.title}
        text={isLoading ? headerTextPlaceholder() : pageLabel}
      >
        <Button
          label={createNewLabel}
          iconName={'add'}
          onClick={() => setIsVisible(true)}
        />
      </PageHeader>
      <div className={style.container}>
        {isLoading ? (
          <Card className={style.loaderCard}>
            <Loader />
          </Card>
        ) : (
          masterCategories.map((cat) => (
            <MasterCategory
              key={cat.id}
              category={cat}
              nestedCategories={nestedCategories}
              refreshCallback={refreshCallback}
            />
          ))
        )}
      </div>
      <Modal
        visible={isVisible}
        onClose={closeModal}
        title={createNewLabel}
      >
        <MasterCategoryForm
          closeModal={closeModal}
          refreshCallback={refreshCallback}
        />
      </Modal>
    </>
  );
};

export default ExpensesManagement;
