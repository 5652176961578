import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Modal,
  PaginationNav,
  Searchbar,
  SeoHelmet,
  StickyPageHeader,
  useRequestAbortController,
  useSearchParam
} from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { WholesalersApi } from 'src/api';
import query from 'src/utils/query';

import AddWholesalerForm from './components/AddWholesalerForm';
import WholesalersTable from './components/WholesalersTable';
import { refreshWholesalers } from './actions';

import styles from './Wholesalers.module.scss';

const Wholesalers = (props) => {
  const params = query(props);
  const dispatch = useDispatch();
  const version = useSelector((state) => state.wholesalers.version);

  const search = useSearchParam(params, 'search');
  const sortBy = useSearchParam(params, 'sort_by');
  const sortOrder = useSearchParam(params, 'sort_order');
  const page = useSearchParam(params, 'page');

  const [pageData, setPageData] = useState([]);
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [abortController, setNewController] = useRequestAbortController();

  const getData = async () => {
    if (abortController) abortController.abort();
    const signal = setNewController();

    const params = {
      page: page || 1,
      perPage: perPage,
      ...(search && { search: search }),
      ...(sortBy && { sort_by: sortBy }),
      ...(sortOrder && { sort_order: sortOrder })
    };

    try {
      setIsLoading(true);
      const { data } = await WholesalersApi.getWholesalers(params, signal);
      setPageQty(data.last_page);
      setPageData(data.data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const saveHandler = () => {
    setVisible(false);
    dispatch(refreshWholesalers());
  };

  useEffect(() => {
    getData();
  }, [version, search, sortBy, sortOrder, page, perPage]);

  return (
    <div className={styles.container}>
      <SeoHelmet title={'Hurtownie'} />
      <StickyPageHeader name={'Hurtownie'}>
        <>
          <Searchbar params={params} />
          <Button
            label={'Dodaj hurtownię'}
            onClick={() => setVisible(true)}
            iconName={'add'}
          />
        </>
      </StickyPageHeader>
      <WholesalersTable
        list={pageData}
        isLoading={isLoading}
        params={params}
      />
      <PaginationNav
        params={params}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
      />
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        title={'Dodaj hurtownię'}
      >
        <AddWholesalerForm onClose={saveHandler} />
      </Modal>
    </div>
  );
};

export default Wholesalers;
