import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { AsyncSelect, Button, QtySelector, Textarea, useIsAdmin, useIsMobile, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OfferCatalogApi, ProductsApi, UserApi } from 'src/api';
import { getFormattedAmount } from 'src/utils/helpers';

import { refreshCartOrCatalog } from '../../../../actions';

import style from '../../AddNewPrice.module.scss';

const Product = ({ productId, selectedCompany, closeModal }) => {
  const validator = useValidator();
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  const isMobile = useIsMobile(480);

  const [isLoading, setIsLoading] = useState(false);

  const [comment, setComment] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [price, setPrice] = useState(0);

  const addPrice = async () => {
    const queryData = {
      ...(isAdmin && { company_id: selectedCompany }),
      ...(!!comment && { comment }),
      product_id: productId || selectedProduct?.value,
      supplier_id: selectedSupplier?.value,
      type: 'valuation',
      price: +price
    };

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      await OfferCatalogApi.addPrice(queryData);
      if (selectedProduct?.label && price) {
        notifyCommon([`Produkt ${selectedProduct.label} został dodany wraz z ceną ${getFormattedAmount(price)}`]);
      }
      dispatch(refreshCartOrCatalog());
      setSelectedProduct(null);
      setSelectedSupplier(null);
      setPrice(0);
      if (closeModal) closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.wrapper}>
      {!productId && (
        <AsyncSelect
          value={selectedProduct}
          onChange={setSelectedProduct}
          apiCallback={ProductsApi.getProducts}
          valueKey={'id'}
          labelKey={'name'}
          label={'Produkt'}
          placeholder={'Produkt'}
          validator={validator}
          rule={'required'}
          productSearch
          isClearable
        />
      )}
      <div className={style.box}>
        <AsyncSelect
          value={selectedSupplier}
          onChange={setSelectedSupplier}
          apiCallback={UserApi.getDistributors}
          valueKey={'id'}
          labelKey={'name'}
          label={'Dostawca'}
          placeholder={'Dostawca'}
          queryParams={{ pagination: 1, ...(isAdmin && { company_id: selectedCompany }) }}
          validator={validator}
          rule={'required'}
          isClearable
        />
        <QtySelector
          id={`${productId}-price`}
          state={price}
          setState={setPrice}
          label={'Cena'}
          suffix={'zł'}
          width={isMobile ? '100%' : 120}
          priceInput
          hideArrows
          hideValueWhenZero
          validator={validator}
          rule={'required|only_positive'}
        />
      </div>
      <Textarea
        label={'Komentarz do oferty (notatka)'}
        value={comment}
        setValue={setComment}
      />
      <Button
        isLoading={isLoading}
        label={'Dodaj ofertę'}
        onClick={addPrice}
      />
    </div>
  );
};

export default Product;
