import React from 'react';
import classNames from 'classnames';

import { useIsMobile } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import ProductItemDepartment from '../ProductItemDepartment';

import style from './ProductItem.module.scss';

const ProductItem = ({ item, index }) => {
  const { id, producer, name, qty, unit_name, thumbnail, image, departments } = item || {};

  const isMobile = useIsMobile(767);

  return (
    <div
      className={style.container}
      id={`order-departments-product-${id}`}
    >
      <div className={style.item}>
        <div className={style.index}>
          <p>{index + 1}.</p>
        </div>
        <div className={style.wrapper}>
          <ProductThumbnailImage
            thumbnail={thumbnail}
            image={image}
            title={name}
            id={id}
          />
          <p className={style.text}>{name}</p>
        </div>
        <div className={style.wrapper}>
          {isMobile && <p className={style.text}>Producent: </p>}
          <p className={style.text}>{producer}</p>
        </div>
        <div className={style.wrapper}>
          {isMobile && <p className={style.text}>Ilość: </p>} {qty} {unit_name}
        </div>
      </div>

      {departments.length > 0 && (
        <div className={style.departments}>
          {departments.map((department, i) => (
            <ProductItemDepartment
              product={item}
              department={department}
              unit_name={unit_name}
              key={i}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductItem;
