import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Loader } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import AddressesList from 'src/features/Profile/features/Addresses';

import style from './Addresses.module.scss';

const Addresses = ({ companyId }) => {
  const [company, setCompany] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const addressesVersion = useSelector((state) => state.companiesList.addressesVersion);

  const getCompanyDetails = async () => {
    try {
      const { data } = await AdminApi.getCompanyDetails(companyId);
      setCompany(data);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    const handleLoadInitialData = async () => {
      setIsLoading(true);
      await getCompanyDetails();
      setIsLoading(false);
    };

    handleLoadInitialData();
  }, []);

  useEffect(() => {
    if (addressesVersion > 0) {
      getCompanyDetails();
    }
  }, [addressesVersion]);

  if (isLoading) {
    return <Loader center />;
  }

  return (
    <div className={style.container}>
      <AddressesList company={company} />
    </div>
  );
};

export default Addresses;
