import React, { useState } from 'react';
import dayjs from 'dayjs';

import { Button } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';
import { EXCEL_FILE_TYPE } from 'src/constants/misc';
import { downloadFile, getDecodeBase64File } from 'src/utils/helpers';

import style from './CartExport.module.scss';

const CartExport = ({ listUID }) => {
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);
  const [isLoadingXLSX, setIsLoadingXLSX] = useState(false);

  const setIsLoading = (value, type, withSupplier) => {
    if (!withSupplier && type === 'XLSX') {
      setIsLoadingXLSX(value);
    } else if (!withSupplier && type === 'CSV') {
      setIsLoadingCSV(value);
    }
  };

  const downloadHandler = async (type) => {
    const queryData = {
      list_uid: listUID,
      file_format: type
    };

    const filename = 'koszyk';

    const date = dayjs().format('DD.MM.YYYY, HH:mm');

    const downloadCSV = async () => {
      const { data } = await ListsApi.exportYourCartData(queryData);
      const blob = new Blob([data], { type: 'text/csv' });
      downloadFile(blob, filename + '_' + date + '.csv');
    };
    const downloadXLSX = async () => {
      const { data } = await ListsApi.exportYourCartData(queryData);
      const blob = getDecodeBase64File(data, EXCEL_FILE_TYPE);
      downloadFile(blob, filename + '_' + date + '.xlsx');
    };

    const callback = type === 'CSV' ? downloadCSV : downloadXLSX;

    try {
      setIsLoading(true, type);
      await callback();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false, type);
    }
  };

  return (
    <div className={style.container}>
      <p>
        Pobierz listę produktów z koszyka w formacie CSV lub pliku Excel (.xlsx). Pliki zawierają informacje o produktach, ich
        cenach, oraz dostawcach.
      </p>
      <div className={style.buttonsWrapper}>
        <Button
          label={'Eksportuj (.csv)'}
          isLoading={isLoadingCSV}
          onClick={() => downloadHandler('CSV')}
        />
        <Button
          label={'Eksportuj (.xlsx)'}
          isLoading={isLoadingXLSX}
          onClick={() => downloadHandler('XLSX')}
        />
      </div>
    </div>
  );
};

export default CartExport;
