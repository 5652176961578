import { useState } from 'react';

import { Button, FileDropzone, Input, QtySelector, Textarea, useValidator } from 'components';
import File from 'components/layout/FileUploader/components/File';
import InputWrapper from 'components/layout/InputWrapper';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ExpensesApi } from 'src/api';

import styles from './AddInvoiceForm.module.scss';

const defaultInvoice = {
  name: '',
  nip: '',
  price: '',
  payment_date: '',
  comment: '',
  files: []
};

const AddInvoiceForm = ({ invoiceData, expenseId, closeModal }) => {
  const [invoice, setInvoice] = useState(invoiceData || defaultInvoice);
  const [replaceFiles, setReplaceFiles] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validator = useValidator();

  const onChange = (e) => {
    setInvoice((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const onChangeHandler = (name, data) => {
    setInvoice((prev) => ({
      ...prev,
      [name]: data
    }));
  };

  const editInvoiceHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      id: invoice.id,
      name: invoice.name,
      nip: invoice.nip,
      price: Number(invoice.price),
      payment_date: invoice.payment_date,
      comment: invoice.comment
    };

    try {
      setIsLoading(true);
      if (replaceFiles) {
        await ExpensesApi.deleteExpensePaymentFile({ id: invoice.existing_files[0].id });
      }
      if (invoice.files.length > 0) {
        await ExpensesApi.addExpensePaymentFiles({
          expense_payment_id: invoice.id,
          files: invoice.files
        });
      }
      const { data } = await ExpensesApi.editUserExpensePayment(params);
      if (closeModal) closeModal();
      notifyCommon([`${data.message}`]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const addInvoiceHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      expense_id: expenseId,
      name: invoice.name,
      price: invoice.price,
      payment_date: invoice.payment_date,
      nip: invoice.nip,
      ...(invoice.files.length > 0 && { files: invoice.files }),
      ...(invoice.comment && { comment: invoice.comment })
    };

    try {
      setIsLoading(true);
      const { data } = await ExpensesApi.addUserExpensePayment(params);
      if (closeModal) closeModal();
      setInvoice(defaultInvoice);
      notifyCommon([`${data.message}`]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={styles.container}
      onSubmit={invoiceData ? editInvoiceHandler : addInvoiceHandler}
    >
      <Input
        id='name'
        name='name'
        label='Nazwa usługodawcy'
        value={invoice.name}
        onChange={onChange}
        validator={validator}
        rule={'required'}
      />
      <Input
        id='nip'
        name='nip'
        label='NIP kontrahenta'
        type='number'
        value={invoice.nip}
        onChange={(nip) => onChangeHandler('nip', nip.target.value)}
        validator={validator}
        rule={'required'}
      />
      <div className={styles.row}>
        <QtySelector
          id='price'
          name='price'
          label='Wartość faktury'
          state={invoice.price}
          setState={(price) => onChangeHandler('price', price)}
          width={'100%'}
          suffix={'zł'}
          priceInput
          hideArrows
          validator={validator}
          rule={'required|only_positive'}
        />
        <Input
          type='date'
          id='payment_date'
          name='payment_date'
          label='Data płatności'
          value={invoice.payment_date}
          onChange={onChange}
          validator={validator}
          rule={'required'}
        />
      </div>
      {!replaceFiles && invoiceData?.existing_files.length > 0 ? (
        <InputWrapper label={'Faktura'}>
          <div className={styles.fileCard}>
            <File
              value={{ name: invoiceData?.existing_files[0].file_name }}
              handleClearFile={() => setReplaceFiles(true)}
            >
              <a
                onClick={() => setReplaceFiles(true)}
                className={styles.a}
              >
                Zmień fakturę
              </a>
            </File>
          </div>
        </InputWrapper>
      ) : (
        <FileDropzone
          label={'Faktura'}
          onChange={(file) => onChangeHandler('files', file ? [file] : [])}
          value={invoice.files[0] || null}
          id={'file'}
          name={'file'}
        />
      )}
      <Textarea
        id={'category-comment'}
        name={'category-comment'}
        label={'Komentarz'}
        value={invoice.comment || ''}
        setValue={(comment) => onChangeHandler('comment', comment)}
      />
      <Button
        label={invoiceData ? 'Zapisz' : 'Dodaj'}
        isLoading={isLoading}
        className={styles.button}
      />
    </form>
  );
};

export default AddInvoiceForm;
