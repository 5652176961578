import React from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { CompanySelect, useIsAdmin, useIsMobile } from 'components';
import { openMobileSidebar } from 'components/layout/Sidebar/actions';

import { ReactComponent as MenuIcon } from 'src/assets/icons/sidebar/menu.svg';

import Changelog from './Changelog';
import Notification from './Notification';
import User from './User';

import styles from './Header.module.scss';

const localAppVersion = import.meta.env.VITE_APP_VERSION;
const env = import.meta.env.VITE_NODE_ENV;

const Header = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isAdmin = useIsAdmin();

  const isAdminView = useSelector((state) => state.auth.isAdminView);
  const sidebarStore = useSelector((state) => state.sidebar);
  const { isOpen, isMobileSidebarOpen } = sidebarStore;

  return createPortal(
    <header
      className={cn(styles.header, {
        [styles.isSidebarClose]: !isOpen,
        [styles.isMobileOpen]: isMobileSidebarOpen,
        [styles.isAdminView]: isAdminView
      })}
    >
      {isMobile &&
        (isMobileSidebarOpen ? (
          <MenuIcon className={styles.menuIcon} />
        ) : (
          <button onClick={() => dispatch(openMobileSidebar())}>
            <MenuIcon className={styles.menuIcon} />
          </button>
        ))}
      {!isMobile && (
        <div className={styles.companyWrapper}>
          <CompanySelect />
        </div>
      )}
      <div className={styles.control}>
        {!isAdmin && (
          <>
            <Changelog
              localAppVersion={localAppVersion}
              env={env}
            />
            <Notification />
          </>
        )}
        <User />
      </div>
    </header>,
    document.body
  );
};

export default Header;
