import React from 'react';

import { getFormattedPhoneNumber } from 'src/utils/helpers';

import style from './UserThumbnail.module.scss';

const UserThumbnail = ({ name, position, email, phone }) => {
  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <p>{name}</p>
      </div>
      <div className={style.wrapper}>
        <p>{position || '-'}</p>
      </div>
      <div className={style.wrapper}>
        <a href={`mailto:${email}`}>{email}</a>
      </div>
      <div className={style.wrapper}>
        <a href={`tel:${phone}`}>{getFormattedPhoneNumber(phone)}</a>
      </div>
    </div>
  );
};

export default UserThumbnail;
