export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_OFFER = 'ADD_OFFER';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';

export const ADD_ORDER_DETAILS = 'ADD_ORDER_DETAILS';
export const CLEAR_ORDER_DETAILS = 'ADD_ORDER_DETAILS';

export const CHANGE_VALUATIONS_VERSION = 'CHANGE_VALUATIONS_VERSION';

export const OPEN_CART_DETAILS = 'OPEN_CART_DETAILS';
export const CLOSE_CART_DETAILS = 'CLOSE_CART_DETAILS';
export const TOGGLE_CART_DETAILS = 'TOGGLE_CART_DETAILS';

export const UPDATE_SAVINGS = 'UPDATE_SAVINGS';
export const UPDATE_SINGLE_SAVING = 'UPDATE_SINGLE_SAVING';

export const CHANGE_PRODUCT_QUANTITY = 'CHANGE_PRODUCT_QUANTITY';
