import React from 'react';

import { ReactComponent as DownIcon } from 'src/assets/icons/angle-down.svg';
import { ReactComponent as UpIcon } from 'src/assets/icons/angle-up.svg';

import style from './OpenCloseButton.module.scss';

const OpenCloseButton = ({ state, setState }) => {
  return (
    <button
      className={style.button}
      onClick={() => setState((prev) => !prev)}
    >
      {state ? <UpIcon /> : <DownIcon />}
    </button>
  );
};

export default OpenCloseButton;
