import { initialState } from '../features/onboarding/reducers';
import { getLocalStorageItem, getSessionStorageItem } from '../utils/storage';

export const checkIfTokensRemembered = () =>
  getLocalStorageItem('rememberMe') ? getLocalStorageItem('rememberMe') === 'true' : false;

export const getTokensFromStorage = () => {
  const getFromStorage = checkIfTokensRemembered() ? getLocalStorageItem : getSessionStorageItem;

  return {
    access: getFromStorage('access'),
    adminId: getSessionStorageItem('adminId'),
    selectedCompanyId: getFromStorage('selectedCompanyId'),
    isAdminView: JSON.parse(getSessionStorageItem('isAdminView'))
  };
};

const loadState = () => {
  try {
    const { access = '', selectedCompanyId, isAdminView, adminId } = getTokensFromStorage();

    return {
      auth: {
        ...initialState,
        isAuthenticated: access !== '',
        selectedCompanyId,
        isAdminView,
        adminId,
        access
      }
    };
  } catch (err) {
    return undefined;
  }
};

export { loadState };
