import React, { useState } from 'react';

import { Card, Modal, SaveButton, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ExpensesApi } from 'src/api';
import ExpenseCategory from 'src/features/ExpensesManagement/components/ExpenseCategory';
import ExpenseCategoryForm from 'src/features/ExpensesManagement/components/ExpenseCategoryForm';
import MasterCategoryForm from 'src/features/ExpensesManagement/components/MasterCategoryForm';

import style from './MasterCategory.module.scss';

const MasterCategory = ({ category, nestedCategories, refreshCallback }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isCreateVisible, setIsCreateVisible] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const deleteMasterCategoryHandler = () =>
    handleOpenModalConfirm({
      message: 'Czy na pewno chcesz usunąć tag? Aktualnie dodane wydatki zostaną bez zmian.',
      handleConfirm: deleteMasterCategory,
      cancelButtonName: 'Nie',
      confirmButtonName: 'Tak'
    });

  const deleteMasterCategory = async () => {
    try {
      await ExpensesApi.deleteTag(category.id);
      notifyCommon(['Tag został usunięty.']);
      refreshCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const closeModal = () => setIsVisible(false);
  const closeCreateModal = () => setIsCreateVisible(false);

  return (
    <Card className={style.container}>
      <div className={style.header}>
        <h3 className={style.title}>{category.name}</h3>
        <SaveButton
          type={'add'}
          onClick={() => setIsCreateVisible(true)}
        />
        <SaveButton
          type={'edit'}
          onClick={() => setIsVisible(true)}
        />
        <SaveButton
          type={'delete'}
          onClick={deleteMasterCategoryHandler}
        />
      </div>
      <div className={style.wrapper}>
        {nestedCategories?.length > 0 &&
          nestedCategories
            .filter((cat) => cat.tag_name === category.name)
            .map((cat) => (
              <ExpenseCategory
                key={cat.id}
                category={cat}
                refreshCallback={refreshCallback}
              />
            ))}
      </div>
      {renderedModalConfirm}
      <Modal
        visible={isVisible}
        onClose={closeModal}
        title={`Edytuj tag: ${category.name}`}
      >
        <MasterCategoryForm
          closeModal={closeModal}
          refreshCallback={refreshCallback}
          currentName={category.name}
          id={category.id}
        />
      </Modal>
      <Modal
        visible={isCreateVisible}
        onClose={closeCreateModal}
        title={`Dodaj nową kategorie do tagu: ${category.name}`}
      >
        <ExpenseCategoryForm
          closeModal={closeCreateModal}
          refreshCallback={refreshCallback}
          masterCategoryId={category.id}
        />
      </Modal>
    </Card>
  );
};

export default MasterCategory;
