import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LoadingIndicator, useModalConfirm } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { availabilities, productTypes } from 'src/constants/enums';

import { setIsDataSaved, updateProductPricing } from '../../actions';
import {
  getAreItemsEqual,
  getCorrectDataSource,
  getDefaultOffer,
  getDefaultReplacement,
  getNewReplacement,
  getProductTypeAsKey,
  resetDefaultProductValue
} from '../../helpers';
import CommentForm from './forms/CommentForm';
import OfferForm from './forms/OfferForm';
import ProductForm from './forms/ProductForm';
import ReplacementForm from './forms/ReplacementForm';

import style from './Product.module.scss';

export const SAVE_ERROR_DATA_TIMEOUT = 10000;
const SAVE_DATA_TIMEOUT = 1200;
const REMOVE_OFFER_WARNING_MESSAGE = 'Dodanie zamiennika spowoduje usunięcie oferty. Czy chcesz kontynuować?';
const REMOVE_REPLACEMENT_WARNING_MESSAGE = 'Dodanie oferty spowoduje usunięcie zamiennika. Czy chcesz kontynuować?';

const Product = ({ productData }) => {
  const { uid } = useParams();
  const dispatch = useDispatch();
  const commentRef = useRef(null);

  const priceType = useSelector((state) => state.supplierForm.form.price_type);
  const oldProductPrices = useSelector((state) => state.supplierForm.oldProductPrices);
  const isDisabled = useSelector((state) => state.supplierForm.isDisabled);

  const [initialProductData, setInitialProductData] = useState(productData);
  const [product, setProduct] = useState(productData);
  const [offer, setOffer] = useState(getDefaultOffer(productData));
  const [replacement, setReplacement] = useState(getDefaultReplacement(productData));

  const [saveErrorType, setSaveErrorType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isOfferVisible, setIsOfferVisible] = useState(!!productData.offer);
  const [isReplacementVisible, setIsReplacementVisible] = useState(!!productData.replacement);

  const [isCommentVisible, setIsCommentVisible] = useState(!!product.comment);

  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const isProductUnavailable = useMemo(() => product.availability === availabilities.notAvailable, [product]);

  const onChangeHandler = useCallback((value, name) => setProduct((prev) => ({ ...prev, [name]: value })), [setProduct]);
  const saveDataWithTimeout = useCallback(
    (type, item, timeout = SAVE_DATA_TIMEOUT) => setTimeout(() => saveProductHandler(type, item), timeout),
    [initialProductData]
  );

  const removeOfferHandler = async () => {
    await removeOfferOrReplacementHandler();
    setOffer(null);
    setIsOfferVisible(false);
  };
  const removeReplacementHandler = async () => {
    await removeOfferOrReplacementHandler();
    setReplacement(null);
    setIsReplacementVisible(false);
  };

  const removeOfferAddReplacementHandler = async () => {
    try {
      await removeOfferHandler();
      addReplacementHandler(false);
    } catch (err) {
      notifyApiError(err);
    }
  };
  const removeReplacementAddOfferHandler = async () => {
    try {
      await removeReplacementHandler();
      addOfferHandler(false);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const confirmRemovingOfOfferHandler = () => {
    handleOpenModalConfirm({
      message: REMOVE_OFFER_WARNING_MESSAGE,
      handleConfirm: removeOfferAddReplacementHandler
    });
  };
  const confirmRemovingOfReplacementHandler = () => {
    handleOpenModalConfirm({
      message: REMOVE_REPLACEMENT_WARNING_MESSAGE,
      handleConfirm: removeReplacementAddOfferHandler
    });
  };

  const addOfferHandler = (checkIsReplacement = true) => {
    if (replacement && checkIsReplacement) {
      confirmRemovingOfReplacementHandler();
    } else {
      setOffer(product);
      setIsOfferVisible(true);
    }
  };
  const addReplacementHandler = (checkIsOffer = true) => {
    if (offer && checkIsOffer) {
      confirmRemovingOfOfferHandler();
    } else {
      setReplacement(getNewReplacement(product));
      setIsReplacementVisible(true);
    }
  };

  const saveProductHandler = async (type, item) => {
    const toCompare = {
      [productTypes.product]: initialProductData,
      [productTypes.offer]: initialProductData.offer,
      [productTypes.replacement]: initialProductData.replacement
    };

    const areItemsEqual = toCompare[type] && getAreItemsEqual(item, toCompare[type], type);

    if (!item || areItemsEqual) {
      setIsLoading(false);
      dispatch(setIsDataSaved(false));
      return;
    }

    if (type === productTypes.product) dispatch(updateProductPricing(item));

    const data = {
      uid,
      ...item,
      ...getProductTypeAsKey(type)
    };

    try {
      setIsLoading(true);
      dispatch(setIsDataSaved(true));
      setSaveErrorType(null);
      await SupplierApi.savePrice(data);
      resetDefaultProductValue(type, item, setInitialProductData);
    } catch (err) {
      setSaveErrorType(type);
      notifyApiError(err);
    } finally {
      setIsLoading(false);
      dispatch(setIsDataSaved(false));
    }
  };

  const removeOfferOrReplacementHandler = async () => {
    try {
      setIsLoading(true);
      dispatch(setIsDataSaved(true));
      await SupplierApi.removeOfferOrReplacement({ uid, id: product.id });
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
      dispatch(setIsDataSaved(false));
    }
  };

  const addCommentHandler = () => {
    if (commentRef.current) commentRef.current.focus();
    if (!product.comment) setIsCommentVisible(true);
  };

  const mainProductMenuOptions = [
    {
      title: 'Dodaj specjalną ofertę',
      icon: 'add',
      action: addOfferHandler,
      disabled: isDisabled || !!offer || isProductUnavailable
    },
    {
      title: 'Zaproponuj zamiennik',
      icon: 'replacement',
      action: addReplacementHandler,
      disabled: isDisabled || !!replacement
    },
    {
      title: 'Dodaj komentarz',
      icon: 'message',
      action: addCommentHandler,
      disabled: isDisabled
    }
  ];

  useEffect(() => {
    if (isProductUnavailable && !!offer) removeOfferHandler();
    const timeout = saveDataWithTimeout(productTypes.product, product);
    return () => clearTimeout(timeout);
  }, [product]);

  useEffect(() => {
    const timeout = saveDataWithTimeout(productTypes.offer, offer);
    return () => clearTimeout(timeout);
  }, [offer]);

  useEffect(() => {
    const timeout = saveDataWithTimeout(productTypes.replacement, replacement);
    return () => clearTimeout(timeout);
  }, [replacement]);

  useEffect(() => {
    let timeout;
    const dataSource = getCorrectDataSource(saveErrorType, product, offer, replacement);
    if (dataSource) timeout = saveDataWithTimeout(saveErrorType, dataSource, SAVE_ERROR_DATA_TIMEOUT);
    return () => clearTimeout(timeout);
  }, [saveErrorType]);

  useEffect(() => {
    const oldProductData = oldProductPrices.find((prod) => prod.id === product.id);
    if (oldProductData) {
      setProduct((prev) => ({
        ...prev,
        price: oldProductData.price,
        tax: oldProductData.tax
      }));
    }
  }, [oldProductPrices]);

  return (
    <div className={style.mainContainer}>
      <LoadingIndicator
        isLoading={isLoading}
        hasError={!!saveErrorType}
      />
      <ProductForm
        product={product}
        setProduct={setProduct}
        menu={mainProductMenuOptions}
        priceType={priceType}
      />
      {isOfferVisible && (
        <OfferForm
          product={offer}
          setProduct={setOffer}
          removeHandler={removeOfferHandler}
        />
      )}
      {isReplacementVisible && (
        <ReplacementForm
          currentProduct={product}
          product={replacement}
          setProduct={setReplacement}
          removeHandler={removeReplacementHandler}
          isLoading={isLoading}
          setAutoSaveLoading={setIsLoading}
        />
      )}
      {isCommentVisible && (
        <CommentForm
          ref={commentRef}
          comment={product.comment}
          setComment={(value) => onChangeHandler(value, 'comment')}
          priceType={priceType}
          parentId={product.id}
        />
      )}
      {renderedModalConfirm}
    </div>
  );
};

export default Product;
