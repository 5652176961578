import React from 'react';
import { Popover } from 'antd';

import { Tag } from 'components';

import { productStatuses } from 'src/constants/enums';
import { getProductStatusTag } from 'src/utils/helpers';

import style from './ProductStatusExtended.module.scss';

const ProductStatusExtended = ({ product, className }) => {
  const { received_quantity, send_quantity, qty, unit_name, status } = product;

  const content = (
    <div className={style.box}>
      <div className={style.header}>
        <span>Zamówiona ilość produktu:</span>{' '}
        <p>
          {qty} {unit_name}
        </p>
      </div>
      {qty - Math.max(received_quantity, send_quantity) > 0 && (
        <div>
          {getProductStatusTag(productStatuses.processing)}{' '}
          <p>
            {qty - Math.max(received_quantity, send_quantity)} {unit_name}
          </p>
        </div>
      )}
      {send_quantity - received_quantity > 0 && (
        <div>
          {getProductStatusTag(productStatuses.sent)}{' '}
          <p>
            {send_quantity - received_quantity} {unit_name}
          </p>
        </div>
      )}
      {received_quantity > 0 && (
        <div>
          {getProductStatusTag(productStatuses.completed)}{' '}
          <p>
            {received_quantity} {unit_name}
          </p>
        </div>
      )}
    </div>
  );

  const disableContent = status === productStatuses.completed;

  if (!disableContent && send_quantity > 0 && send_quantity < qty) {
    return (
      <Popover
        content={content}
        placement={'top'}
      >
        <div>
          <Tag
            value={`Wysłano ${send_quantity} / ${qty} ${unit_name ? unit_name : ''}`}
            className={className}
            color={status === productStatuses.sent ? 'blue' : 'yellow'}
          />
        </div>
      </Popover>
    );
  }

  if (!disableContent && received_quantity > 0 && received_quantity < qty) {
    return (
      <Popover
        content={content}
        placement={'top'}
      >
        <div>
          <Tag
            value={`Zrealizowano ${received_quantity} / ${qty} ${unit_name ? unit_name : ''}`}
            className={className}
            color={status === productStatuses.sent ? 'blue' : 'yellow'}
            withWarning
          />
        </div>
      </Popover>
    );
  }

  return getProductStatusTag(product.status, false, className);
};

export default ProductStatusExtended;
