import React, { useState } from 'react';
import { useParams } from 'react-router';

import Attachments from '../../../Attachments';
import Communication from '../../../Communication';
import Summary from '../../../Summary';
import Table from '../../components/ProductsTable';
import SupplierDetails from '../../components/SupplierDetails';

import style from '../../OrderCart.module.scss';

const ApprovalCart = (props) => {
  const { cart, refreshDataCallback, token } = props;
  const {
    messages,
    attachments,
    products,
    status,
    total_sum: totalSum,
    supplier_id: supplierId,
    created_at: createdAt,
    supplier_pricing_comment,
    user_comment,
    supplier_name
  } = cart || {};

  const [selectedProducts, setSelectedProducts] = useState(products || []);

  const params = useParams();

  return (
    <div className={style.wrapper}>
      <SupplierDetails
        supplier={{ ...cart, name: supplier_name }}
        products={products}
        totalSum={totalSum}
      />
      <Table
        token={token}
        refreshDataCallback={refreshDataCallback}
        setSelectedProducts={setSelectedProducts}
        selectedProducts={selectedProducts}
        products={products}
        status={status}
        hideProductActions
      />
      <Communication
        orderCreatedAt={createdAt}
        messages={messages}
        refreshHandler={refreshDataCallback}
        pricingComment={supplier_pricing_comment}
        userComment={user_comment}
        hideInput
      />
      {attachments?.length > 0 && (
        <Attachments
          uid={params.uid}
          attachments={attachments}
          supplierId={supplierId}
          refreshDataCallback={refreshDataCallback}
        />
      )}
      <Summary
        totalSum={cart?.total_sum}
        productsSum={cart?.products_sum}
        payMethod={cart?.pay_method}
        shipMethod={cart?.ship_method}
        hideShipment
      />
    </div>
  );
};

export default ApprovalCart;
