import React, { useRef } from 'react';

const useRequestAbortController = () => {
  const abortController = useRef(null);

  const handleSetNewController = () => {
    abortController.current = new AbortController();
    const { signal } = abortController.current;
    return signal;
  };

  return [abortController.current, handleSetNewController];
};

export default useRequestAbortController;
