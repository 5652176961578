import React, { useEffect, useState } from 'react';

import { SwitchButton } from 'components';

import style from './SupplierSwitch.module.scss';

const SupplierSwitch = ({ supplier, onChange, isLoading, onlyOneVisible }) => {
  const [isVisible, setIsVisible] = useState(!!supplier.is_visible);

  useEffect(() => {
    setIsVisible(!!supplier.is_visible);
  }, [supplier]);

  return (
    <div className={style.container}>
      <p className={style.name}>{supplier.name}</p>
      <SwitchButton
        value={isVisible}
        setValue={setIsVisible}
        onClick={onChange}
        disabled={isLoading || (onlyOneVisible && isVisible)}
      />
    </div>
  );
};

export default SupplierSwitch;
