import React, { useEffect, useState } from 'react';

import { Button } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';

import SingleProduct from './components/SingleProduct';

import style from '../../../../OrderCart.module.scss';

const getDefaultProduct = (prod) => ({
  ...prod,
  currentQuantity: Math.min(prod.qty - prod.send_quantity, prod.qty - prod.received_quantity)
});

const ConfirmOrderSupplierForm = ({
  selectedProducts,
  uid,
  isLoading,
  setIsLoading,
  closeConfirmOrderModal,
  refreshDataCallback
}) => {
  const [products, setProducts] = useState(selectedProducts.map(getDefaultProduct));

  const onProductChange = (product) => {
    setProducts((prev) => prev.map((item) => (item.id === product.id ? product : item)));
  };

  const sendProductsHandler = async () => {
    const queryData = {
      suborder_uid: uid,
      products: products
        .filter((prod) => prod.currentQuantity > 0)
        .map((prod) => ({ product_id: prod.product_id, send_quantity: prod.currentQuantity }))
    };

    try {
      setIsLoading(true);
      await SupplierApi.sendPartialProducts(queryData);
      if (refreshDataCallback) await refreshDataCallback();
      if (closeConfirmOrderModal) await closeConfirmOrderModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedProducts.length === 0) closeConfirmOrderModal();
    setProducts(selectedProducts.map(getDefaultProduct));
  }, [selectedProducts]);

  return (
    <div className={style.sendProductForm}>
      <p>
        Zadeklaruj ilości produktów do wysyłki. Pozostałe produkty będą nadal oczekiwały na wysyłkę, możesz ją realizować
        kilkukrotnie.
      </p>
      <div className={style.list}>
        {products.map((product, index) => (
          <SingleProduct
            onChange={onProductChange}
            isLoading={isLoading}
            product={product}
            key={product.id}
            index={index}
          />
        ))}
      </div>
      <div className={style.formButtonsContainer}>
        <Button
          label={'Cofnij'}
          onClick={closeConfirmOrderModal}
          gray
        />
        <Button
          label={'Zatwierdź'}
          onClick={sendProductsHandler}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default ConfirmOrderSupplierForm;
