import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Button, EmptyState } from 'components';
import { notifyApiError } from 'components/layout/Toasts';
import ValuationModalProduct from 'components/valuations/ValuationModalProduct';

import { ValuationsApi } from 'src/api';

import { refreshValuations } from '../../../../actions';

import style from './OfferSelect.module.scss';
import shared from 'styles/Shared.module.scss';

const OfferSelect = ({ item, closeModal }) => {
  const [valuation, setValuation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleChangeSupplier = async () => {
    setIsLoading(true);

    const changeSupplierParams = {
      pricing_prod_id: item.pricing_prod_id,
      offer_id: valuation.id
    };

    const changeSelectedProductParams = {
      pricing_prod_id: item.pricing_prod_id,
      dist_id: valuation.dist_id
    };

    try {
      await ValuationsApi.changeSupplierOffer(changeSupplierParams);
      await ValuationsApi.changeSelectedProductDistributor(changeSelectedProductParams);
      dispatch(refreshValuations());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValuation(item.offers.find((offer) => offer.id === item.id));
  }, []);

  return (
    <div className={style.container}>
      <section className={shared.tableWrapper}>
        <div className={classNames(style.header)}>
          <p></p>
          <p>Nazwa</p>
          <p>Dostepność</p>
          <p>Ilość</p>
          <p>Cena (za szt.)</p>
          <p>Wartość</p>
        </div>
        <div className={shared.tableCategory}>
          {item.offers?.length ? (
            item.offers?.map((sup) => (
              <ValuationModalProduct
                key={sup.id}
                product={sup}
                setValuation={setValuation}
                valuation={valuation}
              />
            ))
          ) : (
            <div className={shared.tableCategory}>
              <h3 className={shared.tableCategoryTitle}>
                <EmptyState type={'suppliers'} />
              </h3>
            </div>
          )}
          <div className={style.buttonWrapper}>
            <Button
              onClick={handleChangeSupplier}
              label={'Zapisz'}
              disabled={valuation?.length === 0}
              isLoading={isLoading}
              className={style.button}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default OfferSelect;
