import * as types from '../actions/types';

export const initialState = {
  notifications: [],
  oldNotifications: [],
  hasUnread: false,
  version: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_VERSION: {
      return {
        ...state,
        version: state.version + 1
      };
    }
    case types.RESET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: []
      };
    }
    case types.NEW_NOTIFICATION: {
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
        hasUnread: true
      };
    }
    case types.SET_OLD_NOTIFICATIONS: {
      return {
        ...state,
        oldNotifications: action.payload,
        hasUnread: state.hasUnread || action.payload.filter((ntf) => !ntf.read_at).length > 0
      };
    }
    case types.SET_ALL_NOTIFICATIONS_AS_READ:
      return {
        ...state,
        hasUnread: false,
        notifications: state.notifications.map((notification) => ({
          ...notification,
          read_at: new Date().toISOString()
        }))
      };
    case types.SET_NOTIFICATION_AS_READ: {
      const setAsRead = (notification) =>
        notification.id === action.payload ? { ...notification, read_at: new Date().toISOString() } : notification;
      const newNtfs = state.notifications.map(setAsRead);
      const oldNtfs = state.oldNotifications.map(setAsRead);
      const allNtfs = [...newNtfs, ...oldNtfs];
      return {
        ...state,
        hasUnread: allNtfs.filter((ntf) => !ntf.read_at).length > 0,
        notifications: newNtfs,
        oldNotifications: oldNtfs
      };
    }
    case types.SET_DEFAULT_STATE:
      return initialState;
    default:
      return state;
  }
};
