import React from 'react';
import classNames from 'classnames';

import { calculateFinalProductPrice } from 'src/utils/helpers';

import style from '../ProductComponents.module.scss';

const ItemValue = ({ isMobile, priceType, price, quantity, tax }) => {
  return (
    <div
      className={classNames(style.wrapper, style.amountWrapper)}
      id={'final-price'}
    >
      {isMobile && <p className={style.text}>Wartość: </p>}
      <p className={style.numberValue}>{calculateFinalProductPrice(priceType, price, quantity, 0, tax)}</p>
    </div>
  );
};

export default ItemValue;
