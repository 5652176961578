import React from 'react';

import { ReactComponent as SuppliersIcon } from 'src/assets/icons/users-list.svg';

import style from './OtherSuppliers.module.scss';

const OtherSuppliers = ({ onClick, count }) => {
  return (
    <button
      onClick={onClick}
      className={style.button}
      title={'Wszystkie oferty'}
    >
      <div className={style.container}>
        <div className={style.box}>
          <SuppliersIcon className={style.icon} />
          <span className={style.counter}>{count}</span>
        </div>
      </div>
    </button>
  );
};

export default OtherSuppliers;
