import React from 'react';
import { Grid, Placeholder, Segment } from 'semantic-ui-react';

import style from './ThumbnailPlaceholderSmall.module.scss';

const ThumbnailPlaceholderSmall = ({ quantity = 2 }) => {
  const getElements = () => {
    let elements = [];
    for (let i = 0; i < quantity; i++) {
      elements.push(
        <div
          style={{ height: '212px', width: '100%' }}
          key={'placeholder' + i}
        >
          <Segment
            raised
            className={style.container}
          >
            <Grid
              columns={1}
              stackable
            >
              <Grid.Column className={style.columnWrapper}>
                <Placeholder className={style.leftWrapper}>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length='long' />
                  </Placeholder.Paragraph>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length='short' />
                    <Placeholder.Line length='medium' />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Grid.Column>
              <Grid.Column className={style.columnWrapper}>
                <Placeholder className={style.rightWrapperSmall}>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length='very long' />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Grid.Column>
            </Grid>
          </Segment>
        </div>
      );
    }
    return elements;
  };

  return getElements();
};

export default ThumbnailPlaceholderSmall;
