import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { Button, CustomTooltip, LoaderGlobal, useAuthUser, useModalConfirm, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { userRoles, valuationStatuses as statuses } from 'src/constants/enums';
import Freebies from 'src/features/SupplierForm/components/Freebies';
import OrderComments from 'src/features/SupplierForm/components/OrderComments';
import ProductsTable from 'src/features/SupplierForm/components/ProductsTable';

import SeoHelmet from '../../components/layout/SeoHelmet';
import { KATE_PHONE_NUMBER, KATE_PHONE_NUMBER_FORMATTED } from '../../constants/misc';
import { scrollToErrors } from '../../utils/helpers';
import ClientDetails from './components/ClientDetails';
import FooterHeader from './components/FooterHeader';
import FooterInfo from './components/FooterInfo';
import OrderDetails from './components/OrderDetails';
import ProgressAndSum from './components/ProgressAndSum';
import ValuationDetails from './components/ValuationDetails';
import ValuationHeader from './components/ValuationHeader';
import {
  clearSupplierFormStore,
  createProductsPricing,
  getFormData,
  increaseNumberOfValidations,
  setInitialFormData,
  setIsDataSaved,
  setNewValidator,
  updateIsDisabled
} from './actions';
import { areFormDataEqual, getProductsFromCategories, getSelectedId } from './helpers';

import style from './SupplierForm.module.scss';

const SupplierForm = ({ inPublicPage }) => {
  const { uid } = useParams();
  const user = useAuthUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const localValidator = useValidator();

  const [sendLoading, setSendLoading] = useState(false);
  const [wasSavingError, setWasSavingError] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [savingErrorMessage, setSavingErrorMessage] = useState(null);

  const store = useSelector((state) => state.supplierForm);
  const { status, isLoading, public_id, initialForm, form, validator, isDisabled, company, isDataSaved } = store;

  const getData = async () => {
    try {
      const { data } = await dispatch(getFormData({ uid }));
      dispatch(createProductsPricing(getProductsFromCategories(data.categories)));
    } catch (err) {
      if (err.response.status === 401) {
        notifyCommon(['Zaloguj się, aby zobaczyć wycenę']);
        history.push(`/supplier/valuations/${uid}`);
      } else {
        notifyApiError(err);
        history.push('/dashboard');
      }
    }
  };

  const saveAll = async (e) => {
    e?.preventDefault();
    if (areFormDataEqual(form, initialForm)) return;

    const queryParams = {
      uid,
      formData: {
        ...form,
        price_type: form.price_type.value,
        payments: getSelectedId(form.payments),
        shipments: getSelectedId(form.shipments),
        gifts: form.gifts.filter((gift) => !!gift)
      }
    };

    try {
      setWasSavingError(false);
      setSavingErrorMessage(null);
      dispatch(setIsDataSaved(true));
      await SupplierApi.saveFormData(queryParams);
      dispatch(setInitialFormData());
    } catch (err) {
      notifyApiError(err);
      setWasSavingError(true);
      setSavingErrorMessage(err.response.data.message || null);
    } finally {
      dispatch(setIsDataSaved(false));
    }
  };

  const sendForm = async () => {
    try {
      setSendLoading(true);
      await SupplierApi.sendValuation({ uid });
      notifyCommon(['Wycena została wysłana do klienta.']);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setSendLoading(false);
      getData();
    }
  };

  const sendFormHandler = (e) => {
    e.preventDefault();
    validator.autoForceUpdate.forceUpdate();

    if (!validator.allValid()) {
      validator.showMessages();
      validator.autoForceUpdate.forceUpdate();
      dispatch(increaseNumberOfValidations());
      scrollToErrors();
      return;
    }

    handleOpenModalConfirm({
      message: 'Po wysłaniu wyceny nie będziesz mógł już zmienić jej parametrów. Czy chcesz kontynuować?',
      handleConfirm: () => sendForm()
    });
  };

  const valuationName = useMemo(
    () => (company?.name ? `Wycena dla: ${company?.name}` : `Wycena numer: #${public_id}`),
    [company, public_id]
  );

  useEffect(() => {
    const timeout = setTimeout(saveAll, 500);
    return () => clearTimeout(timeout);
  }, [form]);

  useEffect(() => {
    dispatch(updateIsDisabled(status !== statuses.pending && user?.role !== userRoles.admin));
  }, [status, user]);

  useEffect(() => {
    getData();
    dispatch(setNewValidator(localValidator));

    return () => {
      dispatch(clearSupplierFormStore());
      validator?.purgeFields();
    };
  }, []);

  const sendFormButton = (
    <Button
      id={'send-form'}
      className={style.button}
      iconName={'send'}
      onClick={sendFormHandler}
      label={'Wyślij'}
      disabled={isDisabled || isDataSaved}
      isLoading={sendLoading}
    />
  );

  const tooltipContent = (
    <p>Formularz zapisuje się automatycznie po każdej zmianie. Klient otrzyma wycenę dopiero, gdy ją wyślesz.</p>
  );

  const tooltipErrorContent = (
    <p>
      Wystąpił błąd podczas zapisywania formularza. Skontaktuj się z nami:
      <br />
      <a
        className={style.link}
        href={`tel:${KATE_PHONE_NUMBER}`}
      >
        {KATE_PHONE_NUMBER_FORMATTED}
      </a>
      {!!savingErrorMessage && (
        <>
          <br />
          <br />
          Błąd: <br />
          <i>{savingErrorMessage}</i>
        </>
      )}
    </p>
  );

  const autoSaveButton = !isDisabled && (
    <CustomTooltip title={wasSavingError ? tooltipErrorContent : tooltipContent}>
      <Button
        label={wasSavingError ? 'Wystąpił błąd!' : isDataSaved ? 'Zapisywanie' : 'Zapisano'}
        iconName={wasSavingError ? 'phone' : 'tick'}
        isLoading={isDataSaved}
        gray
      />
    </CustomTooltip>
  );

  if (isLoading) {
    return <LoaderGlobal />;
  }

  return (
    <>
      <SeoHelmet title={valuationName || 'Wycena'} />
      <ValuationHeader
        sendFormButton={sendFormButton}
        autoSaveButton={autoSaveButton}
        valuationName={valuationName}
      />
      <div className={style.container}>
        <ValuationDetails uid={uid} />
        <ClientDetails />
        <ProgressAndSum />
        <ProductsTable
          inPublicPage={inPublicPage}
          productsType={'in_collection'}
        />
        <ProductsTable
          inPublicPage={inPublicPage}
          productsType={'not_in_collection'}
        />
        <OrderComments />
        <Freebies />
        <OrderDetails />
        <FooterHeader
          valuationName={valuationName}
          sendFormButton={sendFormButton}
          autoSaveButton={autoSaveButton}
        />
      </div>
      <FooterInfo />
      {renderedModalConfirm}
    </>
  );
};

export default SupplierForm;
