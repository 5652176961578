import React from 'react';

import { CustomTooltip } from 'components';

import { ReactComponent as CartIcon } from 'src/assets/icons/cart-clock.svg';
import { toDateNumber, toTime } from 'src/utils/dateTime';

import style from './PricesCounter.module.scss';

const RecentlyOrdered = ({ data }) => {
  if (!data?.created_at) {
    return null;
  }

  const { created_at } = data;
  const text = (
    <p>
      Ten produkt został ostatnio zamówiony ({toDateNumber(created_at)}, {toTime(created_at)})
    </p>
  );

  return (
    <CustomTooltip title={text}>
      <CartIcon className={style.icon} />
    </CustomTooltip>
  );
};

export default RecentlyOrdered;
