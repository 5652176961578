import React, { useMemo } from 'react';

import { PriceChangeDifference, RelativeTime } from 'components';
import InfoWidget from 'components/layout/InfoWidget';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import { calculateDifference, getFormattedAmount, getPriceTypeContent } from 'src/utils/helpers';

import styles from './ChangedPricesProductThumbnail.module.scss';

const ChangedPricesProductThumbnail = ({ price, previous_price, name, thumbnail, image, product_id, created_at, add_type }) => {
  const difference = calculateDifference(previous_price, price);

  const { label: priceAddTypeLabel } = useMemo(() => getPriceTypeContent(add_type), [add_type]);

  return (
    <div className={styles.container}>
      <div className={styles.productThumbnail}>
        <ProductThumbnailImage
          thumbnail={thumbnail}
          image={image}
          title={name}
          id={product_id}
        />
        <span>{name}</span>
      </div>
      <div>{previous_price ? `${getFormattedAmount(previous_price)}` : '-'}</div>
      <div className={styles.priceContainer}>
        {price ? `${getFormattedAmount(price)}` : '-'}
        <InfoWidget place={'top'}>
          <p>{priceAddTypeLabel}</p>
        </InfoWidget>
      </div>
      <div>
        {previous_price ? (
          <PriceChangeDifference
            difference={{ percentage: difference.percentageDifference, numeric: difference.numericDifference }}
          />
        ) : (
          '-'
        )}
      </div>
      <div>
        <RelativeTime date={created_at} />
      </div>
    </div>
  );
};

export default ChangedPricesProductThumbnail;
