import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';

import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';

import { refreshCartOrCatalog } from '../../../../actions';
import SelectedSupplierWrapper from '../../../SelectedSupplierWrapper';
import SupplierThumbnail from '../SupplierThumbnail';

const SupplierDropzone = ({ isDragged, selectedSupplier, product, priority, disabled }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const collectHandler = (monitor) => ({ isOver: !!monitor.isOver() });
  const changeSupplierHandler = async (sup) => {
    const data = { id: product.id, supplier_id: sup.supplier_id, priority };

    try {
      setIsLoading(true);
      await OfferCatalogApi.setPreferredSupplier(data);
      dispatch(refreshCartOrCatalog());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const [collectedProps, drop] = useDrop(
    () => ({
      accept: 'supplier',
      canDrop: () => !disabled,
      drop: changeSupplierHandler,
      collect: collectHandler
    }),
    [disabled]
  );

  return (
    <SelectedSupplierWrapper
      disabled={disabled}
      isOver={collectedProps.isOver}
      isDragged={isDragged}
      isLoading={isLoading}
      ref={drop}
      smaller
    >
      {selectedSupplier && <SupplierThumbnail item={selectedSupplier} />}
    </SelectedSupplierWrapper>
  );
};

export default SupplierDropzone;
