import React from 'react';

import { CustomTooltip } from 'components';

import style from '../../PermissionsForm.module.scss';

const RoleHeader = ({ value = '', info = '' }) => {
  return (
    <p className={style.columnName}>
      <CustomTooltip title={<p>{info}</p>}>{value}</CustomTooltip>
    </p>
  );
};

export default RoleHeader;
