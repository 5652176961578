import React from 'react';

import { CopyToClipboard } from 'components';

import styles from '../../NotExistingProductsTable.module.scss';

const NotExistingProductTableThumbnail = ({ name, id }) => {
  return (
    <div className={styles.notExistingProductTableThumbnail}>
      <p>
        <CopyToClipboard
          text={name}
          renderElement={(text) => <span>{text}</span>}
        />
      </p>
      <p>
        <CopyToClipboard
          text={id}
          renderElement={(text) => <span>{text}</span>}
        />
      </p>
    </div>
  );
};

export default NotExistingProductTableThumbnail;
