import React from 'react';
import Skeleton from 'react-loading-skeleton';
import cn from 'classnames';

import style from './LabelWithValue.module.scss';

const LabelWithValue = ({ label, value, dangerWhenFalsy, dangerValue, className, labelClasses, isLoading }) => {
  return (
    <div className={cn(style.container, { [style.falsy]: dangerWhenFalsy && !dangerValue }, className)}>
      <p className={cn(style.label, labelClasses)}>{label}</p>
      <div className={style.value}>
        {isLoading ? (
          <Skeleton
            width={100}
            height={20}
          />
        ) : (
          value
        )}
      </div>
    </div>
  );
};

export default LabelWithValue;
