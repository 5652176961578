import * as types from '../actions/types';

export const initialState = {
  products: [],
  orderDetails: [],
  valuationsVersion: 0,
  isSummaryOpen: false,
  newQuantityProducts: [],
  savings: {
    products: [],
    sum: 0
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_PRODUCT: {
      const products = state.products.filter((prod) => prod.product_id !== action.payload.product_id);

      return {
        ...state,
        products: [
          ...products,
          {
            product_id: action.payload.product_id,
            valuation: action.payload.valuation_id,
            price: action.payload.price,
            discount: action.payload.discount,
            supplier_id: action.payload.supplier_id,
            type: action.payload.type
          }
        ]
      };
    }
    case types.CHANGE_PRODUCT_QUANTITY:
      return {
        ...state,
        newQuantityProducts: [...state.newQuantityProducts.filter((item) => item.id !== action.payload.id), action.payload]
      };
    case types.UPDATE_SAVINGS:
      return {
        ...state,
        savings: {
          products: action.payload.products,
          sum: action.payload.savings_sum
        }
      };
    case types.UPDATE_SINGLE_SAVING: {
      const newProducts = state.savings.products.filter((prod) => {
        return prod.product_id !== action.payload.products_pricings_id;
      });

      newProducts.push({
        product_id: action.payload.products_pricings_id,
        saved_money: action.payload.saved_money
      });

      let newSum = 0;
      newProducts.forEach((prod) => (newSum += prod.saved_money));

      return {
        ...state,
        savings: {
          products: newProducts,
          sum: newSum
        }
      };
    }
    case types.ADD_OFFER: {
      const products = state.products.filter((prod) => prod.product_id !== action.payload.product_id);

      return {
        ...state,
        products: [
          ...products,
          {
            product_id: action.payload.product_id,
            offer: action.payload.offer_id,
            price: action.payload.price,
            discount: action.payload.discount,
            supplier_id: action.payload.supplier_id,
            type: action.payload.type
          }
        ]
      };
    }
    case types.ADD_ORDER_DETAILS: {
      const details = state.orderDetails.filter((order) => order?.supplier_id !== action.payload?.supplier_id);

      return {
        ...state,
        orderDetails: [...details, action.payload]
      };
    }
    case types.CLEAR_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: []
      };
    case types.CLEAR_PRODUCTS:
      return {
        ...state,
        products: []
      };
    case types.CHANGE_VALUATIONS_VERSION:
      return {
        ...state,
        valuationsVersion: (state.valuationsVersion += 1)
      };
    case types.CLOSE_CART_DETAILS:
      return {
        ...state,
        isSummaryOpen: false
      };
    case types.OPEN_CART_DETAILS:
      return {
        ...state,
        isSummaryOpen: true
      };
    case types.TOGGLE_CART_DETAILS:
      return {
        ...state,
        isSummaryOpen: !state.isSummaryOpen
      };
    default:
      return state;
  }
};
