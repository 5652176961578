import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, FloatingCard, useModalConfirm, usePermissions } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OrdersApi } from 'src/api';
import { orderStatuses, userPermissions } from 'src/constants/enums';

import { refreshOrder } from '../../actions';

const SentToApproval = ({ uid, status }) => {
  const dispatch = useDispatch();
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isLoading, setIsLoading] = useState(false);
  const [canCreateOrder] = usePermissions([userPermissions.orders.create]);

  const sentToApproval = async () => {
    try {
      setIsLoading(true);
      await OrdersApi.sentToApproval(uid);
      dispatch(refreshOrder());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const sentToApprovalHandler = () =>
    handleOpenModalConfirm({
      message: 'Czy na pewno chcesz wysłać zamówienie do akceptacji?',
      handleConfirm: sentToApproval
    });

  if (status !== orderStatuses.draft) {
    return null;
  }

  return (
    <FloatingCard
      title={'Zamówienie osiągnęło próg wymogu akceptacji'}
      description={'Dodaj niezbędne dokumenty i komentarze, a następnie wyślij do akceptacji.'}
      trackLeftPosition
      yellow
    >
      {canCreateOrder && (
        <>
          <Button
            onClick={sentToApprovalHandler}
            label={'Wyślij do akceptacji'}
            isLoading={isLoading}
          />
          {renderedModalConfirm}
        </>
      )}
    </FloatingCard>
  );
};

export default SentToApproval;
