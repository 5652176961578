import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  EmptyState,
  FilterByCompany,
  PaginationNav,
  Searchbar,
  SeoHelmet,
  StickyPageHeader,
  useIsMobile,
  useRequestAbortController
} from 'components';
import ColumnList from 'components/layout/ColumnList';
import { notifyApiError } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import query from 'src/utils/query';

import pages from '../../dictionaries/pages.json';
import CollectionThumbnail from './components/CollectionThumbnail';

import style from './Collections.module.scss';

const Collections = (props) => {
  const params = query(props);
  const isMobile = useIsMobile();

  const [pageData, setPageData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [search, setSearch] = useState();
  const [queryCompanyId, setQueryCompanyId] = useState();
  const [wasDataLoaded, setWasDataLoaded] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();

  const version = useSelector((state) => state.collections.version);

  const getData = async () => {
    let error;
    const params = {
      page: currentPage || 1,
      company_id: queryCompanyId,
      perPage,
      search
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await SupplierApi.getCollections(params, signal);
      setPageData(data);
      setPageQty(data?.last_page);
      setIsLoading(false);
    } catch (err) {
      notifyApiError(err);
      error = err;
    } finally {
      setIsLoading(error?.message === 'canceled');
      if (!wasDataLoaded) setWasDataLoaded(true);
    }
  };

  useEffect(() => {
    getData();
  }, [version, search, currentPage, perPage, queryCompanyId]);

  useEffect(() => {
    setCurrentPage(params.get('page'));
    setSearch(params.get('search'));
    setQueryCompanyId(params.get('company'));
  }, [params]);

  const headerContent = (
    <>
      <Searchbar params={params} />
      <FilterByCompany params={params} />
    </>
  );

  return (
    <div className={style.container}>
      <SeoHelmet title={pages.collections.title} />
      <StickyPageHeader name={pages.collections.title}>{headerContent}</StickyPageHeader>
      <ColumnList
        isLoading={isLoading}
        list={pageData?.data}
        component={CollectionThumbnail}
        emptyState={<EmptyState type={params.get('search') ? 'search' : 'collections'} />}
      />
      <PaginationNav
        onClick={(number) => setCurrentPage(number)}
        params={params}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
      />
    </div>
  );
};

export default Collections;
