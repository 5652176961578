import { ReactComponent as ApprovalIcon } from 'assets/icons/newSidebar/approval.svg';
import { ReactComponent as CompaniesIcon } from 'assets/icons/newSidebar/companies.svg';
import { ReactComponent as PeopleIcon } from 'assets/icons/newSidebar/users.svg';
import { ReactComponent as WarehouseIcon } from 'assets/icons/newSidebar/warehouse.svg';
import { ReactComponent as ShippingIcon } from 'assets/icons/profile/addresses.svg';
import { ReactComponent as CompanyIcon } from 'assets/icons/profile/building.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/profile/notification.svg';
import { ReactComponent as UserIcon } from 'assets/icons/profile/user.svg';

import { userPermissions, userRoles } from 'src/constants/enums';
import { ordersApprovalEnabled } from 'src/utils/companyAccessFunctions';

const { company } = userPermissions;
const { user, admin, distributor, supplier } = userRoles;

export const desktopLinks = [
  {
    title: 'User menu',
    dropdown: [
      {
        id: 1,
        title: 'Twoje dane',
        url: '/profile',
        icon: <UserIcon />,
        exact: true,
        roles: [user, admin, supplier, distributor]
      }
    ]
  },
  {
    title: 'Company menu',
    dropdown: [
      {
        id: 3,
        title: 'Dane oddziału',
        url: '/profile/company',
        icon: <CompanyIcon />,
        roles: [user]
      },
      {
        id: 4,
        title: 'Adresy dostaw',
        url: '/profile/address',
        icon: <ShippingIcon />,
        roles: [user]
      },
      {
        id: 5,
        title: 'Personel oddziału',
        url: '/profile/members-management',
        icon: <PeopleIcon />,
        roles: [user],
        requiredPermission: company.admin
      },
      {
        id: 6,
        title: 'Akceptacja zamówień',
        url: '/profile/orders-approval',
        icon: <ApprovalIcon />,
        roles: [user],
        requiredPermission: company.admin,
        companyAccessFn: ordersApprovalEnabled
      },
      {
        id: 7,
        title: 'Centrum powiadomień',
        url: '/profile/notifications',
        icon: <NotificationIcon />,
        roles: [user, admin, supplier]
      },
      {
        id: 8,
        title: 'Zarządzanie oddziałami',
        url: '/profile/companies-management',
        icon: <CompaniesIcon />,
        roles: [user],
        requiredPermission: company.admin
      },
      {
        id: 9,
        title: 'Jednostki ogranizacyjne',
        url: '/profile/warehouse',
        icon: <WarehouseIcon />,
        roles: [user],
        requiredPermission: company.admin
      }
    ]
  }
];

export const mobileLinks = [
  {
    id: 1,
    label: 'Twoje dane',
    value: '/profile',
    exact: true,
    roles: [user, admin, supplier, distributor],
    forCompanyAdmin: false
  },
  {
    id: 3,
    label: 'Dane oddziału',
    value: '/profile/company',
    roles: [user],
    forCompanyAdmin: false
  },
  {
    id: 4,
    label: 'Adresy dostaw',
    value: '/profile/address',
    roles: [user],
    forCompanyAdmin: false
  },
  {
    id: 7,
    label: 'Personel oddziału',
    value: '/profile/members-management',
    roles: [user],
    forCompanyAdmin: true
  },
  {
    id: 6,
    label: 'Akceptacja zamówień',
    value: '/profile/orders-approval',
    roles: [user],
    forCompanyAdmin: true,
    companyAccessFn: ordersApprovalEnabled
  },
  {
    id: 8,
    label: 'Centrum powiadomień',
    value: '/profile/notifications',
    roles: [user, admin, supplier],
    forCompanyAdmin: false
  },
  {
    id: 9,
    label: 'Zarządzanie oddziałami',
    value: '/profile/companies-management',
    roles: [user],
    forCompanyAdmin: true
  },
  {
    id: 10,
    title: 'Jednostki organizacyjne',
    url: '/profile/warehouse',
    icon: <WarehouseIcon />,
    roles: [user],
    forCompanyAdmin: true
  }
];
