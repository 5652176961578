import React from 'react';

import { PageHeader, SeoHelmet } from 'components';

import pages from 'src/dictionaries/pages.json';

import QuickAccess from '../../components/QuickAccess';

import style from '../../Dashboard.module.scss';

const SupplierDashboard = () => {
  return (
    <>
      <SeoHelmet title={pages.dashboard.title} />
      <PageHeader
        name={pages.dashboard.title}
        className={style.header}
      />
      <div className={style.container}>
        <QuickAccess />
      </div>
    </>
  );
};

export default SupplierDashboard;
