import React from 'react';

import OrderDetails from '../OrderDetails';

const OrdersList = ({ suppliers = [] }) => {
  return suppliers.map((order) => (
    <OrderDetails
      key={order.id}
      order={order}
    />
  ));
};

export default OrdersList;
