import OffersViewProductsList from './components/OffersViewProductsList';
import OrderViewProductsList from './components/OrderViewProductsList';

const PendingProductsTable = ({ products, productsWithoutAssignedOffer, isDefinedView, isOrderView }) => {
  if (isOrderView) {
    return (
      <OrderViewProductsList
        products={products}
        productsWithoutAssignedOffer={productsWithoutAssignedOffer}
        isDefinedView={isDefinedView}
      />
    );
  }

  return <OffersViewProductsList products={products} />;
};

export default PendingProductsTable;
