import React from 'react';

import { Button, SwitchButton, useIsAdmin, usePermissions } from 'components';

import { userPermissions } from 'src/constants/enums';

const HeaderContent = (props) => {
  const {
    setIsHidingModalOpen,
    isDisabled,
    setIsModalOpen,
    columnsLength,
    optimizeSelect,
    optimizationLoading,
    isLoading,
    handleProceedOrder,
    orderProceedLoading,
    isHorizontal,
    setIsHorizontal,
    setShowRefPrice,
    showRefPrice
  } = props;

  const isAdmin = useIsAdmin();
  const [canCreateOrder] = usePermissions([userPermissions.orders.create]);

  return (
    <>
      <SwitchButton
        value={isHorizontal}
        setValue={setIsHorizontal}
        label={'Wyświetlaj horyzontalnie'}
      />
      <Button
        label='Ukryj'
        onClick={() => setIsHidingModalOpen(true)}
        disabled={isDisabled}
        gray
      />
      {!isHorizontal && (
        <>
          <Button
            label='Wyślij do dostawcy'
            onClick={() => setIsModalOpen(true)}
            disabled={isDisabled}
            gray
          />
        </>
      )}
      {isHorizontal && isAdmin && (
        <Button
          label={`${showRefPrice ? 'Ukryj' : 'Pokaż'} ceny referencyjne`}
          onClick={() => setShowRefPrice((prev) => !prev)}
          gray
        />
      )}
      {(isHorizontal || columnsLength > 1) && (
        <Button
          label='Optymalizuj koszyk'
          onClick={optimizeSelect}
          isLoading={optimizationLoading || isLoading}
          disabled={isDisabled}
        />
      )}
      {canCreateOrder && (
        <Button
          label='Podsumowanie zamówienia'
          onClick={handleProceedOrder}
          isLoading={orderProceedLoading || isLoading}
          disabled={isDisabled}
        />
      )}
    </>
  );
};

export default HeaderContent;
