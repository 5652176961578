import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

import { ReactComponent as InvoiceIcon } from 'assets/icons/newSidebar/file-invoice.svg';
import { ReactComponent as OffersIcon } from 'assets/icons/newSidebar/offers.svg';
import { ReactComponent as SuppliersIcon } from 'assets/icons/newSidebar/suppliers.svg';
import { ReactComponent as PeopleIcon } from 'assets/icons/newSidebar/users.svg';
import { ReactComponent as CompanyIcon } from 'assets/icons/profile/building.svg';
import { Card, Modal, NestedNavigation, useIsAdmin, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OfferCatalogApi, UserApi } from 'src/api';
import { PrivateRoute } from 'src/app/AppRouter/routes';
import { userRoles } from 'src/constants/enums';

import { changeViewType, refreshCartOrCatalog } from '../../actions';
import AssignVolumesWithRangeForm from './components/AssignVolumesWithRangeForm';
import InvoicesOCR from './components/InvoicesOCR';
import UploadInvoiceForm from './components/InvoicesOCR/components/UploadInvoiceForm';
import Management from './components/Management';
import OffersOCR from './components/OffersOCR';
import UploadOffersForm from './components/OffersOCR/components/UploadOffersForm';
import SavingsTable from './components/SavingsTable';
import ShowHideSupplierOffers from './components/ShowHideSupplierOffers';
import SuppliersSummary from './components/SuppliersSummary';
import { mobileLinks } from './links';

import style from './CatalogManagement.module.scss';

const { user, admin } = userRoles;

const CatalogManagement = () => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();

  const [suppliers, setSuppliers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [suppliersVersion, setSuppliersVersion] = useState(0);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isSuppliersLoading, setIsSuppliersLoading] = useState(true);
  const selectedCompany = useSelector((state) => state.cartAndCatalog.selectedCompany);

  const [isOCRModalOpen, setIsOCRModalOpen] = useState(false);
  const [isOCROffersModalOpen, setIsOCROffersModalOpen] = useState(false);
  const [isVolumesRangeAssignOpen, setIsVolumesRangeAssignOpen] = useState(false);

  const setFirstSecondSuppliers = async () => {
    const data = {
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      await OfferCatalogApi.setPreferredSuppliers(data);
      dispatch(changeViewType(true));
      dispatch(refreshCartOrCatalog());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const setAvgVolumesForCatalogHandler = async () => {
    const params = {
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      const { data } = await OfferCatalogApi.setAvgVolumesForCatalog(params);
      dispatch(refreshCartOrCatalog());
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const setFirstSecondSuppliersHandler = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz przypisać dostawców automatycznie?',
      handleConfirm: setFirstSecondSuppliers,
      confirmButtonName: 'Tak, przypisz',
      cancelButtonName: 'Nie',
      content: (
        <>
          <p>
            Algorytm przypisywania ofert dostawców automatycznie analizuje dostępne oferty, a następnie wybiera te najtańsze i
            ustawia je jako preferowane oraz alternatywne.
          </p>
          <p style={{ marginTop: '12px' }}>Uwaga! Tej akcji nie będzie można cofnąć, ale zawsze można zmienić ten wybór.</p>
        </>
      )
    });
  };

  const setVolumesConfirmOpen = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz przypisać wolumeny automatycznie?',
      handleConfirm: setAvgVolumesForCatalogHandler,
      confirmButtonName: 'Tak, przypisz',
      cancelButtonName: 'Nie',
      content: (
        <>
          <p>
            Algorytm automatycznie przypisze wolumen roczny do produktów na podstawie danych o wolumenie z ostatnich 6 miesięcy.
          </p>
          <p style={{ marginTop: '12px' }}>
            <strong>Uwaga!</strong> Tej akcji nie będzie można cofnąć, ale zawsze można zmienić wolumen ręcznie.
          </p>
        </>
      )
    });
  };

  const openSuppliersOffersVisibilityModal = () => setIsModalOpen(true);

  const desktopLinks = [
    {
      title: 'Dostosuj moduł',
      dropdown: [
        {
          id: 1,
          title: 'Zarządzanie',
          url: '/offer-catalog',
          icon: <CompanyIcon />,
          roles: [user, admin],
          keepSearchParams: true,
          exact: true
        },
        {
          id: 2,
          title: 'Dostawcy',
          url: '/offer-catalog/suppliers',
          icon: <SuppliersIcon />,
          roles: [user, admin],
          keepSearchParams: true,
          buttons: [
            { title: 'Przypisz dostawców', onClick: setFirstSecondSuppliersHandler, id: 'suppliers-button-1' },
            { title: 'Pokaż / ukryj dostawcę', onClick: openSuppliersOffersVisibilityModal, id: 'suppliers-button-2' }
          ]
        },
        {
          id: 3,
          title: 'Analityka',
          url: '/offer-catalog/analytics',
          icon: <PeopleIcon />,
          roles: [user, admin],
          keepSearchParams: true,
          buttons: [
            { title: 'Przypisz wolumen', onClick: setVolumesConfirmOpen, id: 'analytics-button-1' },
            ...(isAdmin
              ? [
                  {
                    title: 'Przypisz wolumen (zakres)',
                    onClick: () => setIsVolumesRangeAssignOpen(true),
                    id: 'analytics-button-2'
                  }
                ]
              : [])
          ]
        },
        {
          id: 4,
          title: 'OCR Faktur',
          url: '/offer-catalog/invoices',
          icon: <InvoiceIcon />,
          roles: [admin],
          keepSearchParams: true,
          buttons: [{ title: 'Dodaj fakturę', onClick: () => setIsOCRModalOpen(true), id: 'ocr-button-1' }]
        },
        {
          id: 5,
          title: 'OCR Ofert',
          url: '/offer-catalog/offers',
          icon: <OffersIcon />,
          roles: [admin],
          keepSearchParams: true,
          buttons: [{ title: 'Dodaj oferty', onClick: () => setIsOCROffersModalOpen(true), id: 'ocr-button-2' }]
        }
      ]
    }
  ];

  const getSuppliers = async () => {
    const queryParams = {
      pagination: 0,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      setIsSuppliersLoading(true);
      const { data } = await UserApi.getDistributors(queryParams);
      setSuppliers(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsSuppliersLoading(false);
    }
  };

  useEffect(() => {
    getSuppliers();
  }, [suppliersVersion]);

  const closeOCRModal = () => setIsOCRModalOpen(false);
  const closeOCROffersModal = () => setIsOCROffersModalOpen(false);
  const closeVolumesRangeAssignModal = () => setIsVolumesRangeAssignOpen(false);

  return (
    <div className={style.container}>
      <NestedNavigation
        title={'Dostosuj moduł'}
        desktopLinks={desktopLinks}
        mobileLinks={mobileLinks}
      />
      {renderedModalConfirm}
      <ShowHideSupplierOffers
        suppliers={suppliers}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        refreshSuppliers={() => setSuppliersVersion((prev) => prev + 1)}
        isSuppliersLoading={isSuppliersLoading}
      />
      <Modal
        title={'Dodaj fakturę do analizy'}
        visible={isOCRModalOpen}
        onClose={closeOCRModal}
      >
        <UploadInvoiceForm closeModal={closeOCRModal} />
      </Modal>
      <Modal
        title={'Dodaj oferty do analizy'}
        visible={isOCROffersModalOpen}
        onClose={closeOCROffersModal}
      >
        <UploadOffersForm closeModal={closeOCROffersModal} />
      </Modal>
      <Modal
        title={'Przypisz wolumen (zakres)'}
        visible={isVolumesRangeAssignOpen}
        onClose={closeVolumesRangeAssignModal}
      >
        <AssignVolumesWithRangeForm
          selectedCompany={selectedCompany}
          refetchData={() => dispatch(refreshCartOrCatalog())}
          closeModal={closeVolumesRangeAssignModal}
        />
      </Modal>
      <Card className={style.wrapper}>
        <Switch>
          <PrivateRoute
            exact
            nestedRoute
            path={'/offer-catalog'}
            roles={[userRoles.user, userRoles.admin]}
            component={Management}
          />
          <PrivateRoute
            exact
            nestedRoute
            path={'/offer-catalog/suppliers'}
            roles={[userRoles.user, userRoles.admin]}
            render={(props) => (
              <SuppliersSummary
                suppliers={suppliers}
                isSuppliersLoading={isSuppliersLoading}
                {...props}
              />
            )}
          />
          <PrivateRoute
            exact
            nestedRoute
            path={'/offer-catalog/analytics'}
            roles={[userRoles.user, userRoles.admin]}
            component={SavingsTable}
          />
          <PrivateRoute
            nestedRoute
            path={'/offer-catalog/invoices'}
            roles={[userRoles.admin]}
            component={InvoicesOCR}
          />
          <PrivateRoute
            nestedRoute
            path={'/offer-catalog/offers'}
            roles={[userRoles.admin]}
            component={OffersOCR}
          />
        </Switch>
      </Card>
    </div>
  );
};

export default CatalogManagement;
