export const getLocalStorageItem = (key) => {
  const value = window.localStorage.getItem(key);
  if (value === '') {
    return null;
  }
  return value;
};

export const setLocalStorageItem = (key, value) => {
  if (value == null) {
    value = '';
  }
  window.localStorage.setItem(key, value);
};

export const getSessionStorageItem = (key) => {
  const value = window.sessionStorage.getItem(key);
  if (value === '') {
    return null;
  }
  return value;
};

export const setSessionStorageItem = (key, value) => {
  if (value == null) {
    value = '';
  }
  window.sessionStorage.setItem(key, value);
};

export const removeSessionStorageItem = (key) => {
  if (!key) return null;
  window.sessionStorage.removeItem(key);
};
