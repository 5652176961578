import React, { useState } from 'react';
import { useAtom } from 'jotai/index';

import { Button, FileDropzone, useIsAdmin, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { selectedCompanyAtom, versionAtom } from 'src/features/Warehouse';
import { handleNumeralWords } from 'src/utils/helpers';

import NotExistingProductsTable from '../NotExistingProductsTable';

import style from '../../ImportExportForm.module.scss';

const Import = ({ closeModal }) => {
  const isAdmin = useIsAdmin();
  const validator = useValidator();

  const [version, setVersion] = useAtom(versionAtom);
  const [selectedCompany] = useAtom(selectedCompanyAtom);

  const [flatFile, setFlatFile] = useState(null);
  const [notExistingProducts, setNotExistingProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const formData = new FormData();
    formData.append('products', flatFile);

    if (isAdmin) {
      formData.append('company_id', selectedCompany);
    }

    try {
      setIsLoading(true);
      const { data } = await WarehouseApi.importWarehouseProducts(formData);
      const addedProductsCount = data.message.added_products_to_warehouse_count;
      const notExistingProductsArray = data.message.not_existing_product_ids;

      notifyCommon(
        [
          `Zawartość magazynu została poprawiona. Dodano ${addedProductsCount} ${handleNumeralWords(
            ['produkt', 'produkty', 'produktów'],
            addedProductsCount
          )}`
        ],
        { title: 'Sukces!' }
      );
      validator.hideMessages();
      setFlatFile(null);
      setNotExistingProducts(notExistingProductsArray);
      setVersion(version + 1);
      if (closeModal && notExistingProductsArray.length === 0) closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form
        className={style.container}
        onSubmit={submitHandler}
      >
        <header className={style.header}>
          <h3>Import pliku z zawartością magazynu</h3>
          <p>Wybierz plik i zapisz, aby zaktualizować stany magazynowe.</p>
        </header>
        <FileDropzone
          label={'Plik z inwentaryzacją'}
          onChange={setFlatFile}
          value={flatFile}
          validator={validator}
          rule={'required'}
        />
        <Button
          label={'Zaimportuj'}
          type={'submit'}
          className={style.button}
          isLoading={isLoading}
        />
      </form>
      {notExistingProducts.length > 0 && <NotExistingProductsTable products={notExistingProducts} />}
    </>
  );
};

export default Import;
