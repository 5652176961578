import React from 'react';

import { NestedNavigation } from 'components';

import Router from './components/Router';
import { desktopLinks, mobileLinks } from './links';

import style from './Profile.module.scss';

const Profile = () => {
  return (
    <>
      <div className={style.container}>
        <NestedNavigation
          title={'Profil'}
          desktopLinks={desktopLinks}
          mobileLinks={mobileLinks}
        />
        <Router />
      </div>
    </>
  );
};

export default Profile;
