import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AsyncSelect, Button, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OrdersApi } from 'src/api';
import { changeOrdersVersion } from 'src/features/Orders/actions';

import style from './RefreshOrders.module.scss';

const RefreshOrders = ({ handleCloseModal }) => {
  const dispatch = useDispatch();
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const validator = useValidator();

  const ordersUids = useMemo(() => {
    return selectedOrders.map((order) => order.uid);
  }, [selectedOrders]);

  const handleRefreshOrders = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      await OrdersApi.refreshOrdersStatus(ordersUids);
      setSelectedOrders([]);
      notifyCommon(['Statusy wybranych zamówień zostały odświeżone.']);
      handleCloseModal();
      dispatch(changeOrdersVersion());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleRefreshOrders}
      className={style.form}
    >
      <AsyncSelect
        value={selectedOrders}
        onChange={setSelectedOrders}
        apiCallback={OrdersApi.getOrders}
        valueKey={'uid'}
        labelKey={'name'}
        label={'Zamówienia'}
        placeholder={'Wybierz zamówienia'}
        isClearable
        validator={validator}
        rule={'required'}
        id={'number'}
        name={'number'}
        isMulti
      />
      <Button
        label={'Zapisz'}
        isLoading={isLoading}
        className={style.button}
      />
    </form>
  );
};

export default RefreshOrders;
