import React, { useEffect, useMemo, useState } from 'react';

import { EmptyState, usePermissions } from 'components';
import ThumbnailPlaceholderSmall from 'components/layout/ThumbnailPlaceholderSmall';
import { notifyApiError } from 'components/layout/Toasts';

import { InvoicesApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import Section from 'src/features/Dashboard/components/Section';

import InvoiceThumbnail from '../../../Invoices/components/InvoiceThumbnail';

import style from './LastInvoices.module.scss';

const DASHBOARD_ELEMENT_ID = 1;

const LastInvoices = ({ config }) => {
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [canReadInvoices] = usePermissions([userPermissions.invoices.read]);
  const canBeDisplayed = useMemo(
    () => canReadInvoices && config?.elements?.includes(DASHBOARD_ELEMENT_ID),
    [canReadInvoices, config]
  );

  const getData = async () => {
    setIsLoading(true);
    const params = {
      page: 1,
      perPage: 3
    };

    try {
      const { data } = await InvoicesApi.getInvoices(params);
      setPageData(data.data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (canBeDisplayed) getData();
  }, [canBeDisplayed]);

  if (!canBeDisplayed) {
    return null;
  }

  return (
    <Section
      title={'Ostatnie faktury'}
      link={'/invoices'}
      id={'last-invoices'}
      className={style.lastInvoicesSection}
    >
      {pageData?.length > 0 ? (
        <div className={style.container}>
          {pageData.map((item) => (
            <InvoiceThumbnail
              refreshCallback={getData}
              key={item.id}
              item={item}
              {...item}
              small
            />
          ))}
        </div>
      ) : isLoading ? (
        <ThumbnailPlaceholderSmall
          quantity={2}
          small
        />
      ) : (
        <div className={style.wrapper}>
          <EmptyState type={'invoices'} />
        </div>
      )}
    </Section>
  );
};

export default LastInvoices;
