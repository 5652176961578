import React, { useMemo } from 'react';

import { LoaderGlobal, useAuthUser } from 'components';

import { userRoles } from 'src/constants/enums';

import SupplierDashboard from './containers/SupplierDashboard';
import UserDashboard from './containers/UserDashboard';

const Dashboard = () => {
  const user = useAuthUser();

  const content = useMemo(() => {
    switch (user.role) {
      case userRoles.user:
      case userRoles.admin:
        return <UserDashboard />;
      case userRoles.supplier:
        return <SupplierDashboard />;
      default:
        return null;
    }
  }, [user]);

  if (!user) {
    return <LoaderGlobal />;
  }

  return content;
};

export default Dashboard;
