import * as types from '../actions/types';

export const initialState = {
  user: null,
  access: null,
  adminId: null,
  isLoading: false,
  selectedCompanyId: null,
  isAdminView: false,
  formattedCompanies: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.REGISTER_NEW_MEMBER_REQUEST:
    case types.SUPPLIER_REGISTER_REQUEST:
    case types.GET_USER_DATA_REQUEST:
    case types.REGISTER_REQUEST:
    case types.LOGIN_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case types.LOAD_USER_TOKEN: {
      return {
        ...state,
        access: action.payload.token,
        adminId: action.payload.admin_id,
        isAdminView: true
      };
    }
    case types.SET_TOKENS:
      return {
        ...state
      };
    case types.REGISTER_NEW_MEMBER_SUCCESS:
    case types.SUPPLIER_REGISTER_SUCCESS:
    case types.REGISTER_SUCCESS:
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        access: action.data.access_token,
        isLoading: false
      };
    case types.REFRESH_USER_DATA_SUCCESS:
    case types.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedCompanyId: action.data?.company?.id || null,
        formattedCompanies: action.data?.companies?.map(({ id, name, type }) => ({
          label: name,
          value: id,
          isHeadQuoter: type === 'HQ'
        })),
        user: {
          ...action.data
        }
      };
    case types.REGISTER_NEW_MEMBER_FAILURE:
    case types.SUPPLIER_REGISTER_FAILURE:
    case types.GET_USER_DATA_FAILURE:
    case types.REGISTER_FAILURE:
    case types.LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case types.CHANGE_CURRENT_COMPANY:
      return {
        ...state,
        selectedCompanyId: action.payload
      };
    case types.LOGOUT:
      return initialState;
    default:
      return state;
  }
};
