import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useAuthUser, useCompany } from 'components';

import { NotificationsSockets } from 'src/api/sockets/connections';
import { NotificationsRoutes } from 'src/api/sockets/routes';
import { userRoles } from 'src/constants/enums';

import { newNotification } from './actions';

const NotificationsStore = () => {
  const company = useCompany();
  const user = useAuthUser();
  const dispatch = useDispatch();
  const prevCompanyID = useRef(null);

  const registerNewNotification = (data) => {
    dispatch(newNotification(data));
  };

  useEffect(() => {
    if (
      user &&
      user.role === userRoles.supplier &&
      window.pusher &&
      window.pusher?.channels?.channels &&
      !Object.keys(window.pusher?.channels?.channels).includes(NotificationsRoutes.SUPPLIER_NOTIFICATIONS(user?.id))
    ) {
      NotificationsSockets.openSupplierChanel(registerNewNotification, user?.id);
      console.log('SUPPLIERS Notification Channel opened');
    }
  }, [window.pusher, user]);

  useEffect(() => {
    if (
      prevCompanyID.current &&
      prevCompanyID.current !== company?.id &&
      company?.id &&
      window.pusher?.channels?.channels &&
      Object.keys(window.pusher?.channels?.channels).includes(NotificationsRoutes.USER_NOTIFICATIONS(prevCompanyID.current))
    ) {
      const nestedCompanies = user.companies.filter((company) => company.company_id === prevCompanyID.current);

      NotificationsSockets.closeUserChanel(prevCompanyID.current);
      console.log(`COMPANIES Notification Channel closed (id: ${prevCompanyID.current})`);

      if (nestedCompanies.length > 0) {
        nestedCompanies.forEach((nestedCompany) => {
          NotificationsSockets.closeUserChanel(nestedCompany.id);
          console.log(`COMPANIES Notification Channel closed (id: ${nestedCompany?.id})`);
        });
      }
    }

    prevCompanyID.current = company?.id ?? null;
  }, [window.pusher, company]);

  useEffect(() => {
    if (
      company &&
      user.role === userRoles.user &&
      window.pusher &&
      window.pusher?.channels?.channels &&
      !Object.keys(window.pusher?.channels?.channels).includes(NotificationsRoutes.USER_NOTIFICATIONS(company.id))
    ) {
      const nestedCompanies = user.companies.filter((nestedCompany) => nestedCompany.company_id === company.id);

      NotificationsSockets.openUserChanel(registerNewNotification, company?.id);
      console.log(`COMPANIES Notification Channel opened (id: ${company?.id})`);

      console.log('nestedCompanies.length', nestedCompanies.length);

      if (nestedCompanies.length > 0) {
        nestedCompanies.forEach((nestedCompany) => {
          NotificationsSockets.openUserChanel(registerNewNotification, nestedCompany?.id);
          console.log(`COMPANIES Notification Channel opened (id: ${nestedCompany?.id})`);
        });
      }
    }
  }, [window.pusher, company, user]);

  return null;
};

export default NotificationsStore;
