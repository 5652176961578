import { NavLink, useLocation } from 'react-router-dom';
import { Badge } from 'antd';
import cn from 'classnames';

const DropdownElement = ({ link, styles, index, isClose, horizontalOnMobile, themeLight, onClose }) => {
  const hasButtons = link.buttons?.length > 0;
  const location = useLocation();
  const isActive = location.pathname.includes(link.url);

  return (
    <div className={cn({ [styles.linkWrapper]: hasButtons && isActive })}>
      <NavLink
        className={cn(styles.link, {
          [styles.isClose]: isClose,
          [styles.wide]: horizontalOnMobile,
          [styles.linkLight]: themeLight
        })}
        onClick={onClose}
        activeClassName={styles.active}
        to={link.url + (link.keepSearchParams ? location.search : '')}
        key={index}
        exact={link.exact}
        id={link.url.slice(1, link.url.length)}
      >
        {link.icon && link.icon}
        {!isClose && link.title}
        <Badge
          count={link.count}
          className={styles.badge}
        />
      </NavLink>
      {hasButtons && (
        <div className={cn(styles.options, !isActive && styles.optionsHidden)}>
          {link.buttons.map((button) => (
            <button
              key={button.id}
              onClick={button.onClick}
              className={styles.option}
            >
              {button.title}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownElement;
