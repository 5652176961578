import React, { useMemo } from 'react';
import classNames from 'classnames';

import { ColumnSortButton, EmptyState } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import { productBaseTypes } from 'src/constants/enums';

import DefaultProduct from '../DefaultProduct';
import SupplierProduct from '../SupplierProduct';

import style from './ProductsList.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductsList = ({ list = [], isLoading, type, params }) => {
  if (!isLoading && !list.length) {
    return (
      <section className={shared.tableWrapper}>
        <div className={shared.tableCategory}>
          <EmptyState type={'search'} />
        </div>
      </section>
    );
  }

  const Component = useMemo(() => (type === productBaseTypes.supplier ? SupplierProduct : DefaultProduct), [type]);

  return (
    <section className={shared.tableWrapper}>
      <div
        className={classNames(shared.tableHeader, style.header, {
          [style.supplierHeader]: type === productBaseTypes.supplier
        })}
      >
        <ColumnSortButton
          parameterName={'name'}
          params={params}
          content={'Nazwa'}
        />
        {type !== productBaseTypes.supplier && (
          <>
            <p>Jednostka</p>
            <p>Ilość w op.</p>
            <p>Producent</p>
          </>
        )}
        <p>Kategoria</p>
      </div>
      <div className={shared.tableCategory}>
        {isLoading ? (
          <ProductPlaceholder />
        ) : (
          list.map((product) => (
            <Component
              key={product.id}
              product={product}
            />
          ))
        )}
      </div>
    </section>
  );
};

export default ProductsList;
