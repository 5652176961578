import * as types from '../actions/types';

const initialState = {
  departments: [],
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DEPARTMENTS_REQUEST:
    case types.REFRESH_DEPARTMENTS_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case types.GET_DEPARTMENTS_SUCCESS:
    case types.REFRESH_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        departments: action.data.data || [],
        isLoading: false
      };
    }
    case types.GET_DEPARTMENTS_FAILURE:
    case types.REFRESH_DEPARTMENTS_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    default:
      return state;
  }
};
