import React, { useState } from 'react';
import classNames from 'classnames';

import { Modal } from 'components';

import style from './CommentModal.module.scss';

const CommentModal = ({ comment, title, modalTitle, children, emptyState, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={className}>
      {comment ? (
        <a
          className={classNames(style.text, style.trim)}
          onClick={() => setIsOpen(true)}
        >
          {comment}
        </a>
      ) : emptyState ? (
        emptyState
      ) : (
        <p className={style.text}>-</p>
      )}
      <Modal
        title={modalTitle}
        onClose={() => setIsOpen(false)}
        visible={isOpen}
      >
        <div className={style.container}>
          {!!title && <h2 className={style.container}>{title}</h2>}
          {!!comment && <p className={classNames(style.text, style.comment)}>{comment}</p>}
          {children}
        </div>
      </Modal>
    </div>
  );
};

export default CommentModal;
