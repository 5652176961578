import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Select } from 'components';

import style from '../ProductComponents.module.scss';

export const taxTypes = [
  { label: '-', value: 0 },
  { label: '8%', value: 8 },
  { label: '23%', value: 23 }
];

const TaxSelect = ({ isMobile, disabled, isUnavailable = false, tax, setTax }) => {
  const [selectedTax, setSelectedTax] = useState(taxTypes.find((type) => type?.value === tax) || taxTypes[1]);

  useEffect(() => {
    if (typeof selectedTax?.value === 'number' && selectedTax.value !== tax) {
      setTax(selectedTax.value);
    }
  }, [selectedTax]);

  return (
    <div className={classNames(style.wrapper, style.vatWrapper)}>
      <Select
        options={taxTypes}
        value={selectedTax}
        onChange={setSelectedTax}
        wrapperStyle={style.vat}
        isDisabled={isUnavailable || disabled}
        placeholder={'VAT'}
        label={isMobile ? 'Stawka VAT' : null}
        isSearchable={false}
      />
    </div>
  );
};

export default TaxSelect;
