import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Checkbox, useAuthUser } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { UserApi } from 'src/api';
import {
  AnalyticsElements,
  defaultDashboardConfig,
  groupsNames,
  groupsTitles,
  InvoicesElements,
  NotificationsElements,
  OrdersElements,
  userAndAdminTabs
} from 'src/constants/dashboardElements';

import { getUserData } from '../../../onboarding/actions';

import style from './DashboardConfigure.module.scss';

const DashboardConfigure = ({ onClose }) => {
  const dispatch = useDispatch();
  const user = useAuthUser();

  const defaultConfigValue = { ...defaultDashboardConfig, ...(!!user?.dashboard_config && user.dashboard_config) };
  const [isLoading, setIsLoading] = useState(false);
  const [config, setConfig] = useState(defaultConfigValue);

  const onCheckboxChange = (e, tab, group) => {
    setConfig((prev) => {
      const groupData = prev[group];

      if (!groupData) {
        return prev;
      }

      return {
        ...prev,
        [group]: {
          ...groupData,
          elements: e.target.checked ? [...groupData.elements, tab.id] : groupData.elements.filter((item) => item !== tab.id)
        }
      };
    });
  };

  const getGroupCheckbox = (elements, groupName, title) => (
    <div className={style.wrapper}>
      <h2 className={style.title}>{title}</h2>
      {elements?.map((tab) => (
        <Checkbox
          key={tab.id + groupName}
          content={tab.title}
          value={config[groupName]?.elements?.includes(tab.id)}
          onChange={(e) => onCheckboxChange(e, tab, groupName)}
        />
      ))}
    </div>
  );

  const saveDashboardConfig = async () => {
    try {
      setIsLoading(true);
      await UserApi.saveDashboardConfig(JSON.stringify(config));
      notifyCommon(['Układ dashboardu został zapisany!']);
      dispatch(getUserData());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(true);
    }
  };

  const cancelHandler = () => {
    setConfig(defaultConfigValue);
    onClose();
  };

  useEffect(() => {
    if (user?.dashboard_config) {
      setConfig(user.dashboard_config);
    }
  }, [user]);

  return (
    <div className={style.container}>
      <div className={style.box}>
        {getGroupCheckbox(userAndAdminTabs, groupsNames.quickAccess, groupsTitles.quickAccess)}
        {getGroupCheckbox(OrdersElements, groupsNames.orders, groupsTitles.orders)}
        {getGroupCheckbox(AnalyticsElements, groupsNames.analytics, groupsTitles.analytics)}
        {getGroupCheckbox(NotificationsElements, groupsNames.notifications, groupsTitles.notifications)}
        {getGroupCheckbox(InvoicesElements, groupsNames.invoices, groupsTitles.invoices)}
      </div>
      <div className={style.buttonWrapper}>
        <Button
          onClick={cancelHandler}
          label={'Anuluj'}
          gray
        />
        <Button
          onClick={saveDashboardConfig}
          isLoading={isLoading}
          label={'Zapisz'}
        />
      </div>
    </div>
  );
};

export default DashboardConfigure;
