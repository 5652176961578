import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { ReactComponent as MenuIcon } from 'assets/icons/newSidebar/menu.svg';
import { ReactComponent as LogoAvalio } from 'assets/img/logo-avalio.svg';
import { ReactComponent as LogoAvalioShort } from 'assets/img/logo-avalio-short.svg';
import { CompanySelect, Loader, OutsideClickHandler, useIsMobile } from 'components';
import { closeMobileSidebar, toggleSidebar } from 'components/layout/Sidebar/actions';
import useNavigation from 'components/layout/Sidebar/useNavigation';

import Dropdown from './Dropdown';

import styles from './Sidebar.module.scss';

const Sidebar = ({ className, onClose }) => {
  const sidebarStore = useSelector((state) => state.sidebar);
  const { isOpen, isMobileSidebarOpen } = sidebarStore;
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const isAdminView = useSelector((state) => state.auth.isAdminView);
  const isMobile = useIsMobile();
  const navigation = useNavigation();

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const handleClose = () => {
    if (isMobile) {
      dispatch(closeMobileSidebar());
    }
    onClose && onClose();
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (isMobile && isMobileSidebarOpen) {
          dispatch(closeMobileSidebar());
        }
      }}
    >
      <div
        id={'main-sidebar'}
        className={cn(
          styles.sidebar,
          {
            [styles.isClose]: !isOpen && !isMobile,
            [styles.mobile]: isMobile,
            [styles.isMobileOpen]: isMobileSidebarOpen,
            [styles.isAdminView]: isAdminView
          },
          className
        )}
      >
        <div className={styles.logoWrapper}>
          <Link
            className={styles.logo}
            to='/dashboard'
            onClick={handleClose}
          >
            {isOpen ? (
              <LogoAvalio className={styles.logoSvg} />
            ) : (
              <LogoAvalioShort className={cn(styles.logoSvg, styles.logoSvgShort)} />
            )}
          </Link>
        </div>
        {isMobile && (
          <div className={styles.companySelect}>
            <CompanySelect />
          </div>
        )}
        <div className={styles.menu}>
          {isLoading ? (
            <div className={styles.loader}>
              <Loader />
            </div>
          ) : (
            navigation.map((item, index) => (
              <Dropdown
                className={styles.dropdown}
                key={index}
                item={item}
                onClose={handleClose}
                disabled
                isClose={!isOpen && !isMobile}
                themeLight={true}
              />
            ))
          )}
        </div>
        {!isMobile && (
          <div className={styles.foot}>
            <button
              className={styles.button}
              onClick={handleToggleSidebar}
            >
              <MenuIcon />
              {isOpen && <p className={styles.info}>Zwiń panel</p>}
            </button>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Sidebar;
