import React from 'react';

import { Accordion } from 'components';

import ValuationReplacementsTable from './components/ValuationReplacementsTable';

import styles from './ValuationReplacements.module.scss';

const ValuationReplacements = ({ replacements }) => {
  return (
    <div className={styles.container}>
      <Accordion
        title={'Zamienniki'}
        id={'replacements'}
        extraDeps={[]}
      >
        <div className={styles.container_content}>
          <p>
            Poniżej prezentowane są alternatywne propozycje do produktów zaproponowane przez Twoich dostawców. Możesz dodać je do
            swojej wyceny oraz katalogu Ofert za pomocą przycisku „+” po prawej stronie, a następnie zamówić.
          </p>
          <ValuationReplacementsTable replacements={replacements} />
        </div>
      </Accordion>
    </div>
  );
};

export default ValuationReplacements;
