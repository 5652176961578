import React from 'react';
import classNames from 'classnames';

import { QtySelector } from 'components';

import style from '../ProductComponents.module.scss';

const Quantity = ({ quantity, setQuantity, unitName, isMobile, disabled, validator, ...rest }) => {
  return (
    <div className={classNames(style.wrapper, style.quantityWrapper)}>
      <QtySelector
        state={quantity}
        setState={setQuantity}
        suffix={unitName}
        hideArrows
        width={90}
        label={isMobile ? 'Ilość' : null}
        id={`offerQty-${parent.id}`}
        disabled={disabled}
        validator={validator}
        rule={'required|only_positive'}
        {...rest}
      />
    </div>
  );
};

export default Quantity;
