import React from 'react';
import classNames from 'classnames';

import styles from './PriceChangeDifference.module.scss';

const PriceChangeDifference = ({ difference }) => {
  const { percentage, numeric } = difference;
  const priceIsUp = numeric.substring(0, 1) === '+';
  const priceIsDown = numeric.substring(0, 1) === '-';

  return (
    <span
      className={classNames({
        [styles.isNegative]: priceIsUp,
        [styles.isPositive]: priceIsDown
      })}
    >
      {`${percentage} (${numeric})`}
    </span>
  );
};

export default PriceChangeDifference;
