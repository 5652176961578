import React, { useEffect, useMemo, useState } from 'react';

import { Select } from 'components';

import style from './DepartmentFilter.module.scss';

const defaultOptions = [
  {
    label: 'Wszystkie',
    value: 'all'
  },
  {
    label: 'Bez przypisania',
    value: 'no_departments'
  }
];

const departmentToOption = (department) => {
  const { id, name } = department;
  return { label: name, value: id };
};

const DepartmentFilter = ({ data, list, setList }) => {
  const [filter, setFilter] = useState(defaultOptions[0]);

  const findDepartments = (products) => {
    const departments = [];

    products.forEach((item) => {
      item.departments.forEach((department) => {
        const isInArray = departments.some((dep) => dep.id === department.id);
        if (!isInArray) {
          departments.push(department);
        }
      });
    });

    return departments;
  };

  const options = useMemo(() => {
    return [...defaultOptions, ...findDepartments(data).map((item) => departmentToOption(item))];
  }, [data]);

  const modifyViewData = (products) => {
    return products.map((item) => ({
      ...item,
      // change quantity and hide departments to show only product view with quantity of selected department
      qty: item.departments.find((department) => +department.id === +filter.value).quantity,
      departments: []
    }));
  };

  useEffect(() => {
    const filterData = () => {
      let items = [];
      switch (filter.value) {
        case 'all':
          items = data;
          break;
        case 'no_departments':
          items = data.filter((product) => product.departments?.length === 0);
          break;
        default:
          items = data.filter((product) => product.departments?.some((department) => +department.id === +filter.value));
          items = modifyViewData(items);
          break;
      }
      setList(items);
    };

    filterData();
  }, [data, filter]);

  return (
    <Select
      className={style.select}
      options={options}
      value={filter}
      onChange={setFilter}
      placeholder={'Jednostka organizacyjna'}
      isSearchable
    />
  );
};

export default DepartmentFilter;
