import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { Tag } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import { valuationStatuses } from 'src/constants/enums';
import { getFormattedAmount } from 'src/utils/helpers';

import OfferWrapper from '../Column/components/OfferWrapper';
import RefPrice from '../RefPrice';

import style from './ValuationProduct.module.scss';

const HorizontalProduct = ({ product, className, changeValuationSelections, status, showRefPrice = false }) => {
  const { name, producer, unit_name, qty, thumbnail, image, ref_price, id } = product || {};

  const savings = useSelector((state) => state.valuationView.savings);
  const { products } = savings;

  const productSaving = useMemo(
    () => products.find((prod) => prod.product_id === product.pricing_prod_id)?.saved_money,
    [savings]
  );

  const refPriceSaving = useMemo(() => {
    const x = product.prices.find((x) => x.selected);

    return +ref_price - +x?.price;
  }, [ref_price, product]);

  return (
    <div className={classnames(style.box, { [style.withRefPrice]: showRefPrice })}>
      <div className={classnames(style.container, className)}>
        <div className={style.wrapper}>
          <ProductThumbnailImage
            thumbnail={thumbnail}
            image={image}
            title={name}
            id={id}
          />
          <p
            className={style.text}
            title={name}
          >
            {name}
          </p>
        </div>
        <div className={style.row}>
          <span
            className={style.details}
            title={`Ilość: ${qty} ${unit_name || ''}`}
          >
            <p>Ilość:</p>
            <span className={style.detailsValue}>
              {qty}&nbsp;{unit_name}
            </span>
          </span>
          <span
            className={style.details}
            title={`Producent: ${producer}`}
          >
            <p>Producent:</p>
            <span className={style.detailsValue}>{producer || 'Brak'}</span>
          </span>
          {showRefPrice
            ? !!ref_price &&
              +refPriceSaving > 0 && (
                <div className={style.savings}>
                  <Tag
                    value={`Oszczędność względem listy: ${getFormattedAmount(refPriceSaving)}`}
                    color={'yellow'}
                    className={style.tag}
                  />
                </div>
              )
            : !!+productSaving && (
                <div className={style.savings}>
                  <Tag
                    value={`Oszczędność: ${getFormattedAmount(productSaving)}`}
                    color={'green'}
                    className={style.tag}
                  />
                </div>
              )}
        </div>
      </div>
      {showRefPrice && (
        <div className={classnames(style.priceWrapper, style.ref)}>
          <RefPrice
            price={ref_price}
            quantity={product.quantity}
            productId={product.product_id}
          />
        </div>
      )}
      <div className={style.prices}>
        {product.prices?.map((price, index) => (
          <div
            key={price.id + '-' + index}
            className={style.priceWrapper}
          >
            <OfferWrapper
              horizontal
              item={price}
              product={product}
              changeValuationSelections={changeValuationSelections}
            />
          </div>
        ))}
        {product.prices.length === 0 && (
          <div className={style.emptyStateWrapper}>
            <Tag
              className={style.tag}
              color={status === valuationStatuses.pending ? 'yellow' : ''}
              value={status === valuationStatuses.pending ? 'Oczekiwanie na oferty' : 'Brak ofert produktu'}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HorizontalProduct;
