import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { Loader, SwitchButton, Tag } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';

import { refreshCartOrCatalog } from '../../../../../../actions';

import rootStyles from '../../ShowHideSupplierOffers.module.scss';
import style from './Supplier.module.scss';

const Supplier = ({ supplier, refreshSuppliers, isLoading, setIsLoading }) => {
  const dispatch = useDispatch();
  const [localLoading, setLocalLoading] = useState(false);
  const [isSupplierHidden, setIsSupplierHidden] = useState(!!supplier?.is_hidden);

  useEffect(() => {
    setIsSupplierHidden(!!supplier?.is_hidden);
  }, [supplier]);

  const setSupplierVisibility = async (value) => {
    const queryParams = {
      supplier_id: supplier.id,
      hide: +value
    };

    try {
      setIsLoading(true);
      setLocalLoading(true);
      await SupplierApi.changeSupplierOffersVisibility(queryParams);
      setIsSupplierHidden(value);
      dispatch(refreshCartOrCatalog());
      refreshSuppliers();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
      setLocalLoading(false);
    }
  };

  const onVisibilityChange = (val) => {
    setSupplierVisibility(!val);
  };

  return (
    <div className={style.container}>
      <Tag
        value={supplier.name}
        className={style.tag}
      />
      {localLoading && (
        <>
          <Loader />
          <p className={cn(rootStyles.disclaimer, { [rootStyles.isLoading]: isLoading })}>*</p>
        </>
      )}
      <SwitchButton
        value={!isSupplierHidden}
        setValue={onVisibilityChange}
        disabled={isLoading}
      />
    </div>
  );
};

export default Supplier;
