import React from 'react';
import classNames from 'classnames';

import { EmptyState } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import TagThumbnail from '../TagThumbnail';

import styles from './TagsTable.module.scss';
import shared from 'styles/Shared.module.scss';

const TagsTable = ({ list = [], isLoading, params, refreshCallback }) => {
  if (isLoading) {
    return (
      <section className={shared.tableWrapper}>
        <div className={classNames(shared.tableHeader, styles.tableHeader)}>
          <p>Nazwa</p>
          <p>Ilość użyć</p>
          <p></p>
        </div>
        <div className={shared.tableCategory}>
          <ProductPlaceholder />
        </div>
      </section>
    );
  }

  return (
    <section className={shared.tableWrapper}>
      <div className={classNames(shared.tableHeader, styles.tableHeader)}>
        <p>Nazwa</p>
        <p>Ilość użyć</p>
        <p></p>
      </div>
      <div className={shared.tableCategory}>
        {list.length ? (
          list.map((tag) => (
            <TagThumbnail
              key={tag.id}
              refreshCallback={refreshCallback}
              {...tag}
            />
          ))
        ) : (
          <EmptyState type={params.keys.search ? 'search' : 'tags'} />
        )}
      </div>
    </section>
  );
};

export default TagsTable;
