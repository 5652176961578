import { userRoles } from 'src/constants/enums';

const { user, admin } = userRoles;

export const mobileLinks = [
  {
    id: 1,
    label: 'Zarządzanie',
    value: '/offer-catalog',
    roles: [user, admin],
    exact: true
  },
  {
    id: 2,
    label: 'Dostawcy',
    value: '/offer-catalog/suppliers',
    roles: [user, admin]
  },
  {
    id: 3,
    label: 'Analityka',
    value: '/offer-catalog/analytics',
    roles: [user, admin]
  },
  {
    id: 4,
    label: 'OCR Faktur',
    value: '/offer-catalog/invoices',
    roles: [admin]
  }
];
