import React from 'react';

import style from '../Single.module.scss';

const SingleSupplier = ({ supplier, children }) => {
  const { name, phone, email } = supplier || {};

  return (
    <div className={style.container}>
      <div className={style.wrapper}>{children}</div>
      <div className={style.wrapper}>
        <p>{name}</p>
      </div>
      <div className={style.wrapper}>
        <p>{email}</p>
      </div>
      <div className={style.wrapper}>
        <p>{phone}</p>
      </div>
    </div>
  );
};

export default SingleSupplier;
