import React, { useState } from 'react';
import cn from 'classnames';

import { Button, Input, useAuthUser, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import { userRoles } from 'src/constants/enums';

import style from '../../Personnel.module.scss';

const InviteUserToCompany = ({ companyId, onSuccess }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const validator = useValidator();
  const user = useAuthUser();

  if (user.role !== userRoles.admin) {
    return null;
  }

  const handleInvite = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const queryData = {
      company_id: companyId,
      email
    };

    try {
      setIsLoading(true);
      await AdminApi.inviteUserToCompany(queryData);
      notifyCommon(['Zaproszenie zostało wysłane.']);
      if (onSuccess) onSuccess();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={cn(style.container, style.smaller)}
      onSubmit={handleInvite}
    >
      <Input
        id={'email-for-invite'}
        name={'email-for-invite'}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        label={'E-mail'}
        validator={validator}
        rule={'required|email'}
      />
      <Button
        label={'Dodaj do oddziału'}
        type={'submit'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default InviteUserToCompany;
