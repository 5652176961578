import React from 'react';

import style from './Tile.module.scss';

const Tile = ({ tile }) => {
  const { icon, title, description, action, content } = tile;

  return (
    <div className={style.container}>
      <h4 className={style.title}>
        {icon} {title}
      </h4>
      {!!description && <p>{description}</p>}
      {!!action && <div className={style.actions}>{action}</div>}
      {content}
    </div>
  );
};

export default Tile;
