import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Card } from 'components';

import style from './FloatingCard.module.scss';

const FloatingCard = ({ floating = true, title, description, children, yellow, red, trackLeftPosition, exitMessage }) => {
  const staticCardRef = useRef(null);
  const [isElementVisible, setIsElementVisible] = useState(false);
  const [leftPosition, setLeftPosition] = useState(0);
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);

  useEffect(() => {
    const windowListener = () => {
      if (staticCardRef.current) {
        setIsElementVisible(staticCardRef.current.getBoundingClientRect().bottom <= 0);
        if (trackLeftPosition) {
          setLeftPosition(staticCardRef.current.getBoundingClientRect().left + 12);
        }
      }
    };

    addEventListener('scroll', windowListener);
    return () => {
      removeEventListener('scroll', windowListener);
    };
  }, []);

  useEffect(() => {
    if (staticCardRef.current && trackLeftPosition) {
      setLeftPosition(staticCardRef.current.getBoundingClientRect().left + 12);
    }
  }, [isSidebarOpen]);

  const cardsContent = (
    <>
      <div className={style.texts}>
        {!!title && <h3 className={style.title}>{title}</h3>}
        {!!description && <p>{description}</p>}
        {!!exitMessage && <p className={style.exit}>{exitMessage}</p>}
      </div>
      <div className={style.childrenWrapper}>{children}</div>
    </>
  );

  return (
    <>
      <Card
        className={style.container}
        ref={staticCardRef}
        yellow={yellow}
        red={red}
      >
        {cardsContent}
      </Card>
      {floating && isElementVisible && (
        <Card
          className={cn(style.container, style.floating)}
          style={trackLeftPosition ? { left: leftPosition + 'px', transform: 'none' } : {}}
          showClose
          yellow
        >
          {cardsContent}
        </Card>
      )}
    </>
  );
};

export default FloatingCard;
