import * as types from './types';

export const refreshCollection = () => ({
  type: types.CHANGE_COLLECTION_VERSION
});

export const startDataLoading = () => ({
  type: types.START_DATA_LOADING
});

export const finishDataLoading = () => ({
  type: types.FINISH_DATA_LOADING
});
