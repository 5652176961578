import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { Button, Card, DateRangePicker, useIsAdmin, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';

import { toRangeMonth } from '../../../../../../utils/dateTime';

import styles from './AssignVolumesWithRangeForm.module.scss';

const AssignVolumesWithRangeForm = ({ selectedCompany, refetchData, closeModal }) => {
  const isAdmin = useIsAdmin();
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const validator = useValidator();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!startDate || !endDate) {
      return validator.showMessages();
    } else {
      validator.hideMessages();
    }

    const params = {
      ...(isAdmin && selectedCompany && { company_id: parseInt(selectedCompany) }),
      ...(startDate &&
        endDate && {
          date_from: dayjs(startDate).format('YYYY-MM-DD'),
          date_to: dayjs(endDate).format('YYYY-MM-DD')
        })
    };

    try {
      setIsLoading(true);
      const { data } = await OfferCatalogApi.setAvgVolumesWithRangeForCatalog(params);
      if (refetchData) refetchData();
      if (closeModal) closeModal();
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    validator.hideMessages();
  }, [startDate, endDate]);

  return (
    <form
      className={styles.container}
      onSubmit={handleSubmit}
    >
      <p>Algorytm automatycznie przypisze wolumen roczny do produktów na podstawie średniej wartości z wybranych miesięcy.</p>
      <p>
        <strong>Uwaga!</strong> Tej akcji nie będzie można cofnąć, ale zawsze można zmienić wolumen ręcznie.
      </p>
      <DateRangePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        validator={validator}
        rule={'required'}
        label={'Wybierz zakres'}
        wrapperStyle={styles.rangePicker}
        style={{ maxWidth: '100%' }}
        format={'MM/YYYY'}
        placeholder={'MM/YYYY'}
      />
      {startDate && endDate && (
        <Card
          className={styles.info}
          info
        >
          <div className={styles.selectedRange}>
            <p>
              <strong>Wybrany zakres:</strong> {toRangeMonth(startDate, endDate)}
            </p>
          </div>
        </Card>
      )}
      <Button
        label={'Przypisz'}
        isLoading={isLoading}
        className={styles.button}
      />
    </form>
  );
};

export default AssignVolumesWithRangeForm;
