import React from 'react';
import cn from 'classnames';

import styles from './Radio.module.scss';

const Radio = ({ className, content, name, value, onChange }) => {
  return (
    <label className={cn(styles.radio, className)}>
      <input
        data-hj-allow
        className={styles.input}
        type='radio'
        name={name}
        onChange={onChange}
        checked={value}
      />
      <span className={styles.inner}>
        <span className={styles.tick} />
        <span
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </span>
    </label>
  );
};

export default Radio;
