import React, { useState } from 'react';
import { Popover } from 'antd';
import cn from 'classnames';

import { ChangeValue, Icon, Menu, Modal, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OCRInvoicesApi } from 'src/api';
import { OCRInvoiceStatuses } from 'src/constants/enums';
import ProductFormAdmin from 'src/features/ProductsBase/components/ProductForms/components/ProductFormAdmin';
import { getFormattedAmount } from 'src/utils/helpers';

import ConnectProducts from '../ConnectProducts';
import RenameProductFormOCR from '../RenameProductFormOCR';

import style from './ProductThumbnail.module.scss';

const ProductThumbnail = ({ index, product, refreshCallback, status }) => {
  const { name, price, quantity, id } = product || {};

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalNameOpen, setIsModalNameOpen] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const handleSaveValue = async (value, key) => {
    try {
      await OCRInvoicesApi.editInvoiceProduct({ product_id: id, [key]: value });
      notifyCommon(['Dane produktu zostały zmienione.']);
      if (refreshCallback) refreshCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleDeleteProduct = async () => {
    try {
      await OCRInvoicesApi.deleteInvoiceProduct(id);
      notifyCommon(['Produkt został usunięty.']);
      if (refreshCallback) refreshCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleOpenDeleteProductConfirm = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć pozycję z faktury?',
      handleConfirm: handleDeleteProduct,
      content: (
        <p>
          Usunięta zostanie pozycja o nazwie <strong>{name}</strong>
        </p>
      )
    });
  };

  const actions = [
    {
      title: 'Zmień nazwę',
      icon: 'edit',
      action: () => setIsModalNameOpen(true)
    },
    {
      title: 'Usuń produkt',
      color: 'red',
      icon: 'trash',
      action: handleOpenDeleteProductConfirm
    }
  ];

  const namePopoverContent = (
    <div className={style.column}>
      <a onClick={() => setIsModalOpen(true)}>+ Dodaj ten produkt do bazy</a>
      <div className={style.nameWrapper}>{name}</div>
    </div>
  );

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <p>{index + 1}.</p>
      </div>
      <div className={style.wrapper}>
        <Popover content={namePopoverContent}>
          <p>{name}</p>
        </Popover>
      </div>
      <div className={cn(style.wrapper, style.dontShrink)}>
        <ConnectProducts
          isDisabled={status === OCRInvoiceStatuses.added}
          product={product}
          refreshCallback={refreshCallback}
        />
      </div>
      <div className={style.wrapper}>
        <ChangeValue
          disabled={status === OCRInvoiceStatuses.added}
          apiCallback={async (value) => await handleSaveValue(value, 'price')}
          valueWrapperClasses={cn({ [style.falsy]: !+price })}
          displayValueFormatter={getFormattedAmount}
          initialValue={price}
          placement={'bottom'}
          label={'Zmień cenę'}
          unit={'zł'}
          hideArrows
          icon={
            <Icon
              name={'edit'}
              fill={'#888'}
            />
          }
        />
      </div>
      <div className={style.wrapper}>
        <ChangeValue
          disabled={status === OCRInvoiceStatuses.added}
          apiCallback={async (value) => await handleSaveValue(value, 'quantity')}
          valueWrapperClasses={cn({ [style.falsy]: !+quantity })}
          initialValue={quantity}
          placement={'bottom'}
          label={'Zmień ilość'}
          icon={
            <Icon
              name={'edit'}
              fill={'#888'}
            />
          }
        />
      </div>
      <div className={style.wrapper}>
        <p>{getFormattedAmount(price * quantity)}</p>
      </div>
      <div className={style.wrapper}>
        <Menu
          actions={actions}
          className={style.menu}
        />
      </div>
      <ProductFormAdmin
        title='Dodaj produkt'
        type='create-product'
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        productName={name}
      />
      <Modal
        visible={isModalNameOpen}
        onClose={() => setIsModalNameOpen(false)}
        title={'Zmień nazwę produktu'}
      >
        <RenameProductFormOCR
          handleSave={handleSaveValue}
          currentName={name}
          onClose={() => setIsModalNameOpen(false)}
        />
      </Modal>
      {renderedModalConfirm}
    </div>
  );
};

export default ProductThumbnail;
