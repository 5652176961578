import { ReactComponent as LogoAvalio } from 'assets/img/logo-avalio.svg';
import { SeoHelmet } from 'components';

import pages from 'src/dictionaries/pages.json';

import ContactInfo from '../../components/ContactInfo';
import LoginForm from './components/LoginForm';

import style from '../../onboarding.module.scss';

const Login = () => (
  <>
    <SeoHelmet
      title={pages.login.title}
      titleForce
      description={pages.login.description}
    />
    <div className={style.container}>
      <div className={style.logoContainer}>
        <LogoAvalio />
      </div>
      <LoginForm />
      <ContactInfo />
    </div>
  </>
);

export default Login;
