import React from 'react';

import { SaveButton, useModalConfirm } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ReplacementsApi } from 'src/api';

import styles from './ProductReplacementsTableThumbnail.module.scss';

const ProductReplacementsTableThumbnail = ({ id, thumbnail, image, name, producer, setVersion, refreshCallback }) => {
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const handleRemoveProduct = async () => {
    try {
      const { data } = await ReplacementsApi.removeGroupProduct(id);
      if (refreshCallback) refreshCallback(true);
      setVersion((prev) => prev + 1);
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const openRemoveProductModalConfirm = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć wybrany produkt z grupy?',
      handleConfirm: handleRemoveProduct,
      content: (
        <p>
          Usunięty zostanie produkt o nazwie <strong>{name}</strong>.
        </p>
      )
    });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.nameWrapper}>
          <ProductThumbnailImage
            thumbnail={thumbnail}
            image={image}
            title={name}
            id={id}
            size={'tiny'}
          />
          <p>{name}</p>
        </div>
        <div>{producer}</div>
        <div>
          <SaveButton
            type={'delete'}
            onClick={openRemoveProductModalConfirm}
          />
        </div>
      </div>
      {renderedModalConfirm}
    </>
  );
};

export default ProductReplacementsTableThumbnail;
