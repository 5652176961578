import React from 'react';

import { FilterByCategory, FilterByCompany, FilterByProducer } from 'components';

const Filtering = ({ params }) => {
  return (
    <>
      <FilterByProducer
        fullWidth
        params={params}
      />
      <FilterByCategory
        fullWidth
        params={params}
        showAsTree
      />
      <FilterByCompany
        fullWidth
        params={params}
      />
    </>
  );
};

export default Filtering;
