import React, { useEffect, useRef } from 'react';
import { Popover } from 'antd';
import classNames from 'classnames';

import style from './ChangeMenu.module.scss';

const events = ['mousedown', 'touchstart'];

const ChangeMenu = ({
  wasDataChange,
  isActive,
  setIsActive,
  value,
  children,
  placement = 'bottomRight',
  valueWrapperClasses,
  displayValueFormatter = (val) => val,
  icon,
  disabled = false,
  destroyOnClose = true
}) => {
  const elementTarget = useRef(null);

  const wrapperClasses = classNames(
    style.wrapper,
    {
      [style.active]: isActive,
      [style.yellow]: wasDataChange,
      [style.disabled]: disabled
    },
    valueWrapperClasses
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (elementTarget.current && !elementTarget.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    const addEventListeners = () => {
      events.forEach((event) => document.addEventListener(event, handleClickOutside));
    };

    const removeEventListeners = () => {
      events.forEach((event) => document.removeEventListener(event, handleClickOutside));
    };

    addEventListeners();

    return () => {
      removeEventListeners();
    };
  }, []);

  useEffect(() => {
    if (isActive && elementTarget.current) {
      const inputElement = elementTarget.current.querySelector('input');
      if (inputElement) {
        inputElement.focus({ preventScroll: true });
      }
    }
  }, [isActive]);

  const content = (
    <div
      className={style.box}
      ref={elementTarget}
    >
      {children}
    </div>
  );

  const valueWrapper = (
    <div className={wrapperClasses}>
      {displayValueFormatter(value)}
      {!disabled && icon}
    </div>
  );

  if (disabled) {
    return valueWrapper;
  }

  return (
    <Popover
      trigger={'click'}
      placement={placement}
      onOpenChange={setIsActive}
      content={content}
      open={isActive}
      destroyTooltipOnHide={destroyOnClose}
    >
      {valueWrapper}
    </Popover>
  );
};

export default ChangeMenu;
