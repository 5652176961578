import classNames from 'classnames';

import { ReactComponent as InfoIcon } from 'src/assets/icons/info-icon.svg';

import CustomTooltip from '../CustomTooltip';

import style from './InfoWidget.module.scss';

const InfoWidget = ({ children, place = 'left', className, tooltipConfig }) => {
  return (
    <CustomTooltip
      title={children}
      placement={place}
      {...tooltipConfig}
    >
      <div className={classNames(style.container, className)}>
        <InfoIcon />
      </div>
    </CustomTooltip>
  );
};

export default InfoWidget;
