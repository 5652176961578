import React, { useEffect, useRef, useState } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { useAtom } from 'jotai/index';
import moment from 'moment';

import { AsyncSelect, Button, Card, Input, QtySelector, SwitchButton, useIsAdmin, useValidator } from 'components';
import Scanner from 'components/layout/BarcodeScanner/components/Scanner';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { ReactComponent as ScannerIcon } from 'src/assets/icons/scanner-gun.svg';
import { selectedCompanyAtom } from 'src/features/Warehouse';

import style from './ProductSupply.module.scss';

const defaultSupply = (product) => ({
  supplyDate: moment().format('YYYY-MM-DD'),
  expireDate: null,
  product: product ? { value: product.product_id, label: product.name, ...product } : null,
  quantity: 0,
  price: 0
});

const ProductSupply = ({ refreshData, closeModal, product }) => {
  const isAdmin = useIsAdmin();
  const dateInputRef = useRef();
  const validator = useValidator();
  const containerRef = useRef(null);

  const [selectedCompany] = useAtom(selectedCompanyAtom);

  const [data, setData] = useState(defaultSupply(product));
  const [version, setVersion] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [letModalOpen, setLetModalOpen] = useState(false);
  const [isScannerOpen, setIsScannerOpen] = useState(false);

  const changeData = (value, key) => {
    setData((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const clearFormHandler = () => {
    setData(defaultSupply());
    if (dateInputRef.current?.value) {
      dateInputRef.current.value = '';
    }
    setVersion((prev) => prev + 1);
    validator.purgeFields();
    validator.hideMessages();
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    const { product, quantity, price, expireDate, supplyDate } = data;
    const payload = {
      price,
      quantity,
      supply_date: supplyDate,
      product_id: product.value,
      ...(expireDate && { expire_date: expireDate }),
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      setIsLoading(true);
      await WarehouseApi.addManualSupply(payload);
      if (refreshData) refreshData();
      if (letModalOpen) clearFormHandler();
      else if (closeModal) closeModal();
      notifyCommon([`Dostawa produktu "${data.product.label}" została dodana.`]);
    } catch (error) {
      notifyApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    disableBodyScroll(containerRef.current);

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const onScanHandler = async (ean) => {
    const queryParams = {
      page: 1,
      perPage: 1,
      ean,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      const { data } = await WarehouseApi.getProducts(queryParams);
      const product = data.data[0];
      if (product) {
        changeData({ value: product.product_id, label: product.name, ...product }, 'product');
      } else {
        notifyCommon([`Nie znaleziono produktu. Kod EAN: ${ean}`]);
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsScannerOpen(false);
    }
  };

  if (isScannerOpen) {
    return (
      <div className={style.scannerWrapper}>
        <Scanner onScan={onScanHandler} />
        <Button
          label={'Zakończ skanowanie'}
          onClick={() => setIsScannerOpen(false)}
          className={style.stopScanButton}
        />
      </div>
    );
  }

  return (
    <form
      className={style.container}
      onSubmit={formSubmitHandler}
      ref={containerRef}
    >
      <div className={style.inputWrapper}>
        <Button
          onClick={() => setIsScannerOpen(true)}
          label={<ScannerIcon className={style.icon} />}
          className={style.buttonScanner}
          disabled={!!data.product}
          type={'button'}
        />
        <AsyncSelect
          apiCallback={WarehouseApi.getProducts}
          onChange={(val) => changeData(val, 'product')}
          queryParams={{ version, ...(isAdmin && { company_id: selectedCompany }) }}
          valueKey={'product_id'}
          placeholder={'Produkt'}
          id={'select-product'}
          validator={validator}
          value={data.product}
          rule={'required'}
          labelKey={'name'}
          label={'Produkt'}
          productSearch
          isClearable
        />
      </div>
      {data.product && data.product.unit_name !== 'szt.' && (
        <Card yellow>
          <p className={style.text}>
            <strong>Uwaga!</strong> wybrany produkt występuje w opakowaniach zbiorczych{' '}
            <strong>po {data.product?.collection_amount ?? '?'} szt.</strong>
          </p>
        </Card>
      )}
      <div className={style.wrapper}>
        <QtySelector
          label={'Ilość sztuk'}
          setState={(val) => changeData(val, 'quantity')}
          wrapperStyle={style.quantityInput}
          state={data.quantity}
          hideValueWhenZero
          id={'quantity'}
          suffix={'szt.'}
          validator={validator}
          rule={'required|only_positive'}
        />
        <span className={style.divider} />
        <QtySelector
          label={'Cena za sztukę'}
          setState={(val) => changeData(val, 'price')}
          wrapperStyle={style.quantityInput}
          state={data.price}
          hideValueWhenZero
          validator={validator}
          rule={'required|only_positive'}
          id={'price'}
          suffix={'zł'}
          priceInput
          hideArrows
        />
        <span className={style.divider} />
        <Input
          type='date'
          id='expired_date'
          name='expired_date'
          value={data.expireDate || ''}
          ref={dateInputRef}
          onChange={(e) => changeData(e.target.value, 'expireDate')}
          label={'Termin ważności'}
          wrapperStyle={style.quantityInput}
        />
        <span className={style.divider} />
        <Input
          type='date'
          id='supply_date'
          name='supply_date'
          value={data.supplyDate}
          onChange={(e) => changeData(e.target.value, 'supplyDate')}
          validator={validator}
          rule={'required'}
          label={'Data dostawy'}
          wrapperStyle={style.quantityInput}
        />
      </div>
      <Button
        className={style.button}
        label={'Dodaj dostawę'}
        isLoading={isLoading}
      />
      <div className={style.switchWrapper}>
        <SwitchButton
          setValue={setLetModalOpen}
          value={letModalOpen}
          label={'Wyczyść formularz i zostaw otwarte okno'}
          reverse
        />
      </div>
    </form>
  );
};

export default ProductSupply;
