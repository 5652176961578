import * as types from '../actions/types';

export const initialState = {
  version: 0,
  editedProduct: {
    id: null,
    suppliers: []
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_LIST_VERSION:
      return {
        ...state,
        version: state.version + 1
      };
    case types.EDIT_NEW_PRODUCT:
      return {
        ...state,
        editedProduct: {
          id: action.payload,
          suppliers: []
        }
      };
    case types.ADD_SUPPLIER_DATA: {
      const arr = state.editedProduct.suppliers.filter((supplier) => supplier?.supplier_id !== action.payload?.supplier_id);

      return {
        ...state,
        editedProduct: {
          ...state.editedProduct,
          suppliers: [...arr, action.payload]
        }
      };
    }
    default:
      return state;
  }
};
