import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { atom, useSetAtom } from 'jotai';

import {
  Button,
  Card,
  EmptyState,
  FilterByCompany,
  Modal,
  PageHeader,
  PaginationNav,
  Searchbar,
  SeoHelmet,
  StickyPageHeader,
  SwitchButton,
  useIsAdmin,
  useRequestAbortController,
  useSearchParam
} from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ServicesApi } from 'src/api';
import pages from 'src/dictionaries/pages.json';
import ServicersList from 'src/features/Services/components/ServicersList';
import ServicesTable from 'src/features/Services/components/ServicesTable';
import { handleNumeralWords } from 'src/utils/helpers';
import query from 'src/utils/query';

import AddServiceForm from './components/AddServiceForm';
import { refreshServices } from './actions';

import styles from './Services.module.scss';

export const selectedCompanyAtom = atom(undefined);

const Services = (props) => {
  const params = query(props);
  const dispatch = useDispatch();
  const version = useSelector((state) => state.services.version);

  const isAdmin = useIsAdmin();
  const setSelectedCompany = useSetAtom(selectedCompanyAtom);

  const page = useSearchParam(params, 'page');
  const search = useSearchParam(params, 'search');
  const selectedCompany = useSearchParam(params, 'company');

  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(18);
  const [total, setTotal] = useState(null);
  const [pageData, setPageData] = useState([]);
  const [showDisabled, setShowDisabled] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isServicersListVisible, setIsServicersListVisible] = useState(false);

  const [abortController, setNewController] = useRequestAbortController();

  const getData = async () => {
    if (isAdmin && !selectedCompany) return null;

    if (abortController) abortController.abort();
    const signal = setNewController();

    const params = {
      page: page || 1,
      perPage: perPage,
      show_disabled: showDisabled ? 1 : 0,
      ...(search && { search: search }),
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      setIsLoading(true);
      const { data } = await ServicesApi.getServicesList(params, signal);
      setPageQty(data.last_page);
      setPageData(data.data);
      setTotal(data.total);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const saveHandler = () => {
    setVisible(false);
    dispatch(refreshServices());
  };

  useEffect(() => {
    getData();
  }, [version, search, page, perPage, selectedCompany, showDisabled]);

  useEffect(() => {
    setSelectedCompany(selectedCompany);
  }, [selectedCompany]);

  if (isAdmin && !selectedCompany) {
    return (
      <>
        <PageHeader
          name={pages.services.title}
          text={'Wybierz firmę'}
        />
        <Card className={styles.centerContent}>
          <EmptyState type={'selectCompanyServices'} />
          <FilterByCompany
            value={selectedCompany}
            params={params}
          />
        </Card>
      </>
    );
  }

  return (
    <>
      <SeoHelmet title={pages.services.title} />
      <StickyPageHeader
        name={pages.services.title}
        belowContent={
          <div>
            {total ? (
              `${total} ${handleNumeralWords(['sprzęt', 'sprzęty', 'sprzętów'], pageData.length)}`
            ) : (
              <Skeleton
                width={100}
                height={20}
              />
            )}
          </div>
        }
      >
        <SwitchButton
          value={showDisabled}
          setValue={setShowDisabled}
          label={'Pokaż wyłączone'}
        />
        <Searchbar
          params={params}
          searchSize={'small'}
          fullWidth
        />
        <Button
          label={'Lista serwisantów'}
          onClick={() => setIsServicersListVisible(true)}
          gray
        />
        <Button
          label={'Dodaj sprzęt'}
          onClick={() => setVisible(true)}
          iconName={'add'}
        />
      </StickyPageHeader>
      <div className={styles.container}>
        <ServicesTable
          list={pageData}
          isLoading={isLoading}
          params={params}
        />
        <PaginationNav
          params={params}
          pagesQty={pageQty}
          setPerPage={setPerPage}
          defaultQty={perPage}
        />
        <Modal
          visible={visible}
          onClose={() => setVisible(false)}
          title={'Dodaj sprzęt'}
        >
          <AddServiceForm
            refreshCallback={saveHandler}
            onClose={() => setVisible(false)}
          />
        </Modal>
        <Modal
          visible={isServicersListVisible}
          onClose={() => setIsServicersListVisible(false)}
          hideHeader
        >
          <ServicersList onClose={() => setIsServicersListVisible(false)} />
        </Modal>
      </div>
    </>
  );
};

export default Services;
