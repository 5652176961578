import React, { useEffect, useState } from 'react';

import { Button, FilterByCategory, Modal, PaginationNav, Searchbar, useRequestAbortController, useSearchParam } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { WholesalersApi } from 'src/api';
import query from 'src/utils/query';

import WholesalerAddProductForm from '../WholesalerAddProductForm';
import WholesalerImportExportForm from '../WholesalerImportExportForm';
import WholesalerProductsTable from '../WholesalerProductsTable';

import styles from './WholesalerProducts.module.scss';

const WholesalerProducts = ({ wholesalerId, paramsData }) => {
  const params = query(paramsData);

  const search = useSearchParam(params, 'search');
  const sortBy = useSearchParam(params, 'sort_by');
  const sortOrder = useSearchParam(params, 'sort_order');
  const page = useSearchParam(params, 'page');
  const categoryId = useSearchParam(params, 'category');

  const [pageData, setPageData] = useState([]);
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [loading, setLoading] = useState(true);

  const [addProductVisible, setAddProductVisible] = useState(false);
  const [importExportVisible, setImportExportVisible] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();

  const getData = async () => {
    if (!wholesalerId) return;

    if (abortController) abortController.abort();
    const signal = setNewController();

    const params = {
      wholesaler_id: wholesalerId,
      page: page || 1,
      perPage: perPage,
      ...(search && { search: search }),
      ...(categoryId && { category_id: Number(categoryId) }),
      ...(sortBy && { sort_by: sortBy }),
      ...(sortOrder && { sort_order: sortOrder })
    };

    try {
      setLoading(true);
      const { data } = await WholesalersApi.getProducts(params, signal);
      setPageQty(data.last_page);
      setPageData(data.data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [search, sortBy, sortOrder, page, perPage, categoryId]);

  return (
    <div>
      <div className={styles.buttonsContainer}>
        <div className={styles.buttons}>
          <Button
            label='Dodaj produkt'
            onClick={() => setAddProductVisible(true)}
          />
          <Button
            label='Import/Export produktów'
            onClick={() => setImportExportVisible(true)}
            gray
          />
        </div>
        <div className={styles.buttons}>
          <FilterByCategory params={params} />
          <Searchbar params={params} />
        </div>
      </div>
      <WholesalerProductsTable
        list={pageData}
        isLoading={loading}
        params={params}
        refetchProducts={getData}
      />
      <PaginationNav
        params={params}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
      />
      <Modal
        visible={addProductVisible}
        onClose={() => setAddProductVisible(false)}
        title={'Dodaj produkt do hurtownii'}
      >
        <WholesalerAddProductForm
          wholesalerId={wholesalerId}
          closeModal={() => {
            setAddProductVisible(false);
            getData();
          }}
        />
      </Modal>
      <Modal
        visible={importExportVisible}
        onClose={() => setImportExportVisible(false)}
        title={'Import/Export produktów hurtownii'}
      >
        <WholesalerImportExportForm
          wholesalerId={wholesalerId}
          closeModal={() => {
            setImportExportVisible(false);
            getData();
          }}
        />
      </Modal>
    </div>
  );
};

export default WholesalerProducts;
