import React, { useState } from 'react';

import { Menu, RelativeTime, useModalConfirm } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WholesalersApi } from 'src/api';

import WholesalerProductChangeNumberForm from './components/WholesalerProductChangeNumberForm';

import styles from './WholesalerProductThumbnail.module.scss';

const WholesalerProductThumbnail = ({
  image,
  thumbnail,
  product_id,
  product_name,
  product_number,
  category_name,
  producer_name,
  created_at,
  updated_at,
  wholesaler_id,
  wholesaler_product_id,
  refetchProducts
}) => {
  const [isActive, setIsActive] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const deleteWholesalerProduct = async () => {
    try {
      const { data } = await WholesalersApi.deleteWholesalerProduct(wholesaler_product_id);
      refetchProducts();
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteWholesalerProductConfirm = () => {
    handleOpenModalConfirm({
      message: `Na pewno chcesz usunąć produkt ${product_name} z hurtownii?`,
      handleConfirm: deleteWholesalerProduct
    });
  };

  const actions = [
    {
      title: 'Edytuj identyfikator',
      icon: 'edit',
      action: () => setIsActive(true),
      color: 'blue'
    },
    {
      title: 'Usuń produkt',
      icon: 'trash',
      action: deleteWholesalerProductConfirm,
      color: 'red'
    }
  ];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.productThumbnail}>
          <ProductThumbnailImage
            thumbnail={thumbnail}
            image={image}
            title={product_name}
            id={product_id}
          />
          <span>{product_name}</span>
        </div>
        <div>
          <WholesalerProductChangeNumberForm
            currentNumber={product_number}
            isActive={isActive}
            setIsActive={setIsActive}
            productId={product_id}
            wholesalerId={wholesaler_id}
            wholesalerProductId={wholesaler_product_id}
            refetchProducts={refetchProducts}
          />
        </div>
        <div>{category_name}</div>
        <div>{producer_name}</div>
        <div>{created_at ? <RelativeTime date={created_at} /> : '-'}</div>
        <div>{updated_at ? <RelativeTime date={updated_at} /> : '-'}</div>
        <div>
          <Menu
            actions={actions}
            className={styles.menu}
          />
        </div>
      </div>
      {renderedModalConfirm}
    </>
  );
};

export default WholesalerProductThumbnail;
