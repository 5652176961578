import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { ProgressLine, Tag, useIsAdmin, useRequestAbortController } from 'components';
import ShipmentPriceInfo from 'components/layout/ShipmentPriceInfo';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi, OfferCatalogApi } from 'src/api';
import { getFormattedAmount, handleNumeralWords } from 'src/utils/helpers';

import CartSummary from '../CartSummary';

import style from '../../CatalogAndCartController.module.scss';

const SelectionSummary = ({ pageData, showRefPrice, selectedCompany, isCatalogView }) => {
  const isAdmin = useIsAdmin();
  const { listUID } = useParams();

  const [isSummaryLoading, setIsSummaryLoading] = useState(true);
  const [savingsData, setSavingsData] = useState(pageData?.summary?.savings || []);
  const [refSavingsData, setRefSavingsData] = useState(pageData?.summary?.ref_savings || null);
  const [summaryData, setSummaryData] = useState(pageData?.summary?.suppliers || []);

  const [abortController, setNewController] = useRequestAbortController();
  const version = useSelector((state) => state.cartAndCatalog.summaryVersion);

  const getPercentages = (a, b) => (a === 0 ? '' : `${(((a - b) * 100) / a).toFixed(2)}%`);
  const getValue = (a, b) => (b === 0 ? 'brak danych' : '');

  const cartSummary = useMemo(() => {
    let sum = 0;
    let totalSum = 0;
    let count = 0;
    let shippingCost = 0;

    summaryData?.forEach((supplier) => {
      sum += supplier.sum;
      totalSum += supplier.total_sum;
      count += supplier.product_count;
      shippingCost += supplier.shipping_price;
    });

    return { sum, count, shippingCost, totalSum };
  }, [summaryData]);

  useEffect(() => {
    const getSummary = async () => {
      let error;
      const queryParams = {
        ...(isAdmin && { company_id: selectedCompany }),
        ...(isAdmin && { get_savings: +showRefPrice }),
        ...(listUID && { list_uid: listUID })
      };

      if (abortController) abortController.abort();
      const signal = setNewController();

      const Api = isCatalogView ? OfferCatalogApi : ListsApi;

      try {
        setIsSummaryLoading(true);
        const { data } = await Api.getSummary(queryParams, signal);
        setSummaryData(data.suppliers);
        setSavingsData(data.savings);
        setRefSavingsData(data.ref_savings);
      } catch (err) {
        notifyApiError(err);
        error = err;
      } finally {
        setIsSummaryLoading(error?.message === 'canceled');
      }
    };

    getSummary();
  }, [version, showRefPrice, pageData]);

  const { avg_sum, max_sum, median_sum, total_sum, ref_prices_sum, products_with_ref_prices_sum } = savingsData;

  return (
    <div className={classNames(style.optionsWrapper)}>
      <div className={style.optionsInfo}>
        <span className={style.priceWrapper}>
          {isSummaryLoading ? (
            <Skeleton
              height={31}
              width={180}
            />
          ) : (
            <h2 className={style.orderPrice}>{getFormattedAmount(cartSummary.sum + cartSummary.shippingCost)}</h2>
          )}
          {isSummaryLoading ? (
            <Skeleton
              height={12}
              width={310}
            />
          ) : (
            <ShipmentPriceInfo
              shippingPrice={cartSummary.shippingCost}
              className={style.shippingPrice}
              included
            />
          )}
          {!isCatalogView && refSavingsData?.ref_savings > 0 && (
            <div className={style.refSavings}>Oszczędzasz: {getFormattedAmount(refSavingsData.ref_savings)}</div>
          )}
          {showRefPrice && !!cartSummary.sum && (
            <div className={style.savings}>
              {!!total_sum && (
                <>
                  <Tag
                    className={style.tag}
                    color={'yellow'}
                    value={`Oszczędność względem listy: ${getValue(
                      ref_prices_sum,
                      products_with_ref_prices_sum
                    )} ${getPercentages(ref_prices_sum, products_with_ref_prices_sum)}`}
                  />
                  <Tag
                    className={style.tag}
                    color={'green'}
                    value={`Oszczędność względem średniej ceny produktu: ${getValue(avg_sum, total_sum)} ${getPercentages(
                      avg_sum,
                      total_sum
                    )}`}
                  />
                  <Tag
                    className={style.tag}
                    color={'green'}
                    value={`Oszczędność względem mediany cen produktu: ${getValue(median_sum, total_sum)} ${getPercentages(
                      median_sum,
                      total_sum
                    )}`}
                  />
                  {/*<Tag*/}
                  {/*  className={style.tag}*/}
                  {/*  color={'green'}*/}
                  {/*  value={`Oszczędność względem najniższych cen produktu: ${getValue(min_sum, total_sum)} ${getPercentages(*/}
                  {/*    min_sum,*/}
                  {/*    total_sum*/}
                  {/*  )}`}*/}
                  {/*/>*/}
                  <Tag
                    className={style.tag}
                    color={'green'}
                    value={`Oszczędność względem najwyższych cen produktu: ${getValue(max_sum, total_sum)} ${getPercentages(
                      max_sum,
                      total_sum
                    )}`}
                  />
                </>
              )}
            </div>
          )}
          {isSummaryLoading ? (
            <Skeleton
              height={27}
              width={210}
            />
          ) : listUID ? (
            <div className={style.progress}>
              <ProgressLine
                maxValue={pageData.total}
                currentValue={summaryData?.reduce((a, c) => a + c.product_count, 0)}
                className={style.bar}
              />
            </div>
          ) : (
            <div className={style.savings}>
              <Tag
                value={`Wybrano ${cartSummary.count} ${handleNumeralWords(
                  ['produkt', 'produkty', 'produktów'],
                  cartSummary.count
                )}`}
                className={style.tag}
              />
            </div>
          )}
        </span>
      </div>
      <CartSummary
        suppliers={pageData?.suppliers}
        summary={summaryData}
        isLoading={isSummaryLoading}
      />
    </div>
  );
};

export default SelectionSummary;
