import React from 'react';

import { FloatingCard } from 'components';

import { toDateNumber } from 'src/utils/dateTime';

import style from './DeclinedReason.module.scss';

const DeclinedReason = ({ orderApprovalData, declineReason, showExit }) => {
  const { decline_reason, updated_at, threshold_email } = orderApprovalData || {};

  if (declineReason) {
    return (
      <FloatingCard
        className={style.container}
        floating={false}
        title={'Zamówienie zostało odrzucone'}
        description={'Powód: ' + declineReason}
        red
      />
    );
  }

  if (!orderApprovalData) {
    return null;
  }

  return (
    <FloatingCard
      className={style.container}
      floating={false}
      title={'Zamówienie zostało odrzucone'}
      description={
        <div className={style.wrapper}>
          <div>
            <strong>Data odrzucenia:</strong> {toDateNumber(updated_at)}
          </div>
          <div>
            <strong>Akceptujący:</strong> {threshold_email}
          </div>
          <div>
            <strong>Powód:</strong> {decline_reason}
          </div>
        </div>
      }
      exitMessage={showExit && 'Zamówienie wróciło do wersji roboczej, możesz ponowanie wysłać je do akceptacji.'}
      red
    />
  );
};

export default DeclinedReason;
