import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ReactComponent as FillIcon } from 'assets/icons/clipboard.svg';
import { CustomTooltip, OutsideClickHandler, SwitchButton } from 'components';

import { closeSummary, toggleSummary } from '../../actions';

import style from './ValuationDropdown.module.scss';

const ValuationDropdown = ({ children }) => {
  const dispatch = useDispatch();
  const [isAutoCloseEnabled, setIsAutoCloseEnabled] = useState(true);

  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const isVisible = useSelector((state) => state.valuationView.isSummaryOpen);

  const toggleHandler = () => {
    dispatch(toggleSummary());
    setIsAutoCloseEnabled((prev) => !prev);
  };
  const closeHandler = () => {
    if (isAutoCloseEnabled) dispatch(closeSummary());
  };

  const containerClasses = classNames(style.container, {
    [style.hidden]: !isVisible && isAutoCloseEnabled,
    [style.sidebarOpen]: isSidebarOpen
  });

  const switchTooltipContent = <p>{`Automatyczne zwijanie ${isAutoCloseEnabled ? 'włączone' : 'wyłączone'}`}</p>;

  return (
    <OutsideClickHandler onOutsideClick={closeHandler}>
      <div className={containerClasses}>
        <button
          className={style.toggleBtn}
          onClick={toggleHandler}
        >
          <FillIcon className={style.icon} />
        </button>
        <div className={style.wrapper}>
          <div className={style.switch}>
            <CustomTooltip title={switchTooltipContent}>
              <SwitchButton
                setValue={setIsAutoCloseEnabled}
                value={isAutoCloseEnabled}
              />
            </CustomTooltip>
          </div>
          {children}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default ValuationDropdown;
