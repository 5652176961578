import SingleValuation from '../../../PendingValuations/components/SingleValuation';

import style from '../../../PendingValuations/PendingValuations.module.scss';

const OffersViewProductsList = ({ products }) => {
  return (
    <div className={style.elementsWrapper}>
      <div className={style.tableWrapper}>
        <div className={style.valuationsHeader}>
          <p>Status</p>
          <p>Numer</p>
          <p>Dostawca</p>
          <p>Produkty</p>
          <p>Data wysłania</p>
          <p>Data wygaśnięcia</p>
        </div>
        {products?.map((val) => (
          <SingleValuation
            valuation={val}
            key={val.public_id}
          />
        ))}
      </div>
    </div>
  );
};

export default OffersViewProductsList;
