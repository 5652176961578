import React, { useState } from 'react';
import classNames from 'classnames';

import { Checkbox, SupplierColor } from 'components';

import style from './SuppliersListItem.module.scss';
const SuppliersListItem = ({ supplier, onChange }) => {
  const { id, name, email, checked } = supplier || {};
  const [isChecked, setIsChecked] = useState(checked);
  const changeChecked = (id) => {
    setIsChecked(!isChecked);
    if (onChange) onChange(id);
  };

  return (
    <div className={style.container}>
      <div className={classNames(style.wrapper, style.checkbox)}>
        <Checkbox
          onChange={() => changeChecked(id)}
          value={checked}
        />
      </div>
      <div className={style.wrapper}>
        <p className={style.text}>{name}</p>
        <SupplierColor supplier={supplier} />
      </div>
      <div className={style.wrapper}>
        <p className={style.text}>{email}</p>
      </div>
    </div>
  );
};

export default SuppliersListItem;
