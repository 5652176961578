import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Button, FilterByCompany, Input, useIsAdmin, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';

import { refreshOrder } from '../../../OrdersView/actions';

import style from './CreateList.module.scss';

const CreateList = ({ closeModal, selectedProducts, products, orderID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [listName, setListName] = useState('');
  const validator = useValidator();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();

  const selectCompanyHandler = (value) => {
    setSelectedCompany(value);
  };

  const selectedProductsData = useMemo(
    () =>
      selectedProducts?.map((id) => {
        const product = products?.find(({ product_id }) => product_id === id);
        return {
          id,
          name: product?.name,
          quantity: product?.qty
        };
      }),
    [selectedProducts]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    const productsData = {
      order_id: orderID,
      title: listName,
      products: selectedProductsData?.map((product) => ({ id: product.id, quantity: product.quantity })),
      ...(isAdmin && selectedCompany && { company_id: selectedCompany })
    };

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      const { data } = await ListsApi.createList(productsData);
      notifyCommon([`Lista ${listName} została utworzona!`]);
      dispatch(refreshOrder());
      closeModal();
      history.push(`/lists/${data}`);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={handleSubmit}
    >
      <div className={style.inputs}>
        <Input
          id={'list-name'}
          name={'list-name'}
          label={'Nazwa listy'}
          placeholder={'Lista ortodoncja'}
          value={listName}
          onChange={(e) => setListName(e.target.value)}
          validator={validator}
          rule={'required'}
        />
        {isAdmin && (
          <FilterByCompany
            label={'Firma'}
            onChange={selectCompanyHandler}
            value={selectedCompany}
            fullWidth
          />
        )}
      </div>
      <Button
        label={'Utwórz listę'}
        type={'submit'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default CreateList;
