import React, { useState } from 'react';
import classnames from 'classnames';

import { Modal, ProductDetails, Radio, usePermissions } from 'components';

import { supplierTypes, userPermissions } from 'src/constants/enums';

import OfferWrapper from '../../../OfferWrapper';
import SelectedSupplierWrapper from '../../../SelectedSupplierWrapper';
import SuppliersList from '../../../SuppliersList';

import style from '../../Product.module.scss';

const ProductReplacementThumbnail = ({
  product,
  className,
  isCatalogView,
  recentlyOrdered,
  selectedCompany,
  showRefPrice = false,
  changeValuationSelections,
  isQuietLoading,
  setReplacement,
  currentReplacement,
  customQuantity,
  viewOnly = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [canMenageCatalog] = usePermissions([userPermissions.offer_catalog.menage]);

  const primary = product.prices.find((sup) => sup.priority === supplierTypes.primary);
  const secondary = product.prices.find((sup) => sup.priority === supplierTypes.secondary);
  const third = product.prices.find((sup) => sup.priority === supplierTypes.third);

  const primaryWithOptions = (product.prices.length > 0 && !secondary) || (product.prices.length > 1 && secondary);
  const secondaryWithOptions = (product.prices.length > 0 && !primary) || (product.prices.length > 1 && primary);
  const thirdWithOptions =
    (product.prices.length > 0 && !primary && !secondary) || (product.prices.length > 2 && primary && secondary);

  return (
    <>
      <div className={classnames(style.box, style.replacementThumbnail)}>
        <ProductDetails
          className={className}
          product={product}
          showRefPrice={showRefPrice}
          orderedDate={recentlyOrdered}
          withoutSavings
          autoSize
          showTags={isCatalogView}
          customQuantity={customQuantity}
        />
        <div className={style.supplierWrapperContainer}>
          <span className={style.mobileOfferLabel}>Oferta preferowana</span>
          <SelectedSupplierWrapper>
            {!!primary && (
              <div className={style.priceWrapper}>
                <OfferWrapper
                  item={primary}
                  product={product}
                  changeValuationSelections={changeValuationSelections}
                  selectedCompany={selectedCompany}
                  isCatalogView={isCatalogView}
                />
              </div>
            )}
          </SelectedSupplierWrapper>
        </div>
        <div className={style.supplierWrapperContainer}>
          <span className={style.mobileOfferLabel}>Oferta alternatywna</span>
          <SelectedSupplierWrapper>
            {!!secondary && (
              <div className={style.priceWrapper}>
                <OfferWrapper
                  item={secondary}
                  product={product}
                  changeValuationSelections={changeValuationSelections}
                  selectedCompany={selectedCompany}
                  isCatalogView={isCatalogView}
                />
              </div>
            )}
          </SelectedSupplierWrapper>
        </div>
        <div className={style.supplierWrapperContainer}>
          <span className={style.mobileOfferLabel}>Oferta zapasowa</span>
          <SelectedSupplierWrapper>
            {!!third && (
              <div className={style.priceWrapper}>
                <OfferWrapper
                  item={third}
                  product={product}
                  changeValuationSelections={changeValuationSelections}
                  selectedCompany={selectedCompany}
                  isCatalogView={isCatalogView}
                />
              </div>
            )}
          </SelectedSupplierWrapper>
        </div>
        {!viewOnly && (
          <Radio
            onChange={() => setReplacement(product)}
            value={currentReplacement?.product_id === product.product_id}
            className={style.radio}
          />
        )}
        <Modal
          visible={isOpen}
          onClose={() => setIsOpen(false)}
          title={'Wszystkie oferty'}
        >
          <SuppliersList
            product={product}
            changeValuationSelections={changeValuationSelections}
            selectedCompany={selectedCompany}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isQuietLoading={isQuietLoading}
          />
        </Modal>
      </div>
    </>
  );
};

export default ProductReplacementThumbnail;
