import React from 'react';
import classNames from 'classnames';

import { getFormattedAmount } from '../../../../utils/helpers';

import style from '../ProductHistory/ProductHistory.module.scss';

const ProductHead = ({ name, current_quantity, price }) => {
  return (
    <div className={classNames(style.column, style.productHead)}>
      <div className={classNames(style.wrapper, style.name)}>
        <p>{name}</p>
      </div>
      <div className={style.wrapper}>
        <p>{current_quantity}</p>
      </div>
      <div className={style.wrapper}>
        <p>{getFormattedAmount(price * current_quantity)}</p>
      </div>
    </div>
  );
};

export default ProductHead;
