import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AsyncSelect, Button, Card, Input, Textarea, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ProductsApi, VariationsApi } from 'src/api';

import { refreshVariations } from '../../actions';

import styles from './DefineGroupForm.module.scss';
import shared from 'styles/Shared.module.scss';

const defaultGroup = {
  name: '',
  description: '',
  product_ids: []
};

const DefineGroupForm = ({ groupData, closeModal }) => {
  const dispatch = useDispatch();

  const [group, setGroup] = useState(groupData || defaultGroup);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const validator = useValidator();

  const onChange = (e) => {
    setGroup((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const onChangeHandler = (name, data) => {
    setGroup((prev) => ({
      ...prev,
      [name]: data
    }));
  };

  const editGroupHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      group_id: groupData.id,
      name: group.name,
      ...(group.description && { description: group.description })
    };

    try {
      setIsLoading(true);
      const { data } = await VariationsApi.editGroup(params);
      dispatch(refreshVariations());
      closeModal();
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const addGroupHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      product_ids: group.product_ids,
      name: group.name,
      ...(group.description && { description: group.description })
    };

    try {
      setIsLoading(true);
      const { data } = await VariationsApi.addGroup(params);
      dispatch(refreshVariations());
      closeModal();
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setGroup((prev) => ({
      ...prev,
      product_ids: selectedProducts.map((product) => product.id)
    }));
  }, [selectedProducts]);

  return (
    <form
      className={styles.container}
      onSubmit={groupData ? editGroupHandler : addGroupHandler}
    >
      {!groupData && (
        <>
          <p>Zdefiniuj grupę wariantów, wybierając produkty i nadając jej nazwę oraz opcjonalnie opis.</p>
          <Card info>
            <strong>Uwaga!</strong> Produkty oznaczone na <strong className={shared.colorRed}>czerwono</strong> w wyszukiwarce
            produktów są już przypisane do istniejącej grupy. Wybranie ich ponownie spowoduje przeniesienie ich do nowo tworzonej
            grupy.
          </Card>
        </>
      )}
      {!groupData && (
        <AsyncSelect
          id={'selected-products'}
          value={selectedProducts}
          onChange={setSelectedProducts}
          apiCallback={ProductsApi.getProducts}
          label={'Wybierz produkty'}
          valueKey={'id'}
          labelKey={'name'}
          placeholder={'Wyszukaj..'}
          validator={validator}
          rule={'required'}
          productSearch
          highlightUsedInVariationsGroup
          isClearable
          isMulti
        />
      )}
      <Input
        id='name'
        name='name'
        label='Nazwa grupy'
        value={group.name}
        onChange={onChange}
        validator={validator}
        rule={'required'}
      />
      <Textarea
        id={'description'}
        name={'description'}
        label={'Opis grupy'}
        value={group.description || ''}
        setValue={(description) => onChangeHandler('description', description)}
      />
      <Button
        label={groupData ? 'Zapisz' : 'Dodaj'}
        isLoading={isLoading}
        className={styles.button}
      />
    </form>
  );
};

export default DefineGroupForm;
