import React, { useEffect, useMemo, useState } from 'react';

import { Card, Chart, Select, SwitchButton, useIsAdmin } from 'components';
import { getCustomTooltipWithSeriesSum } from 'components/layout/Chart';
import { notifyApiError } from 'components/layout/Toasts';

import { StatisticsApi } from 'src/api';
import { toDateNumber } from 'src/utils/dateTime';
import { getFormattedAmount, translateMonthsWithInfo } from 'src/utils/helpers';

import ExportData from '../ExportData';

import style from '../Charts.module.scss';

const options = [
  { label: 'Wartość oszczędności narastająco', value: 'saved_money_agregation' },
  { label: 'Wartość oszczędności miesięcznie', value: 'saved_money' },
  { label: 'Procent oszczędności w stosunku do łącznych wydatków', value: 'perc_saved_to_total_sum' },
  { label: 'Procent oszczędności w stosunku do kosztów produktów', value: 'perc_saved_to_product_sum' }
];

const SavingsMonthly = ({ selectedCompanyId, filters }) => {
  const isAdmin = useIsAdmin();
  const [chartData, setChartData] = useState({});
  const [isStacked, setIsStacked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState(options[0]);

  const prefix = isAdmin ? 'series_' : '';

  const getData = async () => {
    if (isAdmin && !selectedCompanyId) return;

    const queryData = {
      ...(isAdmin
        ? {
            company_ids:
              typeof selectedCompanyId === 'string' ? [Number(selectedCompanyId)] : selectedCompanyId.map((id) => Number(id))
          }
        : { company_ids: filters.selectedCompanies.map((company) => company.value) }),
      ...(filters.startDate && filters.endDate && { date_from: filters.startDate, date_to: filters.endDate })
    };

    const ApiCallback = isAdmin ? StatisticsApi.getSavingsMonthlyByCompanies : StatisticsApi.getSavingsMonthly;

    try {
      setIsLoading(true);
      const { data } = await ApiCallback(queryData);
      setChartData(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [filters, selectedCompanyId]);

  const selectedChartData = useMemo(() => {
    if (chartData) return isAdmin ? chartData[prefix + selectedDataType.value] : chartData[selectedDataType.value];
    else return [];
  }, [chartData, selectedDataType, selectedCompanyId]);

  const chartFormatter = useMemo(() => {
    let yAxis;
    let formatter;

    switch (selectedDataType?.value) {
      case 'saved_money': {
        yAxis = (val) => getFormattedAmount(val);
        if (isAdmin) {
          formatter = (val, index, dataPointIndex) => {
            const percentages = chartData?.series_perc_saved_to_total_sum[index]?.data[dataPointIndex];
            const formattedPercentages = getFormattedAmount(percentages, '%');
            return `${getFormattedAmount(val)} ${index !== undefined ? `(${formattedPercentages})` : ''}`;
          };
        } else {
          formatter = (val, { dataPointIndex }) => {
            const percentages = chartData?.perc_saved_to_total_sum[dataPointIndex];
            const formattedPercentages = getFormattedAmount(percentages, '%');
            return `${getFormattedAmount(val)} (${formattedPercentages})`;
          };
        }
        break;
      }
      case 'saved_money_agregation':
        yAxis = (val) => getFormattedAmount(val);
        formatter = (val) => getFormattedAmount(val);
        break;
      case 'perc_saved_to_total_sum':
      case 'perc_saved_to_product_sum':
        yAxis = (val) => +val.toFixed(2) + '%';
        formatter = (val) => +val.toFixed(2) + '%';
        break;
      default:
        yAxis = (val) => val;
        formatter = (val) => val;
        break;
    }

    const getSeriesSumRenderer = ({ series, dataPointIndex, w }) => {
      const formatterFn = (value, index) => formatter(value, index, dataPointIndex);
      return getCustomTooltipWithSeriesSum(series, dataPointIndex, w, formatterFn);
    };

    return { yAxis, formatter, getSeriesSumRenderer };
  }, [chartData, selectedCompanyId, selectedDataType, isStacked]);

  return (
    <Card className={style.container}>
      <header className={style.header}>
        <h2 className={style.title}>Oszczędności</h2>
        <div className={style.options}>
          {isAdmin && (
            <SwitchButton
              setValue={setIsStacked}
              value={isStacked}
              label={'Sumuj dane'}
            />
          )}
          <ExportData
            company_id={selectedCompanyId}
            filters={filters}
            apiCallback={StatisticsApi.exportSavingsData}
          />
        </div>
      </header>
      <Chart
        fullWidth
        height={350}
        title={'Oszczędności w ujęciu miesięcznym'}
        isLoading={isLoading}
        chartTypes={['area', 'bar']}
        categories={chartData?.months}
        haveData={chartData?.have_data}
        stacked={isAdmin ? isStacked : false}
        configRefreshVariable={selectedDataType}
        yAxisFormatter={chartFormatter.yAxis}
        tooltipShared={isAdmin ? isStacked : false}
        dataLabelsFormatter={chartFormatter.formatter}
        categoriesLabel={'Oszczędności'}
        data={isAdmin ? undefined : selectedChartData}
        customSeries={isAdmin ? selectedChartData : undefined}
        categoriesFormatter={(value) => translateMonthsWithInfo(value, filters)}
        tooltipFormatter={!isAdmin ? chartFormatter.formatter : undefined}
        customTooltipRender={isAdmin ? chartFormatter.getSeriesSumRenderer : undefined}
        colors={['#13655b']}
      >
        <Select
          options={options}
          value={selectedDataType}
          onChange={setSelectedDataType}
          isSearchable={false}
          width={200}
          className={style.select}
          wrapperStyle={style.select}
        />
      </Chart>
      <p>
        * - Dane na dzień {toDateNumber(filters.endDate)}r. Dane agregowane są na podstawie zamówień o statusach: Oczekuje na
        potwierdzenie, W realizacji, Wysłano, Zrealizowano.
      </p>
    </Card>
  );
};

export default SavingsMonthly;
