import React from 'react';

import ValuationProduct from '../../../ValuationProduct';

import shared from 'styles/Shared.module.scss';

const ProductsList = ({ pageData, productClasses }) => {
  return (
    <>
      {pageData?.categories.map((category) => (
        <div
          className={shared.tableCategory}
          key={category.id}
        >
          <h3 className={shared.tableCategoryTitle}>{category.name}</h3>
          {category.products.map((product) => (
            <ValuationProduct
              product={product}
              key={product.id}
              className={productClasses}
            />
          ))}
        </div>
      ))}
    </>
  );
};

export default ProductsList;
