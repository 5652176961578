import React from 'react';
import cn from 'classnames';

import { Checkbox } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import style from './ProductListThumbnail.module.scss';

const ProductListThumbnail = ({ product, onChangeSelect, isSelected }) => {
  const { thumbnail, image, name, id } = product || {};

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <ProductThumbnailImage
          thumbnail={thumbnail}
          image={image}
          title={name}
          id={id}
          size={'tiny'}
        />
      </div>
      <div className={cn(style.wrapper, style.name)}>
        <p>{name}</p>
      </div>
      <div className={style.wrapper}>
        <Checkbox
          value={isSelected}
          onChange={onChangeSelect}
        />
      </div>
    </div>
  );
};

export default ProductListThumbnail;
