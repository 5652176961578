import React from 'react';

import { OrderOrValuationId, RelativeTime, Tag, Thumbnail, useIsAdmin, useIsMobile } from 'components';

import { toDate, toTime } from 'src/utils/dateTime';
import { getOrderStatusTag, getThumbnailName } from 'src/utils/helpers';

import style from '../OrderThumbnail/OrderThumbnail.module.scss';
import shared from 'styles/Shared.module.scss';

const ProviderOrderThumbnail = ({ item: order, small = false, type }) => {
  const isMobile = useIsMobile();
  const isAdmin = useIsAdmin();

  const menuActions = [
    {
      title: 'Otwórz w widoku z emaila',
      icon: 'send',
      color: 'blue',
      roles: ['admin'],
      action: () => window.open(`/confirm-order/${order.uid}`, '_blank', 'noopener,noreferrer')
    }
  ];

  return (
    <Thumbnail
      item={order}
      menu={isAdmin ? menuActions : []}
      showTime
      link={`/${type}/orders/${order.uid}`}
      small={small || isMobile}
      namePrefix={'Zamówienie dla: '}
      title={getThumbnailName(order)}
      status={getOrderStatusTag({ status: order.status })}
      extraInfo={
        <>
          <OrderOrValuationId
            id={order?.public_id}
            withPrefix={'Numer:'}
          />
        </>
      }
    >
      <div className={style.container}>
        {order?.sent_date && (
          <span>
            Wysłano: <RelativeTime date={order.sent_date} />
          </span>
        )}
        {isAdmin && (
          <div className={shared.thumbnailContentWrapper}>
            <p className={shared.thumbnailContentLabel}>Dostawca:</p>
            <Tag
              value={order?.supplier_user_name || order?.supplier_name}
              className={style.tag}
            />
          </div>
        )}
      </div>
    </Thumbnail>
  );
};

export default ProviderOrderThumbnail;
