import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { Loader } from 'components';

import { ReactComponent as TickIcon } from 'src/assets/icons/tick.svg';

import style from './LoadingIndicator.module.scss';

export const SAVE_ERROR_DATA_TIMEOUT = 7000;
const LoadingIndicator = ({ isLoading, hasError, aboveInput }) => {
  const [wasSaved, setWasSaved] = useState(true);
  const [wasInitialLoading, setWasInitialLoading] = useState(false);

  useEffect(() => {
    let timeout;
    setWasSaved(!isLoading);

    if (isLoading && !wasInitialLoading) setWasInitialLoading(true);
    if (!isLoading) timeout = setTimeout(() => setWasSaved(false), SAVE_ERROR_DATA_TIMEOUT);

    return () => clearTimeout(timeout);
  }, [isLoading]);

  if (!wasInitialLoading) {
    return null;
  }

  return (
    <div
      className={cn(style.container, { [style.aboveInput]: aboveInput, [style.withBgc]: aboveInput && (wasSaved || isLoading) })}
    >
      {isLoading && (
        <span className={style.loading}>
          <p className={style.text}>Zapisywanie</p> <Loader className={style.loader} />
        </span>
      )}
      {wasSaved &&
        (hasError ? (
          <span className={style.error}>
            <p className={style.text}>Błąd zapisywania!</p>
          </span>
        ) : (
          <span className={style.saved}>
            <p className={style.text}>Zapisano!</p> <TickIcon className={style.tick} />
          </span>
        ))}
    </div>
  );
};

export default LoadingIndicator;
