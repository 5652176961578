import React, { useEffect, useState } from 'react';

import { Loader } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { VariationsApi } from 'src/api';

import ProductVariationsCreateOrAddToGroupFormForm from './components/ProductVariationsCreateOrAddToGroupFormForm';
import ProductVariationsTable from './components/ProductVariationsTable';

import styles from './ProductVariations.module.scss';

const ProductVariations = ({ productId, canLoad, variationsCount, hasGroup, refreshCallback }) => {
  const [variations, setVariations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = async (hasGroup) => {
    if (!hasGroup) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const { data } = await VariationsApi.getProductVariations(productId);
      setVariations(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (canLoad) getData(hasGroup);
  }, [canLoad, variationsCount, hasGroup]);

  if (isLoading)
    return (
      <div className={styles.loadingStateContainer}>
        <Loader />
      </div>
    );

  return (
    <div className={styles.container}>
      <h3 className={styles.headline}>Warianty</h3>
      {hasGroup ? (
        <ProductVariationsTable
          {...variations}
          productId={productId}
          refreshCallback={refreshCallback}
        />
      ) : (
        <ProductVariationsCreateOrAddToGroupFormForm
          productId={productId}
          refreshCallback={refreshCallback}
        />
      )}
    </div>
  );
};

export default ProductVariations;
