import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Textarea } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';

import { refreshListView } from '../../actions';

import style from './ProductCommentForm.module.scss';

const ProductCommentForm = ({ product, productIds, uid, closeModal, onSuccess }) => {
  const dispatch = useDispatch();

  const [comment, setComment] = useState(product?.comment);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      product_ids: product ? [product.id] : productIds,
      comment,
      uid
    };

    try {
      setIsLoading(true);
      await ListsApi.setProductsComment(data);
      dispatch(refreshListView());
      notifyCommon(['Komentarz został zapisany.']);
      if (closeModal) closeModal();
      if (onSuccess) onSuccess();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={handleSubmit}
    >
      <Textarea
        id={`product-comment-${product ? product.id : productIds?.join('-')}`}
        name={`product-comment-${product ? product.id : productIds?.join('-')}`}
        label={'Komentarz do produktu'}
        value={comment}
        setValue={setComment}
      />
      <div className={style.buttonsWrapper}>
        <Button
          label={'Zapisz'}
          type={'submit'}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default ProductCommentForm;
