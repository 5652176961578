import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import cn from 'classnames';

import {
  Accordion,
  Menu,
  Modal,
  ProductThumbnailImage,
  SaveButton,
  Table,
  Tag,
  useIsAdmin,
  useModalConfirm,
  useRequestAbortController
} from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ExpensesApi } from 'src/api';
import { getExpenseBillingPeriodName, getExpensePaymentName, getFormattedAmount } from 'src/utils/helpers';

import { refreshExpenses } from '../../actions';
import AddCategoryForm from '../AddCategoryForm';
import AddInvoiceForm from '../AddInvoiceForm';
import ExpenseItem from '../ExpenseItem';

import style from './ExpenseThumbnail.module.scss';

const ExpenseThumbnail = (props) => {
  const isAdmin = useIsAdmin();
  const store = useSelector((state) => state.auth);
  const { selectedCompanyId } = store;
  const dispatch = useDispatch();

  const perPage = 10;
  const [editCategoryVisible, setEditCategoryVisible] = useState(false);
  const [addInvoiceVisible, setAddInvoiceVisible] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [expensePayments, setExpensePayments] = useState([]);
  const [fileUrl, setFileUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [wasExpenseOpened, setWasExpenseOpened] = useState(false);

  const [abortController, setNewController] = useRequestAbortController();

  const { small } = props;

  const { startDate, endDate, search, companyIds, displayGrouped } = props;
  const {
    billing_period,
    category_id,
    category_logo,
    category_name,
    comment,
    files,
    for_negotiation,
    id,
    payment_method,
    tag_name,
    last_payment,
    payment_sum
  } = props.item;

  const categoryData = {
    id: id,
    data: { label: category_name, value: category_id },
    billing_period: { label: getExpenseBillingPeriodName(billing_period), value: billing_period },
    payment_method: { label: getExpensePaymentName(payment_method), value: payment_method },
    comment: comment,
    existing_files: files,
    files: [],
    for_negotiation: for_negotiation
  };

  const actions = [
    {
      title: 'Dodaj płatność',
      icon: 'add',
      action: () => setAddInvoiceVisible(true),
      color: 'blue'
    },
    {
      title: 'Pobierz dokument',
      icon: 'download',
      action: () => openExpenseFile(files[0], true, true),
      disabled: files?.length > 0 ? false : true
    },
    {
      title: 'Edytuj wydatek',
      icon: 'edit',
      action: () => setEditCategoryVisible(true)
    },
    {
      title: 'Usuń wydatek',
      icon: 'trash',
      action: () => deleteExpenseConfirm(),
      color: 'red'
    }
  ];

  const deleteExpenseHandler = async () => {
    const params = {
      id: id
    };

    try {
      await ExpensesApi.deleteUserExpense(params);
      notifyCommon(['Wydatek usunięty.']);
      if (props.refreshCallback) props.refreshCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteExpenseConfirm = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć wydatek?',
      handleConfirm: deleteExpenseHandler,
      confirmButtonName: 'Tak',
      cancelButtonName: 'Nie'
    });
  };

  const handleViewExpenseFile = (url, download = false) => {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');

    if (download) {
      link.setAttribute('download', files[0].file_name);
    }

    document.body.appendChild(link);
    link.click();
  };

  const openExpenseFile = async (file, download = false, actionFromSubmenu = false) => {
    !actionFromSubmenu && setIsDownloading(true);

    const params = {
      expense_id: id,
      expense_file_id: file.id,
      file_name: file.file_name
    };

    if (fileUrl && download) {
      handleViewExpenseFile(fileUrl, true);
      setIsDownloading(false);
      return;
    }

    try {
      const { data, headers } = await ExpensesApi.downloadExpenseFile(params);
      const url = window.URL.createObjectURL(new Blob([data], { type: headers['content-type'] }));
      setFileUrl(url);

      if (download) {
        handleViewExpenseFile(url, true);
      } else {
        handleViewExpenseFile(url);
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsDownloading(false);
    }
  };

  const getData = async (page) => {
    if (abortController) abortController.abort();
    const signal = setNewController();
    let paymentsData;

    try {
      if (page !== 1) {
        setIsLoadingMore(true);
      } else {
        setIsLoading(true);
      }

      if (displayGrouped) {
        const params = {
          ...(startDate && { date_from: startDate }),
          ...(endDate && { date_to: endDate }),
          ...(search && { search: search }),
          expense_category_id: category_id,
          company_ids: companyIds,
          perPage: perPage,
          page: page
        };
        const { data } = await ExpensesApi.getExpensePaymentsGrouped(params, signal);
        paymentsData = data;
      } else {
        const params = {
          ...(isAdmin ? { company_ids: [] } : { company_id: selectedCompanyId }),
          ...(startDate && { date_from: startDate }),
          ...(endDate && { date_to: endDate }),
          ...(search && { search: search }),
          expense_id: id,
          perPage: perPage,
          page: page
        };
        const { data } = await ExpensesApi.getUserExpensePayments(params, signal);
        paymentsData = data;
      }

      if (page !== 1) {
        setExpensePayments((prev) => [...prev, ...paymentsData.data]);
      } else {
        setExpensePayments(paymentsData.data);
        setTotalPages(paymentsData.last_page);
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    if (!wasExpenseOpened) return;
    getData(currentPage);
  }, [wasExpenseOpened, currentPage]);

  const saveEditExpenseHandler = () => {
    setEditCategoryVisible(false);
    dispatch(refreshExpenses());
  };

  const saveAddPaymentHandler = () => {
    setAddInvoiceVisible(false);
    dispatch(refreshExpenses());
  };

  const thumbnail = (
    <div className={classNames(style.container, { [style.small]: small })}>
      <div className={cn(style.wrapper, style.mobileRow)}>
        <ProductThumbnailImage
          thumbnail={category_logo}
          image={category_logo}
          title={category_name}
          id={category_id}
          size={'tiny'}
          clickable={false}
        />
      </div>
      <div className={style.wrapper}>
        <p className={style.title}>{category_name}</p>
        <p className={style.label}>{tag_name}</p>
      </div>
      <div className={style.wrapper}>
        {!isAdmin && (
          <>
            <p className={style.label}>Ostatnia płatność:</p>
            <p className={style.text}>{last_payment ? getFormattedAmount(last_payment) : '-'}</p>
          </>
        )}
      </div>
      <div className={style.wrapper}>
        {!isAdmin && (
          <>
            <p className={style.label}>Suma płatności:</p>
            <p className={style.text}>{payment_sum ? getFormattedAmount(payment_sum) : '-'}</p>
          </>
        )}
      </div>
      <div className={style.wrapper}>
        {!isAdmin && for_negotiation === 1 ? (
          <Tag
            value={'Do negocjacji'}
            color={'green'}
          />
        ) : (
          <span />
        )}
      </div>
      <div className={style.wrapper}>
        {!isAdmin && !displayGrouped && (
          <SaveButton
            type={'add'}
            className='prevent-trigger-accordion'
            onClick={() => setAddInvoiceVisible(true)}
          />
        )}
      </div>
      {!isAdmin && !displayGrouped && (
        <Menu
          actions={actions}
          className={cn(style.menu, 'prevent-trigger-accordion')}
          displayOver
        />
      )}
      <Modal
        visible={addInvoiceVisible}
        onClose={() => setAddInvoiceVisible(false)}
        title={'Dodaj płatność'}
      >
        <AddInvoiceForm
          expenseId={id}
          closeModal={saveAddPaymentHandler}
        />
      </Modal>
      <Modal
        visible={editCategoryVisible}
        onClose={() => setEditCategoryVisible(false)}
        title={'Edytuj wydatek'}
      >
        <AddCategoryForm
          categoryData={categoryData}
          closeModal={saveEditExpenseHandler}
        />
      </Modal>
      {renderedModalConfirm}
    </div>
  );

  if (small) {
    return thumbnail;
  }

  const columns = displayGrouped
    ? [
        { name: 'Nazwa' },
        { name: 'Oddział' },
        { name: 'Kwota' },
        { name: 'Data płatności' },
        { name: 'Dokument' },
        { name: 'Komentarz' }
      ]
    : [{ name: 'Nazwa' }, { name: 'Kwota' }, { name: 'Data płatności' }, { name: 'Dokument' }, { name: 'Komentarz' }];

  return (
    <div className={cn(style.expenseWrapper, [isDownloading && style.expenseWrapperLoading])}>
      <Accordion
        title={thumbnail}
        defaultWrapped
        noPadding
        extraDeps={[expensePayments, isLoading, isLoadingMore]}
        setWasOpened={setWasExpenseOpened}
        noGap={!displayGrouped}
        withActions
      >
        {!isAdmin && !displayGrouped && (
          <div className={style.expenseMeta}>
            <div></div>
            <div>
              <p className={style.label}>Okres rozliczeniowy:</p>
              <p className={style.text}>{getExpenseBillingPeriodName(billing_period)}</p>
            </div>
            <div>
              <p className={style.label}>Metoda płatności:</p>
              <p className={style.text}>{getExpensePaymentName(payment_method)}</p>
            </div>
            <div>
              <p className={style.label}>Dokument:</p>
              <p className={style.text}>
                {files?.length > 0 ? (
                  files.map((file) => (
                    <a
                      className={style.text}
                      key={file.id}
                      onClick={() => openExpenseFile(file, false)}
                    >
                      {file.file_name}
                    </a>
                  ))
                ) : (
                  <span>-</span>
                )}
              </p>
            </div>
            <div>
              <p className={style.label}>Komentarz:</p>
              <p className={style.text}>{comment ?? '-'}</p>
            </div>
          </div>
        )}
        {isLoading ? (
          <div className={style.empty}>
            <Skeleton count={4} />
          </div>
        ) : expensePayments.length > 0 ? (
          <Table
            columns={columns}
            headerClasses={cn(style.expenseRow, style.header, [displayGrouped && style.expenseRowWithCompany])}
            className={style.table}
          >
            {expensePayments.map((payment) => (
              <ExpenseItem
                key={payment.id}
                item={payment}
                expenseId={payment.expense_id}
                refreshCallback={props.refreshCallback && props.refreshCallback}
              />
            ))}
            {isLoadingMore ? (
              <div>
                <Skeleton count={4} />
              </div>
            ) : currentPage < totalPages ? (
              <button
                onClick={() => setCurrentPage((prev) => prev + 1)}
                className={style.loadMoreButton}
              >
                Załaduj więcej
              </button>
            ) : null}
          </Table>
        ) : (
          <div className={style.empty}>
            {props.startDate || props.endDate || props.search ? (
              <p>Brak płatności spełniających kryteria wyszukiwania.</p>
            ) : (
              <p>Brak dodanych płatności.</p>
            )}
          </div>
        )}
      </Accordion>
    </div>
  );
};

export default ExpenseThumbnail;
