import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import ProductsSectionsPlaceholder from 'components/layout/ProductsSectionPlaceholder';
import { notifyApiError } from 'components/layout/Toasts';

import { OrdersApi } from 'src/api';
import Accordion from 'src/components/layout/Accordion';

import DepartmentFilter from './components/DepartmentFilter';
import ExportDepartments from './components/ExportDepartments';
import ProductItem from './components/ProductItem';

import style from './OrderProductsDepartments.module.scss';
import shared from 'styles/Shared.module.scss';

const OrderProductsDepartments = ({ uid }) => {
  const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await OrdersApi.getOrderProductsDepartments({ uid });
      setProducts(data);
      setVisibleProducts(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [uid]);

  const title = (
    <h3 className={classNames(shared.tableCategoryTitle, shared.large, shared.noPadding)}>
      Przydział dla jednostek organizacyjnych
    </h3>
  );

  if (isLoading) {
    return (
      <section className={shared.tableWrapper}>
        <ProductsSectionsPlaceholder />
      </section>
    );
  }

  if (products.length === 0) return null;
  return (
    <section className={style.accordionWrapper}>
      <Accordion
        title={title}
        defaultWrapped
        noGap
        overflowVisible
      >
        <div className={style.topBar}>
          <DepartmentFilter
            data={products}
            list={visibleProducts}
            setList={setVisibleProducts}
          />
          <ExportDepartments uid={uid} />
        </div>
        <div className={classNames(shared.tableHeader, style.header)}>
          <p>Lp.</p>
          <p>Nazwa /Jednostka</p>
          <p>Producent</p>
          <p>Ilość</p>
        </div>
        {visibleProducts.map((product, index) => (
          <ProductItem
            item={product}
            index={index}
            key={product.id}
          />
        ))}
      </Accordion>
    </section>
  );
};

export default OrderProductsDepartments;
