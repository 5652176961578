import React, { useEffect, useState } from 'react';

import { ColumnList, EmptyState, PaginationNav, SeoHelmet, StickyPageHeader, useRequestAbortController } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import pages from 'src/dictionaries/pages.json';
import query from 'src/utils/query';

import ApprovalThumbnail from './components/ApprovalThumbnail';

import style from './OrdersApprovals.module.scss';

const OrdersApprovals = (props) => {
  const params = query(props);
  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [abortController, setNewController] = useRequestAbortController();

  const getData = async () => {
    let error;
    const params = {
      page: currentPage || 1,
      perPage: perPage
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await AdminApi.getOrderApprovals(params, signal);
      setPageData(data);
      setPageQty(data?.last_page);
    } catch (err) {
      notifyApiError(err);
      error = err;
    } finally {
      setIsLoading(error?.message === 'canceled');
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage, perPage]);

  return (
    <div className={style.container}>
      <SeoHelmet title={pages.ordersApprovals.title} />
      <StickyPageHeader name={pages.ordersApprovals.title} />
      <ColumnList
        type={props.type}
        isLoading={isLoading}
        list={pageData?.data}
        component={ApprovalThumbnail}
        emptyState={<EmptyState type={'ordersApproval'} />}
      />
      <PaginationNav
        onClick={(number) => setCurrentPage(number)}
        params={params}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
      />
    </div>
  );
};

export default OrdersApprovals;
