import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, QtySelector, useStateTimeout } from 'components';

import { setFreeShippingPrice, setShippingPrice } from '../../../../actions';
import { useSupplierFormValidationForceUpdate } from '../../../../helpers';

import style from '../../OrderDetails.module.scss';
import shared from 'styles/Shared.module.scss';

const ShippingPrice = ({ disabled }) => {
  const dispatch = useDispatch();
  useSupplierFormValidationForceUpdate();

  const shipping_price = useSelector((store) => store.supplierForm.form.shipping_price);
  const free_shipping_price = useSelector((store) => store.supplierForm.form.free_shipping_price);
  const validator = useSelector((state) => state.supplierForm.validator);

  const [localShippingPrice, setLocalShippingPrice] = useState(shipping_price);
  const [shippingThreshold, setShippingThreshold] = useState(free_shipping_price);

  useStateTimeout({
    localState: localShippingPrice,
    setLocalState: setLocalShippingPrice,
    state: shipping_price,
    setState: (price) => dispatch(setShippingPrice(price)),
    timeoutValue: 200
  });

  useStateTimeout({
    localState: shippingThreshold,
    setLocalState: setShippingThreshold,
    state: free_shipping_price,
    setState: (price) => dispatch(setFreeShippingPrice(price)),
    timeoutValue: 200
  });

  return (
    <Card
      className={shared.defaultWrapper}
      id={'shipping-price'}
    >
      <div className={style.box}>
        <h2 className={style.title}>Koszt wysyłki</h2>
        <QtySelector
          id={'shipping-price-input'}
          name={'shipping-price-input'}
          state={localShippingPrice || ''}
          setState={setLocalShippingPrice}
          disabled={disabled}
          suffix={'zł'}
          width={100}
          hideArrows
          priceInput
          validator={validator}
          rule={'required|min_price'}
        />
      </div>
      <div className={style.box}>
        <h2 className={style.title}>Próg darmowej wysyłki</h2>
        <QtySelector
          id={'shipping-threshold-input'}
          name={'shipping-threshold-input'}
          state={shippingThreshold || ''}
          setState={setShippingThreshold}
          disabled={disabled}
          suffix={'zł'}
          width={100}
          hideArrows
          priceInput
          validator={validator}
          rule={'required|min_price'}
        />
      </div>
    </Card>
  );
};

export default ShippingPrice;
