import { ReactComponent as LogoAvalio } from 'assets/img/logo-avalio.svg';

import ContactInfo from '../../components/ContactInfo';
import ForgotPasswordForm from './components/ForgotPasswordForm';

import style from '../../onboarding.module.scss';

const ForgotPassword = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.logoContainer}>
          <LogoAvalio />
        </div>
        <ForgotPasswordForm />
        <ContactInfo />
      </div>
    </>
  );
};

export default ForgotPassword;
