import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Button, Loader } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';

import MonthItem from '../MonthItem';

import styles from './VolumesForm.module.scss';

const formatVolumes = (volumes) => {
  const ocrs = [];
  const orders = [];
  const values = [];
  let average = null;

  if (volumes.length > 0) {
    volumes.forEach((volume) => {
      if (volume[2] === 'order') {
        orders.push({
          date: volume[0],
          amount: volume[1],
          type: volume[2]
        });
      } else if (volume[2] === 'ocr') {
        ocrs.push({
          date: volume[0],
          amount: volume[1],
          type: volume[2]
        });
      }
      values.push(volume[1]);
    });

    const sum = values.reduce((acc, curr) => acc + curr, 0);
    average = Math.ceil((sum * 12) / volumes.length);
  }

  return { ocrs, orders, average };
};

const VolumesForm = ({ handleSave, companyId, productId, isSaveLoading, setVolumeState, children }) => {
  const [data, setData] = useState({
    ocr: [],
    order: [],
    average: null
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const params = {
        company_id: Number(companyId),
        product_id: productId
      };

      try {
        setIsLoading(true);
        const { data } = await OfferCatalogApi.getVolumesForProduct(params);
        const { ocrs, orders, average } = formatVolumes(data.product_month_volumes);

        setData({
          ocr: ocrs,
          order: orders,
          average: average
        });
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.column}>{children}</div>
      <div className={classNames(styles.column, styles.volumesFormColumn)}>
        <div
          className={classNames(styles.volumesFormWrapper, {
            [styles.loading]: isLoading || (data.ocr.length === 0 && data.order.length === 0)
          })}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {data.ocr.length > 0 || data.order.length > 0 ? (
                <>
                  <div className={styles.columnLabel}>Wolumen miesięczny</div>
                  <div>
                    <div className={styles.months}>
                      {data.ocr.length > 0 && (
                        <div className={styles.ocrGroup}>
                          <span className={styles.ocrGroupLabel}>Przed wdrożeniem</span>
                          {data.ocr.map((item) => (
                            <MonthItem
                              key={item.date}
                              {...item}
                            />
                          ))}
                        </div>
                      )}
                      {data.order.length > 0 &&
                        data.order.map((item) => (
                          <MonthItem
                            key={item.date}
                            {...item}
                          />
                        ))}
                    </div>
                  </div>
                </>
              ) : (
                <div>Brak danych o wolumenach miesięcznych.</div>
              )}
            </>
          )}
        </div>
        {data.average !== null && (
          <Button
            label={`Przypisz średni wolumen roczny (${data.average})`}
            disabled={data.average === 0}
            onClick={() => {
              setVolumeState(data.average);
              handleSave(data.average);
            }}
            isLoading={isSaveLoading}
          />
        )}
      </div>
    </div>
  );
};

export default VolumesForm;
