import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Button, InviteThumbnail, Loader, MemberThumbnail, Modal, Tag } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';

import AddUserToCompany from './components/AddUserToCompany';
import InviteUserToCompany from './components/InviteUserToCompany';

import style from './Personnel.module.scss';
import shared from 'styles/Shared.module.scss';

const Personnel = ({ companyId }) => {
  const [company, setCompany] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [contentType, setContentType] = useState('');

  const getCompanyDetails = async () => {
    const queryParams = {
      company_id: companyId
    };

    try {
      const { data } = await AdminApi.getCompanyMembers(queryParams);
      setCompany(data);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    const handleLoadInitialData = async () => {
      setIsLoading(true);
      await getCompanyDetails();
      setIsLoading(false);
    };

    handleLoadInitialData();
  }, []);

  const handleCloseModal = () => {
    getCompanyDetails();
    setIsVisible(false);
  };

  const openModalHandler = (type) => {
    setContentType(type);
    setIsVisible(true);
  };

  const modalContent = useMemo(() => {
    switch (contentType) {
      case 'INVITE':
        return {
          title: `Zaproś nowego użytkownika do oddziału: ${company.name}`,
          content: (
            <InviteUserToCompany
              companyId={companyId}
              onSuccess={handleCloseModal}
            />
          )
        };
      case 'ADD':
        return {
          title: `Dodaj użytkownika do ${company?.name}`,
          content: (
            <AddUserToCompany
              companyId={companyId}
              onSuccess={handleCloseModal}
            />
          )
        };
      default:
        return {
          content: null,
          title: ''
        };
    }
  }, [contentType]);

  const modalBody = (
    <Modal
      visible={isVisible}
      onClose={handleCloseModal}
      title={modalContent.title}
    >
      {modalContent.content}
    </Modal>
  );

  if (isLoading) {
    return <Loader center />;
  }

  return (
    <div className={style.container}>
      <section className={classNames(shared.tableWrapper, style.wrapper)}>
        <div className={classNames(shared.tableHeader, style.header)}>
          <h2>Personel oddziału</h2>
          <Button
            label={'Dodaj użytkownika'}
            onClick={() => openModalHandler('ADD')}
          />
          {modalBody}
        </div>
        <div className={style.thumbnailsWrapper}>
          {!!company?.owner && (
            <MemberThumbnail
              member={company.owner}
              isOwner
              companyId={companyId}
            />
          )}
          {company?.members?.map((member) => (
            <MemberThumbnail
              companyId={companyId}
              member={member}
              key={member.name}
              refreshCallback={getCompanyDetails}
            />
          ))}
        </div>
      </section>
      <section className={shared.tableWrapper}>
        <div className={classNames(shared.tableHeader, style.header)}>
          <h2>Oczekujące zaproszenia</h2>
          <Button
            label={'Zaproś użytkownika'}
            onClick={() => openModalHandler('INVITE')}
          />
          {modalBody}
        </div>
        {company?.invitations?.length > 0 ? (
          company?.invitations?.map((invite) => (
            <InviteThumbnail
              companyId={companyId}
              invite={invite}
              key={invite.name}
              refreshCallback={getCompanyDetails}
            />
          ))
        ) : (
          <div className={style.emptyState}>
            <Tag value={'Brak zaproszeń'} />
          </div>
        )}
      </section>
    </div>
  );
};

export default Personnel;
