import React, { useEffect } from 'react';

const SAVE_INPUT_TIMEOUT = 800;
const UseStateTimeout = ({ localState, setLocalState, state, setState, timeoutValue = SAVE_INPUT_TIMEOUT }) => {
  useEffect(() => {
    const timeout = setTimeout(() => setState(localState), timeoutValue);
    return () => clearTimeout(timeout);
  }, [localState]);

  useEffect(() => {
    setLocalState(state);
  }, [state]);

  return null;
};

export default UseStateTimeout;
