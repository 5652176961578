import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { Button, DateRangePicker, PaginationNav, useIsAdmin, useRequestAbortController } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';
import { EXCEL_FILE_TYPE } from 'src/constants/misc';
import { downloadFile, getDecodeBase64File } from 'src/utils/helpers';

import ChangedPricesProductsListTable from './components/ChangedPricesProductsListTable';

import styles from './ChangedPricesProductsList.module.scss';

const ChangedPricesProductsList = () => {
  const isAdmin = useIsAdmin();
  const selectedCompany = useSelector((state) => state.cartAndCatalog.selectedCompany);

  const [pageData, setPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
  const [isDownloadingXLSX, setIsDownloadingXLSX] = useState(false);

  const [abortController, setNewController] = useRequestAbortController();

  const getData = async () => {
    if (abortController) abortController.abort();
    const signal = setNewController();

    const params = {
      page: currentPage,
      perPage: perPage,
      ...(isAdmin && { company_id: selectedCompany }),
      ...(startDate && endDate && { date_from: dayjs(startDate).tz().format(), date_to: dayjs(endDate).tz().format() })
    };

    try {
      setIsLoading(true);
      const { data } = await OfferCatalogApi.getCatalogPricesHistory(params, signal);
      setPageQty(data.last_page);
      setPageData(data.data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const setProperLoading = (type, value) => {
    if (type === 'CSV') {
      setIsDownloadingCSV(value);
    } else if (type === 'XLSX') {
      setIsDownloadingXLSX(value);
    }
  };

  const downloadFileHandler = async (type) => {
    const date = dayjs().format('DD.MM.YYYY');

    try {
      setProperLoading(type, true);
      const { data } = await OfferCatalogApi.exportCatalogPricesHistory({
        ...(isAdmin && { company_id: selectedCompany }),
        ...(startDate && endDate && { date_from: dayjs(startDate).tz().format(), date_to: dayjs(endDate).tz().format() }),
        file_format: type
      });

      if (type === 'CSV') {
        const blob = new Blob([data], { type: 'text/csv' });
        downloadFile(blob, 'katalog_produkty_ceny_export_' + date + '.csv');
      } else {
        const blob = getDecodeBase64File(data, EXCEL_FILE_TYPE);
        downloadFile(blob, 'katalog_produkty_ceny_export_' + date + '.xlsx');
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setProperLoading(type, false);
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage, perPage, startDate, endDate]);

  return (
    <div className={styles.container}>
      <div className={styles.actions}>
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <div className={styles.buttonsWrapper}>
          <Button
            label={'Pobierz listę (.csv)'}
            isLoading={isDownloadingCSV}
            onClick={() => downloadFileHandler('CSV')}
          />
          <Button
            label={'Pobierz listę Excel (.xlsx)'}
            isLoading={isDownloadingXLSX}
            onClick={() => downloadFileHandler('XLSX')}
          />
        </div>
      </div>

      <ChangedPricesProductsListTable
        list={pageData}
        isLoading={isLoading}
      />
      <PaginationNav
        setPage={setCurrentPage}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
        page={currentPage}
        className={styles.pagination}
        hideSelect
      />
    </div>
  );
};

export default ChangedPricesProductsList;
