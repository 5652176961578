import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import classNames from 'classnames';

import { Loader, useIsAdmin, useIsMobile } from 'components';
import InputWrapper, { getWrapperProps } from 'components/layout/InputWrapper';
import { selectStyles } from 'components/layout/Select/styles';

import { getAllCategories } from 'src/utils/helpers';

import style from './AsyncSelect.module.scss';

const AsyncSelect = (props) => {
  const isMobile = useIsMobile();
  const isAdmin = useIsAdmin();

  const [localLoading, setLocalLoading] = useState(false);
  const {
    value,
    onChange,
    noOptionsMessage = 'Brak wyników',
    noOptionCallback,
    perPage = 30,
    valueKey,
    labelKey,
    emailKey = 'email',
    apiCallback,
    setTotal,
    labelFormat,
    className,
    queryParams,
    showAsTree,
    isOptionDisabled,
    isMulti,
    defaultFirstOption,
    isSearchable = true,
    productSearch = false,
    isDisabled,
    isTiny,
    customRadius,
    highlightUsedInReplacementsGroup,
    highlightUsedInVariationsGroup,
    excludeOptionById,
    replacementsArray = [],
    ...rest
  } = props;

  const wrapperProps = getWrapperProps(props);

  const getFormattedOptions = (data) =>
    data.map((item) => ({
      value: item[valueKey],
      email: item[emailKey],
      label: labelFormat ? labelFormat(item) : item[labelKey],
      ...item
    }));

  const loadOptions = async (search = '', prevOptions, { page }) => {
    if (search && queryParams && 'exclude_product_ids' in queryParams) {
      delete queryParams.exclude_product_ids;
    }

    const params = {
      page,
      perPage,
      ...queryParams,
      ...(!!search && { search })
    };
    setLocalLoading(true);
    const response = await apiCallback(params);
    if (setTotal) setTotal(response?.data?.total);
    setLocalLoading(false);

    const options = showAsTree
      ? getAllCategories(response.data.data || response.data)
      : getFormattedOptions(response.data.data || response.data);

    if (page === 1 && defaultFirstOption) {
      options.unshift(defaultFirstOption);
    }

    if (page === 1 && !search && replacementsArray.length > 0) {
      const formattedReplacements = getFormattedOptions(replacementsArray);
      formattedReplacements.reverse().forEach((replacement) => {
        options.unshift(replacement);
      });
    }

    return {
      options: excludeOptionById ? options.filter((option) => option.id !== excludeOptionById) : options,
      hasMore: options.length + options.length < response.data.total,
      additional: {
        page: page + 1
      }
    };
  };

  const handleNoOptionsMessage = ({ inputValue }) => {
    if (noOptionCallback) {
      return localLoading ? <Loader prefix={'Ładowanie...'} /> : noOptionCallback(inputValue);
    }

    return localLoading ? <Loader prefix={'Ładowanie...'} /> : noOptionsMessage;
  };

  const loadingMessage = () => <Loader prefix={'Ładowanie...'} />;

  useEffect(() => {
    if (setTotal) {
      loadOptions(undefined, [], { page: 1 });
    }
  }, [setTotal]);

  return (
    <InputWrapper {...wrapperProps}>
      <AsyncPaginate
        value={value}
        loadOptions={loadOptions}
        isLoading={!isDisabled && localLoading}
        onChange={onChange}
        styles={selectStyles({
          hasError: !!wrapperProps.errorMessage,
          isMulti,
          isAdmin,
          highlightUsedInReplacementsGroup,
          highlightUsedInVariationsGroup,
          isSearchable,
          productSearch,
          customRadius,
          isDisabled,
          isTiny
        })}
        loadingMessage={loadingMessage}
        noOptionsMessage={handleNoOptionsMessage}
        isOptionDisabled={isOptionDisabled}
        additional={{ page: 1 }}
        cacheUniqs={[queryParams]}
        className={classNames(style.input, className, {
          [style.mobile]: isMobile
        })}
        isMulti={isMulti}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        {...rest}
      />
    </InputWrapper>
  );
};

export default AsyncSelect;
