import React, { useState } from 'react';

import { Button, Input, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AccountsApi } from 'src/api';

import style from './ChangePassword.module.scss';

const ChangePassword = () => {
  const validator = useValidator();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();

    const data = {
      old_password: oldPassword,
      new_password: newPassword
    };

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      await AccountsApi.changePassword(data);
      notifyCommon(['Hasło zostało zmienione.']);
      setNewPassword('');
      setOldPassword('');
      setIsVisible(false);
      validator.purgeFields();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isVisible) {
    return (
      <div className={style.container}>
        <Button
          label={'Zmiana hasła'}
          onClick={() => setIsVisible(true)}
        />
      </div>
    );
  }

  return (
    <form
      className={style.container}
      onSubmit={submitHandler}
    >
      <Input
        id='password'
        name='password'
        label='Aktualne hasło'
        type='password'
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <Input
        id='new-password'
        name='new-password'
        label='Nowe hasło'
        type='password'
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <Input
        id='repeatPassword'
        name='repeatPassword'
        label='Powtórz nowe hasło'
        type='password'
        value={secondPassword}
        onChange={(e) => setSecondPassword(e.target.value)}
        validator={validator}
        rule={`required|min_password|in:${newPassword}`}
      />
      <Button
        label={'Zmień hasło'}
        type={'submit'}
        isLoading={isLoading}
        className={style.button}
      />
    </form>
  );
};

export default ChangePassword;
