import { useEffect, useState } from 'react';

import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';

const useRecentlyOrderedProducts = (UID) => {
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const { data } = await ListsApi.getRecentlyOrderedProducts(UID);
      setData(data);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    if (UID) {
      getData();
    }
  }, [UID]);

  return data;
};

export default useRecentlyOrderedProducts;
