import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, FileDropzone, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';

import { refreshList } from '../../../../actions';
import ImportNewProductsForm from './components/ImportNewProductsForm';

import style from '../../ExportImportForm.module.scss';

const Import = () => {
  const dispatch = useDispatch();
  const validator = useValidator();
  const [flatFile, setFlatFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const formData = new FormData();
    formData.append('products', flatFile);

    try {
      setIsLoading(true);
      await ProductsApi.importEditedProducts(formData);
      notifyCommon(['Produkty zostały zaimportowane'], { title: 'Sukces!' });
      validator.hideMessages();
      setFlatFile(null);
      dispatch(refreshList());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form
        className={style.container}
        onSubmit={submitHandler}
      >
        <header className={style.header}>
          <h3>Import pliku z bazą produktów</h3>
          <p>Wybierz plik i zapisz, aby zaktualizować bazę.</p>
        </header>
        <FileDropzone
          label={'Szablon z wprowadzonymi cenami'}
          onChange={setFlatFile}
          value={flatFile}
          validator={validator}
          rule={'required'}
        />
        <Button
          label={'Zaimportuj produkty'}
          type={'submit'}
          className={style.button}
          isLoading={isLoading}
        />
      </form>
      <ImportNewProductsForm />
    </>
  );
};

export default Import;
