import { ReactComponent as LogoAvalio } from 'assets/img/logo-avalio.svg';

import ContactInfo from '../../components/ContactInfo';
import ResetPasswordForm from './components/ResetPasswordForm';

import style from '../../onboarding.module.scss';

const ResetPassword = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.logoContainer}>
          <LogoAvalio />
        </div>
        <ResetPasswordForm />
        <ContactInfo />
      </div>
    </>
  );
};

export default ResetPassword;
