export const SET_TOKENS = 'SET_TOKENS';

export const LOGOUT = 'LOGOUT';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const REGISTER_NEW_MEMBER_REQUEST = 'REGISTER_NEW_MEMBER_REQUEST';
export const REGISTER_NEW_MEMBER_SUCCESS = 'REGISTER_NEW_MEMBER_SUCCESS';
export const REGISTER_NEW_MEMBER_FAILURE = 'REGISTER_NEW_MEMBER_FAILURE';

export const GET_USER_DATA_REQUEST = 'GET_USER_DATA_REQUEST';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';

export const REFRESH_USER_DATA_REQUEST = 'REFRESH_USER_DATA_REQUEST';
export const REFRESH_USER_DATA_SUCCESS = 'REFRESH_USER_DATA_SUCCESS';
export const REFRESH_USER_DATA_FAILURE = 'REFRESH_USER_DATA_FAILURE';

export const SUPPLIER_REGISTER_REQUEST = 'SUPPLIER_REGISTER_REQUEST';
export const SUPPLIER_REGISTER_SUCCESS = 'SUPPLIER_REGISTER_SUCCESS';
export const SUPPLIER_REGISTER_FAILURE = 'SUPPLIER_REGISTER_FAILURE';

export const CHANGE_CURRENT_COMPANY = 'CHANGE_CURRENT_COMPANY';
export const LOAD_USER_TOKEN = 'LOAD_USER_TOKEN';
