import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import { Loader } from 'semantic-ui-react';

import { Accordion, EmptyState, useIsMobile } from 'components';

import Thumbnail from './components/Thumbnail';

import style from './TableWithPagination.module.scss';
import shared from 'styles/Shared.module.scss';

const PER_PAGE = 10;

const TableWithPagination = ({ id, apiCallback, title, canLoad, asOrder }) => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const isTabletWidth = useIsMobile(768);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const getData = async (page) => {
    const params = {
      page: page || 1,
      perPage: PER_PAGE
    };

    try {
      setIsLoading(true);
      const { data } = await apiCallback(id, params);
      setTotal(data.total);
      setItems((prev) => [...prev, ...data.data]);
      if (data.data.length === 0) setHasMore(data.data.length < data.total);
      else setHasMore(data.data.length + items.length < data.total);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onLoadMore = () => {
    getData(page + 1);
    setPage(page + 1);
  };

  useEffect(() => {
    if (canLoad) getData(1);
  }, [canLoad]);

  const firstTitle = (
    <h3 className={classNames(shared.tableCategoryTitle, shared.large, shared.noPadding)}>
      {title}{' '}
      {isLoading ? (
        <Skeleton
          width={30}
          height={20}
        />
      ) : (
        `(${total})`
      )}
    </h3>
  );

  return (
    <div className={style.container}>
      <Accordion
        section
        title={firstTitle}
        extraDeps={[items, hasMore, isLoading]}
        defaultWrapped
      >
        {items.length === 0 ? (
          <EmptyState
            type={'prices'}
            tiny
          />
        ) : (
          <>
            <div className={classNames(shared.tableHeader, style.header)}>
              <p>Numer</p>
              <p>Dostawca</p>
              <p>Cena</p>
              {!isTabletWidth && (
                <>
                  <p>Ilość</p>
                  <p>Wartość</p>
                  <p>Data</p>
                </>
              )}
            </div>
            {items.map((item, i) => (
              <Thumbnail
                item={item}
                asOrder={asOrder}
                key={`${item.id}-${i}`}
              />
            ))}
            {isLoading ? (
              <Loader />
            ) : (
              hasMore && (
                <a
                  className={style.loadMore}
                  onClick={onLoadMore}
                >
                  Pokaż więcej
                </a>
              )
            )}
          </>
        )}
      </Accordion>
    </div>
  );
};

export default TableWithPagination;
