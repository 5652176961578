import React from 'react';

import { Tag } from 'components';
import ThumbnailLists from 'components/layout/ThumbnailLists';

import shared from 'styles/Shared.module.scss';

const CategoriesList = ({ categories = [], uid }) => {
  const renderElement = (tag) => (
    <Tag
      key={tag.id + uid}
      value={tag.label}
      className={shared.thumbnailTag}
    />
  );

  return (
    <ThumbnailLists
      renderElement={renderElement}
      label={'Kategorie:'}
      items={categories}
      statusesList={[]}
      uid={uid}
    />
  );
};

export default CategoriesList;
