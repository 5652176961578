import React from 'react';

import { OrderOrValuationId } from 'components';

import { toDate, toTime } from 'src/utils/dateTime';
import { getValuationStatusTag } from 'src/utils/helpers';

import style from './SingleValuation.module.scss';

const SingleValuation = ({ valuation }) => {
  const { public_id, supplier_name, created_at, expire_date, status, product_count } = valuation || {};

  //  TODO link to user valuation on click

  return (
    <div className={style.container}>
      <div className={style.wrapper}>{getValuationStatusTag(status)}</div>
      <div className={style.wrapper}>
        <OrderOrValuationId id={public_id} />
      </div>
      <div className={style.wrapper}>
        <p>{supplier_name}</p>
      </div>
      <div className={style.wrapper}>
        <p>{product_count}</p>
      </div>
      <div className={style.wrapper}>
        <p>{created_at ? `${toDate(created_at)}, ${toTime(created_at)}` : '-'}</p>
      </div>
      <div className={style.wrapper}>
        <p>{expire_date ? `${toDate(expire_date)}, ${toTime(expire_date)}` : '-'}</p>
      </div>
    </div>
  );
};

export default SingleValuation;
