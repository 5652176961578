import React, { useEffect, useState } from 'react';

import { AsyncSelect, Button, Card, Input, OptionSwitch, Textarea, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { VariationsApi } from 'src/api';

import styles from './ProductVariationsCreateOrAddToGroupFormForm.module.scss';

const ProductVariationsCreateOrAddToGroupFormForm = ({ productId, refreshCallback }) => {
  const [isAddNewGroup, setIsAddNewGroup] = useState(true);
  const [group, setGroup] = useState({ name: '', description: '', product_ids: [productId] });
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const validator = useValidator();

  const onChange = (e) => {
    setGroup((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const onChangeHandler = (name, data) => {
    setGroup((prev) => ({
      ...prev,
      [name]: data
    }));
  };

  const addGroupHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      product_ids: group.product_ids,
      name: group.name,
      ...(group.description && { description: group.description })
    };

    try {
      setIsLoading(true);
      const { data } = await VariationsApi.addGroup(params);
      if (refreshCallback) refreshCallback(true);
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const addProductToGroupHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      group_id: selectedGroup.id,
      product_ids: [productId]
    };

    try {
      setIsLoading(true);
      const { data } = await VariationsApi.addGroupProducts(params);
      if (refreshCallback) refreshCallback(true);
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    validator.fields = {};
    validator.hideMessages();
  }, [isAddNewGroup]);

  return (
    <form className={styles.container}>
      <OptionSwitch
        defaultOption={isAddNewGroup}
        onChange={setIsAddNewGroup}
        falseLabel={'Dodaj do istniejącej grupy'}
        trueLabel={'Stwórz nową grupę'}
      />
      {isAddNewGroup ? (
        <>
          <Card info>Stwórz grupę wariantów zawierającą aktualny produkt.</Card>
          <Input
            id='name'
            name='name'
            label='Nazwa grupy'
            value={group.name}
            onChange={onChange}
            validator={validator}
            rule={'required'}
          />
          <Textarea
            id={'description'}
            name={'description'}
            label={'Opis grupy'}
            value={group.description || ''}
            setValue={(description) => onChangeHandler('description', description)}
          />
        </>
      ) : (
        <>
          <Card info>Wybierz istniejącą grupę, do której chcesz przypisać aktualny produkt.</Card>
          <AsyncSelect
            id={'selected-groups'}
            value={selectedGroup}
            onChange={setSelectedGroup}
            apiCallback={VariationsApi.getGroups}
            label={'Wybierz grupy'}
            valueKey={'id'}
            labelKey={'name'}
            placeholder={'Wyszukaj..'}
            validator={validator}
            rule={'required'}
            isClearable
          />
        </>
      )}
      <Button
        label={isAddNewGroup ? 'Utwórz grupę' : 'Przypisz'}
        isLoading={isLoading}
        onClick={isAddNewGroup ? addGroupHandler : addProductToGroupHandler}
      />
    </form>
  );
};

export default ProductVariationsCreateOrAddToGroupFormForm;
