import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { useIsUserAuth, useLocalStorage } from 'components';

import { clearNotificationsStore } from 'src/features/NotificationsStore/actions';
import { removeSessionStorageItem, setSessionStorageItem } from 'src/utils/storage';

import { logout } from '../../actions';

const Logout = () => {
  const isAuth = useIsUserAuth();
  const dispatch = useDispatch();
  const [_, setRememberMe] = useLocalStorage('rememberMe', false);

  useEffect(() => {
    setRememberMe(false);
    dispatch(logout());
    dispatch(clearNotificationsStore());
    setSessionStorageItem('isAdminView', false);
    removeSessionStorageItem('adminId');

    if (window.pusher) {
      window.pusher.disconnect();
      window.pusher = undefined;
      console.log('SocketClient disconnected');
    }
  }, []);

  if (!isAuth) {
    return <Redirect to={'/'} />;
  }

  return null;
};

export default Logout;
