import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { AsyncSelect, Button } from 'components';
import { notifyApiError, notifyCommon, notifyDanger } from 'components/layout/Toasts';

import { CompanyApi, UserApi } from 'src/api';
import { handleNumeralWords } from 'src/utils/helpers';

import { refreshProducts } from '../../actions';

import style from './ChangeManyAtOnce.module.scss';

const ChangeManyAtOnce = ({ selectedProducts, setSelectedProducts }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const handleSubmit = async (e, type = 'add') => {
    e.preventDefault();

    const apiRequest = type === 'add' ? CompanyApi.addSupplierToProduct : CompanyApi.removeSupplierFromProduct;

    if (selectedProducts.length === 0) return notifyDanger(['Wybierz produkty do których chcesz przypisać dostawcę.']);
    if (!selectedSupplier) return notifyDanger(['Wybierz dostawcę, którego chcesz przypisać do produktów.']);

    const data = {
      product_ids: selectedProducts.map((item) => item?.id),
      supplier_id: selectedSupplier?.value
    };

    try {
      setIsLoading(true);
      await apiRequest(data);
      notifyCommon([
        `Dostawca ${selectedSupplier?.label} został ${type === 'add' ? 'przypisany do' : 'usunięty z'} wybranych produktów. (${
          selectedProducts.length || 0
        })`
      ]);
      setSelectedProducts([]);
      dispatch(refreshProducts());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form
        className={style.header}
        onSubmit={handleSubmit}
      >
        <div className={style.titles}>
          <h3 className={style.title}>Wybierz dostawcę, którym chcesz zarządzać</h3>
          <p className={style.title}>
            Wybrano {selectedProducts.length} {handleNumeralWords(['produkt', 'produkty', 'produktów'], selectedProducts.length)}.
          </p>
        </div>
        <div className={style.wrapper}>
          <AsyncSelect
            value={selectedSupplier}
            onChange={setSelectedSupplier}
            apiCallback={UserApi.getDistributors}
            valueKey={'id'}
            labelKey={'name'}
            placeholder={'Dostawca'}
            // label={'Wybierz dostawcę, którego chcesz przypisać do wybranych produktów:'}
            isClearable
            showAsTree={false}
            queryParams={{ pagination: 1 }}
          />
          <Button
            label='Usuń wybranego dostawcę'
            onClick={(e) => handleSubmit(e, 'delete')}
            gray
          />
          <Button
            label='Przypisz produkty'
            onClick={(e) => handleSubmit(e, 'add')}
          />
        </div>
      </form>
    </>
  );
};

export default ChangeManyAtOnce;
