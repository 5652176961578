import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { AsyncSelect } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';
import { formatProducerValue } from 'src/utils/helpers';

import style from '../Filtering.module.scss';

const FilterByProducer = ({ params, fullWidth, valueKey = 'id', labelKey = 'producer_name', label, queryParams }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const paramValue = params.get('producer');

  useEffect(() => {
    if (selectedCategory?.value !== paramValue && params.get('page')) {
      params.setFew([
        { key: 'producer', value: selectedCategory?.value },
        { key: 'page', value: '1' }
      ]);
    } else if (selectedCategory?.value !== paramValue) {
      params.set('producer', selectedCategory?.value);
    }
  }, [selectedCategory]);

  useEffect(() => {
    const getSelectedElement = async () => {
      const queryParams = {
        producer_id: paramValue,
        perPage: 1,
        page: 1
      };

      try {
        setIsLoading(true);
        const { data } = await ProductsApi.getProducers(queryParams);
        setSelectedCategory(formatProducerValue(data.data[0]));
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (paramValue) getSelectedElement();
  }, []);

  return (
    <AsyncSelect
      value={selectedCategory}
      onChange={setSelectedCategory}
      apiCallback={ProductsApi.getProducers}
      valueKey={valueKey}
      labelKey={labelKey}
      placeholder={'Producent'}
      label={label}
      isClearable
      queryParams={{ pagination: 1, ...queryParams }}
      wrapperStyle={classNames(style.select, {
        [style.selected]: !!selectedCategory,
        [style.fullWidth]: fullWidth
      })}
    />
  );
};

export default FilterByProducer;
