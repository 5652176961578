import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Card } from 'components';

import { PrivateRoute } from 'src/app/AppRouter/routes';
import { userPermissions, userRoles } from 'src/constants/enums';
import Warehouse from 'src/features/Profile/features/Warehouse';
import { ordersApprovalEnabled } from 'src/utils/companyAccessFunctions';

//Features
import Account from '../../features/Account';
import Addresses from '../../features/Addresses';
import CompaniesManagement from '../../features/CompaniesManagement';
import Company from '../../features/Company';
import MembersManagement from '../../features/MembersManagement';
import NotificationsCenter from '../../features/NotificationsCenter';
import OrdersApproval from '../../features/OrdersApproval';
import TopSection from '../TopSection';

import style from './Router.module.scss';

const Router = () => {
  const { company } = userPermissions;
  const { user, admin, distributor, supplier } = userRoles;

  return (
    <Card className={style.container}>
      <TopSection />
      <Switch>
        <PrivateRoute
          nestedRoute
          exact
          path={'/profile'}
          roles={[user, admin, supplier, distributor]}
          component={Account}
        />
        <PrivateRoute
          nestedRoute
          exact
          path={'/profile/address'}
          roles={[user, admin]}
          component={Addresses}
        />
        <PrivateRoute
          nestedRoute
          exact
          path={'/profile/company'}
          roles={[user, admin]}
          component={() => <Company inProfile />}
        />
        <PrivateRoute
          nestedRoute
          exact
          path={'/profile/members-management'}
          roles={[user, admin]}
          requiredPermissions={[company.admin]}
          component={MembersManagement}
        />
        <PrivateRoute
          nestedRoute
          exact
          path={'/profile/orders-approval'}
          roles={[user, admin]}
          requiredPermissions={[company.admin]}
          component={OrdersApproval}
          companyAccessFn={ordersApprovalEnabled}
        />
        <PrivateRoute
          nestedRoute
          exact
          path={'/profile/notifications'}
          roles={[user, admin, supplier]}
          component={NotificationsCenter}
        />
        <PrivateRoute
          nestedRoute
          exact
          path={'/profile/companies-management'}
          roles={[user, admin]}
          requiredPermissions={[company.admin]}
          component={CompaniesManagement}
        />
        <PrivateRoute
          nestedRoute
          exact
          path={'/profile/warehouse'}
          roles={[user]}
          requiredPermissions={[company.admin]}
          component={Warehouse}
        />
        <Route render={() => <Redirect to='/404' />} />
      </Switch>
    </Card>
  );
};

export default Router;
