import React from 'react';
import cn from 'classnames';

import { useIsMobile } from 'components';

import { toDateNumber, toTime } from 'src/utils/dateTime';
import { getOrderStatusTag, handleNumeralWords } from 'src/utils/helpers';

import style from './OrderHeader.module.scss';

const OrderHeader = (props) => {
  const { supplier, createdAt, children, withoutBorder, extraData = [] } = props;
  const isMobile = useIsMobile();

  return (
    <div className={cn(style.orderHeader, { [style.withoutBorder]: withoutBorder })}>
      {isMobile && getOrderStatusTag({ status: supplier.status, className: style.tag })}
      <div className={style.supplierName}>
        <h2 className={style.name}>{supplier.name}</h2>
        <p
          className={style.productsNumber}
          title={`Liczba produktów w zamówieniu: ${supplier.products?.length}`}
        >
          ({supplier.products?.length}&nbsp;
          {handleNumeralWords(['produkt', 'produkty', 'produktów'], supplier.products?.length)})
        </p>
        {extraData.map((data) => (
          <>
            <p className={style.productsNumber}>|</p>
            <p className={style.productsNumber}>{data}</p>
          </>
        ))}
        {createdAt && (
          <>
            <p className={style.productsNumber}>|</p>
            <p className={style.productsNumber}>
              {toDateNumber(createdAt)}, {toTime(createdAt)}
            </p>
          </>
        )}
      </div>
      <div className={style.topStatus}>{children}</div>
    </div>
  );
};

export default OrderHeader;
