import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { Accordion, DateRangePicker, EmptyState, useCompany } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';

import styles from './ProductVolumesHistory.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductVolumesHistory = ({ title, id, canLoad }) => {
  const company = useCompany();
  const [data, setData] = useState([]);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const titleElement = (
    <h3 className={classNames(shared.tableCategoryTitle, shared.large, shared.noPadding)}>
      {title}{' '}
      {isLoading ? (
        <Skeleton
          width={30}
          height={20}
        />
      ) : (
        `(${data.length})`
      )}
    </h3>
  );

  useEffect(() => {
    const getData = async () => {
      const params = {
        company_id: company.id,
        product_id: id,
        ...(startDate && endDate && { date_from: dayjs(startDate).tz().format(), date_to: dayjs(endDate).tz().format() })
      };

      try {
        setIsLoading(true);
        const { data } = await OfferCatalogApi.getVolumesHistoryForProduct(params);
        setData(data.product_month_volumes);
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (canLoad) getData();
  }, [canLoad, startDate, endDate]);

  return (
    <Accordion
      section
      title={titleElement}
      className={classNames(shared.tableCategory)}
      extraDeps={[data]}
      defaultWrapped
    >
      <div className={styles.filtersContainer}>
        <strong>{startDate && endDate ? 'Wybrany zakres' : 'Ostatnie 12 miesięcy'}</strong>
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </div>
      {data.length > 0 && (
        <div className={classNames(shared.tableHeader, styles.header)}>
          <p>Miesiąc</p>
          <p>Wolumen</p>
        </div>
      )}
      <div className={classNames(shared.tableCategory, styles.table)}>
        {data.length ? (
          data.map((volume) => (
            <div
              key={volume[0]}
              className={styles.volumeThumbnail}
            >
              <div>{volume[0]}</div>
              <div>{volume[1] !== 0 ? volume[1] : '-'}</div>
            </div>
          ))
        ) : (
          <EmptyState
            type={'volumes'}
            tiny
          />
        )}
      </div>
    </Accordion>
  );
};

export default ProductVolumesHistory;
