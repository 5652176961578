import React, { useEffect, useMemo, useState } from 'react';

import { EmptyState, usePermissions } from 'components';
import ThumbnailPlaceholderSmall from 'components/layout/ThumbnailPlaceholderSmall';
import { notifyApiError } from 'components/layout/Toasts';

import { OrdersApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import Section from 'src/features/Dashboard/components/Section';
import OrderItem from 'src/features/Orders/components/OrderThumbnail';

import style from './LastOrders.module.scss';

const DASHBOARD_ELEMENT_ID = 1;

const LastOrders = ({ config }) => {
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [canReadOrders] = usePermissions([userPermissions.orders.read]);
  const canBeDisplayed = useMemo(
    () => canReadOrders && config?.elements?.includes(DASHBOARD_ELEMENT_ID),
    [canReadOrders, config]
  );

  const getData = async () => {
    setIsLoading(true);
    const params = {
      page: 1,
      perPage: 3,
      hide: true
    };

    try {
      const { data } = await OrdersApi.getOrders(params);
      setPageData(data.data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (canBeDisplayed) getData();
  }, [canBeDisplayed]);

  if (!canBeDisplayed) {
    return null;
  }

  return (
    <Section
      title={'Ostatnie zamówienia'}
      link={'/orders'}
      id={'last-orders'}
    >
      {pageData?.length > 0 ? (
        <div className={style.container}>
          {pageData.map((item) => (
            <OrderItem
              key={item.id}
              item={item}
              small
            />
          ))}
        </div>
      ) : isLoading ? (
        <ThumbnailPlaceholderSmall
          quantity={2}
          small
        />
      ) : (
        <div className={style.wrapper}>
          <EmptyState type={'orders'} />
        </div>
      )}
    </Section>
  );
};

export default LastOrders;
