import React from 'react';

import { FilterByCategory, FilterByProducer, FilterByUser } from 'components';

const Filtering = ({ params }) => {
  const queryParams = { filter_by: 'SUPPLIER_PRODUCTS' };
  return (
    <>
      <FilterByProducer
        fullWidth
        params={params}
        queryParams={queryParams}
      />
      <FilterByCategory
        fullWidth
        params={params}
        queryParams={queryParams}
      />
      <FilterByUser
        fullWidth
        params={params}
      />
    </>
  );
};

export default Filtering;
