import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { SaveButton, useCompany } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ValuationsApi } from 'src/api';
import { refreshValuations } from 'src/features/ValuationView/actions';

import OfferWrapper from '../../../../../../../CatalogAndCartController/components/OfferWrapper';
import ProductInfo from '../../../ProductInfo';

import styles from '../../../../ValuationReplacements.module.scss';

const ReplacementsTableThumbnail = ({ replacement, pricingProdId }) => {
  const dispatch = useDispatch();
  const company = useCompany();

  const { name, producer, qty: quantity, unit_name, thumbnail, prices, replacement_product_id, offers_added } = replacement;
  const product = { name, producer, quantity, unit_name, thumbnail };

  const [isLoading, setIsLoading] = useState(false);

  const handleAdd = async () => {
    const params = {
      pricing_prod_id: pricingProdId,
      company_id: company.id,
      replacement_product_id
    };

    try {
      setIsLoading(true);
      const { data } = await ValuationsApi.addPrices(params);
      dispatch(refreshValuations());
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.table_item_replacement}>
      <div className={styles.table_item_replacement_product}>
        <ProductInfo product={product} />
      </div>
      <div className={styles.table_item_replacement_offers}>
        {prices.map((price) => {
          return (
            <div
              key={price.supplier_id}
              className={styles.table_item_replacement_offer}
            >
              <OfferWrapper
                item={price}
                isCatalogView={true}
                isReplacementOffer
              />
            </div>
          );
        })}
      </div>
      <div className={styles.table_item_replacement_button}>
        <SaveButton
          type={offers_added === 1 ? 'save' : 'add'}
          onClick={handleAdd}
          isLoading={isLoading}
          disabled={offers_added === 1}
        />
      </div>
    </div>
  );
};

export default ReplacementsTableThumbnail;
