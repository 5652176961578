import React from 'react';
import classNames from 'classnames';

import { ColumnSortButton, EmptyState } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import WholesalerThumbnail from '../WholesalerThumbnail';

import style from './WholesalersTable.module.scss';
import shared from 'styles/Shared.module.scss';

const WholesalersTable = ({ list = [], isLoading, params }) => {
  if (isLoading) {
    return (
      <section className={shared.tableWrapper}>
        <div className={classNames(shared.tableHeader, style.header)}>
          <p>Logo</p>
          <p>Nazwa</p>
          <p>NIP</p>
          <p>Domena</p>
          <p>Data utworzenia</p>
        </div>
        <div className={shared.tableCategory}>
          <ProductPlaceholder />
        </div>
      </section>
    );
  }

  return (
    <section className={shared.tableWrapper}>
      <div className={classNames(shared.tableHeader, style.header)}>
        <p>Logo</p>
        <ColumnSortButton
          parameterName={'name'}
          params={params}
          content={'Nazwa'}
        />
        <p>NIP</p>
        <p>Domena</p>
        <ColumnSortButton
          parameterName={'created_at'}
          params={params}
          content={'Data utworzenia'}
        />
      </div>
      <div className={shared.tableCategory}>
        {list.length ? (
          list.map((wholesaler) => (
            <WholesalerThumbnail
              key={wholesaler.id}
              wholesaler={wholesaler}
            />
          ))
        ) : (
          <EmptyState type={params.keys.search ? 'search' : 'wholesalers'} />
        )}
      </div>
    </section>
  );
};

export default WholesalersTable;
