import React, { useMemo } from 'react';
import classNames from 'classnames';

import { PriceChangeDifference, RelativeTime } from 'components';
import InfoWidget from 'components/layout/InfoWidget';

import { calculateDifference, getFormattedAmount, getPriceTypeContent } from 'src/utils/helpers';

import styles from '../../PricesChangeSummary.module.scss';

const PricesChangeSummaryThumbnail = ({ item }) => {
  const difference = calculateDifference(item.previous_price, item.price);

  const { label: priceAddTypeLabel } = useMemo(() => getPriceTypeContent(item.add_type), [item.add_type]);

  return (
    <div className={classNames(styles.item, styles.grid)}>
      <p>
        <span
          className={styles.textOverflow}
          title={item.company_name}
        >
          {item.company_name}
        </span>
      </p>
      <p>
        <span
          className={styles.textOverflow}
          title={item.product_name}
        >
          {item.product_name}
        </span>
      </p>
      <p>
        <RelativeTime date={item.created_at} />
      </p>
      <p>{getFormattedAmount(item.previous_price)}</p>
      <p className={styles.priceContainer}>
        {item.price ? `${getFormattedAmount(item.price)}` : '-'}
        <InfoWidget place={'top'}>
          <p>{priceAddTypeLabel}</p>
        </InfoWidget>
      </p>
      <p>
        {item.previous_price ? (
          <PriceChangeDifference
            difference={{ percentage: difference.percentageDifference, numeric: difference.numericDifference }}
          />
        ) : (
          '-'
        )}
      </p>
      <p>
        <span
          className={styles.textOverflow}
          title={item.supplier_name}
        >
          {item.supplier_name}
        </span>
      </p>
      <p>
        <span
          className={styles.textOverflow}
          title={item.wholesaler_name}
        >
          {item.wholesaler_name || '-'}
        </span>
      </p>
    </div>
  );
};

export default PricesChangeSummaryThumbnail;
