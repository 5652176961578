import React from 'react';

import { ProductThumbnailImage, QtySelector, useValidator } from 'components';
import ProductStatusExtended from 'components/layout/ProductStatusExtended';

import style from './SingleProduct.module.scss';

const SingleProduct = ({ product, index, onChange, isLoading }) => {
  const { thumbnail, image, name, qty, unit_name, send_quantity, received_quantity = 0, currentQuantity } = product;

  const validator = useValidator();
  const quantityToSent = Math.min(qty - send_quantity, qty - received_quantity);

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <span className={style.counter}>{index + 1}.</span>
        <ProductThumbnailImage
          thumbnail={thumbnail}
          image={image}
          title={name}
          size={'tiny'}
          clickable={false}
        />
        {name}
      </div>
      <div className={style.wrapper}>
        <ProductStatusExtended
          product={product}
          className={style.tag}
        />
      </div>
      <div className={style.wrapper}>
        <QtySelector
          wrapperStyle={style.qtySelector}
          setState={(currentQuantity) => onChange({ ...product, currentQuantity })}
          suffix={unit_name}
          state={currentQuantity}
          max={quantityToSent}
          width={120}
          rule={`required|only_positive|max:${quantityToSent},num`}
          disabled={isLoading}
          validator={validator}
        />
        <p className={style.qty}>
          / {quantityToSent}&nbsp;{unit_name}
        </p>
      </div>
    </div>
  );
};

export default SingleProduct;
