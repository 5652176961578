import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AsyncSelect, Button, FileDropzone, useIsAdmin, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OCRInvoicesApi, UserApi, WholesalersApi } from 'src/api';

import { changeOcrVersion } from '../../../../../../actions';

import style from './UploadOffersForm.module.scss';

const UploadOffersForm = ({ closeModal }) => {
  const isAdmin = useIsAdmin();

  const selectedCompany = useSelector((state) => state.cartAndCatalog.selectedCompany);
  const [selectedWholesaler, setSelectedWholesaler] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const validator = useValidator();
  const dispatch = useDispatch();

  const uploadOCRInvoice = async () => {
    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const formData = new FormData();
    const invoiceData = {
      company_id: selectedCompany,
      wholesaler_id: selectedWholesaler.value,
      supplier_id: selectedSupplier.value,
      is_offer: 1,
      invoice
    };

    for (const [key, value] of Object.entries(invoiceData)) {
      if (value) formData.append(key, value);
    }

    try {
      setIsLoading(true);
      await OCRInvoicesApi.uploadInvoice(formData);
      if (closeModal) closeModal();
      dispatch(changeOcrVersion());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <FileDropzone
        label={'Plik z ofertami'}
        onChange={setInvoice}
        value={invoice}
        validator={validator}
        rule={'required'}
        id={'file'}
        name={'file'}
      />
      <AsyncSelect
        value={selectedWholesaler}
        onChange={setSelectedWholesaler}
        apiCallback={WholesalersApi.getWholesalers}
        valueKey={'id'}
        emailKey={'domain'}
        labelKey={'name'}
        label={'Hurtownia'}
        placeholder={'Hurtownia'}
        queryParams={{ pagination: 1, company_id: selectedCompany }}
        isClearable
        validator={validator}
        rule={'required'}
      />
      <AsyncSelect
        value={selectedSupplier}
        onChange={setSelectedSupplier}
        apiCallback={UserApi.getDistributors}
        valueKey={'id'}
        labelKey={'name'}
        label={'Dostawca'}
        placeholder={'Dostawca'}
        queryParams={{ pagination: 1, ...(isAdmin && { company_id: selectedCompany }) }}
        validator={validator}
        rule={'required'}
        isClearable
      />
      <Button
        label={'Przeanalizuj oferty'}
        onClick={uploadOCRInvoice}
        isLoading={isLoading}
      />
    </div>
  );
};

export default UploadOffersForm;
