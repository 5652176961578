import React from 'react';
import cn from 'classnames';

import { Card } from 'components';

import { getFormattedAmount } from 'src/utils/helpers';

import { orderStatuses } from '../../../constants/enums';

import style from './AllOrdersSumBar.module.scss';

const AllOrdersSumBar = ({ isHidden, suppliersNumber, totalSum, shipPrice = 0, children, status }) => {
  if (isHidden) {
    return null;
  }

  if (status === orderStatuses.cancelled) {
    return (
      <Card className={style.container}>
        <h2 className={cn(style.title, style.center)}>Zamówienie zostało anulowane</h2>
      </Card>
    );
  }

  return (
    <Card className={style.container}>
      <div className={style.value}>
        <span className={style.ship}>Wartość produktów: {getFormattedAmount(totalSum - shipPrice)}</span>
        <span className={style.ship}>Koszty wysyłki: {getFormattedAmount(shipPrice)}</span>
        <h2 className={style.title}>
          {suppliersNumber > 1 ? 'Suma wszystkich zamówień: ' : 'Suma zamówienia: '} {getFormattedAmount(totalSum)}
        </h2>
      </div>
      {children}
    </Card>
  );
};

export default AllOrdersSumBar;
