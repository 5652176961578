import React from 'react';
import classNames from 'classnames';

import style from '../ProductComponents.module.scss';

const ConstantQuantity = ({ isMobile, quantity, unitName }) => {
  return (
    <div className={classNames(style.wrapper, style.quantityWrapper)}>
      {isMobile && <p className={style.text}>Ilość: </p>}
      <p
        className={style.numberValue}
        id={'product-qty'}
      >
        {`${quantity} ${unitName || ''}`}
      </p>
    </div>
  );
};

export default ConstantQuantity;
