import React from 'react';
import { Store } from 'react-notifications-component';

import { NotificationCard } from 'components';

import { apiErrorHandler } from 'src/api/utils';
import closeNotify from 'src/assets/icons/xmark-solid.svg';
import { getNotificationIdFromName } from 'src/utils/helpers';
import { imageErrorHandler } from 'src/utils/helpers';

import 'styles/global.scss';
import 'animate.css';
import style from './Toasts.module.scss';

const NotificationContent = (props) => {
  const { color, text, title = 'Powiadomienie', handleCloseConfirm, id } = props;

  return (
    <div
      className={style.container}
      style={{ backgroundColor: color }}
      id={id}
    >
      <div className={style.header}>
        <p className={style.title}>{title}</p>
        <img
          className={style.icon}
          onClick={handleCloseConfirm}
          src={closeNotify}
          alt={'close icon'}
          onError={imageErrorHandler}
        />
      </div>
      <p className={style.message}>{text}</p>
    </div>
  );
};

const notify = (color, errors, options) => {
  const notifyId = getNotificationIdFromName(errors);
  if (document.getElementById(notifyId)) return;

  errors.forEach((error) => {
    Store.addNotification({
      content: (id) => (
        <NotificationContent
          color={color}
          text={error}
          id={notifyId}
          title={options?.title}
          handleCloseConfirm={() => Store.removeNotification(id.id)}
        />
      ),
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated animate__fadeIn animate__faster'],
      animationOut: ['animate__animated animate__fadeOut animate__faster'],
      dismiss: {
        duration: options?.duration || Math.max(Math.min(error.length * 50, 8000), 8000),
        pauseOnHover: true,
        click: false,
        touch: false,
        waitForAnimation: true
      },
      slidingEnter: {
        duration: 300
      },
      slidingExit: {
        duration: 1,
        delay: 0
      }
    });
  });
};

const serverNotify = (notification, onClick, options) => {
  const notifyId = notification.id;
  if (document.getElementById(notifyId)) return;

  const handleClose = (id) => {
    Store.removeNotification(id.id);
    if (onClick) onClick();
  };

  Store.addNotification({
    content: (id) => (
      <NotificationCard
        notification={notification}
        handleCloseConfirm={() => handleClose(id)}
        fixed
      />
    ),
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated animate__slideInRight animate__faster'],
    animationOut: ['animate__animated animate__slideOutRight animate__faster'],
    dismiss: {
      duration: options?.duration || 20000,
      pauseOnHover: true,
      click: true,
      touch: true,
      waitForAnimation: true
    },
    slidingEnter: {
      duration: 400
    },
    slidingExit: {
      duration: 1,
      delay: 0
    }
  });
};

export const notifyCommon = (items, options) => notify('#1D7268', items, options);

export const notifyDanger = (items, options) => notify('#EA6C64', items, options);

export const notifyNotification = (notification, onClick, options) => serverNotify(notification, onClick, options);

export const notifyApiError = (error, color = 'danger') => {
  const errorMessage = apiErrorHandler(error);
  if (error?.message === 'canceled') return;

  console.error('errorMessage', errorMessage);
  if (color === 'danger') {
    notifyDanger(errorMessage, { title: 'Wystąpił błąd' });
  } else if (color === 'common') {
    notifyCommon(errorMessage);
  }
};

export default {
  notifyCommon,
  notifyDanger,
  notifyApiError
};
