import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Accordion, Chart, Select } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { StatisticsApi } from 'src/api';
import { orderStatuses } from 'src/constants/enums';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './OrderAnalytics.module.scss';

const options = [
  { label: 'Wartość produktów', value: 'series_total' },
  { label: 'Liczba SKU', value: 'series_count' }
];

const OrderAnalytics = ({ status }) => {
  const { uid } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState();
  const [selectedDataType, setSelectedDataType] = useState(options[0]);

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await StatisticsApi.getProductsRealisationBySupplierForOrderUid(uid);
      setChartData(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (status === orderStatuses.completed) {
      getData();
    }
  }, [status]);

  const chartConfig = useMemo(() => {
    const getPercentages = (data) => {
      const completedPercentages = [];
      const cancelledPercentages = [];

      if (data && Array.isArray(data)) {
        data[0]?.data.forEach((completed, index) => {
          const cancelled = data[1].data[index];
          const total = completed + cancelled;

          completedPercentages.push(total === 0 ? 0 : (completed / total) * 100);
          cancelledPercentages.push(total === 0 ? 0 : (cancelled / total) * 100);
        });

        return { realized: completedPercentages, cancelled: cancelledPercentages };
      } else {
        return { realized: [], cancelled: [] };
      }
    };

    const data = chartData ? chartData[selectedDataType.value] : [];
    const percentagesData = getPercentages(data);
    let formatter;

    switch (selectedDataType.value) {
      case 'series_total': {
        formatter = (value) => getFormattedAmount(value);
        break;
      }
      default: {
        formatter = (value) => value;
        break;
      }
    }

    const defaultFormatter = (val, opts) => {
      const index = opts?.dataPointIndex;
      let percentages;
      if (opts.seriesIndex === 0) {
        percentages = percentagesData.realized[index];
      } else {
        percentages = percentagesData.cancelled[index];
      }
      return `${formatter(val)} (${percentages.toFixed(2)}%)`;
    };

    return { data, formatter, percentagesFormatter: defaultFormatter };
  }, [chartData, selectedDataType]);

  const chartHeight = Math.max((chartData?.supplier_names?.length || 0) * 60, 200);
  const colors = ['#3e877e', '#ea6c64'];

  const title = <h2 className={style.tableTitle}>Analiza zamówienia</h2>;

  if (status !== orderStatuses.completed) {
    return null;
  }

  return (
    <Accordion
      title={title}
      extraDeps={[chartHeight, chartConfig]}
      defaultWrapped
    >
      <div className={style.wrapper}>
        <Chart
          tooltipFormatter={chartConfig.percentagesFormatter}
          title={'Produkty zrealizowane i anulowane'}
          dataLabelsFormatter={chartConfig.formatter}
          xAxisFormatter={chartConfig.formatter}
          categories={chartData?.supplier_names}
          configRefreshVariable={chartConfig}
          customSeries={chartConfig.data}
          haveData={chartData?.have_data}
          isLoading={isLoading}
          chartTypes={['bar']}
          height={chartHeight}
          colors={colors}
          tooltipShared
          horizontal
          fullWidth
          stacked
        >
          <Select
            onChange={setSelectedDataType}
            wrapperStyle={style.select}
            value={selectedDataType}
            className={style.select}
            isSearchable={false}
            options={options}
            width={200}
          />
        </Chart>
      </div>
    </Accordion>
  );
};

export default OrderAnalytics;
