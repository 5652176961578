import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import { Button, Checkbox, Modal, useIsMobile } from 'components';

import Accordion from 'src/components/layout/Accordion';
import { handleNumeralWords } from 'src/utils/helpers';

import OrderCancelledProducts from '../OrderCancelledProducts';
import ProductView from '../ProductView';

import style from './RealizationSummary.module.scss';
import shared from 'styles/Shared.module.scss';

const RealizationSummary = ({ suppliers = [], products, orderID, productsAddedToList = [] }) => {
  const [visible, setVisible] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const isMobile = useIsMobile();

  const ids = useMemo(() => products.map((prod) => prod.id), [products]);
  const supplierWithCancelledProducts = useMemo(
    () => suppliers.filter((sup) => sup.products.filter((prod) => ids.includes(prod.id)).length > 0),
    [suppliers, products]
  );

  const selectAll = () => {
    if (selectedProducts.length === products.length) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(products.map((prod) => prod.product_id));
    }
  };

  const supplierWording = handleNumeralWords(['Dostawca', 'Dostawcy', 'Dostawcy'], supplierWithCancelledProducts.length);
  const cancelWording = handleNumeralWords(['anulował', 'anulowali', 'anulowali'], supplierWithCancelledProducts.length);
  const productsWording = handleNumeralWords(['produkt', 'produkty', 'produktów'], products.length);

  const title = (
    <div className={style.headerTitle}>
      <h2 className={style.tableTitle}>
        Anulowane produkty
        <p className={style.suppliersQty}>
          ({products.length} {productsWording})
        </p>
      </h2>
      <p
        className={style.txt}
      >{`${supplierWording} ${cancelWording} ${products.length} ${productsWording} z Twojego zamówienia. Poniżej znajdziesz listę anulowanych pozycji:`}</p>
    </div>
  );

  return (
    <div className={style.accordionWrapper}>
      <Accordion
        defaultWrapped
        className={style.container}
        title={title}
      >
        <div className={style.products}>
          <div className={classNames(shared.tableHeader, style.header)}>
            <Checkbox
              wrapperStyle={style.checkbox}
              value={selectedProducts.length === products.length}
              onChange={selectAll}
              reverse={isMobile}
              content={isMobile ? 'Wybierz wszystko' : null}
            />
            <p className={style.isMobile}>Nazwa</p>
            <p className={style.isMobile}>Ilość</p>
            <p className={style.isMobile}>Producent</p>
          </div>
          {products.map((product) => (
            <ProductView
              key={product.id}
              item={product}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              wasAddedToListData={productsAddedToList.find((prod) => prod.product_id === product.product_id)}
            />
          ))}
        </div>

        <div className={style.btns}>
          <Button
            label='Zamów'
            onClick={() => setVisible(true)}
            disabled={selectedProducts.length === 0}
          />
        </div>
        <Modal
          visible={visible}
          onClose={() => setVisible(false)}
          title={'Zamów anulowane produkty'}
        >
          <OrderCancelledProducts
            products={products}
            selectedProducts={selectedProducts}
            onClose={() => setVisible(false)}
            orderID={orderID}
          />
        </Modal>
      </Accordion>
    </div>
  );
};

export default RealizationSummary;
