import { useEffect, useState } from 'react';

const events = ['mousedown', 'touchstart'];

const useOutsideClick = (ref) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    const addEventListeners = () => {
      events.forEach((event) => document.addEventListener(event, handleClickOutside));
    };

    const removeEventListeners = () => {
      events.forEach((event) => document.removeEventListener(event, handleClickOutside));
    };

    addEventListeners();
    return () => {
      removeEventListeners();
    };
  }, []);

  return [isActive, setIsActive];
};

export default useOutsideClick;
