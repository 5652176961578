import React, { useState } from 'react';

import { OptionSwitch } from 'components';

import ExportForm from './components/ExportForm';
import ImportForm from './components/ImportForm';

import styles from './WholesalerImportExportForm.module.scss';

const WholesalerImportExportForm = ({ wholesalerId, closeModal }) => {
  const [isExport, setIsExport] = useState(true);

  return (
    <div className={styles.container}>
      <OptionSwitch
        defaultOption={isExport}
        onChange={setIsExport}
        falseLabel={'Import'}
        trueLabel={'Export'}
      />
      {isExport ? (
        <ExportForm wholesalerId={wholesalerId} />
      ) : (
        <ImportForm
          wholesalerId={wholesalerId}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default WholesalerImportExportForm;
