import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { useIsMobile } from 'components';

import { availabilities, priceTypes, productTypes } from 'src/constants/enums';

import { increaseNumberOfValidations } from '../../../../actions';
import { useSupplierFormValidationForceUpdate } from '../../../../helpers';
import Availability from '../../components/Availability';
import ConstantQuantity from '../../components/ConstantQuantity';
import ItemMenu from '../../components/ItemMenu';
import ItemValue from '../../components/ItemValue';
import Price from '../../components/Price';
import ProductDetails from '../../components/ProductDetails';
import TaxSelect from '../../components/TaxSelect';

import style from '../ProductForms.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductForm = React.memo((props) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  useSupplierFormValidationForceUpdate();
  const { product, setProduct, menu, priceType } = props;
  const {
    price,
    availability,
    name,
    producer,
    id,
    quantity,
    unit_name,
    thumbnail,
    delivery_date,
    image,
    tax,
    fixed_price,
    is_fixed,
    product_id
  } = product || {};

  const disabled = useSelector((state) => state.supplierForm.isDisabled);
  const validator = useSelector((state) => state.supplierForm.validator);
  const isProductUnavailable = useMemo(() => availability === availabilities.notAvailable, [availability]);

  const containerClasses = classNames(shared.tableHeader, style.container, {
    [style.containerNet]: priceType.value === priceTypes.netto
  });

  useEffect(() => {
    if (isProductUnavailable) {
      onChangeHandler(0, 'price');
      validator.purgeFields();
      dispatch(increaseNumberOfValidations());
    }
  }, [isProductUnavailable]);

  const onChangeHandler = (value, name) => {
    setProduct((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className={containerClasses}>
      <ProductDetails
        thumbnail={thumbnail}
        image={image}
        name={name}
        product_id={product_id}
        producer={producer}
        isMobile={isMobile}
      />
      <Price
        fixedPrice={fixed_price}
        isFixed={is_fixed}
        price={price}
        setPrice={(value) => onChangeHandler(value, 'price')}
        disabled={disabled || isProductUnavailable}
        validator={validator}
        isMobile={isMobile}
        id={id}
      />
      {priceType.value === priceTypes.netto && (
        <TaxSelect
          tax={tax}
          disabled={disabled || isProductUnavailable}
          setTax={(value) => onChangeHandler(value, 'tax')}
        />
      )}
      <ConstantQuantity
        isMobile={isMobile}
        unitName={unit_name}
        quantity={quantity}
      />
      <ItemValue
        priceType={priceType}
        isMobile={isMobile}
        price={price}
        quantity={quantity}
        tax={tax}
      />
      <Availability
        validator={validator}
        isMobile={isMobile}
        disabled={disabled}
        type={productTypes.product}
        availability={availability}
        deliveryDate={delivery_date}
        setAvailability={(value) => onChangeHandler(value, 'availability')}
        setDeliveryDate={(value) => onChangeHandler(value, 'delivery_date')}
      />
      <ItemMenu
        disabled={disabled}
        actions={menu}
      />
    </div>
  );
});

export default ProductForm;
