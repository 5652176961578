import React, { useState } from 'react';

import { Button, FileDropzone, Input, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WholesalersApi } from 'src/api';

import styles from './AddWholesalerForm.module.scss';

const defaultWholesaler = {
  name: '',
  nip: '',
  domain: '',
  logo: null
};

const AddWholesalerForm = ({ wholesalerData, onClose }) => {
  const [wholesaler, setWholesaler] = useState(wholesalerData || defaultWholesaler);
  const [replaceLogo, setReplaceLogo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validator = useValidator();

  const onChange = (e) => {
    setWholesaler((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const onChangeHandler = (name, data) => {
    setWholesaler((prev) => ({
      ...prev,
      [name]: data
    }));
  };

  const addOrEditWholesalerHandler = async (e, type) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      ...(type === 'edit' && { wholesaler_id: wholesalerData.id }),
      ...(wholesaler.logo && typeof wholesaler.logo !== 'string' && { logo: wholesaler.logo }),
      name: wholesaler.name,
      nip: wholesaler.nip,
      domain: wholesaler.domain
    };

    try {
      setIsLoading(true);
      await WholesalersApi.addOrUpdateWholesaler(params);
      if (onClose) onClose();
      notifyCommon(type === 'add' ? ['Hurtownia dodana pomyślnie.'] : [`Hurtownia ${wholesaler.name} została zaktualizowana.`]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={styles.container}
      onSubmit={(e) => addOrEditWholesalerHandler(e, wholesalerData ? 'edit' : 'add')}
    >
      {typeof wholesaler.logo === 'string' && !replaceLogo ? (
        <div className={styles.existingLogo}>
          <img src={wholesaler.logo} />
          <a
            onClick={() => setReplaceLogo(true)}
            className={styles.a}
          >
            Zmień logo
          </a>
        </div>
      ) : (
        <FileDropzone
          label={'Logo (jpg, jpeg, png)'}
          onChange={(logo) => onChangeHandler('logo', logo || null)}
          value={typeof wholesaler.logo === 'string' ? null : wholesaler.logo}
          id={'logo'}
          name={'logo'}
          validator={validator}
          rule={'required'}
        />
      )}

      <Input
        id='name'
        name='name'
        label='Nazwa hurtowni'
        value={wholesaler.name}
        onChange={onChange}
        validator={validator}
        rule={'required'}
      />
      <Input
        id='nip'
        name='nip'
        label='NIP'
        value={wholesaler.nip}
        onChange={onChange}
        validator={validator}
        rule={'required'}
      />
      <Input
        id='domain'
        name='domain'
        label='Domena hurtowni'
        value={wholesaler.domain}
        onChange={onChange}
        validator={validator}
        rule={'required'}
      />
      <Button
        label={wholesalerData ? 'Zapisz' : 'Dodaj'}
        isLoading={isLoading}
        className={styles.button}
      />
    </form>
  );
};

export default AddWholesalerForm;
