import React from 'react';
import cn from 'classnames';

import { OrderProductThumbnail, Table } from 'components';

import { InvoicesApi } from 'src/api';
import thumbnailStyles from 'src/components/layout/OrderProductThumbnail/OrderProductThumbnail.module.scss';
import { orderProductTypes } from 'src/constants/enums';
import FilePreview from 'src/features/CatalogAndCartController/components/CatalogManagement/components/InvoiceDetails/components/FilePreview';

import style from './InvoicePreviewWithProductsList.module.scss';

const InvoicePreviewWithProductsList = ({ suborder_uid, file_name, supplier_id, company_id, products }) => {
  const invoicePreviewCallback = () => {
    const params = { suborder_uid, file_name, supplier_id, company_id };
    return InvoicesApi.downloadInvoice(params);
  };

  const columns = [
    { name: 'Lp.' },
    { name: 'Nazwa' },
    { name: 'Producent' },
    { name: 'Ilość' },
    { name: 'Cena za szt.' },
    { name: 'Wartość' },
    { name: 'Komentarz' },
    { name: 'Status' }
  ];

  return (
    <div className={style.container}>
      <div className={style.list}>
        <Table
          columns={columns}
          headerClasses={cn(thumbnailStyles.container, style.header)}
        >
          {products.map((product, index) => (
            <OrderProductThumbnail
              supplierId={supplier_id}
              index={index + 1}
              type={orderProductTypes.user}
              key={product.id}
              product={product}
              addedToWarehouse={product?.added_to_warehouse}
            />
          ))}
        </Table>
      </div>
      <FilePreview
        invoice={{ file_name }}
        apiCallback={invoicePreviewCallback}
        isVisible
      />
    </div>
  );
};

export default InvoicePreviewWithProductsList;
