import React from 'react';

import ThumbnailPlaceholder from 'components/layout/ThumbnailPlaceholder';

import shared from 'styles/Shared.module.scss';

const ColumnList = ({ list, component: Component, emptyState, isLoading, type, extraProps, headerContent }) => {
  if (isLoading) {
    return <ThumbnailPlaceholder />;
  }

  return (
    <section className={shared.listContent}>
      {!!headerContent && <header className={shared.listHeader}>{headerContent}</header>}
      {list?.length ? (
        list.map((item, i) => (
          <Component
            type={type}
            key={`${item?.id}${i}` || `${item.uid}${i}`}
            item={item}
            {...item}
            {...extraProps}
          />
        ))
      ) : (
        <div className={shared.tableCategory}>
          <h3 className={shared.tableCategoryTitle}>{emptyState}</h3>
        </div>
      )}
    </section>
  );
};

export default ColumnList;
