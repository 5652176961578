import React, { forwardRef, useState } from 'react';
import cn from 'classnames';

import { Icon, Tag, useIsAdmin } from 'components';
import ChangeMenu from 'components/layout/changeMenu';

import { getFormattedAmount } from 'src/utils/helpers';

import ChangeOfferPriceForm from './components/ChangeOfferPriceForm';

import style from './SupplierThumbnail.module.scss';

const SupplierThumbnail = forwardRef((props, ref) => {
  const isAdmin = useIsAdmin();
  const { item, children, isDragging, notDraggable, isEditable } = props;

  const [isActive, setIsActive] = useState(false);

  const containerClasses = cn(style.container, {
    [style.isDragging]: isDragging,
    [style.notDraggable]: notDraggable
  });

  return (
    <>
      <div
        className={containerClasses}
        ref={ref}
      >
        <div className={style.nameWrapper}>
          <Tag
            value={item.supplier_name || '-'}
            style={{ borderLeft: `5px solid ${item?.color}` || '' }}
            className={style.tag}
          />
        </div>
        <p className={style.price}>
          {isEditable && isAdmin ? (
            <ChangeMenu
              value={getFormattedAmount(item.price)}
              valueWrapperClasses={style.priceEditable}
              isActive={isActive}
              setIsActive={setIsActive}
              width={'350px'}
              icon={
                <Icon
                  name={'edit'}
                  fill={'#888'}
                />
              }
            >
              <ChangeOfferPriceForm
                offerId={item.id}
                currentPrice={item.price}
                onClose={() => setIsActive(false)}
              />
            </ChangeMenu>
          ) : (
            getFormattedAmount(item.price)
          )}
        </p>
        {children}
      </div>
    </>
  );
});

export default SupplierThumbnail;
