import React from 'react';

import BestOfferThumbnail from './components/BestOfferThumbnail';

import styles from './BestOffers.module.scss';

const BestOffers = ({ offers }) => {
  if (offers.length < 1) return null;

  return (
    <div className={styles.wrapper}>
      {offers.map((offer, i) => {
        return (
          <div key={`${offer.offer_catalog_product_id}${i}`}>
            <BestOfferThumbnail offer={offer} />
          </div>
        );
      })}
    </div>
  );
};

export default BestOffers;
