import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { useIsAdmin } from 'components';

import { loadUserToken } from '../onboarding/actions';

const AdminToUserRedirect = () => {
  const { token, admin_id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const isAdmin = useIsAdmin();

  useLayoutEffect(() => {
    if (token && isAdmin) {
      dispatch(loadUserToken(window.atob(token), admin_id));
    } else {
      history.push('/dashboard');
    }
  }, [token, isAdmin]);

  return (
    <div>
      <h1>Trwa logowanie na konto użytkownika</h1>
    </div>
  );
};

export default AdminToUserRedirect;
