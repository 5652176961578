import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import cn from 'classnames';

import { EmptyState, PaginationNav, Table, useRequestAbortController } from 'components';
import SearchInput from 'components/layout/SearchInput';
import { notifyApiError } from 'components/layout/Toasts';

import { OCRInvoicesApi } from 'src/api';
import { PrivateRoute } from 'src/app/AppRouter/routes';
import { userRoles } from 'src/constants/enums';

import InvoiceDetails from '../InvoiceDetails';
import InvoiceThumbnail from './components/InvoiceThumbnail';

import managementStyle from '../../CatalogManagement.module.scss';
import thumbnailStyles from './components/InvoiceThumbnail/InvoiceThumbnail.module.scss';
import style from './InvoicesOCR.module.scss';

const OCR_POOLING_INTERVAL = 10_000;

const InvoicesOCR = () => {
  const [total, setTotal] = useState();
  const [search, setSearch] = useState();
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(12);
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [abortController, setNewController] = useRequestAbortController();
  const ocrVersion = useSelector((state) => state.cartAndCatalog.ocrVersion);
  const selectedCompany = useSelector((state) => state.cartAndCatalog.selectedCompany);

  const getInvoices = async () => {
    const queryParams = {
      company_id: selectedCompany,
      page: currentPage,
      perPage,
      ...(!!search && { search })
    };

    if (abortController) {
      abortController.abort();
    }
    const signal = setNewController();

    try {
      const { data } = await OCRInvoicesApi.getInvoicesList(queryParams, signal);
      setPageQty(data.last_page);
      setInvoices(data.data);
      setTotal(data.total);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      await getInvoices();
      setIsLoading(false);
    };

    const interval = setInterval(getInvoices, OCR_POOLING_INTERVAL);

    getData();

    return () => {
      clearInterval(interval);
    };
  }, [ocrVersion, currentPage, perPage, search]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  const columns = [
    { name: 'Status' },
    { name: 'Hurtownia' },
    { name: 'Nazwa pliku' },
    { name: 'Produkty' },
    { name: 'Numer faktury' },
    { name: 'Data sprzedaży' },
    { name: 'Dodano' }
  ];

  return (
    <div className={style.container}>
      <Switch>
        <PrivateRoute
          exact
          nestedRoute
          path={'/offer-catalog/invoices/:selectedInvoice'}
          roles={[userRoles.admin]}
          component={InvoiceDetails}
        />
      </Switch>
      <div className={style.box}>
        <div className={cn(managementStyle.header)}>
          <h2>OCR Faktur</h2>
          <p className={managementStyle.text}>Lista analizowanych dokumentów {!!total && `(${total})`}</p>
        </div>
        <SearchInput
          state={search}
          setState={setSearch}
          placeholder={'Nazwa dokumentu lub numer...'}
          alwaysExpand
        />
      </div>
      {isLoading ? (
        <div className={style.skeleton}>
          <Skeleton
            width={'100%'}
            height={34}
            count={7}
          />
        </div>
      ) : (
        <div className={cn(style.box, { [style.emptyState]: invoices.length === 0 })}>
          {invoices && invoices.length > 0 ? (
            <>
              <Table
                columns={columns}
                headerClasses={thumbnailStyles.gridRow}
              >
                {invoices?.map((inv) => (
                  <InvoiceThumbnail
                    key={inv.id}
                    invoice={inv}
                    refreshCallback={getInvoices}
                  />
                ))}
              </Table>
              <PaginationNav
                className={style.pagination}
                setPage={setCurrentPage}
                pagesQty={pageQty}
                setPerPage={setPerPage}
                defaultQty={perPage}
                page={currentPage}
                hideSelect
              />
            </>
          ) : (
            <EmptyState
              type={'invoicesOCR'}
              tiny
            />
          )}
        </div>
      )}
    </div>
  );
};

export default InvoicesOCR;
